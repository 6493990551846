import { DatePicker, Form, Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useAcademicYear } from "hooks/academic-year-hook/useAcademicYear";
import { useClassList } from "hooks/classes-hook/useClassList";
import { useSemesterList } from "hooks/semester-hook/useSemesterList";
import moment from "moment";
import React, { useEffect, useState } from "react";

export default function EditRaport({ open, setOpen, data }) {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const [isLoad, setIsLoad] = useState(false);
  const format = "YYYY-MM-DD";
  const [newData, setNewData] = useState({});

  // *fetch class list ---
  const { data: dataClass, refetch: fetchClass } = useClassList(false);

  // * get semester list ---
  const { data: dataSemester, refetch: fetchSemester } = useSemesterList(true);

  //* get academic year ---
  const { data: dataAcademicYear, refetch: fetchAcademicYear } =
    useAcademicYear();

  const getData = () => {
    form.setFieldsValue({
      name: data?.name,
      semesterId: data?.semester_id,
      academicYearId: data?.academic_year_id,
      classId: data?.class_id,
      fromDate: dayjs(moment(data?.from_date).format(format)),
      toDate: dayjs(moment(data?.to_date).format(format)),
    });
  };

  useEffect(() => {
    if (open) {
      fetchClass();
      fetchSemester();
      fetchAcademicYear();
      getData();
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [open, fetchClass, fetchSemester, fetchAcademicYear]);

  const handleSubmit = async () => {
    await form.validateFields();
    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }
    newData["studentRaports"] = data?.studentRaports;
    setIsLoad(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/academics/raports/${data?.id}`,
        {
          ...newData,
        },
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Berhasil mengubah raport");
      setOpen();
      form.resetFields();
    } catch (error) {
      console.log(error);
      alert("Gagal mengubah raport");
    } finally {
      setIsLoad(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen();
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      closable={false}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={isLoad}
    >
      <Form form={form} layout="vertical">
        {/* <Form.Item label="Nama Raport" name="name" rules={[{ required: true }]}>
          <Input onChange={(e) => (newData["name"] = e.target.value)} />
        </Form.Item> */}

        <Form.Item
          label="Semester"
          name="semesterId"
          rules={[{ required: true }]}
        >
          <Select onChange={(e) => (newData["semesterId"] = e)}>
            {dataSemester?.data?.map((semester) => (
              <Select.Option key={semester.id} value={semester.id}>
                {semester.semester_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tahun Ajar"
          name="academicYearId"
          rules={[{ required: true }]}
        >
          <Select onChange={(e) => (newData["academicYearId"] = e)}>
            {dataAcademicYear?.data?.data?.map((tahunAjar) => (
              <Select.Option key={tahunAjar.id} value={tahunAjar.id}>
                {tahunAjar.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Kelas" name="classId" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => (newData["classId"] = e)}
          >
            {dataClass?.data?.map((kelas) => (
              <Select.Option key={kelas.id} value={kelas.id}>
                {kelas.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <p>Priode Raport</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "10px",
          }}
        >
          <Form.Item
            format={format}
            label="Dari Tanggal"
            style={{ width: "100%" }}
            name="fromDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              placeholder="Dari Tanggal"
              onChange={(e) => (newData["fromDate"] = dayjs(e).format(format))}
            />
          </Form.Item>

          <Form.Item
            format={format}
            label="Sampai Tanggal"
            style={{ width: "100%" }}
            name="toDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              placeholder="Sampai Tanggal"
              onChange={(e) => (newData["toDate"] = dayjs(e).format(format))}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
