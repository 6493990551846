import { DatePicker, Form, Input, Modal, Select, Skeleton } from "antd";
import CreatePresenceLogic from "./CreatePresenceLogic";
import "./CreatePresence.css";

const CreatePresence = (props) => {
  const { loading, form, skeleton, data, format, handleSubmit, onCancelModal } =
    CreatePresenceLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      onOk={handleSubmit}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      {skeleton ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            name="employeeId"
            label="Nama karyawan"
            rules={[{ required: true, message: "harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {data?.data.map((employee) => (
                <Select.Option key={employee.id} value={employee.id}>
                  {employee.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="timeIn"
            label="Waktu Masuk"
            rules={[{ required: true, message: "harap diisi" }]}
          >
            <DatePicker showTime format={format} placeholder="Pilih Tanggal" />
          </Form.Item>
          <Form.Item
            name="timeOut"
            label="Waktu Pulang"
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    getFieldValue("timeIn").format(format) <
                    value.format(format)
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "masukkan diatas " +
                      getFieldValue("timeIn").format(format)
                    )
                  );
                },
              }),
            ]}
          >
            <DatePicker showTime format={format} placeholder="Pilih Tanggal" />
          </Form.Item>
          <Form.Item name="description" label="Deskripsi">
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default CreatePresence;
