import RequireAuth from "components/Auth/RequireAuth";
// import MasterFoundation from "pages/si-foundation/MasterFoundation";
import RequireMenuFoundation from "components/Auth/RequireMenuFoundation";
import Foundation from "pages/si-foundation/foundation/master/Foundation";
import LayoutFoundation from "Layouts/LayoutFoundation/LayoutFoundation";
import UnitsFoundation from "pages/si-foundation/foundation/units/UnitsFoundation";
import DetailUnit from "pages/si-foundation/foundation/units/detail/DetailUnit";
import AddEmployeeBatch from "pages/si-foundation/foundation/units/detail/AddEmployeeBatch";
import DivisiFoundation from "pages/si-foundation/foundation/master-divisi/DivisiFoundation";
import DetailDivision from "pages/si-foundation/foundation/master-divisi/detail/DetailDivision";
import AddEmployeeToDivisiBatch from "pages/si-foundation/foundation/master-divisi/detail/AddEmployeeToDivisiBatch";

export const DataRoutesFoundation = [
  // {
  //   path: "foundation",
  //   element: (
  //     <RequireAuth>
  //       <RequireMenuFoundation>
  //         <LayoutFoundation content={<MasterFoundation />} />
  //       </RequireMenuFoundation>
  //     </RequireAuth>
  //   ),
  // },
  {
    path: "foundation",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<Foundation />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/:id",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<UnitsFoundation />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/:id/:unit_id/divisi",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<DivisiFoundation />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/:id/:unit_id/divisi/:divisi_id",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<DetailDivision />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/:id/:unit_id/divisi/:divisi_id/add-employee-divisi-batch",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<AddEmployeeToDivisiBatch />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/:id/:unit_id",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<DetailUnit />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/unit/:unit_id/add-employee-batch",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<AddEmployeeBatch />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
];
