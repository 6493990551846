import Backdrop from "components/UI/Backdrop";
import { MonthlyReportProvider } from "context/MonthlyReportContext";
import { PresenceProvider } from "context/academic-context/PresencePerSubjectContext";
import { InventoryProvider } from "pages/si-inventory/inventory-context/InventoryContext";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DataRoutes } from "./DataRoutes";
import { LeaveDailyProvider } from "context/LeaveDailyContext";
import { LeaveSessionProvider } from "context/LeaveSessionContext";
import { TriwulanReportProvider } from "context/TriwulanReportContext";

const RoutesComponent = () => {
  let arr = [];

  DataRoutes.map((data) => {
    if (Array.isArray(data)) arr.push(...data);
    return arr;
  });

  return (
    <BrowserRouter>
      <PresenceProvider>
        <LeaveDailyProvider>
          <LeaveSessionProvider>
            <InventoryProvider>
              <MonthlyReportProvider>
                <TriwulanReportProvider>
                  <Suspense fallback={<Backdrop />}>
                    <Routes>
                      {arr.map((x) => (
                        <Route key={x.path} path={x.path} element={x.element} />
                      ))}
                    </Routes>
                  </Suspense>
                </TriwulanReportProvider>
              </MonthlyReportProvider>
            </InventoryProvider>
          </LeaveSessionProvider>
        </LeaveDailyProvider>
      </PresenceProvider>
    </BrowserRouter>
  );
};

export default RoutesComponent;
