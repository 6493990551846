import { AppstoreFilled, LogoutOutlined } from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CgLink } from "react-icons/cg";
import { TbExternalLink } from "react-icons/tb";
import { AiTwotoneTags } from "react-icons/ai";

const LayoutLinkLogic = () => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");

  const { data } = usePermission("mdlLink");

  const [open, setOpen] = useState(false);

  const handleSelectMenu = (param) => {
    encryptCookies("defaultLinkMenu", param);
  };

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
  };

  // Menu variable
  const mnuLink = data?.find((x) => x.id === "mnuLink");
  const mnuLinkGroup = data?.find((x) => x.id === "mnuLinkGroup");
  const mnuCategories = data?.find((x) => x.id === "mnuCategories");

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuLink") navigate("/link");
      else if (param.key === "mnuLinkGroup") navigate("/link/group");
      else if (param.key === "mnuCategories") navigate("/link/categories");
      else navigate("/link/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },

    mnuLink && {
      key: "mnuLink",
      icon: <CgLink />,
      label: "Link",
      disabled: mnuLink?.type === "disabled",
    },

    mnuLinkGroup && {
      key: "mnuLinkGroup",
      icon: <TbExternalLink />,
      label: "Link Group",
      disabled: mnuLinkGroup?.type === "disabled",
    },

    mnuCategories && {
      key: "mnuCategories",
      icon: <AiTwotoneTags />,
      label: "Kategori Link",
      disabled: mnuCategories?.type === "disabled",
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return {
    email,
    collapsed,
    items,
    items2,
    open,
    setOpen,
    itemsUser,
    setCollapsed,
    handleClickItemUser,
    handleClickMenu,
  };
};

export default LayoutLinkLogic;
