import {
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Skeleton,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { AccountContext } from "context/finance-context/AccountContext";
import dayjs from "dayjs";
import { useAccountDetail } from "hooks/finance-hook/useAccountDetail";
import { useStudentList } from "hooks/students-hook/useStudentList";
import { useContext, useEffect, useState } from "react";

dayjs.locale("id");

const EditAccount = () => {
  const { dataId, showEditAccount, setShowEditAccount, setFetchAccount } =
    useContext(AccountContext);

  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleteon,
    refetch: fetchData,
  } = useAccountDetail(dataId, false);

  // fetch teacher list
  const {
    data: dataStudent,
    refetch: fetchStudent,
    isLoading: loadingStudent,
  } = useStudentList();

  useEffect(() => {
    if (showEditAccount) fetchStudent();
  }, [showEditAccount, fetchStudent]);

  useEffect(() => {
    if (showEditAccount) {
      fetchData();
    }

    if (data) {
      form.setFieldsValue({
        student_id: data.data.student_id,
        employee_id: data.data.employee_id,
        balance: parseInt(data.data.balance),
        number: data.data.number,
        ref_amount: parseInt(data.data.ref_amount) || 0,
        type: data.data.type,
      });
    }

    return () => setNewData({});
  }, [form, data, showEditAccount, fetchData]);

  const onCancelModal = () => {
    form.resetFields();
    setShowEditAccount(false);
  };

  // dev ---
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        if (Object.keys(newData).length === 0) {
          alert("Nothing has changed");
          return;
        }
        if (newData.student_id) {
          newData.account_name = dataStudent?.data?.data?.find(
            (x) => x.id === newData.student_id
          ).name;
        }
        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + `/finance/accounts/${dataId}`,
            newData,
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );

          message.success(data.message);
          onCancelModal();
          setFetchAccount(true);
        } catch (error) {
          console.log(error);
          alert(
            error.response.data.error ||
              error.response.data.message ||
              error.message
          );
        }

        setLoading(false);
      })
      .catch(() => alert("Fields Error"));
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={showEditAccount}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      title="Edit Akun"
      centered
    >
      {skeleteon && <Skeleton active />}

      {!skeleteon && (
        <Form form={form} layout="vertical">
          <Form.Item name="student_id" label="Nama Murid">
            <Select
              showSearch
              disabled={loadingStudent}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(val) => (newData["student_id"] = val)}
            >
              {dataStudent?.data?.data?.map((student) => (
                <Select.Option key={student.id} value={student.id}>
                  {student.name} - {student.class.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="balance"
            label="Saldo"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
              style={{ width: "100%" }}
              onChange={(value) => (newData["balance"] = `${value}`)}
            />
          </Form.Item>

          <Form.Item
            hidden
            name="ref_amount"
            label="Nominal Acuan"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
              style={{ width: "100%" }}
              onChange={(value) => (newData["ref_amount"] = `${value}`)}
            />
          </Form.Item>

          <Form.Item
            name="number"
            label="Nomor Rekening"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["number"] = value)}
            />
          </Form.Item>

          <Form.Item hidden name="type" label="Tipe">
            <Select onChange={(val) => (newData["type"] = val)}>
              <Select.Option value="spp">SPP</Select.Option>
              <Select.Option value="bp">Biaya Pendidikan</Select.Option>
              <Select.Option value="bwt">Biaya Wajib Tahunan</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditAccount;
