import { Button, Form, Modal, Select, Skeleton } from "antd";
import React from "react";

const SelectModal = ({ show, skeleton, onCancel, onChange, dataActivity }) => {
  return (
    <Modal
      open={show}
      onCancel={onCancel}
      title="Pilih Aktivitas"
      footer={[<Button key="cancel" hidden />]}
    >
      <Form>
        {skeleton ? (
          <Skeleton active />
        ) : (
          <Form.Item>
            <Select showSearch onChange={onChange}>
              {dataActivity?.map((data) => (
                <Select.Option key={data.id} value={data.id}>
                  {data.name} {data?.unit?.name && `- (${data?.unit?.name})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default SelectModal;
