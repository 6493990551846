import { Badge, Button, Skeleton } from "antd";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MasterHrd.module.css";
import { useStatusEmployee } from "hooks/dashboard-hrd/useStatusEmployee";
import dayjs from "dayjs";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import { DashboardTotalKaryawan } from "./DashboardTotalKaryawan";
import DashboardPresensiKaryawan from "./DashboardPresensiKaryawan";

const MasterHrd = () => {
  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();
  const { data: statusEmployee, isLoading: isLoadingStatusEmployee } =
    useStatusEmployee();

  // data unit
  const { data: dataUnit, isLoading: isLoadingUnit } = useUnitList();

  const roles = decryptCookies("role").split(",");

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchDashboard = async () => {
      try {
        await axios.get(
          REACT_APP_HOST_API,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );
      } catch (error) {
        if (error.response.status === 401) {
          alert("Akun belum terverifikasi, silahkan hubungi IT / HRD");
          return navigate("/");
        } else {
          alert(error);
        }
      }
    };

    fetchDashboard();

    return () => {
      cancelToken.cancel();
    };
  }, [REACT_APP_HOST_API, navigate]);

  const handleClickMenu = (menu) => {
    encryptCookies("defaultHrdMenu", menu);

    if (menu === "mnuRecapPresence")
      navigate("activity/presence-:id/recapPresence-:id");
    else if (menu === "mnuPresence") navigate("activity/presence-:id");
    else if (menu === "mnuLeaveHourly") navigate("leavesession");
    else navigate(menu.toLowerCase().split("nu")[1]);
  };

  // presence,leave daily, leave hourly, monthly report
  const dataMenuAdmin = [
    { id: "mnuPresence", name: "Presence" },
    { id: "mnuRecapPresence", name: "Recap Presence" },
    { id: "mnuLeaveDaily", name: "Leave Daily" },
    { id: "mnuLeaveHourly", name: "Leave Hourly" },
    { id: "mnuMonthlyReport", name: "Monthly Report" },
  ];

  // data menu user_hrd leave daily, leave hourly, monthly report, triwulan report
  const dataMenuUserHrd = [
    { id: "mnuLeaveDaily", name: "Leave Daily" },
    { id: "mnuLeaveHourly", name: "Leave Hourly" },
    { id: "mnuMonthlyReport", name: "Monthly Report" },
    { id: "mnuTriwulanReport", name: "Triwulan Report" },
  ];

  const dataMenu = roles.some(
    (x) => x === "super_admin" || x === "admin_foundation" || x === "admin_hrd"
  )
    ? dataMenuAdmin
    : dataMenuUserHrd;

  return (
    <>
      <div className={styles.card}>
        {!isLoadingStatusEmployee && (
          <div>
            <h1
              className={styles.greetingHeader}
            >{`Halo ${statusEmployee?.data?.name}!`}</h1>
            <p className={styles.greetingDate}>
              {dayjs().format("dddd, D MMMM YYYY")}
            </p>
          </div>
        )}
        {isLoadingStatusEmployee && <Skeleton active />}
        <div className={styles.menuBoxShortcut}>
          <h2 className={styles.headerThird}>Shortcut</h2>
          <div className={styles.menuList}>
            {dataMenu.map((x) => (
              <Button
                key={x.id}
                shape="round"
                onClick={() => handleClickMenu(x.id)}
              >
                {x.id.split("nu")[1]}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.recapList}>
        <div className={styles.card}>
          {!isLoadingStatusEmployee && (
            <div>
              <h2 className={styles.headerSecondary}>Kepegawaian</h2>
              <p
              // className={styles.headerDescription}
              >{`- Masa kerja: ${statusEmployee?.data?.period_of_work}`}</p>
              <p>
                - Sisa Jatah Cuti:{" "}
                {!statusEmployee?.data?.sisa_jatah_cuti
                  ? "-"
                  : statusEmployee?.data?.sisa_jatah_cuti}
              </p>

              <p>
                - Memiliki BPJS Kesehatan:{" "}
                {statusEmployee?.data?.has_bpjs_health ? "Sudah" : "Belum"}
              </p>
              <p>
                - Memiliki BPJS Ketenagakerjaan:{" "}
                {statusEmployee?.data?.has_bpjs_employment ? "Sudah" : "Belum"}
              </p>
              <div className={styles.badgeBox}>
                {statusEmployee?.data?.employeeUnits.map((x) => (
                  <div key={x.id} className={styles.badge}>
                    <Badge
                      text={`${x.title} - ${x?.unit?.name} - ${x?.status}`}
                      color={`${x.title === "lead" ? "#1677FF" : "#1CEF92"}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {isLoadingStatusEmployee && <Skeleton active />}
        </div>
        {roles.some(
          (x) =>
            x === "super_admin" || x === "admin_foundation" || x === "admin_hrd"
        ) && (
          <DashboardTotalKaryawan
            dataUnit={dataUnit}
            isLoadingUnit={isLoadingUnit}
          />
        )}
        {
          <DashboardPresensiKaryawan
            dataUnit={dataUnit}
            isLoadingUnit={isLoadingUnit}
          />
        }
      </div>
    </>
  );
};

export default MasterHrd;
