import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAgendaPagination = (dataTable, keyword, date, foudationId) => {
  return useQuery(
    [
      "get-agenda-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      date,
      foudationId,
    ],
    () =>
      GetList(
        `/academics/agendas?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&fromDate=${date.from}&toDate=${date.to}&foundationId=${foudationId}`
      )
  );
};
