import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import { useStudentDetail } from "hooks/students-hook/useStudentDetail";
import React, { Fragment } from "react";

const AlamatSiswaTab = ({ studentId }) => {
  const { data, isLoading } = useParentList(studentId, true);
  const { data: dataStudent } = useStudentDetail(studentId, true);

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data.data && (
        <Fragment key={data.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Alamat</th>
                <td>{data.data.address && data.data.address}</td>
              </tr>
              <tr>
                <th>RT</th>
                <td>{data.data.rt && data.data.rt}</td>
              </tr>
              <tr>
                <th>RW</th>
                <td>{data.data.rw && data.data.rw}</td>
              </tr>
              <tr>
                <th>Provinsi</th>
                <td>
                  {dataStudent.data.provinsi && dataStudent.data.provinsi.nama}
                </td>
              </tr>
              <tr>
                <th>Kota / Kabupaten</th>
                <td>{dataStudent.data.kota && dataStudent.data.kota.nama}</td>
              </tr>
              <tr>
                <th>Kecamatan</th>
                <td>
                  {dataStudent.data.kecamatan &&
                    dataStudent.data.kecamatan.nama}
                </td>
              </tr>
              <tr>
                <th>Kelurahan</th>
                <td>
                  {dataStudent.data.kelurahan &&
                    dataStudent.data.kelurahan.nama}
                </td>
              </tr>
              <tr>
                <th>Kode Pos</th>
                <td>{data.data.zip && data.data.zip}</td>
              </tr>
              <tr>
                <th>Jenis Tinggal</th>
                <td>
                  {data.data.residence &&
                    (() => {
                      if (data.data.residence === "others") {
                        return "lainnya";
                      } else if (data.data.residence === "with parent") {
                        return "Dengan orang tua";
                      } else if (data.data.residence === "boarding school") {
                        return "Pondok";
                      } else if (data.data.residence === "dormitory") {
                        return "Asrama";
                      }
                    })()}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default React.memo(AlamatSiswaTab);
