import { Button, Skeleton, Table } from "antd";
import { Fragment } from "react";
import "./DetailTeachers.css";
import DetailTeachersLogic from "./DetailTeachersLogic";
import AddTeachings from "./create/AddTeachings";
import EditTeachings from "./edit/EditTeachings";
import EditTeacher from "./edit/EditTeacher";

const DetailTeachers = () => {
  const {
    id,
    showEditTeacher,
    // setShowEditTeacher,
    onUpdateTeacher,
    onCancelTeacher,
    handleGender,
    data,
    skeleton,
    isLoading,
    columns,
    dataSource,
    isFetching,
    setShowAddTeaching,
    showAddTeaching,
    onCancel,
    onCreate,
    dataId,
    onUpdate,
    showEditTeachings,
    btnAdd,
  } = DetailTeachersLogic();

  return (
    <>
      {skeleton && <Skeleton active />}

      {!skeleton && data && (
        <Fragment key={data.id}>
          <div className="table-header">
            <h1>Detail Guru</h1>
            {/* <Button onClick={() => setShowEditTeacher(true)} type="primary">
              Edit
            </Button> */}
          </div>
          <table className="detail-teachers__table">
            <tbody>
              <tr>
                <th>Nama</th>
                <td>: {data.employee.name}</td>
              </tr>
              <tr>
                <th>NIP</th>
                <td>: {data.employee.nip}</td>
              </tr>
              <tr>
                <th>NUPTK</th>
                <td>: {data.employee.nuptk}</td>
              </tr>
              <tr>
                <th>Jenis Kelamin</th>
                <td>: {handleGender(data.employee.gender)}</td>
              </tr>
              {/* <tr>
                <th>Tempat Lahir</th>
                <td>: {data.employee.birth_city}</td>
              </tr>
              <tr>
                <th>Tanggal Lahir</th>
                <td>: {data.employee.birth_day}</td>
              </tr>
              <tr>
                <th>Tanggal Masuk</th>
                <td>: {data.employee.date_in}</td>
              </tr>
              <tr>
                <th>Tanggal Keluar</th>
                <td>: {data.employee.date_out}</td>
              </tr> */}
              <tr>
                <th>Alamat</th>
                <td>: {data.employee.address}</td>
              </tr>
              {/* <tr>
                <th>Total Mengajar</th>
                <td>: {data.total_mengajar}</td>
              </tr> */}
              {/* <tr>
                <th>Provinsi</th>
                <td>: {data?.provinsi?.nama}</td>
              </tr>
              <tr>
                <th>Kota</th>
                <td>: {data?.kota?.nama}</td>
              </tr>
              <tr>
                <th>Kecamatan</th>
                <td>: {data?.kecamatan?.nama}</td>
              </tr>
              <tr>
                <th>Kelurahan</th>
                <td>: {data?.kelurahan?.nama}</td>
              </tr> */}
            </tbody>
          </table>
          <div className="table-header">
            <h1>Daftar kelas {data?.name} Mengajar</h1>
            <Button
              type="primary"
              hidden={!btnAdd}
              onClick={() => setShowAddTeaching(true)}
              disabled={btnAdd && btnAdd.type === "disabled"}
            >
              Tambah Mengajar
            </Button>
          </div>
          <Table
            size="small"
            tableLayout="auto"
            loading={isLoading || isFetching}
            columns={columns}
            dataSource={dataSource}
            // pagination={pagination}
            scroll={{
              y: "50vh",
              x: 800,
            }}
          />
          <AddTeachings
            dataTeachings={dataSource}
            onCreate={onCreate}
            onCancel={onCancel}
            show={showAddTeaching}
          />
          <EditTeachings
            id={dataId}
            onUpdate={onUpdate}
            onCancel={onCancel}
            show={showEditTeachings}
          />
          <EditTeacher
            id={id}
            onUpdate={onUpdateTeacher}
            onCancel={onCancelTeacher}
            show={showEditTeacher}
          />
        </Fragment>
      )}
    </>
  );
};

export default DetailTeachers;
