import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const usePresenceEmployee = (
  fromDate = "",
  toDate = "",
  unitId = "",
  activityId = ""
) => {
  return useQuery(
    [
      "get-dashboard-presence-employees",
      fromDate || "",
      toDate || "",
      unitId || "",
      activityId || "",
    ],
    () =>
      GetPagination(
        `/dashboard/chart-presence-employees?fromDate=${fromDate}&toDate=${toDate}&unitId=${unitId}&activityId=${activityId}`
      )
  );
};
