import { Form, Input, Tag, message } from "antd";
import { useClassDetail } from "hooks/classes-hook/useClassDetail";
import { useClassList } from "hooks/classes-hook/useClassList";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks/usePermissions";

const DailyAttandanceLogic = () => {
  const [form] = Form.useForm();
  const [classId, setClassId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [posting, setPosting] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { REACT_APP_HOST_API } = process.env;
  const { data: dataClass, isLoading: skeleton, refetch } = useClassList(false);
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const navigate = useNavigate();

  const navigateToRecap = () => {
    navigate("/academic/PresenceDaily");
  };

  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuPresenceDaily"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddPresenceDaily");

  const {
    data,
    isLoading,
    isFetching,
    refetch: fetchClassDetail,
  } = useClassDetail(classId, false);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const filteredDataSource = dataSource.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDataSource(filteredDataSource);
  }, [dataSource, searchQuery]);

  const handleStatusChange = (studentId, status) => {
    form.setFieldsValue({ [studentId]: status });
    // update the dataSource
    setDataSource((prevDataSource) => {
      const newDataSource = prevDataSource.map((student) => {
        if (student.id === studentId) {
          return { ...student, status };
        }
        return student;
      });
      return newDataSource;
    });
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      fixed: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      responsive: ["md"],
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      width: window.innerWidth > 800 ? 200 : 65,
      fixed: "left",
    },
    {
      title: "status",
      dataIndex: "status",
      align: "left",
      key: "present",
      render: (text, record) => (
        <Form.Item name={record.id} initialValue="present">
          <div className="tag-list">
            <Tag
              color={record.status === "present" ? "green" : "default"}
              onClick={() => handleStatusChange(record.id, "present")}
              style={
                posting ? { cursor: "not-allowed" } : { cursor: "pointer" }
              }
            >
              Hadir
            </Tag>
            <Tag
              color={record.status === "sick" ? "orange" : "default"}
              onClick={() => handleStatusChange(record.id, "sick")}
              style={
                posting ? { cursor: "not-allowed" } : { cursor: "pointer" }
              }
            >
              Sakit
            </Tag>
            <Tag
              color={record.status === "permission" ? "purple" : "default"}
              onClick={() => handleStatusChange(record.id, "permission")}
              style={
                posting ? { cursor: "not-allowed" } : { cursor: "pointer" }
              }
            >
              Izin
            </Tag>
            <Tag
              color={record.status === "absent" ? "red" : "default"}
              onClick={() => handleStatusChange(record.id, "absent")}
              style={
                posting ? { cursor: "not-allowed" } : { cursor: "pointer" }
              }
            >
              Alpa
            </Tag>
          </div>
        </Form.Item>
      ),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <Form.Item name={`description_${record.id}`}>
          <Input placeholder="Description" disabled={posting} />
        </Form.Item>
      ),
    },
  ];

  useEffect(() => {
    refetch();
  }, [refetch]);

  // dev ---
  const [dateIn, setDateIn] = useState();
  const [isFillToday, setIsFillToday] = useState(false);

  useEffect(() => {
    if (classId !== "") {
      fetchClassDetail();

      // checkToday ---

      if (!dateIn) {
        const today = dayjs().format("YYYY-MM-DD");
        setDateIn(today);
      }

      const checkToday = async () => {
        const dateConvert = dayjs(dateIn).format("YYYY-MM-DD");

        const { data } = await axios.get(
          `${REACT_APP_HOST_API}/academics/daily-attendances?page=1&limit=10&mode=page&classId=${classId}&fromDate=${dateConvert}&toDate=${dateConvert}`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        const result = data.data.data;

        if (result.length !== 0) {
          setIsFillToday(true);
        } else {
          setIsFillToday(false);
        }
      };

      checkToday();
    }
  }, [classId, fetchClassDetail, dateIn, REACT_APP_HOST_API]);

  useEffect(() => {
    if (data) {
      const sortedStudents = data?.data.students.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setDataSource(
        sortedStudents.map((student, index) => {
          return {
            ...student,
            index: index + 1,
            status: "present",
            key: student.id,
          };
        })
      );
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      if (data?.data.students.length === 0)
        return message.error("Tidak ada data siswa");
      setSearchQuery("");
      await form.validateFields();
      const values = await form.getFieldsValue();
      const attendanceData = data?.data.students.map((student) => {
        return {
          classId,
          date_in: dayjs(values["date_in"]).format("YYYY-MM-DD HH:mm:ss"),
          date_out: values["date_out"]
            ? dayjs(values["date_out"]).format("YYYY-MM-DD HH:mm:ss")
            : null,
          status: values[student.id] || "present",
          description: values[`description_${student.id}`] || "",
          studentId: student.id,
        };
      });
      setPosting(true);
      const { data: dataSubmit } = await axios.post(
        REACT_APP_HOST_API + `/academics/daily-attendances`,
        { dailyAttendance: attendanceData },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(dataSubmit.message);
      form.resetFields();
      setClassId("");
      setDataSource([]);
      navigateToRecap();
    } catch (error) {
      console.log("error", error);
      alert(error?.response?.data?.error);
    } finally {
      setPosting(false);
    }
  };

  return {
    btnAdd,
    isFillToday,
    setDateIn,
    skeleton,
    form,
    dataClass,
    columns,
    data,
    isLoading,
    isFetching,
    dataSource,
    setClassId,
    handleSearch,
    handleSubmit,
    navigateToRecap,
    searchQuery,
    posting,
    filteredDataSource,
  };
};

export default DailyAttandanceLogic;
