import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useAccountPagination = (
  dataTable,
  keyword,
  tipe,
  foundationId
) => {
  return useQuery(
    [
      "get-account-finance-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      tipe,
      foundationId,
    ],
    () =>
      GetPagination(
        `/finance/accounts?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&keyword=${keyword}&tipe=${tipe}&foundation_id=${foundationId}`
      )
  );
};
