import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useDeskripsiRaportDetail = (raport_id, enabled) => {
  return useQuery(
    ["get-raport-deskripsi-detail", raport_id],
    () =>
      GetDetail(
        `/academics/student-raports/${raport_id}/student-raport-details?description=true`
      ),
    { enabled }
  );
};
