import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const KontakSiswaTab = () => {
  const { student_id } = useParams();
  const { data, isLoading } = useParentList(student_id, true);
  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data.data && (
        <Fragment key={data.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>No. Telp</th>
                <td>{data.data.phone && data.data.phone}</td>
              </tr>
              <tr>
                <th>No. Hp</th>
                <td>{data.data.mobile_phone && data.data.mobile_phone}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{data.data.email && data.data.email}</td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default KontakSiswaTab;
