import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useRecapSubActivityPagination = ({ id, dataTable, date }) => {
  return useQuery(
    [
      "getRecapSubActivityPagination",
      id,
      dataTable.current_page,
      dataTable.per_page,
      date.from,
      date.to,
    ],
    () =>
      GetPagination(
        `/recap-sub-activities/${id}?fromDate=${date.from}&toDate=${date.to}&page=${dataTable.current_page}&limit=${dataTable.per_page}`
      )
  );
};
