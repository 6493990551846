import { useTotalEmployee } from "hooks/dashboard-hrd/useTotalEmployee";
import React, { useEffect, useMemo, useState } from 'react'
import { Pie } from '@ant-design/plots';
import styles from "./MasterHrd.module.css";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Select, Tooltip } from "antd";

export const DashboardTotalKaryawan = ({ dataUnit, isLoadingUnit }) => {
   const [unitId, setUnitId] = useState("");
   const roles = decryptCookies("role").split(",");
   const { data: totalEmployee } = useTotalEmployee(
      unitId,
      roles.some((x) => (x === "super_admin" || x === "admin_foundation" || x === "admin_hrd"))
   );
   const [dataTotalEmployee, setDataTotalEmployee] = useState([{
      type: 'Fulltime',
      value: 0
   }, {
      type: 'Parttime',
      value: 0
   }]);

   useEffect(() => {
      setDataTotalEmployee([{
         type: 'Fulltime',
         value: Number(totalEmployee?.data?.full_time) || 0
      }, {
         type: 'Parttime',
         value: Number(totalEmployee?.data?.part_time) || 0
      }])
   }, [totalEmployee])

   const config = useMemo(() => ({
      appendPadding: 10,
      data: dataTotalEmployee,
      angleField: 'value',
      colorField: 'type',
      color: ({ type }) => {
         if (type === 'Fulltime') {
            return '#1DF4D5';
         }
         return '#8FB2BE';
      },
      radius: 1,
      innerRadius: 0.6,
      label: {
         type: 'inner',
         offset: '-50%',
         content: '{value}',
         style: {
            textAlign: 'center',
            fontSize: 14,
         },
      },
      interactions: [
         {
            type: 'element-selected',
         },
         {
            type: 'element-active',
         },
      ],
      statistic: {
         title: false,
         content: {
            style: {
               whiteSpace: 'pre-wrap',
               overflow: 'hidden',
               textOverflow: 'ellipsis',
            },
            content: '',
         },
      },
      legend: {
         layout: 'horizontal',
         position: 'top'
      },
   }), [dataTotalEmployee]);

   return (
      <div className={styles.card}>
         <div className={styles.flexItem}>
            <h2 className={styles.headerSecondary}>Karyawan</h2>
            <h2 className={styles.totalEmployee}>Total : {
               (Number(totalEmployee?.data?.full_time) + Number(totalEmployee?.data?.part_time)) || '0'
            }</h2>
         </div>
         <Tooltip Tooltip title="Unit">
            <Select
               placeholder="Pilih Unit"
               disabled={isLoadingUnit}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                  option.children
                     .toLowerCase()
                     .includes(input.toLowerCase())
               }
               onChange={(value) => setUnitId(value)}
               style={{
                  minWidth: 200,
                  marginBottom: 20,
               }}
            >
               <Select.Option
                  className="select-option-foundation"
                  value=""
               >
                  ALL
               </Select.Option>
               {dataUnit?.data?.map((x) => (
                  <Select.Option
                     key={x.id}
                     value={x.id}
                     className="select-option-foundation"
                  >
                     {x.name}
                  </Select.Option>
               ))}
            </Select>
         </Tooltip>
         <Pie {...config} />
      </div>
   )
}
