import { InboxOutlined } from "@ant-design/icons";
import { Form, Modal, Upload } from "antd";
import React from "react";
import ImportRevenuesLogic from "./ImportRevenuesLogic";
import FilterFoundation from "components/UI/FilterFoundation";
const { Dragger } = Upload;

const ImportRevenues = (props) => {
  const {
    form,
    isPDF,
    propsUpload,
    uploading,
    onCancelModal,
    handleUpload,
    isSuperAdmin,
    foundationId,
    setFoundationId,
  } = ImportRevenuesLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleUpload}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: uploading }}
      cancelButtonProps={{ disabled: uploading }}
      title="Import Penerimaan"
    >
      <Form form={form} layout="vertical">
        {isSuperAdmin && (
          <Form.Item
            label="Yayasan"
            name="foundation_id"
            rules={[{ required: isSuperAdmin && !foundationId }]}
          >
            <FilterFoundation
              setFoundationId={setFoundationId}
              isNotShowAll={true}
            />
          </Form.Item>
        )}

        <Form.Item
          name="file"
          label="File PDF"
          rules={[
            {
              validator: (_, value) => {
                if (!value || !value.fileList || value.fileList.length === 0) {
                  return Promise.reject(new Error("Please upload a PDF file"));
                }
                const file = value.fileList[0];
                if (!isPDF(file)) {
                  return Promise.reject(new Error("Please upload a PDF file"));
                }
                return Promise.resolve();
              },
            },
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Dragger
            name="file"
            accept=".pdf"
            listType="picture"
            disabled={uploading}
            iconRender={(file) => {
              if (file.type === "application/pdf") {
                return (
                  <InboxOutlined style={{ fontSize: 45, color: "#d9534f" }} />
                );
              }
              return <InboxOutlined />;
            }}
            {...propsUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for PDF files only.</p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportRevenues;
