import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useKompetensiIntiList = () => {
  return useQuery(
    "get-kompetensi_inti-list",
    () => GetList("/academics/kompetensi-inti"),
    {
      enabled: false,
    }
  );
};
