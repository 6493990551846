import {
  Badge,
  Button,
  Image,
  Popconfirm,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { LeaveDailyContext } from "context/LeaveDailyContext";
import { useLeaveDailyPagination } from "hooks/leave-hook/apiCalls";
import { usePermission } from "hooks/usePermissions";
import { useContext, useEffect, useState } from "react";
import LeaveFilter from "./LeaveFilter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useBaseUrl } from "hooks/base-url-hook/useBaseUrl";
import dayjs from "dayjs";
import ReasonCell from "./ReasonCell";
import { DeleteApiWithMessage } from "api/DeleteDataWithMessage";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Leave = () => {
  const [dataTable, setDataTable] = useState({ page: 1, limit: 20 });
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const employeeIdCookies = decryptCookies("employeeId");

  const {
    date,
    keyword,
    fetchLeave,
    setFetchLeave,
    setLeaveId,
    status: statusContext,
    leaveStatus,
  } = useContext(LeaveDailyContext);

  const { REACT_APP_HOST_API } = process.env;

  const context = useContext(LeaveDailyContext);

  const { data: baseUrl, refetch: fetchBaseUrl } = useBaseUrl();
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some(
    (x) => x === "super_admin" || x === "admin_foundation"
  );

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuLeaveDaily");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddLeaveDaily");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditLeaveDaily");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteLeaveDaily");
  // const btnDetail = dataPermission?.find((x) => x.id === "btnDetailLeaveDaily");
  // const btnExport = dataPermission?.find((x) => x.id === "btnExportLeaveDaily");
  const btnApprove = dataPermission?.find(
    (x) => x.id === "btnApproveLeaveDaily"
  );
  const btnReject = dataPermission?.find((x) => x.id === "btnRejectLeaveDaily");

  const { data, isFetching, refetch } = useLeaveDailyPagination({
    date,
    dataTable,
    keyword,
    status: statusContext,
    leaveStatus,
  });

  useEffect(() => {
    refetch();
    fetchBaseUrl();
    roles.find((x) => x === "admin_hrd" || x === "super_admin")
      ? setIsAdmin(true)
      : setIsAdmin(false);

    setEmployeeId(baseUrl?.data[0]?.employee_id);
    return () => setFetchLeave(false);
    // eslint-disable-next-line
  }, [fetchLeave, refetch, setFetchLeave, fetchBaseUrl, baseUrl]);

  const status = {
    aprove: <Badge status="success" text="Diterima" />,
    rejected: <Badge status="error" text="Ditolak" />,
    waiting: <Badge status="default" text="Menunggu" />,
  };

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
    name: x.employee?.name,
    unitName: x.unit?.name,
  }));

  const changeStatus = async (id, status) => {
    try {
      setLoading(true);
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/leaves/${id}`,
        { status },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      refetch();
      message.success(data.message);
    } catch (error) {
      alert(error?.response?.data?.message || "input tidak valid");
    } finally {
      setLoading(false);
    }
  };

  const currentDate = dayjs();

  const columns = [
    { title: "No", dataIndex: "index", width: 50, fixed: "left" },
    {
      title: "Nama",
      dataIndex: "name",
      fixed: "left",
      width: window.innerWidth < 768 ? 220 : 250,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      width: 100,
      render: (x) => status[x],
    },
    {
      title: "Unit",
      dataIndex: "unitName",
      width: window.innerWidth < 768 ? 160 : 170,
    },
    {
      title: "Tanggal Awal",
      dataIndex: "from_date",
      align: "left",
      width: 130,
    },
    {
      title: "Tanggal Akhir",
      dataIndex: "to_date",
      align: "left",
      width: 130,
    },
    {
      title: "Status Izin",
      dataIndex: "leave_status",
      width: 100,
      align: "left",
    },
    // { title: "Tipe", dataIndex: "type", width: 90, align: "left", },
    {
      title: "Alasan",
      dataIndex: "reason",
      width: 90,
      align: "left",
      render: (text) => {
        return <ReasonCell text={text} />;
      },
    },
    {
      title: "Gambar",
      dataIndex: "image",
      width: 90,
      render: (file) => {
        const Content = file ? (
          <>
            <Tag
              color="blue"
              onClick={() => {
                setVisible(true);
                setFile(file);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Lihat
            </Tag>
          </>
        ) : (
          <span>-</span>
        );
        return Content;
      },
    },
    {
      title: "Ubah Status",
      dataIndex: "id",
      align: "center",
      width: 120,
      hidden: !btnApprove && !btnReject,
      render: (id, record) => {
        const isLead =
          record?.unit?.employeeUnits[0]?.employee_id === employeeIdCookies;
        return (
          <Space>
            {record.status !== "aprove" ? (
              <>
                {record.status !== "rejected" && (
                  <Tag
                    color={
                      btnReject?.type === "disabled" || loading ? "" : "red"
                    }
                    hidden={
                      !btnReject ||
                      record.status !== "waiting" ||
                      (!isLead && !isSuperAdmin)
                    }
                    style={
                      btnReject?.type === "disabled" || loading
                        ? disabled
                        : { cursor: "pointer" }
                    }
                    onClick={() => {
                      if (btnReject?.type === "disabled" || loading) return;
                      else {
                        changeStatus(id, "rejected");
                      }
                    }}
                  >
                    Reject
                  </Tag>
                )}
                <Tag
                  color={
                    btnApprove?.type === "disabled" || loading ? "" : "green"
                  }
                  hidden={
                    !btnApprove ||
                    record.status !== "waiting" ||
                    (!isLead && !isSuperAdmin)
                  }
                  style={
                    btnApprove?.type === "disabled" || loading
                      ? disabled
                      : { cursor: "pointer" }
                  }
                  onClick={() => {
                    if (btnApprove?.type === "disabled" || loading) return;
                    else {
                      changeStatus(id, "aprove");
                    }
                  }}
                >
                  Aprove
                </Tag>
              </>
            ) : (
              "-"
            )}
          </Space>
        );
      },
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, data) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApiWithMessage({ url: "/leaves/", dataId: id, refetch })
              }
              disabled={btnDelete?.type === "disabled"}
            >
              <Tag
                color={btnDelete?.type === "disabled" ? "" : "magenta"}
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color={btnEdit?.type === "disabled" ? "" : "orange"}
              hidden={
                !btnEdit ||
                (data?.employee_id !== employeeId &&
                  !isAdmin &&
                  !isSuperAdmin) ||
                (dayjs(data?.from_date).isBefore(currentDate, "day") &&
                  !isAdmin &&
                  !isSuperAdmin)
              }
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") return;
                else {
                  navigate(`/hrd/leavedaily/edit/${id}`);
                  setLeaveId(id);
                }
              }}
            >
              Ubah
            </Tag>
          </Space>
        );
      },
    },
  ].filter((x) => x.hidden !== true);

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable((x) => ({ ...x, page: curr, limit: size })),
  };

  const moveToCreate = () => {
    navigate("/hrd/leavedaily/create");
  };

  return (
    <>
      <div className="table-header">
        <h1>Izin Harian</h1>
        <Space>
          <Button
            type="primary"
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            onClick={() => {
              if (btnAdd?.type === "disabled") return;
              else moveToCreate(true);
            }}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <LeaveFilter context={context} />

      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 1200 }
            : { y: "55vh", x: 1200 }
        }
        rowClassName={(record) =>
          record.status === "waiting" ? "table-pink" : ""
        }
      />
      <Image
        width={100}
        style={{
          display: "none",
        }}
        src={file}
        preview={{
          visible,
          scaleStep: 0.5,
          src: file,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
};

export default Leave;
