import { PlusOutlined } from "@ant-design/icons";
import RichTextEditor from "@mantine/rte";
import { Button, DatePicker, Form, Input, Space, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { ActivityContext } from "context/ActivityContext";
import dayjs from "dayjs";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const date = "YYYY-MM-DD HH:mm:ss";

const EditSubActivity = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [newFileList, setNewFileList] = useState([]);
  const [newData, setNewData] = useState({});
  const [value, setValue] = useState("");

  const { setFetchSession } = useContext(ActivityContext);
  const { REACT_APP_HOST_API } = process.env;
  const { activity_id, session_id } = useParams();
  const navigate = useNavigate();
  const html2json = require("html2json").html2json;
  const json2html = require("html2json").json2html;

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/sub-activities/${session_id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      setFileList(
        data?.data?.images?.map((x) => {
          return { uid: x.uid, name: x["0"], url: x["1"], status: "done" };
        })
      );

      setValue(json2html(data?.data?.note));
      form.setFieldsValue({
        name: data?.data?.name,
        date: dayjs(moment(data?.data?.date).format(date)),
        note: json2html(data?.data?.note),
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  }, [form, REACT_APP_HOST_API, session_id, json2html]);

  useEffect(() => {
    fetchDetail();

    return () => {
      setNewData({});
      setDeletedImages([]);
      setNewFileList([]);
    };
  }, [fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    navigate(-1);
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/sub-activities/${session_id}`,
        newFileList.length === 0
          ? {
              ...values,
              note: html2json(value),
              date: values?.date?.format(date),
              activityId: activity_id,
              deleteImages: deletedImages.length > 0 && deletedImages.join(","),
            }
          : {
              ...values,
              note: html2json(value),
              date: values?.date?.format(date),
              activityId: activity_id,
              images: newFileList,
              deleteImages: deletedImages.length > 0 && deletedImages.join(","),
            },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success(data.message);
      setFetchSession(true);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // image upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <div className="table-header">
        <span></span>
        <Space>
          <Button onClick={onCancelModal}>Batal</Button>
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Submit
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <Form.Item name="name" label="Nama" rules={[{ min: 3 }]}>
          <Input onChange={({ target: { value } }) => (newData.name = value)} />
        </Form.Item>
        <Form.Item name="date" label="Tanggal">
          <DatePicker onChange={(e) => (newData.date = e)} />
        </Form.Item>
        <Form.Item label="Gambar(bukti kegiatan)">
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            beforeUpload={(file) => {
              if (file.size <= 2000000) {
                setFileList([...fileList, file]);
                setNewFileList([...newFileList, file]);
                return false;
              }
              if (file.size > 2000000) {
                message.error("Maks File 2MB");
              }
            }}
            onRemove={(file) => {
              setDeletedImages([...deletedImages, file?.name]);
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
            }}
            onChange={(file) => {
              if (file?.file.size > 2000000) return;
              else setFileList(file.fileList);
            }}
            fileList={fileList}
          >
            {fileList.length >= 3 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item name="note" label="Catatan" rules={[{ min: 3 }]}>
          <RichTextEditor
            value={value}
            onChange={setValue}
            id="rte"
            controls={[
              ["bold", "italic", "underline", "link"],
              ["h1", "h2", "h3"],
              ["unorderedList", "orderedList"],
              ["alignLeft", "alignCenter", "alignRight"],
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditSubActivity;
