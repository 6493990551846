import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useActivityDetail = (id, enabled) => {
  return useQuery(
    ["get-activity-detail", id],
    () => GetDetail(`/activity/${id}`),
    { enabled }
  );
};
