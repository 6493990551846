import React from 'react'
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
// import './Billings.css'
import AlokasiPembayaranLogic from './AlokasiPembayaranLogic'
import BayarAlokasi from '../bayar/BayarAlokasi';

const AlokasiPembayaran = () => {
   const { columns,
      dataSource,
      btnBayar,
      isLoading,
      isFetching,
      pagination,
      handleChange,
      keyword,
      rowSelection,
      setIsBayar,
      isBayar,
      onCancel,
      selectedData,
      onCreate
   } = AlokasiPembayaranLogic()

   return (
      <div>
         <div className="table-header">
            <h1>Alokasi Pembayaran</h1>
            <Space>
               <Button
                  hidden={!btnBayar}
                  type='primary'
                  onClick={() => { setIsBayar(true) }}
                  disabled={rowSelection.selectedRowKeys.length === 0 || (btnBayar && btnBayar.type === "disabled")}
               >
                  Bayar
               </Button>
            </Space>
         </div>
         <div className="filter-search">
            <Input
               prefix={<SearchOutlined />}
               value={keyword}
               onChange={handleChange}
               placeholder="Cari Billings"
               className="search-input-billings"
               style={{ width: "100%" }}
            />
         </div>
         <Table
            size="small"
            tableLayout="auto"
            columns={columns}
            loading={isLoading || isFetching}
            dataSource={dataSource}
            pagination={pagination}
            rowSelection={rowSelection}
            scroll={{
               y: "50vh",
               x: 800,
            }}
         />
         <BayarAlokasi
            show={isBayar}
            rowSelection={selectedData}
            onCancel={onCancel}
            onCreate={onCreate}
         />
      </div>
   )
}

export default AlokasiPembayaran