import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const DataRekeningTab = () => {
  const { candidate_id } = useParams();
  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Nama Bank</th>
                <td>{dataInti.bank_name && dataInti.bank_name}</td>
              </tr>
              <tr>
                <th>Rekening Atas Nama</th>
                <td>
                  {dataInti.bank_account_owner && dataInti.bank_account_owner}
                </td>
              </tr>
              <tr>
                <th>No. Rekening</th>
                <td>
                  {dataInti.bank_account_number && dataInti.bank_account_number}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataRekeningTab;
