import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuSystem from "components/Auth/RequireMenuSystem";
import LayoutSystem from "Layouts/LayoutSystem/LayoutSystem";
import { PermissionProvider } from "pages/si-system/master-role/permissions/PermissionContext";
import AddStudentUserParent from "pages/si-system/master-user-parent/detail/create-student/AddStudentUserParent";
import DetailUserParent from "pages/si-system/master-user-parent/detail/DetailUserParent";
import UsersParent from "pages/si-system/master-user-parent/master/UsersParent";
import MasterUser from "pages/si-system/master-user/master/MasterUser";
import MasterSystem from "pages/si-system/MasterSystem";
import { lazy } from "react";

// lazy route system
const MasterModule = lazy(() =>
  import("pages/si-system/master-module/module/master/MasterModule")
);
const MenusModule = lazy(() =>
  import("pages/si-system/master-module/menus/master/MenusModule")
);
const MasterFunction = lazy(() =>
  import("pages/si-system/master-module/functions/master/MasterFunction")
);
const MasterRole = lazy(() =>
  import("pages/si-system/master-role/role/master/MasterRole")
);
const Permissions = lazy(() =>
  import("pages/si-system/master-role/permissions/Permissions")
);

export const DataRoutesSytem = [
  {
    path: "system",
    element: (
      <RequireAuth>
        <RequireMenuSystem>
          <LayoutSystem content={<MasterSystem />} />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/modules",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuModule">
          <LayoutSystem content={<MasterModule />} />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/modules/:module_id",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuModule">
          <LayoutSystem content={<MenusModule />} />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/modules/:module_id/:menu_id",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuModule">
          <LayoutSystem content={<MasterFunction />} />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/roles",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuRole">
          <LayoutSystem
            content={
              <PermissionProvider>
                <MasterRole />
              </PermissionProvider>
            }
          />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/roles/:role_name",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuRole">
          <LayoutSystem
            content={
              <PermissionProvider>
                <Permissions />
              </PermissionProvider>
            }
          />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/users",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuUsers">
          <LayoutSystem
            content={
              <PermissionProvider>
                <MasterUser />
              </PermissionProvider>
            }
          />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/users-parent",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuUsersParent">
          <LayoutSystem
            content={
              <PermissionProvider>
                <UsersParent />
              </PermissionProvider>
            }
          />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/users-parent/:id",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuUsersParent">
          <LayoutSystem
            content={
              <PermissionProvider>
                <DetailUserParent />
              </PermissionProvider>
            }
          />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
  {
    path: "system/users-parent/:id/add-student",
    element: (
      <RequireAuth>
        <RequireMenuSystem menu="mnuUsersParent">
          <LayoutSystem
            content={
              <PermissionProvider>
                <AddStudentUserParent />
              </PermissionProvider>
            }
          />
        </RequireMenuSystem>
      </RequireAuth>
    ),
  },
];
