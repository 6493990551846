import React from "react";

const criteriaList = [
  "Efektifitas dan efisiensi kerja",
  "Ketepatan waktu dalam menyelesaikan tugas",
  "Kemampuan mencapai target",
  "Tertib administrasi",
  "Inisiatif",
  "Kerjasama dan koordinasi antar bagian",
  "Prilaku",
  "Kedisiplinan",
  "Tanggung jawab & Loyalitas",
  "Koordinasi anggota",
  "Kontrol anggota",
  "Evaluasi dan Pembinaan anggota",
  "Delegasi tanggung jawab dan wewenang",
  "Kecepatan dan Ketepatan Pengambilan Keputusan",
];

const TReportContent = ({ data }) => {
  const findEmployeeDetail = (component, isDirect) =>
    data?.triwulanEmployeeDetail?.find(
      (x) =>
        x.assessment_component === component && x.direct_supervisor === isDirect
    )?.skor;

  const renderRow = (index, label, key) => (
    <tr key={index}>
      <td>{index}</td>
      <td>{label}</td>
      <td>{renderSkor(key, true, 15, 21)}</td>
      <td>{renderSkor(key, false, 15, 21)}</td>
      <td>{renderSkor(key, true, 10, 16)}</td>
      <td>{renderSkor(key, false, 10, 16)}</td>
      <td>{renderSkor(key, true, 5, 11)}</td>
      <td>{renderSkor(key, false, 5, 11)}</td>
      <td>{renderSkor(key, true, 0, 6)}</td>
      <td>{renderSkor(key, false, 0, 6)}</td>
    </tr>
  );

  const renderSkor = (key, isDirect, value1, value2) => {
    const score = findEmployeeDetail(key, isDirect);
    return score > value1 && score < value2 ? score : "";
  };

  return (
    <table>
      <thead style={{ textAlign: "center" }}>
        <tr>
          <th rowSpan={3}>No</th>
          <th rowSpan={3}>Aspek Penilaian</th>
          <th colSpan={8}>Skor</th>
        </tr>
        <tr>
          <th colSpan={2}>A</th>
          <th colSpan={2}>B</th>
          <th colSpan={2}>C</th>
          <th colSpan={2}>D</th>
        </tr>
        <tr>
          <th colSpan={2}>16-20</th>
          <th colSpan={2}>11-15</th>
          <th colSpan={2}>6-10</th>
          <th colSpan={2}>0-5</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={10}>I. Aspek Teknis Pekerjaan</td>
        </tr>
        {criteriaList
          .slice(0, 3)
          .map((criteria, index) => renderRow(index + 1, criteria, criteria))}
      </tbody>
      <tbody>
        <tr>
          <td colSpan={10}>II. Aspek Non Teknis</td>
        </tr>
        {criteriaList
          .slice(3, 6)
          .map((criteria, index) => renderRow(index + 1, criteria, criteria))}
      </tbody>
      <tbody>
        <tr>
          <td colSpan={10}>III. Aspek Kepribadian</td>
        </tr>
        {criteriaList
          .slice(6, 9)
          .map((criteria, index) => renderRow(index + 1, criteria, criteria))}
      </tbody>
      <tbody>
        <tr>
          <td colSpan={10}>IV. Aspek Kepemimpinan</td>
        </tr>
        {criteriaList
          .slice(9)
          .map((criteria, index) => renderRow(index + 1, criteria, criteria))}
      </tbody>
    </table>
  );
};

export default TReportContent;
