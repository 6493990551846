import { Form, Input } from "antd";
import React from "react";

const TabDataLainnya = ({ newData }) => {
  return (
    <div className="double-form">
      <div>
        <Form.Item label="Kebutuhan Khusus" name="special_needs">
          <Input
            onChange={({ target: { value } }) =>
              (newData.special_needs = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Anak Ke"
          name="child_no"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 2, message: "Maksimal 2 Karakter" },
          ]}
        >
          <Input
            onChange={({ target: { value } }) => (newData.child_no = value)}
          />
        </Form.Item>

        <Form.Item
          label="Jumlah Saudara"
          name="siblings"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 2, message: "Maksimal 2 Karakter" },
          ]}
        >
          <Input
            onChange={({ target: { value } }) => (newData.siblings = value)}
          />
        </Form.Item>

        <Form.Item
          label="No KK"
          name="family_card_no"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newData.family_card_no = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Berat /Kg"
          name="weight"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) => (newData.weight = value)}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Tinggi /Cm"
          name="height"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) => (newData.height = value)}
          />
        </Form.Item>

        <Form.Item
          label="Lingkar Kepala /Cm"
          name="head_circumference"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newData.head_circumference = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Latitude"
          name="address_lat"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka dan (.)",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) => (newData.address_lat = value)}
          />
        </Form.Item>

        <Form.Item
          label="Longitude"
          name="address_long"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka dan (.)",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) => (newData.address_long = value)}
          />
        </Form.Item>

        <Form.Item
          label="Jarak Rumah /Km"
          name="distance_to_school_in_km"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newData.distance_to_school_in_km = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Alat Transportasi"
          name="transportation"
          rules={[{ max: 40 }]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newData.transportation = value)
            }
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabDataLainnya;
