import { RightOutlined } from "@ant-design/icons";
import { Button, Divider, Space } from "antd";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MasterExecutive.css";

const MasterExecutive = () => {
  const [dataMenuExecutive, setDataMenuExecutive] = useState([]);

  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchDashboard = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        const resultModule = data.data[0].role.permissions.modules;
        if (resultModule.find((x) => x.id === "mdlExecutiveSummary")) {
          const resultMenuExecutive =
            data.data[0].role.permissions.modules.find(
              (x) => x.id === "mdlExecutiveSummary"
            ).menus;

          setDataMenuExecutive(resultMenuExecutive);
        }
      } catch (error) {
        if (error.response.status === 401) {
          alert("Akun belum terverifikasi, silahkan hubungi IT / HRD");
          return navigate("/");
        } else {
          alert(error);
        }
      } finally {
      }
    };

    fetchDashboard();

    return () => cancelToken.cancel();
  }, [REACT_APP_HOST_API, navigate]);

  const handleClickMenu = (menu) => {
    encryptCookies("defaultExecutiveMenu", menu);
    navigate(menu.toLowerCase().split("nu")[1]);
  };

  return (
    <div>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ textTransform: "uppercase" }}
      >
        Selamat Datang di Dashboard Executive
      </Divider>
      <h3 style={{ textTransform: "uppercase" }}>Daftar Menu</h3>
      <Space direction="vertical">
        {dataMenuExecutive
          .filter((x) => x.id !== "mnuPPDB")
          .map((x) => (
            <Button
              key={x.id}
              type="primary"
              ghost
              icon={<RightOutlined />}
              onClick={() => handleClickMenu(x.id)}
            >
              {x.id.split("nu")[1]}
            </Button>
          ))}
      </Space>
    </div>
  );
};

export default MasterExecutive;
