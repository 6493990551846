import { Button, Form, message, Select, Table } from "antd";
import { useBudgetAllocationDetail } from "hooks/finance-hook/useBudgetAllocationDetail";
import { usePermission } from "hooks/usePermissions";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { renderAmount } from "../utils/utlisFunction";
import { LockFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";

export default function AlokasiManual() {
  const [isLoad, setIsLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectAccount, setSelectAccount] = useState({});
  const [saldo, setSaldo] = useState({
    total: 0,
    sisa: 0,
  });

  const [dataSource, setDataSource] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);
  const { id: idAllocation } = useParams();
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const navigate = useNavigate();

  // get detail budget allocation ---
  const { data, isLoading, isFetching, refetch } = useBudgetAllocationDetail(
    idAllocation,
    true
  );

  useEffect(() => {
    if (data) {
      setAccounts(data?.data?.accounts);

      setDataSource(
        data?.data?.billings?.map((x) => ({ ...x, key: x.id, alokasiBaru: 0 }))
      );
      setSelectAccount(data?.data?.accounts[0]);

      setSaldo({
        total: data?.data?.accounts[0].balance,
        sisa: data?.data?.accounts[0].balance,
      });

      form.setFieldsValue({
        account: data?.data?.accounts[0].id,
      });
    }
  }, [data, form, refetch]);

  const { data: dataPermission } = usePermission(
    "mdlFinance",
    "mnuAlokasiDana"
  );

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddManual");

  const columns = [
    {
      title: "",
      dataIndex: "account_id",
      key: "account_id",
      render: (text) => (
        <>
          {text !== selectAccount.id ? (
            <LockFilled style={{ marginRight: 0 }} />
          ) : (
            <LockFilled style={{ opacity: 0, marginRight: 0 }} />
          )}{" "}
        </>
      ),
    },
    {
      title: "Tipe",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Jumlah",
      dataIndex: "amount",
      key: "amount",
      render: (text) => renderAmount(text),
    },
    {
      title: "Sisa Bayar",
      dataIndex: "remaining_amount",
      key: "remaining_amount",
      render: (text) => renderAmount(text),
    },
    {
      title: "Alokasi Baru",
      dataIndex: "alokasiBaru",
      key: "alokasiBaru",
      render: (text) => renderAmount(text),
    },
    {
      title: "Jatuh Tempo",
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => dayjs(text).format("DD MMM YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (text === "paid") return "Lunas";
        if (text === "unpaid") return "Belum Lunas";
        if (text === "past_due") return "Terlambat";
        if (text === "paid_partial") return "Bayar Sebagian";
        else return text;
      },
    },
  ];

  const handleSelectTable = () => {
    let newSisa = saldo.total;

    const selectedData = rowSelected.map((selectedItem) => {
      const { remaining_amount } = selectedItem;
      const matchingData = dataSource.find((x) => x.id === selectedItem.id);
      const alokasiBaru = Math.min(remaining_amount, newSisa);
      const disabled = newSisa - remaining_amount < 0;

      if (selectedItem.account_id === selectAccount.id) newSisa -= alokasiBaru;
      return { ...matchingData, alokasiBaru, disabled, isSelected: true };
    });

    const notSelected = dataSource
      .filter(
        (item) =>
          !rowSelected.some((selectedItem) => selectedItem.id === item.id)
      )
      .map((item) => {
        const disabled =
          newSisa - item.remaining_amount < 0 ||
          item.account_id !== selectAccount.id;
        return { ...item, alokasiBaru: 0, disabled, isSelected: false };
      });

    const newData = [...selectedData, ...notSelected]
      .slice()
      .sort((a, b) => a.due_date.localeCompare(b.due_date));

    setDataSource(newData);

    setSaldo((prevSaldo) => ({ ...prevSaldo, sisa: newSisa }));
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      handleSelectTable();
    }
    // eslint-disable-next-line
  }, [rowSelected, selectAccount, refetch, data]);

  // useEffect(() => {
  //   let newSisa = saldo.total;

  //   const selectedData = rowSelected.map((selectedItem) => {
  //     const { remaining_amount } = selectedItem;
  //     const matchingData = dataSource.find((x) => x.id === selectedItem.id);
  //     const alokasiBaru = Math.min(remaining_amount, newSisa);
  //     const disabled = newSisa - remaining_amount < 0;

  //     // newSisa -= alokasiBaru;
  //     if (selectedItem.account_id === selectAccount.id) newSisa -= alokasiBaru;
  //     return { ...matchingData, alokasiBaru, disabled };
  //   });

  //   const notSelected = dataSource
  //     .filter(
  //       (item) =>
  //         !rowSelected.some((selectedItem) => selectedItem.id === item.id)
  //     )
  //     .map((item) => {
  //       const disabled =
  //         newSisa - item.remaining_amount < 0 ||
  //         item.account_id !== selectAccount.id;
  //       return { ...item, alokasiBaru: 0, disabled };
  //     });

  //   const newData = [...selectedData, ...notSelected]
  //     .slice()
  //     .sort((a, b) => a.type.localeCompare(b.type));

  //   setDataSource(newData);

  //   setSaldo((prevSaldo) => ({ ...prevSaldo, sisa: newSisa }));

  //   // eslint-disable-next-line
  // }, [rowSelected, selectAccount]);

  const handleRowSelectChange = (selectedRowKeys, selectedRows) => {
    setRowSelected(selectedRows);
  };

  const handleSubmit = async () => {
    const payload = dataSource.filter((x) => x.isSelected === true);
    const allocations = payload.map((x) => ({
      id: x.id,
      value: x.alokasiBaru,
    }));

    try {
      setIsLoad(true);
      await axios.put(
        `${REACT_APP_HOST_API}/finance/budget-allocation/${idAllocation}`,
        {
          allocations,
        },
        { headers: { Authorization: `Bearer ${decryptCookies("token")}` } }
      );

      message.success("Berhasil Alokasi Manual");
      refetch();
      navigate("/finance/alokasidana");
    } catch (error) {
      console.log("error message:", error);
      alert("Gagal mengalokasikan dana!");
    } finally {
      setIsLoad(false);
    }
  };

  const onAccountChange = (id) => {
    const findAccount = accounts.find((x) => x.id === id);
    setSelectAccount(findAccount);
    setSaldo({
      total: findAccount.balance,
      sisa: findAccount.balance,
    });
  };

  return (
    <div>
      <div className="table-header">
        <h1>Alokasi Manual</h1>
      </div>
      <Form form={form}>
        <table className="detail-teachers__table">
          <tbody>
            <tr>
              <th>Nomor VA.</th>
              <td>
                :
                <Form.Item name="account" style={{ margin: 0, padding: 0 }}>
                  <Select
                    style={{ width: "200px" }}
                    onChange={(e) => onAccountChange(e)}
                  >
                    {data?.data?.accounts.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.number}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </td>
            </tr>

            <tr>
              <th>Nama</th>
              <td>: {data?.data?.name}</td>
            </tr>
            <tr>
              <th>NISN</th>
              <td>: {data?.data?.nisn}</td>
            </tr>
            <tr>
              <th>Saldo</th>
              <td>: {renderAmount(saldo.total)}</td>
            </tr>
            <tr>
              <th>Saldo Baru</th>
              <td>: {renderAmount(saldo.sisa)}</td>
            </tr>
          </tbody>
        </table>
      </Form>

      <Table
        loading={isLoading || isFetching}
        rowSelection={{
          onChange: handleRowSelectChange,
          preserveSelectedRowKeys: true,
          getCheckboxProps: (record) => ({
            disabled: record.disabled,
          }),
          hideSelectAll: true,
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="button-container">
        <Button
          loading={isLoad}
          onClick={handleSubmit}
          style={{ width: "100%" }}
          hidden={rowSelected.length === 0 || !btnAdd}
          disabled={rowSelected.length === 0 || btnAdd?.type === "disabled"}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
