import React, { useEffect, useState } from "react";
import "./MasterPpdb.css";
import CountPpdb from "./component-dashboard/CountPpdb";
import StudentCome from "./component-dashboard/StudentCome";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Skeleton } from "antd";

//* halaman dashboard
const MasterPpdb = () => {
  const { REACT_APP_HOST_API } = process.env;
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        // { cancelToken: cancelToken.token }
      );

      await setDatas(data.data[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {/* tampil di student-candidate */}
          {datas?.role_name === "student_candidate" ? (
            <StudentCome datas={datas} />
          ) : null}

          {/* tampil admin */}
          {datas?.role_name !== "student_candidate" ? <CountPpdb /> : null}
        </>
      )}
    </div>
  );
};

export default MasterPpdb;
