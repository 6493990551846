import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useTheacresSubject = (
  dataTable,
  keyword,
  sessionName,
  optionsValue,
  dateIn,
  dateOut,
  foundationId,
  enabled
) => {
  return useQuery(
    [
      "get-theachers-presence-subject",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      sessionName,
      optionsValue,
      dateIn,
      dateOut,
      foundationId,
    ],
    () =>
      GetDetail(
        `/academics/teacher-attendances?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&fromDate=${dateIn}&toDate=${dateOut}&session=${sessionName}&className=${optionsValue.kelas}&subject=${optionsValue.pelajaran}&foundationId=${foundationId}`
      ),
    {
      enabled,
    }
  );
};
