import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Table, Tooltip } from "antd";
import AddPelajaran from "../create/AddPelajaran";
import EditPelajaran from "../edit/EditPelajaran";
import DetailPelajaran from "../detail/DetailPelajaran";
import { Popconfirm, Space, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePelajaranPagination } from "hooks/pelajaran-hook/usePelajaranPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Pelajaran = () => {
  const [dataId, setDataId] = useState("");
  const [showAddPelajaran, setShowAddPelajaran] = useState(false);
  const [showEditPelajaran, setShowEditPelajaran] = useState(false);
  const [showDetailPelajaran, setShowDetailPelajaran] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const { data, isLoading, isFetching, refetch } = usePelajaranPagination(
    dataTable,
    keyword,
    foundationId
  );

  // fetch classes permission
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuSubjects");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const onCreate = useCallback(() => {
    setShowAddPelajaran(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowEditPelajaran(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowAddPelajaran(false);
    setShowEditPelajaran(false);
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const navigate = useNavigate();

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddPelajaran");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditPelajaran");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeletePelajaran");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailPelajaran");

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 50 : 40,
      fixed: "left",
    },
    // { title: 'Nama', dataIndex: 'name', align: 'left' },
    {
      title: "Nama",
      dataIndex: "id",
      align: "left",
      width: 150,
      fixed: "left",
      render: (id, data) => {
        return (
          <>
            {data.is_extracurricular ? (
              <Space>
                {data.name}
                <Tag color="green">Ekskul</Tag>
              </Space>
            ) : (
              <>{data.name}</>
            )}
          </>
        );
      },
    },
    {
      title: "Yayasan",
      dataIndex: "foundationName",
      hidden: !isSuperAdmin,
    },
    { title: "Deskripsi", dataIndex: "description", align: "left" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, data) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/academics/subjects/", dataId, refetch });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditPelajaran(true);
                }
              }}
              disabled={btnEdit?.type === "disabled"}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (data.is_extracurricular) {
                  navigate(`/academic/subjects/${id}`);
                } else {
                  setDataId(id);
                  setShowDetailPelajaran(true);
                }
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        key: x.id,
        index: i + 1,
        foundationName: x.foundation?.name,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Pelajaran</h1>
        <Button
          type="primary"
          hidden={!btnAdd}
          onClick={() => setShowAddPelajaran(true)}
          disabled={btnAdd && btnAdd.type === "disabled"}
        >
          Tambah Pelajaran
        </Button>
      </div>
      <div
        style={{
          maxWidth: 700,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: 10,
        }}
      >
        <Input
          value={keyword}
          onChange={handleChange}
          prefix={<SearchOutlined />}
          placeholder="cari nama pelajaran"
        />
        {roles.some((x) => x === "super_admin") && (
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => setFoundationId(x)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">
                ALL
              </Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        )}
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
      />
      <AddPelajaran
        onCreate={onCreate}
        onCancel={onCancel}
        show={showAddPelajaran}
      />
      <EditPelajaran
        id={dataId}
        onUpdate={onUpdate}
        onCancel={onCancel}
        show={showEditPelajaran}
      />
      <DetailPelajaran
        show={showDetailPelajaran}
        onCancel={() => setShowDetailPelajaran(false)}
        id={dataId}
      />
    </div>
  );
};

export default Pelajaran;
