import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Skeleton,
} from "antd";
import EditProsemDetailLogic from "./EditProsemDetailLogic";

const EditProsemDetail = (props) => {
  const {
    form,
    dataKI,
    newData,
    loading,
    isLoading,
    dataMetode,
    onSubmit,
    onCancelModal,
  } = EditProsemDetailLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      closable={false}
      open={props.open}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      style={{ top: 20 }}
    >
      {isLoading ? (
        new Array(5)
          .fill(null)
          .map((_, index) => <Skeleton key={index} active />)
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item label="Kompetensi Inti" name="kompetensiIntiId">
            <Select onChange={(e) => (newData.kompetensiIntiId = e)}>
              {dataKI?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.nama}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Kompetensi Dasar" name="kompetensiDasar">
            <Input
              onChange={({ target: { value } }) =>
                (newData.kompetensiDasar = value)
              }
            />
          </Form.Item>
          <Form.Item label="Bab" name="kompetensiDasarIndex">
            <InputNumber
              min={1}
              onChange={(e) => (newData.kompetensiDasarIndex = e)}
            />
          </Form.Item>
          <Form.Item label="Pertemuan ke" name="pertemuan">
            <InputNumber min={1} onChange={(e) => (newData.pertemuan = e)} />
          </Form.Item>
          <Form.Item label="Nama Materi" name="materi">
            <Input
              onChange={({ target: { value } }) => (newData.materi = value)}
            />
          </Form.Item>
          <Form.Item label="Metode Pengambilan Nilai" name="metode">
            <Select onChange={(e) => (newData.metode = e)}>
              {dataMetode?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.nama}>
                  {x.nama}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="kategori1" valuePropName="checked">
            <Checkbox
              // onChange={(e) => console.log(e.target.checked)}
              onChange={({ target: { checked } }) =>
                (newData.kategori1 = checked)
              }
            >
              Penting untuk keselamatan dunia dan akhirat
            </Checkbox>
          </Form.Item>

          <Form.Item name="kategori2" valuePropName="checked">
            <Checkbox
              onChange={({ target: { checked } }) =>
                (newData.kategori2 = checked)
              }
            >
              Penting sebagai bekal masa depan
            </Checkbox>
          </Form.Item>

          <Form.Item name="kategori3" valuePropName="checked">
            <Checkbox
              onChange={({ target: { checked } }) =>
                (newData.kategori3 = checked)
              }
            >
              Penting untuk melanjutkan jenjang berikutnya
            </Checkbox>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditProsemDetail;
