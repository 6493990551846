import React from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Skeleton,
  Table,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./AddTeachingJournal.css";
import AddPresencePerSubjectLogic from "./AddPresencePerSubjectLogic";
import dayjs from "dayjs";
import moment from "moment";

const AddPresencePerSubject = () => {
  const {
    openModal,
    setOpenModal,
    recordColumn,
    handleDescChange,
    // getSessionById,
    teacherId,
    btnAdd,
    posting,
    form,
    dataGuru,
    dataPelajaran,
    setTeacherId,
    dataSession,
    columns,
    handleSubmit,
    idProsem,
    //* note: untuk mengatur waktu dateOut + 80 menit
    // handleDateInChange,
    // dataSource,
    sourceStudents,
    isLoadStudentList,
    isFetchStudentList,
    isloadPelajaran,
    dataProsem,
    setSubjectId,
    subjectId,
    dataProsemDetail,
    isLoadProsemDetail,
    setIdProsem,
    setKeyword,
    isEkskul,
    setStatusTeach,
    statusTeach,
    isLoadProsem,
    materiProsem,
    setMateriProsem,
    isGuruLogin,
    // filteredDataSource,
  } = AddPresencePerSubjectLogic();

  const handleChangeSubject = (value) => {
    setSubjectId(value);
  };

  const style = {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: 5,
  };

  return (
    <div>
      <div className="table-header">
        <h1>Form Jurnal Mengajar</h1>
      </div>
      <Form layout="vertical" form={form}>
        {posting ? (
          <Skeleton active />
        ) : (
          <>
            <div style={style}>
              <Form.Item
                label="Guru"
                name="teacherId"
                rules={[{ required: true, message: "Harap diisi" }]}
              >
                <Select
                  disabled={isGuruLogin}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setTeacherId(value)}
                >
                  {dataGuru?.data?.map((guru) => (
                    <Select.Option key={guru.id} value={guru.id}>
                      {guru.employee.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Mata Pelajaran"
                name="subjectId"
                rules={[{ required: true, message: "Harap diisi" }]}
              >
                <Select
                  placeholder="Pilih Pelajaran"
                  disabled={isloadPelajaran}
                  loading={isloadPelajaran}
                  onChange={(value) => handleChangeSubject(value)}
                >
                  {dataPelajaran?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.class?.name
                        ? `${x.subject?.name} - ${x.class?.name}`
                        : x.subject?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Sesi"
                name="sessionId"
                rules={[{ required: true, message: "Harap diisi" }]}
              >
                <Select
                  // * untuk mengatur waktu sesuai sesi (dikomen dulu sapa tau entar kepake hehe...)
                  // onChange={(e) => {
                  //   getSessionById(e);
                  // }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={isEkskul}
                >
                  {dataSession?.data?.data?.map((session) => (
                    <Select.Option key={session.id} value={session.id}>
                      {session.session}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="date_in"
                label="Waktu masuk"
                initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD "))}
                rules={[
                  {
                    required: true,
                    message: "Harap masukan tanggal dan waktu dimulai",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                //* note: untuk mengatur waktu dateOut + 80 menit
                // onChange={(e) => handleDateInChange(e)}
                />
              </Form.Item>

              <Form.Item
                initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
                name="date_out"
                label="Waktu keluar"
                rules={[{ required: true, message: "Harap diisi" }]}
              //* note: untuk mengatur waktu dateOut + 80 menit
              // initialValue={dayjs(
              //   moment(new Date())
              //     .add(80, "minutes")
              //     .format("YYYY-MM-DD HH:mm:ss")
              // )}
              >
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>

              <Form.Item label="Prosem" name="prosem">
                <Select
                  onChange={(value) => setIdProsem(value)}
                  disabled={isLoadProsem || !subjectId}
                  loading={isLoadProsem}
                >
                  <Select.Option value="">----------</Select.Option>
                  {isGuruLogin
                    ? dataProsem?.data?.map((x) => (
                      <Select.Option key={x.id} value={x.id}>
                        {x.mapel?.name} - {x.teachers?.employee?.name}
                      </Select.Option>
                    ))
                    : dataProsem?.data?.map((x) => {
                      if (x.teacher_id === teacherId) {
                        return (
                          <Select.Option key={x.id} value={x.id}>
                            {x?.class?.name} - {x?.mapel?.name}
                          </Select.Option>
                        );
                      }
                      return null;
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Materi Prosem" name="programSemesterDetailId">
                <Select
                  disabled={isLoadProsemDetail || !idProsem}
                  loading={isLoadProsemDetail}
                  onChange={(value) => setMateriProsem(value)}
                >
                  <Select.Option value="">----------</Select.Option>
                  {dataProsemDetail?.data?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.materi}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Materi"
                name="material"
                rules={[
                  statusTeach !== "not_teach" && { required: materiProsem === "", message: "Harap diisi" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                rules={[statusTeach !== "not_teach" && { required: true, message: "Harap diisi" }]}
                label="Catatan Kelas"
                name="catatanKelas"
              // style={{ width: "50%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: "Harap diisi" }]}
              >
                <Radio.Group
                  // defaultValue="teach"
                  size="small"
                  buttonStyle="solid"
                  onChange={(e) => setStatusTeach(e.target.value)}
                >
                  <Radio.Button value="teach">Mengajar</Radio.Button>
                  <Radio.Button value="not_teach">Tidak Mengajar</Radio.Button>
                  <Radio.Button value="exam">Ujian</Radio.Button>
                  <Radio.Button value="homework">Tugas </Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                hidden={statusTeach !== "not_teach"}
                name="reason_not_teach"
                label="Alasan tidak mengajar"
                rules={[statusTeach === "not_teach" && { required: true, message: "Harap diisi" }]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                name="post_test"
                label="Post test"
                style={{ marginBottom: "0px" }}
              >
                <Radio.Group
                  size="small"
                  buttonStyle="solid"
                // defaultValue={false}
                >
                  <Radio.Button value={true}>Ya</Radio.Button>
                  <Radio.Button value={false}>Tidak</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>

            {/* table and search in here --- */}
            {statusTeach !== "not_teach" && (
              <>
                <div className="search-wrapper filter-wrapper">
                  <Input
                    prefix={<SearchOutlined />}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder=" cari nama murid"
                  />
                </div>
                <Table
                  style={{ marginBottom: 20 }}
                  size={window.innerWidth > 1600 ? "middle" : "small"}
                  scroll={{
                    y: "50vh",
                    // x: 800,
                  }}
                  pagination={false}
                  tableLayout="auto"
                  dataSource={subjectId ? sourceStudents : null}
                  columns={columns}
                  loading={isLoadStudentList || isFetchStudentList}
                />
              </>
            )}
          </>
        )}

        <div className="button-container">
          <Button
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            loading={posting}
            type="primary"
            onClick={() => handleSubmit()}
          >
            Simpan
          </Button>
        </div>

        {/* modal deskripsi --- */}
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          onOk={() => setOpenModal(false)}
          closable={false}
          okText="Simpan"
          cancelText="Kembail"
          title="Deskripsi"
        >
          <Form.Item name={`description_${recordColumn.id}`}>
            <Input
              placeholder="Description ..."
              onChange={(e) =>
                handleDescChange(recordColumn.id, e.target.value)
              }
            />
          </Form.Item>
        </Modal>
      </Form>
    </div>
  );
};

export default AddPresencePerSubject;
