import React from "react";

const Jadwal = () => {
  return (
    <div>
      <h1>HALAMAN JADWAL PPDB</h1>
    </div>
  );
};

export default Jadwal;
