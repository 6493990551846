import Login from "components/Auth/Auth";
import ForgotPassword from "components/Auth/Forgot-Password/ForgotPassword";
import NewPassword from "components/Auth/Forgot-Password/NewPassword";
import OtpVerification from "components/Auth/Forgot-Password/OtpVerification";
import RequireAuth from "components/Auth/RequireAuth";
import RequireForgotPassword from "components/Auth/RequireForgotPassword";
import RequireLogin from "components/Auth/RequireLogin";
import Page404 from "pages/error-pages/page-404/Page404";
import PermissionError from "pages/error-pages/permission-error/PermissionError";
import { lazy } from "react";

const Home = lazy(() => import("pages/home-page/Home"));

export const DataRoutesUniversal = [
  {
    path: "/",
    element: (
      <RequireLogin>
        <Login />
      </RequireLogin>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <RequireLogin>
        <ForgotPassword />
      </RequireLogin>
    ),
  },
  {
    path: "/create-password/:token",
    element: (
      <RequireLogin>
        <RequireForgotPassword>
          <NewPassword />
        </RequireForgotPassword>
      </RequireLogin>
    ),
  },
  {
    path: "/otp-verification",
    element: (
      <RequireLogin>
        <RequireForgotPassword>
          <OtpVerification />
        </RequireForgotPassword>
      </RequireLogin>
    ),
  },
  {
    path: "home",
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: "permission-error",
    element: <PermissionError />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];
