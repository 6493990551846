import { Layout } from "antd";
import styles from "./LayoutScan.module.css";
import DynamicTitle from "components/UI/DynamicTitle";

const { Content } = Layout;

const LayoutHome = ({ children }) => {
  return (
    <Layout>
      <DynamicTitle />
      <Content className={styles.content}>{children}</Content>
    </Layout>
  );
};

export default LayoutHome;
