import React from "react";
import GrafikRecapsDaily from "../component-chart/GrafikRecapsDaily";

export default function ChartRecapsDaily() {
  return (
    <div>
      <GrafikRecapsDaily />
    </div>
  );
}
