import { Modal } from 'antd';

const DetailFoundation = ({ foundation, show, onClose }) => {

   return (
      <Modal
         title="Detail Foundation"
         open={show}
         onCancel={onClose}
         footer={null}
      >
         <div
            style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center"
            }}
         >
            <img src={foundation.logo} alt="foundation logo"
               style={
                  {
                     width: "360px",
                     height: "360px",
                     objectFit: "cover",

                  }
               }
            />
         </div>
         <table className="detail-teachers__table">
            <tbody>
               <tr>
                  <th>Nama</th>
                  <td>: {
                     foundation.name
                  }</td>
               </tr>
               <tr>
                  <th>Deskripsi</th>
                  <td>: {
                     foundation.description
                  }</td>
               </tr>
               <tr>
                  <th>Website</th>
                  <td>: {
                     foundation.website
                  }</td>
               </tr>
               <tr>
                  <th>Email</th>
                  <td>: {
                     foundation.email
                  }</td>
               </tr>
               <tr>
                  <th>Phone</th>
                  <td>: {
                     foundation.phone
                  }</td>
               </tr>
               <tr>
                  <th>Alamat</th>
                  <td>: {
                     foundation.address
                  }</td>
               </tr>
            </tbody>
         </table>
      </Modal>
   );
};

export default DetailFoundation; 