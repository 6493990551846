import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useClassDetail = (id, enabled) => {
  return useQuery(
    ["get-class-detail", id],
    () => GetDetail(`/academics/classes/${id}`),
    {
      enabled,
    }
  );
};
