const { createContext, useState } = require("react");

export const DivisionContext = createContext();

export const DivisionProvider = (props) => {
  const [division, setDivision] = useState({ divisionId: "", title: "" });

  return (
    <DivisionContext.Provider value={{ division, setDivision }}>
      {props.children}
    </DivisionContext.Provider>
  );
};
