import { Form, Input, Modal, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useState } from "react";

const AddGelombang = ({ open, setOpen }) => {
  const { REACT_APP_HOST_API } = process.env;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    const val = await form.validateFields();

    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/ppdb/settings/batches`,
        val,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      console.log(data);
      form.resetFields();
      setOpen();
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      open={open}
      onCancel={setOpen}
      onOk={handleSubmit}
      okText="Simpan"
      cancelText="Batal"
      closable={false}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Nama" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Tahun Ajar" name="academic_year">
          <Select
            options={[
              {
                value: "2021 - 2022",
                label: "2021 - 2022",
              },
              {
                value: "2022 - 2023",
                label: "2022 - 2023",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Deskripsi" name="description">
          <Input />
        </Form.Item>
        {/* <Form.Item label="Aktif" name="active">
          <Radio.Group>
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AddGelombang;
