import { Form, Modal, Radio, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import ExcelJS from "exceljs";


const GenerateBrodcastModal = (props) => {
   const [form] = Form.useForm();
   const [posting, setPosting] = useState(false);
   const gradeList = ["X", "XI", "XII"];
   const { REACT_APP_HOST_API } = process.env;


   const onCancelModal = () => {
      props.onCancelModal();
      form.resetFields();
   }

   const setCellBorders = (sheet, row, columns, border) => {
      columns.forEach((column) => {
         sheet.getCell(`${column}${row}`).border = border;
      });
   };

   const handleSubmit = async () => {
      setPosting(true);
      try {
         const values = await form.validateFields();
         const { data } = await axios.post(
            REACT_APP_HOST_API + `/finance/billings/generate-broadcast-data`,
            values,
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
         );
         const workbook = new ExcelJS.Workbook();
         const sheet = workbook.addWorksheet("My Sheet");

         // style 
         const fullBorder = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
         };

         sheet.columns = [
            {
               header: "Nama",
               key: "name",
            },
            {
               header: "Kelas",
               key: "class",
            },
            {
               header: "Program",
               key: "program",
            },
            {
               header: "Link",
               key: "link",
            },
            {
               header: "No VA",
               key: "nomorVa",
            },
            {
               header: "No HP korespondensi",
               key: "noHp",
            },
         ];

         // style header
         let currentRow = 1;
         setCellBorders(sheet, currentRow, ['A', 'B', 'C', 'D', 'E', 'F'], fullBorder);
         currentRow++;

         data?.data?.forEach((item) => {
            sheet.addRow({
               name: item?.name,
               class: item?.class.name,
               program: item?.program,
               link: item?.link,
               nomorVa: item?.accounts[0]?.number,
               noHp: item?.phone,
            });
            setCellBorders(sheet, currentRow, ['A', 'B', 'C', 'D', 'E', 'F'], fullBorder);
            currentRow++;
         });

         // Set the column width
         sheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
               const columnLength = cell.value ? cell.value.toString().length : 10;
               if (columnLength > maxLength) {
                  maxLength = columnLength;
               }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
         });

         workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
               type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `brodcast-wa-${values.grade}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
         });
         message.success('Berhasil generate broadcast');
         props.onCancelModal();
         form.resetFields();
      } catch (error) {
         message.error(error.message);
      }
      setPosting(false);
   };


   return (
      <Modal
         okText="Simpan"
         cancelText="Batal"
         onOk={handleSubmit}
         open={props.show}
         onCancel={onCancelModal}
         okButtonProps={{ loading: posting }}
         title="Generate Broadcast"
      >
         <Form form={form} layout="vertical">
            <Form.Item
               name="grade"
               label="Tingkatan"
               rules={[{ required: true, message: "Harap diisi" }]}
            >
               <Radio.Group
                  size="small"
                  buttonStyle="solid"
               >
                  {gradeList.map((item, index) => (
                     <Radio.Button key={index} value={item}>
                        {item}
                     </Radio.Button>
                  ))}
               </Radio.Group>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default GenerateBrodcastModal;
