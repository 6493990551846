import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useRecapsBillingsList = (student_id, academic_year_id = "") => {
  return useQuery(["get-recaps-billings", student_id, academic_year_id], () =>
    GetPagination(
      `/finance/billings/recap-billing/${student_id}?academic_year_id=${academic_year_id}`
    )
  );
};
