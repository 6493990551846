import React, { useEffect, useState } from "react";
import { Modal, Form, InputNumber, Select, message } from "antd";
import { useDetailAccount } from "hooks/finance-hook/useDetailAccount";
import { useParams } from "react-router-dom";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";

export default function EditDetailAccount({ dataItem, open, setOpen }) {
  const [form] = Form.useForm();
  const [postData, setPostData] = useState({});
  const [isLoad, setIsLoad] = useState(false);

  const { id } = useParams();
  const { REACT_APP_HOST_API } = process.env;
  const { data } = useDetailAccount(id, open);

  useEffect(() => {
    if (open) {
      const newData = data?.data?.accountReferences?.filter((item) => {
        return item?.id === dataItem?.key;
      });

      form.setFieldsValue({
        type: newData[0]?.type,
        amount: newData[0]?.amount,
      });

      setPostData({});
    }

    // eslint-disable-next-line
  }, [open, data]);

  const onSubmit = async () => {
    try {
      await form.validateFields();

      if (Object.keys(postData).length === 0) {
        alert("Nothing has changed");
        return;
      }

      setIsLoad(true);

      await axios.put(
        `${REACT_APP_HOST_API}/finance/account-references/${dataItem.key}`,
        postData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success("Berhasil mengubah data");
      setOpen();
      form.resetFields();
    } catch (error) {
      console.log("error message:", error);
      if (
        error?.response?.data?.data[0]?.message === "unique validation failure"
      ) {
        message.error("Tipe sudah ada!");
      }
      message.error("Gagal mengubah data");
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        setOpen();
        form.resetFields();
      }}
      closable={false}
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      confirmLoading={isLoad}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Tipe" name="type">
          <Select onChange={(value) => (postData["type"] = value)}>
            <Select.Option value="spp">SPP</Select.Option>
            <Select.Option value="bp">BP</Select.Option>
            <Select.Option value="bwt">BWT</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Nominal Acuan" name="amount">
          <InputNumber
            formatter={(value) =>
              `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
            style={{ width: "100%" }}
            onChange={(value) => (postData["amount"] = value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
