import { Select, Tooltip } from "antd";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import React from "react";

export default function FilterFoundation({
  setFoundationId,
  isNotShowAll,
  isHiddenTooltip = false,
}) {
  const { data: dataFoundation } = useFoundationList();

  return (
    <Tooltip Tooltip title={isHiddenTooltip ? "" : "Yayasan"}>
      <Select
        placeholder="Pilih Yayasan"
        style={{ minWidth: 150 }}
        onChange={(e) => setFoundationId(e)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {!isNotShowAll && <Select.Option value="">Semua</Select.Option>}
        {dataFoundation?.data?.data?.map((x) => (
          <Select.Option
            key={x.id}
            value={x.id}
            className="select-option-foundation"
          >
            {x.name}
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  );
}
