import { Checkbox, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useState } from "react";

const AddClass = (props) => {
  const [posting, setPosting] = useState(false);

  const [form] = Form.useForm();
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  const { REACT_APP_HOST_API } = process.env;

  const handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields();
      setPosting(true);

      if (fieldsValue.isExtracurricular === undefined) {
        fieldsValue.isExtracurricular = false;
      }

      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/academics/subjects`,
        fieldsValue,
        { headers: { Authorization: `Bearer ${decryptCookies("token")}` } }
      );

      message.success(data.message);
      form.resetFields();
      props.onCreate();
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert("Input Field Error");
      }
    } finally {
      setPosting(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: posting }}
    >
      <Form form={form} layout="vertical">
        {(isSuperAdmin) &&
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          name="name"
          label="Nama Pelajaran"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Deskripsi">
          <Input />
        </Form.Item>
        <Form.Item name="isExtracurricular" valuePropName="checked">
          <Checkbox>Apakah merupakan ekstrakulikuler?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddClass;
