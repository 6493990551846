import { Form, Input, InputNumber, Modal, Select } from "antd";
import CreateRpnLogic from "./CreateRpnLogic";

const CreateRpn = (props) => {
  const {
    form,
    loading,
    disabled,
    dataMetode,
    dataProsem,
    dataProsemDetail,
    onSubmit,
    onCancelModal,
    onSelectProsem,
  } = CreateRpnLogic(props);

  return (
    <Modal
      style={{ top: 20 }}
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      open={props.open}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Pilih Program Semester"
          name="programSemesterId"
          rules={[{ required: true }]}
        >
          <Select onChange={onSelectProsem}>
            {dataProsem?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.mapel?.name} - {x.teachers?.employee?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Pilih Cakupan Materi"
          name="programSemesterDetailId"
          rules={[{ required: true }]}
        >
          <Select disabled={disabled}>
            {dataProsemDetail?.data?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.materi}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Metode Penilaian"
          name="metodePengambilanNilaiId"
          rules={[{ required: true }]}
        >
          <Select>
            {dataMetode?.data?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Topik/Materi"
          name="topik"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Pertemuan Ke"
          name="pertemuan"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item
          label="Persentase (1 - 100)"
          name="presentase"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} max={100} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateRpn;
