import { useCallback, useEffect, useState } from "react";
import { Column, Line, Pie } from "@ant-design/plots";

import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Navigate } from "react-router-dom";
import moment from "moment";

const Karyawan = () => {
  const [dataHarian, setDataHarian] = useState([]);
  const [dataBulanan, setDataBulanan] = useState([]);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API } = process.env;

  const fetchKehadiranKaryawan = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/charts/karyawan-kehadiran`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      data.dataBulanan.sort((a, b) => a.bulan.localeCompare(b.bulan));

      setDataHarian(data.dataHarian);
      setDataBulanan(data.dataBulanan);
    } catch (error) {
      if (error.response.status === 401) {
        if (
          error.response.statusText === "TOKEN" ||
          error.response.statusText === "UNVERIFIED"
        ) {
          return <Navigate to="/" />;
        } else {
          return <Navigate to="/permission-error" />;
        }
      }

      alert("Error " + error.message);
    }
    setLoading(false);
  }, [REACT_APP_HOST_API]);

  useEffect(() => {
    fetchKehadiranKaryawan();
  }, [fetchKehadiranKaryawan]);

  const data = [
    { type: "IT", value: 4 },
    { type: "HR", value: 2 },
    { type: "TU", value: 5 },
    { type: "FG College", value: 6 },
  ];

  const configPie = {
    appendPadding: 0,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    legend: {
      position: "bottom",
    },
    interactions: [{ type: "element-active" }],
  };

  const newDataHarian = dataHarian.map((x) => {
    return {
      ...x,
      tanggal: moment(x.tanggal).format("YYYY-MM-DD"),
      precentage: Number(x.precentage.split("%")[0]),
    };
  });

  const configLine = {
    data: newDataHarian,
    xField: "tanggal",
    yField: "precentage",
    label: { formatter: (x) => `${x.precentage}%` },
    point: {
      size: 3,
      shape: "circle",
    },
    tooltip: {
      showMarkers: false,
      formatter: (datum) => {
        return { name: "Kehadiran", value: datum.precentage + "%" };
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [{ type: "marker-active" }],
    yAxis: {
      grid: { line: { style: { stroke: "#ddd" } } },
      tickCount: 6,
      min: 0,
      max: 100,
      label: { formatter: (x) => `${x}%` },
    },
    xAxis: {
      grid: { line: { style: { stroke: "#ddd" } } },
      label: { style: {}, rotate: 5.7, offsetY: 15 },
    },
    smooth: true,
  };

  const newDataBulanan = dataBulanan.map((x) => {
    return {
      ...x,
      precentage: Number(x.precentage.split("%")[0]),
    };
  });

  const configColumn = {
    data: newDataBulanan,
    xField: "bulan",
    yField: "precentage",
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',
      style: { fill: "#FFFFFF" },
      formatter: (x) => `${x.precentage}%`,
    },
    yAxis: {
      grid: { line: { style: { stroke: "#ddd" } } },
      tickCount: 6,
      min: 0,
      max: 100,
      label: { formatter: (x) => `${x}%` },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    maxColumnWidth: 60,
  };

  return (
    <div>
      <div className="chart-wrapper1">
        <div className="chart-item">
          <h3>Karyawan Per Divisi</h3>
          <Pie
            {...configPie}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
        <div className="chart-item">
          <h3 style={{ textAlign: "center" }}>Kehadiran 7 Hari Terakhir</h3>
          <Line
            {...configLine}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
      </div>
      <div className="chart-wrapper">
        <div className="chart-item">
          <h3 style={{ textAlign: "center" }}>Kehadiran 3 Bulan Terakhir</h3>
          <Column
            {...configColumn}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Karyawan;
