import { DatePicker, Form, Input, Radio, Select } from "antd";
import React from "react";

const TabIdentitasSiswa = ({ newData }) => {
  return (
    <div className="double-form">
      <Form.Item
        label="Nama"
        name="full_name"
        rules={[
          {
            pattern: new RegExp("^[A-Za-z ]+$"),
            message: "Nama hanya dapat berupa huruf",
          },
          {
            required: true,
            message: "Nama harus diisi",
          },
          {
            min: 5,
            message: "Nama minimal 5 karakter",
          },
          {
            required: true,
            message: "data tidak boleh kosong",
          },
        ]}
        onChange={({ target: { value } }) => (newData["full_name"] = value)}
      >
        <Input disabled={true} />
      </Form.Item>

      <Form.Item
        label="NIK"
        name="nik"
        rules={[
          {
            pattern: new RegExp("^([0-9]*)$"),
            message: "Input hanya berupa angka",
          },
          { min: 16, message: "Minimal 16 karakter" },
          { max: 16, message: "Maksimal 16 karakter" },
          { required: true, message: "NIK tidak boleh kosong!" },
        ]}
        onChange={({ target: { value } }) => (newData["nik"] = value)}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        label="NIPD"
        name="nis"
        rules={[
          {
            pattern: new RegExp("^([0-9]*)$"),
            message: "input hanya berupa angka",
          },
          { min: 3, message: "minimal 3 karakter" },
        ]}
        onChange={({ target: { value } }) => (newData["nis"] = value)}
      >
        <Input />
      </Form.Item> */}

      <Form.Item
        label="NISN"
        name="nisn"
        rules={[
          {
            pattern: new RegExp("^([0-9]*)$"),
            message: "input hanya berupa angka",
          },
          { min: 3, message: "minimal 3 karakter" },
          { max: 15 },
          { required: true, message: "NISN tidak boleh kosong!" },
        ]}
        onChange={({ target: { value } }) => (newData["nisn"] = value)}
      >
        <Input disabled={true} />
      </Form.Item>

      <Form.Item
        label="Jenis Kelamin"
        name="gender"
        rules={[
          {
            required: true,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Radio.Group
          onChange={({ target: { value } }) => (newData.gender = value)}
        >
          <Radio value="male">Laki-laki</Radio>
          <Radio value="female">Perempuan</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Kota Lahir"
        name="birth_city"
        onChange={({ target: { value } }) => (newData["birth_city"] = value)}
        rules={[
          {
            required: true,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Tanggal Lahir"
        name="birth_day"
        rules={[
          {
            required: true,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <DatePicker onChange={(e) => (newData.birth_day = e)} />
      </Form.Item>

      <Form.Item
        label="Agama"
        name="religion"
        rules={[
          {
            required: true,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Select
          onChange={(a) => {
            newData.religion = a;
          }}
          // defaultValue="islam"
          options={[
            {
              value: "islam",
              label: "Islam",
            },
          ]}
        />
      </Form.Item>
    </div>
  );
};

export default TabIdentitasSiswa;
