import { DatePicker, Form, Input, Tooltip } from "antd";
import dayjs from "dayjs";

const PresenceFilter = (props) => {
  const [form] = Form.useForm();
  const { date, keyword, onChange, handleDateFromChange, handleDateToChange } =
    props;

  return (
    <Form form={form} layout="vertical">
      <div className="presence-filter">
        <Tooltip title="filter nama">
          <Form.Item style={{ marginBottom: 0 }} className="item-search">
            <Input
              value={keyword}
              onChange={onChange}
              placeholder="cari nama Karyawan"
            />
          </Form.Item>
        </Tooltip>
        <Tooltip title="Tanggal Awal">
          <Form.Item style={{ marginBottom: 0 }}>
            <DatePicker
              picker="date"
              format="YYYY-MM-DD"
              defaultValue={dayjs(date.from, "YYYY-MM-DD")}
              onChange={handleDateFromChange}
              style={{ padding: 4 }}
            />
          </Form.Item>
        </Tooltip>
        <Tooltip title="Tanggal Akhir">
          <Form.Item style={{ marginBottom: 0 }}>
            <DatePicker
              picker="date"
              format="YYYY-MM-DD"
              defaultValue={dayjs(date.to, "YYYY-MM-DD")}
              onChange={handleDateToChange}
              style={{ padding: 4 }}
            />
          </Form.Item>
        </Tooltip>
      </div>
    </Form>
  );
};

export default PresenceFilter;
