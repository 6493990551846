import { useParams } from "react-router-dom";

const { useState } = require("react");
const { default: IdentitasSiswaTab } = require("./Tab/IdentitasSiswaTab");
const { default: AlamatSiswaTab } = require("./Tab/AlamatSiswaTab");
const { default: KontakSiswaTab } = require("./Tab/KontakSiswaTab");
const { default: DataAkademikSiswaTab } = require("./Tab/DataAkademikSiswaTab");
const { default: DataRekeningTab } = require("./Tab/DataRekeningTab");
const { default: DataSiswaLainnyaTab } = require("./Tab/DataSiswaLainnyaTab");
const { default: DataAyahTab } = require("./Tab/DataAyahTab");
const { default: DataIbuTab } = require("./Tab/DataIbuTab");
const { default: DataWaliTab } = require("./Tab/DataWaliTab");

const DetialStudentLogic = () => {
  const [activeKey, setActiveKey] = useState("1");
  const { student_id } = useParams();

  const selectTap = (key) => {
    setActiveKey(key.toString());
  };

  const items = [
    {
      key: "1",
      label: `Identitas`,
      children: <IdentitasSiswaTab studentId={student_id} />,
    },
    {
      key: "2",
      label: `Alamat`,
      children: <AlamatSiswaTab studentId={student_id} />,
    },
    {
      key: "3",
      label: `Kontak`,
      children: <KontakSiswaTab />,
    },
    {
      key: "4",
      label: `Data akademik`,
      children: <DataAkademikSiswaTab />,
    },
    {
      key: "5",
      label: `Data Rekening`,
      children: <DataRekeningTab />,
    },
    {
      key: "6",
      label: `Data lainnya`,
      children: <DataSiswaLainnyaTab />,
    },
    {
      key: "7",
      label: `Data ayah`,
      children: <DataAyahTab />,
    },
    {
      key: "8",
      label: `Data ibu`,
      children: <DataIbuTab />,
    },
    {
      key: "9",
      label: `Data wali`,
      children: <DataWaliTab />,
    },
  ];
  return {
    activeKey,
    selectTap,
    items,
  };
};

export default DetialStudentLogic;
