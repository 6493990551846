import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useStudentPagination = ({
  dataTable,
  keyword,
  classId,
  isNew = "",
  foundationId = "",
  hasNisn,
}) => {
  return useQuery(
    [
      "get-student-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      classId,
      isNew,
      hasNisn,
      foundationId,
    ],
    () =>
      GetPagination(
        `/academics/students?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&mode=page&classId=${classId}&isGraduated=false&isNew=${isNew}&foundationId=${foundationId}&hasNisn=${hasNisn}`
      )
  );
};
