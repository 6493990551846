import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Navigate } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

export const DeleteApi = async ({ url, dataId, refetch }) => {
  try {
    const { data } = await axios.delete(REACT_APP_HOST_API + url + dataId, {
      headers: { Authorization: "Bearer " + decryptCookies("token") },
    });
    message.success(data.message, 2);
    refetch && refetch();
  } catch (error) {
    if (error.response.status === 401) {
      if (
        error.response.statusText === "TOKEN" ||
        error.response.statusText === "UNVERIFIED"
      ) {
        return <Navigate to="/" />;
      } else {
        return <Navigate to="/permission-error" />;
      }
    }

    if (error.response.status === 400) {
      if (error.response.data.error) {
        if (error.response.data.error.includes("violates foreign key")) {
          alert(
            "Error! Harap hapus terlebih dahulu semua isi di dalam data ini"
          );
          return;
        }

        alert(error.response.data.message);
        return;
      }
    }

    message.error(
      `Gagal menghapus data ( ${
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message
      } )`
    );
  }
};
