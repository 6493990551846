import { Button, Input, Popconfirm, Space, Table, Tag, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { ActivityContext } from "context/ActivityContext";
import { useMemberActivity } from "hooks/activity-hook/useMemberActivity";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddMemberActivity from "./add/AddMemberActivity";
import EditMemberActivity from "./edit/EditMemberActivity";
import { usePermission } from "hooks/usePermissions";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const MemberActivity = () => {
  const [keyword, setKeyword] = useState("");

  const {
    selectedRow,
    setMemberId,
    fetchActivity,
    setSelectedRow,
    setShowAddMember,
    setShowEditMember,
    setFetchActivity,
  } = useContext(ActivityContext);

  const { activity_id } = useParams();
  const { REACT_APP_HOST_API: api } = process.env;

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuActivity");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddMember");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditMember");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteMember");

  const { data, isFetching, refetch } = useMemberActivity(activity_id, keyword);

  useEffect(() => {
    refetch();

    return () => setFetchActivity(false);
  }, [keyword, fetchActivity, setFetchActivity, refetch]);

  const dataSource = data?.data?.map((x, i) => {
    return {
      ...x,
      index: i + 1,
      key: x.id,
      name: x.employee?.name,
    };
  });

  dataSource?.sort((a, b) => a.role.localeCompare(b.role));

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "center",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Nama", dataIndex: "name" },
    { title: "Role", dataIndex: "role" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Tag
            color="orange"
            hidden={!btnEdit}
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnEdit?.type === "disabled") return;
              else {
                setMemberId(id);
                setShowEditMember(true);
              }
            }}
          >
            Ubah
          </Tag>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedRow(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  const onDelete = async () => {
    try {
      const { data } = await axios.delete(api + "/activity-members/batch", {
        data: selectedRow?.map((x) => {
          return { member_id: x.id };
        }),
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      message.success(data.message);
      refetch();
      setSelectedRow([]);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="table-header">
        <h1>Member</h1>
        <Space>
          <Input
            allowClear
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
            placeholder="cari nama..."
          />
          <Popconfirm
            title="Yakin ingin menghapus data?"
            okText="Hapus"
            cancelText="Batal"
            disabled={
              selectedRow.length === 0 || btnDelete?.type === "disabled"
            }
            onConfirm={onDelete}
          >
            <Button
              danger
              type="primary"
              hidden={!btnDelete}
              disabled={
                selectedRow.length === 0 || btnDelete?.type === "disabled"
              }
            >
              Hapus
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            onClick={() => {
              if (btnAdd?.type === "disabled") return;
              else setShowAddMember(true);
            }}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <Table
        size="small"
        columns={columns}
        pagination={false}
        loading={isFetching}
        scroll={{ y: "35vh" }}
        dataSource={dataSource}
        rowSelection={{ ...rowSelection }}
        style={{ marginBottom: 20 }}
      />
      <AddMemberActivity />
      <EditMemberActivity />
    </>
  );
};

export default MemberActivity;
