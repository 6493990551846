import React, { useState, useEffect } from "react";

const IntervalOtp = ({ initialCount, onCountdownEnd }) => {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    if (count === 0) {
      onCountdownEnd();
      return;
    }

    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [count, onCountdownEnd]);

  return (
    <div>
      {count > 0 ? (
        <p
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          Kirim ulang OTP dalam:{" "}
          <span style={{ fontWeight: "bold", color: "#4096FF" }}>
            {" "}
            {count}{" "}
          </span>
          detik
        </p>
      ) : (
        <p>Anda bisa mengirim ulang OTP sekarang.</p>
      )}
    </div>
  );
};

export default IntervalOtp;
