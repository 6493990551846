import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import CreateDivision from "./create/CreateDivision";
import EditDivision from "./edit/EditDivision";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useDivisonPagination } from "hooks/divison-hook/useDivisionPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useUnitDetail } from "hooks/unit-hook/useUnitDetail";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const DivisiFoundation = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showEdit, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const employeeId = decryptCookies("employeeId");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some(
    (x) => x === "super_admin" || x === "admin_foundation"
  );

  const navigate = useNavigate();

  const { unit_id } = useParams();

  const { data: dataUnit } = useUnitDetail(unit_id);

  // fetch division pagination
  const {
    data: dataDivision,
    isLoading,
    isFetching,
    refetch,
  } = useDivisonPagination(dataTable, keyword);

  // fetch permission
  const { data: dataPermission } = usePermission(
    "mdlFoundation",
    "mnuDivisionFoundation"
  );

  const onCreate = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowUpdate(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowCreate(false);
    setShowUpdate(false);
  };

  const handleChange = async (param) => {
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddDivisionFDT");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditDivisionFDT");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteDivisionFDT"
  );
  const btnDetail = dataPermission?.find(
    (x) => x.id === "btnDetailDivisionFDT"
  );

  const dataSource = dataDivision?.data.data
    .slice(0, dataTable.per_page)
    .filter((data) => data.unit_id === unit_id)
    .map((data, index) => ({
      ...data,
      key: data.id,
      index: index + 1,
      lead: data.employees && data.employees[0]?.employee?.name,
      unitName: data.unit?.name,
    }));

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 60,
      fixed: "left",
    },
    { title: "Nama", dataIndex: "name", fixed: "left" },
    { title: "Unit", dataIndex: "unitName" },
    { title: "Deskripsi", dataIndex: "description" },
    { title: "Ketua", dataIndex: "lead" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, data) => {
        const isLead = data?.unit?.employeeUnits[0]?.employee_id === employeeId;

        return (
          <Space>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/division/", dataId: id, refetch })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete || (!isLead && !isSuperAdmin)}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit || (!isLead && !isSuperAdmin)}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowUpdate(true);
                  setDataId(id);
                }
              }}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail || (!isLead && !isSuperAdmin)}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") {
                  return;
                } else navigate(`${id}`);
              }}
            >
              Anggota
            </Tag>
          </Space>
        );
      },
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataDivision?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      }),
  };

  return (
    <>
      <div className="table-header">
        <h1>Daftar Divisi Unit {dataUnit?.data?.name}</h1>
        <Space>
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="cari nama divisi"
          />
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 800 }
            : window.innerHeight < 760
            ? { y: "50vh", x: 800 }
            : { y: "55vh", x: 800 }
        }
      />
      <CreateDivision
        onCancel={onCancel}
        onCreate={onCreate}
        show={showCreate}
      />
      <EditDivision
        id={dataId}
        onCancel={onCancel}
        onUpdate={onUpdate}
        show={showEdit}
      />
    </>
  );
};

export default DivisiFoundation;
