import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useProsemDetail } from "hooks/prosem-hook/useProsemDetail";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const EditProsemLogic = ({
  data: itemProsem,
  id,
  open,
  onUpdate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let teacherId = itemProsem?.teacher_id;

  const { REACT_APP_HOST_API: api } = process.env;

  const roles = decryptCookies("role").split(",");

  // get prosem detail
  const { data, isLoading, refetch } = useProsemDetail(id, false);

  // get teacher teaching
  const { data: dataTeaching, refetch: fetchTeaching } = useTeachingList(
    Cookies.get("teacher_id") ? decryptCookies("teacher_id") : teacherId
  );

  // get teacher list
  const { data: dataTeachers, refetch: fetchTeachers } = useTeachersList();

  useEffect(() => {
    if (open && id) {
      refetch();

      if (
        roles.find(
          (x) =>
            x === "super_admin" ||
            x === "admin" ||
            x === "curriculum" ||
            x === "admin_academic"
        )
      ) {
        fetchTeachers();
        fetchTeaching();
      } else if (roles.find((x) => x === "employee")) fetchTeaching();
    }

    if (data) {
      let teachingId = "";
      const { class_id, subject_id, teachers } = data?.data;
      const matchingData = teachers?.teaching.filter((teaching) => {
        return (
          teaching.class_id === class_id && teaching.subject_id === subject_id
        );
      });
      if (matchingData) {
        teachingId = matchingData[0]?.id;
      }

      form.setFieldsValue({
        teacherId: teacherId,
        subjectId: teachingId,
      });
    }

    // eslint-disable-next-line
  }, [id, open, form, data, refetch, fetchTeachers, fetchTeaching]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    const datas = dataTeaching?.data?.filter((x) => x.id === values.subjectId);

    setLoading(true);

    try {
      const { data } = await axios.put(
        api + `/academics/program-semester/${id}`,
        { classId: datas[0]?.class_id, mapelId: datas[0]?.subject_id },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      onUpdate();
      form.resetFields();
      message.success(data.message);
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return {
    data,
    form,
    loading,
    isLoading,
    dataTeaching,
    dataTeachers,
    onSubmit,
    onCancelModal,
  };
};

export default EditProsemLogic;
