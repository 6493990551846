import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useShowdailyDetail } from "hooks/attandance-hook/useShowDailyDetail";
import moment from "moment";
import { useEffect, useState } from "react";

const EditRecapLogic = ({ id, show, onUpdate }) => {
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleteon,
    refetch: fetchData,
  } = useShowdailyDetail(id, false);

  useEffect(() => {
    if (show) {
      fetchData();
    }

    if (data) {
      form.setFieldsValue({
        status: data.data.status,
        description: data.data.description,
        date_in: data.data.date_in
          ? dayjs(moment(data.data.date_in).format("YYYY-MM-DD HH:mm:ss"))
          : null,
        date_out: data.data.date_out
          ? dayjs(moment(data.data.date_out).format("YYYY-MM-DD HH:mm:ss"))
          : null,
      });
    }

    return () => setNewData({});
  }, [form, data, show, fetchData]);

  // dev ---
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        if (Object.keys(newData).length === 0) {
          alert("Nothing has changed");
          return;
        }

        // console.log(newData);

        setLoading(true);
        try {
          const daily_attendance = [
            {
              id,
              ...newData,
            },
          ];

          const { data } = await axios.put(
            REACT_APP_HOST_API + `/academics/daily-attendances`,
            { daily_attendance },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          console.log(newData.date_in);

          message.success(data.message);
          form.resetFields();
          onUpdate();
        } catch (error) {
          console.log(error);
          alert(
            error.response.data.error ||
              error.response.data.message ||
              error.message
          );
        }

        setLoading(false);
      })
      .catch(() => alert("Fields Error"));
  };

  return { form, newData, loading, skeleteon, handleSubmit };
};

export default EditRecapLogic;
