import { InfoCircleTwoTone, MoreOutlined } from "@ant-design/icons";
import { Select, Badge, Button, Form, Popover, Space, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useSessionList } from "hooks/attandance-hook/useSession";
import { useProsemDetailList } from "hooks/prosem-detail-hook/useProsemDetailList";
import { useProsemFilter } from "hooks/prosem-hook/useProsemFilter";
import { useStudentSubjectList } from "hooks/students-hook/useStudentSubjectList";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddPresencePerSubjectLogic = () => {
  const [form] = Form.useForm();
  const [teacherId, setTeacherId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [pelajaranId, setPelajaranId] = useState("");
  const [idProsem, setIdProsem] = useState("");
  const [isEkskul, setIsEkskul] = useState(false);
  const [statusTeach, setStatusTeach] = useState("");
  const [materiProsem, setMateriProsem] = useState("");
  const [sourceStudents, setSourceStudents] = useState([]);
  const [posting, setPosting] = useState(false);
  const [isGuruLogin, setIsGuruLogin] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [recordColumn, setRecordColumn] = useState({});

  const { REACT_APP_HOST_API } = process.env;

  // const [filteredDataSource, setFilteredDataSource] = useState(dataSource);

  const navigate = useNavigate();

  const navigateToRecap = () => {
    navigate("/academic/presencepersubject");
  };

  //* get data guru ---
  const { data: dataGuru, refetch: fetchGuru } = useTeachersList();

  //* get data pelajaran ---
  const {
    data: dataPelajaran,
    isLoading: isloadPelajaran,
    refetch: fetchPelajaran,
  } = useTeachingList(isGuruLogin ? decryptCookies("teacher_id") : teacherId);

  //* get data session ---
  const { data: dataSession, refetch: fetchSession } = useSessionList();

  //* get student list in teble ---
  const {
    data: dataStudentList,
    isLoading: isLoadStudentList,
    isFetching: isFetchStudentList,
    refetch: fetchStudentList,
  } = useStudentSubjectList({
    keyword,
    classId: classId ? classId : "",
    subjectId: !pelajaranId || !isEkskul ? "" : pelajaranId,
    enabled: Boolean((pelajaranId && classId) || isEkskul),
  });

  //* get prosem list ---
  const {
    data: dataProsem,
    isLoading: isLoadProsem,
    refetch: fetchProsem,
  } = useProsemFilter({
    subjectId: pelajaranId ? pelajaranId : "",
    classId: classId ? classId : "",
    enabled: Boolean(pelajaranId && classId),
  });

  //* get prosem detail list ---
  const {
    data: dataProsemDetail,
    isLoading: isLoadProsemDetail,
    refetch: fetchProsemDetail,
  } = useProsemDetailList(idProsem, false);

  // fetch student permission
  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuPresencePerSubject"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddPresencePerSubject"
  );

  // logic sistem in here---

  // note: untuk mengatur value dateIn dan dateOut dari Sesi ---
  const getSessionById = (id) => {
    const res = dataSession?.data?.data?.find((x) => x.id === id);

    const parseTimeIn = dayjs(res.time_in, "HH:mm:ss");
    const parseTimeOut = dayjs(res.time_out, "HH:mm:ss");

    const hourIn = parseTimeIn.hour();
    const minuteIn = parseTimeIn.minute();
    const secondIn = parseTimeIn.second();

    const hourOut = parseTimeOut.hour();
    const minuteOut = parseTimeOut.minute();
    const secondOut = parseTimeOut.second();

    form.setFieldsValue({
      date_in: dayjs().second(secondIn).hour(hourIn).minute(minuteIn),
      date_out: dayjs().second(secondOut).hour(hourOut).minute(minuteOut),
    });
  };

  const filterDataBySubject = async () => {
    const subjectData = await dataPelajaran?.data?.find(
      (item) => item.id === subjectId
    );
    const classIds = subjectData?.class_id;
    const subjectIds = subjectData?.subject_id;
    const isEkskuls = subjectData?.subject?.is_extracurricular;

    setClassId(classIds);
    setPelajaranId(subjectIds);
    setIsEkskul(isEkskuls);

    if (isEkskuls) {
      const filteredData = dataSession?.data?.data?.filter(
        (item) =>
          item.session.toLowerCase() === "sesi extracurricular".toLowerCase()
      );

      getSessionById(filteredData[0]?.id);

      form.setFieldsValue({
        // ID dari sesi ekskul",
        sessionId: filteredData[0]?.id,
      });
    }
  };

  // end logic sistem ---

  // useEffect in here ---
  useEffect(() => {
    fetchGuru();
    fetchSession();
    if (Cookies.get("teacher_id")) {
      setIsGuruLogin(true);
      form.setFieldsValue({
        teacherId: decryptCookies("teacher_id"),
      });
    }
    if (teacherId || isGuruLogin) {
      fetchPelajaran();
    }

    if (idProsem !== "") {
      fetchProsemDetail();
    }
    // eslint-disable-next-line
  }, [
    isGuruLogin,
    fetchGuru,
    fetchPelajaran,
    teacherId,
    fetchSession,
    subjectId,
    fetchProsemDetail,
    idProsem,
  ]);

  useEffect(() => {
    if (
      dataProsem &&
      dataProsem.data &&
      dataProsem.data.length > 0 &&
      isGuruLogin
    ) {
      form.setFieldsValue({
        prosem: dataProsem.data[0].id,
      });
      setIdProsem(dataProsem.data[0].id);
    }
    // eslint-disable-next-line
  }, [dataProsem, isGuruLogin]);

  useEffect(() => {
    form.setFieldsValue({
      status: "teach",
      post_test: false,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterDataBySubject();

    // eslint-disable-next-line
  }, [subjectId, teacherId]);

  useEffect(() => {
    onGuruChange();

    // eslint-disable-next-line
  }, [teacherId]);

  useEffect(() => {
    onPelajaranChange();
    if (subjectId && classId) {
      fetchProsem();
    }
    fetchStudentList();
    // eslint-disable-next-line
  }, [subjectId]);

  useEffect(() => {
    onProsemChange();

    // eslint-disable-next-line
  }, [idProsem]);

  // end useEffect ---

  // handle in here ---
  const handleSubmit = async () => {
    try {
      if (sourceStudents.length === 0)
        return message.error("Tidak ada data siswa");
      await form.validateFields();
      const values = await form.getFieldsValue();
      const attendanceData = sourceStudents?.map((student) => {
        return {
          date: dayjs(values["date_in"]).format("YYYY-MM-DD HH:mm:ss"),
          status: student.statusPresence,
          description: values[`description_${student.id}`] || "",
          studentId: student.id,
          sessionId: values.sessionId,
          subjectId: pelajaranId,
        };
      });
      setPosting(true);
      await axios.post(
        // url:
        REACT_APP_HOST_API + `/academics/teacher-attendances`,
        {
          date_in: dayjs(values["date_in"]).format("YYYY-MM-DD HH:mm:ss"),
          date_out: dayjs(values["date_out"]).format("YYYY-MM-DD HH:mm:ss"),
          programSemesterDetailId: values.programSemesterDetailId,
          material: values.material,
          status: values.status,
          reason_not_teach: values.reason_not_teach,
          post_test: values.post_test,
          sessionId: values.sessionId,
          teacherId: values.teacherId,
          subjectId: pelajaranId,
          classId: classId,
          catatanKelas: values.catatanKelas,
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      if (values.status !== "not_teach") {
        await axios.post(
          REACT_APP_HOST_API + `/academics/lesson-attendances`,
          { lessonAttendance: attendanceData },
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );
      }
      message.success("Berhasi menambahkan data kehadiran");
      form.resetFields();
      setClassId("");
      setSourceStudents([]);
      navigateToRecap();
    } catch (error) {
      let msg = "";
      console.log(error);
      if (error.response) {
        msg = error?.response?.data?.message;
      } else {
        msg = error?.message;
      }
      message.error(`${msg}`);
    } finally {
      setPosting(false);
    }
  };

  const handleStatusChange = (studentId, newStatus) => {
    const updatedDataSource = sourceStudents.map((student) => {
      if (student.key === studentId) {
        return { ...student, statusPresence: newStatus };
      }
      return student;
    });

    setSourceStudents(updatedDataSource);
  };

  const handleDescChange = (studentId, newDesc) => {
    const updatedDataSource = sourceStudents.map((student) => {
      if (student.key === studentId) {
        return { ...student, descPresence: newDesc };
      }
      return student;
    });

    setSourceStudents(updatedDataSource);
  };

  const onGuruChange = () => {
    form.resetFields([
      "subjectId",
      "prosem",
      "programSemesterDetailId",
      `description_${recordColumn.id}`,
    ]);
    setIdProsem("");
    setSubjectId("");
  };

  const onPelajaranChange = () => {
    form.resetFields([
      "prosem",
      "programSemesterDetailId",
      `description_${recordColumn.id}`,
      // "sessionId",
    ]);
    setIdProsem("");
  };
  const onProsemChange = () => {
    form.resetFields(["programSemesterDetailId"]);
  };

  //* note: untuk mengatur waktu dateOut + 80 menit
  const handleDateInChange = (value) => {
    // Menghitung waktu date_in + 80 menit
    const newDateOut = value.add(80, "minute");
    form.setFieldsValue({ date_out: newDateOut });
  };

  const handleDesc = (obj) => {
    setOpenModal(true);
    setRecordColumn(obj);
  };

  // end handle ---

  // columns table ---
  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      responsive: ["sm"],
      width: window.innerWidth > 800 ? 70 : 50,
    },
    // {
    //   title: "Kelas",
    //   dataIndex: "class",
    //   align: "left",
    // },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (text, record) => (
        <Space>
          {text}

          {record.descPresence && (
            <Popover
              content={record.descPresence}
              title="Deskripsi"
              placement="topLeft"
            >
              <InfoCircleTwoTone />
            </Popover>
          )}
        </Space>
      ),
    },

    {
      title: "Status/Deskripsi",
      dataIndex: "statusPresence",
      key: "present",
      align: "left",
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Form.Item
            name={record.id}
            initialValue="present"
            style={{ width: "100%" }}
          >
            <Select onChange={(e) => handleStatusChange(record.id, e)}>
              <Select.Option value="present">
                <Badge color="green" text="Hadir" />
              </Select.Option>
              <Select.Option value="sick">
                <Badge color="orange" text="Sakit" />
              </Select.Option>
              <Select.Option value="permission">
                <Badge color="purple" text="Izin" />
              </Select.Option>
              <Select.Option value="absent">
                <Badge color="red" text="Alpa" />
              </Select.Option>
            </Select>
          </Form.Item>
          <Button
            onClick={() => handleDesc(record)}
            type="ghost"
            icon={<MoreOutlined />}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (dataStudentList?.data?.data) {
      const initialStudents = dataStudentList.data.data.map((item, index) => ({
        ...item,
        index: index + 1,
        key: item?.id,
        class: item?.class?.name,
        statusPresence: "present",
      }));
      setSourceStudents(initialStudents);
    }
  }, [dataStudentList]);

  return {
    teacherId,
    openModal,
    setOpenModal,
    recordColumn,
    handleDescChange,
    getSessionById,
    handleStatusChange,
    handleDateInChange,
    form,
    dataGuru,
    dataPelajaran,
    setTeacherId,
    dataSession,
    columns,
    idProsem,
    btnAdd,
    // dataSource,
    handleSubmit,
    sourceStudents,
    isLoadStudentList,
    isFetchStudentList,
    setKeyword,
    isloadPelajaran,
    setSubjectId,
    dataProsem,
    dataProsemDetail,
    setIdProsem,
    isLoadProsemDetail,
    isEkskul,
    isLoadProsem,
    setStatusTeach,
    statusTeach,
    subjectId,
    materiProsem,
    setMateriProsem,
    posting,
    isGuruLogin,
    // filteredDataSource,
  };
};

export default AddPresencePerSubjectLogic;
