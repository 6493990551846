import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useTotalEmployee = (unitId = "", enabled = false) => {
  return useQuery(
    ["get-dashboard-total-employees", unitId || ""],
    () => GetPagination(`/dashboard/total-employees?unitId=${unitId}`),
    {
      enabled,
    }
  );
};
