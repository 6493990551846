import {
  Button,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  message,
} from "antd";
import BukuNilaiLogic from "./BukuNilaiLogic";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";

const BukuNilai = () => {
  const {
    record,
    handleUpdateData,
    isEkskul,
    setIsEkskul,
    subjectId,
    classId,
    refetch,
    isOpenGenerate,
    handleOpenGenerate,
    setType,
    teacherId,
    isLoadingMengajar,
    setAspekPenilaian,
    aspekPenilaian,
    setKeyword,
    isLoadBukuNilai,
    isFetching,
    tableRef,
    columns,
    handleDownload,
    btnAdd,
    dataKey,
    dataGuru,
    dataMengajar,
    navigate,
    setClassId,
    setSubjectId,
    setTeacherId,
    btnDelete,
    data,
  } = BukuNilaiLogic();
  // role
  const roles = decryptCookies("role").split(",");

  //todo: reset field pelajaran jika ganti guru
  useEffect(() => {
    form.resetFields(["subjectId"]);
    setClassId("");
    setSubjectId("");
    // eslint-disable-next-line
  }, [teacherId]);

  // todo: memisahkan classId dan subjectId dari teaching dan menentukan apakah merupakan ekskul
  const handleFindByTeaching = (id) => {
    if (!id) {
      setClassId("");
      setSubjectId("");
    } else {
      const foundItem = dataMengajar?.data?.find((x) => x.id === id);
      if (foundItem && foundItem?.class_id) {
        setClassId(foundItem.class_id);
        setSubjectId(foundItem.subject_id);
        setIsEkskul(false);
      } else {
        setIsEkskul(true);
        setSubjectId(foundItem.subject_id);
        setAspekPenilaian("");
        form.resetFields(["aspekPenilaian"]);
        setType("");
        setClassId("");
      }
    }
  };

  const [form] = Form.useForm();

  const { REACT_APP_HOST_API } = process.env;

  const handleGenerate = async () => {
    const values = await form.validateFields();

    if (!values.startDate || !values.endDate) {
      message.warning(
        "Harap masukan tanggal mulai dan akhir untuk generate UTS!"
      );
      return;
    }

    // check apakah end date dari generate Uts ada di data
    // const dailyDates = (data?.data?.bab || []).flatMap((bab) =>
    //   (bab?.type || [])
    //     .filter((dates) => dates.name === "HARIAN")
    //     .map((dates) =>
    //       dayjs(dates.tanggal_pengambilan_nilai).format("YYYY-MM-DD")
    //     )
    // );

    // const endDate = dayjs(values.endDate).format("YYYY-MM-DD");

    // if (!dailyDates.length) {
    //   message.warning("Pastikan data sudah tampil di tabel!");
    //   return;
    // } else if (dailyDates.includes(endDate)) {
    //   message.warning(
    //     "Tanggal akhir tidak boleh sama dengan tanggal nilai harian, harap majukan beberapa hari!"
    //   );
    //   return;
    // }
    // end check

    try {
      await axios.post(
        `${REACT_APP_HOST_API}/academics/generate-uts`,
        {
          aspekPenilaian,
          classId,
          subjectId,
          teacherId,
          fromDate: dayjs(values.startDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.endDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      form.resetFields(["startDate", "endDate"]);
      handleOpenGenerate();
      await refetch();

      setTimeout(() => {
        refetch();
        message.success("Berhasil update data");
      }, 400);
    } catch (error) {
      alert("Gagal generate UTS!");
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    const dataNilai = record.map((x) => x.nilai).flat();
    const bukuNilai = dataNilai.map((x) => ({ id: x.id }));

    try {
      setIsLoading(true);
      axios.delete(`${REACT_APP_HOST_API}/academics/multi-delete-buku-nilai`, {
        headers: {
          Authorization: "Bearer " + decryptCookies("token"),
        },
        data: {
          bukuNilai,
        },
      });

      await refetch();
      setTimeout(() => {
        refetch();
        message.success("Berhasil menghapus data");
      }, 300);
    } catch (error) {
      alert("Gagal menghapus nilai!");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Buku Nilai</h1>
        <Space>
          <Button type="primary" ghost onClick={handleDownload}>
            Export to Excel
          </Button>
          <Button
            type="primary"
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            onClick={() => navigate("create")}
          >
            Tambah Buku Nilai
          </Button>
        </Space>
      </div>
      <Form layout="vertical" form={form}>
        <div className="search-wrapper filter-wrapper">
          {dataGuru && !roles.includes("teacher") && (
            <Form.Item label="Guru" name="teacherId">
              <Select
                style={{ width: "100%" }}
                placeholder="Guru"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  if (e === "") setTeacherId("");
                  else setTeacherId(e);
                }}
              >
                {dataGuru?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.employee?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="Pelajaran" name="subjectId">
            <Select
              style={{ width: "100%" }}
              placeholder="Pelajaran"
              disabled={!teacherId || isLoadingMengajar}
              loading={isLoadingMengajar}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option.children
                  ? option.children.toString()
                  : "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={(e) => {
                handleFindByTeaching(e);
              }}
            >
              {dataMengajar?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.subject?.name} - {x.class?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Aspek Penilaian"
            name="aspekPenilaian"
            hidden={isEkskul}
          >
            <Select
              onChange={(e) => setAspekPenilaian(e)}
              defaultValue={aspekPenilaian}
            >
              <Select.Option value="PENGETAHUAN">PENGETAHUAN</Select.Option>
              <Select.Option value="KETERAMPILAN">KETERAMPILAN</Select.Option>
              <Select.Option value="SIKAP">SIKAP</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Tipe" name="type" hidden={isEkskul}>
            <Select placeholder="Tipe" onChange={(e) => setType(e)}>
              <Select.Option value="">ALL</Select.Option>
              <Select.Option value="HARIAN">HARIAN</Select.Option>
              <Select.Option value="UTS">UTS</Select.Option>
              <Select.Option value="UAS">UAS</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <Button
          style={{ width: 150 }}
          type="primary"
          onClick={handleOpenGenerate}
          hidden={isOpenGenerate}
          disabled={!aspekPenilaian || !teacherId || !subjectId}
        >
          Generate Nilai UTS
        </Button>

        {isOpenGenerate && (
          <div className="wrap-default-nilai">
            <Form.Item label="Tanggal Mulai" name="startDate" required>
              <DatePicker />
            </Form.Item>

            <Form.Item label="Tanggal Akhir" name="endDate" required>
              <DatePicker />
            </Form.Item>

            <Space>
              <Button
                type="primary"
                onClick={() => {
                  handleGenerate();
                }}
              >
                Generate UTS
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  handleOpenGenerate();
                }}
              >
                Batal
              </Button>
            </Space>
          </div>
        )}

        <Form.Item
          label="Siswa"
          style={{
            marginTop: `${isOpenGenerate ? 0 : "6px"}`,
          }}
          name="siswa"
          onChange={(e) => setKeyword(e.target.value)}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
            }}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Cari nama siswa ..."
            />
            <Button
              hidden={
                !data ||
                data?.data.length === 0 ||
                isEkskul ||
                !aspekPenilaian ||
                aspekPenilaian === "SIKAP"
              }
              type="primary"
              style={{ width: 100 }}
              onClick={handleUpdateData}
            >
              Update UTS
            </Button>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              cancelText="Batal"
              okText="Hapus"
              onConfirm={handleDelete}
            >
              <Button
                hidden={record.length === 0 || !btnDelete}
                disabled={btnDelete?.type === "disabled"}
                type="primary"
                ghost
                danger
                style={{ width: 70 }}
                loading={isLoading}
              >
                Delete
              </Button>
            </Popconfirm>
          </div>
        </Form.Item>

        <Table
          loading={isLoadBukuNilai || isFetching}
          ref={tableRef}
          // columns={columns}
          columns={isFetching ? [] : columns}
          dataSource={dataKey}
          bordered
          size="middle"
          scroll={{
            x: 900,
            // x: "max-content",
          }}
          pagination={false}
          tableLayout="fixed"
        />
      </Form>
    </div>
  );
};

export default BukuNilai;
