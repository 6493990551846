import { DateTime } from "luxon";
import { Tag } from "antd";

const statusComponents = {
  waiting: <Tag color="default">Menunggu</Tag>,
  approved: <Tag color="green">Diterima</Tag>,
  rejected: <Tag color="red">Ditolak</Tag>,
};

export const statusRender = (status) => {
  return statusComponents[status] || <Tag color="default">Menunggu</Tag>;
};

export const renderAmount = (amount) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(amount);
};

const statusBillings = {
  paid_full: <Tag color="green">Lunas</Tag>,
  paid_partial: <Tag color="orange">Bayar Sebagian</Tag>,
  unpaid: <Tag color="default">Belum Bayar</Tag>,
  approved: <Tag color="green">Disetujui</Tag>,
  past_due: <Tag color="red">Lewat Jatuh Tempo</Tag>,
  refunded: <Tag color="cyan">Refund</Tag>,
};

export const statusBillingsRender = (status) => {
  return statusBillings[status] || <Tag color="default">{status}</Tag>;
};

export const renderDateTime = (date) => {
  return DateTime.fromISO(date, {
    zone: "utc",
  }).toFormat("dd MMMM yyyy hh:mm a");
};

export const renderDateAndMonth = (date) => {
  return DateTime.fromISO(date, {
    zone: "utc",
  }).toFormat("dd MMM yyyy");
};

export const renderDateMonthYear = (date) => {
  return DateTime.fromISO(date, {
    zone: "utc",
  }).toFormat("dd MMMM yyyy");
};
