import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useRecapPresencePagination = (date, idActivity) => {
  return useQuery(
    ["get-recapPresence-pagination", idActivity, date.from, date.to],
    () =>
      GetPagination(
        `/presence/${idActivity}/recap?from=${date.from}&to=${date.to}`
      ),
    { enabled: false }
  );
};
