import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useSemesterList = (isActive) => {
  return useQuery(
    ["get-semester-list", isActive],
    () => GetList(`/academics/semesters?isActive=${isActive}`),
    {
      enabled: false,
    }
  );
};
