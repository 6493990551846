import { Modal } from "antd";
import React from "react";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";

export default function RefundModal({ data, open, setOpen }) {
  return (
    <Modal open={open} onCancel={setOpen} footer={false}>
      <h1>{renderAmount(data?.refund_amount)}</h1>
    </Modal>
  );
}
