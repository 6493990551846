import React, { Fragment } from "react";
import { toRoman } from "@javascript-packages/roman-numerals";
import dayjs from "dayjs";
const MReportContent = ({ data }) => {
  const convertTimeToText = (value) => {
    const parts = value.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    let result = '';
    if (hours > 0) {
      result += `${hours} jam `;
    }
    if (minutes > 0) {
      result += `${minutes} menit`;
    }

    return result.trim();
  };

  return (
    <table>
      <thead style={{ textAlign: "center" }}>
        <tr>
          <th rowSpan={2}>No</th>
          <th rowSpan={2}>Komponen</th>
          <th colSpan={2}>Skor</th>
        </tr>
        <tr>
          <th>Jumlah</th>
          <th>Keterangan</th>
        </tr>
      </thead>
      <tbody>
        {data?.monthlyReportEmployeeDetail.map((parent, index) => (
          <Fragment key={index}>
            <tr>
              <td colSpan={4} className="title">
                {toRoman(index + 1)}. {parent?.name}
              </td>
            </tr>

            {parent?.data.map((child, childIndex) => {
              const isDefault = child?.item?.some(
                (x) => x.item_name === "default"
              );

              let value;

              if (isDefault) {
                value = child?.item.find(
                  (x) => x.item_name === "percentage_persen"
                )?.data_value[0]?.value;
              } else {
                if (child?.activity_name === "IZIN (SESI)") {
                  value = child?.item.find(
                    (x) => x.item_name === "total_leave_session"
                  )?.data_value[0]?.value;
                } else {
                  value = child?.item.find((x) => x.item_name === "skor")
                    ?.data_value[0]?.value;
                }
              }

              const findNote = child?.item.find((x) => x.item_name === "note")
                ?.data_value[0]?.value;

              return (
                <tr key={child.activity_name}>
                  <td>{childIndex + 1}</td>
                  <td>
                    {child.activity_name === "IZIN (SESI)"
                      ? "IZIN (PERJAM)"
                      : child.activity_name}
                  </td>
                  <td>
                    {child.activity_name === "IZIN (SESI)"
                      ? (dayjs(value, "HH:mm:ss").isValid() ? convertTimeToText(dayjs(value, "HH:mm:ss").format("HH:mm")) : "-")
                      : value}
                  </td>
                  <td>{findNote && findNote}</td>
                </tr>
              );
            })}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default MReportContent;
