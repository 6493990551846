import { createContext, useState } from "react";

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [dataId, setDataId] = useState("");
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [showEditAccount, setShowEditAccount] = useState(false);
  const [showImportAccount, setShowImportAccount] = useState(false);
  const [fetchAccount, setFetchAccount] = useState(false);

  return (
    <AccountContext.Provider
      value={{
        dataId,
        setDataId,
        showAddAccount,
        setShowAddAccount,
        showEditAccount,
        setShowEditAccount,
        showImportAccount,
        setShowImportAccount,
        fetchAccount,
        setFetchAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
