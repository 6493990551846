import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuHrd from "components/Auth/RequireMenuHrd";
import { ActivityProvider } from "context/ActivityContext";
import LayoutHrd from "Layouts/LayoutHrd/LayoutHrd";
import LayoutScan from "Layouts/LayoutScan/LayoutScan";
import DetailActivity from "pages/si-hrd/activity/detail/DetailActivity";
import CreateSubActivity from "pages/si-hrd/activity/detail/sub-activity/create/CreateSubActivity";
import DetailSubActivity from "pages/si-hrd/activity/detail/sub-activity/DetailSubActivity";
import EditSubActivity from "pages/si-hrd/activity/detail/sub-activity/edit/EditSubActivity";
import RecapSubActivity from "pages/si-hrd/activity/detail/sub-activity/recap/RecapSubActivity";
import Activity from "pages/si-hrd/activity/master/Activity";
import AddEmployeeToDivisiBatch from "pages/si-hrd/division/detail/AddEmployeeToDivisiBatch";
import DetailDivision from "pages/si-hrd/division/detail/DetailDivision";
import Division from "pages/si-hrd/division/master/Division";
import { DivisionProvider } from "pages/si-hrd/employee/create/DivisionContext";
import Employee from "pages/si-hrd/employee/master/Employee";
import CreateLeave from "pages/si-hrd/leave-daily/CreateLeave";
import EditLeave from "pages/si-hrd/leave-daily/EditLeave";
import Leave from "pages/si-hrd/leave-daily/Leave";
import CreateLeaveSession from "pages/si-hrd/leave-session/CreateLeave";
import EditLeaveSession from "pages/si-hrd/leave-session/EditLeave";
import LeaveSession from "pages/si-hrd/leave-session/LeaveSession";
import MasterHrd from "pages/si-hrd/MasterHrd";
import MReportEmployee from "pages/si-hrd/monthly-report/employee-report/MReportEmployee";
// import MonthlyReport from "pages/si-hrd/monthly-report/MonthlyReport";
import MonthlyReportCardList from "pages/si-hrd/monthly-report/MonthLyReportCardList";
import PresenceTable from "pages/si-hrd/presence/master/Presence";
import RecapPresence from "pages/si-hrd/recap-presence/RecapPresence";
import EmployeeTriwulanReport from "pages/si-hrd/triwulan-report/employee-report/EmployeeTriwulanReport";
import TriwulanReport from "pages/si-hrd/triwulan-report/TriwulanReport";
import AddEmployeeBatch from "pages/si-hrd/unit/detail/AddEmployeeBatch";
import DetailUnit from "pages/si-hrd/unit/detail/DetailUnit";
import Unit from "pages/si-hrd/unit/master/Unit";
import { lazy, Suspense } from "react";

// lazy route hrd
const CreateEmployeeTab = lazy(() =>
  import("pages/si-hrd/employee/create/CreateEmployeeTab")
);
const EmployeeDetail = lazy(() =>
  import("pages/si-hrd/employee/detail/EmployeeDetail")
);
const ScanPresence = lazy(() => import("pages/si-scanpresence/ScanPresence"));
const MReportDetail = lazy(() =>
  import("pages/si-hrd/monthly-report/MReportDetail")
);
const TReportDetail = lazy(() =>
  import("pages/si-hrd/triwulan-report/TReportDetail")
);
const TReportRecap = lazy(() =>
  import("pages/si-hrd/triwulan-report/TReportRecap")
);

export const DataRoutesHrd = [
  {
    path: "hrd",
    element: (
      <RequireAuth>
        <RequireMenuHrd>
          <LayoutHrd content={<MasterHrd />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/unit",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuUnit">
          <LayoutHrd content={<Unit />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/unit/:unit_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuUnit">
          <LayoutHrd content={<DetailUnit />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/unit/:unit_id/add-employee-batch",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuUnit">
          <LayoutHrd content={<AddEmployeeBatch />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/division",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuDivision">
          <LayoutHrd content={<Division />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/division/:division_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuDivision">
          <LayoutHrd content={<DetailDivision />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/division/:division_id/add-employee-divisi-batch",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuDivision">
          <LayoutHrd content={<AddEmployeeToDivisiBatch />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/employee",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuEmployee">
          <LayoutHrd
            content={
              <DivisionProvider>
                <Employee />
              </DivisionProvider>
            }
          />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/employee/create",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuEmployee">
          <LayoutHrd
            content={
              <DivisionProvider>
                <CreateEmployeeTab />
              </DivisionProvider>
            }
          />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/employee/:id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="btnDetailEmployee">
          <LayoutHrd content={<EmployeeDetail />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuActivity">
          <LayoutHrd
            content={
              <ActivityProvider>
                <Activity />
              </ActivityProvider>
            }
          />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity/:activity_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuActivity">
          <LayoutHrd
            content={
              <ActivityProvider>
                <DetailActivity />
              </ActivityProvider>
            }
          />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity/:activity_id/create",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuActivity">
          <LayoutHrd
            content={
              <ActivityProvider>
                <CreateSubActivity />
              </ActivityProvider>
            }
          />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity/:activity_id/:session_id/edit",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuActivity">
          <LayoutHrd
            content={
              <ActivityProvider>
                <EditSubActivity />
              </ActivityProvider>
            }
          />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity/:activity_id/recap",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuActivity">
          <LayoutHrd content={<RecapSubActivity />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity/:activity_id/:session_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuActivity">
          <LayoutHrd
            content={
              <ActivityProvider>
                <DetailSubActivity />
              </ActivityProvider>
            }
          />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity/presence-:id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuPresence">
          <LayoutHrd content={<PresenceTable />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/activity/presence-:id/recapPresence-:id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuActivity">
          <LayoutHrd content={<RecapPresence />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/leavedaily",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuLeaveDaily">
          <LayoutHrd content={<Leave />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/leavedaily/create",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuLeaveDaily">
          <LayoutHrd content={<CreateLeave />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/leavedaily/edit/:leave_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuLeaveDaily">
          <LayoutHrd content={<EditLeave />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/leavesession",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuLeaveSession">
          <LayoutHrd content={<LeaveSession />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/leavesession/create",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuLeaveSession">
          <LayoutHrd content={<CreateLeaveSession />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/leavesession/edit/:leave_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuLeaveSession">
          <LayoutHrd content={<EditLeaveSession />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/monthlyreport",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuMonthlyReport">
          <LayoutHrd content={<MonthlyReportCardList />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/monthlyreport/:mreport_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuMonthlyReport">
          <MReportDetail />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/monthlyreport/:mreport_id/:mreport_employee_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuMonthlyReport">
          <MReportEmployee />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/triwulanreport",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuTriwulanReport">
          <LayoutHrd content={<TriwulanReport />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/triwulanreport/:triwulan_id/recap",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuTriwulanReport">
          <LayoutHrd content={<TReportRecap />} />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/triwulanreport/:triwulan_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuTriwulanReport">
          <TReportDetail />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "hrd/triwulanreport/:triwulan_id/:triwulan_employee_id",
    element: (
      <RequireAuth>
        <RequireMenuHrd menu="mnuTriwulanReport">
          <EmployeeTriwulanReport />
        </RequireMenuHrd>
      </RequireAuth>
    ),
  },
  {
    path: "scan-presence",
    element: (
      <LayoutScan>
        <Suspense>
          <ScanPresence />
        </Suspense>
      </LayoutScan>
    ),
  },
];
