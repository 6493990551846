import React from "react";
import { Form, Input, Modal, Select, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useDivisionDetail } from "hooks/divison-hook/useDivisionDetail";
import { useEffect, useState } from "react";
import { useUnitList } from "hooks/unit-hook/useUnitList";


const EditDivision = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const isSuperAdmin = decryptCookies("role").split(",").some((x) => x === "super_admin" || x === "admin_foundation");

  const { REACT_APP_HOST_API } = process.env;

  const { data: dataUnits, isLoading: loadingUnits } =
    useUnitList();

  // fetch detail employee
  const {
    data,
    isLoading: skeleton,
    refetch: fetchData,
  } = useDivisionDetail(props.id, false);

  useEffect(() => {
    if (props.show) fetchData();

    if (data)
      form.setFieldsValue({
        name: data.data?.name,
        description: data.data?.description,
        unitId: data.data?.unit_id,
      });

    return () => setNewData({});
  }, [data, form, props.show, fetchData]);

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/division/${props.id}`,
        newData,
        { headers: { Authorization: `Bearer ${decryptCookies("token")}` } }
      );
      message.success(data.message, 2);
      props.onUpdate();
    } catch (error) {
      const msg = error.response?.data?.message || "input field error";
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          {isSuperAdmin && <Form.Item
            name="unitId"
            label="Unit"
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={loadingUnits}
              onChange={(value) => {
                newData["unitId"] = value;
              }}
            >
              {dataUnits?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>}

          <Form.Item
            label="Nama Divisi"
            name="name"
          >
            <Input onChange={({ target: { value } }) =>
              (newData["name"] = value)
            } />
          </Form.Item>
          <Form.Item
            name="description"
            label="Deskripsi"
          >
            <Input onChange={({ target: { value } }) =>
              (newData["description"] = value)
            } />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default React.memo(EditDivision);
