import { Form, Input, Modal, Upload } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

const CreateUnit = (props) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const { id: foundationId } = useParams();

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const handleCreateDivision = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const postData = {
        name: values.name,
        description: values.description,
        foundationId,
      };

      if (values?.signature && fileList?.length > 0) {
        postData.signature = values.signature.file;
      }

      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/units`,
        { ...postData },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data?.message, 2);
      props.onCreate();
      form.resetFields();
      setFileList([]);
    } catch (error) {
      message.error(error?.response?.data?.message, 2);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    props.onCancel();
  };

  // upload
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const isImage = (file) => {
    return file.type === "image/png";
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.png)");
    }

    if (file.size > 2000000) {
      message.error("Ukuran maksimal foto 2MB");
      return false;
    }

    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleRemove = (file) => {
    // setFileList([]);
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      open={props.show}
      onCancel={handleCancel}
      onOk={handleCreateDivision}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nama Unit"
          name="name"
          rules={[
            {
              required: true,
              message: "Nama unit harus diisi!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Deskripsi"
          name="description"
          rules={[
            {
              required: true,
              message: "Deskripsi harus diisi!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="signature"
          label="Gambar"
          rules={[
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="file"
            accept=".png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUpload}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
              </div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateUnit);
