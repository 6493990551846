import { Skeleton } from "antd";
import React, { useEffect } from "react";
import "./Pendaftaran.css";
import { useBaseUrl } from "hooks/base-url-hook/useBaseUrl";
import ForAdmin from "./admin/ForAdmin";
import ForCandidate from "./student-candidate/ForCandidate";

const Pendaftaran = () => {
  const { data, isLoading, refetch } = useBaseUrl();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {/* table admin  candidate  */}

          {data?.data[0].role_name !== "student_candidate" ? (
            <ForAdmin />
          ) : (
            <ForCandidate baseData={data} />
          )}
        </>
      )}
    </div>
  );
};

export default Pendaftaran;
