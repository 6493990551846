import { Form, Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";

const titleOptions = [
  { label: "Ketua", value: "lead" },
  { label: "Wakil", value: "vice" },
  { label: "Anggota", value: "member" },
];

const EditDivision = ({ employeeId, divisionId, open, onUpdate, onCancel }) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});

  const { REACT_APP_HOST_API: api } = process.env;

  useEffect(() => {
    return () => setNewData({});
  }, []);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang dirubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    try {
      const { data } = await axios.put(
        api + `/employee/${employeeId}/divisions/${divisionId}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "application/json",
          },
        }
      );

      message.success(data.message);
      form.resetFields();
      onUpdate();
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Modal
      open={open}
      okText="Submit"
      closable={false}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Jabatan">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label?.toLowerCase().includes(input.toLowerCase())
            }
            options={titleOptions}
            onChange={(e) => (newData.title = e)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDivision;
