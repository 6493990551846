import { Form, Input, Modal } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import { useParams } from "react-router-dom";

const CreateDivision = (props) => {
  const [loading, setLoading] = useState(false);
  const isSuperAdmin = decryptCookies("role")
    .split(",")
    .some((x) => x === "super_admin" || x === "admin_foundation");

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const { unit_id } = useParams();

  const { data: dataUnits } = useUnitList();

  const handleCreateDivision = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      const values = form.getFieldsValue();
      values.unitId = unit_id;

      if (!isSuperAdmin) {
        const unitIdFromArray = dataUnits?.data?.find(
          (x) => x.employeeUnits.length > 0
        );
        values.unitId = unitIdFromArray?.id;
      }
      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/division`,
        values,
        { headers: { Authorization: `Bearer ${decryptCookies("token")}` } }
      );
      message.success(data.message, 2);
      props.onCreate();
      form.resetFields();
    } catch (error) {
      alert(new Error(error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      open={props.show}
      onCancel={handleCancel}
      onOk={handleCreateDivision}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nama"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Deskripsi"
          rules={[
            { required: true, message: "Harap diisi" },
            {
              min: 6,
              message: `masukkan minimal 6 karakter`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateDivision);
