import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useClassList = (is_graduated) => {
  return useQuery(
    ["get-class-list", is_graduated],
    () => GetList(`/academics/classes?mode=list&is_graduated=${is_graduated}`),
    { enabled: false }
  );
};
