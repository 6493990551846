import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useKecList } from "hooks/wilayah-hook/useKecList";
import { useKelList } from "hooks/wilayah-hook/useKelList";
import { useKotaList } from "hooks/wilayah-hook/useKotaList";
import { useProvinceList } from "hooks/wilayah-hook/useProvinceList";
import React, { useEffect, useState } from "react";

const TabAlamatSiswa = ({ form }) => {
  const [kdPro, setKdPro] = useState();
  const [kdKot, setKdKot] = useState();
  const [kdKec, setKdKec] = useState();

  const [isdisabledKot, setIsDisableKot] = useState(true);
  const [isdisabledKec, setIsDisableKec] = useState(true);
  const [isdisabledKel, setIsDisableKel] = useState(true);

  // fetch prov,kot,kec,kel list
  const { data: pro, refetch, isLoading: isLoadProv } = useProvinceList();
  const {
    data: kot,
    refetch: refetchKot,
    isLoading: isLoadKot,
  } = useKotaList(kdPro);
  const {
    data: kec,
    refetch: refetchKec,
    isLoading: isLoadKec,
  } = useKecList(kdKot);
  const {
    data: kel,
    refetch: refetchKel,
    isLoading: isLoadKel,
  } = useKelList(kdKec);

  useEffect(() => {
    refetch();
    refetchKot();
    refetchKec();
    refetchKel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kdPro, kdKot, kdKec]);

  return (
    <div className="double-form">
      <div>
        <Form.Item label="Jenis Tinggal" name="residence">
          <Select
            options={[
              {
                value: "with parent",
                label: "Dengan orang tua",
              },
              {
                value: "boarding school",
                label: "Pondok",
              },
              {
                value: "dormitory",
                label: "Asrama",
              },
              {
                value: "others",
                label: "Lainnya",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Alamat" name="address">
          <TextArea rows={3} />
        </Form.Item>

        <Form.Item
          label="Rt"
          name="rt"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              min: 3,
              message: "harus 3 karakter",
            },
            {
              max: 3,
              message: "harus 3 karakter",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Rw"
          name="rw"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              min: 3,
              message: "harus 3 karakter",
            },
            {
              max: 3,
              message: "harus 3 karakter",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div>
        <Form.Item label="Provinsi" name="prov">
          <Select
            disabled={isLoadProv}
            loading={isLoadProv}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              setKdPro(a);
              setKdKot(null);
              setKdKec(null);

              setIsDisableKot(false);
              setIsDisableKel(true);
              setIsDisableKec(true);
              form.resetFields(["kot", "kec", "kel"]);
            }}
          >
            {pro?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Kota / Kabupaten" name="kot">
          <Select
            disabled={isLoadKot || isdisabledKot}
            loading={isLoadKot}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              setKdKot(a);
              setKdKec(null);

              setIsDisableKec(false);
              setIsDisableKel(true);
              form.resetFields(["kec", "kel"]);
            }}
          >
            {kot?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Kecamatan" name="kec">
          <Select
            disabled={isLoadKec || isdisabledKec}
            loading={isLoadKec}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              setKdKec(a);
              setIsDisableKel(false);
              form.resetFields(["kel"]);
            }}
          >
            {kec?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Kelurahan" name="kel">
          <Select
            disabled={isLoadKel || isdisabledKel}
            loading={isLoadKel}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {kel?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Kode Pos"
          name="zip"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              min: 5,
              message: "harus 5 karakter",
            },
            {
              max: 5,
              message: "harus 5 karakter",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabAlamatSiswa;
