import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useMetodePenilaianList } from "hooks/prosem-detail-hook/useMetodePenilaianList";
import { useProsemDetailList } from "hooks/prosem-detail-hook/useProsemDetailList";
import { useProsemList } from "hooks/prosem-hook/useProsemList";
import { useRpnDetail } from "hooks/rpn-hook/useRpnDetail";
import { useEffect, useState } from "react";

const EditRpnLogic = ({ id, open, onUpdate, onCancel }) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const [idProsem, setIdProsem] = useState("");
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API: api } = process.env;

  // get rpn detail
  const { data, isFetching, refetch } = useRpnDetail(id, false);

  // get metode pengambilan nilai
  const { data: dataMetode, refetch: fetchMetode } = useMetodePenilaianList();

  //   get prosem list
  const { data: dataProsem, refetch: fetchProsem } = useProsemList();

  //   get prosem detail list
  const {
    data: dataProsemDetail,
    isFetching: fetching,
    refetch: fetchProsemDetail,
  } = useProsemDetailList(idProsem, false);

  useEffect(() => {
    if (open && id) {
      refetch();
      fetchProsem();
      fetchMetode();
      if (idProsem) fetchProsemDetail();
    }

    if (data) {
      setIdProsem(data.data?.programSemesterDetail?.program_semester_id);

      form.setFieldsValue({
        programSemesterDetailId: data.data?.program_semester_detail_id,
        metodePengambilanNilaiId: data.data?.metode_pengambilan_nilai_id,
        topik: data.data?.topik,
        pertemuan: data.data?.pertemuan,
        presentase: data.data?.presentase,
      });
    }

    return () => setNewData({});
  }, [
    id,
    open,
    form,
    data,
    idProsem,
    refetch,
    fetchProsem,
    fetchMetode,
    fetchProsemDetail,
  ]);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang dirubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        api + `/academics/rencana-pengambilan-nilai/${id}`,
        { ...newData, presentase: newData.presentase },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      form.resetFields();
      onUpdate();
      onCancelModal();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    setIdProsem("");
    onCancel();
  };

  const onSelectProsem = (value) => {
    setIdProsem(value);
  };

  return {
    form,
    newData,
    loading,
    fetching,
    dataMetode,
    dataProsem,
    isFetching,
    dataProsemDetail,
    onSubmit,
    onCancelModal,
    onSelectProsem,
  };
};

export default EditRpnLogic;
