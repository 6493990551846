import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useMemberEmployee = ({ activityId, subActivityId, keyword }) => {
  return useQuery(
    ["get-memberAndEmployee", activityId, subActivityId, keyword],
    () =>
      GetList(
        `/get-member-and-employees?activityId=${activityId}&subActivityId=${
          subActivityId ? subActivityId : ""
        }&keyword=${keyword ? keyword : ""}`
      ),
    { enabled: false }
  );
};
