import { Form, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";

const ImportRevenuesLogic = (props) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const { REACT_APP_HOST_API } = process.env;
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // Helper function to check if file is PDF
  const isPDF = (file) => file.type === "application/pdf";

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const values = await form.validateFields();
      const postData = isSuperAdmin
        ? { foundationId: foundationId, upload: values.file.file }
        : { upload: values.file.file };

      const { data } = await axios({
        method: "POST",
        url: REACT_APP_HOST_API + props.url,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + decryptCookies("token"),
        },
        data: postData,
      });
      message.success(data.message);
      setFileList([]);
      props.onImport();
      form.resetFields();
      setFoundationId("");
    } catch (error) {
      const msg =
        error.response.data.error && error.response.data.error.length > 0
          ? error.response.data.error
          : error.response.data.message;

      if (error.response.data.error && error.response.data.error.length > 0) {
        // alert(msg.map((m) => m?.item, ",").join("\n"));
        errorModal(
          "Gagal Melakukan Import",
          // msg.map((m) => m?.item, ",").join("\n")
          <pre>{msg}</pre>
        );
      } else {
        errorModal("Gagal Melakukan Import", <pre>{msg}</pre>);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    if (!isPDF(file)) {
      message.error("You can only upload PDF files!");
    }
    if (!isPDF(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isPDF(file) ? [file] : []);
    return false;
  };

  const onDrop = (e) => {
    const droppedFiles = e.dataTransfer.files;
    if (!isPDF(droppedFiles[0])) {
      message.error("You can only upload PDF files!");
      return;
    }
    message.success("File dropped");
  };

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    props.onCancel();
    setFoundationId("");
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    onDrop,
    fileList,
  };

  return {
    form,
    propsUpload,
    uploading,
    onCancelModal,
    handleUpload,
    isPDF,
    isSuperAdmin,
    foundationId,
    setFoundationId,
  };
};

export default ImportRevenuesLogic;
