import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useKelList = (kodeKel) => {
  return useQuery(
    ["get-kel-list", kodeKel],
    () => GetList(`/wilayah?t=kel&p=${kodeKel}&k=`),
    { enabled: false }
  );
};
