import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useTeachingProsem = (id, classId, subjectId) => {
  return useQuery(
    ["get-teachings", id, classId, subjectId],
    () =>
      GetDetail(
        `/academics/teachers/${id}/teachings?classId=${classId}&subjectId=${subjectId}`
      ),
    { enabled: false }
  );
};
