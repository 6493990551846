import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Navigate } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

export const getPermission = async (module, menu) => {
  if (menu) {
    try {
      const { data } = await axios.get(REACT_APP_HOST_API, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      return data.data[0].role.permissions.modules
        .find((x) => x.id === module)
        .menus.find((x) => x.id === menu).functions;
    } catch (error) {
      if (error.response.status === 401) {
        if (
          error.response.statusText === "TOKEN" ||
          error.response.statusText === "UNVERIFIED"
        ) {
          return <Navigate to="/" />;
        } else {
          return <Navigate to="/permission-error" />;
        }
      }

      message.error(`Gagal mengambil data permission ( ${error.message} )`);
    }
  } else {
    try {
      const { data } = await axios.get(REACT_APP_HOST_API, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      return data.data[0].role.permissions.modules.find((x) => x.id === module)
        .menus;
    } catch (error) {
      if (error.response.status === 401) {
        if (
          error.response.statusText === "TOKEN" ||
          error.response.statusText === "UNVERIFIED"
        ) {
          return <Navigate to="/" />;
        } else {
          return <Navigate to="/permission-error" />;
        }
      }

      message.error(`Gagal mengambil data permission ( ${error.message} )`);
    }
  }
};
