import {
  Button,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Select,
  Space,
  Table,
} from "antd";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import EditTeachers from "../edit-teachers/EditTeachers";
import DetailJurnalMengajar from "../detail/DetailJurnalMengajar";
import { useMediaQuery } from "react-responsive";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/id"; // Import the Indonesian locale
import { useTheacresSubject } from "hooks/attandance-hook/useTheachersSubject";
import { useSessionList } from "hooks/attandance-hook/useSession";
import { usePelajaranList } from "hooks/pelajaran-hook/usePelajaranList";
import { usePermission } from "hooks/usePermissions";
import * as XLSX from "xlsx";
import { usePresenceContext } from "context/academic-context/PresencePerSubjectContext";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useClassListFoundation } from "hooks/classes-hook/useClassListFoundation";

dayjs.locale("id");

const disabled = { color: "#ccc", cursor: "not-allowed" };

const TheachersPresenceSubject = () => {
  const [form] = Form.useForm();
  const [sessionName, setSessionName] = useState("");
  const [optionsValue, setOptionsValue] = useState({
    kelas: "",
    pelajaran: "",
  });
  const [dateIn, setDateIn] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateOut, setDateOut] = useState(dayjs().format("YYYY-MM-DD"));
  const [dataId, setDataId] = useState("");
  const [showEditPerSubject, setShowEditPerSubject] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [keyword, setKeyword] = useState("");
  const {
    data: dataSession,
    // isLoading: skeleton,
    refetch: fetchSession,
  } = useSessionList();
  const {
    data: dataClass,
    isLoading: skeleton,
    refetch,
  } = useClassListFoundation(foundationId);
  const { data: dataPelajararan, refetch: fetchPelajaran } = usePelajaranList();
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const {
    data,
    isLoading,
    isFetching,
    refetch: fetchPresenceSubject,
  } = useTheacresSubject(
    dataTable,
    keyword,
    sessionName,
    optionsValue,
    dateIn,
    dateOut,
    foundationId,
    true
  );

  // * Get presencePerSubject Context ---
  const {
    setIsRecap,
    setDateInFilter,
    setDateOutFilter,
    setDateIn: setDateInContext,
    setDateOut: setDateOutContext,
    setClassName,
    setSubject,
    setSession,
    setIsFetchFilter,
  } = usePresenceContext();

  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuPresencePerSubject"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddPresencePerSubject"
  );
  const btnEdit = dataPermission?.find(
    (x) => x.id === "btnEditPresencePerSubject"
  );
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeletePresencePerSubject"
  );

  const navigateToTambahAbsensi = () => {
    navigate("/academic/addPresencePerSubject");
  };

  const [openDetail, setOpenDetail] = useState(false);
  const [jurnalId, setJurnalId] = useState("");

  const handleOpenDetail = (id) => {
    setOpenDetail(true);
    setJurnalId(id);
  };

  const handleSearch = (param) => {
    setKeyword(param.target.value);
  };

  const onUpdate = useCallback(() => {
    setShowEditPerSubject(false);
    fetchPresenceSubject();
  }, [fetchPresenceSubject]);

  const onCancel = () => {
    setShowEditPerSubject(false);
  };

  const statusMap = {
    teach: { text: "Mengajar", color: "green" },
    not_teach: { text: "Tidak Mengajar", color: "red" },
    exam: { text: "Ujian", color: "purple" },
    homework: { text: "Tugas", color: "gold" },
  };

  const getStatusTag = (status) => {
    const { text, color } = statusMap[status] || {
      text: status,
      color: "gray",
    };
    return <Tag color={color}>{text}</Tag>;
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 60,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "teacherName",
      key: "teacherName",
      align: "left",
      width: window.innerWidth > 800 ? 200 : 150,
      fixed: "left",
    },
    {
      title: "Pelajaran",
      dataIndex: "subject",
      key: "subject",
      align: "left",
    },
    {
      title: "Kelas",
      dataIndex: "className",
      key: "class",
      align: "left",
    },

    {
      title: "Sesi",
      dataIndex: "sessionName",
      key: "sessionName",
      align: "left",
    },
    {
      title: "Tanggal",
      dataIndex: "dateIn",
      key: "date",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (status) => getStatusTag(status),
    },
    {
      title: "Catatan Kelas",
      dataIndex: "catatan_kelas",
      key: "catatan_kelas",
      width: 150,
      align: "left",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      align: "center",
      render: (id, items) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              disabled={btnDelete?.type === "disabled"}
              onConfirm={() => {
                const dataId = id;
                DeleteApi({
                  url: "/academics/teacher-attendances/",
                  dataId,
                  refetch: fetchPresenceSubject,
                });
              }}
            >
              <Tag
                color="red"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Delete
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditPerSubject(true);
                }
              }}
            >
              Edit
            </Tag>

            <Tag
              color="blue"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOpenDetail(items);
              }}
            >
              Detail
            </Tag>

            <Tag
              color="green"
              style={{ cursor: "pointer" }}
              // onClick={() => navigate(`${id}`)}
              onClick={() => {
                navigate("/academic/studentpresencepersubject");
                setDateInContext(dayjs(items.date_in).format("YYYY-MM-DD"));
                setDateOutContext(dayjs(items.date_out).format("YYYY-MM-DD"));
                setDateInFilter(
                  dayjs(moment(items.date_in).format("YYYY-MM-DD"))
                );
                setDateOutFilter(
                  dayjs(moment(items.date_out).format("YYYY-MM-DD"))
                );
                setClassName(items?.class ? items.class.name : "");
                setSubject(items.subject);
                setSession(items.sessionName);
                setIsRecap(false);
                setIsFetchFilter(true);
              }}
            >
              Presensi Siswa
            </Tag>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refetch();
    fetchSession();
    fetchPelajaran();
  }, [refetch, fetchSession, fetchPelajaran]);

  useEffect(() => {
    if (sessionName !== "") {
      fetchPresenceSubject();
    }
  }, [sessionName, fetchPresenceSubject]);

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const dataSources = data?.data.data
    .slice(0, dataTable.per_page)
    .map((item, index) => {
      return {
        ...item,
        index: index + 1,
        teacherName: item.teacher.employee.name,
        materiProsem: item?.prosemDetail?.materi,
        kompetensiDasar: item?.prosemDetail?.kompetensi_dasar,
        className: item?.class?.name,
        subject: item?.subject?.name,
        key: index,
        sessionName: item.session.session,
        dateIn: dayjs(item?.date_in).format("DD MMMM YYYY"),
      };
    });

  const dataToExport = data?.data?.data.map((datas) => {
    const status = () => {
      if (datas?.status === "teach") {
        return "Mengajar";
      } else if (datas?.status === "no_teach") {
        return "Tidak Mengajar";
      } else if (datas?.status === "exam") {
        return "Ujian";
      } else if (datas?.status === "homework") {
        return "Tugas";
      } else {
        return datas?.status;
      }
    };
    return {
      nama: datas.teacher.employee.name,
      pelajaran: datas?.subject?.name,
      kelas: datas?.class?.name,
      KD: datas?.prosemDetail?.kompetensi_dasar,
      materi_prosem: datas?.prosemDetail?.materi,
      materi: datas?.material,
      sesi: datas?.session?.session,
      tanggal: dayjs(moment.utc(datas?.date_in).format("DD MMMM YYYY")).format(
        "DD MMMM YYYY"
      ),
      status: status(),
    };
  });

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `data_jurnal_mengajar.xlsx`);
  };

  const isDesktop = useMediaQuery({
    minWidth: 768,
  });

  return (
    <div>
      <div className="table-header">
        <h1>Teaching Journal</h1>

        <Space>
          <Button type="primary" ghost onClick={handleExport}>
            Export To Excel
          </Button>
          <Button
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            type="primary"
            onClick={navigateToTambahAbsensi}
          >
            Tambah Jurnal Mengajar
          </Button>
        </Space>
      </div>

      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col
            xs={12}
            style={isDesktop ? { display: "flex", gap: "10px" } : {}}
          >
            {isSuperAdmin && (
              <Form.Item
                name="foundationId"
                label="Yayasan"
                className="form-item-kehadiran"
              >
                <Select
                  style={{ minWidth: 150 }}
                  onChange={(x) => {
                    setFoundationId(x);
                    setOptionsValue((oldValue) => {
                      return {
                        ...oldValue,
                        kelas: "",
                      };
                    });
                    form.setFieldsValue({ classId: "" });
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option className="select-option-foundation" value="">
                    ALL
                  </Select.Option>
                  {dataFoundation?.data?.data?.map((x) => (
                    <Select.Option
                      key={x.id}
                      value={x.id}
                      className="select-option-foundation"
                    >
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name="sessionId"
              label="Sesi"
              className="form-item-kehadiran"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => setSessionName(value)}
              >
                <Select.Option value={""}>Semua Sesi</Select.Option>
                {dataSession?.data?.data.map((session) => (
                  <Select.Option key={session.id} value={session.session}>
                    {session.session}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="subjectId"
              label="Mata Pelajaran"
              className="form-item-kehadiran"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) =>
                  setOptionsValue((oldValue) => {
                    return {
                      ...oldValue,
                      pelajaran: value,
                    };
                  })
                }
                disabled={skeleton}
              >
                <Select.Option value={""}>Semua Mapel</Select.Option>
                {dataPelajararan?.data?.map((pelajaran) => (
                  <Select.Option key={pelajaran.id} value={pelajaran.name}>
                    {pelajaran.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={12}
            style={isDesktop ? { display: "flex", gap: "10px" } : {}}
          >
            <Form.Item
              name="classId"
              label="Kelas"
              className="form-item-kehadiran"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) =>
                  setOptionsValue((oldValue) => {
                    return {
                      ...oldValue,
                      kelas: value,
                    };
                  })
                }
              >
                <Select.Option value={""}>Semua kelas</Select.Option>
                {dataClass?.data?.map((kelas) => (
                  <Select.Option key={kelas.id} value={kelas.name}>
                    {kelas.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="date_in"
              label="Tanggal mulai"
              className="form-item-kehadiran"
              initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            >
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) =>
                  setDateIn(dayjs(value).format("YYYY-MM-DD"))
                }
              />
            </Form.Item>
            <Form.Item
              name="date_out"
              label="Tanggal akhir"
              className="form-item-kehadiran"
              initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            >
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) =>
                  setDateOut(dayjs(value).format("YYYY-MM-DD"))
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={handleSearch}
          placeholder=" cari nama Guru"
        />
      </div>
      <Table
        size={window.innerWidth > 1600 ? "middle" : "small"}
        tableLayout="auto"
        dataSource={dataSources}
        columns={columns}
        loading={isLoading || isFetching}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 1400 }
            : { y: "55vh", x: 1400 }
        }
        pagination={pagination}
      />
      <EditTeachers
        id={dataId}
        onUpdate={onUpdate}
        onCancel={onCancel}
        show={showEditPerSubject}
      />
      <DetailJurnalMengajar
        open={openDetail}
        setOpen={setOpenDetail}
        jurnalData={jurnalId}
      />
    </div>
  );
};

export default TheachersPresenceSubject;
