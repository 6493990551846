import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useProsemDetailList = (prosem_id, enabled) => {
  return useQuery(
    ["get-prosem_detail-list", prosem_id, enabled],
    () =>
      GetList(
        `/academics/program-semester/${prosem_id}/program-semester-detail`
      ),
    { enabled: enabled }
  );
};
