import { Input, Select, Table, Tooltip } from "antd";
// import { useNavigate } from "react-router-dom";
import ImportStudent from "../import/ImportStudent";
import { Popconfirm, Space, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useClassList } from "hooks/classes-hook/useClassList";
import { useStudentAlumniPagination } from "hooks/students-hook/useStudentAlumniPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Students = () => {
  const [dataId, setDataId] = useState("");
  const [classId, setClassId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showImportStudent, setShowImportStudent] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const navigate = useNavigate();

  // fetch student
  const { data, isLoading, isFetching, refetch } = useStudentAlumniPagination({
    dataTable,
    keyword,
    classId,
    foundationId,
  });

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetchClass list
  const { data: dataClass, refetch: fetchClass } = useClassList(true);

  // fetch student permission
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuStudents");

  // Buttons Permission
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditStudent");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailStudent");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteStudent");

  useEffect(() => {
    fetchClass();
  }, [fetchClass]);

  const onCancel = () => {
    setShowImportStudent(false);
  };

  const onImport = useCallback(() => {
    setShowImportStudent(false);
    refetch();
  }, [refetch]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 50 : 50,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      align: "left",
      fixed: "left",
      width: 150,
    },
    {
      title: "Yayasan",
      dataIndex: "foundationName",
      hidden: !isSuperAdmin,
      width: 120,
    },
    {
      title: "Kelas",
      dataIndex: "class",
      align: "left",
      width: 70,
    },
    { title: "NIS", dataIndex: "nis", align: "left" },
    {
      title: "Universitas",
      dataIndex: "institution",
      align: "left",
      width: 170,
    },
    { title: "Jurusan", dataIndex: "department", align: "left", width: 150 },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/academics/students/", dataId, refetch })
              }
              disabled={btnDelete && btnDelete.type === "disabled"}
              style={{ cursor: "pointer" }}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDelete?.type === "disabled") {
                    return;
                  } else {
                    setDataId(id);
                  }
                }}
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  navigate(`/academic/alumni/${id}/edit`);
                }
              }}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              onClick={() => {
                if (btnDetail?.type === "disabled") {
                  return;
                } else {
                  navigate("/academic/alumni/" + id);
                }
              }}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
            >
              Detail
            </Tag>
          </Space>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        foundationName: data.foundation?.name,
        index: index + 1,
        class: data.class ? data.class.name : "",
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Alumni</h1>

        {/* ---dev */}
        {/* <Space>
          <Button
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() => setShowImportStudent(true)}
          >
            Import
          </Button>
          <Button
            onClick={handleExport}
            hidden={!btnExport}
            disabled={btnExport?.type === "disabled"}
          >
            Export
          </Button>
          <Button
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            onClick={handleAdd}
            type="primary"
          >
            Tambah Siswa
          </Button>
        </Space> */}
        {/* --dev */}
      </div>
      <div
        style={{
          maxWidth: 600,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: 10,
        }}
      >
        <Input
          value={keyword}
          onChange={handleChange}
          prefix={<SearchOutlined />}
          placeholder="cari alumni..."
        />
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: "100%" }}
          placeholder="Kelas"
          onChange={setClassId}
        >
          <Select.Option value="">Semua Kelas</Select.Option>
          {dataClass?.data?.map((x) => (
            <Select.Option key={x.id} value={x.id}>
              {x.name}
            </Select.Option>
          ))}
        </Select>
        {roles.some((x) => x === "super_admin") && (
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150, maxWidth: 150 }}
              onChange={(x) => setFoundationId(x)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">
                ALL
              </Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        )}
      </div>
      <Table
        bordered
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataKey}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
      />
      <ImportStudent
        show={showImportStudent}
        onCancel={onCancel}
        onImport={onImport}
      />
    </div>
  );
};

export default Students;
