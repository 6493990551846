import { Image, Skeleton, Tag } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Presence from "./Presence";
import RichTextEditor from "@mantine/rte";

const DetailSubActivity = () => {
  const [loading, setLoading] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [dataDetail, setDataDetail] = useState();

  const { REACT_APP_HOST_API } = process.env;
  const { session_id } = useParams();
  const json2html = require("html2json").json2html;

  const fetchDetail = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/sub-activities/${session_id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      setDataDetail(data?.data);
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, [REACT_APP_HOST_API, session_id]);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  return (
    <>
      {loading ? (
        new Array(4).fill(null).map((_, x) => <Skeleton key={x} active />)
      ) : (
        <>
          <div className="table-employee-wrapper">
            <div className="table1">
              <table className="detail-employee__table">
                <tbody>
                  <tr>
                    <th>Nama</th>
                    <td>: {dataDetail?.name}</td>
                  </tr>
                  <tr>
                    <th>Tanggal</th>
                    <td>: {dataDetail?.date}</td>
                  </tr>
                  <tr>
                    <th>Foto kegiatan</th>
                    <td>:</td>
                  </tr>
                  {dataDetail?.images?.length > 0 && (
                    <tr style={{ border: "none" }}>
                      <td>
                        <Image.PreviewGroup>
                          {dataDetail?.images?.map((x, i) => (
                            <Image key={i} width={200} src={x["1"]} />
                          ))}
                        </Image.PreviewGroup>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>Catatan/Notulen</th>
                    {dataDetail?.note && (
                      <td>
                        <Tag
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => setShowNote(!showNote)}
                        >
                          {showNote ? "hide" : "show"}
                        </Tag>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              {showNote && (
                <RichTextEditor
                  value={dataDetail?.note ? json2html(dataDetail?.note) : ""}
                  id="rte"
                  readOnly
                />
              )}
            </div>
          </div>
          <Presence />
        </>
      )}
    </>
  );
};

export default DetailSubActivity;
