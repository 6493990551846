import { getPermission } from "api/getPermission";
import { useQuery } from "react-query";

export const usePermission = (module, menu) => {
  return useQuery(
    ["get-permission", module, menu],
    () => getPermission(module, menu),
    { refetchOnWindowFocus: false }
  );
};
