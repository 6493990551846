import { Form, InputNumber, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

export default function RealokasiBilling({ data, open, setOpen }) {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const [isLoad, setIsLoad] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setOpen();
  };

  useEffect(() => {
    if (open) {
      const val = data?.amount - data?.remaining_amount;
      form.setFieldsValue({ amount_paid: val });
    }
    // eslint-disable-next-line
  }, [open]);

  const onSubmit = async () => {
    try {
      const { amount_paid } = form.getFieldsValue();
      if (amount_paid < 0) {
        message.error("Nominal tidak boleh negatif!");
        return;
      }
      setIsLoad(true);
      const { data: item } = await axios.post(
        REACT_APP_HOST_API + `/finance/billings/${data?.id}/reallocate`,
        { amount_paid },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(item.message || "Realokasi Berhasil!");
      handleCancel();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      confirmLoading={isLoad}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nominal Terbayar"
          name="amount_paid"
          rules={[{ required: true }]}
        >
          <InputNumber
            formatter={(value) =>
              `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
