import React from 'react'
import ReportCard from "./ReportCard"
import { Skeleton } from "antd"

const ReportList = ({ dataPermission, dataSource, refetch, isFetching }) => {
   return (
      <div className="report-monthly-list">
         {
            isFetching && <Skeleton active />
         }
         {!isFetching &&
            dataSource?.map((data, index) => (
               <ReportCard
                  key={index}
                  dataPermission={dataPermission}
                  name={data.name}
                  unit={data.unitName}
                  dataUnit={data.unit}
                  id={data.id}
                  refetch={refetch}
               />
            ))

         }
      </div>
   )
}

export default ReportList