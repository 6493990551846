import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useStudentRaportList = (id_raport, keyword) => {
  return useQuery(
    ["get-student-raport-list", id_raport, keyword],
    () =>
      GetList(
        `/academics/raports/${id_raport}/student-raports?keyword=${keyword}`
      ),
    {
      enabled: true,
    }
  );
};
