import LayoutExecutive from "Layouts/LayoutExecutive/LayoutExecutive";
import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuExecutive from "components/Auth/RequireMenuExecutive";
import MasterExecutive from "pages/si-executive/MasterExecutive";
import Alumni from "pages/si-executive/alumni/Alumni";
import Karyawan from "pages/si-executive/karyawan/Karyawan";
import PpdbDiterima from "pages/si-executive/ppdb/diterima/PpdbDiterima";
import PpdbPendaftar from "pages/si-executive/ppdb/pendaftar/PpdbPendaftar";
import Siswa from "pages/si-executive/siswa/Siswa";

export const DataRoutesExecutive = [
  {
    path: "executive",
    element: (
      <RequireAuth>
        <RequireMenuExecutive>
          <LayoutExecutive content={<MasterExecutive />} />
        </RequireMenuExecutive>
      </RequireAuth>
    ),
  },
  {
    path: "executive/alumni",
    element: (
      <RequireAuth>
        <RequireMenuExecutive menu="mnuAlumni">
          <LayoutExecutive content={<Alumni />} />
        </RequireMenuExecutive>
      </RequireAuth>
    ),
  },
  {
    path: "executive/karyawan",
    element: (
      <RequireAuth>
        <RequireMenuExecutive menu="mnuKaryawan">
          <LayoutExecutive content={<Karyawan />} />
        </RequireMenuExecutive>
      </RequireAuth>
    ),
  },
  {
    path: "executive/siswa",
    element: (
      <RequireAuth>
        <RequireMenuExecutive menu="mnuSiswa">
          <LayoutExecutive content={<Siswa />} />
        </RequireMenuExecutive>
      </RequireAuth>
    ),
  },
  {
    path: "executive/ppdb-pendaftar",
    element: (
      <RequireAuth>
        <RequireMenuExecutive menu="mnuPPDB">
          <LayoutExecutive content={<PpdbPendaftar />} />
        </RequireMenuExecutive>
      </RequireAuth>
    ),
  },
  {
    path: "executive/ppdb-diterima",
    element: (
      <RequireAuth>
        <RequireMenuExecutive menu="mnuPPDB">
          <LayoutExecutive content={<PpdbDiterima />} />
        </RequireMenuExecutive>
      </RequireAuth>
    ),
  },
];
