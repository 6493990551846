import { MenuOutlined } from "@ant-design/icons";
import NavMenus from "Layouts/NavMenus";
import { Drawer, Layout, Space } from "antd";
import LogoFG from "assets/img/logoFG.png";
import BreadCrumb from "components/UI/BreadCrumb";
import React, { useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks/usePermissions";
import { BsCalendar2DateFill } from "react-icons/bs";
import { FaCodeBranch, FaCubes, FaFolderOpen } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { SiCodeforces } from "react-icons/si";
import { useParams } from "react-router-dom";
import {
  AppstoreFilled,
  DashboardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import { encryptCookies } from "components/Helper/CookiesHelper";
import Notifications from "./Notifications";
import DynamicTitle from "components/UI/DynamicTitle";
import NavUserProfile from "components/UI/NavUserProfile";
const { Sider, Content, Header } = Layout;

const LayoutHrd = (props) => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = usePermission("mdlHRD");
  // fetch notif
  // fetch division pagination
  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
    else return;
  };

  const handleSelectMenu = (param) => encryptCookies("defaultHrdMenu", param);

  // Menu variable
  const mnuDivision = data?.find((x) => x.id === "mnuDivision");
  const mnuEmployee = data?.find((x) => x.id === "mnuEmployee");
  const mnuActivity = data?.find((x) => x.id === "mnuActivity");
  const mnuPresence = data?.find((x) => x.id === "mnuPresence");
  const mnuRecapPresence = data?.find((x) => x.id === "mnuRecapPresence");
  const mnuLeaveDaily = data?.find((x) => x.id === "mnuLeaveDaily");
  const mnuLeaveSession = data?.find((x) => x.id === "mnuLeaveSession");
  const mnuMonthlyReport = data?.find((x) => x.id === "mnuMonthlyReport");
  const mnuTriwulanReport = data?.find((x) => x.id === "mnuTriwulanReport");
  const mnuUnit = data?.find((x) => x.id === "mnuUnit");

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuHrd") navigate("/hrd");
      else if (param.key === "mnuPresence") {
        if (id) navigate(`/hrd/activity/presence-${id}`);
        else navigate("/hrd/activity/presence-:id");
      } else if (param.key === "mnuRecapPresence") {
        if (id) navigate(`/hrd/activity/presence-${id}/recapPresence-${id}`);
        else navigate(`/hrd/activity/presence-:id/recapPresence-:id`);
      } else navigate("/hrd/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    { key: "mnuHrd", icon: <DashboardOutlined />, label: "Dashboard" },
    mnuUnit && {
      key: "mnuUnit",
      icon: <FaCubes />,
      label: "Unit",
      disabled: mnuUnit?.type === "disabled",
    },
    mnuDivision && {
      key: "mnuDivision",
      icon: <FaCodeBranch />,
      label: "Division",
      disabled: mnuDivision?.type === "disabled",
    },
    mnuEmployee && {
      key: "mnuEmployee",
      icon: <HiUsers />,
      label: "Employee",
      disabled: mnuEmployee?.type === "disabled",
    },
    mnuLeaveDaily && {
      key: "mnuLeaveDaily",
      icon: <BsCalendar2DateFill />,
      label: "Leave Daily",
      disabled: mnuLeaveDaily?.type === "disabled",
    },
    mnuLeaveSession && {
      key: "mnuLeaveSession",
      icon: <BsCalendar2DateFill />,
      label: "Leave Hourly",
      disabled: mnuLeaveSession?.type === "disabled",
    },
    mnuMonthlyReport && {
      key: "mnuMonthlyReport",
      icon: <FaFolderOpen />,
      label: "Monthly Report",
      disabled: mnuMonthlyReport?.type === "disabled",
    },
    mnuTriwulanReport && {
      key: "mnuTriwulanReport",
      icon: <FaFolderOpen />,
      label: "Triwulan Report",
      disabled: mnuTriwulanReport?.type === "disabled",
    },
    mnuActivity && {
      key: "Activity",
      label: "Activity",
      icon: <SiCodeforces />,
      children: [
        mnuActivity && {
          key: "mnuActivity",
          label: "Activity",
          disabled: mnuActivity?.type === "disabled",
        },
        mnuPresence && {
          key: "mnuPresence",
          label: "Presence",
          disabled: mnuPresence?.type === "disabled",
        },
        mnuRecapPresence && {
          key: "mnuRecapPresence",
          label: "Recap Presence",
          disabled: mnuRecapPresence?.type === "disabled",
        },
      ],
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return (
    <Layout>
      <DynamicTitle />
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={250}
      >
        <div className="mobile-menu-wrapper">
          <NavMenus
            items={items}
            theme="light"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultHrdMenu"}
            defaultOpen={["Activity"]}
          />
        </div>
      </Drawer>
      <div className="menu-mobile">
        <div onClick={() => navigate("/home")}>
          <img src={LogoFG} alt="logo fg" style={{ width: 22 }} />
          <h1 style={{ fontSize: "1.4em" }}>SMA FG</h1>
        </div>
        {/* notification--- */}
        <div style={{ position: "absolute", right: "60px" }}>
          <Notifications />

          <NavUserProfile
            isOnlyIcon={true}
            itemsUser={itemsUser}
            handleClickItemUser={handleClickItemUser}
          />
        </div>
        {/* end notification--- */}

        <MenuOutlined
          style={{ fontSize: "1.3em" }}
          onClick={() => setOpen(true)}
        />
      </div>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={window.innerWidth > 1600 ? 250 : 210}
      >
        <div className="logo">
          <img src={LogoFG} alt="fg" />
          <span>SMA FG</span>
        </div>
        <div className="sider-menu-wrapper">
          <NavMenus
            theme="dark"
            items={items}
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultHrdMenu"}
            defaultOpen={["Activity"]}
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header>
          {React.createElement(HiOutlineMenuAlt2, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          <div className="header-container">
            <Space>
              {/* notification--- */}
              <Notifications />
              {/* end notification--- */}
              <NavUserProfile
                isOnlyIcon={true}
                itemsUser={itemsUser}
                handleClickItemUser={handleClickItemUser}
              />
            </Space>
          </div>
        </Header>
        <BreadCrumb />
        <Content className="site-layout-background">{props.content}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutHrd;
