import { GetDetail } from "api/GetDetail";
import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useLeaveDailyPagination = ({
  date,
  dataTable,
  keyword,
  employeeId,
  status,
  leaveStatus,
}) => {
  return useQuery(
    [
      "get-leavedaily-pagination",
      date.from,
      date.to,
      dataTable.page,
      dataTable.limit,
      keyword,
      employeeId,
      status,
      leaveStatus,
    ],
    () =>
      GetPagination(
        `/leaves?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${
          keyword ? keyword : ""
        }&fromDate=${date.from}&toDate=${date.to}&employeeId=${
          employeeId ? employeeId : ""
        }&status=${status ? status : ""}&leaveStatus=${
          leaveStatus ? leaveStatus : ""
        }`
      )
  );
};

export const useLeaveDailyDetail = (id) => {
  return useQuery(["get-leave-detail", id], () => GetDetail(`/leaves/${id}`), {
    enabled: false,
  });
};

export const useLeaveSessionPagination = ({
  date,
  dataTable,
  keyword,
  employeeId,
  status,
}) => {
  return useQuery(
    [
      "get-leavesession-pagination",
      date.from,
      date.to,
      dataTable.page,
      dataTable.limit,
      keyword,
      employeeId,
      status,
    ],
    () =>
      GetPagination(
        `/leave-sessions?page=${dataTable.page}&limit=${
          dataTable.limit
        }&keyword=${keyword ? keyword : ""}&fromDate=${date.from}&toDate=${
          date.to
        }&employeeId=${employeeId ? employeeId : ""}
        &status=${status ? status : ""}`
      )
  );
};

export const useLeaveSessionDetail = (id) => {
  return useQuery(
    ["get-leavesession-detail", id],
    () => GetDetail(`/leave-sessions/${id}`),
    { enabled: false }
  );
};
