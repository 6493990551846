import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useGenerateRaportList = (
  keyword,
  year,
  semester,
  foundationId
) => {
  return useQuery(
    ["get-generate-raport-list", keyword, year, semester, foundationId],
    () =>
      GetList(
        `/academics/raports?keyword=${keyword}&tahunAjaran=${year}&semester=${semester}&foundationId=${foundationId}`
      ),
    {
      enabled: true,
    }
  );
};
