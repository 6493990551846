import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const DataRekeningTab = () => {
  const { student_id } = useParams();
  const { data, isLoading } = useParentList(student_id, true);
  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data.data && (
        <Fragment key={data.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Nama Bank</th>
                <td>{data.data.bank_name && data.data.bank_name}</td>
              </tr>
              <tr>
                <th>Rekening Atas Nama</th>
                <td>
                  {data.data.bank_account_owner && data.data.bank_account_owner}
                </td>
              </tr>
              <tr>
                <th>No. Rekening</th>
                <td>
                  {data.data.bank_account_number &&
                    data.data.bank_account_number}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataRekeningTab;
