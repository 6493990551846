import React from "react";

const MReportNote = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>CATATAN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Prestasi lain yang perlu dicatat</td>
          <td>: {data?.dataEmployee[0]?.monthlyReportEmployee?.achievement}</td>
        </tr>
        <tr>
          <td>Indisipliner tertentu yang perlu dicatat</td>
          <td>
            : {data?.dataEmployee[0]?.monthlyReportEmployee?.indisipliner}
          </td>
        </tr>
        <tr>
          <td>Saran dan perbaikan</td>
          <td>
            :{" "}
            {
              data?.dataEmployee[0]?.monthlyReportEmployee
                ?.suggestions_and_improvements
            }
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MReportNote;
