import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useStudentCandidate = (id, enabled) => {
  return useQuery(
    "get-student-candidate",
    () => GetList(`/ppdb/student-candidates/${id}`),

    { enabled }
  );
};
