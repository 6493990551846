import { Input, Modal, Table, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useMemberEmployee } from "hooks/activity-hook/useMemberEmployee";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AddPresence = ({ show, onCancel, onCreate }) => {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const { activity_id, session_id } = useParams();
  const { REACT_APP_HOST_API: api } = process.env;

  const { data, isFetching, refetch } = useMemberEmployee({
    activityId: activity_id,
    subActivityId: session_id,
    keyword,
  });

  useEffect(() => {
    if (show) refetch();

    if (data && selectedRow.length === 0)
      setSelectedRow([
        ...data?.data
          ?.filter((x) => x.role !== false)
          ?.map((x) => x.employee_id),
      ]);

    // eslint-disable-next-line
  }, [show, data, keyword, refetch]);

  const columns = [{ title: "Nama", dataIndex: "name" }];

  const dataSource = data?.data
    ?.sort((a, b) => a.role - b.role)
    .map((x) => {
      return { id: x.employee_id, key: x.employee_id, name: x?.employee?.name };
    });

  const onSelectChange = (selectedRowKeys) => setSelectedRow(selectedRowKeys);

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const onSubmit = async () => {
    const dataToSend = selectedRow.map((x) => {
      return {
        employeeId: x,
        activityId: activity_id,
        subActivityId: session_id,
      };
    });

    setLoading(true);
    try {
      const { data } = await axios.post(
        api + `/presences/${activity_id}/${session_id}`,
        { presences: dataToSend },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data?.message);
      onCreate();
      setSelectedRow([]);
    } catch (error) {
      if (error?.response?.data?.error) {
        alert(error?.response?.data?.error);
        return;
      } else if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
        return;
      } else alert(error.message);
    } finally {
      setLoading(false);
      setKeyword("");
    }
  };

  const onCancelModal = () => {
    onCancel();
    setKeyword("");
    setSelectedRow([]);
  };

  return (
    <Modal
      open={show}
      title="Absensi Kegiatan"
      onOk={onSubmit}
      onCancel={onCancelModal}
      style={{ top: 25 }}
      okText="Submit"
      cancelText="Batal"
      okButtonProps={{ loading }}
    >
      <Input
        allowClear
        value={keyword}
        onChange={({ target: { value } }) => setKeyword(value)}
      />

      <Table
        size="small"
        pagination={false}
        scroll={{ y: "55vh" }}
        loading={isFetching}
        dataSource={dataSource}
        columns={columns}
        rowSelection={{ ...rowSelection }}
      />
    </Modal>
  );
};

export default AddPresence;
