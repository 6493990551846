import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useProsemFilter = ({ subjectId, classId, enabled }) => {
  return useQuery(
    ["get-prosem-filter", subjectId, classId],
    () =>
      GetDetail(
        `/academics/program-semester?mode=list&subjectId=${subjectId}&classId=${classId}`
      ),
    { enabled }
  );
};
