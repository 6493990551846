import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { encryptCookies } from "components/Helper/CookiesHelper";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  const onSubmit = async () => {
    try {
      const { email } = await form.validateFields();
      setIsLoad(true);
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/auth/send-otp-mail",
        {
          email,
        }
      );
      message.success(data?.message);
      encryptCookies("emailForgotPassword", email);
      navigate("/otp-verification");
    } catch (error) {
      console.log(error);
      alert("Gagal mengirim OTP!");
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ maxWidth: "370px", marginTop: "80px", padding: "20px" }}>
        <h1 style={{ fontWeight: "bold" }}>Lupa Password?</h1>
        <p style={{ fontSize: "14px", lineHeight: "20px" }}>
          Jangan khawatir itu terjadi!. silakan masukkan alamat email yang
          tertaut dengan akun Anda
        </p>

        <Form form={form} style={{ marginTop: "50px" }} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input size="large" placeholder="Masukan Email..." />
          </Form.Item>
        </Form>

        <Button
          onClick={onSubmit}
          type="primary"
          size="large"
          style={{ width: "100%" }}
          loading={isLoad}
        >
          Kirim Kode OTP
        </Button>
      </div>
    </div>
  );
}
