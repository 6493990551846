import { Button, ConfigProvider } from "antd";
import Backdrop from "components/UI/Backdrop";
import {
  useMonthlyReportDetail,
  useMonthlyReportEmployee,
} from "hooks/monthly-report/apiCalls";
import { useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import MReportContent from "./MReportContent";
import MReportHeader from "./MReportHeader";
import MReportNote from "./MreportNote";
import "./Report.css";
import ReportLogo from "./ReportLogo";
import ReportSignature from "./ReportSignature";
import { useEffect } from "react";
import { decryptCookies } from "components/Helper/CookiesHelper";
import Cookies from "js-cookie";

const MReportEmployee = () => {
  const { mreport_id, mreport_employee_id } = useParams();
  const employeeId = Cookies.get("employeeId") && decryptCookies("employeeId");

  const { data, isLoading, refetch } =
    useMonthlyReportEmployee(mreport_employee_id);
  const {
    data: data2,
    isLoading: isLoading2,
    refetch: refetch2,
  } = useMonthlyReportDetail({
    id: mreport_id,
    keyword: "",
    employeeId,
    dataTable: {},
  });

  useEffect(() => {
    if (mreport_employee_id !== ":mreport_employee_id") refetch();
    else refetch2();
  }, [mreport_employee_id, refetch, refetch2]);

  const { toPDF, targetRef } = usePDF({
    filename: `Rapor ${data?.dataEmployee[0]?.period_of_assesment},  ${data?.dataEmployee[0]?.name}.pdf`,
  });

  return (
    <>
      {isLoading || isLoading2 ? (
        <Backdrop loading={isLoading || isLoading2} />
      ) : (
        <div className="report_container">
          <div>
            <ConfigProvider
              theme={{ token: { fontFamily: "Onest, sans-serif" } }}
            >
              <Button type="primary" onClick={() => toPDF()}>
                Download
              </Button>
            </ConfigProvider>
          </div>
          <div className="report_wrapper" ref={targetRef}>
            <ReportLogo data={data ? data : data2} />
            <MReportHeader data={data ? data : data2} />
            <MReportContent data={data ? data : data2} />
            <MReportNote data={data ? data : data2} />
            <ReportSignature data={data ? data : data2} />
          </div>
        </div>
      )}
    </>
  );
};

export default MReportEmployee;
