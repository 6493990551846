import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useEmployeeDetail = (id, enabled) => {
  return useQuery(
    ["get-employee-detail", id],
    () => GetDetail(`/employee/${id}`),
    { enabled }
  );
};
