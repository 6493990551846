import { Button, ConfigProvider } from "antd";
import Backdrop from "components/UI/Backdrop";
import { useTriwulanReportDetail } from "hooks/triwulan-report-hook/apiCalls";
import ReportLogo from "pages/si-hrd/monthly-report/employee-report/ReportLogo";
import ReportSignature from "pages/si-hrd/monthly-report/employee-report/ReportSignature";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import "../../monthly-report/employee-report/Report.css";
import TReportContent from "./TReportContent";
import TReportHeader from "./TReportHeader";
import TReportNote from "./TReportNote";
import TReportPenilai from "./TReportPenilai";
import TReportScore from "./TReportScore";

const EmployeeTriwulanReport = () => {
  const { triwulan_id, triwulan_employee_id } = useParams();

  const { data, isLoading, refetch } = useTriwulanReportDetail({
    id: triwulan_id,
    keyword: "",
    employeeId: triwulan_employee_id,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { toPDF, targetRef } = usePDF({
    filename: `Rapor ${data?.dataEmployee?.period_of_assessment},  ${data?.dataEmployee?.name}.pdf`,
  });

  return (
    <>
      {isLoading ? (
        <Backdrop loading={isLoading} />
      ) : (
        <div className="report_container">
          <div>
            <ConfigProvider
              theme={{ token: { fontFamily: "Onest, sans-serif" } }}
            >
              <Button type="primary" onClick={() => toPDF()}>
                Download
              </Button>
            </ConfigProvider>
          </div>
          <div className="report_wrapper triwulan" ref={targetRef}>
            <ReportLogo data={data} />
            <TReportHeader data={data} />
            <TReportContent data={data} />
            <TReportScore data={data} />
            <TReportNote data={data} />
            <TReportPenilai data={data} />
            <ReportSignature data={data} />
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeTriwulanReport;
