import { Skeleton } from "antd";
import { useBukuNilaiDetail } from "hooks/bukunilai-hook/useBukuNilaiDetail";
import React from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";

export default function DetailBukuNilai() {
  const { bukunilai_id } = useParams();

  const { data, isLoading } = useBukuNilaiDetail(
    bukunilai_id,
    bukunilai_id ? true : false
  );

  // console.log(data);

  return (
    <div>
      <div className="table-header">
        <h1>Detail Buku Nilai</h1>
      </div>
      <>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Guru</th>
                <td>{data?.data?.teachers?.employee?.name}</td>
              </tr>
              <tr>
                <th>Kelas</th>
                <td>{data?.data?.classes?.name}</td>
              </tr>
              <tr>
                <th>Pelajaran</th>
                <td>{data?.data?.mapels?.name}</td>
              </tr>
              <tr>
                <th>Siswa</th>
                <td>{data?.data?.students?.name}</td>
              </tr>
              <tr>
                <th>Nis</th>
                <td>{data?.data?.students?.nis}</td>
              </tr>
              <tr>
                <th>Nilai</th>
                <td>{data?.data?.nilai}</td>
              </tr>
              <tr>
                <th>Materi Prosem</th>
                <td>{data?.data?.programSemesterDetail?.materi}</td>
              </tr>
              <tr>
                <th>Kompetensi Dasar</th>
                <td>{data?.data?.programSemesterDetail?.kompetensi_dasar}</td>
              </tr>
              <tr>
                <th>Materi</th>
                <td>{data?.data?.material}</td>
              </tr>
              <tr>
                <th>Tipe</th>
                <td>{data?.data?.type}</td>
              </tr>
            </tbody>
          </table>
        )}
      </>
    </div>
  );
}
