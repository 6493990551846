import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Modal, message } from "antd";
import axios from "axios";
import { encryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

const RegisterPpdb = ({ open, setOpen, emailReg }) => {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/ppdb/register",
        values
      );

      message.success(
        "Berhasil melakukan registrasi, silahkan verifikasi email anda"
      );

      encryptCookies("name", data.data.name);
      // navigate(data.actionUrl);
      form.resetFields();
      setOpen();
    } catch (error) {
      // console.log(error.response.data.data);

      alert(
        error?.response?.data?.data
          // .map((item) => `${item.field}, ${item.message}`)
          .map((item) => `${item.field}, harus unik`)
          .join("\n")
      );
    } finally {
      setLoading(false);
    }
  };

  const getDate = async () => {
    try {
      await form.setFieldsValue({
        email: emailReg,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDate();
    // eslint-disable-next-line
  }, [open]);

  const handleCancle = () => {
    form.resetFields();
    setOpen();
  };
  return (
    <Modal
      open={open}
      onCancel={handleCancle}
      cancelText="Batal"
      okText="Registrasi"
      closable={false}
      onOk={handleRegister}
      confirmLoading={loading}
    >
      <div className="table-header">
        <h1>Registrasi</h1>
      </div>
      <Divider />
      <Form layout="vertical" form={form}>
        <Form.Item
          label="NISN"
          name="nisn"
          rules={[
            {
              required: true,
            },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Masukkan hanya Angka",
            },
            {
              min: 3,
            },
          ]}
        >
          <Input prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: "true",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "please enter a valid email" },
          ]}
        >
          <Input prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[{ required: true, min: 6 }]}
        >
          <Input.Password prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item
          label="Konfirmasi Password"
          name="password_confirmation"
          hasFeedback
          rules={[
            { required: true },
            { min: 6 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password harus sama"));
              },
            }),
          ]}
        >
          <Input.Password prefix={<LockOutlined />} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegisterPpdb;
