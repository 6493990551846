import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Dropdown, Space, Table } from "antd";
import dayjs from "dayjs";
import { useRecapSubActivityPagination } from "hooks/activity-hook/useRecapSubActivityPagination";
import { usePermission } from "hooks/usePermissions";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";

const columns = [
  { title: "No", dataIndex: "index", width: 50, fixed: "left" },
  {
    title: "Nama",
    dataIndex: "name",
    fixed: "left",
    width: window.innerWidth > 600 ? 250 : 150,
  },
  {
    title: "Total Sesi",
    dataIndex: "total_sessions",
    align: "center",
    ellipsis: true,
  },
  { title: "Kehadiran", dataIndex: "presence_count", align: "center" },
  { title: "Jumlah", dataIndex: "percentage", align: "center" },
];

const RecapSubActivity = () => {
  // custom date variables
  const newDate = new Date();
  const firstDate = new Date(newDate.getFullYear(), newDate.getMonth() - 1, 25);
  const lastDate = new Date(newDate.getFullYear(), newDate.getMonth(), 24);
  const [open, setOpen] = useState("");
  const [dataTable, setDataTable] = useState({
    per_page: 100,
    current_page: 1,
  });
  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });

  const { activity_id } = useParams();

  const { data, isFetching } = useRecapSubActivityPagination({
    id: activity_id,
    dataTable,
    date,
  });

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuActivity");

  const btnExport = dataPermission?.find((x) => x.id === "btnExport");

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: i + 1,
    index: i + 1,
    name: x.employee?.name,
    percentage: x.percentage + "%",
  }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => ({
        ...value,
        current_page: curr,
        per_page: size,
      }));
    },
  };

  const dataToExport = data?.data?.data?.map((x, i) => ({
    Nama: x.employee?.name,
    "Total Sesi": x.total_sessions,
    Kehadiran: x.presence_count,
    Jumlah: x.percentage + "%",
  }));

  const onExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet 1");
    XLSX.writeFile(wb, "rekap-presensi.xlsx");
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <div>Tanggal Awal</div>
          <DatePicker
            value={dayjs(date.from)}
            style={{ width: "100%" }}
            onChange={(value) => {
              setDate((curr) => ({
                ...curr,
                from:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(firstDate).format("YYYY-MM-DD"),
              }));
            }}
            placeholder="Awal"
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div>Tanggal Akhir</div>
          <DatePicker
            value={dayjs(date.to)}
            onChange={(value) =>
              setDate((curr) => ({
                ...curr,
                to:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(lastDate).format("YYYY-MM-DD"),
              }))
            }
            placeholder="Akhir"
          />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <Button style={{ width: "100%" }} type="primary">
          Close
        </Button>
      ),
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key === "3") setOpen(false);
  };

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };

  return (
    <>
      <div className="table-header">
        <h1>Rekap Kegiatan</h1>
        <Space>
          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            onOpenChange={handleOpenChange}
            open={open}
            trigger={["click"]}
          >
            <Button icon={<FilterOutlined />}>Filter</Button>
          </Dropdown>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => {
              if (btnExport?.type === "disabled") return;
              else onExport();
            }}
            hidden={!btnExport}
            disabled={btnExport?.type === "disabled"}
          >
            Export
          </Button>
        </Space>
      </div>
      <div className="table-employee-wrapper">
        <div className="table1">
          <table className="detail-employee__table">
            <tbody>
              <tr>
                <th>Nama</th>
                <td>: {data?.activity?.name}</td>
              </tr>
              <tr>
                <th>Tipe</th>
                <td>
                  :{" "}
                  {data?.activity?.activity_type === "fixed+time"
                    ? "Tetap"
                    : "Tidak tetap"}
                </td>
              </tr>
              <tr>
                <th>Divisi</th>
                <td>: {data?.activity?.division?.name}</td>
              </tr>
              <tr>
                <th>Kategori</th>
                <td>: {data?.activity?.categoryActivity?.name}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table2">
          <table className="detail-employee__table">
            <tbody>
              <tr>
                <th>Default Pertemuan</th>
                <td>: {data?.activity?.default}</td>
              </tr>
              <tr>
                <th>Durasi Kerja</th>
                <td>: {data?.activity?.max_working_duration}</td>
              </tr>
              <tr>
                <th>Jam Masuk</th>
                <td>: {data?.activity?.time_in_start}</td>
              </tr>
              <tr>
                <th>Jam Pulang</th>
                <td>: {data?.activity?.time_out_end}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={{ x: 600, y: window.innerHeight > 650 ? "55vh" : "45vh" }}
      />
    </>
  );
};

export default RecapSubActivity;
