import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const DataAkademikSiswaTab = () => {
  const { student_id } = useParams();
  const { data, isLoading } = useParentList(student_id, true);

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data.data && (
        <Fragment key={data.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>No Ujian Nasional</th>
                <td>{data.data.nat_exam_no && data.data.nat_exam_no}</td>
              </tr>
              <tr>
                <th>No Seri Ijazah</th>
                <td>
                  {data.data.junior_hs_cert_no && data.data.junior_hs_cert_no}
                </td>
              </tr>
              <tr>
                <th>Asal Sekolah</th>
                <td>{data.data.junior_hs_name && data.data.junior_hs_name}</td>
              </tr>
              <tr>
                <th>No Akta Lahir</th>
                <td>{data.data.birth_cert_no && data.data.birth_cert_no}</td>
              </tr>
              <tr>
                <th>Penerima KPS</th>
                <td>
                  {data.data.has_kps === null
                    ? "-"
                    : data.data.has_kps
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>No KPS</th>
                <td>{data.data.kps_number && data.data.kps_number}</td>
              </tr>
              <tr>
                <th>Penerima KIP</th>
                <td>
                  {data.data.has_kip === null
                    ? "-"
                    : data.data.has_kip
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>No KIP</th>
                <td>{data.data.kip_number && data.data.kip_number}</td>
              </tr>
              <tr>
                <th>Nama di KIP</th>
                <td>
                  {data.data.name_on_kip === null
                    ? "-"
                    : data.data.name_on_kip
                    ? "Ada"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>Penerima KKS</th>
                <td>
                  {data.data.has_kks === null
                    ? "-"
                    : data.data.has_kks
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>No KKS</th>
                <td>{data.data.kks_number && data.data.birth_cert_no}</td>
              </tr>
              <tr>
                <th>Layak PIP</th>
                <td>
                  {data.data.pip_eligible === null
                    ? "-"
                    : data.data.pip_eligible
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>Alasan PIP</th>
                <td>{data.data.pip_desc && data.data.pip_desc}</td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataAkademikSiswaTab;
