import useConvertImgToBase64 from "components/Helper/useConvertImgToBase64";

const ReportSignature = ({ data }) => {
  const imageUrl = data?.dataUnit?.signature;

  const imgBase64 = useConvertImgToBase64(imageUrl);

  return (
    <div className="signature-wrapper">
      <div id="signature-item">
        <p>Penanggung Jawab</p>
        <img src={imgBase64} alt="signature" />
        <p>{data?.dataUnit?.unit_lead_employee_name}</p>
      </div>
    </div>
  );
};

export default ReportSignature;
