import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import CreateFondation from "../create/CreateFoundation";
import EditFoundation from "../edit/EditFoundation";
import { useFoundationPagination } from "hooks/foundation-hook/useFoundationPagination";
import DetailFoundation from "../detail/DetailFoundation";
import { useNavigate } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Foundation = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showEdit, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [showDetail, setShowDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const navigate = useNavigate();

  // fetch division pagination
  const {
    data: dataFoundation,
    isLoading,
    isFetching,
    refetch,
  } = useFoundationPagination(dataTable, keyword);

  // fetch permission
  const { data: dataPermission } = usePermission(
    "mdlFoundation",
    "mnuFoundation"
  );

  const onCreate = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowUpdate(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowCreate(false);
    setShowUpdate(false);
  };

  const onClose = () => {
    setShowDetail(false);
  };

  const handleChange = async (param) => {
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddFoundation");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditFoundation");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteFoundation");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailFoundation");
  const btnUnit = dataPermission?.find((x) => x.id === "btnUnitFoundation");

  const dataSource = dataFoundation?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
      };
    });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
      fixed: "left",
      responsive: ["sm"],
    },
    { title: "Nama", dataIndex: "name", fixed: "left", width: 150 },
    { title: "Email", dataIndex: "email", width: 150 },
    { title: "Alamat", dataIndex: "address" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: 300,
      render: (id, dataFoundation) => {
        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({
                  url: "/foundation/foundations/",
                  dataId: id,
                  refetch,
                })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowUpdate(true);
                  setDataId(id);
                }
              }}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") {
                  return;
                } else {
                  setShowDetail(true);
                  setDataDetail(dataFoundation);
                }
              }}
            >
              Detail
            </Tag>
            <Tag
              color="blue"
              hidden={!btnUnit}
              style={
                btnUnit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnUnit?.type === "disabled") {
                  return;
                } else {
                  navigate(`${id}`);
                }
              }}
            >
              Units
            </Tag>
          </>
        );
      },
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataFoundation?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      }),
  };

  return (
    <>
      <div className="table-header">
        <h1>Daftar Foundation</h1>
        <Space>
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="cari nama foundation"
          />
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <Table
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 800 }
            : window.innerHeight < 760
            ? { y: "50vh", x: 800 }
            : { y: "55vh", x: 800 }
        }
      />
      <CreateFondation
        onCancel={onCancel}
        onCreate={onCreate}
        show={showCreate}
      />
      <EditFoundation
        id={dataId}
        onCancel={onCancel}
        onUpdate={onUpdate}
        show={showEdit}
      />
      <DetailFoundation
        show={showDetail}
        foundation={dataDetail}
        onClose={onClose}
      />
    </>
  );
};

export default Foundation;
