import {
  AppstoreFilled,
  DashboardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import { FaFolder, FaUserLock, FaUsersCog } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const LayoutSystemLogic = () => {
  const [open, setOpen] = useState(false);

  const email = Cookies.get("email") && decryptCookies("email");
  const navigate = useNavigate();

  const { data: dataMenus } = usePermission("mdlSystem");

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
    else return;
  };

  const handleSelectMenu = (param) => {
    encryptCookies("defaultSystemMenu", param);
  };

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") return;
      else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuSystem") navigate("/system");
      else if (param.key === "mnuUsers") navigate("/system/users");
      else if (param.key === "mnuUsersParent") navigate("/system/users-parent");
      else navigate("/system/" + param.key.toLowerCase().split("mnu")[1] + "s");
    }

    setOpen(false);
  };

  const menuRole = dataMenus?.find((x) => x.id === "mnuRole");
  const menuUsers = dataMenus?.find((x) => x.id === "mnuUsers");
  const menuModule = dataMenus?.find((x) => x.id === "mnuModule");
  const menuUsersParent = dataMenus?.find((x) => x.id === "mnuUsersParent");

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    { key: "mnuSystem", icon: <DashboardOutlined />, label: "Dashboard" },
    menuModule && {
      key: "mnuModule",
      icon: <FaFolder />,
      label: "Module",
      disabled: menuModule?.type === "disabled",
    },
    menuRole && {
      key: "mnuRole",
      icon: <FaUserLock />,
      label: "Role",
      disabled: menuRole?.type === "disabled",
    },
    menuUsers && {
      key: "mnuUsers",
      icon: <HiUsers />,
      label: "Users",
      disabled: menuUsers?.type === "disabled",
    },
    menuUsersParent && {
      key: "mnuUsersParent",
      icon: <FaUsersCog />,
      label: "Users Parent",
      disabled: menuUsersParent?.type === "disabled",
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return {
    open,
    email,
    items,
    items2,
    itemsUser,
    setOpen,
    handleClickMenu,
    handleClickItemUser,
  };
};

export default LayoutSystemLogic;
