import { usePelajaranDetail } from 'hooks/pelajaran-hook/usePelajaranDetail';
import  { useEffect } from 'react';

const DetailPelajaranLogic = (props) => {
  const {
    data,
    isLoading,
    refetch: fetchData,
  } = usePelajaranDetail(props.id, false);

  useEffect(() => {
    if (props.show) {
      fetchData();
    }
  }, [props.show, fetchData]);


  return {
    data,
    isLoading,
  };
};

export default DetailPelajaranLogic;
