import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const RequireMenuExecutive = ({ menu, children }) => {
  const [menus, setMenus] = useState([]);

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const { data } = await axios.get(REACT_APP_HOST_API, {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        });

        if (
          data.data[0].role.permissions.modules.find(
            (x) => x.id === "mdlExecutiveSummary"
          )
        ) {
          setMenus(
            data.data[0].role.permissions.modules.find(
              (x) => x.id === "mdlExecutiveSummary"
            ).menus
          );
        }
      } catch (error) {
        alert(`Gagal mengambil data menus
${error.message}`);
        return;
      }
    };

    fetchDashboard();
  }, [REACT_APP_HOST_API]);

  if (!Cookies.get("perExecutive")) {
    return <Navigate to="/home" />;
  }

  if (menus.length > 0) {
    if (menu) {
      const targettedMenu = menus.find((x) => x.id === menu);
      if (!targettedMenu || targettedMenu.type === "disabled") {
        return <Navigate to="/permission-error" />;
      }
    }
  }

  return children;
};

export default RequireMenuExecutive;
