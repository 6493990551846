import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useDivisionDetail = (id, enabled, keyword = "") => {
  return useQuery(
    ["get-division-detail", id, keyword],
    () => GetDetail(`/division/${id}?keyword=${keyword}`),
    { enabled }
  );
};
