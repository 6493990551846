import {
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
  FolderOpenTwoTone,
  RightOutlined,
  SnippetsTwoTone,
} from "@ant-design/icons";
import { Col, Dropdown, Popconfirm, Row } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { TriwulanReportContext } from "context/TriwulanReportContext";
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const nonDisabled = { marginTop: "0px", marginBottom: "0px" };
const cardWrapper = {
  maxWidth: "400px",
  height: "86px",
  borderRadius: "10px",
  border: "1px solid #D9D9D9",
  paddingTop: "20px",
  paddingBottom: "20px",
  cursor: "pointer",
};
const disabled = { color: "#ccc", cursor: "not-allowed" };

const TriwulanCard = ({ dataPermission, triwulan, refetch }) => {
  const [open, setOpen] = useState(false);

  const roles = Cookies.get("role") && decryptCookies("role").split(",");
  const employeeId = Cookies.get("employeeId") && decryptCookies("employeeId");
  const navigate = useNavigate();
  const isSuperAdmin = roles.some(
    (x) => x === "super_admin" || x === "admin_foundation"
  );

  const isLead = triwulan?.unit?.employeeUnits[0]?.employee_id === employeeId;
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditTriwulan");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteTriwulan");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailTriwulan");
  const btnRecap = dataPermission?.find((x) => x.id === "btnRecapTriwulan");

  const { setReportId, setShowEdit } = useContext(TriwulanReportContext);

  const items = [
    {
      key: "1",
      label: (
        <p style={btnDetail?.type === "disabled" ? disabled : nonDisabled}>
          <span style={{ marginRight: "15px" }}>
            <EyeTwoTone twoToneColor="#1CEF92" />
          </span>
          Detail
        </p>
      ),
      hidden: !btnDetail,
      disabled: btnDetail?.type === "disabled",
    },
    {
      key: "2",
      label: (
        <p style={btnRecap?.type === "disabled" ? disabled : nonDisabled}>
          <span style={{ marginRight: "15px" }}>
            <SnippetsTwoTone twoToneColor="#34ebc6" />
          </span>
          Rekap
        </p>
      ),
      hidden: !btnRecap || (!isLead && !isSuperAdmin),
      disabled: btnRecap?.type === "disabled",
    },
    {
      key: "3",
      label: (
        <p
          style={btnEdit?.type === "disabled" ? disabled : nonDisabled}
          onClick={() => {
            if (btnEdit?.type === "disabled") return;
            else {
              setShowEdit(true);
              setReportId(triwulan.id);
            }
          }}
        >
          <span style={{ marginRight: "15px" }}>
            <EditTwoTone />
          </span>
          Edit
        </p>
      ),
      hidden: !btnEdit || (!isLead && !isSuperAdmin),
      disabled: btnEdit?.type === "disabled",
    },
    {
      key: "4",
      label: (
        <Popconfirm
          disabled={btnDelete?.type === "disabled"}
          title="Yakin ingin menghapus data?"
          okText="Hapus"
          cancelText="Batal"
          onConfirm={() =>
            DeleteApi({
              url: "/triwulans/",
              dataId: triwulan.id,
              refetch,
            })
          }
        >
          <p style={btnDelete?.type === "disabled" ? disabled : nonDisabled}>
            <span style={{ marginRight: "15px" }}>
              <DeleteTwoTone twoToneColor="#eb2f96" />
            </span>
            Hapus
          </p>
        </Popconfirm>
      ),
      hidden: !btnDelete || (!isLead && !isSuperAdmin),
      disabled: btnDelete?.type === "disabled",
    },
  ].filter((x) => !x.hidden);

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      if (
        roles.includes("super_admin") ||
        isLead ||
        roles.includes("admin_foundation")
      )
        window.open(`/hrd/triwulanreport/${triwulan.id}`, "_blank");
      else {
        window.open(
          `/hrd/triwulanreport/${triwulan.id}/${employeeId}`,
          "_blank"
        );
      }
      setOpen(false);
    } else if (e.key === "2") {
      navigate(`${triwulan.id}/recap`);
    } else if (e.key === "3") {
      setShowEdit(true);
      setReportId(triwulan.id);
      setOpen(false);
    } else if (e.key === "4") {
      setOpen(false);
    }
  };

  const handleOpenChange = (nextOpen, info) => {
    if (items.length === 1 && items[0].key === "1") {
      if (
        roles.includes("super_admin") ||
        isLead ||
        roles.includes("admin_foundation")
      )
        window.open(`/hrd/triwulanreport/${triwulan.id}`, "_blank");
      else {
        window.open(
          `/hrd/triwulanreport/${triwulan.id}/${employeeId}`,
          "_blank"
        );
      }
      return;
    }
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  return (
    <Dropdown
      open={open}
      menu={{
        items,
        style: { width: "150px" },
        onClick: handleMenuClick,
      }}
      onOpenChange={handleOpenChange}
      placement="topLeft"
      arrow
      trigger={["contextMenu", "click"]}
    >
      <div style={cardWrapper}>
        <Row>
          <Col
            span={5}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "40px",
              }}
            >
              <FolderOpenTwoTone twoToneColor="#FFA800" />
            </span>
          </Col>
          <Col span={16}>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "10px",
                marginBottom: "10px",
              }}
            >
              {triwulan?.name}
            </h1>
            <p style={{ fontSize: "14px", color: "#999897" }}>
              {triwulan?.unit?.name}
            </p>
          </Col>
          <Col span={3}>
            <span
              style={{
                fontSize: "18px",
                marginTop: "10px",
                display: "block",
              }}
            >
              <RightOutlined />
            </span>
          </Col>
        </Row>
      </div>
    </Dropdown>
  );
};

export default TriwulanCard;
