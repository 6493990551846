import { Form, Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingProsem } from "hooks/teachers-hook/useTeachingProsem";
import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export default function DuplikatProsem({
  id,
  fullData,
  open,
  onCancel,
  onDuplikat,
}) {
  const [isLoad, setIsLoad] = useState(false);
  const [teacherId, setTeacherId] = useState(false);
  const [classId, setClassId] = useState(false);
  const [pelajaranId, setPelajaranId] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  const [form] = Form.useForm();
  const [classIdProsem, setClassIdProsem] = useState("");
  const [subjectIdProsem, setSubjectIdProsem] = useState("");
  // let classIdProsem = "";
  // let subjectIdProsem = "";

  const roles = decryptCookies("role").split(",");

  // get teacher list
  const { data: dataTeachers, refetch: fetchTeachers } = useTeachersList();

  // get teacher teaching
  const {
    data: dataTeaching,
    isLoading: isLoadTeaching,
    refetch: fetchTeaching,
  } = useTeachingProsem(
    Cookies.get("teacher_id") ? decryptCookies("teacher_id") : teacherId,
    classIdProsem ? classIdProsem : "",
    subjectIdProsem
  );

  // useEffect in here ---

  useEffect(() => {
    if (open) {
      fetchTeachers();
      const { class_id, subject_id } = fullData;
      setClassIdProsem(class_id);
      setSubjectIdProsem(subject_id);
      if (Cookies.get("teacher_id") || teacherId) {
        if (Cookies.get("teacher_id")) {
          setTeacherId(decryptCookies("teacher_id"));
        }

        fetchTeaching();
      }
    }
    // eslint-disable-next-line
  }, [teacherId, open]);

  // handle in here ---

  const handleSubmit = async () => {
    const values = await form.validateFields();

    const valData = {
      prosemId: id,
      teacherId: Cookies.get("teacher_id") ? teacherId : values.teacherId,
      classId: classId,
      subjectId: pelajaranId,
    };
    // console.log(valData);
    setIsLoad(true);
    try {
      await axios.post(
        `${REACT_APP_HOST_API}/academics/program-semester/duplicate`,
        valData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      onDuplikat();
      handleOnCancle();
      message.success("duplikat prosem berhasil");
    } catch (error) {
      alert("duplikat prosem gagal!");
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  };

  const onTeachingChange = (e) => {
    const [{ class_id, subject_id }] = dataTeaching?.data?.filter(
      (datas) => datas.id === e
    );

    setClassId(class_id);
    setPelajaranId(subject_id);
  };

  const handleOnCancle = () => {
    form.resetFields();
    onCancel();
    setTeacherId("");
  };

  return (
    <Modal
      open={open}
      onCancel={handleOnCancle}
      confirmLoading={isLoad}
      cancelText="Batal"
      okText="Simpan"
      onOk={handleSubmit}
    >
      <Form layout="vertical" form={form}>
        {roles.find(
          (x) =>
            x === "super_admin" ||
            x === "admin" ||
            x === "curriculum" ||
            x === "admin_academic"
        ) && (
          <Form.Item label="Guru" name="teacherId" rules={[{ required: true }]}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              placeholder="Pilih guru"
              onChange={(e) => {
                setTeacherId(e);
                form.resetFields(["subjectId"]);
              }}
            >
              {dataTeachers?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.employee?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Pelajaran"
          name="subjectId"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option.children
                ? option.children.toString()
                : "";

              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
            placeholder="Pilih Pelajaran"
            loading={isLoadTeaching}
            disabled={isLoadTeaching || !teacherId}
            onChange={(e) => onTeachingChange(e)}
          >
            {dataTeaching?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.class?.name} - {x.subject?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
