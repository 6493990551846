import {
  AppstoreFilled,
  CalendarFilled,
  DashboardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import {
  FaBookReader,
  FaBook,
  FaChalkboardTeacher,
  FaGraduationCap,
} from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { IoMdSchool } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const LayoutAcademicLogic = () => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");

  const { data } = usePermission("mdlAcademic");

  const [open, setOpen] = useState(false);

  const handleSelectMenu = (param) => {
    encryptCookies("defaultAcademicMenu", param);
  };

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
  };

  // Menu variable
  const mnuRaportStudent = data?.find((x) => x.id === "mnuRaportStudent");
  const mnuChartRecapDaily = data?.find((x) => x.id === "mnuChartRecapDaily");
  const mnuClasses = data?.find((x) => x.id === "mnuClasses");
  const mnuStudents = data?.find((x) => x.id === "mnuStudents");
  const mnuSubjects = data?.find((x) => x.id === "mnuSubjects");
  const mnuTeachers = data?.find((x) => x.id === "mnuTeachers");
  const mnuPresenceDaily = data?.find((x) => x.id === "mnuPresenceDaily");
  const mnuStudentPresencePerSubject = data?.find(
    (x) => x.id === "mnuStudentPresencePerSubject"
  );
  const mnuRecapsDaily = data?.find((x) => x.id === "mnuRecapsDaily");
  const mnuPresencePerSubject = data?.find(
    (x) => x.id === "mnuPresencePerSubject"
  );
  const mnuRecapsPerSubject = data?.find((x) => x.id === "mnuRecapsPerSubject");
  const mnuCurriculum = data?.find((x) => x.id === "mnuCurriculum");
  const mnuProsem = data?.find((x) => x.id === "mnuProgramSemester");
  const mnuRpn = data?.find((x) => x.id === "mnuRencanaPengambilanNilai");
  const mnuBukuNilai = data?.find((x) => x.id === "mnuBukuNilai");
  // const menuAlumni = data?.find((x) => x.id === "mnuAlumni");
  const mnuAlumniClass = data?.find((x) => x.id === "mnuAlumniClass");
  const mnuAgenda = data?.find((x) => x.id === "mnuAgenda");

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuAcademic") navigate("/academic");
      else navigate("/academic/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    { key: "mnuAcademic", icon: <DashboardOutlined />, label: "Dashboard" },
    (mnuCurriculum ||
      mnuProsem ||
      mnuRpn ||
      mnuBukuNilai ||
      mnuRaportStudent) && {
      key: "mnuCurriculum",
      icon: <FaBookReader />,
      label: "Curriculum",
      children: [
        mnuProsem && {
          key: "mnuProsem",
          label: "Prosem",
          disabled: mnuProsem?.type === "disabled",
        },
        mnuRpn && {
          key: "mnuRpn",
          label: "RPN",
          disabled: mnuRpn?.type === "disabled",
        },
        mnuBukuNilai && {
          key: "mnuBukuNilai",
          label: "Buku Nilai",
          disabled: mnuBukuNilai?.type === "disabled",
        },
        mnuRaportStudent && {
          key: "mnuRaportStudent",
          label: "Raport Siswa",
          disabled: mnuRaportStudent?.type === "disabled",
        },
      ],
    },
    mnuClasses && {
      key: "mnuClasses",
      icon: <IoMdSchool />,
      label: "Classes",
      disabled: mnuClasses?.type === "disabled",
    },
    (mnuStudents ||
      mnuPresenceDaily ||
      mnuRecapsDaily ||
      mnuStudentPresencePerSubject ||
      mnuChartRecapDaily) && {
      key: "mnuStudentsList",
      icon: <HiUsers />,
      label: "Students",
      children: [
        mnuStudents && {
          key: "mnuStudents",
          label: "Students",
          disabled: mnuStudents?.type === "disabled",
        },
        mnuPresenceDaily && {
          key: "mnuPresenceDaily",
          label: "Presence Daily",
          disabled: mnuPresenceDaily?.type === "disabled",
        },
        mnuRecapsDaily && {
          key: "mnuRecapsDaily",
          label: "Recaps Daily",
          disabled: mnuRecapsDaily?.type === "disabled",
        },
        mnuStudentPresencePerSubject && {
          key: "mnuStudentPresencePerSubject",
          label: "Presence Per Subject",
          disabled: mnuStudentPresencePerSubject?.type === "disabled",
        },
        mnuChartRecapDaily && {
          key: "mnuChartRecapDaily",
          label: "Chart Recaps Daily",
          disabled: mnuChartRecapDaily?.type === "disabled",
        },
      ],
    },
    (mnuTeachers || mnuPresencePerSubject || mnuRecapsPerSubject) && {
      key: "mnuTeachersList",
      icon: <FaChalkboardTeacher />,
      label: "Teachers",
      children: [
        mnuTeachers && {
          key: "mnuTeachers",
          label: "Teachers",
          disabled: mnuTeachers?.type === "disabled",
        },
        mnuPresencePerSubject && {
          key: "mnuPresencePerSubject",
          label: "Teaching Journal",
          disabled: mnuPresencePerSubject?.type === "disabled",
        },
        mnuRecapsPerSubject && {
          key: "mnuRecapsPerSubject",
          label: "Recaps Teaching Journal",
          disabled: mnuRecapsPerSubject?.type === "disabled",
        },
      ],
    },
    mnuSubjects && {
      key: "mnuSubjects",
      icon: <FaBook />,
      label: "Subjects",
      disabled: mnuSubjects?.type === "disabled",
    },
    mnuAgenda && {
      key: "mnuAgenda",
      icon: <CalendarFilled />,
      label: "Agenda",
      disabled: mnuAgenda?.type === "disabled",
    },
    mnuAlumniClass && {
      key: "mnuAlumniList",
      icon: <FaGraduationCap />,
      label: "Alumni",
      disabled: mnuAlumniClass?.type === "disabled",
      children: [
        mnuAlumniClass && {
          key: "mnuAlumni",
          label: "Alumni List",
          disabled: mnuAlumniClass?.type === "disabled",
        },
        mnuAlumniClass && {
          key: "mnuAlumniClass",
          label: " Alumni Class",
          disabled: mnuAlumniClass?.type === "disabled",
        },
      ],
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return {
    email,
    collapsed,
    items,
    items2,
    open,
    setOpen,
    itemsUser,
    setCollapsed,
    handleClickItemUser,
    handleClickMenu,
  };
};

export default LayoutAcademicLogic;
