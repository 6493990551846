import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Input, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import moment from "moment";

const LeaveFilter = ({ context }) => {
  const location = window.location.pathname;

  const {
    date,
    setDate,
    firstDate,
    lastDate,
    keyword,
    setKeyword,
    status,
    setStatus,
    leaveStatus,
    setLeaveStatus,
  } = context;

  return (
    <>
      <div
        className="leave-filter"
      >
        <Tooltip Tooltip title="filter nama">
          <Input
            className="item-search"
            allowClear
            value={keyword}
            placeholder="cari nama..."
            prefix={<SearchOutlined />}
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </Tooltip>
        <Tooltip Tooltip title="tanggal awal">
          <DatePicker
            value={dayjs(date.from)}
            onChange={(value) => {
              setDate((curr) => ({
                ...curr,
                from:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(firstDate).format("YYYY-MM-DD"),
              }));
            }}
            placeholder="Awal"
          />
        </Tooltip>
        <Tooltip Tooltip title="tanggal akhir">
          <DatePicker
            value={dayjs(date.to)}
            onChange={(value) =>
              setDate((curr) => ({
                ...curr,
                to:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(lastDate).format("YYYY-MM-DD"),
              }))
            }
            placeholder="Akhir"
          />
        </Tooltip>
        {location === "/hrd/leavedaily" && (
          <Tooltip Tooltip title="status izin">
            <Select value={leaveStatus} onChange={setLeaveStatus}>
              <Select.Option value="">All</Select.Option>
              <Select.Option value="cuti">Cuti</Select.Option>
              <Select.Option value="sakit">Sakit</Select.Option>
              <Select.Option value="izin">Izin</Select.Option>
            </Select>
          </Tooltip>
        )}
        <Tooltip Tooltip title="status">
          <Select value={status} onChange={setStatus}>
            <Select.Option value="">All</Select.Option>
            <Select.Option value="waiting">Menunggu</Select.Option>
            <Select.Option value="aprove">Diterima</Select.Option>
            <Select.Option value="rejected">Ditolak</Select.Option>
          </Select>
        </Tooltip>
      </div>
    </>
  );
};

export default LeaveFilter;
