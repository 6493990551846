import { DatePicker, Form, Input, Modal, Radio, Select, Skeleton } from "antd";
import dayjs from "dayjs";
import EditTeachersLogic from "./EditTeachersLogic";

const EditTeachers = (props) => {
  const {
    form,
    newData,
    loading,
    skeleteon,
    handleSubmit,
    // dataPelajararan,
    dataGuru,
    dataSession,
    isGuruLogin,
    // dataClass,
    // --
    statusTeach,
    setStatusTeach,
  } = EditTeachersLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={props.onCancel}
      okButtonProps={{ loading }}
    >
      {skeleteon && <Skeleton active />}

      {!skeleteon && (
        <Form form={form} layout="vertical">
          <Form.Item name="status" label="Status">
            <Radio.Group
              onChange={({ target: { value } }) => {
                newData["status"] = value;
                setStatusTeach(value);
              }}
            >
              <Radio value="teach">Mengajar</Radio>
              <Radio value="not_teach">Tidak Mengajar</Radio>
              <Radio value="exam">Ujian</Radio>
              <Radio value="homework">Tugas </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="reason_not_teach"
            label="Alasan tidak mengajar"
            hidden={statusTeach !== "not_teach"}
          >
            <Input
              onChange={({ target: { value } }) =>
                (newData["reason_not_teach"] = value)
              }
            />
          </Form.Item>

          <Form.Item name="teacher_id" label="Guru">
            <Select
              disabled={isGuruLogin}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["teacherId"] = value)}
            >
              {dataGuru?.data?.map((guru) => (
                <Select.Option key={guru.id} value={guru.id}>
                  {guru.employee.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item name="prosem" label="prosem">
          <Select
                  onChange={(value) => setIdProsem(value)}
                  disabled={isLoadProsem || !subjectId}
                  loading={isLoadProsem}
                >
                  <Select.Option value="">----------</Select.Option>
                  {dataProsem?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.mapel?.name} - {x.teachers?.employee?.name}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>

          <Form.Item
            name="programSemesterDetailId"
            label="Materi prosem"
          ></Form.Item> */}

          <Form.Item name="material" label="Materi">
            <Input
              onChange={({ target: { value } }) =>
                (newData["material"] = value)
              }
            />
          </Form.Item>

          <Form.Item name="session_id" label="Sesi">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["sessionId"] = value)}
            >
              {dataSession?.data.data?.map((session) => (
                <Select.Option key={session.id} value={session.id}>
                  {session.session}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item name="class_id" label="Kelas">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["classId"] = value)}
            >
              {dataClass?.data?.map((kelas) => (
                <Select.Option key={kelas.id} value={kelas.id}>
                  {kelas.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          {/* <Form.Item name="subject_id" label="Mata Pelajaran">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["subjectId"] = value)}
            >
              {dataPelajararan?.data?.map((pelajaran) => (
                <Select.Option key={pelajaran.id} value={pelajaran.id}>
                  {pelajaran.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          <Form.Item name="date_in" label="Waktu Masuk">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(value) =>
                (newData["date_in"] = dayjs(value).format(
                  "YYYY-MM-DD HH:mm:ss"
                ))
              }
            />
          </Form.Item>

          <Form.Item name="date_out" label="Waktu Keluar">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(value) =>
                (newData["date_out"] = dayjs(value).format(
                  "YYYY-MM-DD HH:mm:ss"
                ))
              }
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditTeachers;
