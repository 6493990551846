import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Input, Tooltip } from "antd";
import { TriwulanReportContext } from "context/TriwulanReportContext";
import dayjs from "dayjs";
import moment from "moment";
import { useContext } from "react";
import "./TReportFilter.css";

const TReportFilter = () => {
  const { date, setDate, firstDate, lastDate, keyword, setKeyword } =
    useContext(TriwulanReportContext);

  return (
    <>
      <div
        className="triwulan-filter"
      >
        <Input
          allowClear
          value={keyword}
          placeholder="cari nama..."
          prefix={<SearchOutlined />}
          onChange={({ target: { value } }) => setKeyword(value)}
          className="item-search"
        />
        <Tooltip Tooltip title="tanggal awal">
          <DatePicker
            value={dayjs(date.from)}
            onChange={(value) => {
              setDate((curr) => ({
                ...curr,
                from:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(firstDate).format("YYYY-MM-DD"),
              }));
            }}
            placeholder="Awal"
          />
        </Tooltip>
        <Tooltip Tooltip title="tanggal akhir">
          <DatePicker
            value={dayjs(date.to)}
            onChange={(value) =>
              setDate((curr) => ({
                ...curr,
                to:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(lastDate).format("YYYY-MM-DD"),
              }))
            }
            placeholder="Akhir"
          />
        </Tooltip>
      </div>
    </>
  );
};

export default TReportFilter;
