import { Form, Modal, Select, Skeleton } from "antd";
import EditProsemLogic from "./EditProsemLogic";
import { decryptCookies } from "components/Helper/CookiesHelper";

const EditProsem = (props) => {
  const {
    form,
    loading,
    isLoading,
    dataTeaching,
    dataTeachers,
    onSubmit,
    onCancelModal,
  } = EditProsemLogic(props);

  const roles = decryptCookies("role").split(",");

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      closable={false}
      open={props.open}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical">
          {roles.find(
            (x) =>
              x === "super_admin" ||
              x === "admin" ||
              x === "curriculum" ||
              x === "admin_academic"
          ) && (
            <Form.Item
              label="Guru"
              name="teacherId"
              rules={[{ required: true }]}
              hidden
            >
              <Select placeholder="Pilih guru">
                {dataTeachers?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.employee?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Pelajaran"
            name="subjectId"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option.children
                  ? option.children.toString()
                  : "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              placeholder="Pilih Pelajaran"
            >
              {dataTeaching?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.subject?.name} - {x.class?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditProsem;
