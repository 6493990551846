import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAccountList = (keyword, tipe) => {
  return useQuery(
    ["get-account-finance-list", keyword, tipe],
    () =>
      GetList(`/finance/accounts?mode=list&keyword=${keyword}&tipe=${tipe}`),
    {
      enabled: false,
    }
  );
};
