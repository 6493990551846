import { Pie } from "@ant-design/plots";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PpdbDiterima = () => {
  const [dataProgram, setDataProgram] = useState([]);
  const [dataJurusan, setDataJurusan] = useState([]);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API: api } = process.env;

  const fetchDataSiswa = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(api + "/charts/pendaftar-diterima", {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      setDataProgram(
        data.diterimaPerProgram.map((x) => {
          return {
            ...x,
            total: Number(x.total),
          };
        })
      );
      setDataJurusan(
        data.diterimaPerJurusan.map((x) => {
          return {
            ...x,
            total: Number(x.total),
          };
        })
      );
    } catch (error) {
      if (error.response.status === 401) {
        if (
          error.response.statusText === "TOKEN" ||
          error.response.statusText === "UNVERIFIED"
        ) {
          return <Navigate to="/" />;
        } else {
          return <Navigate to="/permission-error" />;
        }
      }

      alert("ERROR", error.message);
    }
    setLoading(false);
  }, [api]);

  useEffect(() => {
    fetchDataSiswa();
  }, [fetchDataSiswa]);

  const configJurusan = {
    appendPadding: 5,
    data: dataJurusan,
    angleField: "total",
    colorField: "jurusan",
    label:
      window.innerWidth > 530
        ? { type: "spider", labelHeight: 28, content: "{name} | {percentage}" }
        : { type: "inner" },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    legend: window.innerWidth > 530 ? false : { position: "left" },
  };

  const configProgram = {
    appendPadding: 5,
    data: dataProgram,
    angleField: "total",
    colorField: "program",
    label:
      window.innerWidth > 530
        ? { type: "spider", labelHeight: 28, content: "{name} | {percentage}" }
        : { type: "inner" },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    legend: window.innerWidth > 530 ? false : { position: "left" },
  };

  return (
    <div>
      <div className="chart-wrapper">
        <div className="chart-item">
          <h3>Diterima Per Jurusan </h3>
          <Pie
            {...configJurusan}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
        <div className="chart-item">
          <h3>Diterima Per Program </h3>
          <Pie
            {...configProgram}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default PpdbDiterima;
