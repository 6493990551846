import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useLinkDetail = (link_id, enabled) => {
  return useQuery(
    ["get-link-detail", link_id],
    () => GetDetail(`/links/links/${link_id}`),
    {
      enabled,
    }
  );
};
