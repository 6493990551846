import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useSchedulesList = () => {
  return useQuery(
    "get-schedules-list",
    () => GetList("/ppdb/exam-schedules?page=1&limit=10&active=true"),
    { enabled: false }
  );
};
