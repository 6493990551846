import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useDetailRaportUmum = (raport_id, enabled) => {
  return useQuery(
    ["get-raport-umum-detail", raport_id],
    () =>
      GetDetail(
        `/academics/student-raports/${raport_id}/student-raport-details`
      ),
    { enabled }
  );
};
