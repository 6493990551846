import { DatePicker, Form, Input, Radio, Select } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useClassListFoundation } from "hooks/classes-hook/useClassListFoundation";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import React, { useState } from "react";

const TabIdentitasSiswa = ({ form }) => {
  // fetch class list
  const [foundationId, setFoundationId] = useState("");
  const { data, isLoading: skeleton } = useClassListFoundation(foundationId);
  const dataClass = data?.data.sort((a, b) => a.name.localeCompare(b.name));
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  return (
    <div className="double-form">
      <div>
        {isSuperAdmin && (
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(x) => {
                setFoundationId(x);
                form.setFieldsValue({ class_id: "" });
              }}
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="Nama"
          name="name"
          required={true}
          rules={[
            {
              pattern: new RegExp("^[A-Za-z' ]+$"),
              message: "Nama hanya dapat berupa huruf dan tanda petik (')",
            },
            { required: true, message: "Nama harus diisi" },
            { min: 5, message: "Nama minimal 5 karakter" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Kelas" name="class_id">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={skeleton}
          >
            {dataClass?.map((data) => (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="NIK"
          name="nik"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Input hanya berupa angka",
            },
            { min: 16, message: "Minimal 16 karakter" },
            { max: 16, message: "Maksimal 16 karakter" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="NIS"
          name="nis"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { min: 3, message: "minimal 3 karakter" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="NISN"
          name="nisn"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { min: 3, message: "minimal 3 karakter" },
            { required: true, message: "NISN tidak boleh kosong!" },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div>
        <Form.Item label="Agama" name="religion" initialValue={"islam"}>
          <Select
            options={[
              {
                value: "islam",
                label: "Islam",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Status" name="isGraduated" initialValue={false}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={false}>Aktif</Radio.Button>
            <Radio.Button value={true}>Lulus</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Jenis Kelamin" name="gender">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="male">Laki-laki</Radio.Button>
            <Radio.Button value="female">Perempuan</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Kota Lahir" name="birth_city">
          <Input />
        </Form.Item>

        <Form.Item label="Tanggal Lahir" name="birth_day">
          <DatePicker />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabIdentitasSiswa;
