import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const usePresenceDetail = (id, enabled) => {
  return useQuery(
    ["get-presence-detail", id],
    () => GetDetail(`/presence/${id}/edit`),
    { enabled }
  );
};
