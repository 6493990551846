import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";

const { REACT_APP_HOST_API } = process.env;

export const DeleteApiWithMessage = async ({ url, dataId, refetch }) => {
  try {
    const { data } = await axios.delete(REACT_APP_HOST_API + url + dataId, {
      headers: { Authorization: "Bearer " + decryptCookies("token") },
    });
    message.success(data.message, 2);
    refetch && refetch();
  } catch (error) {
    let msg = error?.response?.data?.message || "gagal menghapus data";
    message.error(msg);
  }
};
