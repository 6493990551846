import {
   DeleteTwoTone,
   EditTwoTone,
   FolderOpenTwoTone,
   RightCircleTwoTone,
   RightOutlined,
} from "@ant-design/icons";
import { Dropdown, Popconfirm } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { MonthlyReportContext } from "context/MonthlyReportContext";
import React, { useContext, useState } from "react";

const ReportCard = ({ dataPermission, name, unit, id, refetch, dataUnit }) => {
   const { setReportId, setShowEdit } = useContext(MonthlyReportContext);

   const [open, setOpen] = useState(false);
   const btnEdit = dataPermission?.find((x) => x.id === "btnEditReport");
   const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteReport");
   const btnDetail = dataPermission?.find((x) => x.id === "btnDetailReport");
   const roles = decryptCookies("role").split(",");
   const isSuperAdmin = roles.some(
      (x) => x === "super_admin" || x === "admin_foundation"
   );
   const employeeId = decryptCookies("employeeId");
   const isLead = dataUnit?.employeeUnits[0]?.employee_id === employeeId;

   const items = [
      {
         key: "1",
         label: (
            <div className="monthly-report-card-menu">
               <RightCircleTwoTone
                  style={{ fontSize: "14px" }}
                  twoToneColor={
                     btnDetail?.type === "disabled" ? "#d9d9d9" : "#1CEF92"
                  }
               />
               <p>Detail</p>
            </div>
         ),
         disabled: btnDetail?.type === "disabled",
         hidden: !btnDetail,
      },
      {
         key: "2",
         label: (
            <div className="monthly-report-card-menu">
               <EditTwoTone
                  style={{ fontSize: "14px" }}
                  twoToneColor={
                     btnEdit?.type === "disabled" ? "#d9d9d9" : "#4096FF"
                  }
               />
               <p>Edit</p>
            </div>
         ),
         disabled: btnEdit?.type === "disabled",
         hidden: !btnEdit || (!isLead && !isSuperAdmin),
      },
      {
         key: "3",
         label: (
            <Popconfirm
               disabled={btnDelete?.type === "disabled"}
               title="Yakin ingin menghapus data?"
               okText="Hapus"
               cancelText="Batal"
               onConfirm={() =>
                  DeleteApi({
                     url: "/monthly-reports/",
                     dataId: id,
                     refetch,
                  })
               }
            >
               <div className="monthly-report-card-menu">
                  <DeleteTwoTone
                     style={{ fontSize: "14px" }}
                     twoToneColor={
                        btnDelete?.type === "disabled" ? "#d9d9d9" : "#FF4D4F"
                     }
                  />
                  <p>Delete</p>
               </div>
            </Popconfirm>
         ),
         disabled: btnDelete?.type === "disabled",
         hidden: !btnDelete || (!isLead && !isSuperAdmin),
      },
   ].filter((x) => !x.hidden);

   const handleMenuClick = (e) => {
      if (e.key === "1") {
         if (
            roles.includes("super_admin") ||
            isLead ||
            roles.includes("admin_foundation")
         )
            window.open(`/hrd/monthlyreport/${id}`, "_blank");
         else {
            window.open(`/hrd/monthlyreport/${id}/:mreport_employee_id`, "_blank");
         }
         setOpen(false);
      } else if (e.key === "2") {
         setShowEdit(true);
         setReportId(id);
         setOpen(false);
      } else if (e.key === "3") {
         setOpen(false);
      }
   };

   const handleOpenChange = (nextOpen, info) => {
      if (items.length === 1 && items[0].key === "1") {
         if (
            roles.includes("super_admin") ||
            isLead ||
            roles.includes("admin_foundation")
         )
            window.open(`/hrd/monthlyreport/${id}`, "_blank");
         else {
            window.open(`/hrd/monthlyreport/${id}/:mreport_employee_id`, "_blank");
         }
         return;
      }
      if (info.source === "trigger" || nextOpen) {
         setOpen(nextOpen);
      }
   };

   return (
      <Dropdown
         menu={{ items, onClick: handleMenuClick }}
         onOpenChange={handleOpenChange}
         open={open}
         trigger={["contextMenu", "click"]}
         placement="topRight"
         arrow
      >
         <div className="report-monthly-card">
            <FolderOpenTwoTone
               style={{ fontSize: "40px", marginRight: "10px" }}
               twoToneColor="#FFA800"
            />
            <div className="report-monthly-text">
               <h1 className="report-monthly-title ">{name}</h1>
               <p className="report-monthly-subtitle">{unit}</p>
            </div>
            <RightOutlined style={{ marginLeft: "auto" }} />
         </div>
      </Dropdown>
   );
};

export default ReportCard;
