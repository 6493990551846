import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const DataAkademikSiswaTab = () => {
  const { candidate_id } = useParams();
  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>No Ujian Nasional</th>
                <td>{dataInti.nat_exam_no && dataInti.nat_exam_no}</td>
              </tr>
              <tr>
                <th>No Seri Ijazah</th>
                <td>
                  {dataInti.junior_hs_cert_no && dataInti.junior_hs_cert_no}
                </td>
              </tr>
              <tr>
                <th>Asal Sekolah</th>
                <td>{dataInti.junior_hs_name && dataInti.junior_hs_name}</td>
              </tr>
              <tr>
                <th>No Akta Lahir</th>
                <td>{dataInti.birth_cert_no && dataInti.birth_cert_no}</td>
              </tr>
              <tr>
                <th>Penerima KPS</th>
                <td>
                  {dataInti.has_kps === null
                    ? "-"
                    : dataInti.has_kps
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>No KPS</th>
                <td>{dataInti.kps_number && dataInti.kps_number}</td>
              </tr>
              <tr>
                <th>Penerima KIP</th>
                <td>
                  {dataInti.has_kip === null
                    ? "-"
                    : dataInti.has_kip
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>No KIP</th>
                <td>{dataInti.kip_number && dataInti.kip_number}</td>
              </tr>
              <tr>
                <th>Nama di KIP</th>
                <td>
                  {dataInti.name_on_kip === null
                    ? "-"
                    : dataInti.name_on_kip
                    ? "Ada"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>Penerima KKS</th>
                <td>
                  {dataInti.has_kks === null
                    ? "-"
                    : dataInti.has_kks
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>No KKS</th>
                <td>{dataInti.kks_number && dataInti.birth_cert_no}</td>
              </tr>
              <tr>
                <th>Layak PIP</th>
                <td>
                  {dataInti.pip_eligible === null
                    ? "-"
                    : dataInti.pip_eligible
                    ? "Ya"
                    : "Tidak"}
                </td>
              </tr>
              <tr>
                <th>Alasan PIP</th>
                <td>{dataInti.pip_desc && dataInti.pip_desc}</td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataAkademikSiswaTab;
