import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useRecapsDailyList = (
  lastMonths,
  lastDays,
  foundationId,
  enabled
) => {
  return useQuery(
    ["get-recaps-daily-list", lastMonths, lastDays, foundationId],
    () =>
      GetDetail(
        `/academics/daily-attendances?lastMonths=${lastMonths}&lastDays=${lastDays}&recap=chart&foundationId=${foundationId}`
      ),
    {
      enabled,
    }
  );
};
