import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuLink from "components/Auth/RequireMenuLink";
import Link from "pages/si-link/link/master/Link";
import LayoutLink from "Layouts/LayoutLink/LayoutLink";
import LinkGroup from "pages/si-link/link-group/master/LinkGroup";
import CreateLink from "pages/si-link/link/create/CreateLink";
import CreateGroup from "pages/si-link/link-group/create/CreateGroup";
import EditLink from "pages/si-link/link/edit/EditLink";
import Categories from "pages/si-link/categories/master/Categories";

export const DataRoutesLink = [
  {
    path: "link",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLink">
          <LayoutLink content={<Link />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/add",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLink">
          <LayoutLink content={<CreateLink />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/edit/:link_id",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLink">
          <LayoutLink content={<EditLink />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/categories",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuCategories">
          <LayoutLink content={<Categories />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<LinkGroup />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group/add",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<CreateGroup />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
];
