import { DatePicker, Form, Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useAcademicYear } from "hooks/academic-year-hook/useAcademicYear";
import { useClassList } from "hooks/classes-hook/useClassList";
import { useSemesterList } from "hooks/semester-hook/useSemesterList";
import React, { useEffect, useState } from "react";

export default function GenerateRaport({ open, setOpen }) {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const [isLoad, setIsLoad] = useState(false);

  // *fetch class list ---
  const { data: dataClass, refetch: fetchClass } = useClassList(false);

  // * get semester list ---
  const { data: dataSemester, refetch: fetchSemester } = useSemesterList(true);

  //* get academic year ---
  const { data: dataAcademicYear, refetch: fetchAcademicYear } =
    useAcademicYear();

  useEffect(() => {
    if (open) {
      fetchClass();
      fetchSemester();
      fetchAcademicYear();
    }
  }, [open, fetchClass, fetchSemester, fetchAcademicYear]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setIsLoad(true);
    try {
      await axios.post(
        `${REACT_APP_HOST_API}/academics/raports`,
        {
          ...values,
          name: "Raport",
          fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Berhasil generate raport");
      setOpen();
      form.resetFields();
    } catch (error) {
      console.log(error);
      alert("Gagal generate raport");
    } finally {
      setIsLoad(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen();
  };

  return (
    <Modal
      centered
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      closable={false}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={isLoad}
    >
      <Form form={form} layout="vertical">
        {/* <Form.Item label="Nama Raport" name="name" rules={[{ required: true }]}>
          <Input
            suffix={
              <Tooltip title="Contoh: Raport semester 1 kelas XII BHS TA 2022/2023">
                <InfoCircleOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                  }}
                />
              </Tooltip>
            }
          />
        </Form.Item> */}

        <Form.Item
          label="Semester"
          name="semesterId"
          rules={[{ required: true }]}
        >
          <Select>
            {dataSemester?.data?.map((semester) => (
              <Select.Option key={semester.id} value={semester.id}>
                {semester.semester_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tahun Ajar"
          name="academicYearId"
          rules={[{ required: true }]}
        >
          <Select>
            {dataAcademicYear?.data?.data?.map((tahunAjar) => (
              <Select.Option key={tahunAjar.id} value={tahunAjar.id}>
                {tahunAjar.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Kelas" name="classId" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {dataClass?.data?.map((kelas) => (
              <Select.Option key={kelas.id} value={kelas.id}>
                {kelas.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <p>Periode Raport</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "10px",
          }}
        >
          <Form.Item
            label="Dari Tanggal"
            style={{ width: "100%" }}
            name="fromDate"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="Dari Tanggal" />
          </Form.Item>

          <Form.Item
            label="Sampai Tanggal"
            style={{ width: "100%" }}
            name="toDate"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="Sampai Tanggal" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
