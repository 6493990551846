import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useShowLeassonDetail = (id, enabled) => {
  return useQuery(
    ["get-show-leasson-detail", id],
    () => GetDetail(`/academics/lesson-attendances/${id}`),
    {
      enabled,
    }
  );
};
