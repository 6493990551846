import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

const RequireLogin = ({ children }) => {
  if (Cookies.get("token")) {
    return <Navigate to="/home" />;
  }

  return children;
};

export default RequireLogin;
