import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { DeleteApi } from "api/DeleteApi";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useClassList } from "hooks/classes-hook/useClassList";
import { usePelajaranDetail } from "hooks/pelajaran-hook/usePelajaranDetail";
import { useStudentSubjectPagination } from "hooks/students-hook/useStudentSubjectPagination";
import { useSubjectMemberList } from "hooks/subject-member-hook/useSubjectMemberList";
import { usePermission } from "hooks/usePermissions";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function DetailPelajaranEkskul() {
  const [form] = Form.useForm();
  const [openAdd, setOpenAdd] = useState(false);
  const [classId, setClassId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [keywordAddStudent, setKeyworAddStudent] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  const { data: dataClass, refetch } = useClassList();
  // eslint-disable-next-line
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 100000000000,
    total: 0,
  });

  const disabled = { color: "#ccc", cursor: "not-allowed" };

  const { subject_id } = useParams();

  //   detail subject
  const {
    data,
    // isLoading,
    refetch: fetchData,
  } = usePelajaranDetail(subject_id, false);

  const {
    data: dataMemberList,
    isLoading: isLoadMemberList,
    refetch: refetchMemberList,
  } = useSubjectMemberList({ dataTable, keyword, subject_id });

  const {
    data: dataStudentList,
    isLoading: isLoadStudentList,
    refetch: refetchStudentList,
  } = useStudentSubjectPagination({
    dataTable,
    keyword: keywordAddStudent,
    classId,
    subjectId: subject_id,
  });

  // fetch student permission
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuSubjects");

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddMembers");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteMembers");

  useEffect(() => {
    fetchData();
    refetch();
    refetchMemberList();
    refetchStudentList();
    // eslint-disable-next-line
  }, [refetch, refetchMemberList]);

  //   detail pelajaran
  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 40,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Nis",
      dataIndex: "nis",
    },
    {
      title: "Kelas",
      dataIndex: "class",
      with: 150,
    },

    {
      title: "Aksi",
      dataIndex: "id",
      render: (id) => {
        return (
          <Space>
            {/* <Tag color="green">Edit</Tag> */}

            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              disabled={btnDelete && btnDelete.type === "disabled"}
              onConfirm={() =>
                DeleteApi({
                  url: "/academics/members/",
                  dataId: id,
                  refetch: refetchMemberList,
                })
              }
              style={{ cursor: "pointer" }}
            >
              <Tag
                color="red"
                hidden={rowSelectedMember.length !== 0 || !btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const dataKey = dataMemberList?.data?.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data?.id,
        index: index + 1,
        name: data?.students?.name,
        class: data?.students?.class?.name,
        nis: data?.students?.nis,
      };
    });

  // add member ---
  const columnsStudent = [
    {
      title: "No",
      dataIndex: "index",
      width: 40,
      fixed: "left",
    },
    {
      title: "Kelas",
      dataIndex: "class",
      width: 70,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      width: window.innerWidth > 800 ? 300 : 150,
    },
    {
      title: "Nis",
      dataIndex: "nis",
      align: "left",
    },
  ];

  const dataKeyStudent = dataStudentList?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        class: data?.class?.name,
        index: index + 1,
      };
    });

  // rowSelection object indicates the need for row selection
  const [rowSelected, setRowSelected] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  const [rowSelectedMember, setRowSelectedMember] = useState([]);

  const rowSelectionMember = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelectedMember(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  const handleDelete = async () => {
    try {
      const subjectMember = rowSelectedMember.map((data) => ({
        id: data.id,
      }));

      setIsLoad(true);

      await axios.delete(
        REACT_APP_HOST_API + `/academics/multi-delete-subject-members/`,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
          data: { subjectMember },
        }
      );

      refetchMemberList();
      rowSelectedMember.length = 0;

      message.success("data berhasil dihapus");
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setIsLoad(false);
    }
  };

  const handleSubmit = async () => {
    const idSelected = rowSelected.map((data) => data.id);
    const subjectMember = idSelected.map((id) => ({
      // subjectId: subject_id,
      studentId: id,
    }));
    const res = { subjectMember };
    if (res.subjectMember.length === 0) {
      message.warning("Tidak ada data yang dipilih");
      return;
    }
    setIsLoad(true);

    try {
      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/academics/subjects/${subject_id}/members/`,
        { subjectMember },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      refetchMemberList();
      refetchStudentList();
      setOpenAdd(false);
    } catch (error) {
      console.log(error);
      alert("member sudah terdaftar");
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <div>
      <div className="table-header">
        <h1>detail</h1>
      </div>
      <table className="detail-student__table">
        <tbody>
          <tr>
            <th>Nama Pelajaran</th>
            <td>
              <Space>
                {data?.data?.name && data.data.name}{" "}
                <Tag color="green">Ekskul</Tag>
              </Space>
            </td>
          </tr>
          <tr>
            <th>Deskirpsi</th>
            <td>{data?.data?.description && data.data.description}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="table-header">
        <h1>
          {openAdd
            ? "From tambah member"
            : `Daftar Member Pelajaran ${data?.data?.name}`}
        </h1>
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus ?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() => handleDelete()}
            style={{ cursor: "pointer" }}
            disabled={btnDelete && btnDelete.type === "disabled"}
          >
            <Button
              danger
              hidden={rowSelectedMember.length === 0 || !btnDelete}
              disabled={btnDelete?.type === "disabled"}
              loading={isLoad}
            >
              Hapus
            </Button>
          </Popconfirm>

          <Button
            type="primary"
            onClick={() => setOpenAdd(!openAdd)}
            disabled={
              rowSelectedMember.length !== 0 || btnAdd?.type === "disabled"
            }
            hidden={!btnAdd}
          >
            {openAdd ? "Lihat Daftar Member" : "Tambah Member"}
          </Button>
        </Space>
      </div>

      {openAdd ? (
        <>
          {/* Start add member */}
          <Form layout="vertical">
            <div className="search-wrapper filter-wrapper">
              <Form.Item label="Kelas">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setClassId(value)}
                  placeholder="cari berdasarkan kelas"
                  defaultValue="Semua Kelas"
                >
                  <Select.Option value={""}>Semua kelas</Select.Option>
                  {dataClass?.data?.map((kelas) => (
                    <Select.Option key={kelas.id} value={kelas.id}>
                      {kelas.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Nama">
                <Input
                  placeholder="cari berdasarkan nama ..."
                  onChange={(e) => setKeyworAddStudent(e.target.value)}
                ></Input>
              </Form.Item>
            </div>
          </Form>

          <Form form={form}>
            <Table
              rowSelection={rowSelection}
              pagination={false}
              bordered
              size="small"
              tableLayout="auto"
              columns={columnsStudent}
              loading={isLoadStudentList}
              dataSource={dataKeyStudent}
              scroll={
                window.innerHeight < 690
                  ? { y: "40vh", x: 800 }
                  : window.innerHeight < 760
                  ? { y: "50vh", x: 800 }
                  : { y: "55vh", x: 800 }
              }
            />

            <div className="button-container">
              <Button
                loading={isLoad}
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                onClick={() => handleSubmit()}
                hidden={!btnAdd}
                disabled={btnAdd?.type === "disabled"}
              >
                Simpan
              </Button>
            </div>
          </Form>
          {/* End add member */}
        </>
      ) : (
        <>
          {/* Start table daftar member */}
          <div className="search-wrapper filter-wrapper">
            <Input
              placeholder="cari berdasarkan nama"
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <Table
            rowSelection={rowSelectionMember}
            pagination={false}
            bordered
            size="small"
            tableLayout="auto"
            columns={columns}
            loading={isLoadMemberList}
            dataSource={dataKey}
          />
          {/* End table daftar member */}
        </>
      )}
    </div>
  );
}
