import { Form, Input, Modal, Radio, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useShowLeassonDetail } from "hooks/attandance-hook/useShowLeassonDetail";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export default function EditStudentsPerSubject({ open, setOpen, dataId }) {
  const [isLoad, setIsLoad] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  //* Get Leasson Attendance Detail ---
  const { data, isLoading } = useShowLeassonDetail(
    dataId,
    Boolean(dataId && open)
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataId && open) {
      form.setFieldsValue({
        status: data?.data?.status,
        description: data?.data?.description,
      });
    }
    // eslint-disable-next-line
  }, [open, dataId, data]);

  const handleSubmit = async () => {
    const val = await form.validateFields();
    setIsLoad(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/academics/lesson-attendances/${dataId}`,
        val,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Berhasil mengubah data");
      form.resetFields();
      setOpen();
    } catch (error) {
      console.log(error);
      alert("gagal edit data");
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen()}
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      onOk={handleSubmit}
      confirmLoading={isLoad}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item name="status" label="Status">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="present">Hadir</Radio.Button>
              <Radio.Button value="permission">Izin</Radio.Button>
              <Radio.Button value="sick">Sakit</Radio.Button>
              <Radio.Button value="absent">Alpa</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="description" label="Deskripsi">
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
