import { Button, Space, Tabs } from "antd";
import React from "react";
import "./DetailStudent.css";

import DetialStudentLogic from "./DetailStudentLogic";

const DetailStudent = () => {
  const { activeKey, selectTap, items } = DetialStudentLogic();

  return (
    <div>
      <div className="table-header">
        <h1>Data Siswa</h1>

        {/* navigasi */}
        <div className="navigasi-tab-detail">
          <Space>
            <Button
              hidden={activeKey === "1"}
              onClick={() => selectTap(parseInt(activeKey) - 1)}
            >
              Sebelumnya
            </Button>
            <Button
              type="primary"
              onClick={() => selectTap(parseInt(activeKey) + 1)}
              hidden={activeKey === "9"}
            >
              Selanjutnya
            </Button>
          </Space>
        </div>
      </div>

      <Tabs
        onTabClick={(a) => selectTap(a)}
        defaultActiveKey="1"
        items={items}
        activeKey={activeKey}
      />
    </div>
  );
};

export default DetailStudent;
