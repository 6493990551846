import { MenuOutlined } from "@ant-design/icons";
import { Drawer, Layout } from "antd";
import LOGO from "assets/img/logoFG.png";
import BreadCrumb from "components/UI/BreadCrumb";
import LayoutLinkLogic from "./LayoutLinkLogic";

import React from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
// import "./LanyoutLink.css";
import NavMenus from "Layouts/NavMenus";
import LogoFG from "assets/img/logoFG.png";
import { useNavigate } from "react-router";
import DynamicTitle from "components/UI/DynamicTitle";
import NavUserProfile from "components/UI/NavUserProfile";

const { Sider, Content, Header } = Layout;

const LayoutLink = (props) => {
  const navigate = useNavigate();

  const {
    // email,
    collapsed,
    items,
    items2,
    itemsUser,
    open,
    setOpen,
    setCollapsed,
    handleClickItemUser,
    handleClickMenu,
  } = LayoutLinkLogic();

  return (
    <Layout>
      <DynamicTitle />
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={250}
      >
        <div className="mobile-menu-wrapper">
          <NavMenus
            items={items}
            theme="light"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultLinkMenu"}
            defaultOpen={["Link"]}
          />
        </div>
      </Drawer>

      <div className="menu-mobile">
        <div onClick={() => navigate("/home")}>
          <img src={LogoFG} alt="logo fg" style={{ width: 22 }} />
          <h1 style={{ fontSize: "1.4em" }}>SMA FG</h1>
        </div>

        <div style={{ position: "absolute", right: "60px" }}>
          <div className="header-container">
            <NavUserProfile
              isOnlyIcon={true}
              itemsUser={itemsUser}
              handleClickItemUser={handleClickItemUser}
            />
          </div>
        </div>

        <MenuOutlined
          style={{ fontSize: "1.3em" }}
          onClick={() => setOpen(true)}
        />
      </div>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={window.innerWidth > 1600 ? 250 : 210}
      >
        <div className="logo">
          <img src={LOGO} alt="fg" />
          <span>SMA FG</span>
        </div>
        <div className="sider-menu-wrapper">
          <NavMenus
            theme="dark"
            items={items}
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultLinkMenu"}
            defaultOpen={["Link"]}
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header>
          {React.createElement(HiOutlineMenuAlt2, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          <div className="header-container">
            <NavUserProfile
              isOnlyIcon={false}
              itemsUser={itemsUser}
              handleClickItemUser={handleClickItemUser}
            />
          </div>
        </Header>

        <BreadCrumb />

        <Content className="site-layout-background">{props.content}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutLink;
