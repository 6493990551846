import { Form, message, Tag } from "antd";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useActivityList } from "hooks/activity-hook/useActivityList";
import { useRecapPresencePagination } from "hooks/recap-presence-hook/useRecapPresencePagination";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

const RecapPresenceLogic = () => {
  // custom date variables
  const newDate = new Date();
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth() - 1, 25);
  const lastDay = new Date(newDate.getFullYear(), newDate.getMonth(), 24);

  // states
  const [form] = Form.useForm();
  const { id } = useParams();
  const [idActivity, setIdActivity] = useState(id);
  const [dataId, setDataId] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [dataRecapHour, setDataRecapHour] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [date, setDate] = useState({
    from: moment(firstDay).format("YYYY-MM-DD"),
    to: moment(lastDay).format("YYYY-MM-DD"),
  });
  const [dataTable, setDataTable] = useState({
    current: 1,
    pageSize: 10,
  });

  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  const getRecapHour = useCallback(async () => {
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API +
          `/presence/${idActivity}/hours?from=${date.from}&to=${date.to}&employeeId=${dataId}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      setDataRecapHour(data.data);
    } catch (error) {
      if (error.response.status === 401) {
        if (
          error.response.statusText === "TOKEN" ||
          error.response.statusText === "UNVERIFIED"
        ) {
          return <Navigate to="/" />;
        } else {
          return <Navigate to="/permission-error" />;
        }
      }
      message.error(error.message);
    }
  }, [dataId, date.from, date.to, idActivity, REACT_APP_HOST_API]);

  useEffect(() => {
    if (dataId) getRecapHour();

    form.setFieldsValue({
      dateFrom: dayjs(moment(date.from)),
      dateTo: dayjs(moment(date.to)),
    });
  }, [form, date.from, date.to, getRecapHour, dataId]);

  // fetch recap presence pagination
  const {
    data: dataRecap,
    isLoading,
    isFetching,
    refetch: fetchData,
  } = useRecapPresencePagination(date, idActivity);

  // fetch activity list
  const {
    data: dataActivity,
    isLoading: skeleton,
    refetch: fetchActivity,
  } = useActivityList("fixed_time");

  useEffect(() => {
    if (idActivity !== ":id") fetchData();
    if (idActivity === ":id") fetchActivity();
  }, [date.from, date.to, id, idActivity, fetchData, fetchActivity]);

  const overviewToExcel = dataRecap?.overview && [
    {
      "Total Hari": dataRecap?.overview.day_total,
      "Total Karyawan": dataRecap?.overview.employee_total,
      "Total Kehadiran": dataRecap?.overview.presence_total,
      "Karyawan Kehadiran Terbanyak": dataRecap?.overview.employee_most_present,
    },
  ];

  const recapToExcel = dataRecap?.recap.map((data) => {
    return {
      Nama: data.name,
      Periode: `${date.from} - ${date.to}`,
      "Total Kehadiran": data.total,
      "Total Jam Kehadiran": data.total_hours,
      "Total Jam Keterlambatan": data.late,
    };
  });

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(overviewToExcel);
    XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");

    XLSX.utils.sheet_add_json(ws1, recapToExcel, { origin: "A4" });
    XLSX.writeFile(wb, "Rekap Presensi.xlsx");
    // let ws2 = XLSX.utils.json_to_sheet(recapToExcel);
    // ws1 = XLSX.utils.json_to_sheet(overviewToExcel, { origin: "A3" });
    // XLSX.utils.book_append_sheet(wb, ws2, "Sheet2");
  };

  const handleDateFromChange = (val) => {
    setDate((cur) => {
      return {
        ...cur,
        from:
          val !== null
            ? val.format("YYYY-MM-DD")
            : moment(firstDay).format("YYYY-MM-DD"),
      };
    });
  };

  const handleDateToChange = (val) => {
    setDate((cur) => {
      return {
        ...cur,
        to:
          val !== null
            ? val.format("YYYY-MM-DD")
            : moment(lastDay).format("YYYY-MM-DD"),
      };
    });
  };

  const handleActivityChange = (val) => {
    setIdActivity(val);
    setShowModal(false);
    navigate(`/hrd/activity/presence-${val}/recapPresence-${val}`);
  };

  const handleCancelModal = () => {
    encryptCookies("defaultHrdMenu", "mnuActivity");
    setShowModal(false);
    navigate("/hrd/activity");
  };

  const handleClickDetail = (record) => {
    setDataId(record.id);
    setModalTitle(
      <table>
        <tbody>
          <tr>
            <td>Nama</td>
            <td>: {record.name}</td>
          </tr>
          <tr>
            <td>Periode</td>
            <td>
              : {date.from} - {date.to}
            </td>
          </tr>
        </tbody>
      </table>
    );
    setShowDetailModal(true);
  };

  const dataKey = dataRecap?.recap.map((recap, index) => {
    return {
      ...recap,
      no: index + 1,
      key: recap.name,
    };
  });

  const columns = (
    <>
      <Column
        title="No"
        dataIndex="no"
        width={window.innerWidth > 800 ? 70 : 50}
      />
      <Column title="Nama" dataIndex="name" />
      <ColumnGroup title="Total" align="center">
        <Column title="Kehadiran" dataIndex="total" />
        <Column title="Jam Kerja" dataIndex="total_hours" />
        <Column title="Keterlambatan" dataIndex="late" />
        <Column title="Selisih Jam" dataIndex="workingTimeDiff" />
      </ColumnGroup>
      <Column
        title="Aksi"
        render={(_, record) => (
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => handleClickDetail(record)}
          >
            Detail
          </Tag>
        )}
      />
    </>
  );

  const pagination = {
    current: dataTable.current,
    pageSize: dataTable.pageSize,
    total: dataRecap?.recap,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (current, pageSize) => {
      setDataTable({
        current,
        pageSize,
      });
    },
  };

  const format = "YYYY-MM-DD";

  return {
    form,
    format,
    dataKey,
    columns,
    skeleton,
    showModal,
    isLoading,
    dataRecap,
    isFetching,
    idActivity,
    pagination,
    modalTitle,
    dataActivity,
    dataRecapHour,
    showDetailModal,
    handleExport,
    handleCancelModal,
    handleDateToChange,
    setShowDetailModal,
    handleDateFromChange,
    handleActivityChange,
  };
};

export default RecapPresenceLogic;
