import { Button, Modal, Result } from "antd";
import React from "react";

export default function SuccessfullyRegister({
  isOpen,
  onClose,
  onCloseParent,
}) {
  const handleClose = () => {
    onClose();
    setTimeout(() => {
      onCloseParent();
    }, 100);
  };

  return (
    <Modal open={isOpen} onCancel={handleClose} footer={null}>
      <Result
        status="success"
        title="Registrasi Berhasil, silahkan cek email anda!"
        extra={[
          <Button onClick={handleClose} type="primary" key="console">
            Tutup
          </Button>,
        ]}
      />
    </Modal>
  );
}
