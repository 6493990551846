import { Button, Form, Input, Tooltip, Table, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks/usePermissions";
import { useBudgetAllocationPagination } from "hooks/finance-hook/useBudgetAllocationPagination";
import { renderAmount } from "../utils/utlisFunction";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";

export default function AlokasiDana() {
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });

  const [keyword, setKeyword] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [foundationId, setFoundationId] = useState("");

  const roles = decryptCookies("role").split(",");

  // Get budget allocation ---
  const { data, isLoading, isFetching } = useBudgetAllocationPagination(
    dataTable,
    keyword,
    foundationId
  );

  // fetch maste billings permission
  const { data: dataPermission } = usePermission(
    "mdlFinance",
    "mnuAlokasiDana"
  );

  // Buttons Permission start
  const btnAlokasiOtomatis = dataPermission?.find(
    (x) => x.id === "btnAlokasiOtomatis"
  );
  const btnAlokasiManual = dataPermission?.find(
    (x) => x.id === "btnAlokasiManual"
  );

  const navigate = useNavigate();

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      align: "left",
      fixed: "left",
    },
    {
      title: "NISN",
      dataIndex: "nisn",
      align: "left",
    },
    {
      title: "Saldo Belum Terpakai",
      dataIndex: "sum_balance",
      align: "left",
    },
    {
      title: "Aksi",
      align: "left",
      render: (x) => (
        <Button
          hidden={!btnAlokasiManual}
          disabled={btnAlokasiManual && btnAlokasiManual.type === "disabled"}
          onClick={() => navigate(`/finance/alokasidana/${x.id}`)}
          type="primary"
          ghost
          size="small"
        >
          Alokasi Manual
        </Button>
      ),
    },
  ];

  const dataSource = data?.data?.data?.map((data, i) => ({
    key: data.id,
    ...data,
    index: i + 1,
    sum_balance: renderAmount(data.sum_balance),
  }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, 200],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  //   multiselect

  const [rowSelected, setRowSelected] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  const { REACT_APP_HOST_API } = process.env;

  const onSubmit = async (auto_allocations) => {
    try {
      setIsLoad(true);
      await axios.put(
        `${REACT_APP_HOST_API}/finance/budget-allocation/auto`,
        {
          auto_allocations,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success("Berhasil Alokasi Dana!");
    } catch (error) {
      console.log(error);
      alert("Gagal mengalokasikan dana!");
    } finally {
      setIsLoad(false);
    }
  };

  const handleOpenSelect = () => {
    const auto_allocations = rowSelected.map((x) => ({
      id: x.id,
    }));

    onSubmit(auto_allocations);
  };

  return (
    <div>
      <div className="table-header">
        <h1>ALOKASI DANA</h1>

        <Button
          loading={isLoad}
          type="primary"
          onClick={handleOpenSelect}
          hidden={rowSelected.length === 0 || !btnAlokasiOtomatis}
          disabled={
            rowSelected.length === 0 || btnAlokasiManual.type === "disabled"
          }
        >
          ALOKASI OTOMATIS
        </Button>
      </div>

      <div
        className="filter-search"
        // style={{
        //   marginTop: 20,
        //   display: "grid",
        //   gap: 10,
        //   gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        // }}
      >
        {roles.some((x) => x === "super_admin") && (
          <Form.Item>
            <FilterFoundation setFoundationId={setFoundationId} />
          </Form.Item>
        )}

        <Form.Item name="keyword">
          <Tooltip title="nama/nisn">
            <Input
              prefix={<SearchOutlined />}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Cari Accounts berdasarkan nama/nisn"
              className="search-input-billings"
              style={{
                border: "1px solid #d9d9d9",
              }}
            />
          </Tooltip>
        </Form.Item>
      </div>

      <Table
        size="small"
        loading={isLoading || isFetching}
        rowSelection={btnAlokasiOtomatis ? rowSelection : null}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        scroll={{
          y: "50vh",
          x: 800,
        }}
      />
    </div>
  );
}
