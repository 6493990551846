import { AutoComplete, DatePicker, Form, Input, Radio, Select } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useClassList } from "hooks/classes-hook/useClassList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useUniversityPagination } from "hooks/university-hook/useUniversityPagination";
import React, { useEffect, useState } from "react";

const TabIdentitasSiswa = ({ newData }) => {
  // fetch class list
  const { data, refetch } = useClassList(true);
  const [dataTable] = useState({
    current_page: 1,
    per_page: 10000000000,
    total: 0,
  });
  const dataClass = data?.data.sort((a, b) => a.name.localeCompare(b.name));
  const [options, setOptions] = useState([]); // 

  // fetch university list
  const { data: dataUniversity, isLoading: lodadingUniversity } = useUniversityPagination(
    { dataTable }
  );

  const dataOptionsUniversity = dataUniversity?.data?.data.map((item) => ({
    value: item.nama_pt,
    id: item.id_sp,
    key: item.id_sp,
  }));

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const handleSearch = (value) => {
    setOptions(() => {
      return !value
        ? []
        : dataOptionsUniversity.filter((item) =>
          item.value.toLowerCase().includes(value.toLowerCase())
        );
    });
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="double-form">
      <div>
        {(isSuperAdmin) &&
          <Form.Item
            name="foundationId"
            label="Foundation"
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => (newData.foundationId = e)}
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              pattern: new RegExp("^[A-Za-z ]+$"),
              message: "Nama hanya dapat berupa huruf",
            },
            { min: 5, message: "Nama minimal 5 karakter" },
          ]}
          onChange={({ target: { value } }) => (newData["name"] = value)}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Kelas" name="class_id">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              newData.class_id = a;
            }}
          >
            {dataClass?.map((data) => (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="NIK"
          name="nik"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Input hanya berupa angka",
            },
            { min: 16, message: "Minimal 16 karakter" },
            { max: 16, message: "Maksimal 16 karakter" },
          ]}
          onChange={({ target: { value } }) => (newData["nik"] = value)}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="NIS"
          name="nis"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { min: 3, message: "minimal 3 karakter" },
          ]}
          onChange={({ target: { value } }) => (newData["nis"] = value)}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="NISN"
          name="nisn"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { min: 3, message: "minimal 3 karakter" },
          ]}
          onChange={({ target: { value } }) => (newData["nisn"] = value)}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Jenis Kelamin" name="gender">
          <Radio.Group
            buttonStyle="solid"
            onChange={({ target: { value } }) => (newData.gender = value)}
          >
            <Radio.Button value="male">Laki-laki</Radio.Button>
            <Radio.Button value="female">Perempuan</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Status"
          name="isGraduated"
        >
          <Radio.Group
            buttonStyle="solid"
            onChange={({ target: { value } }) => (newData.isGraduated = value)}
          >
            <Radio.Button value={false}>Aktif</Radio.Button>
            <Radio.Button value={true}>Lulus</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Kota Lahir"
          name="birth_city"
          onChange={({ target: { value } }) => (newData["birth_city"] = value)}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Tanggal Lahir" name="birth_day">
          <DatePicker onChange={(e) => (newData.birth_day = e)} />
        </Form.Item>

        <Form.Item label="Agama" name="religion">
          <Select
            onChange={(a) => {
              newData.religion = a;
            }}
            // defaultValue="islam"
            options={[
              {
                value: "islam",
                label: "Islam",
              },
            ]}
          />
        </Form.Item>
        {/* <Form.Item label="Universitas" name="institution">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            filterOption={filterOption}
            options={options}
          />
        </Form.Item> */}
        <Form.Item label="Universitas (tuliskan nama universitas jika tidak ada)" name="institution">
          <AutoComplete
            options={options}
            placeholder="cari atau input universitas"
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(e) => {
              newData.institution = e
            }}
            onSearch={handleSearch}
            disabled={lodadingUniversity}
          />
        </Form.Item>
        <Form.Item
          label="Jurusan"
          name="department"
          onChange={({ target: { value } }) => (newData["department"] = value)}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabIdentitasSiswa;
