import { Form, Input } from "antd";
import React from "react";

const TabRekening = () => {
  return (
    <div className="tab-wrapper">
      <Form.Item label="Nama Bank" name="bank_name" rules={[{ max: 30 }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Rekening Atas Nama"
        name="bank_account_owner"
        rules={[{ max: 50 }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="No Rekening"
        name="bank_account_number"
        rules={[
          {
            pattern: new RegExp("^([0-9]*)$"),
            message: "input hanya berupa angka",
          },
          {
            max: 30,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};

export default TabRekening;
