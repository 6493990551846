import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useEmployeeMember = (id, keyword) => {
  return useQuery(
    ["get-employeemember-list", id, keyword],
    () => GetList(`/get-employees/${id}?keyword=${keyword ? keyword : ""}`),
    { enabled: false }
  );
};
