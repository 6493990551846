import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const RequireForgotPassword = ({ children }) => {
  if (Cookies.get("emailForgotPassword")) {
    return children;
  }

  return <Navigate to="/" />;
};

export default RequireForgotPassword;
