import { createContext, useState } from "react";

export const ActivityContext = createContext();

export const ActivityProvider = ({ children }) => {
  const [memberId, setMemberId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [fetchActivity, setFetchActivity] = useState(false);
  const [fetchSession, setFetchSession] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);
  const [showEditMember, setShowEditMember] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  return (
    <ActivityContext.Provider
      value={{
        memberId,
        sessionId,
        fetchActivity,
        showAddMember,
        showEditMember,
        selectedRow,
        fetchSession,
        setFetchSession,
        setMemberId,
        setSessionId,
        setFetchActivity,
        setShowAddMember,
        setShowEditMember,
        setSelectedRow,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
