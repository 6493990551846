import React from "react";

const MReportHeader = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={4}>RAPOR KINERJA BULANAN {data?.dataUnit?.name}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Nama</td>
          <td>: {data?.dataEmployee[0]?.name}</td>
          <td>Masa Kerja</td>
          <td>: {data?.dataEmployee[0]?.period_of_work}</td>
        </tr>
        <tr>
          <td>NIK</td>
          <td>: {data?.dataEmployee[0]?.nik}</td>
          <td>Periode</td>
          <td>: {data?.dataEmployee[0]?.period_of_assesment}</td>
        </tr>
        <tr>
          <td>Jabatan</td>
          <td>
            :{" "}
            {data?.dataEmployee[0]?.divisi
              ?.map(
                (x) =>
                  `${x?.title?.charAt(0).toUpperCase()}${x?.title?.slice(1)} ${x?.division?.name
                  }`
              )
              .join(" - ")}
          </td>
          <td>Status</td>
          <td>
            :{" "}
            {data?.dataEmployee[0]?.status === "FULL_TIME"
              ? "Full Time"
              : "Part Time"}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MReportHeader;
