import {
  AppstoreFilled,
  DashboardOutlined,
  LogoutOutlined,
  ScheduleFilled,
  SettingFilled,
} from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import { IoMdSchool } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const LayoutPpdbLogic = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");
  const role = Cookies.get("role") && decryptCookies("role");

  const { data } = usePermission("mdlPpdb");

  const handleClickItemUser = (e) => {
    if (e.key === "profile") {
      if (role === "student_candidate") navigate("/ppdb/profile");
      else navigate("/profile");
    } else return;
  };

  const handleSelectMenu = (param) => encryptCookies("defaultPpdbMenu", param);

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuPpdb") navigate("/ppdb/dashboard");
      else navigate("/ppdb/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const menuJadwal = data?.find((x) => x.id === "mnuJadwal");
  const menuSetting = data?.find((x) => x.id === "mnuSetting");
  const menuPendaftaran = data?.find((x) => x.id === "mnuPendaftaran");

  const itemsUser = [
    {
      key: "profile",
      label: (
        <span>{role === "student_candidate" ? "Setting" : "Profile"}</span>
      ),
    },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    { key: "mnuPpdb", icon: <DashboardOutlined />, label: "Dashboard" },
    menuPendaftaran && {
      key: "mnuPendaftaran",
      icon: <IoMdSchool />,
      label: "Pendaftaran",
      disabled: menuPendaftaran?.type === "disabled",
    },
    menuJadwal && {
      key: "mnuJadwal",
      icon: <ScheduleFilled />,
      label: "Jadwal",
      disabled: menuJadwal?.type === "disabled",
    },
    menuSetting && {
      key: "mnuSetting",
      icon: <SettingFilled />,
      label: "Setting",
      disabled: menuSetting?.type === "disabled",
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return {
    open,
    email,
    items,
    items2,
    itemsUser,
    setOpen,
    handleClickMenu,
    handleClickItemUser,
  };
};

export default LayoutPpdbLogic;
