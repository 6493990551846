import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EditTransactionLogic = ({ id, show, onUpdate, dataTransaction }) => {
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const { billings_id: idBilling } = useParams();

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        amount: dataTransaction?.pivot_amount,
      });
    }

    return () => setNewData({});
  }, [form, dataTransaction, show]);

  // dev ---
  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (Object.keys(newData).length === 0) {
        alert("Nothing has changed");
        return;
      }
      const postData = {
        billing_id: idBilling,
        amount: newData?.amount,
        transaction_id: id,
      };
      setLoading(true);
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/finance/transaction-billings`,
        postData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      form.resetFields();
    } catch (error) {
      message.error(
        error.response.data.error ||
          error.response.data.message ||
          error.message
      );
    } finally {
      setLoading(false);
      onUpdate();
    }
  };

  return {
    form,
    newData,
    loading,
    handleSubmit,
  };
};

export default EditTransactionLogic;
