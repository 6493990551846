import { DatePicker, Form, Input, Select } from "antd";
import React, { useState } from "react";

const TabDataWali = () => {
  const [isNameChange, setIsNameChange] = useState([]);

  return (
    <div className="double-form">
      <div>
        <Form.Item
          label="Nama Wali"
          name="guardian0_0name"
          rules={[
            {
              pattern: new RegExp("^[A-Za-z ]+$"),
              message: "Nama hanya dapat berupa huruf",
            },
            {
              required: isNameChange.length !== 0,
              message: "Nama wali wajib diisi",
            },
            { min: 5 },
          ]}
        >
          <Input onChange={(a) => setIsNameChange(a.target.value)} />
        </Form.Item>

        <Form.Item
          label="NIK "
          name="guardian0_0nik"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { required: isNameChange.length !== 0, message: "Nik wajib diisi" },
            { max: 16, message: "Maksimal 16 karakter" },
            { min: 16, message: "Minimal 16 karakter" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Tahun Lahir" name="guardian0_0birth_date">
          <DatePicker />
        </Form.Item>

        <Form.Item label="Jenjang Pendidikan" name="guardian0_0education">
          <Select
            options={[
              { value: "SD / Sederajat", label: "SD/Sederajat" },
              { value: "SMP / Sederajat", label: "SMP/Sederajat" },
              { value: "SMA / Sederajat", label: "SMA/Sederajat" },
              { value: "D1", label: "D1" },
              { value: "D2", label: "D2" },
              { value: "D3", label: "D3" },
              { value: "D4", label: "D4" },
              { value: "S1", label: "S1" },
              { value: "S2", label: "S2" },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Pekerjaan"
          name="guardian0_0occupation"
          rules={[
            {
              pattern: new RegExp("^[A-Za-z ,]+$"),
              message: "Nama hanya dapat berupa huruf spasi (,)",
            },
            { max: 40 },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Penghasilan Minimun"
          name="guardian0_0min_salary"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 10 },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Penghasilan Maximum"
          name="guardian0_0max_salary"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 10 },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Alamat" name="guardian0_0address">
          <Input />
        </Form.Item>

        <Form.Item
          label="No. Telp"
          name="guardian0_0phone_number"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 16 },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="guardian0_0email"
          rules={[{ type: "email", message: "email tidak valid" }, { max: 50 }]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item label="Hubungan Dengan Siswa" name="relationship_w_student">
        <Select
          disabled={true}
          options={[
            {
              value: "biological guardian",
              label: "Ayah Kandung",
            },
          ]}
        />
      </Form.Item> */}
      </div>
    </div>
  );
};

export default TabDataWali;
