import { Form, Modal, Select, Skeleton } from "antd";
import EditTeachingsLogic from "./EditTeachingsLogic";

const EditTeachings = (props) => {
  const {
    form,
    dataClass,
    dataPelajaran,
    loading,
    skeleteon,
    handleSubmit,
    isEkskul,
    checkEkskul,
  } = EditTeachingsLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={props.onCancel}
      okButtonProps={{ loading }}
    >
      {skeleteon && <Skeleton active />}

      {!skeleteon && (
        <Form form={form} layout="vertical">
          <Form.Item
            name="classId"
            label="Kelas"
            rules={[{ required: !isEkskul, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              disabled={isEkskul}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataClass?.data.map((kelas) => (
                <Select.Option key={kelas.id} value={kelas.id}>
                  {kelas.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="subjectId"
            label="Pelajaran"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onChange={() => {
                checkEkskul();
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataPelajaran?.data?.map((pelajaran) => (
                <Select.Option key={pelajaran.id} value={pelajaran.id}>
                  {pelajaran.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditTeachings;
