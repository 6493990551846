import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Skeleton,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useSchedulesList } from "hooks/ppdb-hook/useSchedulesList";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// * NOTE: PAGE PENDAFTARAN AWAL - JIKA DAFTAR ULANG ADA PADA EDIT/EditDaftarUlang

const AddPendaftaran = () => {
  const [fileList, setFileList] = useState([]);
  const { REACT_APP_HOST_API } = process.env;
  // const { REACT_APP_DEV } = process.env;

  const [form] = Form.useForm();
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        // { cancelToken: cancelToken.token }
      );
      form.setFieldsValue({
        full_name: data?.data[0]?.name,
        correspondence_email: data?.data[0]?.email,
        nisn: data?.data[0]?.nisn,
      });
      await setDatas(data?.data[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [idBatch, setIdBatch] = useState("");

  //fetch batch ---
  const getBatch = async () => {
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/settings/batches?page=1&limit=10&keyword=&is_active=true`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      setIdBatch(data.data.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  // fetch schedule list---
  const { data, refetch } = useSchedulesList();

  useEffect(() => {
    getData();
    getBatch();
    refetch();
    // eslint-disable-next-line
  }, [refetch, open]);

  // !end pilihan dan jadwal ---

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      alert("Foto tidak boleh kosong!");
    } else {
      setIsLoad(true);
      try {
        const val = await form.validateFields();

        const { data } = await axios.post(
          REACT_APP_HOST_API + `/ppdb/student-candidates/`,
          {
            ...val,
            user_id: datas.id,
            birth_day: val.birth_day && val.birth_day.format("YYYY-MM-DD"),
          },
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        // requiest post lagi--
        await axios.post(
          `${REACT_APP_HOST_API}/ppdb/batch-candidates`,
          {
            candidate_id: data.data.id,
            batch_id: idBatch,
          },
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        // ! FOR PHOTO
        await axios.put(
          `${REACT_APP_HOST_API}/ppdb/student-candidates/${data.data.id}/file-upload`,
          { file: fileList[0], category: "photo" },
          {
            headers: {
              Authorization: "Bearer " + decryptCookies("token"),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // ! end photo

        message.success(
          "Berhasil, silahkan bergabung ke Grub Whatsapp pada halaman dashboard"
        );

        navigate("/ppdb/dashboard");
      } catch (error) {
        alert("Gagal melakukan pendaftaran");
      } finally {
        setIsLoad(false);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="table-header">
            <h1>Formulir Pendaftaran</h1>
          </div>
          <Divider />
          <div className="form-container">
            <div className="form-wrapper">
              <Form form={form} layout="vertical">
                <Form.Item
                  label="Nama Lengkap"
                  name="full_name"
                  rules={[
                    {
                      pattern: new RegExp("^[A-Za-z ]+$"),
                      message: "Nama hanya dapat berupa huruf",
                    },
                    {
                      required: true,
                      message: "Nama wajib diisi",
                    },
                    // {
                    //   min: 5,
                    //   message: "Nama minimal 5 karakter",
                    // },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="NISN"
                  name="nisn"
                  rules={[
                    {
                      pattern: new RegExp("^([0-9]*)$"),
                      message: "Nama hanya dapat berupa huruf",
                    },
                    {
                      required: true,
                      message: "NISN wajib diisi",
                    },
                    {
                      max: 15,
                      message: "Maksimal 15 karakter",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Tanggal Lahir"
                  name="birth_day"
                  rules={[
                    {
                      required: true,
                      message: "Tanggal lahir wajib diisi",
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>

                <Form.Item
                  label="Asal Sekolah"
                  name="junior_hs_name"
                  rules={[
                    {
                      required: true,
                      message: "Asal sekolah wajib diisi",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Jenis Kelamin"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Jenis kelamin wajib diisi",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="male">Laki-laki</Radio>
                    <Radio value="female">Perempuan</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Agama"
                  name="religion"
                  rules={[
                    {
                      required: true,
                      message: "Agama wajib diisi",
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        value: "islam",
                        label: "Islam",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="No.Telp / Whatsapp"
                  name="correspondence_phone"
                  rules={[
                    {
                      pattern: new RegExp("^([0-9]*)$"),
                      message: "input hanya berupa angka",
                    },
                    { min: 8, message: "minimal 8 karakter" },
                    {
                      required: true,
                      message: "No.Telp / Whatsapp wajib diisi",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="correspondence_email"
                  rules={[
                    {
                      required: true,
                      message: "Email wajib diisi",
                    },
                    { type: "email", message: "email tidak valid" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mengetahui SMA FG dari"
                  name="info_source"
                  rules={[
                    {
                      required: true,
                      message: "Wajib diisi",
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        value: "acquaintance",
                        label: "Kenalan",
                      },
                      {
                        value: "social_media",
                        label: "Sosial Media",
                      },
                      {
                        value: "fg_website",
                        label: "Website FG",
                      },
                      {
                        value: "expo",
                        label: "Expo",
                      },
                      {
                        value: "newspaper",
                        label: "Koran",
                      },
                      {
                        value: "radio",
                        label: "Radio",
                      },
                      {
                        value: "brochure",
                        label: "Brosur",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Alasan Mendaftar di SMA FG"
                  name="interest_in_fg"
                  rules={[
                    {
                      required: true,
                      message: "Wajib diisi",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Pilih SPP"
                  name="spp_choice"
                  rules={[
                    {
                      required: true,
                      message: "SPP wajib diisi",
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        value: "2_juta",
                        label: "2 Juta",
                      },
                      {
                        value: "1_5_juta",
                        label: "1.5 Juta",
                      },
                      {
                        value: "1_3_juta",
                        label: "1.3 Juta",
                      },
                      {
                        value: "1_1_juta",
                        label: "1.1 Juta",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Pilih Program"
                  name="program_choice"
                  rules={[
                    {
                      required: true,
                      message: "Program wajib diisi",
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        value: "mahad",
                        label: "Mahad",
                      },
                      {
                        value: "boarding",
                        label: "Boarding",
                      },
                      {
                        value: "fullday",
                        label: "Fullday",
                      },
                      {
                        value: "wisma",
                        label: "Wisma",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Pilih Jurusan"
                  name="major_choice"
                  rules={[
                    {
                      required: true,
                      message: "Jurusan wajib diisi",
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        value: "mipa",
                        label: "Mipa",
                      },
                      {
                        value: "ips",
                        label: "Ips",
                      },
                      {
                        value: "bahasa",
                        label: "Bahasa",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Pilih Jadwal Test"
                  name="test_schedule_choice"
                  rules={[
                    {
                      required: true,
                      message: "Jadwal tes wajib diisi",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {data?.data?.data?.map((data) => (
                      <Select.Option key={data.id} value={data.id}>
                        {`${moment(data.time_start).format(
                          "DD-MMM-YYYY : HH:mm"
                        )} WIB - ${moment(data.time_end).format(
                          "DD-MMM-YYYY : HH:mm"
                        )} WIB | Kuota (${data.current_quota}/${
                          data.max_capacity
                        })`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Photo (setengah badan, latar bebas)"
                  name="photo"
                  required={true}
                >
                  <Upload
                    listType="picture-card"
                    accept=".png,.jpg,.jpeg,.gif"
                    beforeUpload={(file) => {
                      if (file.size > 2000000) {
                        message.error("Ukuran maksimal foto 2MB");
                        return false;
                      }

                      setFileList([...fileList, file]);
                      return false;
                    }}
                    onRemove={(file) => {
                      const index = fileList.indexOf(file);
                      const newFileList = fileList.slice();
                      newFileList.splice(index, 1);
                      setFileList(newFileList);
                    }}
                    fileList={fileList}
                  >
                    {fileList.length > 0 ? null : "+ Upload"}
                  </Upload>
                </Form.Item>
              </Form>

              <Button
                loading={isLoad}
                className="btn-act"
                type="primary"
                onClick={handleSubmit}
              >
                Simpan
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddPendaftaran;
