import { Form, Input, InputNumber, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAccountList } from "hooks/finance-hook/useAccountList";
// import { useStudentList } from "hooks/students-hook/useStudentList";
import { useStudentPagination } from "hooks/students-hook/useStudentPagination";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AddAccountsBatchLogic = () => {
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedVaNumbers, setSelectedVaNumbers] = useState({});
  const [posting, setPosting] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [isNew, setIsNew] = useState("");
  const [prevAccountType, setPrevAccountType] = useState("");
  const [lastNumberAccount, setLastNumberAccount] = useState();
  const [selectedData, setSelectedData] = useState([{}]);
  const [dataSource, setDataSource] = useState([]);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const { REACT_APP_HOST_API } = process.env;
  // const { data: dataStudent, isLoading, refetch } = useStudentList();
  const {
    data: dataStudent,
    isLoading,
    refetch,
  } = useStudentPagination({
    dataTable,
    keyword: searchQuery,
    classId: "",
    isNew,
  });
  const { data: dataAccount } = useAccountList();

  const navigate = useNavigate();

  const navigateToAccounts = () => {
    navigate("/finance/account");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const getLastNumberAccount = useCallback(async () => {
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/finance/accounts/last-account-no`,
        { type: accountType },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      setLastNumberAccount(data.data);
    } catch (error) {
      message.error(error?.response?.data?.error || "Gagal mendapatkan data");
    }
  }, [REACT_APP_HOST_API, accountType]);

  useEffect(() => {
    setDataSource(
      dataStudent?.data.data.slice(0, dataTable.per_page).map((x, i) => {
        return {
          ...x,
          key: x.id,
          index: i + 1,
        };
      })
    );
  }, [dataStudent, dataTable]);

  useEffect(() => {
    if (accountType && accountType !== prevAccountType) {
      setSelectedIds([]);
      const vaValues = {};
      dataSource.forEach((row) => {
        vaValues[`va_${row.id}`] = undefined;
      });
      form.setFieldsValue(vaValues);
      setPrevAccountType(accountType);
    }
  }, [accountType, dataSource, form, prevAccountType]);

  useEffect(() => {
    if (accountType) getLastNumberAccount();
  }, [accountType, getLastNumberAccount]);

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      width: window.innerWidth > 800 ? 300 : 65,
      fixed: "left",
    },
    {
      title: "Nomor VA(Wajib diisi)",
      dataIndex: "va_number",
      key: "owner",
      width: window.innerWidth > 800 ? 500 : 65,
      render: (_, record) => (
        <Form.Item
          name={`va_${record.id}`}
          rules={[
            {
              validator: (_, value) => {
                if (
                  dataAccount.data?.data?.some(
                    (account) => account.number === value
                  )
                ) {
                  return Promise.reject("Nomor VA sudah digunakan");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            disabled={posting}
            onChange={(e) => {
              setSelectedVaNumbers((prev) => {
                return {
                  ...prev,
                  [`va_${record.id}`]: e.target.value,
                };
              });
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Saldo(optional)",
      dataIndex: "balance",
      key: "saldo",
      width: window.innerWidth > 800 ? 500 : 65,
      render: (_, record) => (
        <Form.Item name={`saldo_${record.id}`}>
          <InputNumber
            placeholder="Masukan Saldo"
            disabled={posting}
            formatter={(value) =>
              `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
  ];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onSelectChange = (selectedRowKeys, selectedData) => {
    const newSelectedIds = selectedRowKeys.filter(
      (key) => !selectedIds.includes(key)
    );
    setSelectedIds(selectedRowKeys);
    setSelectedData(selectedData);
    if (lastNumberAccount && newSelectedIds.length > 0) {
      const vaValues = {};
      newSelectedIds.forEach((id, index) => {
        vaValues[`va_${id}`] = lastNumberAccount + index;
      });
      form.setFieldsValue(vaValues);
      setSelectedVaNumbers((prev) => {
        return {
          ...prev,
          ...vaValues,
        };
      });
      setLastNumberAccount((prev) => prev + 1);
    }

    const deselectedRows = dataSource.filter(
      (row) => !selectedRowKeys.includes(row.id)
    );
    deselectedRows.forEach((row) => {
      form.setFieldsValue({ [`va_${row.id}`]: undefined });
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const handleSubmit = async () => {
    try {
      setPosting(true);
      const values = await form.validateFields();

      if (selectedIds.length === 0)
        return alert("Harap pilih siswa yang akan ditambahkan");
      const accountsData = selectedIds.map((x) => {
        const vaNumber = values[`va_${x}`] || selectedVaNumbers[`va_${x}`];
        return {
          student_id: x,
          account_name: selectedData.find((data) => data.id === x).name,
          balance: values[`saldo_${x}`] || undefined,
          ref_amount: values.amount || undefined,
          number: `${vaNumber}` || undefined,
          type: accountType,
        };
      });
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/finance/accounts`,
        { accounts: accountsData },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      form.resetFields();
      setSelectedIds([]);
      setSearchQuery("");
      navigateToAccounts();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setPosting(false);
    }
  };

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: dataStudent?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, 300],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return {
    isLoading,
    form,
    setIsNew,
    columns,
    handleSearch,
    handleSubmit,
    navigateToBillings: navigateToAccounts,
    searchQuery,
    posting,
    dataSource,
    rowSelection,
    setAccountType,
    pagination,
  };
};

export default AddAccountsBatchLogic;
