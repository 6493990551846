import { Button, Col, Row, Skeleton, Tag } from "antd";
import React from "react";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import dayjs from "dayjs";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useRecapParent } from "../../../../../hooks/finance-hook/useRecapParent";

export default function Tagihan({ type, fromDate }) {
  const paretnId = decryptCookies("parent_id");
  const { data, isLoading } = useRecapParent(paretnId, type, fromDate);

  const status = {
    paid_partial: (
      <Tag
        color="blue"
        style={{ fontSize: "12px", textAlign: "right", margin: "0" }}
      >
        Dibayar Sebagian
      </Tag>
    ),
    past_due: (
      <Tag
        color="red"
        style={{ fontSize: "12px", textAlign: "right", margin: "0" }}
      >
        Terlambat
      </Tag>
    ),
    unpaid: (
      <Tag
        color="orange"
        style={{ fontSize: "12px", textAlign: "right", margin: "0" }}
      >
        Belum Bayar
      </Tag>
    ),
  };
  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Button
          style={{ width: "100%" }}
          type="primary"
          onClick={() => {
            window.open("/finance/download-tagihan", "_blank");
          }}
        >
          Download Kartu Tagihan
        </Button>

        {isLoading ? (
          <Skeleton active />
        ) : (
          <div>
            <div
              style={{
                marginTop: "20px",
                border: "0.5px solid #dddddd",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              {data?.data?.account_balances?.map((item, index) => (
                <Row key={index}>
                  <Col span={12}>Total Saldo {item?.type}</Col>
                  <Col
                    span={12}
                    style={{ textAlign: "right", marginBottom: "5px" }}
                  >
                    {renderAmount(item?.value)}
                  </Col>
                </Row>
              ))}

              {data?.data?.subtotal?.map((item, index) => (
                <Row key={index}>
                  <Col span={12}>Total Tagihan {item?.type}</Col>
                  <Col
                    span={12}
                    style={{ textAlign: "right", marginBottom: "5px" }}
                  >
                    {renderAmount(item?.value)}
                  </Col>
                </Row>
              ))}

              <Row>
                <Col span={12}>Total Tunggakan</Col>
                <Col
                  span={12}
                  style={{ textAlign: "right", marginBottom: "5px" }}
                >
                  {renderAmount(data?.data?.tunggakan)}
                </Col>
              </Row>
              <Row style={{ fontWeight: "bold" }}>
                <Col span={12}>Total Tagihan</Col>
                <Col
                  span={12}
                  style={{ textAlign: "right", marginBottom: "5px" }}
                >
                  {renderAmount(data?.data?.total)}
                </Col>
              </Row>
            </div>

            <p
              style={{
                marginTop: "20px",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Daftar Tagihan Aktif
            </p>

            <div>
              {data?.data?.report?.map((item) => (
                <div
                  key={item?.id}
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "15px",
                    padding: "10px",
                    border: "0.5px solid #dddddd",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100px",
                      minHeight: "79px",
                      backgroundColor: "#4096FF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "5px",
                    }}
                  >
                    <h1>{item?.type}</h1>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td>Status</td>
                          <td>: {status[item?.status]}</td>
                        </tr>
                        <tr>
                          <td>No. Va</td>
                          <td>: {item?.account_number}</td>
                        </tr>
                        <tr>
                          <td>Jatuh Tempo</td>
                          <td>: {dayjs(item?.date).format("DD MMM YYYY")}</td>
                        </tr>
                        <tr>
                          <td>Total Tagihan</td>
                          <td>: {renderAmount(item?.amount)}</td>
                        </tr>
                        <tr>
                          <td>Sisa Tagihan</td>
                          <td>: {renderAmount(item?.remaining_amount)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
