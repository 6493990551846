import React, { useState } from "react";
import { FloatButton } from "antd";
import CoverRaport from "../tab/CoverRaport";
import DataDiri from "../tab/DataDiri";
import Deskripsi from "../tab/Deskripsi";
import Umum from "../tab/Umum";
import {
  AppstoreOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

import { useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Deskripsi2 from "../tab/Deskripsi2";
import Deskripsi3 from "../tab/Deskripsi3";

export default function RaportPage() {
  const [isLoading, setIsLoading] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // todo: cek window width secara real time
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
    }
  }, [windowWidth]);

  // const handleExport = () => {
  //   const input = document.getElementById("export-element");

  //   if (input) {
  //     html2canvas(input, { width: 800, height: 30000 }).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/jpg");
  //       const pdf = new jsPDF();
  //       pdf.addImage(imgData, "JPG", 0, 0);
  //       pdf.save("table.pdf");
  //     });
  //   }
  // };

  const handlePrint = () => {
    window.print();
  };

  const generateImageFromComponent = async (
    component,
    width,
    height,
    scale = 1
  ) => {
    const canvas = await html2canvas(component, { width, height, scale });
    return canvas.toDataURL("image/jpeg");
  };

  const handleExport = async () => {
    const mainElement = document.getElementById("export-element");
    setIsLoading(true);
    if (mainElement) {
      const pdf = new jsPDF();
      const mainChildren = mainElement.children;

      for (let i = 0; i < mainChildren.length; i++) {
        const image = await generateImageFromComponent(
          mainChildren[i],
          900,
          mainChildren[i].clientHeight
        );
        pdf.addImage(image, "JPEG", 0, 0);

        if (i < mainChildren.length - 1) {
          pdf.addPage();
        }
      }

      pdf.save("table.pdf");
    }
    setIsLoading(false);
  };

  const style = {
    backgroundColor: "#f6f6f6",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    padding: "10px",
    width: windowWidth < 800 ? "128vh" : "100%",
  };
  const styleItem = {
    // height: "14in",
  };

  return (
    <>
      <FloatButton.Group
        className="float-btn"
        // trigger="hover"
        type="primary"
        style={{
          top: 20,
          right: window.innerWidth < 800 ? 10 : 80,
        }}
        icon={<AppstoreOutlined />}
      >
        <FloatButton
          hidden
          ghost
          icon={<FilePdfOutlined />}
          onClick={handlePrint}
          tooltip={<div>Print</div>}
        />
        <FloatButton
          type="primary"
          icon={isLoading ? <LoadingOutlined /> : <PrinterOutlined />}
          onClick={handleExport}
          tooltip={<div>Save as PDF</div>}
        />
      </FloatButton.Group>

      <main id="export-element" className="exmp" style={style}>
        <div style={styleItem}>
          <CoverRaport />
        </div>
        <div style={styleItem} className="break-print">
          <DataDiri />
        </div>
        <div style={styleItem} className="break-print">
          <Umum />
        </div>
        <div style={styleItem} className="break-print">
          <Deskripsi />
        </div>
        <div style={styleItem} className="break-print">
          <Deskripsi2 />
        </div>
        <div style={styleItem}>
          <Deskripsi3 />
        </div>
      </main>
    </>
  );
}
