import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useUserParentDetail = (parentId) => {
  return useQuery(
    ["get-user-parent-detail", parentId],
    () => GetDetail(`/system/parents/${parentId}`),
    {
      enabled: false,
    }
  );
};
