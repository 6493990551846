import { InboxOutlined } from "@ant-design/icons";
import { Form, Modal, Spin, Upload } from "antd";
import React from "react";
import ImportUpdateLogic from "./ImportUpdateLogic";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFileCsv } from "react-icons/fa";
const { Dragger } = Upload;

const ImportUpdate = (props) => {
  const {
    form,
    propsUpload,
    uploading,
    isExcel,
    onCancelModal,
    handleUpload,
    isLoading,
  } = ImportUpdateLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleUpload}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: uploading }}
      cancelButtonProps={{ disabled: uploading }}
    >
      <Form form={form} layout="vertical">
        <div>
          {isLoading ? (
            <Spin />
          ) : (
            <p style={{ fontSize: "16px" }}>
              Download template file{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/1jIVaGyH9xIjhFpsdOuwQ3qHVqlSQ1OnZ/edit#gid=1854117761"
              >
                here
              </a>
              .
            </p>
          )}
        </div>
        <Form.Item
          name="file"
          label="File Excel atau CSV"
          rules={[
            {
              validator: (_, value) => {
                if (!value || !value.fileList || value.fileList.length === 0) {
                  return Promise.reject(
                    new Error("Please upload an Excel file or CSV")
                  );
                }
                const file = value.fileList[0];
                if (!isExcel(file)) {
                  return Promise.reject(
                    new Error("Please upload an Excel file or CSV")
                  );
                }
                return Promise.resolve();
              },
            },
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Dragger
            name="file"
            accept=".xlsx, .xls, .csv, .xlsb, .xlsm, .xml"
            listType="picture"
            disabled={uploading}
            iconRender={(file) => {
              if (
                file.type === "application/vnd.ms-excel" ||
                file.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                return (
                  <SiMicrosoftexcel
                    size={45}
                    color={
                      file.type === "application/vnd.ms-excel"
                        ? "#1e90ff"
                        : "#008000"
                    }
                  />
                );
              }
              if (file.type === "text/csv") {
                return (
                  <FaFileCsv
                    size={45}
                    color={file.type === "text/csv" ? "#1e90ff" : "#008000"}
                  />
                );
              }
              return <InboxOutlined />;
            }}
            {...propsUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for excel or csv file.</p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportUpdate;
