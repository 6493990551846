import { Button, Modal } from "antd";
import React from "react";

const DetailActivity = (props) => {
  const { show, setShow, activity } = props;

  return (
    <Modal
      open={show}
      closable={false}
      onCancel={() => setShow(false)}
      footer={[
        <Button key="1" type="primary" onClick={() => setShow(false)}>
          Close
        </Button>,
      ]}
    >
      {activity && (
        <table className="presence__table">
          <tbody>
            <tr>
              <th>Aktivitas</th>
              <td>{activity.name}</td>
            </tr>
            <tr>
              <th>Deskripsi</th>
              <td>{activity.description}</td>
            </tr>
            <tr>
              <th>Jam Masuk</th>
              <td>{`${activity.time_in_start} - ${activity.time_in_end}`}</td>
            </tr>
            <tr>
              <th>Jam Pulang</th>
              <td>{`${activity.time_out_start} - ${activity.time_out_end}`}</td>
            </tr>
          </tbody>
        </table>
      )}
    </Modal>
  );
};

export default DetailActivity;
