import { Form, Input, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

const ResetPasswordProfile = ({ show, onReset, onCancel }) => {
  const [newData, setNewData] = useState({});
  const [form] = Form.useForm();

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    return () => {
      setNewData({});
    };
  }, [show]);

  const onSubmit = () => {
    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }

    form
      .validateFields()
      .then(() => {
        axios
          .post(REACT_APP_HOST_API + `/auth/reset-password`, newData, {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          })
          .then((res) => {
            message.success(res.data.message, 2);
            form.resetFields();
            onReset();
          })
          .catch((err) => {
            if (err.response.data.message) {
              alert(err.response.data.message);
            } else {
              alert(err.message);
            }
          });
      })
      .catch((err) => alert(err.message));
  };

  return (
    <Modal
      open={show}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Password Lama"
          name="old_password"
          rules={[{ min: 6, message: "Enter min 6 character" }]}
        >
          <Input.Password
            onChange={({ target: { value } }) =>
              (newData["old_password"] = value)
            }
          />
        </Form.Item>
        <Form.Item
          label="Password Baru"
          name="password"
          hasFeedback
          rules={[{ min: 6, message: "Enter min 6 character" }]}
        >
          <Input.Password
            onChange={({ target: { value } }) => (newData["password"] = value)}
          />
        </Form.Item>
        <Form.Item
          label="Konfirmasi Password Baru"
          name="password_confirmation"
          hasFeedback
          rules={[
            { min: 6, message: "Enter min 6 character" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password must be the same"));
              },
            }),
          ]}
        >
          <Input.Password
            onChange={({ target: { value } }) =>
              (newData["password_confirmation"] = value)
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPasswordProfile;
