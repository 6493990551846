import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";

const EditRevenuesLogic = ({ onUpdate, selectedData }) => {
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  // if selectedData only have 1 data set the form
  useEffect(() => {
    if (selectedData.length === 1) {
      const { status } = selectedData[0];
      form.setFieldsValue({
        status,
      });
      setNewData({});
    } else if (selectedData.length > 1) {
      form.setFieldsValue({
        status: "",
      });
      setNewData({});
    }
  }, [selectedData, form]);

  const handleSubmit = async () => {
    try {
      if (Object.keys(newData).length === 0) {
        alert("Nothing has changed");
        return;
      }

      // remove null value
      Object.keys(newData).forEach(
        (key) => newData[key] == null && delete newData[key]
      );

      setLoading(true);
      const revenuesData = selectedData.map((x) => {
        return {
          id: x.id,
          ...newData,
        };
      });
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/finance/revenues`,
        { revenues: revenuesData },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      form.resetFields();
      onUpdate();
    } catch (error) {
      console.log("error", error);
      alert(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    form,
    newData,
    loading,
    handleSubmit,
    setNewData,
  };
};

export default EditRevenuesLogic;
