import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useChartTeacherAttendance = (fromDate, toDate, foundationId) => {
  return useQuery(
    ["get-chart-teacher-attendance", fromDate, toDate, foundationId],
    () =>
      GetList(
        `/academics/dashboard/teacher-attendance?fromDate=${fromDate}&toDate=${toDate}&foundationId=${foundationId}`
      ),
    {
      enabled: true,
    }
  );
};
