import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { usePresenceDetail } from "hooks/presence-hook/usePresenceDetail";
import moment from "moment";
import { useEffect, useState } from "react";

const EditPresenceLogic = ({ activityId, dataId, show, onUpdate }) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);

  const format = "YYYY-MM-DD HH:mm:ss";
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleton,
    refetch,
  } = usePresenceDetail(dataId, false);

  useEffect(() => {
    if (show) refetch();

    if (data) {
      form.setFieldsValue({
        activityId: activityId,
        description: data.data.description,
        timeIn: data.data.time_in && dayjs(moment(data.data.time_in)),
        timeOut: data.data.time_out && dayjs(moment(data.data.time_out)),
      });
    }

    return () => {
      setNewData({});
    };
  }, [show, data, dataId, activityId, form, refetch]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        if (Object.keys(newData).length === 0) {
          alert("Nothing has changed");
          return;
        }

        for (const key in newData) {
          if (Object.hasOwnProperty.call(newData, key)) {
            let element = newData[key];
            if (element === "") newData[key] = null;
          }
        }

        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + "/presence/" + dataId,
            {
              ...newData,
              activityId,
            },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          onUpdate();
          form.resetFields();
          message.success(data.message);
        } catch (error) {
          alert(error.message);
        }
        setLoading(false);
      })
      .catch(() => alert("Input Fields Error"));
  };

  return {
    form,
    format,
    newData,
    loading,
    skeleton,
    handleSubmit,
  };
};

export default EditPresenceLogic;
