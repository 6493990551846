import { Button, DatePicker, Form, Table, Input, Select } from "antd";
import React from "react";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";
import * as XLSX from "xlsx";
import "dayjs/locale/id"; // Import the Indonesian locale
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useTeachersPerSubjectFoundation } from "hooks/attandance-hook/useTheacherPerSubjectFoundation";

dayjs.locale("id");

const TeachersPerSubject = () => {
  const [form] = Form.useForm();
  const [dateIn, setDateIn] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateOut, setDateOut] = useState(dayjs().format("YYYY-MM-DD"));
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const {
    data,
    isLoading,
    isFetching,
    // refetch: fetchDaily,
  } = useTeachersPerSubjectFoundation(
    dataTable,
    dateIn,
    dateOut,
    keyword,
    foundationId,
    true
  );

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 60 : 50,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "employeeName",
      key: "employeeName",
      width: 150,
      align: "left",
      fixed: "left",
    },
    {
      title: "Mengajar",
      dataIndex: "teach",
      key: "teach",
      align: "left",
    },
    {
      title: "Tidak Mengajar",
      dataIndex: "not_teach",
      key: "not_teach",
      align: "left",
    },
    {
      title: "Ujian",
      dataIndex: "exam",
      key: "exam",
      align: "left",
    },
    {
      title: "Tugas rumah",
      dataIndex: "homework",
      key: "homework",
      align: "left",
    },
    {
      title: "Persentase",
      dataIndex: "",
      key: "teach_precentage",
      align: "center",
      children: [
        {
          title: "Mengajar",
          dataIndex: "teach_precentage",
          key: "teach_precentage",
          align: "left",
          render: (text) => {
            return <>{text}%</>;
          },
        },
        {
          title: "Tidak Mengajar",
          dataIndex: "not_teach_precentage",
          key: "not_teach_precentage",
          align: "left",
          render: (text) => {
            return <>{text}%</>;
          },
        },
        {
          title: "Ujian",
          dataIndex: "exam_precentage",
          key: "exam_precentage",
          align: "left",
          render: (text) => {
            return <>{text}%</>;
          },
        },
        {
          title: "Tugas rumah",
          dataIndex: "homework_precentage",
          key: "homework_precentage",
          align: "left",
          render: (text) => {
            return <>{text}%</>;
          },
        },
      ],
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const dataSources = data?.data.data
    .slice(0, dataTable.per_page)
    .map((item, index) => {
      return {
        ...item,
        index: index + 1,
        key: index,
        employeeName: item.teacher.employee.name,
      };
    });

  const dataToExport = data?.data?.data?.map((item) => {
    const statusRecap = {
      mengajar: `${item.teach}  (${item.teach_precentage}%)`,
      tidak_mengajar: `${item.not_teach}  (${item.not_teach_precentage}%)`,
      ujian: `${item.exam}  (${item.exam_precentage}%)`,
      tugas_rumah: `${item.homework}  (${item.homework_precentage}%)`,
    };

    return {
      nama: item.teacher.employee.name,
      ...statusRecap,
    };
  });

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `rekap_jurnal_mengajar.xlsx`);
  };

  return (
    <div>
      <div className="table-header">
        <h1>Recaps Teaching Journal</h1>

        <Button type="primary" onClick={handleExport}>
          Export to Excel
        </Button>
      </div>
      <Form form={form} layout="vertical">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "10px",
          }}
        >
          {isSuperAdmin && (
            <Form.Item
              name="foundationId"
              label="Yayasan"
              className="form-item-kehadiran"
              style={{ marginBottom: 0, width: "100%" }}
            >
              <Select
                style={{ minWidth: 150 }}
                onChange={(x) => {
                  setFoundationId(x);
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Nama">
            <Input
              placeholder="cari berdasarkan nama"
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="date_in"
            label="Tanggal mulai"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
          >
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) => setDateIn(dayjs(value).format("YYYY-MM-DD"))}
            />
          </Form.Item>
          <Form.Item
            name="date_out"
            label="Tanggal akhir"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
          >
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) =>
                setDateOut(dayjs(value).format("YYYY-MM-DD"))
              }
            />
          </Form.Item>
        </div>
      </Form>
      <Table
        size={window.innerWidth > 1600 ? "middle" : "small"}
        tableLayout="auto"
        dataSource={dataSources}
        columns={columns}
        loading={isLoading || isFetching}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 1300 }
            : { y: "55vh", x: 1300 }
        }
        pagination={pagination}
      />
    </div>
  );
};

export default TeachersPerSubject;
