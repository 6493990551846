import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useRevenuesPaginition = (
  dataTable,
  keyword,
  filterObject,
  foundationId
) => {
  return useQuery(
    [
      "get-revenues-finance-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      filterObject.fromDate,
      filterObject.toDate,
      foundationId,
    ],
    () =>
      GetPagination(
        `/finance/revenues?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&keyword=${keyword}&from_date=${filterObject.fromDate}&to_date=${filterObject.toDate}&foundation_id=${foundationId}`
      )
  );
};
