import Cookies from "js-cookie";

export const DeleteCookies = () => {
  Cookies.remove("parent_id");
  Cookies.remove("acl");
  Cookies.remove("role");
  Cookies.remove("time");
  Cookies.remove("token");
  Cookies.remove("login");
  Cookies.remove("activityId");
  Cookies.remove("select_button_scan");
  Cookies.remove("defaultHrdMenu");
  Cookies.remove("defaultSystemMenu");
  Cookies.remove("defaultProfileMenu");
  Cookies.remove("defaultAcademicMenu");
  Cookies.remove("defaultInventoryMenu");
  Cookies.remove("defaultExecutiveMenu");
  Cookies.remove("asset_loan");
  Cookies.remove("name");
  Cookies.remove("email");
  Cookies.remove("perAcademic");
  Cookies.remove("perInventory");
  Cookies.remove("perExecutive");
  Cookies.remove("perHrd");
  Cookies.remove("perSystem");
  Cookies.remove("teacher_id");
  Cookies.remove("subject_id");
  Cookies.remove("employeeId");
  Cookies.remove("perFinance");
  Cookies.remove("perPpdb");
  Cookies.remove("perFoundation");
  Cookies.remove("perFinanceReport");
};
