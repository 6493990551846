import { EyeOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const DokumenPembayaranTap = () => {
  const { candidate_id } = useParams();

  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  // filtered parent
  const filteredFather = dataInti?.parents?.filter(
    (obj) => obj.relationship_w_student === "biological father"
  );
  const filteredMother = dataInti?.parents?.filter(
    (obj) => obj.relationship_w_student === "biological mother"
  );

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Akta Kelahiran</th>
                <td>
                  {dataInti.birth_cert_scan && (
                    <a
                      href={dataInti.birth_cert_scan}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<EyeOutlined />}
                      >
                        Lihat
                      </Button>
                    </a>
                  )}
                </td>
              </tr>

              <tr>
                <th>Kartu Keluarga (KK)</th>
                <td>
                  {dataInti.family_card_scan && (
                    <a
                      href={dataInti.family_card_scan}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<EyeOutlined />}
                      >
                        Lihat
                      </Button>
                    </a>
                  )}
                </td>
              </tr>

              <tr>
                <th>Surat Keterangan Lulus (SKL)</th>
                <td>
                  {dataInti.jhs_graduation_letter_scan && (
                    <a
                      href={dataInti.jhs_graduation_letter_scan}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<EyeOutlined />}
                      >
                        Lihat
                      </Button>
                    </a>
                  )}
                </td>
              </tr>

              <tr>
                <th>Ijazah</th>
                <td>
                  {dataInti.jhs_certificate_scan && (
                    <a
                      href={dataInti.jhs_certificate_scan}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<EyeOutlined />}
                      >
                        Lihat
                      </Button>
                    </a>
                  )}
                </td>
              </tr>

              <tr>
                <th>KTP Ayah</th>
                <td>
                  {filteredFather.length !== 0 &&
                    filteredFather[0]?.ktp_scan !== null && (
                      <a
                        href={filteredFather[0].ktp_scan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          size="small"
                          type="primary"
                          icon={<EyeOutlined />}
                        >
                          Lihat
                        </Button>
                      </a>
                    )}
                </td>
              </tr>

              <tr>
                <th>KTP Ibu</th>
                <td>
                  {filteredMother.length !== 0 &&
                    filteredMother[0]?.ktp_scan !== null && (
                      <a
                        href={filteredMother[0].ktp_scan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          size="small"
                          type="primary"
                          icon={<EyeOutlined />}
                        >
                          Lihat
                        </Button>
                      </a>
                    )}
                </td>
              </tr>

              <tr>
                <th>Slip Pembayaran</th>
                <td>
                  {dataInti.scan_payment_proof && (
                    <a
                      href={dataInti.scan_payment_proof}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<EyeOutlined />}
                      >
                        Lihat
                      </Button>
                    </a>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DokumenPembayaranTap;
