import dayjs from "dayjs";
import React, { createContext, useContext, useState } from "react";

const PresenceContext = createContext();

export function usePresenceContext() {
  return useContext(PresenceContext);
}

export function PresenceProvider({ children }) {
  const [isRecap, setIsRecap] = useState(true);
  const [dateIn, setDateIn] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateOut, setDateOut] = useState(dayjs().format("YYYY-MM-DD"));

  const [dateInFilter, setDateInFilter] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [dateOutFilter, setDateOutFilter] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [className, setClassName] = useState("");
  const [subject, setSubject] = useState("");
  const [session, setSession] = useState("");
  const [isFetchFilter, setIsFetchFilter] = useState(false);
  return (
    <PresenceContext.Provider
      value={{
        dateInFilter,
        setDateInFilter,
        dateOutFilter,
        setDateOutFilter,
        isFetchFilter,
        setIsFetchFilter,
        isRecap,
        setIsRecap,
        dateIn,
        dateOut,
        setDateIn,
        setDateOut,
        className,
        setClassName,
        subject,
        setSubject,
        session,
        setSession,
      }}
    >
      {children}
    </PresenceContext.Provider>
  );
}
