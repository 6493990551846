import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useTemplateHook = (keyword) => {
  return useQuery(
    ["get-template-import", keyword],
    () => GetPagination(`/template-excels?page=1&limit=10&keyword=${keyword}`),
    { enabled: false }
  );
};
