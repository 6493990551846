import { Table } from "antd";
import CreatePresence from "../create/CreatePresence";
import EditPresence from "../edit/EditPresence";
import DetailActivity from "./DetailActivity";
import "./Presence.css";
import PresenceFilter from "./PresenceFilter";
import PresenceHeader from "./PresenceHeader";
import PresenceTableLogic from "./PresenceLogic";
import SelectModal from "./SelectModal";
import SetTimeOutDefault from "../time-out-default/SetTimeOutDefault";

const PresenceTable = () => {
  const {
    id,
    date,
    data,
    dataId,
    btnAdd,
    btnTimeout,
    dataSource,
    keyword,
    columns,
    btnRecap,
    showEdit,
    showModal,
    btnExport,
    skeleton,
    isLoading,
    isFetching,
    showCreate,
    pagination,
    showDetail,
    idActivity,
    dataActivity,
    timeOutDisabled,
    showTimeoutDefault,
    onCreate,
    onUpdate,
    onCancel,
    handleExport,
    handleChange,
    setShowCreate,
    setShowDetail,
    onSubmitTimeOut,
    handleCancelModal,
    handleDateToChange,
    handleDateFromChange,
    handleActivityChange,
    setShowTimeoutDefault,
  } = PresenceTableLogic();

  return (
    <>
      {idActivity === ":id" && (
        <SelectModal
          show={showModal}
          skeleton={skeleton}
          dataActivity={dataActivity?.data}
          onCancel={handleCancelModal}
          onChange={handleActivityChange}
        />
      )}

      {idActivity !== ":id" && (
        <>
          <DetailActivity
            show={showDetail}
            setShow={setShowDetail}
            activity={data?.data.activity}
          />
          <PresenceHeader
            id={id}
            btnAdd={btnAdd}
            btnRecap={btnRecap}
            btnExport={btnExport}
            btnTimeout={btnTimeout}
            disabled={timeOutDisabled}
            activity={data?.data.activity}
            handleExport={handleExport}
            setShowDetail={setShowDetail}
            setShowCreate={setShowCreate}
            setShowTimeoutDefault={setShowTimeoutDefault}
          />
          <PresenceFilter
            date={date}
            keyword={keyword}
            onChange={handleChange}
            handleDateToChange={handleDateToChange}
            handleDateFromChange={handleDateFromChange}
          />
          <Table
            bordered
            size="small"
            tableLayout="auto"
            columns={columns}
            loading={isLoading || isFetching}
            dataSource={dataSource}
            pagination={pagination}
            scroll={
              window.innerHeight < 690
                ? { y: "52vh", x: 800 }
                : { y: "55vh", x: 800 }
            }
          />
        </>
      )}

      <CreatePresence
        activityId={id}
        show={showCreate}
        onCreate={onCreate}
        onCancel={() => setShowCreate(false)}
      />
      <EditPresence
        dataId={dataId}
        activityId={id}
        show={showEdit}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
      <SetTimeOutDefault
        activityId={id}
        dateTo={date.to}
        onCancel={onCancel}
        dateFrom={date.from}
        show={showTimeoutDefault}
        onSubmitTimeOut={onSubmitTimeOut}
        timeOut={data?.data?.activity?.time_out_default}
      />
    </>
  );
};

export default PresenceTable;
