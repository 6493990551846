import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useStudentsTemplate } from "hooks/template-hook/useStudentsTemplate";
import { useEffect, useState } from "react";

const ImportUpdateLogic = (props) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  const { data: dataTemplate, refetch, isLoading } = useStudentsTemplate();

  useEffect(() => {
    if (props.show) {
      refetch();
    }
  }, [props.show, refetch]);

  const isExcel = (file) => {
    return (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.template.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.addin.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.sheet.binary.macroEnabled.12" ||
      file.type === "text/csv"
    );
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const values = await form.validateFields();
      const { data } = await axios({
        method: "PUT",
        url: REACT_APP_HOST_API + `/academics/update-student-nisn`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + decryptCookies("token"),
        },
        data: { upload: values.file.file },
      });
      message.success(data.message);
      setFileList([]);
      props.onImport();
    } catch (error) {
      const msg = error.response.data.message
        ? error.response.data.message
        : error.message;
      if (msg.includes("\n")) {
        const showMessageWithNewline = () => {
          message.error({
            content: <pre>{msg}</pre>,
            duration: 5,
          });
        };
        showMessageWithNewline();
      } else {
        message.error(msg);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    if (!isExcel(file)) {
      message.error(
        "You can only upload Excel files (.xlsx, .xls, .xlsm, .xlsb, .csv)!"
      );
    }
    if (!isExcel(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isExcel(file) ? [file] : []);
    return false;
  };

  const onDrop = (e) => {
    const droppedFiles = e.dataTransfer.files;
    if (!isExcel(droppedFiles[0])) {
      message.error(
        "You can only upload Excel files (.xlsx, .xls, .xlsm, .xlsb, .csv)!"
      );
      return;
    }
    message.success("File dropped");
  };

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    props.onCancel();
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    onDrop,
    fileList,
  };

  return {
    form,
    fileList,
    propsUpload,
    uploading,
    dataTemplate,
    onCancelModal,
    handleUpload,
    isExcel,
    isLoading,
  };
};

export default ImportUpdateLogic;
