import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useProsemWithoutLogin = ({
  teacherId,
  subjectId,
  classId,
  enabled,
}) => {
  return useQuery(
    ["get-prosem-without-login", teacherId, subjectId, classId],
    () =>
      GetDetail(
        `/academics/prosem-no-login?teacherId=${teacherId}&subjectId=${subjectId}&classId=${classId}`
      ),
    { enabled }
  );
};
