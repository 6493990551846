import React from "react";

const TReportNote = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>CATATAN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Prestasi lain yang perlu dicatat</td>
          <td>: {data?.triwulanEmployee?.other_achievements_worth_noting}</td>
        </tr>
        <tr>
          <td>Indisipliner tertentu yang perlu dicatat</td>
          <td>
            :{" "}
            {
              data?.triwulanEmployee
                ?.specific_indiscipline_that_needs_to_be_noted
            }
          </td>
        </tr>
        <tr>
          <td>Saran dan perbaikan</td>
          <td>: {data?.triwulanEmployee?.suggestions_and_improvements}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TReportNote;
