import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuFoundation from "components/Auth/RequireMenuFoundation";
import LayoutReportFinance from "Layouts/LayoutFinanceReport/LayoutFinanceReport";
import MasterFinanceReport from "pages/si-finance-report/MasterFinanceReport";

export const DataRoutesFinanceReport = [
  {
    path: "finance-report",
    element: (
      <RequireAuth>
        <RequireMenuFoundation>
          <LayoutReportFinance content={<MasterFinanceReport />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
];
