import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useActivityList = (type) => {
  return useQuery(
    ["get-activity-list", type],
    () => GetList(`/activity-list?activity_type=${type ? type : ""}`),
    { enabled: false }
  );
};
