import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useTeachersDetail } from "hooks/teachers-hook/useTeachersDetail";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const DetailTeachersLogic = () => {
  const [dataId, setDataId] = useState("");
  const [showAddTeaching, setShowAddTeaching] = useState(false);
  const [showEditTeachings, setShowEditTeachings] = useState(false);
  const [showEditTeacher, setShowEditTeacher] = useState(false);
  const { teachers_id: id } = useParams();

  const {
    data: teaching,
    isLoading,
    refetch,
    isFetching,
  } = useTeachingList(id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  // get teachers detail
  const {
    data: dataDetail,
    isLoading: skeleton,
    refetch: refetchDetail,
  } = useTeachersDetail(id, true);

  // fetch teachings permission
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuTeachers");

  const onCreate = useCallback(() => {
    setShowAddTeaching(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowEditTeachings(false);
    refetch();
  }, [refetch]);

  const onUpdateTeacher = useCallback(() => {
    setShowEditTeacher(false);
    refetchDetail();
  }, [refetchDetail]);

  const onCancelTeacher = useCallback(() => {
    setShowEditTeacher(false);
    refetchDetail();
  }, [refetchDetail]);

  const onCancel = () => {
    setShowAddTeaching(false);
    setShowEditTeachings(false);
  };

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddTeachings");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditTeachings");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteTeachings");

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      fixed: "left",
    },
    {
      title: "Nama Pelajaran",
      dataIndex: "name",
      align: "left",
      width: 150,
      fixed: "left",
    },
    { title: "Kelas", dataIndex: "class", align: "left" },
    {
      title: "Aksi",
      dataIndex: "teachingId",
      align: "center",
      render: (teachingId) => {
        return (
          <>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(teachingId);
                  setShowEditTeachings(true);
                }
              }}
              disabled={btnEdit?.type === "disabled"}
            >
              Ubah
            </Tag>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = teachingId;
                DeleteApi({
                  url: `/academics/teachers/${id}/teachings/`,
                  dataId,
                  refetch,
                });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const dataSource = teaching?.data.map((x, i) => {
    return {
      ...x,
      name: x?.subject?.name,
      class: x?.class?.name,
      index: i + 1,
      teachingId: x.id,
      key: x.id,
    };
  });

  const handleGender = (value) => {
    if (value === "L") return "Laki-laki";
    if (value === "P") return "Perempuan";
  };

  const data = dataDetail?.data;

  return {
    id,
    showEditTeacher,
    setShowEditTeacher,
    onUpdateTeacher,
    onCancelTeacher,
    btnAdd,
    handleGender,
    data,
    skeleton,
    isLoading,
    columns,
    dataSource,
    showAddTeaching,
    isFetching,
    onCreate,
    onCancel,
    setShowAddTeaching,
    showEditTeachings,
    onUpdate,
    dataId,
  };
};

export default DetailTeachersLogic;
