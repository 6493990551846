import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useTeachersPerSubjectDetail = (id, enabled) => {
  return useQuery(
    ["get-show-teachers-per-subject", id],
    () => GetDetail(`/academics/teacher-attendances/${id}`),
    {
      enabled,
    }
  );
};
