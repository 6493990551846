import { Form, Input, Modal, Radio, Select, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useClassDetail } from "hooks/classes-hook/useClassDetail";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useJurusanList } from "hooks/jurusan-hook/useJurusanList";
import { useEffect, useState } from "react";

const EditClass = (props) => {
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleteon,
    refetch: fetchData,
  } = useClassDetail(props.id, false);
  const { data: dataEmployee, refetch: fetchEmployee } = useEmployeeList();

  // fetch data jurusan list
  const { data: dataJurusan, refetch: fetchJurusan } = useJurusanList();

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  useEffect(() => {
    if (props.show) {
      fetchData();
      fetchJurusan();
      fetchEmployee();
    }

    if (data) {
      form.setFieldsValue({
        name: data.data.name,
        description: data.data.description,
        employeeId: data.data.homeroomTeacher.name,
        is_graduated: data.data.is_graduated,
        kelasJurusan: data.data.kelas_jurusan,
        foundationId: data.data.foundation_id,
      });
    }

    return () => setNewData({});
  }, [form, data, props.show, fetchData, fetchEmployee, fetchJurusan]);

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      if (Object.keys(newData).length === 0) {
        alert("Nothing has changed");
        return;
      }

      setLoading(true);

      const confirmGraduation = () => {
        return new Promise((resolve) => {
          Modal.confirm({
            title:
              "Anda yakin ingin mengubah status kelas menjadi sudah lulus, semua siswa di dalam kelas ini akan menjadi lulus?",
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });
      };

      if (newData.is_graduated && !(await confirmGraduation())) {
        return;
      }

      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/academics/classes/${props.id}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );

      message.success(data.message);
      form.resetFields();
      props.onUpdate();
    } catch (error) {
      let msg = error?.response?.data?.message || "input field error";
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={props.onCancel}
      okButtonProps={{ loading }}
    >
      {skeleteon && <Skeleton active />}

      {!skeleteon && (
        <Form form={form} layout="vertical">
          {(isSuperAdmin) &&
            <Form.Item
              name="foundationId"
              label="Foundation"
            >
              <Select
                showSearch
                placeholder="Pilih foundation"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData.foundationId = e)}
              >
                {dataFoundation?.data?.data?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          <Form.Item name="name" label="Nama Kelas">
            <Input
              onChange={({ target: { value } }) => (newData["name"] = value)}
            />
          </Form.Item>
          <Form.Item name="kelasJurusan" label="Jurusan">
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(val) => (newData["kelasJurusan"] = val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataJurusan?.data?.map((jurusan) => (
                <Select.Option key={jurusan.kode} value={jurusan.kode}>
                  {jurusan.nama}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="description" label="Deskripsi">
            <Input
              onChange={({ target: { value } }) =>
                (newData["description"] = value)
              }
            />
          </Form.Item>
          <Form.Item name="employeeId" label="Wali Kelas">
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(val) => (newData["employeeId"] = val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataEmployee?.data?.map((employee) => (
                <Select.Option key={employee.id} value={employee.id}>
                  {employee.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="is_graduated" label="Status">
            <Radio.Group
              onChange={({ target: { value } }) =>
                (newData["is_graduated"] = value)
              }
            >
              <Radio value={true}>Sudah Lulus</Radio>
              <Radio value={false}>Belum Lulus</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditClass;
