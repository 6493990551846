import { Form, Input, Modal, Radio, Select, Skeleton } from "antd";
import AddUserLogic from "./AddUserLogic";

const AddUser = (props) => {
  const {
    form,
    loading,
    dataRole,
    isLoading,
    radioValue,
    dataEmployee,
    onFinish,
    onCancelModal,
    handleRadioChange,
  } = AddUserLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onFinish}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      style={{ top: 25 }}
    >
      {isLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Nama"
            name="name"
            rules={[
              { required: true, message: "Harap diisi" },
              { min: 5, message: "Masukkan minimal 5 karakter" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              mode="tags"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataRole?.data.map((role) => (
                <Select.Option key={role.name} value={role.name}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Harap diisi" },
              { type: "email", message: "Please input a valid email" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ID Karyawan"
            name="employee_id"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataEmployee?.data.map((employee) => (
                <Select.Option key={employee.name} value={employee.id}>
                  {`${employee.name} - ${employee.nip}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            hasFeedback
            rules={[
              { required: true, message: "Harap diisi" },
              { min: 6, message: "Masukkan minimal 6 karakter" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Konfirmasi Password"
            name="password_confirmation"
            hasFeedback
            rules={[
              { required: true, message: "Harap diisi" },
              { min: 6, message: "Masukkan minimal 6 karakter" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Password harus sama"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Terverifikasi"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Radio.Group
              id="verified"
              value={radioValue}
              defaultValue={true}
              onChange={handleRadioChange}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddUser;
