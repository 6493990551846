import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Navigate } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

export const GetDetail = async (url) => {
  const location = window.location.pathname;

  try {
    const { data } = await axios.get(
      REACT_APP_HOST_API + url,
      location !== "/form-jurnal-mengajar" && {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      if (
        error.response.statusText === "TOKEN" ||
        error.response.statusText === "UNVERIFIED"
      ) {
        return <Navigate to="/" />;
      } else {
        return <Navigate to="/permission-error" />;
      }
    }

    message.error(`Gagal mengambil data ( ${error.message} )`);
  }
};
