import { Button, Table } from "antd";
import ProsemDetailLogic from "./ProsemDetailLogic";
import "./prosemdetail.css";
import CreateProsemDetail from "../create/CreateProsemDetail";
import EditProsemDetail from "../edit/EditProsemDetail";

const ProsemDetail = () => {
  const {
    dataId,
    btnAdd,
    columns,
    isLoading,
    isFetching,
    dataSource,
    dataProsem,
    showEditModal,
    showCreateModal,
    onCreate,
    onCancel,
    setShowCreateModal,
  } = ProsemDetailLogic();

  return (
    <>
      <div className="table-header prosem-detail" style={{ marginBottom: 20 }}>
        {dataProsem && (
          <table className="table-prosem-header">
            <tbody>
              <tr>
                <th>Guru</th>
                <td>: {dataProsem?.data?.kelas?.teachers?.employee?.name}</td>
              </tr>
              <tr>
                <th>Mata Pelajaran</th>
                <td>: {dataProsem?.data?.kelas?.mapel?.name}</td>
              </tr>
              <tr>
                <th>Jumlah Pertemuan</th>
                <td>: {dataProsem?.data?.data?.length}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div class="table-header" style={{ float: "right" }}>
        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => setShowCreateModal(true)}
        >
          Tambah Prosem
        </Button>
      </div>

      <Table
        bordered
        size="small"
        className="bordered"
        tableLayout="auto"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={false}
        scroll={
          window.innerHeight < 760
            ? {
                y: "50vh",
                x: 1000,
              }
            : {
                y: "55vh",
                x: 800,
              }
        }
      />

      <CreateProsemDetail
        open={showCreateModal}
        onCancel={onCancel}
        onCreate={onCreate}
      />
      <EditProsemDetail
        id={dataId}
        open={showEditModal}
        onCancel={onCancel}
        onUpdate={onCreate}
      />
    </>
  );
};

export default ProsemDetail;
