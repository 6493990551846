import React from "react";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import dayjs from "dayjs";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useRecapParent } from "../../../../../hooks/finance-hook/useRecapParent";
import { Skeleton } from "antd";

export default function Mutasi({ type, fromDate }) {
  const paretnId = decryptCookies("parent_id");
  const { data, isLoading } = useRecapParent(paretnId, type, fromDate);

  return (
    <>
      <p style={{ marginTop: "20px", fontSize: "15px", fontWeight: "bold" }}>
        Daftar Riwayat Pembayaran
      </p>

      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          {data?.data?.report?.map((item) => (
            <div
              key={item?.id}
              style={{
                width: "100%",
                display: "flex",
                gap: "15px",
                padding: "10px",
                border: "0.5px solid #dddddd",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: "100px",
                  minHeight: "79px",
                  backgroundColor: "#4096FF",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                <p style={{ fontWeight: "bold", lineHeight: "1px" }}>TF</p>
                <p style={{ fontWeight: "bold", lineHeight: "1px" }}>BANK</p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td>No. Va</td>
                      <td>: {item?.account_number}</td>
                    </tr>
                    <tr>
                      <td>Tanggal Mutasi</td>
                      <td>: {dayjs(item?.date).format("DD MMM YYYY")}</td>
                    </tr>
                    <tr>
                      <td>Nominal</td>
                      <td>: {renderAmount(item?.amount)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
