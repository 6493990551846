import { Form, Modal, Radio } from "antd";
import EditRevenuesLogic from "./EditRevenuesLogic";


const EditRevenues = (props) => {
   const { form, loading, handleSubmit, newData } =
      EditRevenuesLogic(props);

   return (
      <Modal
         okText="Simpan"
         cancelText="Batal"
         onOk={handleSubmit}
         open={props.show}
         onCancel={() => {
            props.onCancel();
            form.resetFields();
         }}
         okButtonProps={{ loading }}
         title="Edit Penerimaan"
      >
         <Form form={form} layout="vertical">
            <Form.Item
               name="status"
               label="Status"
            >
               <Radio.Group buttonStyle="solid" onChange={({ target: { value } }) => (newData["status"] = value)}>
                  <Radio.Button value="new">Baru</Radio.Button>
                  <Radio.Button value="exported">
                     Sudah diexport
                  </Radio.Button>
               </Radio.Group>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default EditRevenues;
