import React from "react";
import { Modal, Form, Select, InputNumber, message } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { decryptCookies } from "components/Helper/CookiesHelper";

export default function AddDetailAccount({ open, setOpen }) {
  const [form] = Form.useForm();

  const { id } = useParams();

  const { REACT_APP_HOST_API } = process.env;
  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      await axios.post(
        REACT_APP_HOST_API + "/finance/account-references",
        {
          ...values,
          account_id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success("Berhasil menambahkan data!");
      setOpen();
      form.resetFields();
    } catch (error) {
      console.log("error message:", error);
      if (
        error?.response?.data?.data[0]?.message === "unique validation failure"
      ) {
        message.error("Tipe sudah ada!");
      }
      message.error("Gagal menambahkan data!");
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        setOpen();
        form.resetFields();
      }}
      onOk={onSubmit}
      closable={false}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Tipe" name="type" rules={[{ required: true }]}>
          <Select>
            <Select.Option value="spp">SPP</Select.Option>
            <Select.Option value="bp">BP</Select.Option>
            <Select.Option value="bwt">BWT</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Nominal Acuan"
          name="amount"
          rules={[{ required: true }]}
        >
          <InputNumber
            formatter={(value) =>
              `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
