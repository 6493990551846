import React, { useEffect, useState } from "react";
import { Table, Tag, Space, Popconfirm, Button } from "antd";
import { usePermission } from "hooks/usePermissions";
import { DeleteApi } from "api/DeleteApi";
import EditDetailAccount from "./EditDetailAccount";
import AddDetailAccount from "./AddDetailAccount";
import { useParams } from "react-router-dom";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import { useDetailAccount } from "hooks/finance-hook/useDetailAccount";

const disabled = { color: "#ccc", cursor: "not-allowed" };

export default function DetailAccount() {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [dataItem, setDataItem] = useState({});
  const [dataDetail, setDataDetail] = useState({});

  const { data: dataPermission } = usePermission("mdlFinance", "mnuAccount");

  const btnEdit = dataPermission?.find((x) => x.id === "btnEditDetail");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteDetail");
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddDetail");

  const { id: account_id } = useParams();

  const { data, isLoading, refetch, isFetching } = useDetailAccount(
    account_id,
    true
  );

  useEffect(() => {
    setDataDetail(data?.data);
    // eslint-disable-next-line
  }, [data]);

  const handleOpenEdit = () => {
    setShowEdit(!showEdit);
    refetch();
  };

  const handleOpenAdd = () => {
    setShowAdd(!showAdd);
    refetch();
  };

  const columns = [
    {
      title: "Tipe",
      dataIndex: "tipe",
    },
    {
      title: "Nominal Acuan",
      dataIndex: "amount",
    },
    {
      title: "Aksi",
      render: (id, record) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = record?.key;
                DeleteApi({
                  url: "/finance/account-references/",
                  dataId,
                  refetch,
                });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataItem(record);
                  handleOpenEdit();
                }
              }}
              disabled={btnEdit?.type === "disabled"}
            >
              Ubah
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataSource = dataDetail?.accountReferences?.map((item) => {
    return {
      key: item?.id,
      tipe: item?.type,
      amount: renderAmount(item?.amount),
    };
  });

  return (
    <>
      <div className="table-header">
        <h1>Detail Account</h1>
      </div>

      <table className="detail-teachers__table">
        <tbody>
          <tr>
            <th>Nama</th>
            <td>: {dataDetail?.student?.name}</td>
          </tr>
          <tr>
            <th>NISN</th>
            <td>: {dataDetail?.student?.nisn}</td>
          </tr>
          <tr>
            <th>No. Rekening</th>
            <td>: {dataDetail?.number}</td>
          </tr>
          <tr>
            <th>Saldo</th>
            <td>: {renderAmount(dataDetail?.balance)}</td>
          </tr>
        </tbody>
      </table>

      <div className="table-header">
        <h1>Acuan Tagihan</h1>

        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd?.type === "disabled"}
          onClick={() => {
            if (btnAdd?.type === "disabled") {
              return;
            } else {
              handleOpenAdd();
            }
          }}
        >
          Tambah
        </Button>
      </div>

      <Table
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={dataSource}
      />
      <EditDetailAccount
        dataItem={dataItem}
        open={showEdit}
        setOpen={handleOpenEdit}
      />
      <AddDetailAccount open={showAdd} setOpen={handleOpenAdd} />
    </>
  );
}
