import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useUnitDetailPagination = (
  id,
  enabled,
  keyword = "",
  dataTable
) => {
  return useQuery(
    [
      "get-units-detail-pagination",
      id,
      keyword,
      dataTable.current_page,
      dataTable.per_page,
    ],
    () =>
      GetDetail(
        `/units/${id}?keyword=${keyword}&page=${dataTable.current_page}&limit=${dataTable.per_page}`
      ),
    {
      enabled,
    }
  );
};
