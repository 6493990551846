import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useUniversityPagination = ({ dataTable }) => {
  return useQuery(
    [
      "get-institution-test-pagination",
      dataTable.current_page,
      dataTable.per_page,
    ],
    () =>
      GetPagination(
        `/academics/institutions?page=${dataTable.current_page}&limit=${dataTable.per_page}`
      )
  );
};
