import {
  DatePicker,
  Form,
  Input,
  Modal,
  message,
  Select,
} from "antd";
import { GetList } from "api/GetList";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { MonthlyReportContext } from "context/MonthlyReportContext";
import dayjs from "dayjs";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import { useContext, useEffect, useState } from "react";

const format = "YYYY-MM-DD";

const CreateMReport = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { showCreate, setShowCreate, setFetchReport, unitId, setUnitId } =
    useContext(MonthlyReportContext);
  const { data: dataUnit } = useUnitList();

  const { REACT_APP_HOST_API } = process.env;

  const roles = decryptCookies("role").split(",");

  const isAdminHRD = async () => {
    const { data: unitList } = await GetList(`/unit-lists-lead-only`);

    setUnitId(unitList[0]?.id);
  };

  useEffect(() => {
    if (showCreate) {
      if (roles.includes("admin_hrd")) {
        isAdminHRD();
      }
    }
    // eslint-disable-next-line
  }, [showCreate]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    if (!unitId) {
      alert("gagal, anda bukan kepala unit");
      return;
    }

    // update workingDays to string
    values.workingDays = values.workingDays.map((date) => dayjs(date).format(format));

    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/monthly-reports",
        {
          name: values.name,
          fromDate: values.fromDate.format(format),
          toDate: values.toDate.format(format),
          // redDates: values.redDates,
          unitId: unitId,
          workingDays: values.workingDays.length,
          workingDates: values.workingDays,
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      setFetchReport(true);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    setShowCreate(false);
    form.resetFields();
  };


  return (
    <Modal
      open={showCreate}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      centered
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Periode"
          tooltip="Contoh: juni 2023"
          rules={[{ required: true, min: 3 }]}
        >
          <Input placeholder="Contoh: juni 2023" />
        </Form.Item>
        <Form.Item
          name="fromDate"
          label="Tanggal Awal"
          rules={[{ required: true }]}
        >
          <DatePicker format={format} />
        </Form.Item>
        <Form.Item
          name="toDate"
          label="Tanggal Akhir"
          rules={[{ required: true }]}
        >
          <DatePicker format={format} />
        </Form.Item>
        {(roles.includes("super_admin") || roles.includes('admin_foundation')) && (
          <Form.Item
            label="Unit"
            name="unitId"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => setUnitId(value)}
            >
              {dataUnit?.data.map((unit) => (
                <Select.Option key={unit?.id} value={unit?.id}>
                  {unit?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="workingDays"
          label="Jumlah hari kerja Dalam satu periode"
          rules={[{ required: true }]}
        >
          <DatePicker
            multiple
            maxTagCount="responsive"
          />
        </Form.Item>
        {/* <Form.Item
          name="redDates"
          label="Tanggal merah dalam satu periode"
          requiredMark="optional"
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default CreateMReport;
