import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useProvinceList = () => {
  return useQuery(
    ["get-province-list"],
    () => GetList("/wilayah?t=pro&p=&k="),
    { enabled: false }
  );
};
