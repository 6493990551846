import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useTeachingList = (id) => {
  return useQuery(
    ["get-teachings", id],
    () => GetDetail(`/academics/teachers/${id}/teachings`),
    { enabled: false }
  );
};
