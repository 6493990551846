import { Button, Form, Skeleton, message } from "antd";
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import PendaftaranSetting from "./PendaftaranSetting";
import GelombangSetting from "./GelombangSetting";
import { usePermission } from "hooks/usePermissions";

const Setting = () => {
  const { REACT_APP_HOST_API } = process.env;
  const [richVal, setRichVal] = useState("");
  const [form] = Form.useForm();
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const getData = async () => {
    setIsLoad(true);
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/settings/guide`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      await form.setFieldsValue({
        guide: data.data[0].guide_content.desc,
      });

      setContent(form.getFieldValue("guide"));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleSubmitGuide = async () => {
    setIsLoading(true);
    const datas = {
      guide_content: {
        desc: richVal,
      },
    };

    try {
      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/ppdb/settings/guide`,
        datas,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  // fetch Batch permission
  const { data: dataPermission } = usePermission("mdlPpdb", "mnuSetting");

  // Buttons Permission
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditGuide");

  return (
    <div>
      <div className="table-header">
        <h1>panduan pendaftaran</h1>
        <Button
          hidden={!btnEdit}
          disabled={btnEdit && btnEdit.type === "disabled"}
          loading={isLoading}
          type="primary"
          onClick={handleSubmitGuide}
        >
          Simpan Panduan
        </Button>
      </div>
      {/* <div className="form-container"> */}
      {/* <div className="form-wrapper"> */}
      {isLoad ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item label="" name="guide">
            <CKEditor
              editor={ClassicEditor}
              onChange={(e, get) => setRichVal(get.getData())}
              data={content}
              disabled={isLoading}
            />
          </Form.Item>
        </Form>
      )}

      <br />

      {/* BATCH SETTING--- */}

      <GelombangSetting />

      {/* pendaftaran------ */}
      <PendaftaranSetting />
    </div>
    // </div>
    // </div>
  );
};

export default Setting;
