import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useCategoryList } from "hooks/link-hook/category/useCategoryList";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import CreateCategory from "../create/CreateCategory";
import EditCategory from "../edit/EditCategory";
import FilterFoundation from "components/UI/FilterFoundation";
import { decryptCookies } from "components/Helper/CookiesHelper";

const disabled = { color: "#ccc", cursor: "not-allowed" };
export default function Categories() {
  const [keyword, setKeyword] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataItem, setDataItem] = useState({});
  const [foundationId, setFoundationId] = useState("");

  const roles = decryptCookies("role").split(",");

  //fetch category list
  const { data, refetch, isFetching } = useCategoryList(keyword, foundationId);

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuCategories");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddCategories");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditCategories");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteCategories");

  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
    refetch();
  };

  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
    refetch();
  };

  const columns = [
    {
      title: "No. ",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Kategori",
      dataIndex: "name",
      key: "name",
    },
    ...(roles.includes("super_admin")
      ? [
          {
            title: "Foundation",
            dataIndex: "foundation_name",
            key: "foundation_name",
          },
        ]
      : []),
    {
      title: "Aksi",
      dataIndex: "id",
      render: (id, record) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/links/categories/", dataId, refetch });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataItem(record);
                  handleOpenEdit();
                }
              }}
              disabled={btnEdit?.type === "disabled"}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = data?.data
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .map((x, index) => {
      return {
        ...x,
        no: index + 1,
        id: x.id,
        key: x.id,
        name: x.name,
        foundation_name: x?.foundation?.name,
      };
    });

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Kategori</h1>

        <Button
          type="primary"
          disabled={btnAdd?.type === "disabled"}
          hidden={!btnAdd}
          onClick={() => (btnAdd?.type === "disabled" ? null : handleOpenAdd())}
        >
          Tambah
        </Button>
      </div>

      <div className="filter-search">
        {roles.some((x) => x === "super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="nisn/nama/nomor VA">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="cari Kategori..."
            className="search-input-billings"
          />
        </Tooltip>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        // pagination={false}
        loading={refetch || isFetching}
      />

      <CreateCategory open={openAdd} setOpen={handleOpenAdd} />
      <EditCategory open={openEdit} setOpen={handleOpenEdit} data={dataItem} />
    </div>
  );
}
