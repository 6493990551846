import {
   Button,
   Form,
   Input,
   InputNumber,
   Select,
   Table,
} from "antd";
import AddAccountsBatchLogic from "./AddAccountBatchLogic";
import styles from "./AddAccountBatch.module.css";

const AddAccountsBatch = () => {
   const {
      form,
      columns,
      isLoading,
      handleSearch,
      handleSubmit,
      searchQuery,
      posting,
      dataSource,
      rowSelection,
      setAccountType,
      pagination,
      setIsNew
   } = AddAccountsBatchLogic();

   return (
      <div>
         <div className="table-header">
            <h1>Form Tambah Accounts</h1>
         </div>
         <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className={styles.filterList}>
               <Form.Item
                  name="amount"
                  label="Nominal Acuan(optional)"
               >
                  <InputNumber
                     formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                     parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
                     style={{ width: "100%" }}
                  />
               </Form.Item>
               <Form.Item
                  name="type"
                  label="Tipe Rekening"
               >
                  <Select
                     showSearch
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                     }
                     onChange={(value) => {
                        setAccountType(value);
                     }}
                  >
                     <Select.Option value="spp">SPP</Select.Option>
                     <Select.Option value="bp">BP</Select.Option>
                  </Select>
               </Form.Item>
            </div>
            <div className="search-wrapper filter-wrapper">
               <Form.Item label="Nama" style={{ width: "100%", marginBottom: 0 }}>
                  <Input
                     value={searchQuery}
                     onChange={handleSearch}
                     placeholder=" cari nama siswa"
                     disabled={posting}
                  />
               </Form.Item>
               <Form.Item label="Status siswa" style={{ width: "100%", marginBottom: 0 }}>
                  <Select onChange={
                     (value) => {
                        setIsNew(value);
                     }
                  }>
                     <Select.Option value="">All siswa</Select.Option>
                     <Select.Option value={true}>Siswa Baru</Select.Option>
                     <Select.Option value={false}>Siswa Lama</Select.Option>
                  </Select>
               </Form.Item>
            </div>
            <Table
               style={{ marginBottom: 20 }}
               size={window.innerWidth > 1600 ? "middle" : "small"}
               pagination={pagination}
               tableLayout="auto"
               dataSource={dataSource}
               columns={columns}
               loading={isLoading}
               rowSelection={rowSelection}
               scroll={
                  window.innerHeight < 690
                     ? {
                        y: "40vh",
                        x: 800,
                     }
                     : window.innerHeight < 760
                        ? {
                           y: "50vh",
                           x: 800,
                        }
                        : {
                           y: "55vh",
                           x: 800,
                        }
               }
            />
            <div className="button-container">
               <Button
                  type="primary"
                  htmlType="submit"
                  loading={posting}
               >
                  Submit
               </Button>
            </div>
         </Form>
      </div>
   );
};

export default AddAccountsBatch;
