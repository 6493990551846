import { Divider, Form, Modal, Select, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { useEffect, useState } from "react";

const EditStatus = ({ open, setOpen, candidateId }) => {
  const [form] = Form.useForm();
  const [isLoad, setIsLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  // eslint-disable-next-line
  const [newData, setNewData] = useState({});

  const { refetch: fetchCandidate } = useStudentCandidate(candidateId);

  const getData = async () => {
    setIsLoad(true);
    const { isSuccess, data: datas } = await fetchCandidate();
    try {
      if (isSuccess) {
        // const data = await datas.data;
        form.setFieldsValue({
          current_status: datas?.data[0]?.status,
          major_final: datas?.data[0]?.major_final,
          program_final: datas?.data[0]?.program_final,
          spp_final: datas?.data[0]?.spp_final,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSubmit = async () => {
    if (Object.keys(newData).length === 0) {
      message.info("Tidak ada data yang diubah!");
      return;
    }

    await form.validateFields();
    setIsLoading(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${candidateId}`,
        newData,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Berhasil menyimpan data");
      form.resetFields();
      setOpen();
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancle = () => {
    form.resetFields();
    setOpen();
  };
  return (
    <Modal
      open={open}
      onCancel={handleCancle}
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      confirmLoading={isLoading}
      onOk={handleSubmit}
    >
      {isLoad ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item label="Status Sekarng" name="current_status">
            <Select
              disabled={true}
              options={[
                {
                  value: "new",
                  label: "Baru",
                },
                {
                  value: "done_primary_data",
                  label: "Daftar Awal",
                },
                {
                  value: "done_selection",
                  label: "Sudah Memilih",
                },
                {
                  value: "pass_exam",
                  label: "Lulus Ujian",
                },
                {
                  value: "pass_re_register",
                  label: "Diverifikasi",
                },
                {
                  value: "failed_exam",
                  label: "Gagal Ujian",
                },
                {
                  value: "failed_re_register",
                  label: "Gagal Verifikasi",
                },
                {
                  value: "waiting_reregister_verification",
                  label: "Menunggu Verifikasi",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label="Perbarui Status" name="status">
            <Select
              options={[
                {
                  value: "pass_exam",
                  label: "Lulus Ujian",
                },
                {
                  value: "pass_re_register",
                  label: "Diverifikasi",
                },
                {
                  value: "failed_exam",
                  label: "Gagal Ujian",
                },
                {
                  value: "failed_re_register",
                  label: "Gagal Verifikasi",
                },
              ]}
              onChange={(val) => {
                newData.status = val;
              }}
            />
          </Form.Item>

          <Divider />

          <Form.Item label="Program Final" name="program_final">
            <Select
              options={[
                {
                  value: "mahad",
                  label: "Mahad",
                },
                {
                  value: "boarding",
                  label: "Boarding",
                },
                {
                  value: "fullday",
                  label: "Fullday",
                },
                {
                  value: "wisma",
                  label: "Wisma",
                },
              ]}
              onChange={(val) => {
                newData.program_final = val;
              }}
            />
          </Form.Item>

          <Form.Item label="Jurusan Final" name="major_final">
            <Select
              options={[
                {
                  value: "mipa",
                  label: "Mipa",
                },
                {
                  value: "ips",
                  label: "Ips",
                },
                {
                  value: "bahasa",
                  label: "Bahasa",
                },
              ]}
              onChange={(val) => {
                newData.major_final = val;
              }}
            />
          </Form.Item>

          <Form.Item label="SPP Final" name="spp_final">
            <Select
              options={[
                {
                  value: "2_juta",
                  label: "2 Juta",
                },
                {
                  value: "1_5_juta",
                  label: "1.5 Juta",
                },
                {
                  value: "1_3_juta",
                  label: "1.3 Juta",
                },
                {
                  value: "1_1_juta",
                  label: "1.1 Juta",
                },
              ]}
              onChange={(val) => {
                newData.spp_final = val;
              }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditStatus;
