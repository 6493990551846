import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useBukuNilai = (
  aspekPenilaian,
  teacherId,
  classId,
  subjectId,
  type,
  keyword,

  enabled
) => {
  return useQuery(
    [
      "get-buku-nilai",
      aspekPenilaian,
      teacherId,
      classId,
      subjectId,
      type,
      keyword,
    ],
    () =>
      GetDetail(
        `/academics/buku-nilai?aspekPenilaian=${aspekPenilaian}&teacherId=${teacherId}&classId=${classId}&subjectId=${subjectId}&type=${type}&keyword=${keyword}`
      ),
    { enabled }
  );
};
