import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

const DataAyahTab = () => {
  // const [load, setLoad] = useState(false);
  const { student_id } = useParams();
  const { data, isLoading, refetch } = useParentList(student_id, true);

  let dataAyah;

  const filteredData = data?.data?.parents?.filter(
    (obj) => obj.relationship_w_student === "biological father"
  );
  dataAyah = filteredData;

  useEffect(() => {
    refetch();
  }, [refetch]);

  // console.log({ dataAyah });

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data?.data && (
        <Fragment key={data?.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Nama Ayah</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].name}
                </td>
              </tr>
              <tr>
                <th>NIK</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].nik}{" "}
                </td>
              </tr>
              <tr>
                <th>Tahun Lahir</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].birth_date}
                </td>
              </tr>
              <tr>
                <th>Alamat</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].address}
                </td>
              </tr>
              <tr>
                <th>Jenjang Pendidikan</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].education}
                </td>
              </tr>
              <tr>
                <th>Pekerjaan</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].occupation}
                </td>
              </tr>
              <tr>
                <th>Penghasilan Minimum</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].min_salary}
                </td>
              </tr>
              <tr>
                <th>Penghasilan Maximum</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].max_salary}
                </td>
              </tr>

              <tr>
                <th>Nomor Telpon</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].phone_number}
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {dataAyah === undefined || dataAyah.length === 0
                    ? ""
                    : dataAyah[0].email}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataAyahTab;
