import { Button, Form, Input, Typography } from "antd";
import axios from "axios";
import { encryptCookies } from "components/Helper/CookiesHelper";
import DynamicTitle from "components/UI/DynamicTitle";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
const { Link } = Typography;

const Login = ({ loginBtn }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  const onLogin = async () => {
    const values = await form.validateFields();

    setIsLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/auth/login",
        values
      );

      const { token } = data.token;
      const { role_name, email, name, employee, employee_id, parent_id } =
        data.data;

      if (!data.data.verified) {
        alert("Email belum terverifikasi!");
      } else {
        const roleArr = role_name.split(",");
        if (employee) {
          if (employee.teacher)
            encryptCookies("teacher_id", employee.teacher.id);
        }

        if (roleArr.includes("parent") && parent_id) {
          encryptCookies("parent_id", parent_id);
        }

        if (!parent_id && roleArr.includes("parent")) {
          alert("parent_id tidak ditemukan, silahkan hubungi admin");
          return;
        }

        encryptCookies("token", token);
        encryptCookies("login", "true");
        encryptCookies("email", email);
        encryptCookies("role", role_name);
        encryptCookies("name", name);
        encryptCookies("employeeId", employee_id);
        encryptCookies("select_button_scan", "true");

        navigate("/home", { replace: true });
      }
    } catch (error) {
      let msg =
        error.response.data.message || error.message || "Terjadi kesalahan";
      alert(msg);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallbackResponse = useCallback(
    async (response) => {
      setIsLoading(true);
      try {
        const { data } = await axios.post(REACT_APP_HOST_API + "/auth/google", {
          cred: response.credential,
        });

        if (data.message === "Anda belum memiliki akun") {
          alert("Anda belum memiliki akun");

          encryptCookies("dataReg", response.credential);
          loginBtn();
          return;
        }

        if (!data.data.verified) {
          alert("Email belum terverifikasi!");
          return;
        }

        if (data?.data?.employee?.teacher) {
          encryptCookies("teacher_id", data.data.employee.teacher.id);
        }

        encryptCookies("token", data.token.token);
        encryptCookies("login", "true");
        encryptCookies("email", data.data.email);
        encryptCookies("role", data.data.role_name);
        encryptCookies("name", data.data.name);
        encryptCookies("employeeId", data?.data?.employee_id);
        encryptCookies("select_button_scan", "true");
        navigate("/home", { replace: true });
      } catch (error) {
        alert(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [REACT_APP_HOST_API, navigate, loginBtn]
  );

  const divRef = useRef(null);
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    // render google button
    if (divRef.current) {
      setIsFetch(true);
      window.google?.accounts?.id?.initialize({
        client_id:
          "377109222318-c9fhr2v1e7160helt36cso273dajbbr5.apps.googleusercontent.com",
        // 228017211154-8rqo834ljj5frf2q3rcrfqb2c3j567ft.apps.googleusercontent.com
        callback: handleCallbackResponse,
      });

      window.google?.accounts?.id?.renderButton(divRef.current, {
        theme: "outline",
        size: "large",
      });
    }
    // eslint-disable-next-line
  }, [handleCallbackResponse, divRef.current, isFetch]);

  return (
    <div className="login__wrapper">
      <DynamicTitle />
      <div className="left__content">
        <div className="content__wrapper" style={{ width: "80%" }}>
          <h3 className="content__wrapper-title">New Here?</h3>
          <p className="content__wrapper-description">
            Sign Up and discover our System. <br />
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
            cupiditate placeat, amet sapiente dolore animi iure sed eos
            doloremque. Accusamus.
          </p>

          <Button block onClick={loginBtn}>
            Register
          </Button>
        </div>
      </div>
      <div className="right__content">
        <div className="content__wrapper">
          <h3 className="content__wrapper-title">Login</h3>
          <div className="content__wrapper-form">
            <Form form={form} layout="vertical">
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "please enter a valid email" },
                ]}
                className="border-only-bottom"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "please enter password" }]}
                className="border-only-bottom"
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Link
                  style={{ float: "right" }}
                  onClick={() => navigate("/forgot-password")}
                >
                  Lupa Password?
                </Link>

                <br />
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  onClick={onLogin}
                  loading={isLoading}
                >
                  Login
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => navigate("/scan-presence")}
                >
                  Scan Presensi
                </Button>
              </Form.Item>
            </Form>
            <div
              id="signInDiv"
              ref={divRef}
              style={{ display: "flex", justifyContent: "center" }}
            />
            <div className="register">
              <p>
                Belum punya akun?
                <span onClick={loginBtn}> Register</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
