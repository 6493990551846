// /academics/daily-attendances?page=1&limit=10&mode=page&fromDate=2023-06-05&toDate=2023-06-12&recap=true
import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useRecapDaily = (
  dataTable,
  recap,
  dateIn,
  dateOut,
  keyName,
  classId,
  foundationId,
  enabled
) => {
  return useQuery(
    [
      "get-daily-attandce",
      dataTable.current_page,
      dataTable.per_page,
      recap,
      dateIn,
      dateOut,
      classId,
      foundationId,
      keyName,
    ],
    () =>
      // GetDetail(
      //   `/academics/daily-attendances?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&fromDate=${dateIn}&toDate=${dateOut}&recap=true`
      // ),
      GetDetail(
        `/academics/daily-attendances?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&fromDate=${dateIn}&toDate=${dateOut}&recap=${recap}&keyword=${keyName}&classId=${classId}&foundationId=${foundationId}`
      ),
    {
      enabled,
    }
  );
};
