import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

const DataIbuTab = () => {
  const { candidate_id } = useParams();
  const { data, isLoading, refetch } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  let dataIbu;

  const filteredData = dataInti.parents?.filter(
    (obj) => obj.relationship_w_student === "biological mother"
  );
  dataIbu = filteredData;

  useEffect(() => {
    refetch();
  }, [refetch]);

  // console.log({ dataIbu });

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Nama Ibu</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].name}
                </td>
              </tr>
              <tr>
                <th>NIK</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].nik}{" "}
                </td>
              </tr>
              <tr>
                <th>Tahun Lahir</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].birth_date}
                </td>
              </tr>
              <tr>
                <th>Alamat</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].address}
                </td>
              </tr>
              <tr>
                <th>Jenjang Pendidikan</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].education}
                </td>
              </tr>
              <tr>
                <th>Pekerjaan</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].occupation}
                </td>
              </tr>
              <tr>
                <th>Penghasilan Minimum</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].min_salary}
                </td>
              </tr>
              <tr>
                <th>Penghasilan Maximum</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].max_salary}
                </td>
              </tr>

              <tr>
                <th>Nomor Telpon</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].phone_number}
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {dataIbu === undefined || dataIbu.length === 0
                    ? ""
                    : filteredData[0].email}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataIbuTab;
