import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAcademicYear = () => {
  return useQuery(
    "get-tahun-ajaran-list",
    () =>
      GetList("/ppdb/academic-years?page=1&limit=50&keyword=&isActive=true"),
    {
      enabled: false,
    }
  );
};
