import { DatePicker, Form, Input, Modal, Radio, Skeleton } from "antd";
import EditRecapLogic from "./EditRecapLogic";
import dayjs from "dayjs";
import "dayjs/locale/id"; // Import the Indonesian locale

dayjs.locale("id");

const EditRecap = (props) => {
  const { form, newData, loading, skeleteon, handleSubmit } =
    EditRecapLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={props.onCancel}
      okButtonProps={{ loading }}
    >
      {skeleteon && <Skeleton active />}

      {!skeleteon && (
        <Form form={form} layout="vertical">
          <Form.Item name="status" label="Status">
            <Radio.Group
              onChange={({ target: { value } }) => (newData["status"] = value)}
            >
              <Radio value="present">Hadir</Radio>
              <Radio value="permission">Izin</Radio>
              <Radio value="sick">Sakit</Radio>
              <Radio value="absent">Tanpa keterangan</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="description" label="Deskripsi">
            <Input
              onChange={({ target: { value } }) =>
                (newData["description"] = value)
              }
            />
          </Form.Item>
          <Form.Item name="date_in" label="Waktu Masuk">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(value) =>
                (newData["date_in"] = dayjs(value).format(
                  "YYYY-MM-DD HH:mm:ss"
                ))
              }
            />
          </Form.Item>
          <Form.Item name="date_out" label="Waktu Keluar">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(value) =>
                (newData["date_out"] = dayjs(value).format(
                  "YYYY-MM-DD HH:mm:ss"
                ))
              }
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditRecap;
