import { Button, Form, Popconfirm, Skeleton, Space, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import TabIdentitasSiswa from "./Tab/TabIdentitasSiswa";
import TabAlamatSiswa from "./Tab/TabAlamatSiswa";
import TabKontakSiswa from "./Tab/TabKontakSiswa";
import TabDataAkademik from "./Tab/TabDataAkademik";
import TabRekening from "./Tab/TabRekening";
import TabDataLainnya from "./Tab/TabDataLainnya";
import TabDataAyah from "./Tab/TabDataAyah";
import TabDataIbu from "./Tab/TabDataIbu";
import TabDataWali from "./Tab/TabDataWali";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { decryptCookies } from "components/Helper/CookiesHelper";
import axios from "axios";
import TabDokumen from "./Tab/TabDokumen";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import { usePermission } from "hooks/usePermissions";

//*NOTE : HALAMAN INI UNTUK DAFTAR ULANG ATAUPUN UNTU EDIT ---

const EditDaftarUlang = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { candidate_id } = useParams();
  const [newData, setNewData] = useState({});
  const [newDataParent, setnewDataParent] = useState({});
  const [filteredFather, setFilteredFather] = useState([]);
  const [filteredMother, setFilteredMother] = useState([]);
  const [filteredGuardian, setFilteredGuardian] = useState([]);

  const [isFetch, setIsFetch] = useState(false);

  // for Upload ---
  const [fileListKtpAyah, setFileListKtpAyah] = useState([]);
  const [fileListKtpIbu, setFileListKtpIbu] = useState([]);
  // end for upload ---

  // const { REACT_APP_DEV } = process.env;

  const [isLoadDetail, setIsLoadDetial] = useState(false);
  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  // fetch student detail
  const { data: datas, refetch: fetchStudentCandidateDetail } =
    useStudentCandidate(candidate_id, true);

  let dataInti = datas?.data[0];

  let kdWilayah = {
    prov: dataInti?.prov,
    kot: dataInti?.kot,
    kec: dataInti?.kec,
  };

  // * Fetching data ---
  const getData = async () => {
    setIsLoadDetial(true);
    const { isSuccess, data } = await fetchStudentCandidateDetail();

    // blok kode ini untuk filter parents sesuai dengan relationship, jika data ada maka hasilnya
    // adalah array of object
    const dataFather = await data?.data[0]?.parents?.filter(
      (obj) => obj.relationship_w_student === "biological father"
    );
    const dataMother = await data?.data[0]?.parents?.filter(
      (obj) => obj.relationship_w_student === "biological mother"
    );
    const dataGuardian = await data?.data[0]?.parents?.filter(
      (obj) => obj.relationship_w_student === "guardian"
    );

    const detailData = data?.data[0];

    setFilteredFather(dataFather);
    setFilteredMother(dataMother);
    setFilteredGuardian(dataGuardian);

    setIsFetch(true); // isFetch hanya untuk trigger agar data di fetch 2x dan dapat diambil

    // for dokumen ---
    if (isSuccess) {
      if (filteredFather.length !== 0 && filteredFather[0].ktp_scan !== null) {
        setFileListKtpAyah([
          {
            isFirst: true,
            name: "KTP Ayah",
            thumbUrl: filteredFather[0].ktp_scan,
          },
        ]);
      }

      if (filteredMother.length !== 0 && filteredMother[0].ktp_scan !== null) {
        setFileListKtpIbu([
          {
            isFirst: true,
            name: "KTP Ibu",
            thumbUrl: filteredMother[0].ktp_scan,
          },
        ]);
      }
    }

    // fetching data ---
    await form.setFieldsValue({
      //* identitas siswa ---

      full_name: detailData?.full_name,
      nik: detailData?.nik,
      // nis: detailData?.nis,
      nisn: detailData?.nisn,
      gender: detailData?.gender,
      birth_city: detailData?.birth_city,
      birth_day: detailData?.birth_day && dayjs(moment(detailData?.birth_day)),
      religion: detailData?.religion,

      //* alamat siswa ---

      address: detailData?.address,
      rt: detailData?.rt,
      rw: detailData?.rw,
      prov: detailData?.prov && detailData?.prov,
      kot: detailData?.kot && detailData?.kot,
      kec: detailData?.kec && detailData?.kec,
      kel: detailData?.kel && detailData?.kel,
      zip: detailData?.zip,
      residence: detailData?.residence,

      //* kontak siswa ---

      phone: detailData?.phone,
      mobile_phone: detailData?.mobile_phone,
      correspondence_email: detailData?.correspondence_email,

      //* data akademik siswa ---

      nat_exam_no: detailData?.nat_exam_no,
      junior_hs_cert_no: detailData?.junior_hs_cert_no,
      junior_hs_name: detailData?.junior_hs_name,
      birth_cert_no: detailData?.birth_cert_no,
      has_kps:
        detailData?.has_kps === null
          ? ""
          : detailData?.has_kps
          ? "true"
          : "false",
      kps_number: detailData?.kps_number,
      has_kip:
        detailData?.has_kip === null
          ? ""
          : detailData?.has_kip
          ? "true"
          : "false",
      kip_number: detailData?.kip_number,
      name_on_kip:
        detailData?.name_on_kip === null
          ? ""
          : detailData?.name_on_kip
          ? "true"
          : "false",
      has_kks:
        detailData?.has_kks === null
          ? ""
          : detailData?.has_kks
          ? "true"
          : "false",
      kks_number: detailData?.kks_number,
      pip_eligible:
        detailData?.pip_eligible === null
          ? ""
          : detailData?.pip_eligible
          ? "true"
          : "false",
      pip_desc: detailData?.pip_desc,
      unit: detailData?.unit,
      program: detailData?.program,

      //* data rekening ---

      bank_name: detailData?.bank_name,
      bank_account_owner: detailData?.bank_account_owner,
      bank_account_number: detailData?.bank_account_number,

      //* data siswa lainnya ---

      special_needs: detailData?.special_needs,
      child_no: detailData?.child_no,
      siblings: detailData?.siblings,
      family_card_no: detailData?.family_card_no,
      weight: detailData?.weight,
      height: detailData?.height,
      head_circumference: detailData?.head_circumference,
      address_lat: detailData?.address_lat,
      address_long: detailData?.address_long,
      distance_to_school_in_km: detailData?.distance_to_school_in_km,
      transportation: detailData?.transportation,

      //* data ayah ---

      father0_0name:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].name
          : null,
      father0_0nik:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].nik
          : null,
      father0_0birth_date:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].birth_date &&
            dayjs(moment(filteredFather[0].birth_date))
          : null,
      father0_0education:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].education
          : null,
      father0_0occupation:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].occupation
          : null,
      father0_0min_salary:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].min_salary
          : null,
      father0_0max_salary:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].max_salary
          : null,
      father0_0address:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].address
          : null,
      father0_0phone_number:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].phone_number
          : null,
      father0_0email:
        detailData?.parents?.length !== 0 && filteredFather.length !== 0
          ? filteredFather[0].email
          : null,

      //* data ibu ---

      mother0_0name:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].name
          : null,
      mother0_0nik:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].nik
          : null,
      mother0_0birth_date:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].birth_date &&
            dayjs(moment(filteredMother[0].birth_date))
          : null,
      mother0_0education:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].education
          : null,
      mother0_0occupation:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].occupation
          : null,
      mother0_0min_salary:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].min_salary
          : null,
      mother0_0max_salary:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].max_salary
          : null,
      mother0_0address:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].address
          : null,
      mother0_0phone_number:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].phone_number
          : null,
      mother0_0email:
        detailData?.parents?.length !== 0 && filteredMother.length !== 0
          ? filteredMother[0].email
          : null,

      //* data wali ---

      guardian0_0name:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].name
          : null,
      guardian0_0nik:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].nik
          : null,
      guardian0_0birth_date:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].birth_date &&
            dayjs(moment(filteredGuardian[0].birth_date))
          : null,
      guardian0_0education:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].education
          : null,
      guardian0_0occupation:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].occupation
          : null,
      guardian0_0min_salary:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].min_salary
          : null,
      guardian0_0max_salary:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].max_salary
          : null,
      guardian0_0address:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].address
          : null,
      guardian0_0phone_number:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].phone_number
          : null,
      guardian0_0email:
        detailData?.parents?.length !== 0 && filteredGuardian.length !== 0
          ? filteredGuardian[0].email
          : null,
    });

    setIsLoadDetial(false);
  };

  useEffect(() => {
    getData();
    setNewData({});
    setnewDataParent({});

    // **agar eslint dikecualikan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetch]);

  // * Submit data ---
  const handleSubmit = async () => {
    await form.validateFields();

    try {
      if (fileListKtpAyah.length === 0 || fileListKtpIbu.length === 0) {
        alert("KTP tidak boleh kosong");
        return;
      }

      if (
        Object.keys(newData).length === 0 &&
        Object.keys(newDataParent).length === 0
      ) {
        alert("Nothing has changed");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }

      for (const key in newDataParent) {
        if (Object.hasOwnProperty.call(newDataParent, key)) {
          const element = newDataParent[key];
          if (element === "") newDataParent[key] = null;
        }
      }

      const newVal = {
        ...newData,
        birth_day: newData.birth_day && newData.birth_day.format("YYYY-MM-DD"),
      };

      setLoading(true);
      try {
        if (Object.keys(newData).length !== 0) {
          const { data } = await axios.put(
            REACT_APP_HOST_API + `/ppdb/student-candidates/${candidate_id}`,
            newVal,
            {
              headers: { Authorization: "Bearer " + decryptCookies("token") },
            }
          );
          message.success(data.message);
        }

        // ! NOTE: BLOK KODE SUBMIT PARRENT
        // ! cek apakah parent sudah memiliki id, jika belum maka jalankan POST

        // ** UPDATE PARENT & CEK APAKAH PARENT SUDAH MEMILIKI ID
        const objStudent = {
          ...newDataParent,
          father0_0birth_date:
            newDataParent.father0_0birth_date &&
            newDataParent.father0_0birth_date.format("YYYY-MM-DD"),
          mother0_0birth_date:
            newDataParent.mother0_0birth_date &&
            newDataParent.mother0_0birth_date.format("YYYY-MM-DD"),
          guardian0_0birth_date:
            newDataParent.guardian0_0birth_date &&
            newDataParent.guardian0_0birth_date.format("YYYY-MM-DD"),
        };
        const guardian = {
          relationship_w_student: "guardian",
        };

        const father = {
          relationship_w_student: "biological father",
        };
        const mother = {
          relationship_w_student: "biological mother",
        };

        for (let key in objStudent) {
          const [identifier, kunci] = key.split("0_0");
          if (identifier === "guardian") {
            guardian[kunci] = objStudent[key];
          } else if (identifier === "father") {
            father[kunci] = objStudent[key];
          } else if (identifier === "mother") {
            mother[kunci] = objStudent[key];
          }
        }

        // edit ayah
        const validAyah = [
          father.name,
          father.nik,
          father.birth_date,
          father.education,
          father.occupation,
          father.min_salary,
          father.max_salary,
          father.address,
          father.phone_number,
          father.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidFather = validAyah.some(
          (value) => value !== undefined && value !== ""
        );
        if (Object.keys(newDataParent).length !== 0 && isValidFather) {
          //* jika father  memiliki id maka edit
          if (dataInti.parents.length !== 0 && filteredFather.length !== 0) {
            // eslint-disable-next-line
            const { data: dataFather } = await axios.put(
              REACT_APP_HOST_API + `/ppdb/parents/${filteredFather[0].id}`,
              father,
              {
                headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
                },
              }
            );

            message.success("data ayah diubah");
          }

          //* jika father tidak memiliki id maka add
          else {
            try {
              const { data: dataFather } = await axios.post(
                REACT_APP_HOST_API +
                  `/ppdb/student-candidates/${candidate_id}/parents`,
                father,
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                  },
                }
              );

              // file KTP Ayah ---
              await axios.put(
                `${REACT_APP_HOST_API}/ppdb/parents/${dataFather.data.id}/file-upload`,
                { file: fileListKtpAyah[0], category: "ktp" },
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              message.success("data ayah berhasil ditambah");
            } catch (error) {
              message.warning(
                "data ayah gagal ditambah, periksa apakah nik dan nama sudah terisi dengan benar"
              );
            }
          }
        }

        // edit ibu
        const validIbu = [
          mother.name,
          mother.nik,
          mother.birth_date,
          mother.education,
          mother.occupation,
          mother.min_salary,
          mother.max_salary,
          mother.address,
          mother.phone_number,
          mother.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidMother = validIbu.some(
          (value) => value !== undefined && value !== ""
        );

        if (Object.keys(newDataParent).length !== 0 && isValidMother) {
          //* jika mother  memiliki id maka edit
          if (dataInti.parents.length !== 0 && filteredMother.length !== 0) {
            // eslint-disable-next-line
            const { data: dataMother } = await axios.put(
              REACT_APP_HOST_API + `/ppdb/parents/${filteredMother[0].id}`,
              mother,
              {
                headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
                },
              }
            );

            message.success("data ibu diubah");
          } else {
            try {
              const { data: dataMother } = await axios.post(
                REACT_APP_HOST_API +
                  `/ppdb/student-candidates/${candidate_id}/parents`,
                mother,
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                  },
                }
              );

              // file KTP Ibu ---
              await axios.put(
                `${REACT_APP_HOST_API}/ppdb/parents/${dataMother.data.id}/file-upload`,
                { file: fileListKtpIbu[0], category: "ktp" },
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              message.success("data ibu berhasil ditambah");
            } catch (error) {
              message.warning(
                "data ibu gagal ditambah, periksa apakah nik dan nama sudah terisi dengan benar"
              );
            }
          }
        }

        // edit wali
        const validWali = [
          guardian.name,
          guardian.nik,
          guardian.birth_date,
          guardian.education,
          guardian.occupation,
          guardian.min_salary,
          guardian.max_salary,
          guardian.address,
          guardian.phone_number,
          guardian.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidGuardian = validWali.some(
          (value) => value !== undefined && value !== ""
        );

        if (Object.keys(newDataParent).length !== 0 && isValidGuardian) {
          //* jika guardian  memiliki id maka edit
          if (dataInti.parents.length !== 0 && filteredGuardian.length !== 0) {
            // eslint-disable-next-line
            const { data: dataGuardian } = await axios.put(
              REACT_APP_HOST_API + `/ppdb/parents/${filteredGuardian[0].id}`,
              guardian,
              {
                headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
                },
              }
            );
            message.success("data wali diubah");
          } else {
            try {
              // eslint-disable-next-line
              const { data: dataGuardian } = await axios.post(
                REACT_APP_HOST_API +
                  `/ppdb/student-candidates/${candidate_id}/parents`,
                guardian,
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                  },
                }
              );
              message.success("data wali berhasil ditambah");
            } catch (error) {
              message.warning(
                "data wali gagal ditambah, periksa apakah nik dan nama sudah terisi dengan benar"
              );
            }
          }
        }
        // form.resetFields();
        navigate("/ppdb/pendaftaran");
      } catch (error) {
        alert(error.message);
      }
      setLoading(false);
    } catch (error) {
      alert("Fields Error");
    }
  };

  const selectTap = async (key) => {
    try {
      await form.validateFields();
      setActiveKey(key.toString());
    } catch (error) {
      alert("Input yang wajib diisi belum terpenuhi");
    }
  };

  // ---
  const handleNext = async () => {
    try {
      await form.validateFields();
      selectTap(parseInt(activeKey) + 1);
    } catch (error) {
      alert("Input yang wajib diisi belum terpenuhi");
    }
  };

  // * Item Tab ---
  const items = [
    {
      key: "1",
      label: `Identitas`,
      children: <TabIdentitasSiswa newData={newData} />,
    },
    {
      key: "2",
      label: `Alamat`,
      children: (
        <TabAlamatSiswa newData={newData} kdWilayah={kdWilayah} form={form} />
      ),
    },
    {
      key: "3",
      label: `Kontak`,
      children: <TabKontakSiswa newData={newData} />,
    },
    {
      key: "4",
      label: `Data akademik`,
      children: <TabDataAkademik newData={newData} />,
    },
    {
      key: "5",
      label: `Data Rekening`,
      children: <TabRekening newData={newData} />,
    },
    {
      key: "6",
      label: `Data lainnya`,
      children: <TabDataLainnya newData={newData} />,
    },
    {
      key: "7",
      label: `Data ayah`,
      children: (
        <TabDataAyah
          newDataParent={newDataParent}
          fileAyah={fileListKtpAyah}
          setFileAyah={setFileListKtpAyah}
          filteredFather={filteredFather}
        />
      ),
    },
    {
      key: "8",
      label: `Data ibu`,
      children: (
        <TabDataIbu
          newDataParent={newDataParent}
          fileIbu={fileListKtpIbu}
          setFileIbu={setFileListKtpIbu}
          filteredMother={filteredMother}
        />
      ),
    },
    {
      key: "9",
      label: `Data wali`,
      children: <TabDataWali newDataParent={newDataParent} />,
    },
    {
      key: "10",
      label: `Dokumen & Pembayaran`,
      children: <TabDokumen dataFetching={datas} />,
    },
  ];

  // fetch Batch permission
  const { data: dataPermission } = usePermission("mdlPpdb", "mnuPendaftaran");

  // Buttons Permission
  const btnEditDaftarUlang = dataPermission?.find(
    (x) => x.id === "btnEditDaftarUlangPageCandidate"
  );
  return (
    <div>
      <div className="table-header">
        <h1>FORM DAFTAR ULANG</h1>
        {/* navigasi */}
        <Space>
          <Button
            hidden={activeKey === "1"}
            onClick={() => selectTap(parseInt(activeKey) - 1)}
          >
            Sebelumnya
          </Button>

          <Button
            type="primary"
            onClick={handleNext}
            hidden={activeKey === "10"}
          >
            Selanjutnya
          </Button>

          <Popconfirm
            key="3"
            okText="Simpan"
            cancelText="Batal"
            title="Anda yakin ingin menyimpannya ?"
            onConfirm={handleSubmit}
            disabled={
              btnEditDaftarUlang && btnEditDaftarUlang.type === "disabled"
            }
          >
            <Button
              loading={loading}
              type="primary"
              disabled={
                btnEditDaftarUlang && btnEditDaftarUlang.type === "disabled"
              }
              hidden={activeKey !== "10" || !btnEditDaftarUlang}
            >
              Simpan
            </Button>
          </Popconfirm>
        </Space>
      </div>

      {isLoadDetail ? (
        <Skeleton active />
      ) : (
        <>
          <Form form={form} layout="vertical">
            <Tabs
              onTabClick={(a) => selectTap(a)}
              defaultActiveKey="1"
              items={items}
              activeKey={activeKey}
            />
          </Form>
        </>
      )}
    </div>
  );
};

export default EditDaftarUlang;
