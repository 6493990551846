import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useSessionList } from "hooks/attandance-hook/useSession";
import { useTeachersPerSubjectDetail } from "hooks/attandance-hook/useTeachersPerSubjectDetail";
import { useClassList } from "hooks/classes-hook/useClassList";
import { usePelajaranList } from "hooks/pelajaran-hook/usePelajaranList";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";

const EditTeachersLogic = ({ id, show, onUpdate }) => {
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const { data: dataClass, refetch } = useClassList(false);
  const { data: dataPelajararan, refetch: fetchPelajaran } = usePelajaranList();
  const { data: dataGuru, refetch: fetchGuru } = useTeachersList();
  const { data: dataSession, refetch: fetchSession } = useSessionList();
  const [statusTeach, setStatusTeach] = useState("");
  const [isGuruLogin, setIsGuruLogin] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleteon,
    refetch: fetchData,
  } = useTeachersPerSubjectDetail(id, false);

  useEffect(() => {
    if (show) {
      Cookies.get("teacher_id") ? setIsGuruLogin(true) : setIsGuruLogin(false);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      fetchData();
      refetch();
      fetchPelajaran();
      fetchGuru();
      fetchSession();
    }

    if (data) {
      form.setFieldsValue({
        reason_not_teach: data.data.data.reason_not_teach,
        status: data.data.data.status,
        description: data.data.data.description,
        date_in: data.data.data.date_in
          ? dayjs(moment(data.data.data.date_in).format("YYYY-MM-DD HH:mm:ss"))
          : null,
        date_out: data.data.data.date_out
          ? dayjs(moment(data.data.data.date_out).format("YYYY-MM-DD HH:mm:ss"))
          : null,
        class_id: data.data.data.class_id,
        pelajaran_id: data.data.data.pelajaran_id,
        teacher_id: data.data.data.teacher_id,
        session_id: data.data.data.session_id,
        subject_id: data.data.data.subject_id,
        material: data.data.data.material,
      });
      setStatusTeach(data.data.status);
    }

    return () => setNewData({});
  }, [
    form,
    data,
    show,
    fetchData,
    refetch,
    fetchPelajaran,
    fetchGuru,
    fetchSession,
  ]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        if (Object.keys(newData).length === 0) {
          alert("Nothing has changed");
          return;
        }

        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + `/academics/teacher-attendances/${id}`,
            newData,
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          message.success(data.message);
          form.resetFields();
          onUpdate();
        } catch (error) {
          alert(error.message);
        }

        setLoading(false);
      })
      .catch(() => alert("Fields Error"));
  };

  return {
    form,
    newData,
    loading,
    skeleteon,
    handleSubmit,
    dataClass,
    dataPelajararan,
    dataGuru,
    dataSession,
    // --
    isGuruLogin,
    statusTeach,
    setStatusTeach,
  };
};

export default EditTeachersLogic;
