import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuInventory from "components/Auth/RequireMenuInventory";
import LayoutInventory from "Layouts/LayoutInventory/LayoutInventory";
import DetailAssets from "pages/si-inventory/assets/detail/DetailAssets";
import { lazy } from "react";

// lazy route inventory
const MasterInventory = lazy(() =>
  import("pages/si-inventory/MasterInventory")
);
const Manufacturers = lazy(() =>
  import("pages/si-inventory/manufacturers/Manufacturers")
);
const Assets = lazy(() => import("pages/si-inventory/assets/master/Assets"));
const AssetsStatus = lazy(() =>
  import("pages/si-inventory/assets/asset-status/AssetsStatus")
);
const LoanBatch = lazy(() => import("pages/si-inventory/loan-batch/LoanBatch"));
const LoanBatchDetail = lazy(() =>
  import("pages/si-inventory/loan-batch/LoanBatchDetail")
);
const AssetLoan = lazy(() =>
  import("pages/si-inventory/asset-loan/master/AssetLoan")
);

export const DataRoutesInventory = [
  {
    path: "inventory",
    element: (
      <RequireAuth>
        <RequireMenuInventory>
          <LayoutInventory content={<MasterInventory />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/manufacturers",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuManufacturers">
          <LayoutInventory content={<Manufacturers />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assets",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssets">
          <LayoutInventory content={<Assets />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assets/detail-:id",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssets">
          <LayoutInventory content={<DetailAssets />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assets/:statusId",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssets">
          <LayoutInventory content={<AssetsStatus />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/loanbatch",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuLoanBatch">
          <LayoutInventory content={<LoanBatch />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/loanbatch/:batch_id",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuLoanBatch">
          <LayoutInventory content={<LoanBatchDetail />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assetloan",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssetLoan">
          <LayoutInventory content={<AssetLoan />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
];
