import React, { useState } from "react";
import "./Raport.css";
import {
  Button,
  Popconfirm,
  Space,
  Table,
  Tag,
  message,
  Input,
  Select,
  Form,
} from "antd";
import { useNavigate } from "react-router-dom";
import GenerateRaport from "./GenerateRaport";
import { useGenerateRaportList } from "hooks/student-raport-hook/useGenerateRaportList";
import { useEffect } from "react";
import dayjs from "dayjs";
import EditRaport from "../edit/EditRaport";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { SearchOutlined } from "@ant-design/icons";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

export default function Raport() {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [operData, setOperData] = useState({});
  const [keyword, setKeyword] = useState("");
  const [year, setYear] = useState("");
  const [semester, setSemester] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const navigate = useNavigate();

  const disabled = { color: "#ccc", cursor: "not-allowed" };

  // * get generate raport list ---
  const { data, refetch, isLoading, isFetching } = useGenerateRaportList(
    keyword,
    year,
    semester,
    foundationId
  );

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuRaportStudent"
  );

  // Buttons Permission
  const btnGenerate = dataPermission?.find((x) => x.id === "btnGenerateRaport");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteGenerate");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditGenerate");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailGenerate");
  const btnHitungUlang = dataPermission?.find(
    (x) => x.id === "btnHitungUlangGenerate"
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSetOpen = () => {
    setOpen(false);
    refetch();
  };
  const handleSetOpenEdit = (data) => {
    setOpenEdit(!openEdit);
    setOperData(data);
    refetch();
  };

  const handleHitungUlang = async (data) => {
    const sendData = {
      name: data?.name,
      fromDate: data?.from_date,
      toDate: data?.to_date,
      semesterId: data?.semester_id,
      academicYearId: data?.academic_year_id,
      classId: data?.class_id,
      hitungUlang: true,
      studentRaports: data?.studentRaports,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_HOST_API}/academics/raports/${data?.id}/hitung-ulang`,
        sendData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success("Berhasil menghitung ulang raport");
      refetch();
    } catch (error) {
      alert("Terjadi kesalahan!");
      console.log(error);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      width: 50,
    },
    // {
    //   title: "Nama",
    //   dataIndex: "name",
    //   width: 250,
    // },
    {
      title: "Kelas",
      dataIndex: "class",
      width: 100,
    },
    {
      title: "Periode",
      width: 250,
      render: (datas, items) => (
        <>
          {dayjs(items.from_date).format("DD MMMM YYYY")} -{" "}
          {dayjs(items.to_date).format("DD MMMM YYYY")}
        </>
      ),
    },
    {
      title: "Tahun Ajaran",
      dataIndex: "tahun",
      width: 100,
    },
    {
      title: "Semester",
      dataIndex: "semester",
      width: 100,
    },
    {
      title: "Jurusan",
      dataIndex: "jurusan",
      width: 100,
    },
    {
      title: "Aksi",
      dataIndex: "key",
      width: 300,
      render: (id, data) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus ?"
            disabled={btnDelete && btnDelete.type === "disabled"}
            onConfirm={() => {
              DeleteApi({ url: "/academics/raports/", dataId: id, refetch });
            }}
          >
            <Tag
              color="red"
              hidden={!btnDelete}
              style={
                btnDelete?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
            >
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="green"
            hidden={!btnHitungUlang}
            style={
              btnHitungUlang?.type === "disabled"
                ? disabled
                : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnHitungUlang.type === "disabled") {
                return;
              } else {
                handleHitungUlang(data);
              }
            }}
          >
            Hitung Ulang
          </Tag>
          <Tag
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            hidden={!btnEdit}
            onClick={() => {
              if (btnEdit.type === "disabled") {
                return;
              } else {
                handleSetOpenEdit(data);
              }
            }}
            color="orange"
          >
            Edit
          </Tag>

          <Tag
            color="blue"
            style={
              btnDetail?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            hidden={!btnDetail}
            onClick={() => {
              if (btnDetail?.type === "disabled") return;
              navigate(id);
            }}
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ];

  const dataSource = data?.data.map((item, index) => ({
    ...item,
    key: item.id,
    no: index + 1,
    class: item?.class?.name,
    tahun: item?.academicYear?.year,
    jurusan: item?.class?.jurusan?.nama,
    semester: item?.semester?.semester_name,
  }));

  return (
    <>
      <div className="table-header">
        <h1>Daftar Raport</h1>

        <Button
          type="primary"
          onClick={() => setOpen(true)}
          disabled={btnGenerate && btnGenerate.type === "disabled"}
          hidden={!btnGenerate}
        >
          Generate Raport
        </Button>
      </div>

      <Form layout="vertical">
        <div className="search-wrapper filter-wrapper">
          <Form.Item label="Nama" hidden>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Cari berdasarkan nama ..."
              onChange={(e) => setKeyword(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Tahun Ajaran">
            <Input
              onChange={(e) => setYear(e.target.value)}
              placeholder="contoh: 2023"
            />
          </Form.Item>
          {isSuperAdmin &&
            <Form.Item
              name="foundationId"
              label="Yayasan"
            >
              <Select
                style={{ minWidth: 150 }}
                onChange={(x) => {
                  setFoundationId(x)
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">ALL</Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          <Form.Item label="Semester">
            <Select
              onChange={(e) => setSemester(e)}
              placeholder="Pilih semester..."
              style={{ minWidth: 150 }}
            >
              <Select.Option value="">Semua Semester</Select.Option>
              <Select.Option value="ganjil">Ganjil</Select.Option>
              <Select.Option value="genap">Genap</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </Form>

      <div>
        <Table
          loading={isLoading || isFetching}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ y: 400 }}
        />
      </div>

      <GenerateRaport open={open} setOpen={handleSetOpen} />
      <EditRaport open={openEdit} setOpen={handleSetOpenEdit} data={operData} />
    </>
  );
}
