import React, { useEffect, useState } from "react";
import welcomeIcon from "../../../assets/img/welcome-ppdb.png";
import { Skeleton, Steps, Tag } from "antd";
import {
  DollarCircleOutlined,
  SolutionOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { decryptCookies } from "components/Helper/CookiesHelper";
import axios from "axios";
import moment from "moment";

const StudentCome = ({ datas }) => {
  const { REACT_APP_HOST_API } = process.env;
  const [dataStudentDetail, setDataStudentDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getStudentDetail = async () => {
    if (datas?.studentCandidate?.id) {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${REACT_APP_HOST_API}/ppdb/student-candidates/${datas.studentCandidate.id}`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );
        setDataStudentDetail(data?.data[0]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const items = [
    {
      title: "Pendaftaran",
      status:
        datas?.studentCandidate === null
          ? "wait"
          : datas?.studentCandidate?.status === "done_primary_data"
          ? "finish"
          : "finish",
      icon: <SolutionOutlined />,
    },
    {
      title: "Ujian & Interview",
      status:
        datas?.studentCandidate === null
          ? "wait"
          : datas?.studentCandidate?.status === "done_primary_data"
          ? "wait"
          : datas?.studentCandidate?.status === "pass_exam"
          ? "finish"
          : datas?.studentCandidate?.status === "failed_exam"
          ? "error"
          : "finish",
      icon: <TeamOutlined />,
      description:
        dataStudentDetail.entranceExamSchedule === undefined ? null : moment(
            dataStudentDetail.entranceExamSchedule?.time_start
          ).format("DD-MMM-YYYY") ===
          moment(dataStudentDetail.entranceExamSchedule?.time_end).format(
            "DD-MMM-YYYY"
          ) ? (
          <Tag color="#108ee9">{`${moment(
            dataStudentDetail.entranceExamSchedule?.time_start
          ).format("DD-MMM-YYYY | HH:mm")} - ${moment(
            dataStudentDetail.entranceExamSchedule?.time_end
          ).format("HH:mm")}`}</Tag>
        ) : (
          <Tag color="#108ee9">{`${moment(
            dataStudentDetail.entranceExamSchedule?.time_start
          ).format("DD-MMM-YYYY | HH:mm")} - ${moment(
            dataStudentDetail.entranceExamSchedule?.time_end
          ).format("DD-MMM-YYYY | HH:mm")}`}</Tag>
        ),
    },
    {
      title: "Daftar Ulang & Pembayaran",
      status:
        datas?.studentCandidate === null
          ? "wait"
          : datas?.studentCandidate?.status === "done_primary_data"
          ? "wait"
          : datas?.studentCandidate?.status === "pass_exam"
          ? "wait"
          : datas?.studentCandidate?.status === "failed_re_register"
          ? "error"
          : datas?.studentCandidate?.status === "failed_exam"
          ? "wait"
          : datas?.studentCandidate?.status ===
            "waiting_reregister_verification"
          ? "wait"
          : "finish",
      icon: <DollarCircleOutlined />,
    },
  ];

  // guide---
  const [guide, setGuide] = useState([]);
  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/settings/guide`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      setGuide(data.data[0].guide_content);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  // console.log(datas);

  useEffect(() => {
    getStudentDetail();
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="welcome-ppdb">
        <img src={welcomeIcon} alt="" />
        <h1>SELAMAT DATANG DI SISTEM PPDB SMA FG</h1>
      </div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {/* progress */}
          <div className="progress-status-wrapper">
            <div className="progress-status">
              <Steps size="small" items={items} />
            </div>
          </div>

          {/* guide */}

          {datas?.studentCandidate?.status !== "new" &&
          datas?.studentCandidate !== null ? (
            <div
              className="guide-view"
              dangerouslySetInnerHTML={{ __html: guide?.desc }}
            ></div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default StudentCome;
