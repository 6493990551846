import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useClassList } from "hooks/classes-hook/useClassList";
import { usePelajaranList } from "hooks/pelajaran-hook/usePelajaranList";
import { useTeachingsDetail } from "hooks/teachers-hook/useTeachingsDetail";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EditTeachingsLogic = ({ id, show, onUpdate }) => {
  const { teachers_id } = useParams();
  const [loading, setLoading] = useState(false);

  const [isEkskul, setIsEkskul] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleteon,
    refetch: fetchData,
  } = useTeachingsDetail(id, false, teachers_id);

  // fetch class list
  const { data: dataClass, refetch: fetchClass } = useClassList(false);

  // fetch pelajaran list
  const { data: dataPelajaran, refetch: fetchPelajaran } = usePelajaranList();

  useEffect(() => {
    if (show) {
      fetchData();
      fetchClass();
      fetchPelajaran();
    }

    if (data) {
      form.setFieldsValue({
        classId: data.data.class_id,
        subjectId: data.data.subject_id,
      });

      setIsEkskul(data.data.subject.is_extracurricular);
    }
  }, [form, data, show, fetchData, fetchClass, fetchPelajaran]);

  const checkEkskul = async () => {
    try {
      const newData = await form.validateFields();

      if (!dataPelajaran || !dataPelajaran.data) {
        return;
      }

      const isEkskul = dataPelajaran.data
        .filter((item) => item.id === newData.subjectId)
        .some((item) => item.is_extracurricular);

      setIsEkskul(isEkskul);

      if (isEkskul) {
        form.setFieldsValue({ classId: null });
      }
    } catch (error) {
      console.error("Error during form validation:", error);
    }
  };

  const handleSubmit = async () => {
    const newData = await form.validateFields();

    setLoading(true);

    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API +
          `/academics/teachers/${teachers_id}/teachings/${id}`,
        newData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      form.resetFields();
      onUpdate();
    } catch (error) {
      console.log("error", error);

      let errorMessage = error.message;
      if (error?.response?.data?.data[0].rule === "unique") {
        errorMessage = "Data sudah ada";
      }
      message.error(errorMessage);
      message.error("Gagal mengubah data");
    } finally {
      setLoading(false);
    }
  };

  return {
    checkEkskul,
    form,
    dataClass,
    loading,
    skeleteon,
    handleSubmit,
    dataPelajaran,
    isEkskul,
  };
};

export default EditTeachingsLogic;
