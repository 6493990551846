import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useNotificationsPagination = (dataTable) => {
  return useQuery(
    [
      "get-notifications-pagination",
      dataTable.current_page,
      dataTable.per_page,
    ],
    () =>
      GetPagination(
        `/notifications?page=${dataTable.current_page}&limit=${dataTable.per_page}`
      )
  );
};
