import { decryptCookies } from "components/Helper/CookiesHelper";
import { useBukuNilai } from "hooks/bukunilai-hook/useBukuNilai";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Checkbox, InputNumber, Popover, Radio, Space, message } from "antd";
import dayjs from "dayjs";
import axios from "axios";

const BukuNilaiLogic = () => {
  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("");
  const [teacherId, setTeacherId] = useState(
    Cookies.get("teacher_id") ? decryptCookies("teacher_id") : ""
  );
  const [aspekPenilaian, setAspekPenilaian] = useState("PENGETAHUAN");
  const [isOpenGenerate, setIsOpenGenerate] = useState(false);
  const [isEkskul, setIsEkskul] = useState(false);
  const [valItem, setValItem] = useState();
  const roles = decryptCookies("role").split(",");
  const [fromDateData, setFromDateData] = useState();
  const [toDateData, setToDateData] = useState();
  const [isExport, setIsExport] = useState(false);

  const navigate = useNavigate();

  const { data: dataPermission } = usePermission("mdlAcademic", "mnuBukuNilai");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAdd");
  // const btnEdit = dataPermission?.find((x) => x.id === "btnEdit");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDelete");
  // const btnDetail = dataPermission?.find((x) => x.id === "btnDetail");

  // *get list teacher ---
  const { data: dataGuru, refetch: fetchGuru } = useTeachersList();

  //  ** get teacher teaching ---
  const {
    data: dataMengajar,
    refetch: fetchMengajar,
    isLoading: isLoadingMengajar,
  } = useTeachingList(
    Cookies.get("teacher_id") ? decryptCookies("teacher_id") : teacherId
  );

  useEffect(() => {
    if (roles.find((x) => x === "teacher")) {
      fetchMengajar();
    }

    // eslint-disable-next-line
  }, [fetchMengajar]);

  useEffect(() => {
    if (
      roles.find(
        (x) => x === "super_admin" || x === "admin" || x === "admin_academic"
      )
    ) {
      fetchGuru();
      if (teacherId) fetchMengajar();
    }
    // eslint-disable-next-line
  }, [fetchGuru, fetchMengajar, teacherId]);

  const { REACT_APP_HOST_API } = process.env;

  let inputNilai;
  const handleEdit = async (value) => {
    if (!inputNilai) return;
    try {
      const sendData = isEkskul
        ? { nilaiEkskul: inputNilai }
        : aspekPenilaian !== "SIKAP" && !isEkskul
        ? { nilai: inputNilai }
        : aspekPenilaian === "SIKAP" && !isEkskul
        ? { nilaiSikap: inputNilai }
        : {};

      await axios.put(
        REACT_APP_HOST_API + `/academics/buku-nilai/${value?.id}`,
        sendData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      if (
        aspekPenilaian !== "PENGETAHUAN" &&
        aspekPenilaian !== "KETERAMPILAN"
      ) {
        message.success("Berhasil mengubah nilai");
      }
    } catch (error) {
      console.log(error);
      alert("Terjadi Kesalahan");
    } finally {
    }
  };

  const handleUpdateData = async () => {
    try {
      await axios.post(
        `${REACT_APP_HOST_API}/academics/generate-uts`,
        {
          aspekPenilaian,
          classId,
          subjectId,
          teacherId,
          fromDate: dayjs(fromDateData).format("YYYY-MM-DD"),
          toDate: dayjs(toDateData).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      await refetch();

      setTimeout(() => {
        refetch();
        message.success("Berhasil update data");
      }, 400);
    } catch (error) {
      alert("Gagal update data");
      console.log(error);
    }
  };
  const [record, setRecord] = useState([]);

  const handleSelectRow = (checked, index, value) => {
    setRecord((prevRecord) => {
      const existingRecordIndex = prevRecord.findIndex(
        (item) => item.key === index
      );

      // If the item with the same key exists and it should be unchecked, remove it
      if (existingRecordIndex !== -1 && !checked) {
        const updatedRecord = [...prevRecord];
        updatedRecord.splice(existingRecordIndex, 1);
        return updatedRecord;
      }

      // If the item doesn't exist or it should be checked, add or update it
      return [
        ...prevRecord.filter((item) => item.key !== index), // Remove existing item with the same key
        {
          key: index,
          checked: checked,
          data: value,
          nilai: value?.nilai,
        },
      ];
    });
  };

  //* ---- DATA TABEL ----------------------------------------------------------
  const [columns, setColumns] = useState([]);

  // ** GET Buku nilai list ---
  const {
    data,
    isLoading: isLoadBukuNilai,
    isFetching,
    refetch,
  } = useBukuNilai(
    aspekPenilaian,
    teacherId,
    classId,
    subjectId,
    type,
    keyword,
    false
  );

  useEffect(() => {
    if (isEkskul && teacherId && subjectId) {
      refetch();
      setRecord([]);
    } else if (
      !isEkskul &&
      teacherId &&
      subjectId &&
      aspekPenilaian &&
      classId
    ) {
      refetch();
      setRecord([]);
    }
  }, [teacherId, subjectId, refetch, aspekPenilaian, type, classId, isEkskul]);

  useEffect(() => {
    setRecord([]);
  }, [isFetching]);

  const dataResBab = data?.data?.bab?.map((x, i) => {
    return {
      key: i,
      ...x,
    };
  });

  const dataVal = data?.data?.bab?.map((x, i) => {
    const resType = x.type.map((y, i) => {
      return {
        key: i,
        nilai: y.nilai,
      };
    });
    return {
      key: i,
      ...x,
      tipeNilai: resType,
    };
  });

  const dataKey = data?.data?.students?.map((x, index) => {
    return {
      ...x,
      index: index + 1,
      key: x.studentId,
      class: x?.class,
      studentName: x.name,
      bab: dataResBab,
      dataVal,
    };
  });

  useEffect(() => {
    setColumns([]);
    if (data?.data?.bab) {
      setFromDateData(data?.data?.data?.fromDate);
      setToDateData(data?.data?.data?.toDate);

      // comment : buat kolom dinamis untuk bab ---
      const dataBab = data?.data?.bab?.map((babs, i) => {
        // comment : buat kolom tipe dinamis dan kolom nilai ---
        const type = babs.type.map((babVal, index) => {
          return {
            key: `${babs}-${index}type`,
            dataIndex: babs,
            width: "5%",
            // align: !isEkskul ? "center" : "left",
            title: (
              <Popover
                placement="topLeft"
                // title={"Materi : " + babVal.materi}
                content={
                  <div>
                    <p style={{ margin: 0, padding: 0 }}>
                      <b>Materi : </b> {babVal?.materi}
                    </p>
                    <p style={{ margin: 0, padding: 0 }}>
                      <b>Materi prosem : </b> {babVal?.materi_prosem}
                    </p>
                    <p style={{ margin: 0, padding: 0 }}>
                      <b>Tanggal pengambilan nilai : </b>
                      {dayjs(babVal?.tanggal_pengambilan_nilai).format(
                        "DD MMM YYYY"
                      )}
                    </p>
                  </div>
                }
              >
                <div style={{ cursor: "default" }}>
                  <Space>
                    {!isExport && (
                      <Checkbox
                        style={{ display: !btnDelete ? "none" : null }}
                        onClick={(e) =>
                          handleSelectRow(e.target.checked, index, babVal)
                        }
                        disabled={btnDelete && btnDelete.type === "disabled"}
                      />
                    )}
                    {babVal?.name}
                  </Space>
                </div>
              </Popover>
            ),
            render: (text, record) => {
              const nilai = babVal.nilai.find(
                (nilaiItem) => nilaiItem.studentId === record.id
              );

              const nilaiValue = nilai ? nilai.value : 0;

              return (
                <div
                  key={nilai?.id}
                  style={{
                    cursor: `${
                      babVal.name === "UTS" || babVal.name === "UAS"
                        ? "not-allowed"
                        : "pointer"
                    }`,
                  }}
                >
                  {isExport ? (
                    `${nilaiValue}`
                  ) : isEkskul ? (
                    <Radio.Group
                      defaultValue={nilaiValue}
                      size="small"
                      buttonStyle="solid"
                      onChange={(e) => {
                        //eslint-disable-next-line
                        inputNilai = e.target.value;
                        setValItem(nilai);
                        handleEdit(nilai);
                      }}
                    >
                      <Radio.Button value="A">A</Radio.Button>
                      <Radio.Button value="B">B</Radio.Button>
                      <Radio.Button value="C">C</Radio.Button>
                    </Radio.Group>
                  ) : aspekPenilaian !== "SIKAP" && !isEkskul ? (
                    <InputNumber
                      size="small"
                      defaultValue={nilaiValue}
                      style={{ width: "80%" }}
                      min={0}
                      max={100}
                      disabled={babVal.name === "UTS"}
                      onChange={(value) => {
                        //eslint-disable-next-line
                        inputNilai = value;
                      }}
                      onBlur={() => {
                        setValItem(nilai);
                        handleEdit(nilai);
                      }}
                      onPressEnter={() => {
                        setValItem(nilai);
                        handleEdit(nilai);
                      }}
                    />
                  ) : aspekPenilaian === "SIKAP" && !isEkskul ? (
                    <Radio.Group
                      size="small"
                      defaultValue={nilaiValue}
                      onChange={(e) => {
                        //eslint-disable-next-line
                        inputNilai = e.target.value;
                        setValItem(nilai);
                        handleEdit(nilai);
                      }}
                    >
                      <Radio.Button value="B">B</Radio.Button>
                      <Radio.Button value="SB">SB</Radio.Button>
                    </Radio.Group>
                  ) : null}
                </div>
              );
            },
          };
        });

        // comment : buat kolom parent bab dinamis  ---
        return {
          key: `${babs}-${i}`,
          title: `${
            babs.kompetensi_dasar_index !== "penilaian lainnya"
              ? `BAB ${babs.kompetensi_dasar_index} -`
              : "PENILAIAN LAINNYA"
          }  ${babs?.kompetensi_dasar.toUpperCase()}`,

          dataIndex: babs,
          // align: "center",
          // width: 50,
          children: type,
        };
      });

      const classEkskul = {
        title: "Kelas",
        dataIndex: "class",
        key: "class",
        width: "3%",
      };

      const columns = [
        {
          title: "NO.",
          dataIndex: "index",
          key: "index",
          width: "3%",
        },
        ...(isEkskul ? [classEkskul] : []),

        {
          title: "NAMA SISWA",
          dataIndex: "studentName",
          key: "studentName",
          width: isEkskul ? "5%" : "15%",
        },
        ...dataBab,
      ];

      setColumns(columns);
    }
  }, [
    data,
    teacherId,
    classId,
    subjectId,
    isEkskul,
    valItem,
    refetch,
    isExport,
  ]);

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Daftar buku nilai",
    sheet: "buku nilai",
  });

  // Fungsi untuk memulai unduhan
  const handleDownload = async () => {
    setIsExport(true);
    await refetch();
    setTimeout(() => {
      refetch();
      onDownload();
      setIsExport(false);
    }, 700);
  };

  const handleOpenGenerate = () => {
    setIsOpenGenerate(!isOpenGenerate);
  };

  return {
    btnDelete,
    record,
    handleDownload,
    handleUpdateData,
    isEkskul,
    setIsEkskul,
    type,
    subjectId,
    classId,
    refetch,
    handleOpenGenerate,
    setIsOpenGenerate,
    isOpenGenerate,
    data,
    setType,
    teacherId,
    isLoadingMengajar,
    setAspekPenilaian,
    aspekPenilaian,
    setKeyword,
    isLoadBukuNilai,
    isFetching,
    dataKey,
    tableRef,
    columns,
    onDownload,
    btnAdd,
    dataGuru,
    dataMengajar,
    navigate,
    setClassId,
    setSubjectId,
    setTeacherId,
  };
};

export default BukuNilaiLogic;
