import { Button, Popconfirm, Table, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useUserParentDetail } from "hooks/users-parent-hook/useUserParentDetail";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const disabled = { cursor: "not-allowed", color: "#ccc" };

export default function DetailUserParent() {
  const { id: parentId } = useParams();

  const navigate = useNavigate();

  const { data, refetch, isLoading, isFetching } =
    useUserParentDetail(parentId);

  useEffect(() => {
    refetch();
  }, [parentId, refetch]);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlSystem", "mnuUsersParent");

  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteStudentUsersParent"
  );
  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddStudentUserParent"
  );

  const columns = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "NISN",
      dataIndex: "nisn",
      key: "nisn",
    },
    {
      title: "Kelas",
      dataIndex: "class",
      key: "class",
    },
    {
      title: "Foundation",
      dataIndex: "foundation",
      key: "foundation",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      render: (id, record) => (
        <Popconfirm
          title="Yakin ingin menghapus ?"
          okText="Hapus"
          cancelText="Batal"
          onConfirm={() => {
            DeleteApi({
              url: `/system/parents/${parentId}/delete/`,
              dataId: record?.student?.id,
              refetch,
            });
          }}
          disabled={btnDelete?.type === "disabled"}
        >
          <Tag
            color="red"
            hidden={!btnDelete}
            style={
              btnDelete?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnDelete?.type === "disabled") {
                return;
              }
            }}
          >
            Hapus
          </Tag>
        </Popconfirm>
      ),
    },
  ];

  const dataSource = data?.data?.studentParents?.map((x, i) => ({
    ...x,
    id: i + 1,
    key: i,
    name: x.student.name,
    nisn: x.student.nisn,
    class: x.student.class.name,
    foundation: x.student.foundation.name,
  }));

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Anak dari {data?.data?.name}</h1>

        <Button
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          type="primary"
          onClick={() => navigate("add-student")}
        >
          Tambah Siswa
        </Button>
      </div>

      <Table
        loading={isLoading || isFetching}
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
}
