import { Form, Input, Modal, Table, Tag, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useStudentRaportList } from "hooks/student-raport-hook/useStudentRaportList";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { usePermission } from "hooks/usePermissions";

export default function ListRaport() {
  const [isOpenPredikat, setIsOpenPredikat] = useState(false);
  const [itemRaport, setItemRaport] = useState({});
  const [keyword, setKeyword] = useState("");
  const { id_detail } = useParams();
  const { data, refetch } = useStudentRaportList(id_detail, keyword);
  const [form] = Form.useForm();
  let classValue = data?.data[0]?.student?.class?.name;

  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuRaportStudent"
  );

  // Buttons Permission
  const btnShowRaport = dataPermission?.find((x) => x.id === "btnShowRaport");
  const btnAddPredikat = dataPermission?.find((x) => x.id === "btnAddPredikat");

  useEffect(() => {
    refetch();
    if (isOpenPredikat) {
      form.setFieldsValue({
        predikat: itemRaport?.deskripsi_sikap_antarmapel,
      });
    }
    // eslint-disable-next-line
  }, [refetch, isOpenPredikat]);

  const handleClick = (raportId, studentId) => {
    const newWindow = window.open(
      `/academic/print-raport-siswa/${raportId}/${studentId}`,
      "_blank"
    );
    if (newWindow) newWindow.focus();
    // newWindow.onload = function () {
    //   newWindow.print();
    // };
  };

  const handleOpenPredikat = (item) => {
    setIsOpenPredikat(!isOpenPredikat);
    setItemRaport(item);
  };

  const handleSubmitPredikat = async () => {
    const val = await form.validateFields();
    try {
      await axios.put(
        `${process.env.REACT_APP_HOST_API}/academics/student-raports/${itemRaport.id}`,
        {
          deskripsiSikapAntarmapel: val.predikat,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success("Berhasil mengubah predikat");
      handleOpenPredikat(false);
      refetch();
    } catch (error) {
      alert("Terjadi Kesalahan!");
      console.log(error);
    }
  };

  const columns = [
    {
      title: "No",
      width: 50,
      dataIndex: "index",
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      width: 130,
      fixed: "left",
    },

    {
      title: "predikat",
      // dataIndex: "predikat",
      width: 300,
      render: (item) => (
        <>
          {item.predikat && `${item?.name}, `} {item?.predikat?.toUpperCase()}
        </>
      ),
    },
    {
      title: "Aksi",
      width: 200,
      render: (item) => (
        <>
          <Tag
            color="green"
            hidden={!btnShowRaport}
            onClick={() => {
              if (btnShowRaport?.type !== "disabled") {
                handleClick(item?.id, item?.student?.id);
              }
            }}
            style={{
              cursor:
                btnShowRaport?.type === "disabled" ? "not-allowed" : "pointer",
            }}
          >
            Raport
          </Tag>

          <Tag
            color="blue"
            style={{
              cursor:
                btnAddPredikat?.type === "disabled" ? "not-allowed" : "pointer",
            }}
            hidden={!btnAddPredikat}
            onClick={() => {
              if (btnAddPredikat?.type !== "disabled") {
                handleOpenPredikat(item);
              }
            }}
          >
            Predikat
          </Tag>
        </>
      ),
    },
  ];

  const dataSource = data?.data.map((item, index) => ({
    ...item,
    index: index + 1,
    predikat: item?.deskripsi_sikap_antarmapel,
    key: item?.id,
    name: item.student?.name,
    class: item.student?.class?.name,
  }));

  return (
    <div>
      <div className="table-header">
        <h1>
          Daftar Raport Siswa {classValue ? `Kelas - ( ${classValue} )` : ``}
        </h1>
      </div>

      <Input
        prefix={<SearchOutlined />}
        placeholder="Cari nama siswa ..."
        onChange={(e) => setKeyword(e.target.value)}
      />

      <Table
        scroll={{ y: 400 }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <Modal
        open={isOpenPredikat}
        onCancel={handleOpenPredikat}
        onOk={handleSubmitPredikat}
        closable={false}
        okText="Simpan"
        cancelText="Batal"
      >
        <Form form={form} layout="vertical">
          <Form.Item name="predikat" label="Sikap antar mapel">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
