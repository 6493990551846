import { Button, Input, message, Modal, Result, Skeleton } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useSearchStudent } from "hooks/finance-hook/useSearchStudent";
import React, { useEffect, useState } from "react";
import styles from "../ParentStudent.module.css";
import {
  CloseCircleOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function AddStudent() {
  const [nisn, setNisn] = useState("");
  const [newNisn, setNewNisn] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [data, setData] = useState();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const navigate = useNavigate();

  const { REACT_APP_HOST_API } = process.env;
  const parentId = decryptCookies("parent_id");

  const {
    data: dataStudent,
    isLoading,
    isFetching,
  } = useSearchStudent(parentId, newNisn);

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  const onSubmit = async () => {
    if (!data) {
      message.error("Siswa tidak ditemukan!");
      return;
    }

    try {
      await axios.post(
        `${REACT_APP_HOST_API}/parents/${parentId}/student`,
        {
          id: data?.id,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success("Siswa ditambahkan!");
      navigate("/home");
    } catch (error) {
      console.log(error);
      errorModal(
        "Gagal menambahkan siswa",
        error?.response?.data?.message || error?.message
      );
      if (error?.response?.data?.error) {
        errorModal("Gagal menambahkan siswa", error?.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    if (dataStudent) {
      setData(dataStudent?.data[0]);
      if (dataStudent?.data?.length > 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    }
  }, [newNisn, dataStudent]);

  const handleClear = () => {
    setNisn("");
    setNewNisn("");
  };

  return (
    <div>
      <div className="table-header">
        <h1>Tambahkan siswa</h1>
      </div>

      <div style={{ display: "flex", gap: "10px" }}>
        <Input
          prefix={<SearchOutlined />}
          value={nisn}
          onChange={(e) => setNisn(e.target.value)}
          placeholder="Masukan NISN..."
          className="search-input-billings"
          style={{
            border: "1px solid #d9d9d9",
          }}
          suffix={
            nisn ? (
              <CloseCircleOutlined
                onClick={handleClear}
                style={{ cursor: "pointer" }}
              />
            ) : null
          }
          size="large"
          onPressEnter={() => {
            setNewNisn(nisn);
            setIsFirstRender(false);
          }}
        />

        <Button
          onClick={() => {
            setNewNisn(nisn);
            setIsFirstRender(false);
          }}
          type="primary"
          size="large"
        >
          Cari
        </Button>
      </div>
      {isLoading || isFetching ? (
        <Skeleton active style={{ marginTop: "20px" }} />
      ) : (
        <>
          {isEmpty && !isFirstRender ? (
            <Result
              status="404"
              title="Siswa tidak ditemukan"
              subTitle="Harap masukan NISN siswa yang ingin ditambahkan"
            />
          ) : !isEmpty && !isFirstRender ? (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className={styles.card}>
                <div className={styles.card_border_top}></div>
                <div className={styles.img}>
                  <span>
                    <UserOutlined style={{ fontSize: "30px" }} />
                  </span>
                </div>

                <h1> {data?.nisn}</h1>
                <span> {data?.name}</span>
                <p className={styles.job}> {data?.class?.name}</p>
                <button onClick={onSubmit}> Tambah</button>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
