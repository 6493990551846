import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useStudentDetail = (id, enabled) => {
  return useQuery(
    ["get-student-detail", id],
    () => GetDetail(`/academics/students/${id}`),
    { enabled }
  );
};
