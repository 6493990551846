import { Button } from "antd";
import { encryptCookies } from "components/Helper/CookiesHelper";
import { useNavigate } from "react-router-dom";

const PresenceHeader = (props) => {
  const {
    id,
    btnAdd,
    btnRecap,
    activity,
    btnExport,
    btnTimeout,
    disabled,
    handleExport,
    setShowDetail,
    setShowCreate,
    setShowTimeoutDefault,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="presence-table-header">
      <h1>
        Daftar Presensi -{" "}
        <span
          style={{ color: "#256AD1", cursor: "pointer" }}
          onClick={() => setShowDetail(true)}
        >
          {activity ? activity.name : ""}
        </span>
      </h1>
      <div className="includes-export">
        <Button
          hidden={!btnTimeout}
          disabled={btnTimeout?.type === "disabled" || disabled}
          onClick={() => setShowTimeoutDefault(true)}
        >
          Set Waktu Pulang
        </Button>
        <Button
          onClick={handleExport}
          disabled={btnExport?.type === "disabled"}
          hidden={!btnExport || btnExport?.type === "disabled"}
        >
          Export
        </Button>
        <Button
          type="primary"
          hidden={!btnRecap}
          disabled={btnRecap && btnRecap.type === "disabled"}
          onClick={() => {
            encryptCookies("defaultHrdMenu", "mnuRecapPresence");
            navigate(`/hrd/activity/presence-${id}/recapPresence-${id}`);
          }}
        >
          Rekap
        </Button>
        <Button
          type="primary"
          hidden={!btnAdd}
          onClick={() => setShowCreate(true)}
          disabled={btnAdd && btnAdd.type === "disabled"}
        >
          Tambah
        </Button>
      </div>
    </div>
  );
};

export default PresenceHeader;
