import { Button, Input, Space, Table } from "antd";
// import EditStudentToClass from "../edit/EditStudentToClass";
import DetailClassLogic from "./DetailClassLogic";
import EditStudentToClass from "../edit/EditStudentToClass";
import { SearchOutlined } from "@ant-design/icons";
import AddStudentToClassBatch from "../create/AddStudentToClassBatch";

const DetailClass = () => {
  const {
    data,
    btnAdd,
    btnEdit,
    columns,
    keyword,
    isLoading,
    isFetching,
    showAdd,
    showEdit,
    pagination,
    dataSource,
    rowSelection,
    handleChange,
    onCreate,
    onUpdate,
    onCancel,
    setShowAdd,
    setShowEdit,
  } = DetailClassLogic();

  return (
    <>
      <div className="table-header">
        <h1>Daftar Siswa Kelas ({data?.data.name})</h1>
        <Space>
          <Button
            hidden={!btnEdit}
            onClick={() => setShowEdit(true)}
            disabled={
              (btnEdit && btnEdit.type === "disabled") ||
              rowSelection.selectedRowKeys.length === 0
            }
          >
            Pindah Kelas
          </Button>
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => setShowAdd(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah Siswa
          </Button>
        </Space>
      </div>
      <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={handleChange}
          placeholder=" cari nama siswa"
        />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        {...(btnEdit && btnEdit.type !== "disabled" && { rowSelection })}
        scroll={
          window.innerHeight < 760
            ? {
              y: "50vh",
              // x: 800,
              x: "max-content",
            }
            : {
              y: "55vh",
              // x: 800,
              x: "max-content",
            }
        }
      />
      <AddStudentToClassBatch
        show={showAdd}
        onCreate={onCreate}
        onCancel={onCancel}
      />
      <EditStudentToClass
        show={showEdit}
        onUpdate={onUpdate}
        onCancel={onCancel}
        className={data?.data.name}
        selectedIds={rowSelection.selectedRowKeys}
      />
    </>
  );
};

export default DetailClass;
