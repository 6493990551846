import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useEffect, useState } from "react";

const CreatePresenceLogic = ({ show, activityId, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const format = "YYYY-MM-DD HH:mm:ss";
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleton,
    refetch,
  } = useEmployeeList("", "", "", activityId);

  useEffect(() => {
    if (show) refetch();
  }, [show, refetch]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (fieldValue) => {
        const values = {
          ...fieldValue,
          timeIn: fieldValue["timeIn"].format(format),
          timeOut:
            fieldValue["timeOut"] && fieldValue["timeOut"].format(format),
        };

        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + `/presence`,
            { ...values, activityId: activityId },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          form.resetFields();
          message.success(data.message);
          onCreate();
        } catch (error) {
          let msg = error.response.data.message || error.message;
          alert(msg);
        }
        setLoading(false);
      })
      .catch(() => {
        alert("Fields Error");
      });
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return {
    form,
    format,
    loading,
    skeleton,
    data,
    handleSubmit,
    onCancelModal,
  };
};

export default CreatePresenceLogic;
