import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useStudentList = () => {
  return useQuery(
    "get-student-list",
    () =>
      GetList(
        "/academics/students?page=1&limit=1000&keyword=&mode=page&isGraduated=false"
      ),
    { enabled: false }
  );
};
