import { Menu } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import Cookies from "js-cookie";
import React from "react";

const NavMenus = ({
  items,
  items2,
  theme,
  handleClickMenu,
  defaultMenu,
  defaultOpen,
}) => {
  return (
    <>
      <Menu
        style={{ fontSize: "1.05em" }}
        className="fixed-menu"
        theme={theme}
        mode="inline"
        defaultSelectedKeys={
          Cookies.get(defaultMenu) ? decryptCookies(defaultMenu) : null
        }
        defaultOpenKeys={window.innerWidth > 1000 ? defaultOpen : ""}
        items={items}
        onClick={handleClickMenu}
      />
      <Menu
        style={{ fontSize: "1.05em" }}
        theme={theme}
        mode="inline"
        items={items2}
        onClick={handleClickMenu}
      />
    </>
  );
};

export default NavMenus;
