import { Line } from "@ant-design/plots";

const dataDummy = [
  { id: "1", year: 2014, total: 20 },
  { id: "2", year: 2015, total: 55 },
  { id: "3", year: 2016, total: 33 },
  { id: "4", year: 2017, total: 70 },
  { id: "5", year: 2018, total: 60 },
  { id: "6", year: 2019, total: 70 },
  { id: "7", year: 2020, total: 85 },
  { id: "8", year: 2021, total: 63 },
  { id: "9", year: 2022, total: 90 },
  { id: "10", year: 2023, total: 100 },
];

const Alumni = () => {
  // ant chart line
  const config = {
    data: dataDummy,
    xField: "year",
    yField: "total",
    label: "halo",
    point: {
      size: 2,
      shape: "circle",
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [{ type: "marker-active" }],
    yAxis: {
      grid: { line: { style: { stroke: "#ddd" } } },
      min: 0,
      tickCount: 6,
    },
    smooth: true,
  };

  return (
    <div>
      <div className="chart-wrapper">
        <div className="chart-item">
          <h3>Data Alumni</h3>
          <Line {...config} height={window.innerHeight > 800 ? 300 : 230} />
        </div>
        <div className="chart-item">
          <h3>Data Alumni</h3>
          <Line {...config} height={window.innerHeight > 800 ? 300 : 230} />
        </div>
      </div>
    </div>
  );
};

export default Alumni;
