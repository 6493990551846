import { MenuOutlined } from "@ant-design/icons";
import NavMenus from "Layouts/NavMenus";
import { Layout, Drawer } from "antd";
import LogoFG from "assets/img/logoFG.png";
import BreadCrumb from "components/UI/BreadCrumb";
import React from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import LayoutInventoryLogic from "./LayoutInventoryLogic";
import { useState } from "react";
import DynamicTitle from "components/UI/DynamicTitle";
import NavUserProfile from "components/UI/NavUserProfile";

const { Sider, Content, Header } = Layout;

const LayoutInventory = (props) => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );
  const navigate = useNavigate();

  const {
    open,
    // email,
    items,
    items2,
    itemsUser,
    setOpen,
    handleClickMenu,
    handleClickItemUser,
  } = LayoutInventoryLogic();

  return (
    <Layout>
      <DynamicTitle />
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={250}
      >
        <div className="mobile-menu-wrapper">
          <NavMenus
            items={items}
            theme="light"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultInventoryMenu"}
            defaultOpen={""}
          />
        </div>
      </Drawer>

      <div className="menu-mobile">
        <div onClick={() => navigate("/home")}>
          <img src={LogoFG} alt="logo fg" style={{ width: 22 }} />
          <h1 style={{ fontSize: "1.4em" }}>SMA FG</h1>
        </div>

        <div style={{ position: "absolute", right: "60px" }}>
          <NavUserProfile
            isOnlyIcon={true}
            itemsUser={itemsUser}
            handleClickItemUser={handleClickItemUser}
          />
        </div>

        <MenuOutlined
          style={{ fontSize: "1.3em" }}
          onClick={() => setOpen(true)}
        />
      </div>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={window.innerWidth > 1600 ? 250 : 210}
      >
        <div className="logo">
          <img src={LogoFG} alt="fg" />
          <span>SMA FG</span>
        </div>
        <div className="sider-menu-wrapper">
          <NavMenus
            theme="dark"
            items={items}
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultInventoryMenu"}
            defaultOpen={""}
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header>
          {React.createElement(HiOutlineMenuAlt2, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          <div className="header-container">
            <NavUserProfile
              isOnlyIcon={false}
              itemsUser={itemsUser}
              handleClickItemUser={handleClickItemUser}
            />
          </div>
        </Header>

        <BreadCrumb />

        <Content className="site-layout-background">{props.content}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutInventory;
