import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useFoundationPagination = (dataTable, keyword) => {
  return useQuery(
    [
      "get-foundation-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
    ],
    () =>
      GetPagination(
        `/foundation/foundations?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}`
      )
  );
};
