import {
  Badge,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Table,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "./DailyAttandance.css";
import DailyAttandanceLogic from "./DailyAttandanceLogic";

const DailyAttandance = () => {
  const {
    btnAdd,
    isFillToday,
    setDateIn,
    form,
    dataClass,
    setClassId,
    columns,
    isLoading,
    isFetching,
    filteredDataSource,
    handleSearch,
    handleSubmit,
    searchQuery,
    posting,
  } = DailyAttandanceLogic();

  return (
    <div>
      <div className="table-header">
        <h1>Form Absensi harian</h1>
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <div className="search-wrapper filter-wrapper">
          <Form.Item
            name="classId"
            label="Kelas"
            rules={[{ required: true, message: "Harap diisi" }]}
            className="form-item-kehadiran"
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Select
              disabled={posting}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => setClassId(value)}
            >
              {dataClass?.data?.map((kelas) => (
                <Select.Option key={kelas.id} value={kelas.id}>
                  {kelas.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Nama" style={{ width: "100%", marginBottom: 0 }}>
            <Input
              value={searchQuery}
              onChange={handleSearch}
              placeholder=" cari nama murid"
              disabled={posting}
            />
          </Form.Item>
          <Form.Item
            name="date_in"
            label="Waktu masuk"
            initialValue={dayjs(
              moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            )}
            rules={[{ required: true, message: "Harap diisi" }]}
            className="form-item-kehadiran"
            style={{ width: "100%", marginBottom: 0 }}
          >
            <DatePicker
              disabled={posting}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(value) => setDateIn(value)}
              style={{ padding: 4 }}
            />
          </Form.Item>
          <Form.Item
            name="date_out"
            label="Waktu keluar"
            className="form-item-kehadiran"
            style={{ width: "100%", marginBottom: 0 }}
          >
            <DatePicker
              disabled={posting}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              style={{ padding: 4 }}
            />
          </Form.Item>
        </div>
        {isFillToday ? (
          <>
            <Badge.Ribbon text="Information">
              <Card
                // title="Message"
                size="small"
                style={{ textAlign: "center" }}
                className="card-info"
              >
                🕵🏻 <strong> Tidak dapat melakukan absensi.</strong>
                <br /> kelas yang anda pilih dihari ini sudah melakukan absensi.
                silahkan periksa di menu presence daily
              </Card>
            </Badge.Ribbon>
          </>
        ) : (
          <Table
            style={{ marginBottom: 20 }}
            size={window.innerWidth > 1600 ? "middle" : "small"}
            pagination={false}
            tableLayout="auto"
            dataSource={filteredDataSource}
            columns={columns}
            loading={isLoading || isFetching}
            scroll={
              window.innerHeight < 690
                ? { y: "52vh", x: 900 }
                : { y: "55vh", x: 900 }
            }
          />
        )}

        <div className="button-container">
          <Button
            hidden={!btnAdd}
            disabled={isFillToday || btnAdd?.type === "disabled"}
            type="primary"
            htmlType="submit"
            loading={posting}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default DailyAttandance;
