import { Button, Input, Select, Space, Table, Tooltip } from "antd";
import StudentsLogic from "./StudentsLogic";
import ImportUpdate from "../import/ImportUpdate";
import ImportStudent from "../import/ImportStudent";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Students = () => {
  const {
    btnImportUpdate,
    btnAdd,
    dataKey,
    keyword,
    columns,
    btnExport,
    dataClass,
    isLoading,
    isFetching,
    pagination,
    showImportStudent,
    btnImport,
    onCancel,
    onImport,
    setClassId,
    handleExport,
    handleChange,
    setShowImportStudent,
    showImportUpdate,
    setShowImportUpdate,
    onCancelUpdate,
    onImportUpdate,
    setHasNisn,
    dataFoundation,
    classId,
    skeleton,
    setFoundationId,
    roles,
  } = StudentsLogic();

  const navigate = useNavigate();

  const handleAdd = () => {
    navigate("add");
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Siswa</h1>

        {/* ---dev */}
        <Space>
          <Button
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() => setShowImportStudent(true)}
          >
            Import
          </Button>
          <Button
            onClick={handleExport}
            hidden={!btnExport}
            disabled={btnExport?.type === "disabled"}
          >
            Export
          </Button>
          <Button
            type="primary"
            hidden={!btnImportUpdate}
            disabled={btnImportUpdate?.type === "disabled"}
            onClick={() => setShowImportUpdate(true)}
          >
            Update NISN
          </Button>
          <Button
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            onClick={handleAdd}
            type="primary"
          >
            Tambah Siswa
          </Button>
        </Space>
        {/* --dev */}
      </div>
      <div
        style={{
          maxWidth: 700,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: 10,
        }}
      >
        <Input
          value={keyword}
          onChange={handleChange}
          prefix={<SearchOutlined />}
          placeholder="Cari Nama atau NIS"
        />
        {roles.some((x) => x === "super_admin") && (
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => {
                setFoundationId(x);
                setClassId("");
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">
                ALL
              </Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        )}
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: "100%" }}
          placeholder="Kelas"
          onChange={(value) => setClassId(value)}
          disabled={skeleton}
          value={classId}
        >
          <Select.Option value="">Semua Kelas</Select.Option>
          {dataClass?.data?.map((x) => (
            <Select.Option key={x.id} value={x.id}>
              {x.name}
            </Select.Option>
          ))}
        </Select>

        <Select
          style={{ width: "100%" }}
          placeholder="NISN"
          onChange={setHasNisn}
        >
          <Select.Option value="">Semua</Select.Option>
          <Select.Option value={true}>Sudah ada NISN</Select.Option>
          <Select.Option value={false}>Belum ada NISN</Select.Option>
        </Select>
      </div>
      <Table
        bordered
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataKey}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
      />
      <ImportStudent
        show={showImportStudent}
        onCancel={onCancel}
        onImport={onImport}
        keyword="Template Import Data Siswa"
        url="/academics/import-students"
      />
      <ImportUpdate
        show={showImportUpdate}
        onCancel={onCancelUpdate}
        onImport={onImportUpdate}
        keyword="Template Import Update NISN Data Siswa"
      />
    </div>
  );
};

export default Students;
