import {
  AppstoreFilled,
  DashboardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import { HiUsers } from "react-icons/hi";
import { IoMdSchool } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const LayoutExecutiveLogic = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");

  const { data } = usePermission("mdlExecutiveSummary");

  const handleSelectMenu = (param) =>
    encryptCookies("defaultExecutiveMenu", param);

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
    else return;
  };

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuExecutive") navigate("/executive");
      else if (param.key === "mnuPendaftar" || param.key === "mnuDiterima")
        navigate("/executive/ppdb-" + param.key.toLowerCase().split("mnu")[1]);
      else navigate("/executive/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const menuAlumni = data?.find((x) => x.id === "mnuAlumni");
  const menuKaryawan = data?.find((x) => x.id === "mnuKaryawan");
  const menuSiswa = data?.find((x) => x.id === "mnuSiswa");
  const menuPPDB = data?.find((x) => x.id === "mnuPPDB");

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    { key: "mnuExecutive", icon: <DashboardOutlined />, label: "Dashboard" },
    menuAlumni && {
      key: "mnuAlumni",
      icon: <IoMdSchool />,
      label: "Alumni",
      disabled: menuAlumni?.type === "disabled",
    },
    menuKaryawan && {
      key: "mnuKaryawan",
      icon: <HiUsers />,
      label: "Karyawan",
      disabled: menuKaryawan?.type === "disabled",
    },
    menuSiswa && {
      key: "mnuSiswa",
      icon: <HiUsers />,
      label: "Siswa",
      disabled: menuSiswa?.type === "disabled",
    },
    menuPPDB && {
      icon: <HiUsers />,
      label: `PPDB`,
      disabled: menuPPDB?.type === "disabled",
      children: [
        { key: "mnuPendaftar", label: "Pendaftar" },
        { key: "mnuDiterima", label: "Diterima" },
      ],
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return {
    open,
    email,
    items,
    items2,
    itemsUser,
    setOpen,
    handleClickMenu,
    handleClickItemUser,
  };
};

export default LayoutExecutiveLogic;
