import { Badge, Button, Skeleton, Space, Table, Tag } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ForCandidate = ({ baseData }) => {
  const { REACT_APP_HOST_API } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  let id = baseData?.data[0]?.studentCandidate?.id;

  const navigate = useNavigate();

  // fetch Batch permission
  const { data: dataPermission } = usePermission("mdlPpdb", "mnuPendaftaran");

  // Buttons Permission
  const btnAddPendaftaranAwal = dataPermission?.find(
    (x) => x.id === "btnAddPendaftaranAwalCandidate"
  );
  const btnAddDaftarUlang = dataPermission?.find(
    (x) => x.id === "btnDaftarUlangCandidate"
  );
  const btnEditDaftarAwal = dataPermission?.find(
    (x) => x.id === "btnEditPendaftaranAwalCandidate"
  );

  const getData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${id}`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      // console.log(data);
      setData(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
    // eslint-disable-next-line
  }, [id]);

  const columns = [
    {
      title: "Nama",
      dataIndex: "full_name",
      width: 200,
      align: "left",
      // width: window.innerWidth > 800 ? 150 : 50,
    },
    {
      title: "Status",
      dataIndex: "id",
      width: 150,
      render: (id, x) => {
        return (
          <Badge
            status={
              x.status === "new"
                ? "default"
                : x.status === "done_primary_data"
                ? "warning"
                : x.status === "failed_exam"
                ? "error"
                : x.status === "failed_re_register"
                ? "error"
                : x.status === "pass_exam"
                ? "processing"
                : x.status === "pass_re_register"
                ? "success"
                : "default"
            }
            text={
              x.status === "new"
                ? "Baru"
                : x.status === "done_primary_data"
                ? "Daftar Awal"
                : x.status === "failed_exam"
                ? "Gagal Ujian"
                : x.status === "failed_re_register"
                ? "Gagal Verifikasi"
                : x.status === "pass_exam"
                ? "Lulus Ujian"
                : x.status === "pass_re_register"
                ? "Diverifikasi"
                : x.status === "waiting_reregister_verification"
                ? "Menunggu Verifikasi"
                : null
            }
          />
        );
      },
    },
    { title: "Nisn", width: 150, dataIndex: "nisn", align: "left" },
    { title: "Program", dataIndex: "program_choice", align: "left" },
    { title: "Jurusan", dataIndex: "major_choice", align: "left" },
    {
      title: "Jadwal Test",
      dataIndex: "id",
      width: 250,
      align: "left",
      render: (id, x) => {
        return moment(x.entranceExamSchedule?.time_start).format(
          "DD-MMM-YYYY"
        ) === moment(x.entranceExamSchedule?.time_end).format("DD-MMM-YYYY") ? (
          <Tag color="processing">{`${moment(
            x.entranceExamSchedule?.time_start
          ).format("DD-MMM-YYYY | HH:mm")} - ${moment(
            x.entranceExamSchedule?.time_end
          ).format("HH:mm")}`}</Tag>
        ) : (
          <Tag color="processing">{`${moment(
            x.entranceExamSchedule?.time_start
          ).format("DD-MMM-YYYY | HH:mm")} - ${moment(
            x.entranceExamSchedule?.time_end
          ).format("DD-MMM-YYYY | HH:mm")}`}</Tag>
        );
      },
    },

    {
      title: "aksi",
      dataIndex: "id",
      width: 200,
      align: "center",
      render: (dataId, x) => {
        return (
          <Space>
            {/* <Tag
              className="btn"
              color="error"
              onClick={() =>
                DeleteApi({
                  url: "/ppdb/student-candidates/",
                  dataId,
                  getData,
                })
              }
            >
              Hapus
            </Tag> */}

            <Tag
              hidden={!btnEditDaftarAwal || x.status !== "done_primary_data"}
              color="success"
              className="btn"
              onClick={() =>
                btnEditDaftarAwal.type !== "disabled"
                  ? navigate(`edit/${dataId}`)
                  : null
              }
            >
              Edit
            </Tag>

            <Tag
              className="btn"
              color="processing"
              onClick={() => navigate(`${dataId}`)}
            >
              Detail
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataSource = data
    .filter((data) => data.length !== 0)
    .map((data, id) => ({
      ...data,
      key: id,
      // nisn: data.userStudentCandidate.nisn,
    }));

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="table-header">
            <h1>DATA PENDAFTAR</h1>
            <Button
              hidden={!btnAddPendaftaranAwal || data[0]?.id}
              disabled={
                btnAddPendaftaranAwal &&
                btnAddPendaftaranAwal.type === "disabled"
              }
              type="primary"
              onClick={() => navigate("add")}
            >
              Form Pendaftaran
            </Button>

            <Button
              disabled={
                btnAddDaftarUlang && btnAddDaftarUlang.type === "disabled"
              }
              hidden={!btnAddDaftarUlang || data[0]?.status !== "pass_exam"}
              type="primary"
              onClick={() => navigate(`/ppdb/daftarulang/${data[0]?.id}`)}
            >
              Daftar Ulang
            </Button>
          </div>
          <div className="table-pendaftaran-candidate">
            <Table
              size={window.innerWidth > 1600 ? "middle" : "small"}
              tableLayout="auto"
              columns={columns}
              loading={isLoading}
              dataSource={data && dataSource}
              scroll={{
                // y: "50vh",
                x: 900,
              }}
              pagination={{
                position: ["none"],
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ForCandidate;
