import RequireAuth from "components/Auth/RequireAuth";
import LayoutProfile from "Layouts/LayoutProfile/LayoutProfile";
import MasterProfile from "pages/profile/MasterProfile";

export const DataRoutesProfile = [
  {
    path: "profile",
    element: (
      <RequireAuth>
        <LayoutProfile content={<MasterProfile />} />
      </RequireAuth>
    ),
  },
];
