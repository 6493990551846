import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useProsemPagination = ({ dataTable, subjectId, foundationId }) => {
  return useQuery(
    [
      "get-prosem-pagination",
      dataTable.current_page,
      dataTable.per_page,
      subjectId,
      foundationId,
    ],
    () =>
      GetPagination(
        `/academics/program-semester?page=${dataTable.current_page}&limit=${
          dataTable.per_page
        }&mode=page&subjectId=${subjectId ? subjectId : ""}&foundationId=${
          foundationId ? foundationId : ""
        }`
      )
  );
};
