import React from "react";
import IdentitasRaport from "./component/IdentitasRaport";
import ParafRaport from "./component/ParafRaport";
import { useDetailRaportUmum } from "hooks/student-raport-hook/useDetailRaportUmum";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

export default function Umum() {
  const { raport_id } = useParams();
  const [mapelNotEkskul, setMapelNotEkskul] = useState([]);
  const [mapelEkskul, setMapelEkskul] = useState([]);

  const { data, refetch } = useDetailRaportUmum(raport_id, false);

  useEffect(() => {
    if (raport_id) {
      refetch();
    }
    const notEkskul = data?.umum?.data.filter((item) => item.ekskul === false);
    const thisEkskul = data?.umum?.data.filter((item) => item.ekskul === true);
    setMapelNotEkskul(notEkskul);
    setMapelEkskul(thisEkskul);
  }, [raport_id, refetch, data]);

  return (
    <main>
      {/* <div className="container-raport-siswa raport-border"> */}
      <div className="container-raport-siswa th-space papper-raport">
        <div>
          {/* TABEL IDENTITAS */}
          <IdentitasRaport data={data?.umum?.identitasRaport} />

          {/* TABEL RAPORT */}
          <div>
            <table border={1}>
              <thead>
                <tr>
                  <th rowSpan={4}>No</th>
                  <th rowSpan={4}>Mata Pelajaran</th>
                  <th colSpan={6}>Hasil Nilai Belajar</th>
                </tr>
                <tr>
                  <th colSpan={2}>Pengetahuan</th>
                  <th colSpan={2}>Keterampilan</th>
                  <th colSpan={2}>Sikap Spiritual dan Sosial</th>
                </tr>
                <tr>
                  <th>Angka</th>
                  <th>Predikat</th>
                  <th>Angka</th>
                  <th>Predikat</th>
                  <th>Dalam Mapel</th>
                  <th>Predikat</th>
                </tr>
                <tr>
                  <th>1-100</th>
                  <th>A/B/C/D</th>
                  <th>1-100</th>
                  <th>A/B/C/D</th>
                  <th>SB/B/C/K</th>
                  <th>Antar Mapel</th>
                </tr>
              </thead>
              <tbody>
                {mapelNotEkskul &&
                  mapelNotEkskul[0]?.mapel.map((item, index) => {
                    return (
                      <tr key={index} style={{ textAlign: "center" }}>
                        <td>{index + 1}</td>
                        <td style={{ width: "200px", textAlign: "left" }}>
                          {item.subject_name}
                        </td>
                        <td>{item.nilai_pengetahuan}</td>
                        <td>{item.predikat_pengetahuan}</td>
                        <td>{item.nilai_keterampilan}</td>
                        <td>{item.predikat_keterampilan}</td>
                        <td>{item.sikap_dalam_mapel}</td>
                        {index === 0 && (
                          <td rowSpan={500} style={{ width: "200px" }}>
                            {mapelNotEkskul[0]?.predikat}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                <tr>
                  <td colSpan={2}>Ekstra Kulikuler</td>
                  <td colSpan={5}>Keterangan Dalam Kegiatan</td>
                </tr>
                {mapelEkskul ? (
                  mapelEkskul[0]?.mapel?.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{index + 1}.</td>
                        <td>{item?.name}</td>
                        <td colSpan={5}>{item?.keterangan}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <React.Fragment>
                    <tr>
                      <td>1.</td>
                      <td>-</td>
                      <td colSpan={5}>-</td>
                    </tr>
                    <tr>
                      <td>2.</td>
                      <td>-</td>
                      <td colSpan={5}>-</td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>

          {/* PRESENSI KEHADIRAN */}
          <div className="table-grub-raport">
            <div className="tabel-presensi-kehadiran">
              <table className="table-ketidakhadiran" border={"1"}>
                <colgroup>
                  <col style={{ width: 20 }} />
                  <col style={{ width: 50 }} />
                  <col style={{ width: 130 }} />
                  <col style={{ width: 51 }} />
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan={4}>Ketidakhadiran</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Sakit</td>
                    <td>{data && data?.umum?.ketidakHadiran?.sakit}</td>
                    <td>Hari</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Izin</td>
                    <td>{data && data?.umum?.ketidakHadiran?.izin}</td>
                    <td>Hari</td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>Alpa</td>
                    <td>{data && data?.umum?.ketidakHadiran?.alpha}</td>
                    <td>Hari</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* KETERANGAN */}
            <div className="table-keterangan">
              <table>
                <thead>
                  <tr>
                    <th colSpan={2}>Keterangan Nilai & Sikap</th>
                  </tr>
                </thead>
                <colgroup>
                  <col style={{ width: 170 }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>A. (Sangat Baik) = 92-100</td>
                    <td>SB = Sangat Baik</td>
                  </tr>
                  <tr>
                    <td>B. (Baik) = 83-91</td>
                    <td>B = Baik</td>
                  </tr>
                  <tr>
                    <td>C. (Cukup) = 75-82</td>
                    <td>C = Cukup</td>
                  </tr>
                  <tr>
                    <td>D. (Kuran) = 75 &gt;</td>
                    <td>D = Kurang</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* PARAF */}
          <ParafRaport data={data?.umum?.identitasRaport} />
        </div>
      </div>
    </main>
  );
}
