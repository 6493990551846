import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AddTeachersLogic = ({ onCreate, onCancel, show }) => {
  const [posting, setPosting] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const navigate = useNavigate();

  // fetch employee list
  const { data: dataEmployee, refetch } = useEmployeeList();

  // fetch teacher list
  const { data: dataTeacher, refetch: fetchTeachers } = useTeachersList();

  useEffect(() => {
    if (show) refetch();
    if (show) fetchTeachers();
  }, [show, refetch, fetchTeachers]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (fieldsValue) => {
        setPosting(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + `/academics/teachers`,
            fieldsValue,
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          message.success(data.message);
          navigate(data?.data?.id);

          form.resetFields();
          onCreate();
        } catch (error) {
          alert(error.message);
        }

        setPosting(false);
      })
      .catch(() => {
        alert("Input Field Error");
      });
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return {
    form,
    handleSubmit,
    onCancelModal,
    posting,
    dataEmployee,
    dataTeacher,
  };
};

export default AddTeachersLogic;
