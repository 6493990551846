import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useEmployeeList = (
  keyword,
  employeeTypeId,
  divisionId,
  activityId,
  foundationId
) => {
  keyword = keyword || "";
  employeeTypeId = employeeTypeId || "";
  divisionId = divisionId || "";
  activityId = activityId || "";
  foundationId = foundationId || "";
  return useQuery(
    ["get-employee-list", keyword, employeeTypeId, divisionId],
    () =>
      GetList(
        `/employee-list?keyword=${keyword}&employeeTypeId=${employeeTypeId}&divisionId=${divisionId}&activityId=${activityId}&foundationId=${foundationId}`
      ),
    {
      enabled: false,
    }
  );
};
