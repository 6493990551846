import { Form, Input, InputNumber, Modal, Select, Skeleton } from "antd";
import EditRpnLogic from "./EditRpnLogic";

const EditRpn = (props) => {
  const {
    form,
    newData,
    loading,
    fetching,
    isFetching,
    dataMetode,
    dataProsemDetail,
    onSubmit,
    onCancelModal,
  } = EditRpnLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      open={props.open}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      {isFetching || fetching ? (
        new Array(2).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item label="Cakupan Materi" name="programSemesterDetailId">
            <Select onChange={(e) => (newData.programSemesterDetailId = e)}>
              {dataProsemDetail?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.materi}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Metode Penilaian" name="metodePengambilanNilaiId">
            <Select onChange={(e) => (newData.metodePengambilanNilaiId = e)}>
              {dataMetode?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.nama}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Topik/Materi" name="topik">
            <Input
              onChange={({ target: { value } }) => (newData.topik = value)}
            />
          </Form.Item>

          <Form.Item label="Pertemuan Ke" name="pertemuan">
            <InputNumber min={1} onChange={(e) => (newData.pertemuan = e)} />
          </Form.Item>

          <Form.Item label="Persentase (1 - 100)" name="presentase">
            <InputNumber
              min={1}
              max={100}
              onChange={(e) => (newData.presentase = e)}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditRpn;
