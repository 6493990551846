import dayjs from "dayjs";
import { useStudentDetail } from "hooks/students-hook/useStudentDetail";
import React from "react";
import { useParams } from "react-router-dom";

export default function DataDiri() {
  const { student_id } = useParams();
  const { data } = useStudentDetail(student_id, true);
  let dataAyah;
  let dataIbu;
  let dataWali;

  const filterDataAyah = data?.data?.parents?.filter(
    (obj) => obj.relationship_w_student === "biological father"
  );
  const filterDataIbu = data?.data?.parents?.filter(
    (obj) => obj.relationship_w_student === "biological mother"
  );
  const filterDataWali = data?.data?.parents?.filter(
    (obj) => obj.relationship_w_student === "guardian"
  );

  dataAyah = filterDataAyah;
  dataIbu = filterDataIbu;
  dataWali = filterDataWali;

  return (
    <>
      <div className="data-diri container-raport-siswa papper-raport">
        <div className="title" style={{ marginTop: "20px" }}>
          <h1>KETERANGAN TENTANG DIRI PESERTA DIDIK</h1>
        </div>

        <div className="table-data-diri">
          <table>
            <colgroup>
              <col style={{ width: 30 }} />
              <col style={{ width: 195 }} />
              <col style={{ width: 25 }} />
              <col style={{ width: 157 }} />
            </colgroup>

            <tbody>
              <tr>
                <td>1.</td>
                <td>Nama Peserta Didik</td>
                <td>:</td>
                <td style={{ width: "250px" }}>{data?.data?.name}</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>NIS</td>
                <td>:</td>
                <td>{data?.data?.nis}</td>
              </tr>
              <tr>
                <td>3.</td>
                <td>NISN</td>
                <td>:</td>
                <td>{data?.data?.nisn}</td>
              </tr>
              <tr>
                <td>4.</td>
                <td>Tempat Tanggal Lahir</td>
                <td>:</td>
                <td>
                  {data?.data?.birth_city && data?.data?.birth_day
                    ? `${data?.data?.birth_city}, ${dayjs(
                        data?.data?.birth_day
                      ).format("D MMMM YYYY")}`
                    : ""}
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td>Jenis Kelamin</td>
                <td>:</td>
                <td>
                  {data?.data?.gender === "male"
                    ? "Laki-laki"
                    : data?.data?.gender === "female"
                    ? "Perempuan"
                    : ""}
                </td>
              </tr>
              <tr>
                <td>6.</td>
                <td>Agama</td>
                <td>:</td>
                <td>{data?.data?.religion}</td>
              </tr>
              <tr>
                <td>7.</td>
                <td>Status dalam Keluarga</td>
                <td>:</td>
                <td>Anak Kandung</td>
              </tr>
              <tr>
                <td>8.</td>
                <td>Anak Ke</td>
                <td>:</td>
                <td>{data?.data?.child_no}</td>
              </tr>
              <tr>
                <td>9.</td>
                <td>Alamat Peserta Didik</td>
                <td>:</td>
                <td>{data?.data?.address}</td>
              </tr>
              <tr>
                <td>10.</td>
                <td>Nomor Telepon</td>
                <td>:</td>
                <td>{data?.data?.phone}</td>
              </tr>
              <tr>
                <td>11.</td>
                <td>Sekolah Asal</td>
                <td>:</td>
                <td>{data?.data?.junior_hs_name}</td>
              </tr>
              <tr>
                <td>12.</td>
                <td>Diterima di Sekolah ini</td>
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td>Di Kelas</td>
                <td>:</td>
                <td>{data?.data?.class?.name}</td>
              </tr>
              <tr>
                <td />
                <td>Pada Tanggal</td>
                <td>:</td>
                <td></td>
              </tr>
              <tr>
                <td>13.</td>
                <td>Nama Orang Tua</td>
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td>Ayah</td>
                <td>:</td>
                <td>{dataAyah && dataAyah[0]?.name}</td>
              </tr>
              <tr>
                <td />
                <td>Ibu</td>
                <td>:</td>
                <td>{dataIbu && dataIbu[0]?.name}</td>
              </tr>
              <tr>
                <td>14.</td>
                <td>Alamat Orang Tua</td>
                <td>:</td>
                <td>
                  {dataAyah && dataAyah[0]?.address
                    ? dataAyah[0]?.address
                    : dataIbu && dataIbu[0]?.address}
                </td>
              </tr>
              <tr>
                <td>15.</td>
                <td>Nomor Telepon</td>
                <td>:</td>
                <td>
                  {dataAyah && dataAyah[0]?.phone_number
                    ? dataAyah[0]?.phone_number
                    : dataIbu && dataIbu[0]?.phone_number}
                </td>
              </tr>
              <tr>
                <td>16.</td>
                <td>Pekerjaan Orang Tua</td>
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td>Ayah</td>
                <td>:</td>
                <td>{dataAyah && dataAyah[0]?.occupation}</td>
              </tr>
              <tr>
                <td />
                <td>Ibu</td>
                <td>:</td>
                <td>{dataIbu && dataIbu[0]?.occupation}</td>
              </tr>
              <tr>
                <td>17.</td>
                <td>Nama Wali Peserta Didik</td>
                <td>:</td>
                <td>{dataWali && dataWali[0]?.name}</td>
              </tr>
              <tr>
                <td>18.</td>
                <td>Alamat Wali Peserta Didik</td>
                <td>:</td>
                <td>{dataWali && dataWali[0]?.address}</td>
              </tr>
              <tr>
                <td>19.</td>
                <td>Nomor Telepon</td>
                <td>:</td>
                <td>{dataWali && dataWali[0]?.phone_number}</td>
              </tr>
              <tr>
                <td>20.</td>
                <td>Pekerjaan Wali</td>
                <td>:</td>
                <td>{dataWali && dataWali[0]?.occupation}</td>
              </tr>
            </tbody>
          </table>

          <div className="paraf-data-diri">
            <div className="pas-foto">
              <span>3 X 4</span>
            </div>
            <div className="paraf">
              <p>Bekasi, 23 Desember 2023</p>
              <p style={{ fontWeight: "bold" }}>Kepala Sekolah</p>
              <span>M. Zubair Abdurrohman, S.T</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
