import { Form, InputNumber, Modal } from "antd";
import EditTransactionLogic from "./EditTransactionAmountLogic";


const EditTransactionAmount = (props) => {
   const { form, newData, loading, handleSubmit } =
      EditTransactionLogic(props);

   return (
      <Modal
         okText="Simpan"
         cancelText="Batal"
         onOk={handleSubmit}
         open={props.show}
         onCancel={props.onCancel}
         okButtonProps={{ loading }}
         title="Edit Transaksi Amount"
      >

         <Form form={form} layout="vertical">
            <Form.Item
               name="amount"
               label="Nominal"
               rules={[{ required: true, message: "Harap diisi" }]}
            >
               <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
                  style={{ width: "100%" }}
                  onChange={(value) => (newData["amount"] = `${value}`)}
               />
            </Form.Item>
         </Form>

      </Modal>
   );
};

export default EditTransactionAmount;
