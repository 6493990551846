import React from "react";

export default function CoverRaport() {
  return (
    <>
      <div className="cover-raport papper-raport">
        <div className="title">
          <h1>LAPORAN</h1>
          <h1>CAPAIAN KOMPETENSI PESERTA DIDIK</h1>
          <h1>SEKOLAH MENENGAH ATAS</h1>
          <h1>(SMA)</h1>
        </div>

        {/* table-cover */}
        <div className="table-cover">
          <table>
            <colgroup>
              <col style={{ width: 120 }} />
              <col style={{ width: 31 }} />
              <col style={{ width: 200 }} />
            </colgroup>

            <tbody>
              <tr>
                <td>Nama Sekolah</td>
                <td>:</td>
                <td>SMA FUTURE GATE</td>
              </tr>
              <tr>
                <td>NPSN</td>
                <td>:</td>
                <td>20280604</td>
              </tr>
              <tr>
                <td>Alamat Sekolah</td>
                <td>:</td>
                <td>Jalan Yudhistira, Komplek Pemda Jatiasih</td>
              </tr>
              <tr>
                <td />
                <td />
                <td>Kode Pos: 17423 Telp.: 085 100 73 4490</td>
              </tr>
              <tr>
                <td>Kelurahan</td>
                <td>:</td>
                <td>Jatiasih</td>
              </tr>
              <tr>
                <td>Kecamatan</td>
                <td>:</td>
                <td>Jatiasih</td>
              </tr>
              <tr>
                <td>Kabupaten/Kota</td>
                <td>:</td>
                <td>Kota Bekasi</td>
              </tr>
              <tr>
                <td>Provinsi</td>
                <td>:</td>
                <td>Jawa Barat</td>
              </tr>
              <tr>
                <td>Website</td>
                <td>:</td>
                <td>www.smafuturegate.com</td>
              </tr>
              <tr>
                <td>E-mail</td>
                <td>:</td>
                <td>cs@smafg.sch.id</td>
              </tr>
              <tr>
                <td />
                <td />
                <td>smafuturegate@gmail.com</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
