import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useUnitEmployeeList } from "hooks/unit-hook/useUnitEmployeeList";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./leaveDailyStyles.css";

// const { TextArea } = Input;
const format = "YYYY-MM-DD";

const CreateLeave = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const isUserHrd = roles.some((x) => x === "user_hrd");
  const isAdmminHrd = roles.some((x) => x === "admin_hrd");
  const [unitId, setUnitId] = useState("");

  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();

  // get employee
  const { data: dataUnits, isLoading: loadingUnits } = useUnitList();
  const { data, refetch, isFetching } = useUnitEmployeeList(unitId);

  useEffect(() => {
    if (unitId !== "" && unitId) {
      refetch();
    }
    form.setFieldValue("employeeId", "");
  }, [form, refetch, unitId]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      if (isUserHrd && !isAdmminHrd && !isSuperAdmin) {
        values.employeeId = decryptCookies("employeeId");
      }

      const dataSend = {
        leaveStatus: values.leaveStatus,
        employeeId: values.employeeId,
        // type: values.type,
        reason: values.reason,
        status: "waiting",
        // fromDate: values.fromDate.format(format),
        // toDate: values.toDate.format(format),
        leaveDates: values?.leaveDates?.map((x) => x.format(format)),
        unitId: values.unitId,
      };
      if (fileList.length > 0) {
        dataSend.image = values.file.file;
      }
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/leaves",
        {
          ...dataSend,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      form.resetFields();
      setFileList([]);
      navigate("/hrd/leavedaily");
      message.success(data.message);
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    form.setFieldValue("file", null);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  return (
    <>
      <div className="table-header">
        <h1>Form Tambah leave daily</h1>
      </div>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <div className="leave-form">
          <Form.Item
            name="leaveStatus"
            label="Status Izin"
            rules={[{ required: true }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="cuti">Cuti</Radio.Button>
              <Radio.Button value="sakit">Sakit</Radio.Button>
              <Radio.Button value="izin">Izin</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true }]}
            initialValue={"waiting"}
          >
            <Radio.Group buttonStyle="solid"
            >
              <Radio.Button value="waiting">Menunggu</Radio.Button>
              <Radio.Button value="aprove">Diterima</Radio.Button>
              <Radio.Button value="rejected">Ditolak</Radio.Button>
            </Radio.Group>
          </Form.Item> */}

          <Form.Item
            name="unitId"
            label="Unit"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={loadingUnits}
              onChange={(e) => setUnitId(e)}
            >
              {dataUnits?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {(!isUserHrd || isAdmminHrd || isSuperAdmin) && (
            <Form.Item
              name="employeeId"
              label="Nama"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                disabled={unitId === "" || isFetching}
              >
                {data?.data?.map((x) => (
                  <Select.Option key={x.employee?.id} value={x.employee?.id}>
                    {x.employee?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {/* <Form.Item name="type" label="Tipe" rules={[{ required: true }]}>
            <Select
              options={[
                { label: "Tidak Dipotong Gaji", value: "paid" },
                { label: "Dipotong Gaji", value: "unpaid" },
              ]}
            />
          </Form.Item> */}
          {/* <Form.Item
            name="fromDate"
            label="Tanggal Awal"
            rules={[{ required: true }]}
          >
            <DatePicker format={format} />
          </Form.Item>
          <Form.Item
            name="toDate"
            label="Tanggal Akhir"
            rules={[{ required: true }]}
          >
            <DatePicker format={format} />
          </Form.Item> */}
          <Form.Item
            name="leaveDates"
            label="Tanggal Izin"
            rules={[{ required: true }]}
          >
            <DatePicker
              multiple
              maxTagCount="responsive"
            />
          </Form.Item>
          <Form.Item
            name="reason"
            label="Alasan"
            rules={[{ required: true, min: 3 }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          name="file"
          label="Gambar(optional)"
          rules={[
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="file"
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUpload}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
                {fileList.length === 0 ? "" : ""}
              </div>
            </div>
          </Upload>
        </Form.Item>
        <div className="button-container">
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateLeave;
