import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
} from "antd";
import CreateBukuNilaiLogic from "./CreateBukuNilaiLogic";
import "./CreateBukuNilai.css";
import dayjs from "dayjs";
import moment from "moment";

const style = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  gap: 5,
};

const itemStyle = { marginBottom: 5 };

const typeOptions = [
  { label: "HARIAN", value: "HARIAN" },
  { label: "UTS", value: "UTS" },
  { label: "UAS", value: "UAS" },
];

const CreateBukuNilai = (props) => {
  const {
    btnAdd,
    onGuruChange,
    onProsemChange,
    tipe,
    setTipe,
    isEkskul,
    teacherId,
    dataAcademicYear,
    dataSemester,
    isLoadMengajar,
    isGuruLogin,
    dataGuru,
    isLoadStudentList,
    isFetchStudentList,
    valMateriProsem,
    setValMateriProsem,
    handleOpenDefault,
    isDefault,
    setValInputNumber,
    handleSubmitDefault,
    dataKey,
    setTeachingId,
    setAspek,
    aspek,
    form,
    columns,
    loading,
    dataMengajar,
    dataProsem,
    dataProsemDetail,
    handleSubmit,
  } = CreateBukuNilaiLogic(props);

  const handleTypeChange = (val) => {
    if (val.toUpperCase() === "UTS" || val.toUpperCase() === "UAS") {
      setValMateriProsem("");
      form.resetFields(["prosemDetailId"]);
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="table-header">
            <h1>
              Form buku nilai {aspek}{" "}
              <Tag color="blue">
                Semester {dataSemester?.data[0]?.semester_name} /{" "}
                {dataAcademicYear?.data?.data[0]?.year}
              </Tag>
            </h1>
          </div>
          <Form form={form} layout="vertical">
            <div style={style}>
              <Form.Item label="Semester" name="semesterId" hidden={true}>
                <Select>
                  {dataSemester?.data?.map((semester) => (
                    <Select.Option key={semester.id} value={semester.id}>
                      {semester.semester_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Tahun Ajar" name="academicYearId" hidden={true}>
                <Select>
                  {dataAcademicYear?.data?.data?.map((tahunAjar) => (
                    <Select.Option key={tahunAjar.id} value={tahunAjar.id}>
                      {tahunAjar.year}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
                label="Tanggal Pengambilan Nilai"
                name="tanggalPengambilanNilai"
                required={true}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                label="Guru"
                name="teacherId"
                rules={[{ required: !isGuruLogin, message: "Harap diisi" }]}
                hidden={isGuruLogin}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => onGuruChange(value)}
                >
                  {dataGuru?.data?.map((guru) => (
                    <Select.Option key={guru.id} value={guru.id}>
                      {guru.employee.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={itemStyle}
                label="Kelas"
                name="classId"
                rules={[{ required: true }]}
              >
                <Select
                  loading={isLoadMengajar}
                  disabled={isLoadMengajar}
                  placeholder="Kelas"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    setTeachingId(e);
                  }}
                >
                  {dataMengajar?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.class?.name
                        ? `${x.class.name} - ${x.subject.name}`
                        : x.subject.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Aspek Penilaian"
                name="aspekPenilaian"
                hidden={isEkskul}
                rules={[{ required: !isEkskul }]}
              >
                <Select
                  disabled={isEkskul}
                  onChange={(e) => {
                    setAspek(e);
                    setValInputNumber("");
                  }}
                >
                  <Select.Option value="PENGETAHUAN">PENGETAHUAN</Select.Option>
                  <Select.Option value="KETERAMPILAN">
                    KETERAMPILAN
                  </Select.Option>
                  <Select.Option value="SIKAP">SIKAP</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                style={itemStyle}
                label="Tipe"
                name="type"
                hidden={aspek === "SIKAP" || isEkskul}
                rules={[{ required: aspek !== "SIKAP" && !isEkskul }]}
              >
                <Select
                  disabled={isEkskul}
                  onChange={(e) => {
                    setTipe(e);
                    handleTypeChange(e);
                  }}
                  options={typeOptions}
                />
              </Form.Item>
              <Form.Item
                style={itemStyle}
                label="Prosem"
                name="prosemId"
                hidden={
                  tipe === "UTS" ||
                  tipe === "UAS" ||
                  aspek === "SIKAP" ||
                  isEkskul
                }
              >
                <Select
                  disabled={isEkskul}
                  onChange={(e) => {
                    onProsemChange(e);
                  }}
                >
                  {dataProsem?.data?.map((x) => {
                    if (x.teacher_id === teacherId) {
                      return (
                        <Select.Option key={x.id} value={x.id}>
                          {x?.class?.name} - {x.mapel?.name} -{" "}
                          {x.teachers?.employee?.name}
                        </Select.Option>
                      );
                    }
                    return null;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={itemStyle}
                label="Materi Prosem"
                name="prosemDetailId"
                hidden={
                  tipe === "UTS" ||
                  tipe === "UAS" ||
                  aspek === "SIKAP" ||
                  isEkskul
                }
                // rules={[{ required: tipe === "HARIAN" && isEkskul !== true }]}
              >
                <Select
                  disabled={isEkskul}
                  onChange={(e) => setValMateriProsem(e)}
                >
                  <Select.Option value="">----------</Select.Option>
                  {dataProsemDetail?.data?.programSemesterDetail?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.materi}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Materi"
                name="material"
                hidden={isEkskul}
                rules={[
                  {
                    required: !valMateriProsem && !isEkskul,
                  },
                ]}
              >
                <Input disabled={isEkskul} />
              </Form.Item>
              {/* --- */}
              <Button
                style={{ marginTop: 25, width: 150 }}
                type="primary"
                onClick={handleOpenDefault}
                hidden={isDefault}
                disabled={dataKey.length === 0}
              >
                Set Nilai Default
              </Button>

              {isDefault && (
                <div className="wrap-default-nilai">
                  <Form.Item label="Nilai Default">
                    {isEkskul ? (
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(e) => setValInputNumber(e.target.value)}
                      >
                        <Radio.Button value="A">A</Radio.Button>
                        <Radio.Button value="B">B</Radio.Button>
                        <Radio.Button value="C">C</Radio.Button>
                      </Radio.Group>
                    ) : aspek !== "SIKAP" ? (
                      <InputNumber
                        onChange={(val) => setValInputNumber(val)}
                        min={0}
                        max={100}
                      />
                    ) : (
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(e) => setValInputNumber(e.target.value)}
                      >
                        <Radio.Button value="B">B</Radio.Button>
                        <Radio.Button value="SB">SB</Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <Space>
                    <Button type="primary" onClick={handleSubmitDefault}>
                      OK
                    </Button>
                    <Button type="primary" ghost onClick={handleOpenDefault}>
                      Batal
                    </Button>
                  </Space>
                </div>
              )}
            </div>

            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={dataKey}
              pagination={false}
              loading={isLoadStudentList || isFetchStudentList}
              scroll={window.innerHeight < 760 ? { y: "40vh" } : { y: "45vh" }}
            />
          </Form>
        </>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          bottom: 10,
        }}
      >
        <Button
          type="primary"
          loading={loading}
          style={{ width: "50%" }}
          onClick={handleSubmit}
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
        >
          Simpan
        </Button>
      </div>
    </>
  );
};

export default CreateBukuNilai;
