// /academics/daily-attendances?page=1&limit=10&mode=page&fromDate=2023-06-05&toDate=2023-06-12&recap=true
import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useStudentsPerSubject = (
  dataTable,
  dateIn,
  dateOut,
  keyword,
  className,
  subject,
  session,
  isRecap,
  enabled
) => {
  return useQuery(
    [
      "get-students-per-subject",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      className,
      subject,
      session,
      isRecap,
      dateIn,
      dateOut,
    ],
    () =>
      GetDetail(
        // `/academics/lesson-attendances?page=${dataTable.current_page}&limit=${
        //   dataTable.per_page
        // }&mode=page&fromDate=${dateIn}&toDate=${dateOut}&keyword=${keyword}&${
        //   isRecap ? "recap=true" : "isExtracurricular=true"
        // }`

        `/academics/lesson-attendances?page=${dataTable.current_page}&limit=${dataTable.per_page}&fromDate=${dateIn}&toDate=${dateOut}&recap=${isRecap}&keyword=${keyword}&className=${className}&subject=${subject}&session=${session}&isExtracurricular=true`
      ),
    {
      enabled,
    }
  );
};
