import { Form, Modal, Radio, Skeleton, } from "antd";
import BayarAlokasiLogic from "./BayarAlokgasiLogic";
import { DateTime } from "luxon";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import "./BayarAlokasi.css";

const BayarAlokasi = (props) => {
   const { handleSubmit, onCancelModal, posting, skeleton, dataDetail, RowSelection, getTotalYangDibayar, form } =
      BayarAlokasiLogic(props);

   const formattedDate = DateTime.fromISO(dataDetail?.data?.time_received, {
      zone: "utc",
   }).toFormat("dd MMMM yyyy hh:mm a");

   return (
      <Modal
         okText="Bayar"
         cancelText="Batal"
         onOk={handleSubmit}
         open={props.show}
         onCancel={onCancelModal}
         okButtonProps={{ loading: posting }}
      >
         {skeleton && <Skeleton active />}

         {!skeleton && props.show && (
            <>
               <div>
                  <p className="modal-alokasi-title">Buat Transaksi Pembayaran</p>
                  <p className="modal-secondary-heading">{dataDetail?.data?.account?.owner}</p>
                  <p className="modal-paragraph">{formattedDate}</p>
                  <Form form={form} layout="vertical" className="form-alokasi">
                     <Form.Item
                        name="type"
                        label="Tipe"
                        rules={[{ required: true, message: "Harap diisi" }]}
                     >
                        <Radio.Group buttonStyle="solid">
                           <Radio.Button value="debit">Debit</Radio.Button>
                           <Radio.Button value="credit">Kredit</Radio.Button>
                        </Radio.Group>
                     </Form.Item>
                     <Form.Item
                        name="method"
                        label="metode"
                        rules={[{ required: true, message: "Harap diisi" }]}
                     >
                        <Radio.Group buttonStyle="solid">
                           <Radio.Button value="virtual_account">Virtual Akun</Radio.Button>
                           <Radio.Button value="cash">Tunai</Radio.Button>
                        </Radio.Group>
                     </Form.Item>
                  </Form>
                  {<RowSelection />}
                  <div className="modal-amount-box box-top-border">
                     <p>Saldo :</p>
                     <p>{renderAmount(dataDetail?.data?.current_balance)}</p>
                     <p>Total Bayar :</p>
                     <p>{renderAmount(getTotalYangDibayar())}</p>
                     <p>sisa saldo :</p>
                     <p>{renderAmount(dataDetail?.data?.current_balance - getTotalYangDibayar() > 0 ? dataDetail?.data?.current_balance - getTotalYangDibayar() : 0)}</p>
                  </div>
               </div></>

         )}
      </Modal>
   );
};

export default BayarAlokasi;
