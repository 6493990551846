import { GetDetail } from "api/GetDetail";
import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useTriwulanReportPagination = ({ dataTable, keyword, date }) => {
  return useQuery(
    [
      "get-triwulanreport-pagination",
      dataTable.page,
      dataTable.limit,
      keyword,
      date.from,
      date.to,
    ],
    () =>
      GetPagination(
        `/triwulans?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${
          keyword ? keyword : ""
        }&fromDate=${date.from}&toDate=${date.to}`
      )
  );
};

export const useTriwulanReportDetail = ({
  id,
  keyword,
  employeeId,
  dataTable,
}) => {
  return useQuery(
    [
      "get-triwulanreport-detail",
      id,
      keyword,
      employeeId,
      dataTable?.page,
      dataTable?.limit,
    ],
    () =>
      GetPagination(
        `/triwulans/${id}?keyword=${keyword}&employeeId=${employeeId}&page=${dataTable?.page}&limit=${dataTable?.limit}`
      ),
    { enabled: false }
  );
};

export const useTriwulanRecap = (id) => {
  return useQuery(
    "get-triwulan-recap",
    () => GetDetail(`/triwulans/${id}/recaps`),
    { enabled: false }
  );
};
