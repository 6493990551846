import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useEmployeeTypeList = () => {
  return useQuery(
    "get-employee_type-list",
    () => GetList("/employee-types/?page=1&limit=100&keyword="),
    {
      enabled: false,
    }
  );
};
