import { useBillingsPagination } from "hooks/finance-hook/useBillingsPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";

const AlokasiPembayaranLogic = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isBayar, setIsBayar] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });

  const { data, isLoading, isFetching, refetch } = useBillingsPagination(
    dataTable,
    keyword
  );

  // fetch classes permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuBillings");

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const onSelectChange = (key, data) => {
    setSelectedIds(key);
    setSelectedData(data);
  };

  const onCreate = useCallback(() => {
    setIsBayar(false);
    setSelectedIds([]);
    setSelectedData([]);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setSelectedIds([]);
    setSelectedData([]);
    setIsBayar(false);
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: onSelectChange,
  };

  // Buttons Permission start
  const btnBayar = dataPermission?.find((x) => x.id === "btnBayarBillings");

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Nama", dataIndex: "name", align: "left" },
    { title: "Nama Akun tertagih", dataIndex: "account_name", align: "left" },
    { title: "Jumlah", dataIndex: "jumlah", align: "left" },
    { title: "Tipe", dataIndex: "type", align: "left" },
    { title: "Status", dataIndex: "status", align: "left" },
    { title: "Deskripsi", dataIndex: "description", align: "left" },
  ];

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        key: x.id,
        index: i + 1,
        account_name: x.account.account_name,
        jumlah: new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(x.amount),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return {
    columns,
    dataSource,
    btnBayar,
    isLoading,
    isFetching,
    pagination,
    handleChange,
    keyword,
    rowSelection,
    setIsBayar,
    isBayar,
    selectedData,
    onCancel,
    onCreate,
  };
};

export default AlokasiPembayaranLogic;
