import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useCategoryList = (keyword = "", foundation_id = "") => {
  return useQuery(
    ["get-category-list", keyword, foundation_id],
    () =>
      GetList(
        `/links/categories?keyword=${keyword}&foundation_id=${foundation_id}&mode=list`
      ),
    {
      enabled: true,
    }
  );
};
