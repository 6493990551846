import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const DataSiswaLainnyaTab = () => {
  const { candidate_id } = useParams();
  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Kebutuhan Khusus</th>
                <td>{dataInti.special_needs && dataInti.special_needs}</td>
              </tr>
              <tr>
                <th>Anak Ke</th>
                <td>{dataInti.child_no && dataInti.child_no}</td>
              </tr>
              <tr>
                <th>Jumlah Saudara</th>
                <td>{dataInti.siblings && dataInti.siblings}</td>
              </tr>
              <tr>
                <th>No. KK</th>
                <td>{dataInti.family_card_no && dataInti.family_card_no}</td>
              </tr>
              <tr>
                <th>Berat Badan</th>
                <td>{dataInti.weight && dataInti.weight} Kg</td>
              </tr>
              <tr>
                <th>Tinggi Badan</th>
                <td>{dataInti.height && dataInti.height} Cm</td>
              </tr>
              <tr>
                <th>Lingkar Kepala</th>
                <td>
                  {dataInti.head_circumference && dataInti.head_circumference}{" "}
                  Cm
                </td>
              </tr>
              <tr>
                <th>Latitude</th>
                <td>{dataInti.address_lat && dataInti.address_lat}</td>
              </tr>
              <tr>
                <th>Longitude</th>
                <td>{dataInti.address_long && dataInti.address_long}</td>
              </tr>
              <tr>
                <th>Jarak Rumah</th>
                <td>
                  {dataInti.distance_to_school_in_km &&
                    dataInti.distance_to_school_in_km}{" "}
                  Km
                </td>
              </tr>
              <tr>
                <th>Alat Transportasi</th>
                <td>{dataInti.transportation && dataInti.transportation}</td>
              </tr>
              <tr>
                <th>Unit</th>
                <td>{dataInti.unit && dataInti.unit}</td>
              </tr>
              <tr>
                <th>Program Final</th>
                <td>{dataInti.program_final && dataInti.program_final}</td>
              </tr>
              <tr>
                <th>Jurusan Final</th>
                <td>{dataInti.major_final && dataInti.major_final}</td>
              </tr>
              <tr>
                <th>SPP Final</th>
                <td>
                  {dataInti.spp_final && dataInti.spp_final === "1_1_juta"
                    ? "1.1 Juta"
                    : dataInti.spp_final === "1_3_juta"
                    ? "1.3 Juta"
                    : dataInti.spp_final === "1_5_juta"
                    ? "1.5 Juta"
                    : dataInti.spp_final === "2_juta"
                    ? "2 Juta"
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataSiswaLainnyaTab;
