import { Form, Input, Modal, Radio, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useJurusanList } from "hooks/jurusan-hook/useJurusanList";
import { useEffect, useState } from "react";

const AddClass = (props) => {
  const [posting, setPosting] = useState(false);
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const [foundationId, setFoundationId] = useState("");

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;



  // fetch jurusan list
  const { data: dataJurusan, refetch: fetchJurusan } = useJurusanList();

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetch employee list
  const { data: dataEmployee, refetch, isFetching } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId,
  );

  useEffect(() => {
    if (foundationId !== "" || !isSuperAdmin) {
      refetch();
    }
  }, [refetch, foundationId, isSuperAdmin]);

  useEffect(() => {
    if (props.show) {
      fetchJurusan();
    }
  }, [props.show, fetchJurusan]);

  const handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields();
      setPosting(true);

      const response = await axios.post(
        `${REACT_APP_HOST_API}/academics/classes`,
        fieldsValue,
        { headers: { Authorization: `Bearer ${decryptCookies("token")}` } }
      );

      message.success(response.data.message);
      form.resetFields();
      props.onCreate();
    } catch (error) {
      let msg = error?.response?.data?.message || 'input field error';
      message.error(msg);
    } finally {
      setPosting(false);
    }
  };

  const handleChangeFoundationId = (value) => {
    setFoundationId(value);
    form.setFieldsValue({ employeeId: "" });
  };

  const onCancelModal = () => {
    form.resetFields();
    setFoundationId("");
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: posting }}
    >
      <Form form={form} layout="vertical">
        {(isSuperAdmin) &&
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(x) => handleChangeFoundationId(x)}
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          name="name"
          label="Nama Kelas"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="kelasJurusan"
          label="Jurusan"
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {dataJurusan?.data?.map((jurusan) => (
              <Select.Option key={jurusan.kode} value={jurusan.kode}>
                {jurusan.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="employeeId"
          label="Wali Kelas"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={isSuperAdmin && (isFetching || foundationId === "")}
          >
            {dataEmployee?.data?.map((employee) => (
              <Select.Option key={employee.id} value={employee.id}>
                {employee.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Deskripsi (Tahun Ajaran)" rules={[{ required: true, message: "Harap diisi" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="is_graduated"
          label="Status"
          rules={[{ required: true, message: "Harap diisi" }]}
          initialValue={false}
        >
          <Radio.Group>
            <Radio value={true}>Sudah Lulus</Radio>
            <Radio value={false}>Belum Lulus</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddClass;
