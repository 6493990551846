import { Button, Skeleton, Space } from "antd";
import { TriwulanReportContext } from "context/TriwulanReportContext";
import { useTriwulanReportPagination } from "hooks/triwulan-report-hook/apiCalls";
import { usePermission } from "hooks/usePermissions";
import { useContext, useEffect, useState } from "react";
import CreateTReport from "./CreateTReport";
import EditTReport from "./EditTReport";
import TReportFilter from "./TReportFilter";
import TriwulanCard from "./component/TriwulanCard";
import "./triwulan.css";

const TriwulanReport = () => {
  const [dataTable] = useState({
    page: 1,
    limit: 1000000000000000,
  });

  const { date, keyword, fetchReport, setShowCreate, setFetchReport } =
    useContext(TriwulanReportContext);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuTriwulanReport");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddTriwulan");

  const { data, refetch, isFetching } = useTriwulanReportPagination({
    dataTable,
    keyword,
    date,
  });

  useEffect(() => {
    refetch();
    return () => setFetchReport(false);
  }, [fetchReport, refetch, setFetchReport]);

  return (
    <>
      <div className="table-header">
        <h1>Daftar Rapor</h1>
        <Space>
          <Button
            type="primary"
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            onClick={() => {
              if (btnAdd?.type === "disabled") return;
              else setShowCreate(true);
            }}
          >
            Generate Rapor
          </Button>
        </Space>
      </div>
      <TReportFilter />

      <div className="report-triwulan-list">
        {!isFetching &&
          data?.data?.data?.map((triwulans) => (
            <TriwulanCard
              dataPermission={dataPermission}
              key={triwulans.id}
              triwulan={triwulans}
              refetch={refetch}
            />
          ))}
        {isFetching && <Skeleton active />}
      </div>
      <CreateTReport />
      <EditTReport />
    </>
  );
};

export default TriwulanReport;
