import React from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { useRecapsBillingsList } from 'hooks/finance-hook/useRecapsBillings';
import Backdrop from 'components/UI/Backdrop';
import { useParams } from 'react-router-dom';
import { renderAmount } from 'pages/si-finance/utils/utlisFunction';

const styles = StyleSheet.create({
   page: {
      flexDirection: 'row',
      backgroundColor: '#ffff',
      padding: 30,
      gap: 10
   },
   wrapper: {
      flexDirection: 'row',
      flexBasis: '50%',
   },
   section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
   },
   image: {
      width: '85%',
      height: '0.7in',
      marginBottom: 9,
      alignSelf: 'center'
   },
   titleWrapper: {
      marginBottom: 10
   },
   textTitle: {
      fontSize: 8,
      fontWeight: 'bold',
      alignSelf: 'center',
      marginTop: 5
   },
   bioWrapper: {
      gap: 5,
      flexDirection: 'row',
      marginTop: 5,
   },
   textBiodataLabel: {
      fontSize: 8,
      fontWeight: 'bold',
      flexBasis: '15%',
   },
   textBiodata: {
      fontSize: 8,
      flexBasis: '50%',
      alignSelf: 'flex-start',
   },
   table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 10
   },
   tableRow: {
      margin: "auto",
      flexDirection: "row"
   },
   tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
   },
   tableCol50: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
   },
   tableCellHeader: {
      margin: "auto",
      fontWeight: "bold",
      fontSize: 9,
      padding: 5,
   },
   tableCell: {
      paddingLeft: 5,
      marginTop: 5,
      fontSize: 8,
   },
   keteranganWrapper: {
      marginTop: 10,
   },
   textKeterangan: {
      fontSize: 8,
      marginTop: 3
   }
});

function PdfRender() {
   const { student_id: id } = useParams();

   const { data, isLoading, } = useRecapsBillingsList(id);

   const renderStatus = (status) => {
      const statusObject = {
         'paid_full': 'Lunas',
         'paid_partial': 'Bayar Sebagian',
         'unpaid': 'Belum Bayar',
         'approved': 'Disetujui'
      }
      return statusObject[status]
   }

   return (
      <>
         {isLoading && <Backdrop loading={isLoading} />}
         {
            !isLoading &&
            <PDFViewer height={
               window.innerHeight > 0
                  ? window.innerHeight
                  : window.screen.height
            }
               width={
                  window.innerWidth > 0
                     ? window.innerWidth
                     : window.screen.width
               }
            >
               <Document title={
                  `Kartu Catatan Pembayaran ${data?.data?.name} ${data?.data?.class?.name} ${new Date().getFullYear()}`
               }>
                  <Page style={styles.page} orientation='landscape' size="FOLIO">
                     <View style={styles.wrapper}>
                        <View style={styles.section}>
                           <Image style={
                              styles.image
                           } src={require('assets/img/logo-header-fg.jpg')} />
                           <View style={styles.titleWrapper}>
                              <Text style={styles.textTitle}>KARTU CATATAN PEMBAYARAN</Text>
                              <Text style={styles.textTitle}>SMA FUTURE GATE</Text>
                              <Text style={styles.textTitle}>TAHUN AJARAN 2023-2024</Text>
                           </View>
                           <View style={styles.bioWrapper}>
                              <Text style={styles.textBiodataLabel}>Nama</Text>
                              <Text style={styles.textBiodata}>: {data?.data?.name}</Text>
                           </View>
                           <View style={styles.bioWrapper}>
                              <Text style={styles.textBiodataLabel}>Kelas</Text>
                              <Text style={styles.textBiodata}>: {data?.data?.class?.name}</Text>
                           </View>
                           {data.data?.accounts?.map((account, index) => {
                              return (
                                 <View key={index} style={styles.bioWrapper}>
                                    <Text style={styles.textBiodataLabel}>No VA {account.type.toUpperCase()}</Text>
                                    <Text style={styles.textBiodata}>: {account.number}</Text>
                                 </View>
                              )
                           })}
                           <View style={styles.bioWrapper}>
                              <Text style={styles.textBiodataLabel}>Update</Text>
                              <Text style={styles.textBiodata}>: {
                                 new Date().toLocaleDateString('id-ID', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                 })

                              }</Text>
                           </View>
                           {data?.data?.payments?.spp.length !== 0 && (
                              <View style={styles.table}>
                                 <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                       <Text style={[styles.tableCell, styles.tableCellHeader]}>SPP</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                       <Text style={[styles.tableCell, styles.tableCellHeader]}>Tanggal</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                       <Text style={[styles.tableCell, styles.tableCellHeader]}>Nominal</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                       <Text style={[styles.tableCell, styles.tableCellHeader]}>Keterangan</Text>
                                    </View>
                                 </View>
                                 {data?.data?.payments?.spp.map((payment) => {
                                    return (
                                       <View key={payment?.id} style={styles.tableRow}>
                                          <View style={styles.tableCol}>
                                             <Text style={styles.tableCell}>{payment?.bulan}</Text>
                                          </View>
                                          <View style={styles.tableCol}>
                                             <Text style={styles.tableCell}>{payment?.tanggal_bayar}</Text>
                                          </View>
                                          <View style={styles.tableCol}>
                                             <Text style={styles.tableCell}>{renderAmount(payment?.nominal_bayar)}</Text>
                                          </View>
                                          <View style={styles.tableCol}>
                                             <Text style={styles.tableCell}>{renderStatus(payment.status)}</Text>
                                          </View>
                                       </View>
                                    )
                                 })}
                              </View>
                           )}

                           <View style={styles.keteranganWrapper}>
                              <Text style={styles.textKeterangan}>Keterangan</Text>
                              <Text style={styles.textKeterangan}>1.Jika ada ketidak sesuaian data, silakan konfirmasi ke WA 085100734490 di hari dan jam kerja</Text>
                              <Text style={styles.textKeterangan}>2.Data diatas berdasarkan rekap catatan maksimal sesuai tanggal rilis update</Text>
                              <Text style={styles.textKeterangan}>3.Jika rekap diatas terdapat ketidak sesuaian data maka akan di revisi ulang</Text>
                           </View>
                        </View>
                     </View>
                     <View style={styles.wrapper}>
                        <View style={styles.section}>
                           <Image style={
                              styles.image
                           } src={require('assets/img/logo-header-fg.jpg')} />
                           <View style={styles.titleWrapper}>
                              <Text style={styles.textTitle}>KARTU TAGIHAN SISWA</Text>
                              <Text style={styles.textTitle}>SMA FUTURE GATE</Text>
                              <Text style={styles.textTitle}>TAHUN AJARAN 2023-2024</Text>
                           </View>
                           <View style={styles.bioWrapper}>
                              <Text style={styles.textBiodataLabel}>Nama</Text>
                              <Text style={styles.textBiodata}>: {data?.data?.name}</Text>
                           </View>
                           <View style={styles.bioWrapper}>
                              <Text style={styles.textBiodataLabel}>Kelas</Text>
                              <Text style={styles.textBiodata}>: {data?.data?.class?.name}</Text>
                           </View>
                           {data.data?.accounts?.map((account, index) => {
                              return (
                                 <View key={index} style={styles.bioWrapper}>
                                    <Text style={styles.textBiodataLabel}>No VA {account.type.toUpperCase()}</Text>
                                    <Text style={styles.textBiodata}>: {account.number}</Text>
                                 </View>
                              )
                           })}
                           <View style={styles.bioWrapper}>
                              <Text style={styles.textBiodataLabel}>Update</Text>
                              <Text style={styles.textBiodata}>: {
                                 new Date().toLocaleDateString('id-ID', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                 })

                              }</Text>
                           </View>
                           {data?.data?.billings?.spp?.length !== 0 && <View style={styles.table}>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>SPP</Text>
                                 </View>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>Status</Text>
                                 </View>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>Nominal</Text>
                                 </View>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>Keterangan</Text>
                                 </View>
                              </View>
                              {
                                 data?.data?.billings?.spp?.map((payment) => (
                                    <View key={payment.id} style={styles.tableRow}>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{payment.bulan}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{renderStatus(payment.status)}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{renderAmount(payment.nominal_tagihan)}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{payment.keterangan}</Text>
                                       </View>
                                    </View>
                                 ))
                              }
                           </View>}
                           {(data?.data?.billings?.bp?.length !== 0 || data?.data?.billings?.bwt?.length !== 0) && <View style={styles.table}>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>JENIS TAGIHAN</Text>
                                 </View>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>STATUS</Text>
                                 </View>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>Nominal</Text>
                                 </View>
                                 <View style={styles.tableCol}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>Keterangan</Text>
                                 </View>
                              </View>
                              {
                                 data?.data?.billings?.bp?.map((payment) => (
                                    <View key={payment.id} style={styles.tableRow}>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>Bea Awal Pendidikan</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{renderStatus(payment.status)}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{renderAmount(payment.nominal_tagihan)}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{payment.keterangan}</Text>
                                       </View>
                                    </View>
                                 ))
                              }
                              {
                                 data?.data?.billings?.bwt?.map((payment) => (
                                    <View key={payment.id} style={styles.tableRow}>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>bwt</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{renderStatus(payment.status)}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{renderAmount(payment.nominal_tagihan)}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{payment.keterangan}</Text>
                                       </View>
                                    </View>
                                 ))
                              }
                           </View>}
                           <View style={styles.table}>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol50}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>TOTAL TAGIHAN JATUH TEMPO BERDASARKAN UPDATE</Text>
                                 </View>
                                 <View style={styles.tableCol50}>
                                    <Text style={[styles.tableCell, styles.tableCellHeader]}>{
                                       new Date().getDate() + '-' +
                                       new Date().toLocaleString('default', { month: 'short' }) + '-' +
                                       new Date().getFullYear()
                                    }</Text>
                                 </View>
                              </View>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>Total Tagihan SPP</Text>
                                 </View>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>{renderAmount(data?.data?.total_tagihan_spp)}</Text>
                                 </View>
                              </View>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>Total Tagihan BP</Text>
                                 </View>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>{renderAmount(data?.data?.total_tagihan_bp)}</Text>
                                 </View>
                              </View>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>Total Tagihan BWT</Text>
                                 </View>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>{renderAmount(data?.data?.total_tagihan_bwt)}</Text>
                                 </View>
                              </View>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>Total Tunggakan</Text>
                                 </View>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>{renderAmount(data?.data?.total_tunggakan)}</Text>
                                 </View>
                              </View>
                              <View style={styles.tableRow}>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>Total</Text>
                                 </View>
                                 <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>{renderAmount(data?.data?.total)}</Text>
                                 </View>
                              </View>
                           </View>
                        </View>
                     </View>
                  </Page>
               </Document>
            </PDFViewer >
         }
      </>
   )
}

export default PdfRender