import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";
import { useLinkPagination } from "hooks/link-hook/useLinkPagination";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DetailLink from "../detail/DetailLink";
import { useCategoryList } from "hooks/link-hook/category/useCategoryList";
import { DeleteApi } from "api/DeleteApi";

const disabled = { color: "#ccc", cursor: "not-allowed" };

export default function Link() {
  const [openDetail, setOpenDetail] = useState(false);
  const [dataItem, setDataItem] = useState({});
  const [foundationId, setFoundationId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const roles = decryptCookies("role").split(",");

  const navigate = useNavigate();

  //fetch link pagination
  const { data, refetch, isFetching } = useLinkPagination(
    dataTable,
    foundationId,
    keyword,
    categoryId
  );

  //fetch category list
  const { data: dataCategory } = useCategoryList("", foundationId);

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLink");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddLink");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditLink");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailLink");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteLink");

  const handleSetOpenDetail = () => {
    setOpenDetail(!openDetail);
  };

  const info = (categories, title) => {
    Modal.info({
      title: title,
      content: (
        <div>
          {categories.map((item, index) => (
            <p key={item.id} style={{ marginBottom: 0 }}>
              {index + 1}. {item.name}
            </p>
          ))}
        </div>
      ),
      onOk() {},
    });
  };

  const limitCategories = (categories) => {
    const nameArr = categories
      .slice(0, 3)
      .map((item) => item?.name)
      .join(", ");

    return (
      <>
        {nameArr}
        {categories.length > 3 && (
          <p>
            ...
            <span
              style={{
                cursor: "pointer",
                color: "#4096FF",
                fontWeight: "bold",
              }}
              onClick={() => info(categories, "Kategori")}
            >
              detail
            </span>
          </p>
        )}
      </>
    );
  };

  const columns = [
    {
      title: "Link",
      dataIndex: "url",
      render: (url) => (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="text-[#4096FF]"
        >
          {url.length > 50 ? `${url.slice(0, 50)}...` : url}
        </a>
      ),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
    },
    {
      title: "Owner",
      dataIndex: "employee",
    },
    // {
    //   title: "Group",
    //   dataIndex: "group",
    // },
    {
      title: "Kategori",
      dataIndex: "categories",
      render: (categories) => limitCategories(categories || []),
    },

    ...(roles.includes("super_admin")
      ? [
          {
            title: "Foundation",
            dataIndex: "foundation",
          },
        ]
      : []),
    {
      title: "Aksi",
      dataIndex: "id",
      width: 200,
      render: (id, record) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/links/links/", dataId, refetch });
              }}
              disabled={
                (btnDelete && btnDelete.type === "disabled") || !record.is_owner
              }
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled" || !record.is_owner
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" || !record.is_owner
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled" || !record.is_owner) {
                  return;
                } else {
                  navigate(`edit/${record.id}`);
                }
              }}
              disabled={btnEdit?.type === "disabled" || !record.is_owner}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") return;
                setDataItem(record);
                handleSetOpenDetail();
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = data?.data?.data?.map((x) => ({
    ...x,
    key: x.id,
    employee: x?.employee?.name,
    foundation: x?.foundation?.name,
  }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };
  return (
    <div>
      <div className="table-header">
        <h1>Daftar Link</h1>

        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd?.type === "disabled"}
          onClick={() => {
            if (btnAdd?.type === "disabled" || !btnAdd) {
              return;
            } else {
              navigate("add");
            }
          }}
        >
          Tambah
        </Button>
      </div>

      <div className="filter-search">
        {roles.some((x) => x === "super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Cari">
          <Input
            allowClear
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Deskripsi, Owner, Group"
          />
        </Tooltip>

        <Tooltip title="Kategori">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setCategoryId(e)}
          >
            <Select.Option value="">Semua</Select.Option>
            {dataCategory?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isFetching || refetch}
        pagination={pagination}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <DetailLink
        open={openDetail}
        setOpen={handleSetOpenDetail}
        data={dataItem}
      />
    </div>
  );
}
