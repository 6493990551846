import { UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React from "react";

const TabDataIbu = ({ newDataParent, fileIbu, setFileIbu, filteredMother }) => {
  // const { REACT_APP_DEV } = process.env;
  const { REACT_APP_HOST_API } = process.env;

  const uploadKtpIbu = async (file) => {
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/parents/${filteredMother[0].id}/file-upload`,
        { file: file, category: "ktp" },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("KTP berhasil diubah!");
    } catch (error) {
      alert("Gagal mengubah KTP Ibu");
    }
  };

  return (
    <div className="double-form">
      <div>
        <Form.Item
          label="Nama Ibu"
          name="mother0_0name"
          rules={[
            {
              pattern: new RegExp("^[A-Za-z ]+$"),
              message: "Nama hanya dapat berupa huruf",
            },
            {
              min: 5,
              message: "Nama minimal 5 karakter",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0name = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="KTP Ibu"
          name="ktp_ibu"
          rules={[
            {
              required: fileIbu.length === 0,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Upload
            listType="picture"
            accept=".png,.jpg,.jpeg,.gif,.pdf"
            fileList={fileIbu}
            beforeUpload={(file) => {
              if (fileIbu.length >= 1) {
                message.error("Allowed only 1 file");
                return false;
              }
              if (file.size > 2000000) {
                message.error("Maks File 2MB");
                return false;
              }
              if (filteredMother.length !== 0) {
                uploadKtpIbu(file);
              }
              setFileIbu([file]);
              return false;
            }}
            onRemove={(file) => {
              setFileIbu([]);
            }}
          >
            {fileIbu.length === 0 && (
              <Button type="primary" icon={<UploadOutlined />}>
                Upload
              </Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="NIK "
          name="mother0_0nik"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            { max: 16, message: "Maksimal 16 karakter" },
            {
              min: 16,
              message: "Minimal 16 Karakter",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0nik = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Tahun Lahir"
          name="mother0_0birth_date"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <DatePicker
            onChange={(e) => (newDataParent.mother0_0birth_date = e)}
          />
        </Form.Item>

        <Form.Item
          label="Jenjang Pendidikan"
          name="mother0_0education"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Select
            onChange={(a) => {
              newDataParent.mother0_0education = a;
            }}
            options={[
              {
                value: "SD / Sederajat",
                label: "SD/Sederajat",
              },
              {
                value: "SMP / Sederajat",
                label: "SMP/Sederajat",
              },
              {
                value: "SMA / Sederajat",
                label: "SMA/Sederajat",
              },
              {
                value: "D1",
                label: "D1",
              },
              {
                value: "D2",
                label: "D2",
              },
              {
                value: "D3",
                label: "D3",
              },
              {
                value: "D4",
                label: "D4",
              },
              {
                value: "S1",
                label: "S1",
              },
              {
                value: "S2",
                label: "S2",
              },
            ]}
          />
        </Form.Item>
      </div>

      <div>
        <Form.Item
          label="Pekerjaan"
          name="mother0_0occupation"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              max: 40,
            },
            {
              pattern: new RegExp("^[A-Za-z ]+$"),
              message: "Nama hanya dapat berupa huruf dan spasi",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0occupation = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Penghasilan Minimun"
          name="mother0_0min_salary"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              max: 10,
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0min_salary = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Penghasilan Maximum"
          name="mother0_0max_salary"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              max: 10,
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0max_salary = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Alamat"
          name="mother0_0address"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0address = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="No. Telp"
          name="mother0_0phone_number"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              max: 16,
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0phone_number = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="mother0_0email"
          rules={[{ type: "email", message: "email tidak valid" }, { max: 50 }]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0email = value)
            }
          />
        </Form.Item>

        {/* <Form.Item label="Hubungan Dengan Siswa" name="relationship_w_student">
        <Select
          disabled={true}
          options={[
            {
              value: "biological mother",
              label: "Ibu Kandung",
            },
          ]}
        />
      </Form.Item> */}
      </div>
    </div>
  );
};

export default TabDataIbu;
