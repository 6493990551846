import { useState } from "react";
import { Form, Radio, Space, Input, Button, Table, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import { useNotEmployeeDivision } from "hooks/divison-hook/useDivisionNotEmployee";

export default function AddEmployeeToDivisiBatch() {
  const [keyword, setKeyword] = useState("");
  const [rowSelected, setRowSelected] = useState([]);
  const [selectedJabatan, setSelectedJabatan] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });

  const { division_id } = useParams();
  const navigate = useNavigate();
  const { REACT_APP_HOST_API: api } = process.env;

  const { data, isLoading } = useNotEmployeeDivision(division_id,
    dataTable,
    keyword
  )

  const [form] = Form.useForm();

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuDivision");

  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddEmployeeToDivision"
  );


  const onSelectChange = (selectedRowKeys) => {
    setRowSelected(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: rowSelected,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const dataSource = data?.data?.data
    .slice(0, dataTable.per_page)
    .map((employee, index) => {
      return {
        ...employee,
        index: index + 1,
        key: employee?.employee?.id,
        name: employee?.employee?.name,
        id: employee?.employee?.id
      };
    })

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, 300],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const onSubmit = async () => {
    if (rowSelected.length === 0) {
      alert("Tidak ada yang ditambahkan");
      return;
    }

    const employeeData = rowSelected.map((id) => {
      //   const employee = data.data.find((item) => item.id === id);
      const title = selectedJabatan[`title_${id}`] || "member";
      return {
        employeeId: id,
        divisionId: division_id,
        title: title,
        // name: employee?.name,
      };
    });

    try {
      setIsLoad(true);
      await axios.post(
        `${api}/employee-divisions`,
        { employeeDivisions: employeeData },
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Data berhasil ditambahkan");
      navigate(-1);
    } catch (error) {
      message.error("Gagal menambahkan data");
      alert(error?.response?.data?.message);
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  };

  const titleOptions = [
    { label: "Ketua", value: "lead" },
    { label: "Wakil", value: "vice" },
    { label: "Anggota", value: "member" },
  ];

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      fixed: "left",
      width: window.innerWidth > 600 ? 250 : 100,
    },
    {
      title: "Jabatan",
      dataIndex: "key",
      render: (_, record) => (
        <Form.Item
          name={`title${record.id}`}
          onChange={(e) => {
            setSelectedJabatan((prev) => {
              return {
                ...prev,
                [`title_${record.id}`]: e.target.value,
              };
            });
          }}
        >
          <Radio.Group buttonStyle="solid" defaultValue={"member"}>
            {titleOptions.map((title) => (
              <Radio.Button key={title.value} value={title.value}>
                {title.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      ),
    },
  ];

  return (
    <div>
      <div className="table-header">
        <h1>Tambah Karyawan ke Divisi</h1>
        <Space>
          <Input
            allowClear
            placeholder="Cari nama karyawan..."
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button
            hidden={!btnAdd}
            loading={isLoad}
            type="primary"
            onClick={() => onSubmit()}
            disabled={rowSelected.length === 0 || btnAdd?.type === "disabled"}
          >
            Simpan
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <Table
          loading={isLoading}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          scroll={{ y: 500, x: 700 }}
          pagination={pagination}
        />
      </Form>
    </div>
  );
}
