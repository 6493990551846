import { DatePicker, Form, Input, Modal, Select, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { TriwulanReportContext } from "context/TriwulanReportContext";
import dayjs from "dayjs";
import { useTriwulanReportDetail } from "hooks/triwulan-report-hook/apiCalls";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import moment from "moment";
import { useContext, useEffect, useState } from "react";

const format = "YYYY-MM-DD";

const EditTReport = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const { showEdit, setShowEdit, setFetchReport, reportId } = useContext(
    TriwulanReportContext
  );
  const isSuperAdmin = decryptCookies("role").split(",").some((x) => x === "super_admin" || x === "admin_foundation");
  const [prevShowEdit, setPrevShowEdit] = useState(false);

  const { data: dataUnits, isLoading: loadingUnits } =
    useUnitList();

  const { REACT_APP_HOST_API } = process.env;
  const { data, isFetching, refetch } = useTriwulanReportDetail({
    id: reportId,
    keyword: "",
    employeeId: "",
    dataTable: "",
  });

  useEffect(() => {
    if (showEdit && !prevShowEdit) {
      refetch();
      setPrevShowEdit(true);
    }

    if (!showEdit) {
      setPrevShowEdit(false);
    }
    if (data)
      form.setFieldsValue({
        name: data?.triwulan?.name,
        description: data?.triwulan?.description,
        fromDate: dayjs(moment(data?.triwulan?.from_date).format(format)),
        toDate: dayjs(moment(data?.triwulan?.to_date).format(format)),
        unitId: data.triwulan?.unit_id,
      });
    return () => setNewData({});
  }, [data, form, showEdit, refetch, prevShowEdit]);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang berubah");
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/triwulans/${reportId}`,
        newData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      setFetchReport(true);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    setShowEdit(false);
    form.resetFields();
  };

  return (
    <Modal
      open={showEdit}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      centered
    >
      {isFetching ? (
        new Array(2).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Periode"
            tooltip="Contoh: Triwulan 1 2023"
            rules={[{ min: 3 }]}
          >
            <Input
              onChange={({ target: { value } }) => (newData.name = value)}
            />
          </Form.Item>
          {isSuperAdmin && <Form.Item
            name="unitId"
            label="Unit"
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={loadingUnits}
              onChange={(value) => {
                newData["unitId"] = value;
              }}
            >
              {dataUnits?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>}
          <Form.Item name="fromDate" label="Tanggal Awal">
            <DatePicker
              format={format}
              onChange={(e) => {
                if (e !== null) newData.fromDate = e.format(format);
                else return;
              }}
            />
          </Form.Item>
          <Form.Item name="toDate" label="Tanggal Akhir">
            <DatePicker
              format={format}
              onChange={(e) => {
                if (e !== null) newData.toDate = e.format(format);
                else return;
              }}
            />
          </Form.Item>
          <Form.Item name="description" label="Deskripsi">
            <Input onChange={(e) => (newData.description = e.target.value)} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditTReport;
