import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const DataSiswaLainnyaTab = () => {
  const { student_id } = useParams();
  const { data, isLoading } = useParentList(student_id, true);
  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data.data && (
        <Fragment key={data.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Kebutuhan Khusus</th>
                <td>{data.data.special_needs && data.data.special_needs}</td>
              </tr>
              <tr>
                <th>Anak Ke</th>
                <td>{data.data.child_no && data.data.child_no}</td>
              </tr>
              <tr>
                <th>Jumlah Saudara</th>
                <td>{data.data.siblings && data.data.siblings}</td>
              </tr>
              <tr>
                <th>No. KK</th>
                <td>{data.data.family_card_no && data.data.family_card_no}</td>
              </tr>
              <tr>
                <th>Berat Badan</th>
                <td>{data.data.weight && data.data.weight} Kg</td>
              </tr>
              <tr>
                <th>Tinggi Badan</th>
                <td>{data.data.height && data.data.height} Cm</td>
              </tr>
              <tr>
                <th>Lingkar Kepala</th>
                <td>
                  {data.data.head_circumference && data.data.head_circumference}{" "}
                  Cm
                </td>
              </tr>
              <tr>
                <th>Latitude</th>
                <td>{data.data.address_lat && data.data.address_lat}</td>
              </tr>
              <tr>
                <th>Longitude</th>
                <td>{data.data.address_long && data.data.address_long}</td>
              </tr>
              <tr>
                <th>Jarak Rumah</th>
                <td>
                  {data.data.distance_to_school_in_km &&
                    data.data.distance_to_school_in_km}{" "}
                  Km
                </td>
              </tr>
              <tr>
                <th>Alat Transportasi</th>
                <td>{data.data.transportation && data.data.transportation}</td>
              </tr>
              <tr>
                <th>Unit</th>
                <td>{data.data.unit && data.data.unit}</td>
              </tr>
              <tr>
                <th>Program</th>
                <td>{data.data.program && data.data.program}</td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataSiswaLainnyaTab;
