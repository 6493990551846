import { DataRoutesAcademic } from "./DataRoutesAcademic";
import { DataRoutesExecutive } from "./DataRoutesExecutive";
import { DataRoutesHrd } from "./DataRoutesHrd";
import { DataRoutesInventory } from "./DataRoutesInventory";
import { DataRoutesPpdb } from "./DataRoutesPpdb";
import { DataRoutesProfile } from "./DataRoutesProfile";
import { DataRoutesSytem } from "./DataRoutesSytem";
import { DataRoutesUniversal } from "./DataRoutesUniversal";
import { DataRoutesFinance } from "./DataRoutesFinance";
import { DataRoutesFoundation } from "./DataRoutesFoundation";
import { DataRoutesFinanceReport } from "./DataRoutesFinanceReport";
import { DataRoutesLink } from "./DataRoutesLink";

export const DataRoutes = [
  DataRoutesUniversal,
  DataRoutesAcademic,
  DataRoutesHrd,
  DataRoutesInventory,
  DataRoutesSytem,
  DataRoutesExecutive,
  DataRoutesProfile,
  DataRoutesPpdb,
  DataRoutesFinance,
  DataRoutesFoundation,
  DataRoutesFinanceReport,
  DataRoutesLink,
];
