import dayjs from "dayjs";
import React from "react";

export default function ParafRaport({ data }) {
  return (
    <div className="paraf-container">
      <div className="ortu">
        <p>Mengetahui</p>
        <p>Orang Tua/Wali Siswa</p>
        <span>......................................</span>
      </div>
      <div className="wali-kelas">
        <p></p>
        <p>Wali Kelas</p>
        <span>{data?.wali_kelas}</span>
      </div>
      <div className="kepala-sekolah">
        <p>Bekasi, {dayjs().format("DD MMMM YYYY")}</p>
        <p>Kepala Sekolah</p>
        <span>{data?.kepala_sekolah}</span>
      </div>
    </div>
  );
}
