import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useRecapParent = (parentId, type, fromDate) => {
  return useQuery(["get-recap-parent-list", parentId, type, fromDate], () =>
    GetList(
      `/finance/parents/${parentId}/recap-parent?type=${type}&from_date=${fromDate}`
    )
  );
};
