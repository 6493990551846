import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useFoundationDetail = (id, enabled) => {
  return useQuery(
    ["get-founudation-detail", id],
    () => GetDetail(`/foundation/foundations/${id}`),
    {
      enabled,
    }
  );
};
