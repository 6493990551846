import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useProsemList = () => {
  return useQuery(
    "get-prosem-list",
    () => GetList("/academics/program-semester?mode=list"),
    { enabled: false }
  );
};
