import { Form, Modal, Radio, Skeleton, } from "antd";
import BayarAlokasiLogic from "./BayarAlokgasiLogic";
import { DateTime } from "luxon";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import "./BayarAlokasi.css";

const BayarAlokasi = (props) => {
   const { handleSubmit, onCancelModal, posting, skeleton, dataDetail, RowSelection, getTotalYangDibayar, form } =
      BayarAlokasiLogic(props);

   const formattedDate = DateTime.fromISO(dataDetail?.data?.created_at, {
      zone: "utc",
   }).toFormat("dd MMMM yyyy hh:mm a");

   return (
      <Modal
         okText="Bayar"
         cancelText="Batal"
         onOk={handleSubmit}
         open={props.show}
         onCancel={onCancelModal}
         okButtonProps={{ loading: posting }}
      >
         {skeleton && <Skeleton active />}

         {!skeleton && props.show && (
            <>
               <Form form={form} layout="vertical">
                  <Form.Item
                     name="type"
                     label="Tipe"
                     rules={[{ required: true, message: "Harap diisi" }]}
                  >
                     <Radio.Group buttonStyle="solid">
                        <Radio.Button value="debit">Debit</Radio.Button>
                        <Radio.Button value="credit">Kredit</Radio.Button>
                     </Radio.Group>
                  </Form.Item>
                  <Form.Item
                     name="method"
                     label="metode"
                     rules={[{ required: true, message: "Harap diisi" }]}
                  >
                     <Radio.Group buttonStyle="solid">
                        <Radio.Button value="virtual_account">Virtual Akun</Radio.Button>
                        <Radio.Button value="cash">Tunai</Radio.Button>
                     </Radio.Group>
                  </Form.Item>
               </Form>
               <div>
                  <p className="modal-alokasi-title">Buat Transaksi Pembayaran</p>
                  <p className="modal-secondary-heading">{dataDetail?.data?.student?.name}</p>
                  <p className="modal-paragraph">{formattedDate}</p>
                  {<RowSelection />}
                  <div className="modal-amount-box box-top-border">
                     <p>Total Bayar :</p>
                     <p>{renderAmount(getTotalYangDibayar())}</p>
                  </div>
               </div>
            </>
         )}
      </Modal>
   );
};

export default BayarAlokasi;
