import { Column, Pie } from "@ant-design/plots";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const Siswa = () => {
  const [dataTingkat, setDataTingkat] = useState([]);
  const [dataJurusan, setDataJurusan] = useState([]);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API: api } = process.env;

  const fetchDataSiswa = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(api + "/charts/siswa-tingkat", {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      setDataTingkat(
        data.perTingkat.map((x) => {
          return {
            ...x,
            total: Number(x.total),
          };
        })
      );
      setDataJurusan(
        data.perJurusan.map((x) => {
          return {
            ...x,
            total: Number(x.total),
          };
        })
      );
    } catch (error) {
      if (error.response.status === 401) {
        if (
          error.response.statusText === "TOKEN" ||
          error.response.statusText === "UNVERIFIED"
        ) {
          return <Navigate to="/" />;
        } else {
          return <Navigate to="/permission-error" />;
        }
      }
      alert("ERROR", error.message);
    }
    setLoading(false);
  }, [api]);

  useEffect(() => {
    fetchDataSiswa();
  }, [fetchDataSiswa]);

  const configTingkat = {
    appendPadding: 5,
    data: dataTingkat,
    angleField: "total",
    colorField: "tingkat",
    label:
      window.innerWidth > 530
        ? { type: "spider", labelHeight: 28, content: "{name} | {percentage}" }
        : { type: "inner" },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    legend: window.innerWidth > 530 ? false : { position: "left" },
  };

  const configJurusan = {
    appendPadding: 5,
    data: dataJurusan,
    angleField: "total",
    colorField: "jurusan",
    label:
      window.innerWidth > 530
        ? { type: "spider", labelHeight: 28, content: "{name} | {percentage}" }
        : { type: "inner" },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    legend: window.innerWidth > 530 ? false : { position: "left" },
  };

  const seluruhData = [
    {
      tingkat: "X",
      jurusan: "MIPA",
      total: 55,
    },
    {
      tingkat: "XI",
      jurusan: "MIPA",
      total: 58,
    },
    {
      tingkat: "XII",
      jurusan: "MIPA",
      total: 55,
    },
    {
      tingkat: "X",
      jurusan: "IPS",
      total: 42,
    },
    {
      tingkat: "XI",
      jurusan: "IPS",
      total: 25,
    },
    {
      tingkat: "XII",
      jurusan: "IPS",
      total: 25,
    },
    {
      tingkat: "X",
      jurusan: "BHS",
      total: 23,
    },
    {
      tingkat: "XI",
      jurusan: "BHS",
      total: 5,
    },
    {
      tingkat: "XII",
      jurusan: "BHS",
      total: 23,
    },
  ];

  const configColumn = {
    data: seluruhData,
    xField: "tingkat",
    yField: "total",
    seriesField: "jurusan",
    isStack: true,
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle'
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    legend: {
      position: "top-left",
    },
  };

  return (
    <div>
      <div className="chart-wrapper">
        <div className="chart-item">
          <h3>Per Tingkat</h3>
          <Pie
            {...configTingkat}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
        <div className="chart-item">
          <h3>Per Jurusan</h3>
          <Pie
            {...configJurusan}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
      </div>
      <div className="chart-wrapper">
        <div className="chart-item">
          <h3>Per Tingkat & Jurusan</h3>
          <Column
            {...configColumn}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Siswa;
