import { Form, Input, Modal, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import SuccessfullyRegister from "components/UI/SuccessfullyRegister";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

const roleOptions = [
  { label: "Karyawan", value: "employee" },
  { label: "Guru", value: "teacher" },
  { label: "Siswa", value: "student" },
  { label: "Alumni", value: "alumni" },
  { label: "Orang Tua", value: "parent" },
];

const Register = ({ open, onCancel }) => {
  const [form] = Form.useForm();
  const [role, setRole] = useState("");
  const [isHide, setIsHide] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    if (Cookies.get("dataReg")) {
      const { name, email } = jwt_decode(decryptCookies("dataReg"));

      form.setFieldsValue({
        name,
        email,
        role: "employee",
      });

      setRole("employee");
    }
  }, [form, open]);

  const onRegister = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      await axios.post(REACT_APP_HOST_API + "/auth/register", values);

      Cookies.remove("dataReg");
      form.resetFields();
      handleModalSuccess();
      // onCancel();
    } catch (error) {
      alert(
        error?.response?.data?.data
          ? error?.response?.data?.data
              .map(
                (item) =>
                  `( ${item.field} ) : ${
                    item.message === "unique validation failure"
                      ? "harus unik!"
                      : item.message === "exists validation failure"
                      ? "tidak ditemukan!"
                      : item.message
                  }`
              )
              .join("\n")
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : "Gagal Register!"
      );
    } finally {
      setLoading(false);
    }
  };

  const OnCancelModal = () => {
    onCancel();
    form.resetFields();
  };

  const handleModalSuccess = () => {
    setIsOpenModalSuccess(!isOpenModalSuccess);
  };

  return (
    <Modal
      closable
      open={open}
      onCancel={OnCancelModal}
      onOk={onRegister}
      okText="Register"
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nama"
          name="name"
          rules={[{ required: true, min: 5 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Role" name="role" rules={[{ required: true }]}>
          <Select
            options={roleOptions}
            onChange={(e) => {
              setRole(e);
              setIsHide(false);
            }}
          />
        </Form.Item>
        {role === "employee" || role === "teacher" ? (
          <Form.Item
            label="NIK"
            name="nik"
            rules={[
              { required: role === "employee" || role === "teacher" },
              { min: 16 },
              { max: 16 },
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          <Form.Item
            label="NISN"
            name="nisn"
            rules={[
              { required: role !== "employee" },
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
            hidden={isHide}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[{ required: true, min: 6 }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Konfirmasi Password"
          name="password_confirmation"
          hasFeedback
          rules={[
            { required: true },
            { min: 6 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password harus sama"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
      <SuccessfullyRegister
        isOpen={isOpenModalSuccess}
        onClose={handleModalSuccess}
        onCloseParent={onCancel}
      />
    </Modal>
  );
};

export default Register;
