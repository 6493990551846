import React from "react";

const CountPpdb = () => {
  return (
    <div className="count-ppdb">
      <div className="card-count-ppdb card-1">
        <h1>200</h1>
        <p>Jumlah Pendaftar</p>
      </div>
      <div className="card-count-ppdb card-2">
        <h1>100</h1>
        <p>Total Lulus PPDB</p>
      </div>
      <div className="card-count-ppdb card-3">
        <h1>50</h1>
        <p>Total Tidak Lulus PPDB</p>
      </div>
    </div>
  );
};

export default CountPpdb;
