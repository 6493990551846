import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useParentList = (studentId, enabled) => {
  return useQuery(
    "get-parent-list",
    () => GetList(`/academics/students/${studentId}/parents`),
    { enabled }
  );
};
