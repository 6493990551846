import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import GenerateBrodcastModal from "../generate-brodcast/GenerateBrodcastModal";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Tag, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useStudentPagination } from "hooks/students-hook/useStudentPagination";
import { usePermission } from "hooks/usePermissions";
import { useState } from "react";
// import * as XLSX from "xlsx";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PdfPage from "../pdf-renderer/PdfPage";
import ExcelJS from "exceljs";
import logoHeader from "../../../../assets/img/logo-header-fg.jpg";
const disabled = { color: "#ccc", cursor: "not-allowed" };

const RecapsBillings = () => {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const [loadingExport, setLoadingExport] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  const classId = "";

  const { data, isLoading, isFetching } = useStudentPagination({
    dataTable,
    keyword,
    classId,
  });

  const [showModalGenerate, setShowModalGenerate] = useState(false);

  // fetch maste billings permission
  const { data: dataPermission } = usePermission(
    "mdlFinance",
    "mnuRecapsBillings"
  );

  const onCancel = () => {
    setShowModalGenerate(false);
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  // const handleExport = async (data) => {
  //    setLoadingExport(true);
  //    try {
  //       // fetch billings data
  //       const dataRecaps = await axios.get(
  //          `${REACT_APP_HOST_API}/finance/billings/recap-billing/${data.id}?academic_year_id=2`,
  //          {
  //             headers: { Authorization: "Bearer " + decryptCookies("token") },
  //          }
  //       );

  //       const statusBillings = {
  //          paid_full: "Lunas",
  //          paid_partial: "Bayar Sebagian",
  //          unpaid: "Belum Bayar",
  //          approved: "Disetujui",
  //       };

  //       const tableBillings =
  //          dataRecaps.data?.data?.payments?.spp.length !== 0
  //             ? [
  //                ["SPP", "TANGGAL", "NOMINAL", "KETERANGAN"],
  //                ...dataRecaps.data?.data?.payments?.spp.map((x, i) => [
  //                   x.bulan,
  //                   x.tanggal_bayar,
  //                   x.nominal_bayar,
  //                   statusBillings[x.status],
  //                ]),
  //             ]
  //             : [];

  //       const noVA =
  //          dataRecaps.data?.data?.accounts.length !== 0
  //             ? [
  //                ...dataRecaps.data?.data?.accounts.map((x, i) => [
  //                   `No VA ${x.type.toUpperCase()}`,
  //                   x.number,
  //                ]),
  //             ]
  //             : [];

  //       const tableBillingSpp =
  //          dataRecaps.data?.data?.billings?.spp.length !== 0
  //             ? [
  //                ["SPP", "STATUS", "NOMINAL", "KETERANGAN"],
  //                ...dataRecaps.data?.data?.billings?.spp.map((x, i) => [
  //                   x.bulan,
  //                   x.status,
  //                   x.nominal_tagihan,
  //                   x.keterangan,
  //                ]),
  //             ]
  //             : [];

  //       const tableBillingsBP =
  //          dataRecaps.data?.data?.billings?.bp.length !== 0
  //             ? [
  //                ["JENIS TAGIHAN", "STATUS", "NOMINAL", "KETERANGAN"],
  //                ...dataRecaps.data?.data?.billings?.bp.map((x, i) => [
  //                   "Bea Awal Pendidikan",
  //                   x.status,
  //                   x.nominal_tagihan,
  //                   x.keterangan,
  //                ]),
  //                ...dataRecaps.data?.data?.billings?.bwt.map((x, i) => [
  //                   "BWT",
  //                   x.status,
  //                   x.nominal_tagihan,
  //                   x.keterangan,
  //                ]),
  //             ]
  //             : [];

  //       const tableTotal = [
  //          ["Total SPP", dataRecaps.data?.data?.total_tagihan_spp],
  //          ["Total BP", dataRecaps.data?.data?.total_tagihan_bp],
  //          ["Total BWT", dataRecaps.data?.data?.total_tagihan_bwt],
  //          ["Total Tunggakan", dataRecaps.data?.data?.total_tunggakan],
  //          ["Total", dataRecaps.data?.data?.total],
  //       ];

  //       const dataToExport = [
  //          ["KARTU CATATAN PEMBAYARAN"],
  //          ["SMA FUTURE GATE"],
  //          ["TAHUN AJARAN 2023-2024"],
  //          [],
  //          ["Name", data.name],
  //          ["Kelas", data.class.name],
  //          noVA,
  //          [],
  //          ...tableBillings,
  //          [],
  //          [],
  //          ["KARTU TAGIHAN SISWA"],
  //          ["SMA FUTURE GATE"],
  //          ["TAHUN AJARAN 2023-2024"],
  //          [],
  //          ["Name", data.name],
  //          ["Kelas", data.class.name],
  //          noVA,
  //          [],
  //          ...tableBillingSpp,
  //          [],
  //          ...tableBillingsBP,
  //          [],
  //          [],
  //          [
  //             "TOTAL TAGIHAN JATUH TEMPO BERDASARKAN UPDATE",
  //             new Date().getDate() +
  //             "-" +
  //             new Date().toLocaleString("default", { month: "short" }) +
  //             "-" +
  //             new Date().getFullYear(),
  //          ],
  //          ...tableTotal,
  //       ];

  //       let wb = XLSX.utils.book_new();
  //       let ws = XLSX.utils.aoa_to_sheet(dataToExport);

  //       XLSX.utils.book_append_sheet(wb, ws, "MySheet 1");
  //       XLSX.writeFile(wb, `Kartu Catatan Pembayaran ${data.name} ${data.class.name} ${new Date().getFullYear()}.xlsx`);
  //    } catch (error) {
  //       message.error(error.message);
  //    }
  //    setLoadingExport(false);
  // };

  const handleGetDataPdfFile = async (id) => {
    try {
      setLoadingExport(true);
      // fetch billings data
      const dataRecaps = await axios.get(
        `${REACT_APP_HOST_API}/finance/billings/recap-billing/${id}?academic_year_id=2`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      return dataRecaps;
    } catch (error) {
      message.error(error.message);
      return null;
    } finally {
      setLoadingExport(false);
    }
  };

  const handleGeneratePdf = async (id) => {
    try {
      const data = await handleGetDataPdfFile(id);
      if (
        data &&
        data.data &&
        data.data.data &&
        data.data.data.name &&
        data.data.data.class &&
        data.data.data.class.name
      ) {
        const fileName = `Kartu Catatan Pembayaran ${data.data.data.name} ${
          data.data.data.class.name
        } ${new Date().getFullYear()}`;
        const doc = <PdfPage data={data.data} />;
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, fileName);
      } else {
        // Tangani ketika data tidak lengkap atau struktur tidak terduga
        console.error("Struktur data tidak sesuai yang diharapkan");
      }
    } catch (error) {
      message.error(error.message);
    }
    // try {
    //    // fetch billings data
    //    const data = await handleGetDataPdfFile(id);
    //    const fileName = `Kartu Catatan Pembayaran ${data?.data?.data?.name} ${data?.data?.data?.class?.name
    //       } ${new Date().getFullYear()}`;
    //    const doc = <PdfPage data={data.data} />;
    //    const asPdf = pdf();
    //    asPdf.updateContainer(doc);
    //    const blob = await asPdf.toBlob();
    //    saveAs(blob, fileName);
    //    // // Open the PDF in a new tab
    //    // const pdfUrl = URL.createObjectURL(blob);
    //    // window.open(pdfUrl, "_blank");
    //    // URL.revokeObjectURL(pdfUrl);
    // } catch (error) {
    //    message.error(error.message);
    // }
  };

  const handleExportExcel = async (data) => {
    try {
      const dataRecaps = await axios.get(
        `${REACT_APP_HOST_API}/finance/billings/recap-billing/${data.id}?academic_year_id=2`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("My Sheet");
      const imageBuffer = await axios.get(logoHeader, {
        responseType: "arraybuffer",
      });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: "jpeg",
      });
      const fullBorder = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      sheet.mergeCells("A1:E1");
      sheet.getCell("A1").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      // height: 100,
      sheet.getRow(1).height = 100;
      // width: 100,
      sheet.getColumn(1).width = 20;
      sheet.getColumn(2).width = 20;
      sheet.getColumn(3).width = 20;
      sheet.getColumn(4).width = 20;
      sheet.getColumn(5).width = 20;
      sheet.addImage(imageId2, "A1:E1");

      sheet.mergeCells("A2:E2");
      sheet.getCell("A2").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell("A2").value = "KARTU CATATAN PEMBAYARAN";

      sheet.mergeCells("A3:E3");
      sheet.getCell("A3").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell("A3").value = "SMA FUTURE GATE";

      sheet.mergeCells("A4:E4");
      sheet.getCell("A4").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell("A4").value = "TAHUN AJARAN 2023-2024";

      sheet.getCell("A6").value = "Nama";
      sheet.getCell("B6").value = data?.name;
      sheet.getCell("A7").value = "Kelas";
      sheet.getCell("B7").value = data?.class?.name;

      const statusBillings = {
        paid_full: "Lunas",
        paid_partial: "Bayar Sebagian",
        unpaid: "Belum Bayar",
        approved: "Disetujui",
      };

      let row = 8;

      dataRecaps.data?.data?.accounts.forEach((x, i) => {
        sheet.getCell(`A${row}`).value = `No VA ${x.type.toUpperCase()}`;
        sheet.getCell(`B${row}`).value = x.number;
        row += 1;
      });
      row += 1;

      if (dataRecaps.data?.data?.payments?.spp.length !== 0) {
        sheet.getCell(`A${row}`).value = "SPP";
        sheet.getCell(`A${row}`).font = { bold: true };
        sheet.getCell(`A${row}`).border = fullBorder;
        sheet.getCell(`B${row}`).value = "TANGGAL";
        sheet.getCell(`B${row}`).border = fullBorder;
        sheet.getCell(`B${row}`).font = { bold: true };
        sheet.getCell(`C${row}`).value = "NOMINAL";
        sheet.getCell(`C${row}`).border = fullBorder;
        sheet.getCell(`C${row}`).font = { bold: true };
        sheet.getCell(`D${row}`).value = "KETERANGAN";
        sheet.getCell(`D${row}`).border = fullBorder;
        sheet.getCell(`D${row}`).font = { bold: true };
        row += 1;

        dataRecaps.data?.data?.payments?.spp.forEach((x, i) => {
          sheet.getCell(`A${row}`).value = x.bulan;
          sheet.getCell(`A${row}`).border = fullBorder;
          sheet.getCell(`B${row}`).value = x.tanggal_bayar;
          sheet.getCell(`B${row}`).border = fullBorder;
          sheet.getCell(`C${row}`).value = x.nominal_bayar;
          sheet.getCell(`C${row}`).border = fullBorder;
          sheet.getCell(`D${row}`).value = statusBillings[x.status];
          sheet.getCell(`D${row}`).border = fullBorder;
          row += 1;
        });
      }

      // keterangan
      row += 1;
      sheet.getCell(`A${row}`).value = "Keterangan";
      row += 1;
      sheet.getCell(`A${row}`).value =
        "1. Jika ada ketidak sesuaian data, silakan konfirmasi ke WA 085100734490 di hari dan jam kerja";
      row += 1;
      sheet.getCell(`A${row}`).value =
        "2. Data diatas berdasarkan rekap catatan maksimal sesuai tanggal rilis update";
      row += 1;
      sheet.getCell(`A${row}`).value =
        "3. Jika rekap diatas terdapat ketidak sesuaian data maka akan di revisi ulang";

      // kartu tagihan siswa
      sheet.mergeCells("H1:L1");
      sheet.getCell("H1").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      // height: 100,
      sheet.getRow(1).height = 100;
      // width: 100,
      sheet.getColumn(8).width = 20;
      sheet.getColumn(9).width = 20;
      sheet.getColumn(10).width = 20;
      sheet.getColumn(11).width = 20;
      sheet.getColumn(12).width = 20;
      sheet.addImage(imageId2, "H1:L1");

      sheet.mergeCells("H2:L2");
      sheet.getCell("H2").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell("H2").value = "KARTU TAGIHAN SISWA";

      sheet.mergeCells("H3:L3");
      sheet.getCell("H3").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell("H3").value = "SMA FUTURE GATE";

      sheet.mergeCells("H4:L4");
      sheet.getCell("H4").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell("H4").value = "TAHUN AJARAN 2023-2024";

      sheet.getCell("H6").value = "Nama";
      sheet.getCell("I6").value = data?.name;
      sheet.getCell("H7").value = "Kelas";
      sheet.getCell("I7").value = data?.class?.name;

      row = 8;

      dataRecaps.data?.data?.accounts.forEach((x, i) => {
        sheet.getCell(`H${row}`).value = `No VA ${x.type.toUpperCase()}`;
        sheet.getCell(`I${row}`).value = x.number;
        row += 1;
      });
      row += 1;

      if (dataRecaps.data?.data?.billings?.spp.length !== 0) {
        sheet.mergeCells(`H${row}:I${row}`);
        sheet.getCell(`H${row}`).value = "SPP";
        sheet.getCell(`H${row}`).border = fullBorder;
        sheet.getCell(`H${row}`).font = { bold: true };
        sheet.getCell(`J${row}`).value = "STATUS";
        sheet.getCell(`J${row}`).border = fullBorder;
        sheet.getCell(`J${row}`).font = { bold: true };
        sheet.getCell(`K${row}`).value = "NOMINAL";
        sheet.getCell(`K${row}`).border = fullBorder;
        sheet.getCell(`K${row}`).font = { bold: true };
        sheet.getCell(`L${row}`).value = "KETERANGAN";
        sheet.getCell(`L${row}`).border = fullBorder;
        sheet.getCell(`L${row}`).font = { bold: true };
        row += 1;

        dataRecaps.data?.data?.billings?.spp.forEach((x, i) => {
          sheet.mergeCells(`H${row}:I${row}`);
          sheet.getCell(`H${row}`).value = x.bulan;
          sheet.getCell(`H${row}`).border = fullBorder;
          sheet.getCell(`J${row}`).value = statusBillings[x.status];
          sheet.getCell(`J${row}`).border = fullBorder;
          sheet.getCell(`K${row}`).value = x.nominal_tagihan;
          sheet.getCell(`K${row}`).border = fullBorder;
          sheet.getCell(`L${row}`).value = x.keterangan;
          sheet.getCell(`L${row}`).border = fullBorder;
          row += 1;
        });
      }

      if (dataRecaps.data?.data?.billings?.bp.length !== 0) {
        sheet.mergeCells(`H${row}:I${row}`);
        sheet.getCell(`H${row}`).value = "JENIS TAGIHAN";
        sheet.getCell(`H${row}`).border = fullBorder;
        sheet.getCell(`H${row}`).font = { bold: true };
        sheet.getCell(`J${row}`).value = "STATUS";
        sheet.getCell(`J${row}`).border = fullBorder;
        sheet.getCell(`J${row}`).font = { bold: true };
        sheet.getCell(`K${row}`).value = "NOMINAL";
        sheet.getCell(`K${row}`).border = fullBorder;
        sheet.getCell(`K${row}`).font = { bold: true };
        sheet.getCell(`L${row}`).value = "KETERANGAN";
        sheet.getCell(`L${row}`).border = fullBorder;
        sheet.getCell(`L${row}`).font = { bold: true };
        row += 1;

        dataRecaps.data?.data?.billings?.bp.forEach((x, i) => {
          sheet.mergeCells(`H${row}:I${row}`);
          sheet.getCell(`H${row}`).value = "Bea Awal Pendidikan";
          sheet.getCell(`H${row}`).border = fullBorder;
          sheet.getCell(`H${row}`).font = { bold: true };
          sheet.getCell(`J${row}`).value = statusBillings[x.status];
          sheet.getCell(`J${row}`).border = fullBorder;
          sheet.getCell(`K${row}`).value = x.nominal_tagihan;
          sheet.getCell(`K${row}`).border = fullBorder;
          sheet.getCell(`L${row}`).value = x.keterangan;
          sheet.getCell(`L${row}`).border = fullBorder;
          row += 1;
        });

        dataRecaps.data?.data?.billings?.bwt.forEach((x, i) => {
          sheet.mergeCells(`H${row}:I${row}`);
          sheet.getCell(`H${row}`).value = "BWT";
          sheet.getCell(`H${row}`).border = fullBorder;
          sheet.getCell(`H${row}`).font = { bold: true };
          sheet.getCell(`J${row}`).value = x.status;
          sheet.getCell(`J${row}`).border = fullBorder;
          sheet.getCell(`K${row}`).value = x.nominal_tagihan;
          sheet.getCell(`K${row}`).border = fullBorder;
          sheet.getCell(`L${row}`).value = x.keterangan;
          sheet.getCell(`L${row}`).border = fullBorder;
          row += 1;
        });
      }

      row += 1;
      sheet.mergeCells(`H${row}:K${row}`);
      sheet.getCell(`H${row}`).value =
        "TOTAL TAGIHAN JATUH TEMPO BERDASARKAN UPDATE";
      sheet.getCell(`H${row}`).font = { bold: true };
      sheet.getCell(`H${row}`).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      sheet.getCell(`H${row}`).border = fullBorder;
      sheet.getCell(
        `L${row}`
      ).value = `${new Date().getDate()}-${new Date().toLocaleString(
        "default",
        { month: "short" }
      )}-${new Date().getFullYear()}`;
      sheet.getCell(`L${row}`).border = fullBorder;
      row += 1;
      sheet.mergeCells(`H${row}:K${row}`);
      sheet.getCell(`H${row}`).value = "Total SPP";
      sheet.getCell(`H${row}`).border = fullBorder;
      sheet.getCell(`L${row}`).value = dataRecaps.data?.data?.total_tagihan_spp;
      sheet.getCell(`L${row}`).border = fullBorder;
      row += 1;
      sheet.mergeCells(`H${row}:K${row}`);
      sheet.getCell(`H${row}`).value = "Total BP";
      sheet.getCell(`H${row}`).border = fullBorder;
      sheet.getCell(`L${row}`).value = dataRecaps.data?.data?.total_tagihan_bp;
      sheet.getCell(`L${row}`).border = fullBorder;
      row += 1;
      sheet.mergeCells(`H${row}:K${row}`);
      sheet.getCell(`H${row}`).value = "Total BWT";
      sheet.getCell(`H${row}`).border = fullBorder;
      sheet.getCell(`L${row}`).value = dataRecaps.data?.data?.total_tagihan_bwt;
      sheet.getCell(`L${row}`).border = fullBorder;
      row += 1;
      sheet.mergeCells(`H${row}:K${row}`);
      sheet.getCell(`H${row}`).value = "Total Tunggakan";
      sheet.getCell(`H${row}`).border = fullBorder;
      sheet.getCell(`L${row}`).value = dataRecaps.data?.data?.total_tunggakan;
      sheet.getCell(`L${row}`).border = fullBorder;
      row += 1;
      sheet.mergeCells(`H${row}:K${row}`);
      sheet.getCell(`H${row}`).value = "JUMLAH TOTAL TAGIHAN ";
      sheet.getCell(`H${row}`).font = { bold: true };
      sheet.getCell(`H${row}`).border = fullBorder;
      sheet.getCell(`L${row}`).value = dataRecaps.data?.data?.total;
      sheet.getCell(`L${row}`).border = fullBorder;

      workbook.xlsx.writeBuffer().then(function (dataFile) {
        const blob = new Blob([dataFile], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        const fileName = `Kartu Catatan Pembayaran ${data?.name || ""} ${
          data?.class?.name || ""
        } ${new Date().getFullYear()}`;
        anchor.download = `${fileName}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  // Buttons Permission start
  const btnExport = dataPermission?.find(
    (x) => x.id === "btnExportRecapsBillings"
  );
  const btnGenerateBrodcast = dataPermission?.find(
    (x) => x.id === "btnGenerateBrodcast"
  );

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      align: "left",
      fixed: "left",
      width: window.innerWidth > 800 ? 200 : 150,
    },
    {
      title: "Kelas",
      dataIndex: "class_name",
      align: "left",
      width: window.innerWidth > 800 ? 100 : 80,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (_, data) => {
        return (
          <Space>
            <Tag
              color="green"
              hidden={!btnExport}
              style={
                btnExport?.type === "disabled" || loadingExport
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnExport?.type !== "disabled") handleExportExcel(data);
              }}
              disabled={btnExport?.type === "disabled" || loadingExport}
              icon={<FileExcelOutlined />}
            >
              Export Excel
            </Tag>
            <Tag
              color="red"
              hidden={!btnExport}
              style={
                btnExport?.type === "disabled" || loadingExport
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                // window.open(`/finance/recapsbillings/${data.id}`, "_blank");
                handleGeneratePdf(data.id);
              }}
              disabled={btnExport?.type === "disabled" || loadingExport}
              icon={<FilePdfOutlined />}
            >
              Export PDF
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        index: i + 1,
        key: x.id,
        class_name: x.class?.name,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Rekap</h1>
        <Button
          type="primary"
          onClick={() => setShowModalGenerate(true)}
          hidden={!btnGenerateBrodcast}
          disabled={
            btnGenerateBrodcast && btnGenerateBrodcast.type === "disabled"
          }
        >
          Generate Broadcast
        </Button>
      </div>
      <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={handleChange}
          placeholder="Cari nama siswa"
        />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={{
          y: "50vh",
          x: 800,
        }}
      />
      <GenerateBrodcastModal
        show={showModalGenerate}
        onCancelModal={onCancel}
      />
    </div>
  );
};

export default RecapsBillings;
