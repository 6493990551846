import LayoutAcademic from "Layouts/LayoutAcademic/LayoutAcademic";
import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuAcademic from "components/Auth/RequireMenuAcademic";
import MasterAcademic from "pages/si-academic/MasterAcademic";
import CreateBukuNilai from "pages/si-academic/master-bukunilai/create/CreateBukuNilai";
import BukuNilai from "pages/si-academic/master-bukunilai/master/BukuNilai";
import DetailClass from "pages/si-academic/master-classes/detail/master/DetailClass";
import Classes from "pages/si-academic/master-classes/master/Classes";
import ProsemDetail from "pages/si-academic/master-curriculum/program-semester-detail/master/ProsemDetail";
import Prosem from "pages/si-academic/master-curriculum/program-semester/master/Prosem";
import Rpn from "pages/si-academic/master-curriculum/rpn/master/Rpn";
import Pelajaran from "pages/si-academic/master-pelajaran/master/Pelajaran";
import DetailStudent from "pages/si-academic/master-students/detail/DetailStudent";
import AddStudent from "pages/si-academic/master-students/dev/create/AddStudent";
import EditStudent from "pages/si-academic/master-students/dev/edit/EditStudent";
import Students from "pages/si-academic/master-students/master/Students";
import DetailTeachers from "pages/si-academic/master-teachers/detail/DetailTeachers";
import Teachers from "pages/si-academic/master-teachers/master/Teachers";
import DailyAttandance from "pages/si-academic/master-daily-attandence/master/DailyAttandance";
import RecapDaily from "pages/si-academic/mater-recap-daily/master/RecapDaily";
import RecapMasterDaily from "pages/si-academic/mater-recap-daily/recap/RecapMasterDaily";
import StudentsPerSubject from "pages/si-academic/master-per-subject/studentRecap/StudentsPerSubject";
import TeachersPerSubject from "pages/si-academic/master-per-subject/teachersRecap/TeachersPerSubject";
import TheachersPresenceSubject from "pages/si-academic/master-per-subject/teachers/TheachersPresenceSubject";
import AddPresencePerSubject from "pages/si-academic/master-per-subject/add/AddPresencePerSubject";
import ClassesAlumni from "pages/si-academic/master-alumni/alumni-classes/master/Classes";
import DetailClassAlumni from "pages/si-academic/master-alumni/alumni-classes/detail/master/DetailClass";
import StudentsAlumni from "pages/si-academic/master-alumni/alumni-list/master/Students";
import DetailStudentAlumni from "pages/si-academic/master-alumni/alumni-list/detail/DetailStudent";
import EditStudentAlumni from "pages/si-academic/master-alumni/alumni-list/dev/edit/EditStudent";
import DetailPelajaranEkskul from "pages/si-academic/master-pelajaran/detail/ekskul/DetailPelajaranEkskul";
import DetailBukuNilai from "pages/si-academic/master-bukunilai/detail/DetailBukuNilai";
import Agenda from "pages/si-academic/master-agenda/master/Agenda";
import DetailPresensiSiswa from "pages/si-academic/master-per-subject/detail/DetailPresensiSiswa";
import ChartRecapsDaily from "pages/si-academic/master-chart-recaps-daily/master/ChartRecapsDaily";
import FormJurnalMengajar from "pages/si-academic/form-jurnal-mengajar/FormJurnalMengajar";
import Raport from "pages/si-academic/master-raport-siswa/master/Raport";
import RaportPage from "pages/si-academic/master-raport-siswa/raport-print/RaportPage";
import ListRaport from "pages/si-academic/master-raport-siswa/master/ListRaport";

export const DataRoutesAcademic = [
  {
    path: "form-jurnal-mengajar",
    element: <FormJurnalMengajar />,
  },
  {
    path: "academic",
    element: (
      <RequireAuth>
        <RequireMenuAcademic>
          <LayoutAcademic content={<MasterAcademic />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/classes",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuClasses">
          <LayoutAcademic content={<Classes />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/classes/:class_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuClasses">
          <LayoutAcademic content={<DetailClass />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/students",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudents">
          <LayoutAcademic content={<Students />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/raportstudent",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuRaportStudent">
          <LayoutAcademic content={<Raport />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/raportstudent/:id_detail",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuRaportStudent">
          <LayoutAcademic content={<ListRaport />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/print-raport-siswa/:raport_id/:student_id",
    element: <RaportPage />,
  },
  {
    path: "academic/students/:student_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudents">
          <LayoutAcademic content={<DetailStudent />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/students/add",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudents">
          <LayoutAcademic content={<AddStudent />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/students/:student_id/edit",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudents">
          <LayoutAcademic content={<EditStudent />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/subjects",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuSubjects">
          <LayoutAcademic content={<Pelajaran />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/agenda",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuAgenda">
          <LayoutAcademic content={<Agenda />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/subjects/:subject_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuSubjects">
          <LayoutAcademic content={<DetailPelajaranEkskul />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/teachers",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuTeachers">
          <LayoutAcademic content={<Teachers />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/teachers/:teachers_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuTeachers">
          <LayoutAcademic content={<DetailTeachers />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/dailyAttandance",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuPresenceDaily">
          <LayoutAcademic content={<DailyAttandance />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/PresenceDaily",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuPresenceDaily">
          <LayoutAcademic content={<RecapDaily />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/recapsdaily",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuRecapsDaily">
          <LayoutAcademic content={<RecapMasterDaily />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/chartrecapdaily",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuChartRecapDaily">
          <LayoutAcademic content={<ChartRecapsDaily />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/addPresencePerSubject",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuPresencePerSubject">
          <LayoutAcademic content={<AddPresencePerSubject />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/studentPresencePerSubject",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudentPresencePerSubject">
          <LayoutAcademic content={<StudentsPerSubject />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/presencepersubject",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuPresencePerSubject">
          <LayoutAcademic content={<TheachersPresenceSubject />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/presencepersubject/:journal_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuPresencePerSubject">
          <LayoutAcademic content={<DetailPresensiSiswa />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/recapspersubject",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuRecapsPerSubject">
          <LayoutAcademic content={<TeachersPerSubject />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/prosem",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuProgramSemester">
          <LayoutAcademic content={<Prosem />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/prosem/:prosem_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuProgramSemester">
          <LayoutAcademic content={<ProsemDetail />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/rpn",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuRencanaPengambilanNilai">
          <LayoutAcademic content={<Rpn />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/bukunilai",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuBukuNilai">
          <LayoutAcademic content={<BukuNilai />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/bukunilai/:bukunilai_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuBukuNilai">
          <LayoutAcademic content={<DetailBukuNilai />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/bukunilai/create",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuBukuNilai">
          <LayoutAcademic content={<CreateBukuNilai />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/alumni",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudents">
          <LayoutAcademic content={<StudentsAlumni />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/alumni/:student_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudents">
          <LayoutAcademic content={<DetailStudentAlumni />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/alumni/:student_id/edit",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuStudents">
          <LayoutAcademic content={<EditStudentAlumni />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/alumniclass",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuClasses">
          <LayoutAcademic content={<ClassesAlumni />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
  {
    path: "academic/alumniclass/:class_id",
    element: (
      <RequireAuth>
        <RequireMenuAcademic menu="mnuClasses">
          <LayoutAcademic content={<DetailClassAlumni />} />
        </RequireMenuAcademic>
      </RequireAuth>
    ),
  },
];
