import { Divider, Table } from "antd";
import MasterProfileLogic from "./MasterProfileLogic";
import ResetPasswordProfile from "./ResetPasswordProfile";
import "./MasterProfile.css";

const MasterProfile = () => {
  const { columns, dataTable, showResetPasswordModal, onReset, onCancel } =
    MasterProfileLogic();

  return (
    <div>
      <Divider orientation="left" orientationMargin="0">
        Profil {dataTable[0]?.name}
      </Divider>
      <div className="profile__table__wrapper">
        <Table
          size="small"
          dataSource={dataTable}
          columns={columns}
          pagination={false}
        />
      </div>
      <ResetPasswordProfile
        show={showResetPasswordModal}
        onReset={onReset}
        onCancel={onCancel}
      />
    </div>
  );
};

export default MasterProfile;
