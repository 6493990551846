import { Form, Input, Radio, Select } from "antd";
import React from "react";

const TabDataAkademik = ({ newData }) => {
  return (
    <div className="double-form">
      <div>
        <Form.Item label="Unit" name="unit">
          <Select
            options={[
              { value: "putra", label: "Putra" },
              { value: "putri", label: "Putri" },
            ]}
            onChange={(a) => {
              newData.unit = a;
            }}
          />
        </Form.Item>

        <Form.Item label="Program" name="program">
          <Select
            options={[
              { value: "mahad", label: "Ma'had" },
              { value: "boarding", label: "Boarding" },
              { value: "fullday", label: "Fullday" },
              { value: "wisma", label: "Wisma" },
            ]}
            onChange={(a) => {
              newData.program = a;
            }}
          />
        </Form.Item>
        <Form.Item
          label="No Ujian Nasional"
          name="nat_exam_no"
          rules={[{ max: 30 }]}
        >
          <Input
            onChange={({ target: { value } }) => (newData.nat_exam_no = value)}
          />
        </Form.Item>

        <Form.Item label="No Seri Ijazah" name="junior_hs_cert_no">
          <Input
            onChange={({ target: { value } }) =>
              (newData.junior_hs_cert_no = value)
            }
          />
        </Form.Item>

        <Form.Item label="Asal Sekolah" name="junior_hs_name">
          <Input
            onChange={({ target: { value } }) =>
              (newData.junior_hs_name = value)
            }
          />
        </Form.Item>

        <Form.Item label="No Akta Lahir" name="birth_cert_no">
          <Input
            onChange={({ target: { value } }) =>
              (newData.birth_cert_no = value)
            }
          />
        </Form.Item>

        <Form.Item label="Penerima KPS" name="has_kps">
          <Radio.Group
            onChange={({ target: { value } }) => (newData.has_kps = value)}
          >
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="No KPS" name="kps_number">
          <Input
            onChange={({ target: { value } }) => (newData.kps_number = value)}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item label="Penerima KIP" name="has_kip">
          <Radio.Group
            onChange={({ target: { value } }) => (newData.has_kip = value)}
          >
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="No KIP" name="kip_number">
          <Input
            onChange={({ target: { value } }) => (newData.kip_number = value)}
          />
        </Form.Item>
        <Form.Item label="Nama di KIP" name="name_on_kip">
          <Radio.Group
            onChange={({ target: { value } }) => (newData.name_on_kip = value)}
          >
            <Radio value="true">Ada</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Penerima KKS" name="has_kks">
          <Radio.Group
            onChange={({ target: { value } }) => (newData.has_kks = value)}
          >
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="No KKS" name="kks_number">
          <Input
            onChange={({ target: { value } }) => (newData.kks_number = value)}
          />
        </Form.Item>

        <Form.Item label="Layak PIP" name="pip_eligible">
          <Radio.Group
            onChange={({ target: { value } }) => (newData.pip_eligible = value)}
          >
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Alasan PIP" name="pip_desc">
          <Input
            onChange={({ target: { value } }) => (newData.pip_desc = value)}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabDataAkademik;
