import { ClockLoader } from "react-spinners";

const Backdrop = ({ loading }) => {
  return (
    <div className="backdrop">
      <ClockLoader
        color="#1677ff"
        loading={loading}
        size={window.innerWidth > 1000 ? 200 : 100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Backdrop;
