import { Button, Select, Table, Popconfirm, Space, Tag, Tooltip } from "antd";
import CreateProsem from "../create/CreateProsem";
import EditProsem from "../edit/EditProsem";
import DuplikatProsem from "../duplikat/DuplikatProsem";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { usePelajaranList } from "hooks/pelajaran-hook/usePelajaranList";
import { useProsemPagination } from "hooks/prosem-hook/useProsemPagination";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Prosem = () => {
  const [itemProsem, setItemProsem] = useState({});
  const [dataId, setDataId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDuplikatModal, setShowDuplikatModal] = useState(false);
  const [fullData, setFullData] = useState();
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
  });
  const [foundationId, setFoundationId] = useState("");

  const roles = decryptCookies("role").split(",");

  const navigate = useNavigate();

  // get permission prosem
  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuProgramSemester"
  );

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // get teacher teaching
  const { data: dataTeaching, refetch: fetchTeaching } = useTeachingList(
    Cookies.get("teacher_id") ? decryptCookies("teacher_id") : ""
  );

  // get all subject
  const { data: dataSubject, refetch } = usePelajaranList();

  // get prosem pagination
  const {
    data: dataProsem,
    isLoading,
    isFetching,
    refetch: fetchProsem,
  } = useProsemPagination({
    dataTable,
    subjectId,
    foundationId,
  });

  useEffect(() => {
    if (
      roles.find(
        (x) =>
          x === "super_admin" ||
          x === "admin" ||
          x === "curriculum" ||
          x === "admin_academic"
      ) &&
      Cookies.get("teacher_id")
    ) {
      refetch();
      fetchTeaching();
    }
    // eslint-disable-next-line
  }, [refetch, fetchTeaching]);

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddProsem");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditProsem");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteProsem");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailProsem");
  const btnDuplikat = dataPermission?.find((x) => x.id === "btnDuplikatProsem");

  const dataSource = dataProsem?.data?.data.map((x, i) => {
    return {
      ...x,
      key: x.id,
      index: i + 1,
      mapel: x.mapel?.name,
      guru: x.teachers?.employee?.name,
      class: x.class?.name,
    };
  });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Kelas", dataIndex: "class", align: "left", width: 120 },
    { title: "Mata Pelajaran", dataIndex: "mapel", align: "left", width: 120 },
    { title: "Guru", dataIndex: "guru", align: "left" },
    {
      title: "Total Pertemuan",
      dataIndex: "total_pertemuan",
      align: "left",
      width: 120,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, record) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus data?"
              okText="Delete"
              cancelText="Batal"
              style={{ cursor: "pointer" }}
              onConfirm={() =>
                DeleteApi({
                  url: "/academics/program-semester/",
                  dataId,
                  refetch: fetchProsem,
                })
              }
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="red"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDelete?.type === "disabled") {
                    return;
                  } else {
                    setDataId(id);
                  }
                }}
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditModal(true);
                  setItemProsem(record);
                }
              }}
            >
              Ubah
            </Tag>

            <Tag
              color="cyan"
              style={
                btnDuplikat?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDuplikat?.type === "disabled") return;
                else {
                  setDataId(id);
                  setFullData(record);
                  setShowDuplikatModal(true);
                }
              }}
              hidden={!btnDuplikat}
            >
              Duplikat
            </Tag>

            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") return;
                else navigate(`${id}`);
              }}
            >
              Detail
            </Tag>
          </Space>
        );
      },
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: dataProsem?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable({
        current_page: curr,
        per_page: size,
      }),
  };

  const handleCancelModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowDuplikatModal(false);
  };

  const onCreate = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowCreateModal(false);
    fetchProsem();
  };

  const handleSubjectChange = (e) => {
    if (e === "-") setSubjectId("");
    else setSubjectId(e);
  };

  return (
    <>
      <div className="table-header">
        <h1>Daftar Prosem (program Semester)</h1>
        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => setShowCreateModal(true)}
        >
          Tambah Prosem
        </Button>
      </div>
      <div
        style={{
          maxWidth: 600,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: 10,
        }}
      >
        {dataSubject ? (
          <Select
            placeholder="Pilih Pelajaran"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleSubjectChange}
          >
            <Select.Option value="-">ALL</Select.Option>
            {dataSubject?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Select
            placeholder="Pilih Pelajaran"
            onChange={handleSubjectChange}
          >
            <Select.Option value="-">ALL</Select.Option>
            {dataTeaching?.data?.map((x) => (
              <Select.Option key={x.id} value={x.subject_id}>
                {x.subject?.name} - {x.class?.name}
              </Select.Option>
            ))}
          </Select>
        )}
        {roles.some((x) => x === "super_admin") &&
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => setFoundationId(x)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">ALL</Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>}
      </div>
      <Table
        size="small"
        tableLayout="auto"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? {
              y: "50vh",
              x: 800,
            }
            : {
              y: "55vh",
              x: 800,
            }
        }
      />

      <CreateProsem
        open={showCreateModal}
        onCancel={handleCancelModal}
        onCreate={onCreate}
      />
      <EditProsem
        data={itemProsem}
        id={dataId}
        open={showEditModal}
        onCancel={handleCancelModal}
        onUpdate={onCreate}
      />

      <DuplikatProsem
        id={dataId}
        fullData={fullData}
        open={showDuplikatModal}
        onCancel={handleCancelModal}
        onDuplikat={onCreate}
      />
    </>
  );
};

export default Prosem;
