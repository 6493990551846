import { Form, Input, Modal, Select, Skeleton } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

const EditGelombang = ({ open, setOpen, id }) => {
  const { REACT_APP_HOST_API } = process.env;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const getData = async () => {
    setIsLoad(true);
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/settings/batches/${id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      form.setFieldsValue({
        name: data.data.name,
        description: data.data.description,
        academic_year: data.data.academic_year,
      });
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    open && getData();
    // eslint-disable-next-line
  }, [open]);

  const handleSubmit = async () => {
    const val = await form.validateFields();

    setIsLoading(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/settings/batches/${id}`,
        val,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      form.resetFields();
      setOpen();
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      open={open}
      onCancel={setOpen}
      onOk={handleSubmit}
      okText="Ubah"
      cancelText="Batal"
      closable={false}
    >
      {isLoad ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item label="Nama" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Tahun Ajar" name="academic_year">
            <Select
              options={[
                {
                  value: "2021 - 2022",
                  label: "2021 - 2022",
                },
                {
                  value: "2022 - 2023",
                  label: "2022 - 2023",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Deskripsi" name="description">
            <Input />
          </Form.Item>
          {/* <Form.Item label="Aktif" name="active">
          <Radio.Group>
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item> */}
        </Form>
      )}
    </Modal>
  );
};

export default EditGelombang;
