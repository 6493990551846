import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAcademicDashboard = (foundationId) => {
  return useQuery(
    ["get-academic-dashboard", foundationId],
    () => GetList(`/academics/dashboard?foundationId=${foundationId}`),
    {
      enabled: true,
    }
  );
};
