import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useKecList } from "hooks/wilayah-hook/useKecList";
import { useKelList } from "hooks/wilayah-hook/useKelList";
import { useKotaList } from "hooks/wilayah-hook/useKotaList";
import { useProvinceList } from "hooks/wilayah-hook/useProvinceList";
import React, { useEffect, useState } from "react";

const TabAlamatSiswa = ({ newData, kdWilayah, form }) => {
  const [kdPro, setKdPro] = useState();
  const [kdKot, setKdKot] = useState();
  const [kdKec, setKdKec] = useState();

  const [isdisabledKot, setIsDisableKot] = useState(false);
  const [isdisabledKec, setIsDisableKec] = useState(false);
  const [isdisabledKel, setIsDisableKel] = useState(false);

  //* jika true wilayah tidak difetch ulang ke default atau bawaan props kdWilayah
  const [isWilayahSelect, setIsWilayahSelect] = useState(false);

  // fetch prov,kot,kec,kel list
  const { data: pro, refetch, isLoading: isLoadProv } = useProvinceList();
  const {
    data: kot,
    refetch: refetchKot,
    isLoading: isLoadKot,
  } = useKotaList(kdPro);
  const {
    data: kec,
    refetch: refetchKec,
    isLoading: isLoadKec,
  } = useKecList(kdKot);
  const {
    data: kel,
    refetch: refetchKel,
    isLoading: isLoadKel,
  } = useKelList(kdKec);

  useEffect(() => {
    //** agar kode wilayah ke trigger saat pertama di jalankan
    //** dan di select dapat menampilkan nama, bukan kode
    //** jika isWilayahSelect true maka logic tidak dijalankan dan,
    //** tidak dirender dengan kode dari props kdWilayah
    if (kdWilayah && isWilayahSelect === false) {
      setKdPro(kdWilayah.prov && kdWilayah.prov);
      setKdKot(kdWilayah.kot && kdWilayah.kot);
      setKdKec(kdWilayah.kec && kdWilayah.kec);
    }

    refetch();
    refetchKot();
    refetchKec();
    refetchKel();
    // console.log("Prov:", kdPro, "kot:", kdKot, "kec:", kdKec);

    // **agar eslint dikecualikan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kdPro, kdKot, kdKec, kot, kec, kel, pro]);

  return (
    <div className="double-form">
      <div>
        <Form.Item
          label="Jenis Tinggal"
          name="residence"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Select
            options={[
              {
                value: "with parent",
                label: "Dengan orang tua",
              },
              {
                value: "boarding school",
                label: "Pondok",
              },
              {
                value: "dormitory",
                label: "Asrama",
              },
              {
                value: "others",
                label: "Lainnya",
              },
            ]}
            onChange={(a) => {
              newData.residence = a;
            }}
          />
        </Form.Item>

        <Form.Item
          label="Alamat"
          name="address"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <TextArea
            rows={3}
            onChange={({ target: { value } }) => (newData.address = value)}
          />
        </Form.Item>

        <Form.Item
          label="Rt"
          name="rt"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              min: 3,
              message: "Minimal 3 karakter, contoh :  001 ",
            },
            {
              max: 3,
              message: "Maksimal 3 karakter",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Input onChange={({ target: { value } }) => (newData.rt = value)} />
        </Form.Item>

        <Form.Item
          label="Rw"
          name="rw"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              min: 3,
              message: "Minimal 3 karakter, contoh :  001 ",
            },
            {
              max: 3,
              message: "Maksimal 3 karakter",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Input onChange={({ target: { value } }) => (newData.rw = value)} />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Provinsi"
          name="prov"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Select
            disabled={isLoadProv}
            loading={isLoadProv}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              setKdPro(a);
              setIsWilayahSelect(true);
              newData.prov = a;
              newData.kot = null;
              newData.kec = null;
              newData.kel = null;

              setKdKot(null);
              setKdKec(null);

              setIsDisableKot(false);
              setIsDisableKel(true);
              setIsDisableKec(true);
              form.resetFields(["kot", "kec", "kel"]);
            }}
          >
            {pro?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Kota / Kabupaten"
          name="kot"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Select
            disabled={isLoadKot || isdisabledKot}
            loading={isLoadKot}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              setKdKot(a);
              setIsWilayahSelect(true);
              newData.kot = a;
              newData.kec = null;
              newData.kel = null;

              setKdKec(null);
              setIsDisableKec(false);
              setIsDisableKel(true);
              form.resetFields(["kec", "kel"]);
            }}
          >
            {kot?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Kecamatan"
          name="kec"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Select
            disabled={isLoadKec || isdisabledKec}
            loading={isLoadKec}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              setKdKec(a);
              setIsWilayahSelect(true);
              setIsDisableKel(false);

              newData.kec = a;
              newData.kel = null;
              form.resetFields(["kel"]);
            }}
          >
            {kec?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Kelurahan"
          name="kel"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Select
            disabled={isLoadKel || isdisabledKel}
            loading={isLoadKel}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(a) => {
              newData.kel = a;
              setIsWilayahSelect(true);
            }}
          >
            {kel?.data?.map((data) => (
              <Select.Option key={data.kode} value={data.kode}>
                {data.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Kode Pos"
          name="zip"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              min: 5,
              message: "Kode pos minimal 5 karakter",
            },
            {
              max: 5,
              message: "Maksimal 5 karakter",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Input onChange={({ target: { value } }) => (newData.zip = value)} />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabAlamatSiswa;
