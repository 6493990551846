import { CheckOutlined } from "@ant-design/icons";
import { Popconfirm, Space, Tag, Tooltip } from "antd";
import { DeleteApi } from "api/DeleteApi";
import dayjs from "dayjs";
import { useProsemDetailList } from "hooks/prosem-detail-hook/useProsemDetailList";
import { usePermission } from "hooks/usePermissions";
import { useState } from "react";
import { useParams } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const ProsemDetailLogic = () => {
  const [dataId, setDataId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { prosem_id } = useParams();

  // get permission prosem
  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuProgramSemester"
  );

  // get prosem detail
  const {
    data: dataProsem,
    isLoading,
    isFetching,
    refetch: fetchProsem,
  } = useProsemDetailList(prosem_id);

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddProsemDetail");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditProsemDetail");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteProsemDetail"
  );

  const dataSource = dataProsem?.data?.data
    .sort((a, b) => a.pertemuan - b.pertemuan)
    .sort((a, b) => a.kompetensi_dasar_index - b.kompetensi_dasar_index)
    .map((x, i) => {
      return {
        ...x,
        key: x.id,
        index: i + 1,
        tanggal_realisasi: x.teachingJournal?.date_in
          ? dayjs(x.teachingJournal?.date_in).format("DD MMMM YYYY")
          : "",
        kompetensi_dasar: `BAB ${x.kompetensi_dasar_index} - ${x.kompetensi_dasar}`,
        kompetensi_inti: x.kompetensiInti?.nama,
        kategori1: x.kategori1 === true ? <CheckOutlined /> : "",
        kategori2: x.kategori2 === true ? <CheckOutlined /> : "",
        kategori3: x.kategori3 === true ? <CheckOutlined /> : "",
      };
    });

  const columns = [
    {
      title: "Pertemuan Ke",
      dataIndex: "pertemuan",
      align: "center",
      width: 200,
    },
    { title: "KD", dataIndex: "kompetensi_dasar", width: 170 },
    { title: "KI", dataIndex: "kompetensi_inti" },
    { title: "Cakupan Materi", dataIndex: "materi", width: 170 },
    { title: "Tanggal Realisasi", dataIndex: "tanggal_realisasi", width: 170 },
    {
      title: "Klasifikasi Materi",
      children: [
        {
          title: (
            <Tooltip
              color="#108ee9"
              title="Penting untuk keselamatan dunia dan akhirat"
            >
              Kategori 1
            </Tooltip>
          ),
          dataIndex: "kategori1",
          align: "center",
          width: 160,
        },
        {
          title: (
            <Tooltip color="#108ee9" title="Penting sebagai bekal masa depan">
              Kategori 2
            </Tooltip>
          ),
          dataIndex: "kategori2",
          align: "center",
          width: 160,
        },
        {
          title: (
            <Tooltip
              color="#108ee9"
              title="Penting untuk melanjutkan jenjang berikutnya"
            >
              Kategori 3
            </Tooltip>
          ),
          dataIndex: "kategori3",
          align: "center",
          width: 160,
        },
        {
          title: "Metode",
          dataIndex: "metode",
          align: "center",
          width: 120,
        },
      ],
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus data?"
              okText="Delete"
              cancelText="Batal"
              style={{ cursor: "pointer" }}
              onConfirm={() =>
                DeleteApi({
                  url: "/academics/program-semester-detail/",
                  dataId,
                  refetch: fetchProsem,
                })
              }
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="red"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDelete?.type === "disabled") {
                    return;
                  } else {
                    setDataId(id);
                  }
                }}
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditModal(true);
                }
              }}
            >
              Ubah
            </Tag>
          </Space>
        );
      },
    },
  ];

  const onCancel = () => {
    setShowEditModal(false);
    setShowCreateModal(false);
  };

  const onCreate = () => {
    fetchProsem();
    onCancel();
  };

  return {
    dataId,
    btnAdd,
    columns,
    isLoading,
    isFetching,
    dataSource,
    dataProsem,
    showEditModal,
    showCreateModal,
    onCancel,
    onCreate,
    setShowCreateModal,
  };
};

export default ProsemDetailLogic;
