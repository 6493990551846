import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useStudentParent } from "hooks/finance-hook/useStudentParent";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ParentStudent() {
  const navigate = useNavigate();

  const paretnId = decryptCookies("parent_id");
  const { data } = useStudentParent(paretnId);

  return (
    <div>
      <div className="table-header">
        <Button
          onClick={() => navigate("/finance/parentstudent/add")}
          type="primary"
          style={{ width: "100%", marginTop: "10px" }}
        >
          Tambah Siswa
        </Button>
      </div>

      <div style={{ marginTop: "20px" }}>
        <h1 style={{ fontSize: "14px", color: "#808387" }}>
          Daftar Siswa ({data?.data?.length})
        </h1>
      </div>
      {/* card */}
      {data?.data?.length > 0 ? (
        <div>
          {data.data.map((item) => (
            <div
              key={item.nisn}
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                padding: "5px 10px",
                borderRadius: "10px",
                boxShadow: "1px 3px 4px 0px #0f71fa",
              }}
            >
              <div>
                <Avatar
                  style={{ backgroundColor: "#4096FF" }}
                  shape="square"
                  size="large"
                  icon={<UserOutlined />}
                />
              </div>
              <div>
                <h1
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "2px",
                    marginTop: "5px",
                    color: "#4096FF",
                  }}
                >
                  {item.name}
                </h1>
                <p style={{ fontSize: "12px", color: "#808387" }}>
                  {item.nisn}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <h1 style={{ fontSize: "14px", color: "#808387", marginTop: "20px" }}>
            Belum ada siswa yang terdaftar
          </h1>
        </>
      )}
    </div>
  );
}
