import { Button, Input, Popconfirm, Space, Table, Tag, Tooltip } from "antd";
import FilterFoundation from "components/UI/FilterFoundation";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ResetPasswordParent from "../reset/ResetPasswordParent";
import EditUserParent from "../edit/EditUserParent";
import AddUserParent from "../create/AddUserParent";
import { useUsersParentPagination } from "hooks/users-parent-hook/useUsersParentPagination";
import { DeleteApi } from "api/DeleteApi";

const disabled = { cursor: "not-allowed", color: "#ccc" };

export default function UsersParent() {
  const [foundationId, setFoundationId] = useState(null);
  const [showReset, setShowReset] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [dataParent, setDataParent] = useState({});
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const navigate = useNavigate();
  const roles = decryptCookies("role").split(",");

  const { data, isLoading, isFetching, refetch } = useUsersParentPagination(
    dataTable,
    keyword,
    foundationId
  );

  const handleShowReset = () => {
    setShowReset(!showReset);
    refetch();
  };
  const handleShowEdit = () => {
    setShowEdit(!showEdit);
    refetch();
  };
  const handleShowAdd = () => {
    setShowAdd(!showAdd);
    refetch();
  };

  // fetch permission
  const { data: dataPermission } = usePermission("mdlSystem", "mnuUsersParent");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddUsersParent");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditUsersParent");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteUsersParent"
  );
  const btnReset = dataPermission?.find(
    (x) => x.id === "btnResetPasswordUsersParent"
  );
  const btnDetial = dataPermission?.find(
    (x) => x.id === "btnDetailUsersParent"
  );

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Jumlah Anak",
      dataIndex: "student_count",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id, record) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus ?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() => {
              DeleteApi({ url: "/system/parents/", dataId: id, refetch });
            }}
            disabled={btnDelete?.type === "disabled"}
          >
            <Tag
              color="red"
              hidden={!btnDelete}
              style={
                btnDelete?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDelete?.type === "disabled") {
                  return;
                }
              }}
            >
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="red"
            style={
              btnReset?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            hidden={!btnReset}
            onClick={() => {
              if (btnReset?.type === "disabled") {
                return;
              } else {
                setDataParent(record);
                handleShowReset();
              }
            }}
          >
            Reset Password
          </Tag>
          <Tag
            color="orange"
            hidden={!btnEdit}
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnEdit?.type === "disabled") {
                return;
              } else {
                setDataParent(record);
                handleShowEdit();
              }
            }}
          >
            Edit
          </Tag>
          <Tag
            color="blue"
            hidden={!btnDetial}
            style={
              btnDetial?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnDetial?.type === "disabled") {
                return;
              } else {
                navigate(`${id}`);
              }
            }}
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ];

  const dataSource = data?.data?.data
    ?.slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data?.id,
        no: index + 1,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Orang Tua</h1>

        <Button
          type="primary"
          onClick={() => handleShowAdd()}
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
        >
          Tambah Orang Tua
        </Button>
      </div>

      <div className="filter-search">
        <Space>
          {roles.some((x) => x === "super_admin") && (
            <FilterFoundation setFoundationId={setFoundationId} />
          )}

          <Tooltip title="Cari Berdasarkan Nama">
            <Input
              prefix={<SearchOutlined />}
              placeholder="Cari berdasarkan nama ..."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </Tooltip>
        </Space>
      </div>

      <Table
        size="small"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
      />

      {/* modal */}
      <ResetPasswordParent
        open={showReset}
        setOpen={handleShowReset}
        data={dataParent}
      />
      <EditUserParent
        open={showEdit}
        setOpen={handleShowEdit}
        data={dataParent}
      />
      <AddUserParent open={showAdd} setOpen={handleShowAdd} />
    </div>
  );
}
