import { Button, Space, Tabs } from "antd";
import React, { useState } from "react";
import IdentitasSiswaTab from "./Tab/IdentitasSiswaTab";
import AlamatSiswaTab from "./Tab/AlamatSiswaTab";
import KontakSiswaTab from "./Tab/KontakSiswaTab";
import DataAkademikSiswaTab from "./Tab/DataAkademikSiswaTab";
import DataRekeningTab from "./Tab/DataRekeningTab";
import DataSiswaLainnyaTab from "./Tab/DataSiswaLainnyaTab";
import DataAyahTab from "./Tab/DataAyahTab";
import DataIbuTab from "./Tab/DataIbuTab";
import DataWaliTab from "./Tab/DataWaliTab";
import { useParams } from "react-router-dom";
import PendaftaranAwalTab from "./Tab/PendaftaranAwalTab";
import DokumenPembayaranTab from "./Tab/Dokumen-PembayaranTab";

const DetailPendaftaran = () => {
  const [activeKey, setActiveKey] = useState("1");
  const { candidate_id } = useParams();

  const selectTap = (key) => {
    setActiveKey(key.toString());
  };

  const items = [
    {
      key: "1",
      label: `Data Pendaftaran Awal`,
      children: <PendaftaranAwalTab />,
    },
    {
      key: "2",
      label: `Dokumen & Pembayaran`,
      children: <DokumenPembayaranTab />,
    },
    {
      key: "3",
      label: `Identitas`,
      children: <IdentitasSiswaTab candidateId={candidate_id} />,
    },
    {
      key: "4",
      label: `Alamat`,
      children: <AlamatSiswaTab candidateId={candidate_id} />,
    },
    {
      key: "5",
      label: `Kontak`,
      children: <KontakSiswaTab />,
    },
    {
      key: "6",
      label: `Data akademik`,
      children: <DataAkademikSiswaTab />,
    },
    {
      key: "7",
      label: `Data Rekening`,
      children: <DataRekeningTab />,
    },
    {
      key: "8",
      label: `Data lainnya`,
      children: <DataSiswaLainnyaTab />,
    },
    {
      key: "9",
      label: `Data ayah`,
      children: <DataAyahTab />,
    },
    {
      key: "10",
      label: `Data ibu`,
      children: <DataIbuTab />,
    },
    {
      key: "11",
      label: `Data wali`,
      children: <DataWaliTab />,
    },
  ];

  return (
    <div>
      <div className="table-header">
        <h1>Data Pendaftaran</h1>

        {/* navigasi */}
        <div className="navigasi-tab-detail">
          <Space>
            <Button
              hidden={activeKey === "1"}
              onClick={() => selectTap(parseInt(activeKey) - 1)}
            >
              Sebelumnya
            </Button>
            <Button
              type="primary"
              onClick={() => selectTap(parseInt(activeKey) + 1)}
              hidden={activeKey === "11"}
            >
              Selanjutnya
            </Button>
          </Space>
        </div>
      </div>

      <Tabs
        onTabClick={(a) => selectTap(a)}
        defaultActiveKey="1"
        items={items}
        activeKey={activeKey}
      />
    </div>
  );
};

export default DetailPendaftaran;
