import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useBudgetAllocationDetail = (id, enabled) => {
  return useQuery(
    ["get-budget-allocation-detail", id],
    () => GetDetail(`/finance/budget-allocation/${id}`),
    {
      enabled,
    }
  );
};
