import {
   InfoCircleTwoTone,
   BellOutlined,
} from "@ant-design/icons";
import {
   Dropdown,
   Badge,
   Divider,
   theme,
   Button,
   Empty,
   Skeleton,
   message,
} from "antd";
import {
   decryptCookies,
} from "components/Helper/CookiesHelper";
import { useNotificationsPagination } from "hooks/notifications-hook/useNotificationsPagination";
import axios from "axios";
import React, { useState } from "react";
import './Notifications.css'
import { useNavigate } from "react-router-dom";
const { useToken } = theme;


const Notifications = () => {
   const { REACT_APP_HOST_API } = process.env;
   const { token } = useToken();
   const contentStyle = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
   };
   const menuStyle = {
      boxShadow: "none",
   };
   const [dataTableNotif, setDataTableNotif] = useState({
      current_page: 1,
      per_page: 5,
      total: 0,
   });
   const [isUpdateData, setIsUpdateData] = useState(false);


   const navigate = useNavigate();
   const {
      data: dataNotif,
      isLoading,
      refetch,
   } = useNotificationsPagination(dataTableNotif);

   const updateNotif = async (id) => {
      setIsUpdateData(true);
      try {
         await axios.put(
            REACT_APP_HOST_API + `/notifications/${id}`,
            {
               read: true,
            },
            {
               headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
               },
            }
         );
         refetch();
      } catch (error) {
         let msg = error?.response?.data?.message || "Input Field Error";
         message.error(msg);
      }
      setIsUpdateData(false);
   };
   const updateNotifBatch = async (data) => {
      setIsUpdateData(true);
      try {
         await axios.put(
            REACT_APP_HOST_API + `/notification/batch`,
            { notifications: data },
            {
               headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
               },
            }
         );
         refetch();
      } catch (error) {
         let msg = error?.response?.data?.message || "Input Field Error";
         message.error(msg);
      }
      setIsUpdateData(false);
   };

   const handleClickNotifikasi = (e) => {
      const [key, value] = e.key.split("==");

      switch (value) {
         case "leave_daily":
            updateNotif(key);
            navigate(`/hrd/leavedaily`);
            break;
         case "leave_session":
            updateNotif(key);
            navigate(`/hrd/leavesession`);
            break;
         case "triwulan":
            updateNotif(key);
            navigate(`/hrd/triwulanreport`);
            break;
         case "monthly_report":
            updateNotif(key);
            navigate(`/hrd/monthlyreport`);
            break;
         default:
            return;
      }
   };

   const notifItem = dataNotif?.data?.data?.map((item) => {
      return {
         key: `${item.id}==${item.type}`,
         label: (
            <div
               style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
               }}
            >
               <span>
                  <InfoCircleTwoTone
                     style={{
                        fontSize: "20px",
                     }}
                  />
               </span>
               <div
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     gap: "5px",
                  }}
               >
                  <span style={{ lineHeight: "15px" }}>{item.description}</span>
                  <span
                     style={{
                        fontSize: "12px",
                        color: "#8c8c8c",
                     }}
                  >
                     {item.time_elapsed}
                  </span>
               </div>
            </div>
         ),
      };
   });

   const seeAllHandler = () => {
      setDataTableNotif({
         ...dataTableNotif,
         current_page: 1,
         per_page: 1000,
      });
   };

   return (
      <Dropdown
         menu={{
            items: notifItem,
            onClick: handleClickNotifikasi,
         }}
         placement="bottomRight"
         arrow
         trigger={["click"]}
         disabled={isLoading}
         dropdownRender={(menu) => (
            <div style={contentStyle}>
               <div
                  style={{
                     padding: "14px 20px",
                     display: "flex",
                     alignItems: "center",
                     gap: "7px",
                  }}
               >
                  <div>
                     <span
                        style={{
                           fontWeight: "bold",
                           fontSize: "16px",
                           lineHeight: "15px",
                        }}
                     >
                        Notifications
                     </span>
                  </div>
               </div>
               <Divider
                  style={{
                     margin: 0,
                  }}
               />
               <div
                  style={{
                     maxHeight: "300px",
                     overflowY: "auto",
                  }}
               >
                  {isLoading && <Skeleton active paragraph={{ rows: 5 }} />}
                  {!isLoading &&
                     React.cloneElement(menu, {
                        style: menuStyle,
                     })}
               </div>
               {!isLoading && notifItem.length === 0 && (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                     <Empty description="Tidak ada notifikasi" />
                  </div>
               )}
               <Divider
                  style={{
                     margin: 0,
                  }}
               />
               <div
                  style={{
                     padding: "14px 20px",
                     display: "flex",
                     alignItems: "center",
                     gap: "10px",
                     justifyContent: "space-between",
                  }}
               >
                  <span
                     className={`${isLoading || notifItem.length === 0 || isUpdateData ? 'marks-read-text-disabled' : 'marks-read-text'}`}
                     disabled={isLoading || notifItem.length === 0 || isUpdateData}
                     onClick={() => {
                        if (isLoading || notifItem.length === 0 || isUpdateData) return;
                        else {
                           let data = notifItem.map((item) => {
                              return {
                                 id: item.key.split("==")[0],
                                 read: true,
                              };
                           });
                           updateNotifBatch(data);
                        }
                     }}
                  >
                     Mark all as read
                  </span>
                  <Button
                     type="primary"
                     // size="small"
                     onClick={seeAllHandler}
                     disabled={isLoading || notifItem.length === 0 || isUpdateData}
                  >
                     See all
                  </Button>
               </div>
            </div>
         )}
      >
         <div
            style={{
               display: "flex",
               alignItems: "center",
               gap: "7px",
               backgroundColor: "#f5f6f9",
               cursor: "pointer",
               borderRadius: "50%",
               padding: "10px",
            }}
         >
            <Badge
               size="small"
               count={(!isLoading && dataNotif?.data?.meta.total) || 0}
            >
               <BellOutlined
                  style={{
                     fontSize: "18px",
                  }}
               />
            </Badge>
         </div>
      </Dropdown>
   )
}

export default Notifications