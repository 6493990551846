import { Form, InputNumber, Radio, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useAcademicYear } from "hooks/academic-year-hook/useAcademicYear";
import { useProsemDetail } from "hooks/prosem-hook/useProsemDetail";
import { useProsemFilter } from "hooks/prosem-hook/useProsemFilter";
import { useSemesterList } from "hooks/semester-hook/useSemesterList";
import { useStudentSubjectList } from "hooks/students-hook/useStudentSubjectList";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateBukuNilaiLogic = () => {
  const [form] = Form.useForm();
  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [prosemId, setProsemId] = useState("");
  const [loading, setLoading] = useState(false);
  const [valMateriProsem, setValMateriProsem] = useState("");
  const [teachingId, setTeachingId] = useState("");
  const [isEkskul, setIsEkskul] = useState(false);
  const [isGuruLogin, setIsGuruLogin] = useState(false);
  const [teacherId, setTeacherId] = useState("");
  const [aspek, setAspek] = useState("");
  const [tipe, setTipe] = useState("");

  const navigate = useNavigate();

  const { data: dataPermission } = usePermission("mdlAcademic", "mnuBukuNilai");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAdd");

  // * get semester list ---
  const { data: dataSemester, refetch: fetchSemester } = useSemesterList(true);

  //* get academic year ---
  const { data: dataAcademicYear, refetch: fetchAcademicYear } =
    useAcademicYear();

  //* get data guru ---
  const { data: dataGuru, refetch: fetchGuru } = useTeachersList();

  //* get prosem list ---
  const {
    data: dataProsem,
    // isLoading: isLoadProsem,
    refetch: fetchProsem,
  } = useProsemFilter({
    subjectId: subjectId ? subjectId : "",
    classId: classId ? classId : "",
    enabled: Boolean(subjectId && classId),
  });

  //* get prosem detail

  const { data: dataProsemDetail, refetch: fetchProsemDetail } =
    useProsemDetail(prosemId, false);

  //* get student list in teble ---

  const {
    data: dataStudentList,
    isLoading: isLoadStudentList,
    isFetching: isFetchStudentList,
    refetch: fetchStudentList,
  } = useStudentSubjectList({
    keyword: "",
    classId: classId ? classId : "",
    subjectId: !subjectId || !isEkskul ? "" : subjectId,
    enabled: false,
  });

  //*  get teacher teaching
  const {
    data: dataMengajar,
    isLoading: isLoadMengajar,
    refetch: fetchMengajar,
  } = useTeachingList(isGuruLogin ? decryptCookies("teacher_id") : teacherId);

  const roles = decryptCookies("role").split(",");

  // ambil classId ,SubjectId, isEkskul dari teachingId
  const filterTeachingId = () => {
    if (teachingId) {
      const filter = dataMengajar?.data?.filter((x) => x.id === teachingId);
      setClassId(filter[0]?.class_id);
      setSubjectId(filter[0]?.subject_id);
      setIsEkskul(filter[0]?.subject?.is_extracurricular);
    }
  };

  //! ---
  const [dataKey, setDataKey] = useState([]);

  const [valInputNumber, setValInputNumber] = useState(0);
  const [isDefault, setIsDefault] = useState(false);

  // handle in here ---

  const onIsEkskulTrue = () => {
    form.resetFields([
      "aspekPenilaian",
      "type",
      "prosemId",
      "prosemDetailId",
      "material",
    ]);
    setProsemId("");
    setAspek("");
    setTipe("");
  };

  const onClassChange = () => {
    form.resetFields(["prosemId", "prosemDetailId"]);
    setProsemId("");
  };

  const onGuruChange = (id) => {
    setTeacherId(id);
    form.resetFields(["classId", "prosemId", "prosemDetailId"]);
    setProsemId("");
  };

  const onProsemChange = (id) => {
    setProsemId(id);
    form.resetFields(["prosemDetailId"]);
  };

  const handleOpenDefault = () => {
    setIsDefault(!isDefault);
  };

  const handleSubmitDefault = () => {
    setIsDefault(false);

    const updatedDataSource = dataKey.map((student) => ({
      ...student,
      nilai: valInputNumber,
    }));

    setDataKey(updatedDataSource);
  };

  const handleNilaiChange = (id, value) => {
    const updatedDataSource = dataKey.map((student) => {
      if (student.key === id) {
        return { ...student, nilai: value };
      }
      return student;
    });

    setDataKey(updatedDataSource);
  };

  useEffect(() => {
    if ((subjectId && classId) || isEkskul) {
      fetchStudentList();
    }
  }, [subjectId, classId, isEkskul, fetchStudentList]);

  useEffect(() => {
    if (dataStudentList?.data?.data) {
      const dataSource = dataStudentList?.data?.data
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((x, i) => {
          return {
            ...x,
            key: x.id,
            nilai: null,
            no: i + 1,
          };
        });
      setDataKey(dataSource);
    }
  }, [dataStudentList]);

  //! ---

  useEffect(() => {
    fetchSemester();
    fetchAcademicYear();
    form.setFieldsValue({
      semesterId: dataSemester?.data[0]?.id,
      academicYearId: dataAcademicYear?.data?.data[0]?.id,
    });

    // eslint-disable-next-line
  }, [dataSemester, dataAcademicYear]);

  useEffect(() => {
    if (
      roles.find(
        (x) => x === "admin" || x === "super_admin" || x === "admin_academic"
      )
    ) {
      if (prosemId) fetchProsemDetail();
      fetchGuru();
    }
    if (Cookies.get("teacher_id")) {
      setIsGuruLogin(true);
      setTeacherId(decryptCookies("teacher_id"));
    }
    // eslint-disable-next-line
  }, [classId, prosemId, fetchProsemDetail]);

  useEffect(() => {
    if (isGuruLogin || teacherId) {
      if (prosemId) fetchProsemDetail();
      fetchMengajar();
    }
    // eslint-disable-next-line
  }, [teacherId, classId, prosemId, fetchProsemDetail, fetchMengajar]);

  useEffect(() => {
    if (
      dataProsem &&
      dataProsem.data &&
      dataProsem.data.length > 0 &&
      isGuruLogin
    ) {
      form.setFieldsValue({
        prosemId: dataProsem.data[0].id,
      });
      setProsemId(dataProsem.data[0].id);
    }
    // eslint-disable-next-line
  }, [dataProsem, isGuruLogin]);

  useEffect(() => {
    if (teachingId) {
      filterTeachingId();
      onClassChange();
      if (subjectId && classId) {
        fetchProsem();
      }
    }
    // eslint-disable-next-line
  }, [teachingId]);

  useEffect(() => {
    if (isEkskul) {
      onIsEkskulTrue();
    }
    //eslint-disable-next-line
  }, [isEkskul]);

  const columns = [
    { title: "No", dataIndex: "no", width: 50 },
    {
      title: "Kelas",
      dataIndex: "class",
      width: 100,
      render: (text, record) => <div>{record?.class?.name}</div>,
    },
    { title: "NIS", dataIndex: "nis", width: 100 },
    { title: "Nama", dataIndex: "name", width: 150 },

    {
      title: "Nilai",
      dataIndex: "nilai",
      key: "nilai",
      align: "right",
      width: 200,
      render: (text, record) => {
        return isEkskul ? (
          <Radio.Group
            buttonStyle="solid"
            value={text}
            onChange={(e) => handleNilaiChange(record.id, e.target.value)}
          >
            <Radio.Button value="A">A</Radio.Button>
            <Radio.Button value="B">B</Radio.Button>
            <Radio.Button value="C">C</Radio.Button>
          </Radio.Group>
        ) : aspek !== "SIKAP" ? (
          <InputNumber
            type="number"
            size="small"
            onChange={(value) => handleNilaiChange(record.id, value)}
            value={text}
            min={0}
            max={100}
            required
          />
        ) : (
          <Radio.Group
            buttonStyle="solid"
            value={text}
            onChange={(e) => handleNilaiChange(record.id, e.target.value)}
          >
            <Radio.Button value="B">B</Radio.Button>
            <Radio.Button value="SB">SB</Radio.Button>
          </Radio.Group>
        );
      },
    },
  ];

  const { REACT_APP_HOST_API } = process.env;

  const handleSubmit = async () => {
    const values = await form.validateFields();

    const invalidItems = dataKey
      .filter((item) => !item.nilai)
      .map((item) => item.name);

    if (invalidItems.length > 0) {
      const invalidMessage = `Nilai harus diisi untuk: ${invalidItems.join(
        ", "
      )}`;
      alert(invalidMessage);
      return;
    }

    setLoading(true);

    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/academics/buku-nilai`,
        {
          bukuNilai: dataKey
            .filter((x) => x.nilai)
            .map((x) => {
              const nilai =
                aspek !== "SIKAP"
                  ? { nilai: x.nilai }
                  : { nilaiSikap: x.nilai };

              return isEkskul
                ? {
                    studentId: x.id,
                    subjectId: subjectId,
                    teacherId: Cookies.get("teacher_id")
                      ? decryptCookies("teacher_id")
                      : teacherId,
                    semesterId: values.semesterId,
                    academicYearId: values.academicYearId,
                    tanggalPengambilanNilai: dayjs(
                      values.tanggalPengambilanNilai
                    ).format("YYYY-MM-DD"),
                    nilaiEkskul: x.nilai,
                  }
                : {
                    classId,
                    subjectId: subjectId,
                    teacherId: Cookies.get("teacher_id")
                      ? decryptCookies("teacher_id")
                      : teacherId,
                    ...nilai,
                    studentId: x.id,
                    type: values.type,
                    programSemesterDetailId: values.prosemDetailId
                      ? values.prosemDetailId
                      : null,
                    material: values.material,
                    academicYearId: values.academicYearId,
                    semesterId: values.semesterId,
                    aspekPenilaian: values.aspekPenilaian,
                    tanggalPengambilanNilai: dayjs(
                      values.tanggalPengambilanNilai
                    ).format("YYYY-MM-DD"),
                  };
            }),
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      form.resetFields();
      navigate(-1);
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message);
      } else {
        message.error("Terjadi Kesalahan!");
      }

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    btnAdd,
    onGuruChange,
    onProsemChange,
    tipe,
    setTipe,
    isEkskul,
    teacherId,
    dataAcademicYear,
    dataSemester,
    isLoadMengajar,
    isGuruLogin,
    setTeacherId,
    dataGuru,
    isLoadStudentList,
    isFetchStudentList,
    valMateriProsem,
    setValMateriProsem,
    handleOpenDefault,
    isDefault,
    valInputNumber,
    setValInputNumber,
    handleSubmitDefault,
    dataKey,
    setTeachingId,
    setAspek,
    aspek,
    form,
    columns,
    loading,
    dataMengajar,
    dataProsem,
    dataProsemDetail,
    setClassId,
    handleSubmit,
    setProsemId,
  };
};

export default CreateBukuNilaiLogic;
