import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useTeachingsDetail = (id, enabled, teacherId) => {
  return useQuery(
    ["get-teachings-detail", id],
    () => GetDetail(`/academics/teachers/${teacherId}/teachings/${id}`),
    {
      enabled,
    }
  );
};
