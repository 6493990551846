import { Form, Modal, Select } from "antd";
import AddTeachersLogic from "./addTeachersLogic";

const AddTeachers = (props) => {
  const {
    form,
    handleSubmit,
    onCancelModal,
    posting,
    dataEmployee,
    dataTeacher,
  } = AddTeachersLogic(props);

  const filteredDataEmployee = dataEmployee?.data.filter((employee) => {
    return !dataTeacher?.data.some(
      (teacher) => teacher.employee.id === employee.id
    );
  });

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: posting }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="employeeId"
          label="Nama Guru"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {filteredDataEmployee?.map((employee) => (
              <Select.Option key={employee.id} value={employee.id}>
                {employee.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* 
        <Form.Item
          label="Total Mengajar"
          name="totalMengajar"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AddTeachers;
