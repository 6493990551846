import LayoutPpdb from "Layouts/LayoutPpdb/LayoutPpdb";
import LoginPpdb from "components/Auth/Auth-Ppdb/LoginPpdb";
import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuPpdb from "components/Auth/RequireMenuPpdb";
import MasterPpdb from "pages/si-ppdb/MasterPpdb";
import Jadwal from "pages/si-ppdb/master-jadwal/master/Jadwal";
import AddPendaftaran from "pages/si-ppdb/master-pendaftaran/create/AddPendaftaran";
import DetailPendaftaran from "pages/si-ppdb/master-pendaftaran/detail/DetailPendaftaran";
import EditDaftarUlang from "pages/si-ppdb/master-pendaftaran/edit/EditDaftarUlang";
import EditPendaftaranAwal from "pages/si-ppdb/master-pendaftaran/edit/pendaftaran-awal/EditPendaftaranAwal";
import Pendaftaran from "pages/si-ppdb/master-pendaftaran/master/Pendaftaran";
import Profile from "pages/si-ppdb/master-profile/Profile";
import Setting from "pages/si-ppdb/master-setting/master/Setting";

export const DataRoutesPpdb = [
  {
    path: "ppdb",
    element: (
      // <RequireAuth>
      <LoginPpdb />
      // </RequireAuth>
    ),
  },
  {
    path: "ppdb/dashboard",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<MasterPpdb />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    path: "ppdb/pendaftaran",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<Pendaftaran />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    // add pendaftaran awal
    path: "ppdb/pendaftaran/add",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<AddPendaftaran />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    // edit pendaftaran awal
    path: "ppdb/pendaftaran/edit/:candidate_id",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<EditPendaftaranAwal />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    // add daftar ulang NOTE: menggunakan method PUT
    path: "ppdb/daftarulang/:candidate_id",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<EditDaftarUlang />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    // lihat detail pendaftar
    path: "ppdb/pendaftaran/:candidate_id",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<DetailPendaftaran />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    path: "ppdb/profile",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<Profile />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    path: "ppdb/jadwal",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<Jadwal />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
  {
    path: "ppdb/setting",
    element: (
      <RequireAuth>
        <RequireMenuPpdb>
          <LayoutPpdb content={<Setting />} />
        </RequireMenuPpdb>
      </RequireAuth>
    ),
  },
];
