import { Form, Input, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useState } from "react";

export default function ResetPasswordParent({ open, setOpen, data }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  const { id } = data?.user || {};

  const handleCancel = () => {
    form.resetFields();
    setOpen();
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setIsLoading(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/system/users/${id}`,
        { ...values },
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Data Berhasil Ditambah");
      handleCancel();
    } catch (error) {
      console.log(error);
      alert("Gagal reset password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      open={open}
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={isLoading}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Password Baru"
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Konfirmasi Password"
          name="password_confirmation"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password harus sama"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}
