import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   page: {
      flexDirection: 'row',
      backgroundColor: '#ffff',
      padding: 30,
      gap: 10
   },
   section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
   },
   textTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 5,
      width: '100%'
   },
   table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 10
   },
   tableRow: {
      margin: "auto",
      flexDirection: "row"
   },
   tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
   },
   tableCol50: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
   },
   tableCol75: {
      width: "75%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
   },
   tableCol100: {
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
   },
   tableCellHeader: {
      margin: "auto",
      fontWeight: "bold",
      fontSize: 10,
      padding: 5,
   },
   tableCell: {
      paddingLeft: 5,
      paddingRight: 5,
      marginTop: 5,
      fontSize: 9,
   },
   keteranganWrapper: {
      marginTop: 10,
   },
   textKeterangan: {
      fontSize: 8,
      marginTop: 3
   },
   textCenter: {
      textAlign: 'center'
   },
   textLeft: {
      textAlign: 'left'
   },
   textRight: {
      textAlign: 'right'
   },
});

function PdfExportRevenue({ data, title }) {
   return (
      <Document>
         <Page style={styles.page} size="A4">
            <View style={styles.section}>
               <View>
                  <Text style={styles.textTitle}>{title}</Text>
               </View>
               {
                  data?.map((item, index) => {
                     return (
                        <View key={index} style={styles.table}>
                           <View style={styles.tableRow}>
                              <View style={styles.tableCol100}>
                                 <Text style={[styles.tableCell, styles.tableCellHeader]}>{item.month}</Text>
                              </View>
                           </View>
                           <View style={styles.tableRow}>
                              <View style={styles.tableCol}>
                                 <Text style={[styles.tableCell, styles.tableCellHeader]}>Tanggal</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={[styles.tableCell, styles.tableCellHeader]}>No Rekening</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={[styles.tableCell, styles.tableCellHeader]}>Nama rekening</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={[styles.tableCell, styles.tableCellHeader]}>Jumlah</Text>
                              </View>
                           </View>
                           {
                              item.transactions.map((transaction, index) => {
                                 return (
                                    <View key={index} style={styles.tableRow}>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{transaction.tanggal}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{transaction.nomorRekening}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>{transaction.namaRekening}</Text>
                                       </View>
                                       <View style={styles.tableCol}>
                                          <Text style={[styles.tableCell, styles.textRight]}>{transaction.jumlah}</Text>
                                       </View>
                                    </View>
                                 )
                              })
                           }
                           <View style={styles.tableRow}>
                              <View style={styles.tableCol75}>
                                 <Text style={[styles.tableCell, styles.textRight]}>Sub total</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={[styles.tableCell, styles.textRight]}>{item.subtotal}</Text>
                              </View>
                           </View>
                        </View>
                     )
                  })
               }
               {/* grand total */}
               <View style={styles.table}>
                  <View style={styles.tableRow}>
                     <View style={styles.tableCol75}>
                        <Text style={[styles.tableCell, styles.textRight]}>Grand total</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, styles.textRight]}>
                           {
                              data?.reduce((prev, curr) => {
                                 return prev + curr.subtotal
                              }, 0)
                           }
                        </Text>
                     </View>
                  </View>
               </View>
            </View>
         </Page>
      </Document>
   )
}

export default PdfExportRevenue