import React, { useEffect, useRef, useState } from "react";
import "./LoginPpdb.css";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import { LockTwoTone, MailTwoTone } from "@ant-design/icons";
import RegisterPpdb from "./RegisterPpdb";
import fgIcon from "../../../assets/img/logoFG.png";
import axios from "axios";
import { encryptCookies } from "components/Helper/CookiesHelper";

const LoginPpdb = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [openReg, setOpenReg] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  const [userNoRegis, setUserNoRegis] = useState("");
  const [loading, setLoading] = useState(false);

  // function handleCallbackResponse(response) {
  //   console.log("Encode JWT ID token -->: ", response.credential);
  //   let userObject = jwt_decode(response.credential);

  //   console.log(userObject);
  // }

  const openRegister = () => {
    setOpenReg(!openReg);
  };

  const handleLogin = async () => {
    const values = await form.validateFields();
    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/ppdb/auth/login",
        values
      );

      if (data.data[0].verified) {
        encryptCookies("token", data.token.token);
        encryptCookies("login", "true");
        encryptCookies("email", data.data[0].email);
        encryptCookies("role", data.data[0].role_name);
        encryptCookies("name", data.name);
        encryptCookies("select_button_scan", "true");

        message.success("login successful");
        navigate("/home", { replace: true });
      } else {
        alert("Email belum terverifikasi!");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        alert("Harap periksa kembali email atau password anda");
        return;
      }
      if (error.message.includes("timeout")) {
        alert("Waktu permintaan habis, silahkan coba lagi");
      }
      if (error.response.status === 422) {
        alert("Harap periksa kembali email atau password anda");
      } else {
        alert(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCallbackResponse = async (res) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/ppdb/auth/google",
        {
          cred: res.credential,
        }
      );

      if (data.data.verified) {
        encryptCookies("token", data.token.token);
        encryptCookies("login", "true");
        encryptCookies("email", data.data.email);
        encryptCookies("role", data.data.role_name);
        encryptCookies("name", data.name);
        encryptCookies("select_button_scan", "true");

        message.success("login successful");
        navigate("/home", { replace: true });
      } else {
        alert("Email belum terverifikasi!");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        message.warning(
          // error.response.data.message,
          "anda belum memiliki akun, silahkan registrasi terlebih dahulu"
        );
        openRegister();
        // encryptCookies("email", error.response.data.email);
        setUserNoRegis(error.response.data.email);
      } else {
        alert(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const divRef = useRef(null);
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    // google global
    if (divRef.current) {
      setIsFetch(true);
      window.google?.accounts?.id?.initialize({
        client_id:
          "228017211154-8rqo834ljj5frf2q3rcrfqb2c3j567ft.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      window.google?.accounts?.id?.renderButton(
        document.getElementById("signInDiv"),
        { theme: "filled_blue", size: "large", type: "standard" }
      );
    }

    // eslint-disable-next-line
  }, [divRef.current, isFetch]);

  // useEffect(() => {
  //   // google global
  //   window.google?.accounts?.id?.initialize({
  //     client_id:
  //       "228017211154-8rqo834ljj5frf2q3rcrfqb2c3j567ft.apps.googleusercontent.com",
  //     callback: handleCallbackResponse,
  //   });

  //   window.google?.accounts?.id?.renderButton(
  //     document.getElementById("signInDiv"),
  //     { theme: "outline", size: "large" }
  //   );
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="login-ppdb-wrapper">
      <div className="container-login-ppdb">
        <div className="login-ppdb-card">
          <div className="title-login">
            <img src={fgIcon} alt="" />
            <h3 className="ppdb">PPDB</h3>
            <h3 className="school">SMA Future Gate</h3>
          </div>
          <div className="content__wrapper-form">
            <Form form={form} layout="vertical">
              <Form.Item
                label="Email"
                name="email"
                className="border-only-bottom"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "please enter a valid email" },
                ]}
              >
                <Input prefix={<MailTwoTone />} type="email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "please enter password" },
                  { min: 6 },
                ]}
                className="border-only-bottom"
              >
                <Input prefix={<LockTwoTone />} type="password" />
              </Form.Item>

              <Button
                loading={loading}
                className="btn-login"
                type="primary"
                onClick={handleLogin}
              >
                Log in
              </Button>

              {/* register */}
              <div className="register-login">
                <p>
                  Belum punya akun?{" "}
                  <span onClick={openRegister}> Registrasi</span>
                </p>
              </div>

              {/* google signIn */}
              <br />
              <div className="google-account">
                <p>---- or ----</p>
                <div id="signInDiv" ref={divRef}></div>
              </div>
            </Form>

            <RegisterPpdb
              open={openReg}
              setOpen={setOpenReg}
              emailReg={userNoRegis}
            />
          </div>
        </div>
        <footer className="footer-ppdb-login">
          <img src={fgIcon} alt="sma fg" />
          <p>power by @smafg.sch.id</p>
        </footer>
      </div>
    </div>
  );
};

export default LoginPpdb;
