import React from "react";
import styles from "./ParentBilling.module.css";
import { Button, Col, Row } from "antd";
import { usePDF } from "react-to-pdf";
import { useDownloadTagihan } from "hooks/finance-hook/useDownloadTagihan";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import useConvertImgToBase64 from "components/Helper/useConvertImgToBase64";
export default function DownloadTagihan() {
  const parentId = decryptCookies("parent_id");
  const { data } = useDownloadTagihan(parentId);

  const imageUrl = data?.data?.foundation?.logo;

  const imgBase64 = useConvertImgToBase64(imageUrl);

  const { toPDF, targetRef } = usePDF({
    filename: `Kartu Pembiayaan.pdf`,
  });
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Button
          type="primary"
          onClick={toPDF}
          size="large"
          style={{ float: "right" }}
        >
          Download
        </Button>
        <div ref={targetRef}>
          {/* header--- */}
          <header className={styles.header}>
            <div className={styles.logo}>
              {/* <h1>logo</h1> */}
              <img src={imgBase64} alt="logo" />
            </div>

            <div className={styles.text}>
              <h1>{data?.data?.foundation?.name}</h1>
              <p>
                {/* Jl. Yudhistira, Komplek Pemda-Kampung Bulak, 03/03, Jatiasih,
                Bekasi */}
                {data?.data?.foundation?.address}
              </p>
              <p>
                {/* cs@smafg.sch.id +62 851 0073 4490 smafuturegate.com */}
                {data?.data?.foundation?.email} {data?.data?.foundation?.phone}{" "}
                {data?.data?.foundation?.website}
              </p>
            </div>
          </header>
          <span className={styles.lineHead}></span>
          {/* end header--- */}

          {/* body--- */}
          <div className={styles.body}>
            <div
              style={{
                border: "1px solid #bbb",
                padding: "20px 0px 10px 0px",
              }}
            >
              <div className={styles.title}>
                <h1>Kartu Pembiayaan Siswa</h1>
                <span>
                  {data?.data?.date_start} - {data?.data?.date_end}
                </span>
              </div>

              <div>
                <Row className={styles.identity}>
                  <Col>
                    <Row>
                      <Col style={{ marginRight: "7px" }}>
                        <h1>Nama</h1>
                        <h1>Angkatan</h1>
                        <h1>Kelas</h1>
                      </Col>
                      <Col>
                        <h1>: {data?.data?.student?.name}</h1>
                        <h1>: -</h1>
                        <h1>: {data?.data?.student?.kelas}</h1>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row>
                      <Col style={{ marginRight: "7px" }}>
                        <h1>NISN</h1>
                        <h1>No. VA</h1>
                        <h1>Kategori</h1>
                      </Col>
                      <Col>
                        <h1>: {data?.data?.student?.nisn}</h1>
                        <h1>: {data?.data?.student?.no_va}</h1>
                        <h1>: -</h1>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div>
              <Row className={styles.content}>
                {/* left side */}
                <Col>
                  <table style={{ width: "300px" }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left", paddingLeft: "5px" }}>
                          Biaya (Tagihan){" "}
                        </th>
                        <th style={{ textAlign: "left", paddingLeft: "5px" }}>
                          (Rp)
                        </th>
                        <th style={{ textAlign: "left", paddingLeft: "5px" }}>
                          Tanggal
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.billingReport.map((x, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              fontWeight:
                                x.name === "Total Tunggakan"
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {x.name}
                          </td>

                          <td>{renderAmount(x?.value)}</td>
                          <td>{x.due_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* <h2>Terhitung pada tanggal 5 Juli 2023</h2> */}
                  {/* <h2>{data?.data?.status}</h2> */}
                </Col>

                {/* right side */}
                <Col>
                  <table style={{ width: "300px" }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left", paddingLeft: "5px" }}>
                          Bayar (Tercatat){" "}
                        </th>
                        <th style={{ textAlign: "left", paddingLeft: "5px" }}>
                          (Rp)
                        </th>
                        {/* <th style={{ textAlign: "left", paddingLeft: "5px" }}>
                          Tanggal
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.revenueReport.map((x, index) => (
                        <tr key={index}>
                          <td>{x.name}</td>
                          <td>{renderAmount(x?.value)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* <h2>{renderAmount(data?.data?.remainder)}</h2> */}
                </Col>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <h2>{data?.data?.status}</h2>
                  <h2>{renderAmount(data?.data?.remainder)}</h2>
                </div>
              </Row>
            </div>

            <div className={styles.note}>
              <p>*Tertunggak per {data?.data?.date_end}</p>
              <p>Pertanyaan bisa diajukan via WA: 0851 0073 4490</p>
            </div>
          </div>
        </div>

        {/* end body--- */}
      </div>
    </div>
  );
}
