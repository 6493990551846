import { Skeleton, Table, Tag } from "antd";
import { useTeachersPerSubjectDetail } from "hooks/attandance-hook/useTeachersPerSubjectDetail";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function DetailPresensiSiswa() {
  const { journal_id } = useParams();

  const {
    data,
    isLoading,
    refetch: fetchData,
  } = useTeachersPerSubjectDetail(journal_id, false);

  useEffect(() => {
    if (journal_id) {
      fetchData();
      //   console.log(data);
    }
    // eslint-disable-next-line
  }, [journal_id, fetchData]);

  const statusMap = {
    present: { text: "Hadir", color: "green" },
    sick: { text: "Sakit", color: "orange" },
    permission: { text: "Izin", color: "purple" },
    absent: { text: "Alpa", color: "red" },
  };

  const getStatusTag = (status) => {
    const { text, color } = statusMap[status] || {
      text: status,
      color: "gray",
    };
    return <Tag color={color}>{text}</Tag>;
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 60 : 50,
    },
    // {
    //   title: "Waktu",
    //   dataIndex: "waktu",
    //   align: "left",
    //   //   hidden: isRecap === true,
    // },
    {
      title: "Nama",
      dataIndex: "id",
      key: "id",
      align: "left",
      render: (id, data) => {
        return data?.student?.name;
      },
    },
    {
      title: "Kelas",
      dataIndex: "className",
      key: "class",
      align: "left",
    },
    {
      title: "Pelajaran",
      dataIndex: "subjectName",
      key: "subjectName",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "",
      align: "left",
      render: (status) => getStatusTag(status),
    },

    {
      title: "Deskripsi",
      dataIndex: "description",
      align: "left",
    },
  ];

  const dataSource = data?.data?.student.map((item, index) => {
    const dataJurnal = data?.data?.data;

    return {
      ...item,
      key: item.id,
      subjectName: dataJurnal?.subject?.name,
      index: index + 1,
      className: item?.student?.class?.name,
    };
  });

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="table-header">
            <h1>Daftar Presensi Siswa</h1>
          </div>
          <Table dataSource={dataSource} columns={columns} />
        </>
      )}
    </div>
  );
}
