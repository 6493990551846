import { Button, Form, Popconfirm, Skeleton, Space, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import TabIdentitasSiswa from "./Tab/TabIdentitasSiswa";
import TabAlamatSiswa from "./Tab/TabAlamatSiswa";
import TabKontakSiswa from "./Tab/TabKontakSiswa";
import TabDataAkademik from "./Tab/TabDataAkademik";
import TabRekening from "./Tab/TabRekening";
import TabDataLainnya from "./Tab/TabDataLainnya";
import TabDataAyah from "./Tab/TabDataAyah";
import TabDataIbu from "./Tab/TabDataIbu";
import TabDataWali from "./Tab/TabDataWali";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { decryptCookies } from "components/Helper/CookiesHelper";
import axios from "axios";
import { useParentList } from "hooks/parents-hook/useParentList";

const EditStudent = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { student_id } = useParams();
  const [newData, setNewData] = useState({});
  const [newDataParent, setnewDataParent] = useState({});

  const [isLoadDetail, setIsLoadDetial] = useState(false);
  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  // fetch student detail
  const { data, refetch: fetchStudentDetail } = useParentList(
    student_id,
    false
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let kdWilayah = {
    prov: data?.data.prov,
    kot: data?.data.kot,
    kec: data?.data.kec,
  };

  // filtered parent
  const filteredFather = data?.data?.parents?.filter(
    (obj) => obj.relationship_w_student === "biological father"
  );
  const filteredMother = data?.data?.parents?.filter(
    (obj) => obj.relationship_w_student === "biological mother"
  );
  const filteredGuardian = data?.data?.parents?.filter(
    (obj) => obj.relationship_w_student === "guardian"
  );

  const getData = async () => {
    setIsLoadDetial(true);
    await fetchStudentDetail();
    if (data)
      form.setFieldsValue({
        // identitas siswa
        foundationId: data.data.foundation_id,
        name: data.data.name,
        class_id: data.data.class_id,
        nik: data.data.nik,
        nis: data.data.nis,
        nisn: data.data.nisn,
        gender: data.data.gender,
        birth_city: data.data.birth_city,
        birth_day: data.data.birth_day && dayjs(moment(data.data.birth_day)),
        religion: data.data.religion,
        isGraduated: data?.data?.is_graduated,
        institution: data?.data?.institution,
        department: data?.data?.department,
        // alamat siswa
        address: data.data.address,
        rt: data.data.rt,
        rw: data.data.rw,
        prov: data.data.prov && data.data.prov,
        kot: data.data.kot && data.data.kot,
        kec: data.data.kec && data.data.kec,
        kel: data.data.kel && data.data.kel,
        zip: data.data.zip,
        residence: data.data.residence,
        // kontak siswa
        phone: data.data.phone,
        mobile_phone: data.data.mobile_phone,
        email: data.data.email,
        //data akademik siswa
        nat_exam_no: data.data.nat_exam_no,
        junior_hs_cert_no: data.data.junior_hs_cert_no,
        junior_hs_name: data.data.junior_hs_name,
        birth_cert_no: data.data.birth_cert_no,
        has_kps:
          data.data.has_kps === null
            ? ""
            : data.data.has_kps
            ? "true"
            : "false",
        kps_number: data.data.kps_number,
        has_kip:
          data.data.has_kip === null
            ? ""
            : data.data.has_kip
            ? "true"
            : "false",
        kip_number: data.data.kip_number,
        name_on_kip:
          data.data.name_on_kip === null
            ? ""
            : data.data.name_on_kip
            ? "true"
            : "false",
        has_kks:
          data.data.has_kks === null
            ? ""
            : data.data.has_kks
            ? "true"
            : "false",
        kks_number: data.data.kks_number,
        pip_eligible:
          data.data.pip_eligible === null
            ? ""
            : data.data.pip_eligible
            ? "true"
            : "false",
        pip_desc: data.data.pip_desc,
        // data rekening
        bank_name: data.data.bank_name,
        bank_account_owner: data.data.bank_account_owner,
        bank_account_number: data.data.bank_account_number,
        // data siswa lainnya
        special_needs: data.data.special_needs,
        child_no: data.data.child_no,
        siblings: data.data.siblings,
        family_card_no: data.data.family_card_no,
        weight: data.data.weight,
        height: data.data.height,
        head_circumference: data.data.head_circumference,
        address_lat: data.data.address_lat,
        address_long: data.data.address_long,
        distance_to_school_in_km: data.data.distance_to_school_in_km,
        transportation: data.data.transportation,
        unit: data.data.unit,
        program: data.data.program,
        // data ayah
        father0_0name:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].name
            : null,
        father0_0nik:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].nik
            : null,
        father0_0birth_date:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].birth_date &&
              dayjs(moment(filteredFather[0].birth_date))
            : null,
        father0_0education:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].education
            : null,
        father0_0occupation:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].occupation
            : null,
        father0_0min_salary:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].min_salary
            : null,
        father0_0max_salary:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].max_salary
            : null,
        father0_0address:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].address
            : null,
        father0_0phone_number:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].phone_number
            : null,
        father0_0email:
          data.data.parents.length !== 0 && filteredFather.length !== 0
            ? filteredFather[0].email
            : null,

        // data ibu
        mother0_0name:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].name
            : null,
        mother0_0nik:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].nik
            : null,
        mother0_0birth_date:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].birth_date &&
              dayjs(moment(filteredMother[0].birth_date))
            : null,
        mother0_0education:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].education
            : null,
        mother0_0occupation:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].occupation
            : null,
        mother0_0min_salary:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].min_salary
            : null,
        mother0_0max_salary:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].max_salary
            : null,
        mother0_0address:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].address
            : null,
        mother0_0phone_number:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].phone_number
            : null,
        mother0_0email:
          data.data.parents.length !== 0 && filteredMother.length !== 0
            ? filteredMother[0].email
            : null,

        // data wali
        guardian0_0name:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].name
            : null,
        guardian0_0nik:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].nik
            : null,
        guardian0_0birth_date:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].birth_date &&
              dayjs(moment(filteredGuardian[0].birth_date))
            : null,
        guardian0_0education:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].education
            : null,
        guardian0_0occupation:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].occupation
            : null,
        guardian0_0min_salary:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].min_salary
            : null,
        guardian0_0max_salary:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].max_salary
            : null,
        guardian0_0address:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].address
            : null,
        guardian0_0phone_number:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].phone_number
            : null,
        guardian0_0email:
          data.data.parents.length !== 0 && filteredGuardian.length !== 0
            ? filteredGuardian[0].email
            : null,
      });
    setIsLoadDetial(false);
  };

  useEffect(() => {
    getData();
    setNewData({});
    setnewDataParent({});

    // **agar eslint dikecualikan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = async () => {
    await form.validateFields();
    try {
      if (
        Object.keys(newData).length === 0 &&
        Object.keys(newDataParent).length === 0
      ) {
        alert("Nothing has changed");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }

      for (const key in newDataParent) {
        if (Object.hasOwnProperty.call(newDataParent, key)) {
          const element = newDataParent[key];
          if (element === "") newDataParent[key] = null;
        }
      }

      const newVal = {
        ...newData,
        birth_day: newData.birth_day && newData.birth_day.format("YYYY-MM-DD"),
      };

      setLoading(true);
      try {
        if (Object.keys(newData).length !== 0) {
          const { data } = await axios.put(
            REACT_APP_HOST_API + `/academics/students/${student_id}`,
            newVal,
            {
              headers: { Authorization: "Bearer " + decryptCookies("token") },
            }
          );
          message.success(data.message);
        }

        //! cek apakah parent sudah memiliki id, jika belum maka jalankan POST

        // ** UPDATE PARENT & CEK APAKAH PARENT SUDAH MEMILIKI ID
        const objStudent = {
          ...newDataParent,
          father0_0birth_date:
            newDataParent.father0_0birth_date &&
            newDataParent.father0_0birth_date.format("YYYY-MM-DD"),
          mother0_0birth_date:
            newDataParent.mother0_0birth_date &&
            newDataParent.mother0_0birth_date.format("YYYY-MM-DD"),
          guardian0_0birth_date:
            newDataParent.guardian0_0birth_date &&
            newDataParent.guardian0_0birth_date.format("YYYY-MM-DD"),
        };
        const guardian = {
          relationship_w_student: "guardian",
        };

        const father = {
          relationship_w_student: "biological father",
        };
        const mother = {
          relationship_w_student: "biological mother",
        };

        for (let key in objStudent) {
          const [identifier, kunci] = key.split("0_0");
          if (identifier === "guardian") {
            guardian[kunci] = objStudent[key];
          } else if (identifier === "father") {
            father[kunci] = objStudent[key];
          } else if (identifier === "mother") {
            mother[kunci] = objStudent[key];
          }
        }

        // edit ayah
        const validAyah = [
          father.name,
          father.nik,
          father.birth_date,
          father.education,
          father.occupation,
          father.min_salary,
          father.max_salary,
          father.address,
          father.phone_number,
          father.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidFather = validAyah.some(
          (value) => value !== undefined && value !== ""
        );
        if (Object.keys(newDataParent).length !== 0 && isValidFather) {
          console.log("tidak kosong");

          //* jika father  memiliki id maka edit
          if (data.data.parents.length !== 0 && filteredFather.length !== 0) {
            // eslint-disable-next-line
            const { data: dataFather } = await axios.put(
              REACT_APP_HOST_API + `/academics/parents/${filteredFather[0].id}`,
              father,
              {
                headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
                },
              }
            );
            message.success("data ayah diubah");
          }

          //* jika father tidak memiliki id maka add
          else {
            try {
              // eslint-disable-next-line
              const { data: dataFather } = await axios.post(
                REACT_APP_HOST_API +
                  `/academics/students/${student_id}/parents`,
                father,
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                  },
                }
              );
              message.success("data ayah berhasil ditambah");
            } catch (error) {
              message.warning(
                "data ayah gagal ditambah, periksa apakah nik dan nama sudah terisi dengan benar"
              );
            }
          }
        }

        // edit ibu
        const validIbu = [
          mother.name,
          mother.nik,
          mother.birth_date,
          mother.education,
          mother.occupation,
          mother.min_salary,
          mother.max_salary,
          mother.address,
          mother.phone_number,
          mother.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidMother = validIbu.some(
          (value) => value !== undefined && value !== ""
        );

        if (Object.keys(newDataParent).length !== 0 && isValidMother) {
          if (data.data.parents.length !== 0 && filteredMother.length !== 0) {
            // eslint-disable-next-line
            const { data: dataMother } = await axios.put(
              REACT_APP_HOST_API + `/academics/parents/${filteredMother[0].id}`,
              mother,
              {
                headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
                },
              }
            );
            message.success("data ibu diubah");
          } else {
            try {
              // eslint-disable-next-line
              const { data: dataMother } = await axios.post(
                REACT_APP_HOST_API +
                  `/academics/students/${student_id}/parents`,
                mother,
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                  },
                }
              );
              message.success("data ibu berhasil ditambah");
            } catch (error) {
              message.warning(
                "data ibu gagal ditambah, periksa apakah nik dan nama sudah terisi dengan benar"
              );
            }
          }
        }

        // edit wali
        const validWali = [
          guardian.name,
          guardian.nik,
          guardian.birth_date,
          guardian.education,
          guardian.occupation,
          guardian.min_salary,
          guardian.max_salary,
          guardian.address,
          guardian.phone_number,
          guardian.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidGuardian = validWali.some(
          (value) => value !== undefined && value !== ""
        );

        if (Object.keys(newDataParent).length !== 0 && isValidGuardian) {
          if (data.data.parents.length !== 0 && filteredGuardian.length !== 0) {
            // eslint-disable-next-line
            const { data: dataGuardian } = await axios.put(
              REACT_APP_HOST_API +
                `/academics/parents/${filteredGuardian[0].id}`,
              guardian,
              {
                headers: {
                  Authorization: "Bearer " + decryptCookies("token"),
                },
              }
            );
            message.success("data wali diubah");
          } else {
            try {
              // eslint-disable-next-line
              const { data: dataGuardian } = await axios.post(
                REACT_APP_HOST_API +
                  `/academics/students/${student_id}/parents`,
                guardian,
                {
                  headers: {
                    Authorization: "Bearer " + decryptCookies("token"),
                  },
                }
              );
              message.success("data wali berhasil ditambah");
            } catch (error) {
              message.warning(
                "data wali gagal ditambah, periksa apakah nik dan nama sudah terisi dengan benar"
              );
            }
          }
        }
        form.resetFields();
        navigate("/academic/alumni");
      } catch (error) {
        // alert(error.message);
        alert(
          error?.response?.data?.data
            .map(
              (item) =>
                `( ${item.field} ) : ${
                  item.message === "unique validation failure"
                    ? "sudah digunakan didata lain !"
                    : item.message
                }`
            )
            .join("\n")
        );
      }
      setLoading(false);
    } catch (error) {
      alert("Fields Error");
    }
  };

  const selectTap = async (key) => {
    try {
      await form.validateFields();
      setActiveKey(key.toString());
    } catch (error) {
      alert("Input yang wajib diisi belum terpenuhi");
    }
  };

  // ---
  const handleNext = async () => {
    try {
      await form.validateFields();
      selectTap(parseInt(activeKey) + 1);
    } catch (error) {
      alert("Input yang wajib diisi belum terpenuhi");
    }
  };

  const items = [
    {
      key: "1",
      label: `Identitas`,
      children: <TabIdentitasSiswa newData={newData} />,
    },
    {
      key: "2",
      label: `Alamat`,
      children: (
        <TabAlamatSiswa newData={newData} kdWilayah={kdWilayah} form={form} />
      ),
    },
    {
      key: "3",
      label: `Kontak`,
      children: <TabKontakSiswa newData={newData} />,
    },
    {
      key: "4",
      label: `Data akademik`,
      children: <TabDataAkademik newData={newData} />,
    },
    {
      key: "5",
      label: `Data Rekening`,
      children: <TabRekening newData={newData} />,
    },
    {
      key: "6",
      label: `Data lainnya`,
      children: <TabDataLainnya newData={newData} />,
    },
    {
      key: "7",
      label: `Data ayah`,
      children: <TabDataAyah newDataParent={newDataParent} />,
    },
    {
      key: "8",
      label: `Data ibu`,
      children: <TabDataIbu newDataParent={newDataParent} />,
    },
    {
      key: "9",
      label: `Data wali`,
      children: <TabDataWali newDataParent={newDataParent} />,
    },
  ];

  return (
    <div>
      <div className="table-header">
        <h1>Edit Alumni</h1>

        {/* navigasi */}
        <Space>
          <Button
            hidden={activeKey === "1"}
            onClick={() => selectTap(parseInt(activeKey) - 1)}
          >
            Sebelumnya
          </Button>
          <Button
            type="primary"
            onClick={handleNext}
            hidden={activeKey === "9"}
          >
            Selanjutnya
          </Button>
          <Popconfirm
            key="3"
            okText="Simpan"
            cancelText="Batal"
            title="Anda yakin ingin menyimpannya ?"
            onConfirm={handleSubmit}
          >
            <Button loading={loading} type="primary" hidden={activeKey !== "9"}>
              Simpan
            </Button>
          </Popconfirm>
        </Space>
      </div>
      {isLoadDetail ? (
        <Skeleton active />
      ) : (
        <>
          <Form form={form} layout="vertical">
            <Tabs
              onTabClick={(a) => selectTap(a)}
              defaultActiveKey="1"
              items={items}
              activeKey={activeKey}
            />
          </Form>
        </>
      )}
    </div>
  );
};

export default EditStudent;
