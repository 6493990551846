import { Button, Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useEmployeeDetail } from "hooks/employee-hook/useEmployeeDetail";
import { useEmployeeTypeList } from "hooks/employee-hook/useEmployeeTypeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useKecList } from "hooks/wilayah-hook/useKecList";
import { useKelList } from "hooks/wilayah-hook/useKelList";
import { useKotaList } from "hooks/wilayah-hook/useKotaList";
import { useProvinceList } from "hooks/wilayah-hook/useProvinceList";
import moment from "moment";
import { useEffect, useState } from "react";

const EditEmployeeLogic = (props) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const [kodeProv, setKodeProv] = useState("");
  const [kodeKota, setKodeKota] = useState("");
  const [kodeKec, setKodeKec] = useState("");
  const [kotaDisabled, setKotaDisabled] = useState(true);
  const [kecDisabled, setKecDisabled] = useState(true);
  const [kelDisabled, setKelDisabled] = useState(true);

  const [idEmployee, setIdEmployee] = useState("");
  const [idDivision, setIdDivision] = useState("");
  const [showAddDivision, setShowAddDivision] = useState(false);
  const [showEditDivision, setShowEditDivision] = useState(false);

  const { REACT_APP_HOST_API } = process.env;
  const { id, show, onUpdate, onCancel } = props;

  // fetch detail employee
  const { data, isLoading: isFetching, refetch } = useEmployeeDetail(id, false);

  // fetch employee type list
  const { data: dataEmployeeType, refetch: fetchEmployeeType } =
    useEmployeeTypeList();

  // get provinsi list
  const { data: dataProvinsi, refetch: fetchProvinsi } = useProvinceList();

  // get Kota List
  const { data: dataKota, refetch: fetchKota } = useKotaList(kodeProv);

  // get Kecamatan List
  const { data: dataKec, refetch: fetchKec } = useKecList(kodeKota);

  // get Kelurahan List
  const { data: dataKel, refetch: fetchKel } = useKelList(kodeKec);

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  useEffect(() => {
    if (show) {
      refetch();
      fetchEmployeeType();
      fetchProvinsi();
    }

    if (data) {
      if (data.data[0].kode_provinsi) {
        setKodeProv(data.data[0].kode_provinsi);
        setKotaDisabled(false);
      }
      if (data.data[0].kode_kota) {
        setKodeKota(data.data[0].kode_kota);
        setKecDisabled(false);
      }
      if (data.data[0].kode_kecamatan) {
        setKodeKec(data.data[0].kode_kecamatan);
        setKelDisabled(false);
      }
      setStatus(data.data[0].status);

      form.setFieldsValue({
        nip: data.data[0].nip,
        defaultPresence: data.data[0].default_presence,
        status: data.data[0].status,
        name: data.data[0].name,
        birthCity: data.data[0].birth_city,
        birthDay: dayjs(moment(data.data[0].birth_day)),
        gender: data.data[0].gender,
        address: data.data[0].address,
        employeeTypeId: data.data[0].employee_type_id,
        nuptk: data.data[0].nuptk,
        dateIn: dayjs(moment(data.data[0].date_in)),
        dateOut: data.data[0].date_out
          ? dayjs(moment(data.data[0].date_out))
          : "",
        rfid: data.data[0].rfid ? data.data[0].rfid : "",
        kodeProvinsi: data.data[0].kode_provinsi,
        kodeKota: data.data[0].kode_kota,
        kodeKecamatan: data.data[0].kode_kecamatan,
        kodeKelurahan: data.data[0].kode_kelurahan,
        nik: data.data[0].nik,
        lastEducationName: data.data[0].last_education_name,
        lastEducationMajor: data.data[0].last_education_major,
        lastEducationGraduate: data.data[0].last_education_graduate
          ? dayjs(moment(data.data[0].last_education_graduate))
          : "",
        foundationId: data.data[0].foundation_id,
        hasBpjsHealth: data.data[0].has_bpjs_health,
        hasBpjsEmployment: data.data[0].has_bpjs_employment,
      });
    }

    return () => {
      setNewData({});
      setKodeProv("");
      setKotaDisabled(true);
      setKecDisabled(true);
      setKelDisabled(true);
    };
  }, [form, data, show, refetch, fetchEmployeeType, fetchProvinsi]);

  useEffect(() => {
    if (kodeProv) fetchKota();
  }, [kodeProv, fetchKota]);

  useEffect(() => {
    if (kodeKota) fetchKec();
  }, [kodeKota, fetchKec]);

  useEffect(() => {
    if (kodeKec) fetchKel();
  }, [kodeKec, fetchKel]);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak Ada yang dirubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        let element = newData[key];
        if (element === "" || element === "---") newData[key] = null;
      }
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + "/employee/" + id,
        newData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      onUpdate();
      form.resetFields();
      setKodeProv("");
      message.success(data.message);
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    onCancel();
    form.resetFields();
    setKodeProv("");
  };

  const modalFooter = [
    <Button
      key="1"
      onClick={onCancelModal}
      style={{ borderColor: "royalblue", color: "royalblue" }}
    >
      Batal
    </Button>,
    <Button key="2" type="primary" onClick={onSubmit} loading={loading}>
      Simpan
    </Button>,
  ];

  const handleProvinsiChange = (e) => {
    if (e === "---") {
      setKodeProv("");
      newData.kodeProvinsi = null;
      newData.kodeKota = null;
      newData.kodeKecamatan = null;
      newData.kodeKelurahan = null;
      setKotaDisabled(true);
      setKecDisabled(true);
      setKelDisabled(true);
    } else {
      setKodeProv(e);
      newData.kodeProvinsi = e;
      setKotaDisabled(false);
      setKecDisabled(true);
      setKelDisabled(true);
    }

    form.resetFields(["kodeKota", "kodeKecamatan", "kodeKelurahan"]);
  };

  const handleKotaChange = (e) => {
    setKodeKota(e);
    newData.kodeKota = e;
    setKecDisabled(false);
    setKelDisabled(true);
    form.resetFields(["kodeKecamatan", "kodeKelurahan"]);
  };

  const handleKecamatanChange = (e) => {
    setKodeKec(e);
    newData.kodeKecamatan = e;
    setKelDisabled(false);
    form.resetFields(["kodeKelurahan"]);
  };

  const handleDeleteDivision = async (value) => {
    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API +
          `/employee/${value.employee_id}/divisions/${value.division_id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      refetch();
    } catch (error) {
      alert(error.message);
    }
  };

  const onClickTag = (value, data) => {
    if (value.key === "delete") handleDeleteDivision(data);
    else {
      setShowEditDivision(true);
      setIdEmployee(data.employee_id);
      setIdDivision(data.division_id);
    }
  };

  const onCreateDivision = () => {
    setShowAddDivision(false);
    setShowEditDivision(false);
    refetch();
  };

  const onCancelDivision = () => {
    setShowAddDivision(false);
    setShowEditDivision(false);
  };

  const handleTitleDivision = (value) => {
    if (value === "lead") return "Ketua";
    if (value === "vice") return "Wakil";
    else return "Anggota";
  };

  return {
    data,
    form,
    newData,
    dataKec,
    dataKel,
    dataKota,
    kodeProv,
    idEmployee,
    idDivision,
    isFetching,
    kecDisabled,
    kelDisabled,
    modalFooter,
    dataProvinsi,
    kotaDisabled,
    showAddDivision,
    showEditDivision,
    dataEmployeeType,
    dataFoundation,
    status,
    setStatus,
    onClickTag,
    onCancelModal,
    setIdEmployee,
    handleKotaChange,
    onCreateDivision,
    onCancelDivision,
    setShowAddDivision,
    handleProvinsiChange,
    handleKecamatanChange,
    handleTitleDivision,
  };
};

export default EditEmployeeLogic;
