import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useActivityListDashboard = (unitId) => {
  return useQuery(
    ["get-activity-list-dashboard", unitId || ""],
    () => GetList(`/activity-list?activity_type=fixed_time&unitId=${unitId}`),
    { enabled: false }
  );
};
