import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useClassList } from "hooks/classes-hook/useClassList";
import { usePelajaranFilter } from "hooks/pelajaran-hook/useGetPelajaranFilter";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AddTeachingsLogic = ({ onCreate, onCancel, show }) => {
  const { teachers_id: id } = useParams();
  const [posting, setPosting] = useState(false);
  const [valueClass, setValueClass] = useState("");
  const [valuePelajaran, setValuePelajaran] = useState("");
  const [isEkskul, setIsEkskul] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  // fetch class list
  const { data: dataClass, refetch: fetchClass } = useClassList(false);

  // fetch pelajaran list
  const { data: dataPelajaran, refetch: fetchPelajaran } = usePelajaranFilter(
    valueClass,
    id
  );

  useEffect(() => {
    if (show) fetchClass();
    if (show) fetchPelajaran();
  }, [show, fetchClass, fetchPelajaran]);

  useEffect(() => {
    if (valueClass !== "") fetchPelajaran();
  }, [valueClass, fetchPelajaran]);

  useEffect(() => {
    if (valuePelajaran !== "") {
      checkEkskul();
    }
    form.resetFields(["classId"]);
    // eslint-disable-next-line
  }, [isEkskul, valuePelajaran]);

  // cek apakah merupakan pelajaran ekskul
  const checkEkskul = () => {
    const res = dataPelajaran?.data
      .filter((item) => item.id === valuePelajaran)
      .map((item) => item.is_extracurricular);

    setIsEkskul(res[0]);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (fieldsValue) => {
        setPosting(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + `/academics/teachers/${id}/teachings`,
            { ...fieldsValue, teacherId: id },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          message.success(data.message);
          form.resetFields();
          setValueClass("");
          onCreate();
        } catch (error) {
          let message = error.message;
          if (error.response.data.errors[0].rule === "unique") {
            message = "Data sudah ada";
          }
          alert(message);
        }

        setPosting(false);
      })
      .catch(() => {
        alert("Input Field Error");
      });
  };

  const onCancelModal = () => {
    form.resetFields();
    setValueClass("");
    onCancel();
  };

  return {
    form,
    handleSubmit,
    onCancelModal,
    posting,
    dataClass,
    dataPelajaran,
    valueClass,
    setValueClass,
    valuePelajaran,
    setValuePelajaran,
    isEkskul,
  };
};

export default AddTeachingsLogic;
