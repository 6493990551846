import { Form, Input } from "antd";
import React from "react";

const TabDataLainnya = () => {
  return (
    <div className="double-form">
      <div>
        <Form.Item label="Kebutuhan Khusus" name="special_needs">
          <Input />
        </Form.Item>

        <Form.Item
          label="Anak Ke"
          name="child_no"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              max: 2,
              message: "Maksimal 2 Karakter",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Jumlah Saudara"
          name="siblings"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              max: 2,
              message: "Maksimal 2 Karakter",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="No KK"
          name="family_card_no"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Berat /Kg"
          name="weight"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tinggi /Cm"
          name="height"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Lingkar Kepala /Cm"
          name="head_circumference"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Latitude"
          name="address_lat"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Longitude"
          name="address_long"
          rules={[
            {
              pattern: new RegExp("^([0-9.]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Jarak Rumah /Km"
          name="distance_to_school_in_km"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Alat Transportasi"
          name="transportation"
          rules={[{ max: 40 }]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabDataLainnya;
