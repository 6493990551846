import { Card, Divider, Modal, Skeleton, Space, Tag } from "antd";
import { Fragment } from "react";
import DetailPelajaranLogic from "./DetailPelajaranLogic";

const DetailPelajaran = (props) => {
  const { data, isLoading } = DetailPelajaranLogic(props);
  return (
    <Modal
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      open={props.show}
      onCancel={props.onCancel}
    >
      {isLoading && <Skeleton active />}

      {!isLoading && (
        <Fragment>
          <Divider orientation="left">Data Pelajaran</Divider>
          <Card title="Nama Pelajaran" bordered={false}>
            <div>
              <Space>
                {data?.data.name}{" "}
                {data?.data?.is_extracurricular ? (
                  <Tag color="green">Ekskul</Tag>
                ) : null}
              </Space>
            </div>
          </Card>
          <Card title="Deskripsi" bordered={false}>
            <p>{data?.data.description}</p>
          </Card>
        </Fragment>
      )}
    </Modal>
  );
};

export default DetailPelajaran;
