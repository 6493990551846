import React, { useEffect, useState } from "react";
import "./Profile.css";
import ResetPassword from "./ResetPassword";
import { Button, Skeleton } from "antd";
import { SettingFilled } from "@ant-design/icons";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import userIcn from "../../../assets/img/user.png";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  const [datas, setDatas] = useState([]);
  const [photo, setPhoto] = useState();
  const [open, setOpen] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const getStudentDetail = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${REACT_APP_HOST_API}`, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      setDatas(data?.data[0]);
      setPhoto(datas?.studentCandidate?.photo);
      setIsFetch(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getStudentDetail();
    // eslint-disable-next-line
  }, [isFetch]);
  return (
    <div className="profile-ppdb-wrapper">
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="candidate-profile">
            <div className="card-container">
              <span className="pro">SMA FG</span>
              <img
                className="round"
                src={photo && photo ? photo : userIcn}
                alt="user"
              />
              <h3>{datas?.name}</h3>
              <h6>{datas?.email}</h6>
              <br />
              <p>
                SMA FUTURE GATE <br /> "THE SMARTER WAY FOR A BRIGHTER FUTURE"
              </p>
              <Button onClick={handleSetOpen} icon={<SettingFilled />}>
                Ubah Password
              </Button>
              <div className="skills">
                <h6>PPDB SMA FUTURE GATE</h6>
              </div>
            </div>
          </div>

          <ResetPassword open={open} setOpen={handleSetOpen} />
        </>
      )}
    </div>
  );
};

export default Profile;
