import { Form, Modal, Select, Skeleton } from "antd";
import EditStudentToClassLogic from "./EditStudentToClassLogic";

const EditStudentToClass = (props) => {
  const { form, loading, skeleton, dataClasses, onSubmit, onCancelModal } =
    EditStudentToClassLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      open={props.show}
      onCancel={onCancelModal}
      title="Pindah Kelas"
      okButtonProps={{ loading }}
    >
      {skeleton ? <Skeleton active /> : null}

      {!skeleton ? (
        <Form form={form} layout="vertical">
          <Form.Item
            name="class_id"
            label="Nama Kelas"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataClasses?.map((data) => (
                <Select.Option key={data.id} value={data.id}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      ) : null}
    </Modal>
  );
};

export default EditStudentToClass;
