import { RightOutlined } from "@ant-design/icons";
import { Button, Divider, Space } from "antd";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MasterSystem = () => {
  const [dataMenuSystem, setDataMenuSystem] = useState([]);

  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchDashboard = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        const resultModule = data.data[0].role.permissions.modules;
        if (resultModule.find((x) => x.id === "mdlSystem")) {
          const resultMenuSystem = data.data[0].role.permissions.modules.find(
            (x) => x.id === "mdlSystem"
          ).menus;

          setDataMenuSystem(resultMenuSystem);
        }
      } catch (error) {
        if (error.response.status === 401) {
          alert("Akun belum terverifikasi, silahkan hubungi IT / HRD");
          return navigate("/");
        } else {
          alert(error);
        }
      } finally {
      }
    };

    fetchDashboard();

    return () => {
      cancelToken.cancel();
    };
  }, [REACT_APP_HOST_API, navigate]);

  const handleClickMenu = (menu) => {
    encryptCookies("defaultSystemMenu", menu);

    if (menu === "mnuUsers") navigate("users");
    else navigate(menu.toLowerCase().split("mnu")[1] + "s");
  };

  return (
    <div>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ textTransform: "uppercase" }}
      >
        Selamat Datang di Dashboard Sistem
      </Divider>
      <h3 style={{ textTransform: "uppercase" }}>Daftar Menu</h3>
      <Space direction="vertical">
        {dataMenuSystem
          .filter((x) => x.id !== "mnuPPDB")
          .map((x) => (
            <Button
              key={x.id}
              type="primary"
              ghost
              icon={<RightOutlined />}
              onClick={() => handleClickMenu(x.id)}
            >
              {x.id.split("mnu")[1]}
            </Button>
          ))}
      </Space>
    </div>
  );
};

export default MasterSystem;
