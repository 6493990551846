import { DatePicker, Form, Input, Modal, Radio, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import React, { useState } from "react";

const EditSelectRecap = ({ open, setOpen, rowSelected, onUpdate }) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const { REACT_APP_HOST_API } = process.env;
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    form.resetFields();
    onUpdate();
    setOpen();
    setNewData({});
  };

  const handleSubmit = async () => {
    await form.validateFields();

    const idSelected = rowSelected.map((data) => data.id);
    const daily_attendance = idSelected.map((id) => ({ id, ...newData }));
    setIsLoading(true);
    try {
      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/academics/daily-attendances/`,
        { daily_attendance },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      handleClose();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      open={open}
      onOk={handleSubmit}
      onCancel={handleClose}
      closable={false}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item name="status" label="Status">
          <Radio.Group
            onChange={({ target: { value } }) => (newData["status"] = value)}
          >
            <Radio value="present">Hadir</Radio>
            <Radio value="permission">Izin</Radio>
            <Radio value="sick">Sakit</Radio>
            <Radio value="absent">Tanpa keterangan</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="description" label="Deskripsi">
          <Input
            onChange={({ target: { value } }) =>
              (newData["description"] = value)
            }
          />
        </Form.Item>
        <Form.Item name="date_in" label="Waktu Masuk">
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(value) =>
              (newData["date_in"] = dayjs(value).format("YYYY-MM-DD HH:mm:ss"))
            }
          />
        </Form.Item>
        <Form.Item name="date_out" label="Waktu Keluar">
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(value) =>
              (newData["date_out"] = dayjs(value).format("YYYY-MM-DD HH:mm:ss"))
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSelectRecap;
