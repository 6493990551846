import React, { useEffect, useState } from "react";

const TReportScore = ({ data }) => {
  const [nilaiMutu, setNilaiMutu] = useState("");
  const bobot = {
    A: [506, 560],
    B: [449, 505],
    C: [392, 448],
    D: [336, 391],
    E: [0, 335],
  };

  useEffect(() => {
    const nilai = data?.triwulanEmployee?.total_skor;
    for (let key in bobot) {
      if (nilai >= bobot[key][0] && nilai <= bobot[key][1]) {
        setNilaiMutu(key);
        break;
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={7}>TOTAL NILAI</th>
        </tr>
        <tr>
          <th colSpan={3}>Klasifikasi Nilai Akhir</th>
          <th colSpan={2}>Atasan langsung</th>
          <th colSpan={2}>Atasan tidak langsung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ fontWeight: 600 }}>Nilai Mutu</td>
          <td>Bobot</td>
          <td>Kualitas</td>
          <td colSpan={2} rowSpan={2} style={{ background: "#ccc" }}>
            {data?.triwulanEmployee?.total_skor_direct_supervisor}
          </td>
          <td colSpan={2} rowSpan={2} style={{ background: "#ccc" }}>
            {data?.triwulanEmployee?.total_skor_indirect_supervisor}
          </td>
        </tr>
        <tr>
          <td>A</td>
          <td>506-560</td>
          <td>Sangat Baik</td>
        </tr>
        <tr>
          <td>B</td>
          <td>449-505</td>
          <td>Baik</td>
          <td colSpan={2}>Nilai Akhir</td>
          <td style={{ fontWeight: 600, textAlign: "center" }} colSpan={2}>
            NILAI MUTU
          </td>
        </tr>
        <tr>
          <td>C</td>
          <td>392-448</td>
          <td>Cukup</td>
          <td colSpan={2} rowSpan={3}>
            {data?.triwulanEmployee?.total_skor}
          </td>
          <td
            colSpan={2}
            rowSpan={3}
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {nilaiMutu}
          </td>
        </tr>
        <tr>
          <td>D</td>
          <td>336-391</td>
          <td>Kurang</td>
        </tr>
        <tr>
          <td>E</td>
          <td>0-335</td>
          <td>Sangat Kurang</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TReportScore;
