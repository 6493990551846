import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useChartStudentAttendance = (
  fromDate,
  toDate,
  foundationId,
  enabled
) => {
  return useQuery(
    ["get-chart-student-attendance", fromDate, toDate, foundationId],
    () =>
      GetList(
        `/academics/dashboard/student-attendance?fromDate=${fromDate}&toDate=${toDate}&foundationId=${foundationId}`
      ),

    { enabled }
  );
};
