import { RightOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import axios from "axios";
import {
   decryptCookies,
   encryptCookies,
} from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MasterFinanceReport = () => {
   const [dataMenuFinanceReport, setDataMenuFinanceReport] = useState([]);

   const navigate = useNavigate();
   const { REACT_APP_HOST_API } = process.env;

   useEffect(() => {
      const cancelToken = axios.CancelToken.source();

      const fetchDashboard = async () => {
         try {
            const { data } = await axios.get(
               REACT_APP_HOST_API,
               { headers: { Authorization: "Bearer " + decryptCookies("token") } },
               { cancelToken: cancelToken.token }
            );

            const resultModule = data.data[0].role.permissions.modules;
            if (resultModule.find((x) => x.id === "mdlFinanceReport")) {
               data.data[0].role.permissions.modules
                  .find((x) => x.id === "mdlFinanceReport")
                  .menus.sort((a, b) =>
                     a.id.toLowerCase().localeCompare(b.id.toLowerCase())
                  );

               const resultMenuAcademic = data.data[0].role.permissions.modules
                  .find((x) => x.id === "mdlFinanceReport").menus;


               setDataMenuFinanceReport(resultMenuAcademic);
            }
         } catch (error) {
            if (error.response.status === 401) {
               alert("Akun belum terverifikasi, silahkan hubungi IT / HRD");
               return navigate("/");
            } else {
               alert(error);
            }
         } finally {
         }
      };

      fetchDashboard();

      return () => cancelToken.cancel();
   }, [REACT_APP_HOST_API, navigate]);

   const handleClickMenu = (menu) => {
      encryptCookies("defaultFinanceReportMenu", menu);

      navigate(menu.toLowerCase().split("nu")[1]);
   };


   return (
      <div>
         {/* <Divider orientation="left" orientationMargin="0">
      </Divider> */}
         <h2>Selamat Datang di Dashboard Laporan Keuangan</h2>
         <h3 style={{ textTransform: "uppercase" }}>Daftar Menu</h3>
         <Space direction="vertical">
            {dataMenuFinanceReport.map((x) => (
               <Button
                  key={x.id}
                  disabled={x.type === "disabled"}
                  type="primary"
                  ghost
                  icon={<RightOutlined />}
                  onClick={() => handleClickMenu(x.id)}
               >
                  {x.id.split("nu")[1]}
               </Button>
            ))}
         </Space>
      </div>
   );
};

export default MasterFinanceReport;
