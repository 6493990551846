import {
  AppstoreFilled,
  CheckCircleTwoTone,
  DashboardOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useShowParent } from "hooks/parents-hook/useShowParent";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { FaMoneyCheckAlt, FaChartLine, FaFileCsv } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const LayoutFinanceLogic = () => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");

  const { data } = usePermission("mdlFinance");

  const roles = decryptCookies("role").split(",");

  const isParent = roles.includes("parent");

  const handleSelectMenu = (param) => {
    encryptCookies("defaultFinanceMenu", param);
  };

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
  };

  const paretnId = isParent ? decryptCookies("parent_id") : "";
  const { data: dataParent, refetch: refetchParent } = useShowParent(paretnId);

  useEffect(() => {
    isParent && refetchParent();
    // eslint-disable-next-line
  }, []);

  const itemsAccount = [
    {
      key: "student",
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "space-between",
            gap: "5px",
            cursor: "default",
            fontWeight: "bold",
          }}
        >
          {dataParent?.data[0]?.student?.name}
          <CheckCircleTwoTone />
        </div>
      ),
    },
    {
      key: "profile",
      label: <div onClick={() => navigate("/profile")}>Profile</div>,
    },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const menuBillings = data?.find((x) => x.id === "mnuBillings");
  const menuAccount = data?.find((x) => x.id === "mnuAccount");
  const menuRevenues = data?.find((x) => x.id === "mnuRevenues");
  const menuRecapBillings = data?.find((x) => x.id === "mnuRecapsBillings");
  const menuAlokasiDana = data?.find((x) => x.id === "mnuAlokasiDana");
  const menuParentStudent = data?.find((x) => x.id === "mnuParentStudent");
  const menuParentBilling = data?.find((x) => x.id === "mnuParentBilling");

  const itemsUser = [
    ...(isParent
      ? [
          {
            key: "student",
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "space-between",
                  gap: "5px",
                  cursor: "default",
                  fontWeight: "bold",
                }}
              >
                {dataParent?.data[0]?.student?.name}
                <CheckCircleTwoTone />
              </div>
            ),
          },
        ]
      : []),
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    ...(!isParent
      ? [{ key: "mnuFinance", icon: <DashboardOutlined />, label: "Dashboard" }]
      : []),
    menuAccount && {
      key: "mnuAccount",
      icon: <FaMoneyCheckAlt />,
      label: "Bank account",
      disabled: menuAccount?.type === "disabled",
    },
    menuAlokasiDana && {
      key: "mnuAlokasiDana",
      icon: <ReconciliationOutlined />,
      label: "Alokasi Dana",
      disabled: menuAlokasiDana?.type === "disabled",
    },
    menuParentStudent && {
      key: "mnuParentStudent",
      icon: <TeamOutlined />,
      label: "Daftar Siswa",
      disabled: menuParentStudent?.type === "disabled",
    },
    menuParentBilling && {
      key: "mnuParentBilling",
      icon: <ProfileOutlined />,
      label: "Tagihan",
      disabled: menuParentBilling?.type === "disabled",
    },
    menuBillings && {
      key: "mnuTransaksi",
      icon: <IoIosPaper />,
      label: "Transaksi",
      disabled: menuBillings?.type === "disabled" ? true : false,
      children: [
        menuBillings && {
          key: "mnuBillings",
          icon: <IoIosPaper />,
          label: "Billings",
          disabled: menuBillings?.type === "disabled" ? true : false,
        },
        menuRecapBillings && {
          key: "mnuRecapsBillings",
          icon: <FaFileCsv />,
          label: "Recap Billings",
          disabled: menuRecapBillings?.type === "disabled" ? true : false,
        },

        menuRevenues && {
          key: "mnuRevenues",
          icon: <FaChartLine />,
          label: "Revenues",
          disabled: menuRevenues?.type === "disabled" ? true : false,
        },
      ],
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuFinance") navigate("/finance");
      else navigate("/finance/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  return {
    email,
    collapsed,
    items,
    items2,
    itemsUser,
    itemsAccount,
    setCollapsed,
    handleClickItemUser,
    handleClickMenu,
    open,
    setOpen,
    isParent,
  };
};

export default LayoutFinanceLogic;
