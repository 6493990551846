import { InboxOutlined } from "@ant-design/icons";
import { Form, Modal, Select, Upload } from "antd";
import React from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFileCsv } from "react-icons/fa";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
const { Dragger } = Upload;

const ImportStudent = (props) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  const isExcel = (file) => {
    return (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.template.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.addin.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.sheet.binary.macroEnabled.12" ||
      file.type === "text/csv"
    );
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const values = await form.validateFields();
      const { data } = await axios({
        method: "POST",
        url: REACT_APP_HOST_API + `/academics/import-students`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + decryptCookies("token"),
        },
        data: { upload: values.file.file, foundationId: values.foundationId },
      });
      message.success(data.message);
      setFileList([]);
      props.onImport();
    } catch (error) {
      const msg = error.response.data.message
        ? error.response.data.message
        : error.message;
      if (msg.includes("\n")) {
        const showMessageWithNewline = () => {
          message.error({
            content: <pre>{msg}</pre>,
            duration: 5,
          });
        };
        showMessageWithNewline();
      } else {
        message.error(msg);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    if (!isExcel(file)) {
      message.error(
        "You can only upload Excel files (.xlsx, .xls, .xlsm, .xlsb, .csv)!"
      );
    }
    if (!isExcel(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isExcel(file) ? [file] : []);
    return false;
  };

  const onDrop = (e) => {
    const droppedFiles = e.dataTransfer.files;
    if (!isExcel(droppedFiles[0])) {
      message.error(
        "You can only upload Excel files (.xlsx, .xls, .xlsm, .xlsb, .csv)!"
      );
      return;
    }
    message.success("File dropped");
  };

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    props.onCancel();
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    onDrop,
    fileList,
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleUpload}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: uploading }}
      cancelButtonProps={{ disabled: uploading }}
    >
      <Form form={form} layout="vertical">
        <div>
          <p style={{ fontSize: "16px" }}>
            Download the template file{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://docs.google.com/spreadsheets/d/17RwBkclWy1h8gJrBf-Segi_V5i-MO71KrUumWDxBDls/edit?gid=0#gid=0"
            >
              here
            </a>
            .
          </p>
        </div>
        {isSuperAdmin && (
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="file"
          label="File Excel atau CSV"
          rules={[
            {
              validator: (_, value) => {
                if (!value || !value.fileList || value.fileList.length === 0) {
                  return Promise.reject(
                    new Error("Please upload an Excel file or CSV")
                  );
                }
                const file = value.fileList[0];
                if (!isExcel(file)) {
                  return Promise.reject(
                    new Error("Please upload an Excel file or CSV")
                  );
                }
                return Promise.resolve();
              },
            },
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Dragger
            name="file"
            accept=".xlsx, .xls, .csv, .xlsb, .xlsm, .xml"
            listType="picture"
            disabled={uploading}
            iconRender={(file) => {
              if (
                file.type === "application/vnd.ms-excel" ||
                file.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                return (
                  <SiMicrosoftexcel
                    size={45}
                    color={
                      file.type === "application/vnd.ms-excel"
                        ? "#1e90ff"
                        : "#008000"
                    }
                  />
                );
              }
              if (file.type === "text/csv") {
                return (
                  <FaFileCsv
                    size={45}
                    color={file.type === "text/csv" ? "#1e90ff" : "#008000"}
                  />
                );
              }
              return <InboxOutlined />;
            }}
            {...propsUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for excel or csv file.</p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportStudent;
