import { PlusOutlined } from "@ant-design/icons";
import { RichTextEditor } from "@mantine/rte";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { ActivityContext } from "context/ActivityContext";
import { useMemberEmployee } from "hooks/activity-hook/useMemberEmployee";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const date = "YYYY-MM-DD HH:mm:ss";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const CreateSubActivity = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [value, setValue] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  // presence
  const [keyword, setKeyword] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);

  const { REACT_APP_HOST_API } = process.env;
  const { activity_id } = useParams();
  const navigate = useNavigate();
  const html2json = require("html2json").html2json;

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    const fileToUpload = fileList.map((x) => x.originFileObj);

    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/sub-activities/`,
        {
          ...values,
          note: html2json(value),
          date: values?.date?.format(date),
          activityId: activity_id,
          images: fileToUpload,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onCancelModal();
      return data?.data?.id;
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // image upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  // presence
  const { setFetchActivity } = useContext(ActivityContext);
  const { REACT_APP_HOST_API: api } = process.env;

  const { data, isFetching, refetch } = useMemberEmployee({
    activityId: activity_id,
    keyword,
  });

  const onAddPresence = async () => {
    const id = await onSubmit();

    const dataToSend = selectedRow.map((x) => {
      return {
        employeeId: x,
        activityId: activity_id,
        subActivityId: id,
      };
    });

    setLoading(true);
    try {
      const { data } = await axios.post(
        api + `/presences/${activity_id}/${id}`,
        { presences: dataToSend },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      onCancelModal();
      setFetchActivity(true);
      navigate(-1);
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setKeyword("");
      setLoading(false);
    }
  };

  useEffect(() => {
    refetch();

    if (data && selectedRow.length === 0)
      setSelectedRow([
        ...data?.data
          ?.filter((x) => x.role !== false)
          ?.map((x) => x.employee_id),
      ]);

    return () => setFetchActivity(false);
    // eslint-disable-next-line
  }, [keyword, data, refetch, setFetchActivity]);

  const columns = [{ title: "Nama", dataIndex: "name" }];

  const dataSource = data?.data
    ?.sort((a, b) => a.role - b.role)
    .map((x) => ({
      id: x.employee_id,
      key: x.employee_id,
      name: x?.employee?.name,
    }));

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRow(selectedRowKeys);
    console.log(selectedRow);
  };

  const rowSelection = data && {
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      <div className="table-header">
        <span></span>
        <Space>
          <Button
            onClick={() => {
              onCancelModal();
              navigate(-1);
            }}
          >
            Batal
          </Button>
          <Button type="primary" onClick={onAddPresence} loading={loading}>
            Submit
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical" className="full-form">
        <div className="first-form">
          <Form.Item
            name="name"
            label="Nama"
            tooltip="Nama/ Topik jam"
            rules={[{ required: true, min: 3 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="date" label="Tanggal" rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item label="Gambar bukti kegiatan" requiredMark="optional">
            <Upload
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              beforeUpload={(file) => {
                if (file.size <= 2000000) {
                  return false;
                }
                if (file.size > 2000000) {
                  message.error("Maks File 2MB");
                }
              }}
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
              }}
              onChange={(file) => {
                if (file?.file.size > 2000000) return;
                else setFileList(file?.fileList);
              }}
              fileList={fileList}
            >
              {fileList.length >= 3 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            name="note"
            label="Notulensi"
            rules={[{ min: 3 }]}
            requiredMark="optional"
          >
            <RichTextEditor
              value={value}
              onChange={setValue}
              id="rte"
              controls={[
                ["bold", "italic", "underline", "link"],
                ["h1", "h2", "h3"],
                ["unorderedList", "orderedList"],
                ["alignLeft", "alignCenter", "alignRight"],
              ]}
            />
          </Form.Item>
        </div>

        {/* presence */}
        <div className="second-form">
          <Form.Item label="PRESENSI KEGIATAN">
            <Input
              allowClear
              value={keyword}
              placeholder="cari nama..."
              onChange={({ target: { value } }) => setKeyword(value)}
            />
          </Form.Item>
          <Table
            size="small"
            pagination={false}
            scroll={{ y: "60vh" }}
            loading={isFetching}
            dataSource={dataSource}
            columns={columns}
            rowSelection={{ ...rowSelection }}
          />
          {/* <PresenceSubActivity dataSub={dataSubActivity} isloading={loading} /> */}
        </div>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default CreateSubActivity;
