import style from "./MasterAcademic.module.css";
import { Button, Row, Col, DatePicker, Select, Form, Tooltip } from "antd";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useMemo, useState } from "react";
import { Column, Pie } from "@ant-design/plots";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useAcademicDashboard } from "hooks/academic-dashboard-hook/useAcademicDashboard";
import { useChartTeacherAttendance } from "hooks/academic-dashboard-hook/useChartTeacherAttendance";
import { useChartStudentAttendance } from "hooks/academic-dashboard-hook/useChartStudentAttendance";
import moment from "moment";
import { generate } from "@ant-design/colors";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

const MasterAcademic = () => {
  const [dataMenuAcademic, setDataMenuAcademic] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [studentChart, setStudentChart] = useState([]);
  const [teacherChartData, setTeacherChartData] = useState([]);
  const [studentChartData, setStudentChartData] = useState([]);
  const [foundationId, setFoundationId] = useState({
    dashboard: "",
    chartTeacher: "",
    chartStudent: "",
  });
  const [startDateTeacher, setStartDateTeacher] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [endDateTeacher, setEndDateTeacher] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [startDateStudent, setStartDateStudent] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [endDateStudent, setEndDateStudent] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [userName, setUserName] = useState("");

  const roles = decryptCookies("role").split(",");

  const { data } = useAcademicDashboard(foundationId.dashboard);
  const { data: chartTeacher } = useChartTeacherAttendance(
    startDateTeacher,
    endDateTeacher,
    foundationId.chartTeacher
  );
  const { data: chartStudent } = useChartStudentAttendance(
    startDateStudent,
    endDateStudent,
    foundationId.chartStudent,
    Boolean(!roles.includes("user_academic"))
  );
  const { data: dataFoundation } = useFoundationList();

  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  const [form] = Form.useForm();

  useEffect(() => {
    setTeacherChartData(chartTeacher?.data || []);
    setStudentChartData(chartStudent?.data || []);
    setDashboardData(data);

    setStudentChart(data?.data?.infoSiswa?.chart);
  }, [data, chartTeacher, chartStudent]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchDashboard = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        setUserName(data?.data[0]?.name);

        const resultModule = data.data[0].role.permissions.modules;
        if (resultModule.find((x) => x.id === "mdlAcademic")) {
          data.data[0].role.permissions.modules
            .find((x) => x.id === "mdlAcademic")
            .menus.sort((a, b) =>
              a.id.toLowerCase().localeCompare(b.id.toLowerCase())
            );

          const resultMenuAcademic = data.data[0].role.permissions.modules.find(
            (x) => x.id === "mdlAcademic"
          ).menus;

          setDataMenuAcademic(resultMenuAcademic);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          alert("Akun belum terverifikasi, silahkan hubungi IT / HRD");
          return navigate("/");
        } else {
          alert(error);
        }
      } finally {
      }
    };

    fetchDashboard();

    return () => cancelToken.cancel();
    // eslint-disable-next-line
  }, [REACT_APP_HOST_API, navigate]);

  const handleClickMenu = (menu) => {
    encryptCookies("defaultAcademicMenu", menu);

    navigate(menu.toLowerCase().split("nu")[1]);
  };

  const filterMenu = dataMenuAcademic.filter(
    (x) =>
      x.id === "mnuPresencePerSubject" ||
      x.id === "mnuClasses" ||
      x.id === "mnuStudents" ||
      x.id === "mnuTeachers"
  );

  // --- -------------------------
  const isDesktop = useMediaQuery({
    minWidth: 768,
  });

  // Generate custom color palette
  const customPalette = generate("#1890ff");

  const configPie = {
    appendPadding: 10,
    data: studentChart,
    angleField: "value",
    colorField: "kelas",
    theme: {
      colors10: customPalette, // You can use customPalette.slice(0, 10) if you only want 10 colors
    },
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
    legend: {
      layout: "horizontal",
      position: "top",
    },
  };

  // --- chart column

  const configChartSiswa = useMemo(() => {
    const config = {
      data: studentChartData,
      isGroup: true,
      xField: "date",
      yField: "value",
      seriesField: "name",
      isPercent: true,

      label: {
        position: "middle",
        layout: [
          {
            type: "interval-adjust-position",
          },
          {
            type: "interval-hide-overlap",
          },
          {
            type: "adjust-color",
          },
        ],
        content: (data) => {
          return `${(data.value * 100).toFixed(2)}%`;
        },
      },
      yAxis: {
        label: {
          formatter: (v) => `${v * 100}%`,
        },
      },
    };
    return config;
    // eslint-disable-next-line
  }, [studentChartData]);

  const configChartGuru = useMemo(() => {
    const config = {
      data: teacherChartData,
      isGroup: true,
      xField: "date",
      yField: "value",
      seriesField: "name",
      isPercent: true,

      label: {
        position: "middle",
        layout: [
          {
            type: "interval-adjust-position",
          },
          {
            type: "interval-hide-overlap",
          },
          {
            type: "adjust-color",
          },
        ],
        content: (data) => {
          return `${(data.value * 100).toFixed(2)}%`;
        },
      },
      yAxis: {
        label: {
          formatter: (v) => `${v * 100}%`,
        },
      },
    };
    return config;
    // eslint-disable-next-line
  }, [teacherChartData]);

  return (
    <div style={{ paddingBottom: "20px" }}>
      {/* greating section */}
      <div className={style.firstContent}>
        <div>
          <h1>Halo {userName}!</h1>
          <p>{dayjs().format("dddd, DD MMMM YYYY")}</p>
        </div>

        <div>
          <h2>Shortcut</h2>
          <div className={style.shortcutWrapper}>
            {filterMenu.map((x) => {
              return (
                <Button
                  key={x.id}
                  disabled={x.type === "disabled"}
                  onClick={() => handleClickMenu(x.id)}
                  shape="round"
                >
                  {x.id === "mnuPresencePerSubject"
                    ? "Teaching Journal"
                    : x.id.split("nu")[1]}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
      {/* end greating section */}

      <Form form={form} layout="vertical">
        {/* content section */}
        <Row style={{ marginTop: "20px", display: "flex", gap: "30px" }}>
          <Col span={isDesktop ? 11 : 24} className={style.info}>
            <h1>Info Guru</h1>

            <p>
              <span style={{ fontWeight: "bold" }}>Wali Kelas:</span>{" "}
              {dashboardData?.data?.infoGuru?.waliKelas || "-"}
            </p>
            <p style={{ marginBottom: "1px", fontWeight: "bold" }}>Mengajar:</p>
            <p>{dashboardData?.data?.infoGuru?.mengajar || "-"}</p>
            <p style={{ marginBottom: "1px", fontWeight: "bold" }}>Kelas:</p>
            <p>{dashboardData?.data?.infoGuru?.kelas || "-"}</p>
          </Col>

          <Col span={isDesktop ? 11 : 24} className={style.kehadiran}>
            <Row>
              <Col span={isDesktop ? 10 : 24}>
                <h1>Kehadiran Guru</h1>
              </Col>
              <Col
                span={
                  roles.find((x) => x === "super_admin")
                    ? 24
                    : isDesktop
                    ? 14
                    : 24
                }
                style={{ display: "flex", gap: "10px" }}
              >
                <Form.Item style={{ width: "200px" }}>
                  {roles.some((x) => x === "super_admin") && (
                    <Tooltip Tooltip title="Yayasan">
                      <Select
                        placeholder="Pilih Yayasan"
                        style={{ minWidth: 150 }}
                        onChange={(x) => {
                          setFoundationId(() => ({
                            ...foundationId,
                            chartTeacher: x,
                          }));
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        <Select.Option
                          className="select-option-foundation"
                          value=""
                        >
                          ALL
                        </Select.Option>
                        {dataFoundation?.data?.data?.map((x) => (
                          <Select.Option
                            key={x.id}
                            value={x.id}
                            className="select-option-foundation"
                          >
                            {x.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Tooltip>
                  )}
                </Form.Item>

                <Tooltip title="Start Date">
                  <Form.Item
                    name="startDateTeacher"
                    initialValue={dayjs(startDateTeacher)}
                    style={{ width: "200px" }}
                  >
                    <DatePicker
                      inputReadOnly
                      format="YYYY-MM-DD"
                      placeholder="from date"
                      onChange={(value) =>
                        setStartDateTeacher(dayjs(value).format("YYYY-MM-DD"))
                      }
                    />
                  </Form.Item>
                </Tooltip>

                <Tooltip title="End Date">
                  <Form.Item
                    name="endDateTeacher"
                    initialValue={dayjs(
                      moment(new Date()).format("YYYY-MM-DD")
                    )}
                    style={{ width: "200px" }}
                  >
                    <DatePicker
                      inputReadOnly
                      onChange={(value) =>
                        setEndDateTeacher(dayjs(value).format("YYYY-MM-DD"))
                      }
                      format="YYYY-MM-DD"
                      placeholder="to date"
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            <br />
            <Column {...configChartGuru} />
          </Col>
        </Row>

        {roles.find(
          (x) =>
            x === "super_admin" ||
            x === "admin_academic" ||
            x === "admin_foundation"
        ) && (
          <Row style={{ marginTop: "20px", display: "flex", gap: "30px" }}>
            <Col span={isDesktop ? 11 : 24} className={style.info}>
              <Row>
                <Col span={12}>
                  <h1>Siswa</h1>
                </Col>
                <Col span={12}>
                  <h1 style={{ color: "#787878", textAlign: "right" }}>
                    Total: {dashboardData?.data?.infoSiswa?.totalStudent || "0"}
                  </h1>
                </Col>
              </Row>

              {roles.some((x) => x === "super_admin") && (
                <Tooltip Tooltip title="Yayasan">
                  <Select
                    placeholder="Pilih Yayasan"
                    style={{ minWidth: 150 }}
                    onChange={(x) => {
                      setFoundationId(() => ({
                        ...foundationId,
                        dashboard: x,
                      }));
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Select.Option
                      className="select-option-foundation"
                      value=""
                    >
                      ALL
                    </Select.Option>
                    {dataFoundation?.data?.data?.map((x) => (
                      <Select.Option
                        key={x.id}
                        value={x.id}
                        className="select-option-foundation"
                      >
                        {x.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              )}

              <Pie {...configPie} style={{ marginTop: "20px" }} />
            </Col>

            <Col span={isDesktop ? 11 : 24} className={style.kehadiran}>
              <Row>
                <Col span={isDesktop ? 10 : 24}>
                  <h1>Kehadiran Siswa</h1>
                </Col>
                <Col
                  span={
                    roles.find((x) => x === "super_admin")
                      ? 24
                      : isDesktop
                      ? 14
                      : 24
                  }
                  style={{ display: "flex", gap: "10px" }}
                >
                  <Form.Item style={{ width: "200px" }}>
                    {roles.some((x) => x === "super_admin") && (
                      <Tooltip Tooltip title="Yayasan">
                        <Select
                          placeholder="Pilih Yayasan"
                          style={{ minWidth: 150 }}
                          onChange={(x) => {
                            setFoundationId(() => ({
                              ...foundationId,
                              chartStudent: x,
                            }));
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Select.Option
                            className="select-option-foundation"
                            value=""
                          >
                            ALL
                          </Select.Option>
                          {dataFoundation?.data?.data?.map((x) => (
                            <Select.Option
                              key={x.id}
                              value={x.id}
                              className="select-option-foundation"
                            >
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Tooltip>
                    )}
                  </Form.Item>

                  <Tooltip title="Start Date">
                    <Form.Item
                      name="startDateStudent"
                      initialValue={dayjs(startDateTeacher)}
                      style={{ width: "200px" }}
                    >
                      <DatePicker
                        inputReadOnly
                        format={"YYYY-MM-DD"}
                        onChange={(value) =>
                          setStartDateStudent(dayjs(value).format("YYYY-MM-DD"))
                        }
                        placeholder="from date"
                      />
                    </Form.Item>
                  </Tooltip>

                  <Tooltip title="End Date">
                    <Form.Item
                      style={{ width: "200px" }}
                      name="endDateStudent"
                      initialValue={dayjs(
                        moment(new Date()).format("YYYY-MM-DD")
                      )}
                    >
                      <DatePicker
                        inputReadOnly
                        format={"YYYY-MM-DD"}
                        onChange={(value) =>
                          setEndDateStudent(dayjs(value).format("YYYY-MM-DD"))
                        }
                        placeholder="to date"
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <br />
              <Column {...configChartSiswa} />
            </Col>
          </Row>
        )}

        {/* end content section */}
      </Form>

      {/* <ChartKehadiranGuru configPie={configPie} /> */}
    </div>
  );
};

export default MasterAcademic;
