import { Form, Modal, Radio, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";

const titleOptions = [
  { label: "Ketua", value: "lead" },
  { label: "Wakil", value: "vice" },
  { label: "Anggota", value: "member" },
];

const EditEmployeFromUnit = ({ selectedData, show, onUpdate, onCancel }) => {
  const [newData, setNewData] = useState({});
  const [form] = Form.useForm();

  const { REACT_APP_HOST_API: api } = process.env;

  useEffect(() => {
    if (selectedData.length === 1) {
      const employeeData = selectedData[0];
      form.setFieldsValue({
        status: employeeData?.status,
        title: employeeData?.title,
      });
    } else if (selectedData.length > 1) {
      form.setFieldsValue({
        status: "",
        title: "",
      });
      setNewData({});
    }
  }, [selectedData, form]);

  useEffect(() => {
    return () => setNewData({});
  }, []);

  const handleSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang dirubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    const memberUnitDatas = selectedData.map((x) => {
      return {
        id: x.id,
        account_id: x.account_id,
        ...newData,
      };
    });

    try {
      const { data } = await axios.put(
        api + `/employee-units/batch`,
        { employeeUnits: memberUnitDatas },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "application/json",
          },
        }
      );

      message.success(data.message);
      form.resetFields();
      onUpdate();
    } catch (error) {
      const msg = error?.response?.data?.message || "Input Field Error";
      message.error(msg);
    }
  };

  return (
    <Modal
      open={show}
      okText="Submit"
      closable={false}
      onOk={handleSubmit}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Jabatan">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label?.toLowerCase().includes(input.toLowerCase())
            }
            options={titleOptions}
            onChange={(e) => (newData.title = e)}
          />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          onChange={({ target: { value } }) =>
            (newData.status = value)
          }
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="FULLTIME">Full time</Radio.Button>
            <Radio.Button value="PARTTIME">Part time</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditEmployeFromUnit;
