import { useState, useEffect } from "react";

export default function useConvertImgToBase64(imageUrl) {
  const [imgBase64, setImgBase64] = useState(null);

  useEffect(() => {
    async function imageUrlToBase64(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
          reader.onerror = reject;
        });
      } catch (error) {
        console.error("Gagal mengambil gambar:", error);
        return null;
      }
    }

    if (imageUrl) {
      imageUrlToBase64(imageUrl)
        .then((base64String) => {
          setImgBase64(base64String);
        })
        .catch((error) => {
          console.error("Terjadi kesalahan:", error);
        });
    }
  }, [imageUrl]);

  return imgBase64;
}
