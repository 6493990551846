import { Button, Space } from "antd";
import { MonthlyReportContext } from "context/MonthlyReportContext";
import { useMonthlyReportPagination } from "hooks/monthly-report/apiCalls";
import { usePermission } from "hooks/usePermissions";
import { useContext, useEffect, useState } from "react";
import CreateMReport from "./CreateMReport";
import EditMReport from "./EditMReport";
import MReportFilter from "./MReportFilter";
import "./MonthlyReport.css";
import ReportList from "./component/ReportList";

const months = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const MonthlyReportCardList = () => {
  const [dataTable] = useState({ page: 1, limit: 1000 });
  const { date, keyword, fetchReport, setShowCreate, setFetchReport } =
    useContext(MonthlyReportContext);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuMonthlyReport");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddReport");

  const { data, isFetching, refetch } = useMonthlyReportPagination({
    dataTable,
    keyword,
    date,
  });

  useEffect(() => {
    refetch();

    return () => setFetchReport(false);
  }, [fetchReport, refetch, setFetchReport]);

  const dataSource = data?.data?.data
    .sort(
      (a, b) =>
        months.indexOf(a.name.split(" ")[0]) -
        months.indexOf(b.name.split(" ")[0])
    )
    ?.map((x, i) => ({
      ...x,
      key: x.id,
      index: i + 1,
      unitName: x?.unit?.name,
    }));

  return (
    <>
      <div className="table-header">
        <h1>Daftar Rapor</h1>
        <Space>
          <Button
            type="primary"
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            onClick={() => {
              if (btnAdd?.type === "disabled") return;
              else setShowCreate(true);
            }}
          >
            Generate Rapor
          </Button>
        </Space>
      </div>
      <MReportFilter />
      <ReportList
        dataPermission={dataPermission}
        dataSource={dataSource}
        refetch={refetch}
        isFetching={isFetching}
      />
      <CreateMReport />
      <EditMReport />
    </>
  );
};

export default MonthlyReportCardList;
