import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const TabDokumen = ({ dataFetching }) => {
  const [fileListAkta, setFileListAkta] = useState([]);
  const [fileListKK, setFileListKK] = useState([]);
  const [fileListSKL, setFileListSKL] = useState([]);
  const [fileListIjazah, setFileListIjazah] = useState([]);
  const [fileListPayment, setFileListPayment] = useState([]);

  // const { REACT_APP_DEV } = process.env;
  const { REACT_APP_HOST_API } = process.env;

  const datas = dataFetching?.data[0];

  const getData = () => {
    if (datas?.birth_cert_scan) {
      setFileListAkta([
        {
          isFirst: true,
          name: "akta kelahiran",
          thumbUrl: datas?.birth_cert_scan,
        },
      ]);
    }
    if (datas?.family_card_scan) {
      setFileListKK([
        {
          isFirst: true,
          name: "Kartu Keluarga",
          thumbUrl: datas?.family_card_scan,
        },
      ]);
    }
    if (datas?.jhs_graduation_letter_scan) {
      setFileListSKL([
        {
          isFirst: true,
          name: "Surat Keterangan Lulus",
          thumbUrl: datas?.jhs_graduation_letter_scan,
        },
      ]);
    }
    if (datas?.jhs_certificate_scan) {
      setFileListIjazah([
        {
          isFirst: true,
          name: "Ijazah",
          thumbUrl: datas?.jhs_certificate_scan,
        },
      ]);
    }
    if (datas?.scan_payment_proof) {
      setFileListPayment([
        {
          isFirst: true,
          name: "Slip Pembayaran",
          thumbUrl: datas?.scan_payment_proof,
        },
      ]);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const { candidate_id } = useParams();

  const uploadAkta = async (file) => {
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${candidate_id}/file-upload`,
        { file: file, category: "birth_certificate" },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Akta berhasil diupload");
    } catch (error) {
      alert("Gagal upload Akta!");
    }
  };

  const uploadKK = async (file) => {
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${candidate_id}/file-upload`,
        { file: file, category: "family_card" },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("KK berhasil diupload");
    } catch (error) {
      alert("Gagal upload Kartu Keluarga!");
    }
  };

  const uploadSKL = async (file) => {
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${candidate_id}/file-upload`,
        { file: file, category: "jhs_graduation_letter_scan" },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("SKL berhasil diupload");
    } catch (error) {
      alert("Gagal upload Surat Keterngan Lulus!");
    }
  };

  const uploadIjazah = async (file) => {
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${candidate_id}/file-upload`,
        { file: file, category: "jhs_certificate" },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Ijazah berhasil diupload");
    } catch (error) {
      alert("Gagal upload Ijazah!");
    }
  };

  const uploadPayment = async (file) => {
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${candidate_id}/file-upload`,
        { file: file, category: "payment_proof" },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Slip pembayaran berhasil diupload");
    } catch (error) {
      alert("Gagal upload Slip Pembayaran!");
    }
  };

  // const handleUpload = () => {
  //   const requiredDocuments = [
  //     fileListAkta,
  //     fileListKK,
  //     fileListSKL,
  //     fileListIjazah,
  //     fileListPayment,
  //   ];
  // };

  return (
    <div className="double-form">
      <Form.Item
        label="Akta Kelahiran"
        name="akta_doc"
        rules={[
          {
            required: fileListAkta.length === 0,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Upload
          listType="picture"
          accept=".pdf"
          fileList={fileListAkta}
          beforeUpload={(file) => {
            if (fileListAkta.length >= 1) {
              message.error("Allowed only 1 file");
              return false;
            }
            if (file.size > 2000000) {
              message.error("Maks File 2MB");
              return false;
            }
            setFileListAkta([file]);
            uploadAkta(file);
            return false;
          }}
          onRemove={(file) => {
            setFileListAkta([]);
          }}
        >
          {fileListAkta.length === 0 && (
            <Button type="primary" icon={<UploadOutlined />}>
              Upload
            </Button>
          )}
        </Upload>
      </Form.Item>

      <Form.Item
        label="Kartu Keluarga"
        name="kk_doc"
        rules={[
          {
            required: fileListKK.length === 0,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Upload
          listType="picture"
          accept=".pdf"
          fileList={fileListKK}
          beforeUpload={(file) => {
            if (fileListKK.length >= 1) {
              message.error("Allowed only 1 file");
              return false;
            }
            if (file.size > 2000000) {
              message.error("Maks File 2MB");
              return false;
            }
            setFileListKK([file]);
            uploadKK(file);
            return false;
          }}
          onRemove={(file) => {
            setFileListKK([]);
          }}
        >
          {fileListKK.length === 0 && (
            <Button type="primary" icon={<UploadOutlined />}>
              Upload
            </Button>
          )}
        </Upload>
      </Form.Item>

      <Form.Item
        label="Surat Keterngan Lulus"
        name="skl_doc"
        rules={[
          {
            required: fileListSKL.length === 0,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Upload
          listType="picture"
          accept=".pdf"
          fileList={fileListSKL}
          beforeUpload={(file) => {
            if (fileListSKL.length >= 1) {
              message.error("Allowed only 1 file");
              return false;
            }
            if (file.size > 2000000) {
              message.error("Maks File 2MB");
              return false;
            }
            setFileListSKL([file]);
            uploadSKL(file);
            return false;
          }}
          onRemove={(file) => {
            setFileListSKL([]);
          }}
        >
          {fileListSKL.length === 0 && (
            <Button type="primary" icon={<UploadOutlined />}>
              Upload
            </Button>
          )}
        </Upload>
      </Form.Item>

      <Form.Item
        label="Ijazah"
        name="ijazah_doc"
        rules={[
          {
            required: fileListIjazah.length === 0,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Upload
          listType="picture"
          accept=".pdf"
          fileList={fileListIjazah}
          beforeUpload={(file) => {
            if (fileListIjazah.length >= 1) {
              message.error("Allowed only 1 file");
              return false;
            }
            if (file.size > 2000000) {
              message.error("Maks File 2MB");
              return false;
            }
            setFileListIjazah([file]);
            uploadIjazah(file);
            return false;
          }}
          onRemove={(file) => {
            setFileListIjazah([]);
          }}
        >
          {fileListIjazah.length === 0 && (
            <Button type="primary" icon={<UploadOutlined />}>
              Upload
            </Button>
          )}
        </Upload>
      </Form.Item>

      <Form.Item
        label="Slip Pembayaran"
        name="payment_doc"
        rules={[
          {
            required: fileListPayment.length === 0,
            message: "data tidak boleh kosong",
          },
        ]}
      >
        <Upload
          listType="picture"
          accept=".png,.jpg,.jpeg,.pdf"
          fileList={fileListPayment}
          beforeUpload={(file) => {
            if (fileListPayment.length >= 1) {
              message.error("Allowed only 1 file");
              return false;
            }
            if (file.size > 2000000) {
              message.error("Maks File 2MB");
              return false;
            }
            setFileListPayment([file]);
            uploadPayment(file);
            return false;
          }}
          onRemove={(file) => {
            setFileListPayment([]);
          }}
        >
          {fileListPayment.length === 0 && (
            <Button type="primary" icon={<UploadOutlined />}>
              Upload
            </Button>
          )}
        </Upload>
      </Form.Item>
    </div>
  );
};

export default TabDokumen;
