import { UserOutlined } from "@ant-design/icons";
import { Divider, Dropdown, theme } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React from "react";
import Cookies from "js-cookie";

const { useToken } = theme;

const NavUserProfile = ({ isOnlyIcon, itemsUser, handleClickItemUser }) => {
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,

    width: isOnlyIcon ? "100%" : "50%",
  };
  const menuStyle = {
    boxShadow: "none",
  };

  const email = Cookies.get("email") && decryptCookies("email");
  const name = Cookies.get("name") && decryptCookies("name");

  return (
    <Dropdown
      menu={{
        items: itemsUser,
        style: { width: isOnlyIcon ? "100%" : "50%" },

        onClick: handleClickItemUser,
      }}
      placement="bottomLeft"
      arrow
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {isOnlyIcon && (
            <div
              style={{
                padding: "14px 20px",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <div>
                <UserOutlined
                  style={{
                    fontSize: "24px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "15px",
                  }}
                >
                  {name}
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#8c8c8c",
                  }}
                >
                  {email}
                </span>
              </div>
            </div>
          )}
          <Divider
            style={{
              margin: 0,
            }}
          />
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
        </div>
      )}
    >
      {isOnlyIcon ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            backgroundColor: "#f5f6f9",
            cursor: "pointer",
            borderRadius: "50%",
            padding: "10px",
          }}
        >
          <UserOutlined
            style={{
              fontSize: "18px",
            }}
          />
        </div>
      ) : (
        <div className="user-profile">
          <UserOutlined />
          <span>{email}</span>
        </div>
      )}
    </Dropdown>
  );
};

export default NavUserProfile;
