import { Form, Popconfirm, Space, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useClassListFoundation } from "hooks/classes-hook/useClassListFoundation";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useStudentPagination } from "hooks/students-hook/useStudentPagination";
import { usePermission } from "hooks/usePermissions";
import moment from "moment";
import { useCallback,  useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const format = "YYYY-MM-DD";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const StudentsLogic = () => {
  const [form] = Form.useForm();
  const [dataId, setDataId] = useState("");
  const [classId, setClassId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showImportStudent, setShowImportStudent] = useState(false);
  const [showImportUpdate, setShowImportUpdate] = useState(false);
  const [hasNisn, setHasNisn] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const navigate = useNavigate();

  // fetch student
  const { data, isLoading, isFetching, refetch } = useStudentPagination({
    dataTable,
    keyword,
    classId,
    isNew: "",
    foundationId,
    hasNisn,
  });

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetchClass list
  const { data: dataClass, isLoading: skeleton } =
    useClassListFoundation(foundationId);

  // fetch student permission
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuStudents");

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddStudent");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditStudent");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailStudent");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteStudent");
  const btnImport = dataPermission?.find((x) => x.id === "btnImportStudent");
  const btnImportUpdate = dataPermission?.find(
    (x) => x.id === "btnImportUpdate"
  );
  const btnExport = dataPermission?.find((x) => x.id === "btnExportStudent");

  const onCancel = () => {
    setShowImportStudent(false);
  };
  const onCancelUpdate = () => {
    setShowImportUpdate(false);
  };

  const onImport = useCallback(() => {
    setShowImportStudent(false);
    refetch();
  }, [refetch]);

  const onImportUpdate = useCallback(() => {
    setShowImportUpdate(false);
    refetch();
  }, [refetch]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "index",
      align: "left",
      fixed: "left",
      width: 50,
    },
    {
      title: "Nama",
      dataIndex: "name",
      fixed: "left",
      width: window.innerWidth > 800 ? "" : 150,
    },
    { title: "Kelas", dataIndex: "class", align: "left" },
    { title: "NISN", dataIndex: "nisn", align: "left", width: 100 },
    { title: "NIS", dataIndex: "nis", align: "left" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/academics/students/", dataId, refetch })
              }
              disabled={btnDelete && btnDelete.type === "disabled"}
              style={{ cursor: "pointer" }}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDelete?.type === "disabled") {
                    return;
                  } else {
                    setDataId(id);
                  }
                }}
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  navigate(`/academic/students/${id}/edit`);
                }
              }}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              onClick={() => {
                if (btnDetail?.type === "disabled") {
                  return;
                } else {
                  navigate("/academic/students/" + id);
                }
              }}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
            >
              Detail
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        class: data.class ? data.class.name : "",
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const dataToExport = data?.data?.data?.map((x) => {
    return {
      Nama: x.name,
      Kelas: x.class?.name,
      NIK: x.nik,
      NIS: x.nis,
      NISN: x.nisn,
      "Tempat Lahir": x.birth_city,
      "Tanggal Lahir": x.birth_day && moment(x.birth_day).format(format),
      Alamat: x.address,
      RT: x.rt,
      RW: x.rw,
      Kelurahan: x.kel,
      Kecamatan: x.kec,
      Kota: x.kot,
      Provinsi: x.prov,
      "Kode Pos": x.zip,
      Nomor: x.phone,
      "Nomor Hp": x.mobile_phone,
      Email: x.email,
      Agama: x.religion,
      "Jenis Kelamin": x.gender,
      "Jenis Tinggal": x.residence,
      "Alat Transportasi": x.transportation,
      "Nomor skhu smp/sekolah sebelumnya": x.junior_hs_cert_no,
      "Penerima KPS": x.has_kps && x.has_kps === false ? "Tidak" : "Iya",
      "Nomor KPS": x.kps_number,
      "Penerima kip": x.has_kip && x.has_kip === false ? "Tidak" : "Iya",
      "Nomor kip": x.kip_number,
      "Nama di kip":
        x.name_on_kip && x.name_on_kip === false ? "Tidak ada" : "Ada",
      "Penerima kks": x.has_kks && x.has_kks === false ? "Tidak" : "Iya",
      "Nomor kks": x.kks_number,
      "Akta Lahir": x.birth_cert_no,
      "Layak PIP": x.pip_eligible && x.pip_eligible === false ? "Tidak" : "Iya",
      "Alasan PIP": x.pip_desc,
      "Berkebuthan Khusus": x.special_needs,
      "Nama SMP": x.junior_hs_name,
      "Anak Ke": x.child_no,
      Latitude: x.address_lat,
      Longtitude: x.address_long,
      "Nomor KK": x.family_card_no,
      Tinggi: x.height,
      Berat: x.weight,
      "Lingkar Kepala(cm)": x.head_circumference,
      "Jumlah Saudara": x.siblings,
      "Jarak Ke Sekolah": x.distance_to_school_in_km,
      Program: x.program,
      Unit: x.unit,
      "Nama Bank": x.bank_name,
      "Rekening Atas Nama": x.bank_account_owner,
      "Nomor Rekening": x.bank_account_number,
      "Nomor Ujian Nasional SMP/Sekolah sebelumnya": x.nat_exam_no,
    };
  });

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `data_siswa.xlsx`);
  };

  return {
    form,
    btnAdd,
    dataKey,
    keyword,
    columns,
    btnExport,
    dataClass,
    isLoading,
    isFetching,
    pagination,
    showImportStudent,
    btnImport,
    btnImportUpdate,
    onCancel,
    onImport,
    setClassId,
    handleExport,
    handleChange,
    setShowImportStudent,
    showImportUpdate,
    setShowImportUpdate,
    onCancelUpdate,
    onImportUpdate,
    setHasNisn,
    dataFoundation,
    classId,
    skeleton,
    setFoundationId,
    isSuperAdmin,
    roles,
  };
};

export default StudentsLogic;
