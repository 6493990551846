import { Popconfirm, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { DeleteCookies } from "components/Helper/DeleteCookies";
import { Link, useNavigate } from "react-router-dom";

const Logout = ({ children }) => {
  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;
  const roles = decryptCookies("role").split(",");

  // Logout PPDB
  const handleLogoutPpdb = async () => {
    try {
      await axios.post(
        REACT_APP_HOST_API + "/ppdb/auth/logout",
        {},
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      DeleteCookies();
      message.success("Berhasil Logout");
      navigate("/ppdb");
    } catch (error) {
      alert(`Gagal Keluar 
${error.message}`);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        REACT_APP_HOST_API + "/auth/logout",
        {},
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      DeleteCookies();

      navigate("/");
    } catch (error) {
      alert(`Gagal Keluar 
${error.message}`);
    }
  };

  return (
    <Popconfirm
      okText="Logout"
      cancelText="Batal"
      title="Yakin ingin keluar ?"
      onConfirm={
        roles.find((x) => x === "student_candidate")
          ? handleLogoutPpdb
          : handleLogout
      }
    >
      <Link to="#">{children}</Link>
    </Popconfirm>
  );
};

export default Logout;
