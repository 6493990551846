import { Form, Input, Radio, Select } from "antd";
import React from "react";

const TabDataAkademik = () => {
  return (
    <div className="double-form">
      <div>
        <Form.Item label="Unit" name="unit">
          <Select
            options={[
              {
                value: "putra",
                label: "Putra",
              },
              {
                value: "putri",
                label: "Putri",
              },
            ]}
          />
        </Form.Item>

        <Form.Item label="Program" name="program">
          <Select
            options={[
              {
                value: "mahad",
                label: "Ma'had",
              },
              {
                value: "boarding",
                label: "Boarding",
              },
              {
                value: "fullday",
                label: "Fullday",
              },
              {
                value: "wisma",
                label: "Wisma",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="No Ujian Nasional"
          name="nat_exam_no"
          rules={[{ max: 30 }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="No Seri Ijazah" name="junior_hs_cert_no">
          <Input />
        </Form.Item>

        <Form.Item label="Asal Sekolah" name="junior_hs_name">
          <Input />
        </Form.Item>

        <Form.Item label="No Akta Lahir" name="birth_cert_no">
          <Input />
        </Form.Item>
        <Form.Item label="Penerima KPS" name="has_kps">
          <Radio.Group>
            <Radio value="true">Ya</Radio>
            <Radio value="flase">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="No KPS" name="kps_number">
          <Input />
        </Form.Item>
      </div>
      <div>
        <Form.Item label="Penerima KIP" name="has_kip">
          <Radio.Group>
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="No KIP" name="kip_number">
          <Input />
        </Form.Item>

        <Form.Item label="Nama di KIP" name="name_on_kip">
          <Radio.Group>
            <Radio value="true">Ada</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Penerima KKS" name="has_kks">
          <Radio.Group>
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="No KKS" name="kks_number">
          <Input />
        </Form.Item>

        <Form.Item label="Layak PIP" name="pip_eligible">
          <Radio.Group>
            <Radio value="true">Ya</Radio>
            <Radio value="false">Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Alasan PIP" name="pip_desc">
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

export default TabDataAkademik;
