import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useMetodePenilaianList = () => {
  return useQuery(
    "get-metode_penilaian-list",
    () => GetList("/academics/metode-pengambilain-nilai"),
    { enabled: false }
  );
};
