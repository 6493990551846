import { Line, Pie } from "@ant-design/plots";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PpdbPendaftar = () => {
  const [dataPerBulan, setDataPerBulan] = useState([]);
  const [dataPerJurusan, setDataPerJurusan] = useState([]);
  const [dataPerProgram, setDataPerProgram] = useState([]);
  const [dataSumberInfo, setDataSumberInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API: api } = process.env;

  const fetchDataSiswa = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(api + "/charts/pendaftar-baru", {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      setDataPerBulan(
        data.pendaftarPerbulan.map((x) => {
          return { ...x, total: Number(x.total) };
        })
      );
      setDataPerJurusan(
        data.pendaftarPerJurusan.map((x) => {
          return { ...x, total: Number(x.total) };
        })
      );
      setDataPerProgram(
        data.pendaftarPerProgram.map((x) => {
          return { ...x, total: Number(x.total) };
        })
      );
      setDataSumberInfo(
        data.pendaftarSumberInfo.map((x) => {
          return { ...x, total: Number(x.total) };
        })
      );
    } catch (error) {
      if (error.response.status === 401) {
        if (
          error.response.statusText === "TOKEN" ||
          error.response.statusText === "UNVERIFIED"
        ) {
          return <Navigate to="/" />;
        } else {
          return <Navigate to="/permission-error" />;
        }
      }
      alert("ERROR", error.message);
    }
    setLoading(false);
  }, [api]);

  useEffect(() => {
    fetchDataSiswa();
  }, [fetchDataSiswa]);

  const configPerBulan = {
    data: dataPerBulan,
    xField: "bulan",
    yField: "total",
    colorField: "bulan",
    label: {},
    smooth: true,
    point: { size: 2, shape: "circle" },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [{ type: "marker-active" }],
    yAxis: {
      grid: { line: { style: { stroke: "#ddd" } } },
      min: 0,
      tickCount: 5,
    },
  };

  const configPerJurusan = {
    appendPadding: 5,
    data: dataPerJurusan,
    angleField: "total",
    colorField: "jurusan",
    label: { type: "inner" },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    legend: { position: "bottom" },
  };

  const configPerProgram = {
    appendPadding: 5,
    data: dataPerProgram,
    angleField: "total",
    colorField: "program",
    label: { type: "inner" },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    legend: { position: "bottom" },
  };

  const configSumberInfo = {
    appendPadding: 5,
    data: dataSumberInfo,
    angleField: "total",
    colorField: "sumber",
    label: { type: "spider", labelHeight: 28, content: "{name}\n{percentage}" },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    legend: false,
  };

  return (
    <div>
      <div className="chart-wrapper2">
        <div className="chart-item">
          <h3>Pendaftar Per Bulan</h3>
          <Line
            {...configPerBulan}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
        <div className="chart-item">
          <h3>Pendaftar Per Jurusan</h3>
          <Pie
            {...configPerJurusan}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
      </div>
      <div className="chart-wrapper">
        <div className="chart-item">
          <h3>Pendaftar Per program</h3>
          <Pie
            {...configPerProgram}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
        <div className="chart-item">
          <h3>Sumber Info Pendaftar</h3>
          <Pie
            {...configSumberInfo}
            height={window.innerHeight > 800 ? 300 : 230}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default PpdbPendaftar;
