import { Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useRoleList } from "hooks/role-hook/useRoleList";
import React, { useEffect, useState } from "react";

const AddUserRole = ({ id, show, userRoles, onAdd, onCancel }) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API } = process.env;

  const { data: dataRole, refetch: fetchRoles } = useRoleList();

  // const userRoles = decryptCookies("role").split(",");

  // console.log(userRoles);

  useEffect(() => {
    if (show) fetchRoles();

    return () => setRoles([]);
  }, [show, fetchRoles]);

  const onSubmit = async () => {
    if (roles.length === 0) {
      alert("Tidak ada role yang dipilih");
      return;
    } else {
      setLoading(true);
      try {
        const { data } = await axios.post(
          REACT_APP_HOST_API + `/system/user-roles/${id}`,
          { userRoles: roles },
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        message.success(data.message);
        onAdd();
      } catch (error) {
        alert(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={show}
      title="Tambah Role"
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{ loading }}
    >
      <Select
        style={{ width: "100%" }}
        mode="multiple"
        showSearch
        value={roles}
        optionFilterProp="children"
        onChange={setRoles}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {dataRole?.data
          .filter((x) => !userRoles.some((y) => y.role_name === x.name))
          .map((role) => (
            <Select.Option key={role.name} value={role.name}>
              {role.name}
            </Select.Option>
          ))}
      </Select>
    </Modal>
  );
};

export default AddUserRole;
