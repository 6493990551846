import { Form, Input, message, Modal, Radio } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

export default function EditUserParent({ open, setOpen, data }) {
  const [radioValue, setRadioValue] = useState();
  const [newData, setNewData] = useState({});
  const { REACT_APP_HOST_API } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        name: data?.name,
        email: data?.email,
      });

      setRadioValue(data?.user?.verified);
    }
  }, [data, open, form]);

  const onCancel = () => {
    form.resetFields();
    setNewData({});
    setOpen();
  };

  const handleRadioChange = ({ target: { value } }) => {
    setRadioValue(value);
    newData["verified"] = value;
  };

  const handleSubmit = async () => {
    await form.validateFields();
    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang berubah");
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/system/parents/${data?.id}`,
        newData,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Data Berhasil Ditambah");
      onCancel();
    } catch (error) {
      alert(error?.response?.data?.message || "Gagal menyimpan data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => setNewData({});
  }, []);

  return (
    <Modal
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={isLoading}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Nama" name="name">
          <Input
            onChange={({ target: { value } }) => (newData["name"] = value)}
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ type: "email", message: "Please input a valid email" }]}
        >
          <Input
            onChange={({ target: { value } }) => (newData["email"] = value)}
          />
        </Form.Item>

        <Form.Item
          label="Terverifikasi"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Radio.Group
            id="verified"
            value={radioValue}
            defaultValue={true}
            onChange={handleRadioChange}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
