import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useKecList = (kodeKota) => {
  return useQuery(
    ["get-kec-list", kodeKota],
    () => GetList(`/wilayah?t=kec&p=${kodeKota}&k=`),
    { enabled: false }
  );
};
