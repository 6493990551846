import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Modal,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import { BsDownload } from "react-icons/bs";
import "./RecapPresence.css";
import RecapPresenceLogic from "./RecapPresenceLogic";

const { Panel } = Collapse;

const RecapPresence = () => {
  const {
    form,
    format,
    dataKey,
    columns,
    skeleton,
    showModal,
    isLoading,
    dataRecap,
    isFetching,
    idActivity,
    pagination,
    modalTitle,
    dataActivity,
    dataRecapHour,
    showDetailModal,
    handleExport,
    handleCancelModal,
    handleDateToChange,
    setShowDetailModal,
    handleDateFromChange,
    handleActivityChange,
  } = RecapPresenceLogic();

  return (
    <>
      {idActivity === ":id" && (
        <Modal
          title="Pilih Aktivitas"
          open={showModal}
          onCancel={handleCancelModal}
          footer={[<Button key="cancel" hidden />]}
        >
          {skeleton ? (
            <Skeleton active />
          ) : (
            <Select
              style={{ width: "100%" }}
              showSearch
              onChange={(val) => handleActivityChange(val)}
            >
              {dataActivity?.data?.map((data) => (
                <Select.Option key={data.id} value={data.id}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Modal>
      )}

      {idActivity !== ":id" && (
        <>
          <div className="table-header">
            <h1>Rekap Presensi</h1>
            <div className="includes-export">
              <Tooltip title="Export">
                <BsDownload
                  onClick={handleExport}
                  style={{
                    fontSize: "1.7em",
                    color: "#666",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              marginBottom: 30,
            }}
          >
            {dataRecap && (
              <table className="recap-presence__table">
                <tbody>
                  <tr>
                    <th>Total Hari</th>
                    <td>: {dataRecap?.overview?.day_total}</td>
                  </tr>
                  <tr>
                    <th>Karyawan dengan Kehadiran Terbanyak</th>
                    <td>: {dataRecap?.overview?.employee_most_present}</td>
                  </tr>
                  <tr>
                    <th>Total Hari Kehadiran</th>
                    <td>: {dataRecap?.overview?.employee_presence_total}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <Form form={form} layout="vertical">
            <div
              style={{
                maxWidth: 600,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 10,
              }}
            >
              <Tooltip title="Tanggal Awal">
                <Form.Item
                  hasFeedback
                  name="dateFrom"
                  style={{ width: "100%", marginBottom: 0 }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue("dateTo").format(format) >=
                            value.format(format)
                        ) {
                          handleDateFromChange(value);
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            `Masukkan maksimal tanggal ${getFieldValue(
                              "dateTo"
                            ).format(format)}`
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker format={format} />
                </Form.Item>
              </Tooltip>
              <Tooltip title="Tanggal Akhir">
                <Form.Item
                  hasFeedback
                  name="dateTo"
                  style={{ width: "100%", marginBottom: 0 }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue("dateFrom").format(format) <=
                            value.format(format)
                        ) {
                          handleDateToChange(value);
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            `Masukkan minimal tanggal ${getFieldValue(
                              "dateFrom"
                            ).format(format)}`
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker format={format} />
                </Form.Item>
              </Tooltip>
            </div>
          </Form>
          <Table
            size="small"
            dataSource={dataKey}
            loading={isLoading || isFetching}
            pagination={pagination}
            scroll={{
              y: "50vh",
              x: 800,
            }}
          >
            {columns}
          </Table>
        </>
      )}

      <Modal
        width={
          window.innerWidth < 1000
            ? 520
            : window.innerWidth <= 1300
            ? 700
            : 1000
        }
        title={
          <>
            <div className="modal-title">
              <p>Rekap Jam Kehadiran</p>
              <>{modalTitle}</>
            </div>
          </>
        }
        open={showDetailModal}
        closable={false}
        onCancel={() => setShowDetailModal(false)}
        footer={[
          <Button
            key={1}
            type="primary"
            onClick={() => setShowDetailModal(false)}
          >
            OK
          </Button>,
        ]}
      >
        <Collapse accordion>
          {Object.values(dataRecapHour)?.map((x, i) => (
            <Panel
              header={
                <>
                  <div>
                    <span>Pekan Ke: {Object.keys(dataRecapHour)[i]}</span>
                    <span>Total Jam: {x.totalHoursInWeek}</span>
                  </div>
                </>
              }
              key={x.weekNumber}
            >
              <Table
                size="small"
                pagination={false}
                columns={[
                  { title: "Waktu Masuk", dataIndex: "timeIn" },
                  { title: "Waktu Pulang", dataIndex: "timeOut" },
                  {
                    title: "Total Jam Presensi",
                    dataIndex: "originalTotalHours",
                  },
                  { title: "Total Jam Dihitung", dataIndex: "totalHours" },
                  { title: "Selisih Waktu", dataIndex: "timeDiff" },
                ]}
                dataSource={x.dates
                  .sort((a, b) => a.timeIn.localeCompare(b.timeIn))
                  .map((data) => {
                    return {
                      ...data,
                      key: data.timeIn,
                      timeIn: moment(data.timeIn).format("YYYY-MM-DD HH:mm:ss"),
                      originalTotalHours: data.originalTotalHours,
                      timeDiff: data.workingTimeDiff,
                      timeOut: moment(data.timeOut).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                    };
                  })}
              />
            </Panel>
          ))}
        </Collapse>
      </Modal>
    </>
  );
};

export default RecapPresence;
