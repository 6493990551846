import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useClassListFoundation = (foundationId) => {
  return useQuery(
    ["get-class-list-foundation", foundationId],
    () =>
      GetList(
        `/academics/classes?mode=list&is_graduated=false&foundationId=${foundationId}`
      ),
    { enabled: true }
  );
};
