import moment from "moment";
import { useState } from "react";
import { createContext } from "react";

const newDate = new Date();
const firstDate = newDate;
const lastDate = newDate;

export const LeaveSessionContext = createContext();

export const LeaveSessionProvider = ({ children }) => {
  const [leaveId, setLeaveId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [fetchLeave, setFetchLeave] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });
  const [status, setStatus] = useState("");

  return (
    <LeaveSessionContext.Provider
      value={{
        date,
        keyword,
        leaveId,
        lastDate,
        firstDate,
        fetchLeave,
        showCreate,
        showEdit,
        status,
        setStatus,
        setDate,
        setKeyword,
        setLeaveId,
        setFetchLeave,
        setShowCreate,
        setShowEdit,
      }}
    >
      {children}
    </LeaveSessionContext.Provider>
  );
};
