import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useSubjectMemberList = ({ dataTable, keyword, subject_id }) => {
  return useQuery(
    [
      "get-student-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      subject_id,
    ],
    () =>
      GetPagination(
        // `/academics/subjects-members?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&subjectId=${subject_id}`
        `/academics/subjects/${subject_id}/members?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}`
      )
  );
};
