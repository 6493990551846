import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useKompetensiIntiList } from "hooks/prosem-detail-hook/useKompetensiIntiList";
import { useMetodePenilaianList } from "hooks/prosem-detail-hook/useMetodePenilaianList";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CreateProsemDetailLogic = ({ open, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API: api } = process.env;
  const { prosem_id } = useParams();

  // get KI list
  const { data: dataKI, refetch: fetchKI } = useKompetensiIntiList();

  // get metode penilaian list
  const { data: dataMetode, refetch: fetchMetode } = useMetodePenilaianList();

  useEffect(() => {
    if (open) {
      fetchKI();
      fetchMetode();
    }
  }, [open, fetchKI, fetchMetode]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    ["kategori1", "kategori2", "kategori3"].forEach((kategori) => {
      if (!values[kategori]) {
        values[kategori] = false;
      }
    });

    setLoading(true);

    try {
      const { data } = await axios.post(
        api +
          `/academics/program-semester/${prosem_id}/program-semester-detail`,
        values,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      onCreate();
      form.resetFields();
      message.success(data.message);
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return {
    form,
    loading,
    dataKI,
    dataMetode,
    onSubmit,
    onCancelModal,
  };
};

export default CreateProsemDetailLogic;
