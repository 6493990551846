import { Form, Input, message, Modal, Radio } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useState } from "react";

export default function AddUserParent({ open, setOpen }) {
  const [radioValue, setRadioValue] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const { REACT_APP_HOST_API } = process.env;

  const onCancel = () => {
    form.resetFields();
    setRadioValue(true);
    setOpen();
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const postData = { ...values, verified: radioValue };
    setIsLoading(true);
    try {
      await axios.post(`${REACT_APP_HOST_API}/system/parents`, postData, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      message.success("Data Berhasil Ditambah");
      onCancel();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.data?.length > 0) {
        alert(error?.response?.data?.data.map((m) => m?.message));
      } else {
        alert(error?.response?.data?.message || "Gagal menambah data!");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={handleSubmit}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={isLoading}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 5, message: "Masukkan minimal 5 karakter" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Harap diisi" },
            { type: "email", message: "Please input a valid email" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="NISN Anak"
          name="nisn"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Konfirmasi Password"
          name="password_confirmation"
          hasFeedback
          rules={[
            { required: true, message: "Harap diisi" },
            { min: 6, message: "Masukkan minimal 6 karakter" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password harus sama"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Terverifikasi"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Radio.Group
            id="verified"
            value={radioValue}
            defaultValue={true}
            onChange={(e) => setRadioValue(e.target.value)}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
