import { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import "./auth.css";

const Auth = () => {
  const [showRegister, setShowRegister] = useState(false);

  const handleLoginButton = () => setShowRegister(true);
  const onCancel = () => setShowRegister(false);

  return (
    <div className="login">
      <Login loginBtn={handleLoginButton} />

      <Register open={showRegister} onCancel={onCancel} />
    </div>
  );
};

export default Auth;
