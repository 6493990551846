import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const KontakSiswaTab = () => {
  const { candidate_id } = useParams();
  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>No. Telp</th>
                <td>{dataInti.phone && dataInti.phone}</td>
              </tr>
              <tr>
                <th>No. Hp</th>
                <td>{dataInti.mobile_phone && dataInti.mobile_phone}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {dataInti.correspondence_email &&
                    dataInti.correspondence_email}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default KontakSiswaTab;
