import { Form, Modal, Select } from "antd";
import AddTeachingsLogic from "./AddTeachingsLogic";

const AddTeachings = (props) => {
  const {
    form,
    handleSubmit,
    onCancelModal,
    posting,
    dataClass,
    dataPelajaran,
    setValueClass,
    //  valueClass,
    isEkskul,
    valuePelajaran,
    setValuePelajaran,
  } = AddTeachingsLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: posting }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="subjectId"
          label="Pelajaran"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Select
            showSearch
            // disabled={valueClass === ""}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value) => setValuePelajaran(value)}
          >
            {dataPelajaran?.data?.map((pelajaran) => (
              <Select.Option key={pelajaran.id} value={pelajaran.id}>
                {pelajaran.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="classId"
          label="Kelas"
          rules={[
            {
              required: !isEkskul,
              message: "Harap diisi",
            },
          ]}
        >
          <Select
            disabled={valuePelajaran === "" || isEkskul}
            onChange={(value) => setValueClass(value)}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {dataClass?.data?.map((kelas) => (
              <Select.Option key={kelas.id} value={kelas.id}>
                {kelas.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTeachings;
