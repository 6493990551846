import { UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React from "react";

const TabDataAyah = ({
  newDataParent,
  fileAyah,
  setFileAyah,
  filteredFather,
}) => {
  // const { REACT_APP_DEV } = process.env;
  const { REACT_APP_HOST_API } = process.env;

  const uploadKtpAyah = async (file) => {
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/parents/${filteredFather[0].id}/file-upload`,
        { file: file, category: "ktp" },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("KTP berhasil diubah!");
    } catch (error) {
      alert("Gagal mengubah KTP Ibu");
    }
  };

  return (
    <div className="double-form">
      <div>
        <Form.Item
          label="Nama Ayah"
          name="father0_0name"
          rules={[
            {
              pattern: new RegExp("^[A-Za-z ]+$"),
              message: "Nama hanya dapat berupa huruf",
            },
            {
              min: 5,
              message: "Nama minimal 5 karakter",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0name = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="KTP Ayah"
          name="ktp_ayah"
          rules={[
            {
              required: fileAyah.length === 0,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Upload
            listType="picture"
            accept=".png,.jpg,.jpeg,.gif,.pdf"
            fileList={fileAyah}
            beforeUpload={(file) => {
              if (fileAyah.length >= 1) {
                message.error("Allowed only 1 file");
                return false;
              }
              if (file.size > 2000000) {
                message.error("Maks File 2MB");
                return false;
              }
              if (filteredFather.length !== 0) {
                uploadKtpAyah(file);
              }
              setFileAyah([file]);
              return false;
            }}
            onRemove={(file) => {
              setFileAyah([]);
            }}
          >
            {fileAyah.length === 0 && (
              <Button type="primary" icon={<UploadOutlined />}>
                Upload
              </Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="NIK "
          name="father0_0nik"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              required: true,
              message: "Nik wajib diisi",
            },
            { max: 16, message: "Maksimal 16 karakter" },
            {
              min: 16,
              message: "Minimal 16 Karakter",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0nik = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Tahun Lahir"
          name="father0_0birth_date"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <DatePicker
            onChange={(e) => (newDataParent.father0_0birth_date = e)}
          />
        </Form.Item>

        <Form.Item
          label="Jenjang Pendidikan"
          name="father0_0education"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Select
            onChange={(a) => {
              newDataParent.father0_0education = a;
            }}
            options={[
              {
                value: "SD / Sederajat",
                label: "SD/Sederajat",
              },
              {
                value: "SMP / Sederajat",
                label: "SMP/Sederajat",
              },
              {
                value: "SMA / Sederajat",
                label: "SMA/Sederajat",
              },
              {
                value: "D1",
                label: "D1",
              },
              {
                value: "D2",
                label: "D2",
              },
              {
                value: "D3",
                label: "D3",
              },
              {
                value: "D4",
                label: "D4",
              },
              {
                value: "S1",
                label: "S1",
              },
              {
                value: "S2",
                label: "S2",
              },
            ]}
          />
        </Form.Item>
      </div>

      <div>
        <Form.Item
          label="Pekerjaan"
          name="father0_0occupation"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              max: 40,
            },
            {
              pattern: new RegExp("^[A-Za-z ]+$"),
              message: "Nama hanya dapat berupa huruf dan spasi",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0occupation = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Penghasilan Minimun"
          name="father0_0min_salary"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              max: 10,
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0min_salary = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Penghasilan Maximum"
          name="father0_0max_salary"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              max: 10,
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0max_salary = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Alamat"
          name="father0_0address"
          rules={[
            {
              required: true,
              message: "data tidak boleh kosong",
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0address = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="No. Telp"
          name="father0_0phone_number"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            {
              required: true,
              message: "data tidak boleh kosong",
            },
            {
              max: 16,
            },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0phone_number = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="father0_0email"
          rules={[{ type: "email", message: "email tidak valid" }, { max: 50 }]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.father0_0email = value)
            }
          />
        </Form.Item>

        {/* <Form.Item label="Hubungan Dengan Siswa" name="relationship_w_student">
        <Select
          disabled={true}
          options={[
            {
              value: "biological father",
              label: "Ayah Kandung",
            },
          ]}
        />
      </Form.Item> */}
      </div>
    </div>
  );
};

export default TabDataAyah;
