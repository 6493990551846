import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Popconfirm, Tag, Tooltip, Select } from "antd";
import AddUser from "../create/AddUser";
import EditUser from "../edit/EditUser";
import ResetPasswordUsers from "../reset/ResetPasswordUser";
import { DeleteApi } from "api/DeleteApi";
import { useUserPagination } from "hooks/user-hook/useUserPagination";
import { useCallback, useState } from "react";
import { usePermission } from "hooks/usePermissions";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import "./MasterUser.css";

const disabled = { cursor: "not-allowed", color: "#ccc" };

const MasterUser = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const { data, isLoading, isFetching, refetch } = useUserPagination(
    dataTable,
    keyword,
    foundationId
  );

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();


  // fetch permission
  const { data: dataPermission } = usePermission("mdlSystem", "mnuUsers");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddUser");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditUser");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteUser");
  const btnReset = dataPermission?.find((x) => x.id === "btnResetPassword");

  const onCancel = () => {
    setShowAddUser(false);
    setShowEditUser(false);
    setShowResetPassword(false);
  };

  const onCreate = useCallback(() => {
    setShowAddUser(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    refetch();
  }, [refetch]);

  const onReset = () => {
    setShowResetPassword(false);
    refetch();
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      align: "left",
      fixed: "left",
      width: 150,
    },
    { title: "Role", dataIndex: "role", align: "left" },
    { title: "Email", dataIndex: "email", align: "left" },
    { title: "Nama karyawan", dataIndex: "nameEmployee", align: "left" },
    {
      title: "Nip Karyawan",
      dataIndex: "nipEmployee",
      align: "left",
      width: 120,
      responsive: ["sm"],
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                DeleteApi({ url: "/system/users/", dataId: id, refetch })
              }
              }
              disabled={btnDelete?.type === "disabled"}
            >
              <Tag color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDelete?.type === "disabled") {
                    return;
                  } else {
                    setDataId(id);
                  }
                }}
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="magenta"
              style={
                btnReset?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              hidden={!btnReset}
              onClick={() => {
                if (btnReset?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowResetPassword(true);
                }
              }}
            >
              Reset Password
            </Tag>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditUser(true);
                }
              }}
            >
              Ubah
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        nameEmployee: data.employee ? data.employee.name : "",
        nipEmployee: data.employee ? data.employee.nip : "",
        role: data?.roles?.map((x) => x.role_name).join(" | "),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar User</h1>
        <Space>
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={handleChange}
            placeholder="Cari User"
          />
          {isSuperAdmin &&
            <Tooltip Tooltip title="Yayasan">
              <Select
                placeholder="Pilih Yayasan"
                style={{ minWidth: 150, maxWidth: 150 }}
                onChange={(x) => setFoundationId(x)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">ALL</Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>}
          <Button type="primary"
            onClick={() => setShowAddUser(true)}
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah User
          </Button>
        </Space>
      </div>
      <div className="search-wrapper filter-wrapper"></div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataKey}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? { y: "50vh", x: 1100 }
            : { y: "55vh", x: 1100 }
        }
      />
      <AddUser show={showAddUser} onCreate={onCreate} onCancel={onCancel} />
      <EditUser
        id={dataId}
        show={showEditUser}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
      <ResetPasswordUsers
        id={dataId}
        show={showResetPassword}
        onReset={onReset}
        onCancel={onCancel}
      />
    </div>
  );
};

export default MasterUser;
