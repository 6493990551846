import useConvertImgToBase64 from "components/Helper/useConvertImgToBase64";

const ReportLogo = ({ data }) => {
  const imageUrl = data?.foundation?.logo;

  const imgBase64 = useConvertImgToBase64(imageUrl);
  return (
    <div className="report-logo">
      <div>
        <img src={imgBase64} alt="logo fg" />
      </div>
      <div className="text">
        <h1>{data?.foundation?.name}</h1>
        <p className="address">{data?.foundation?.address}</p>
        <span>
          Website: {data?.foundation?.website}
          {/* <a href="https://smafuturegate.com/" target="_blank" rel="noreferrer">
            www.smafuturegate.com
          </a> */}
        </span>
        <span> Email: {data?.foundation?.email}</span>
      </div>
    </div>
  );
};

export default ReportLogo;
