import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useStudentCandidatesPagination = (dataTable, keyword) => {
  return useQuery(
    [
      "get-student-candidates-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
    ],
    () =>
      GetPagination(
        `/ppdb/student-candidates?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&show_all=1`
      )
  );
};
