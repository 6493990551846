import { Input, Table, message } from "antd";
import Modal from "antd/es/modal/Modal";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { ActivityContext } from "context/ActivityContext";
import { useEmployeeMember } from "hooks/activity-hook/useEmployeeMember";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AddMemberActivity = () => {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const { showAddMember, setShowAddMember, setFetchActivity } =
    useContext(ActivityContext);

  const { activity_id } = useParams();
  const { REACT_APP_HOST_API: api } = process.env;

  const { data, isFetching, refetch } = useEmployeeMember(activity_id, keyword);

  useEffect(() => {
    if (showAddMember) refetch();
  }, [showAddMember, refetch, keyword]);

  const columns = [{ title: "Nama", dataIndex: "name" }];

  const dataSource = data?.data
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .map((x) => {
      return {
        id: x.id,
        key: x.id,
        name: x.name,
      };
    });

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRow(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        api + `/activity-members`,
        {
          activityMembers: selectedRow?.map((x) => {
            return {
              employeeId: x,
              activityId: activity_id,
              role: "member",
            };
          }),
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      setShowAddMember(false);
      setFetchActivity(true);
      setSelectedRow([]);
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
      setKeyword("");
    }
  };

  const onCancelModal = () => {
    setShowAddMember(false);
    setSelectedRow([]);
    setKeyword("");
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      closable={false}
      open={showAddMember}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      style={window.innerHeight > 670 ? {} : { top: 30 }}
    >
      <Input
        allowClear
        value={keyword}
        onChange={({ target: { value } }) => setKeyword(value)}
        placeholder="cari nama..."
      />
      <Table
        size="small"
        className="table-default"
        columns={columns}
        pagination={false}
        loading={isFetching}
        dataSource={dataSource}
        rowSelection={{ ...rowSelection }}
        scroll={{ y: "55vh" }}
      />
    </Modal>
  );
};

export default AddMemberActivity;
