import { Form, Input, Modal, Select, Skeleton, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

const EditInterview = ({ open, setOpen, idInterview }) => {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const getData = async () => {
    setIsFetch(true);
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/interviews/${idInterview}`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );

      await form.setFieldsValue({
        topic: data.data.topic,
        interviewer_name: data.data.interviewer_name,
        program_result: data.data.program_result,
        major_result: data.data.major_result,
        spp_result: data.data.spp_result,
        note: data.data.note,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetch(false);
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    }
    // eslint-disable-next-line
  }, [open]);

  const handleCancel = () => {
    setOpen();
    form.resetFields();
  };

  const handleSubmit = async () => {
    const val = await form.validateFields();
    setIsLoading(true);

    try {
      const newData = {
        ...val,
      };
      await axios.put(
        `${REACT_APP_HOST_API}/ppdb/interviews/${idInterview}`,
        newData,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Berhasil mengubah interview");
      form.resetFields();
      setOpen();
    } catch (error) {
      alert(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      style={{ marginTop: "-80px" }}
      open={open}
      onCancel={handleCancel}
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      {isFetch ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item label="Topik" name="topic" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  value: "umum",
                  label: "Umum",
                },
                {
                  value: "tahfidz",
                  label: "Tahfidz",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Nama Interviewer"
            name="interviewer_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Program"
            name="program_result"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                {
                  value: "mahad",
                  label: "Mahad",
                },
                {
                  value: "boarding",
                  label: "Boarding",
                },
                {
                  value: "fullday",
                  label: "Fullday",
                },
                {
                  value: "wisma",
                  label: "Wisma",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Jurusan"
            name="major_result"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                {
                  value: "mipa",
                  label: "Mipa",
                },
                {
                  value: "ips",
                  label: "Ips",
                },
                {
                  value: "bahasa",
                  label: "Bahasa",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label="SPP" name="spp_result" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  value: "2_juta",
                  label: "2 Juta",
                },
                {
                  value: "1_5_juta",
                  label: "1.5 Juta",
                },
                {
                  value: "1_3_juta",
                  label: "1.3 Juta",
                },
                {
                  value: "1_1_juta",
                  label: "1.1 Juta",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label="Catatan" name="note">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditInterview;
