import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import moment from "moment";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const PendaftaranAwalTab = () => {
  const { candidate_id } = useParams();

  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Nama</th>
                <td>{dataInti.full_name && dataInti.full_name}</td>
              </tr>
              <tr>
                <th>NSIN</th>
                <td>{dataInti.nisn && dataInti.nisn}</td>
              </tr>
              <tr>
                <th>Tanggal Lahir</th>
                <td>{dataInti.birth_day && dataInti.birth_day}</td>
              </tr>
              <tr>
                <th>Asal Sekolah</th>
                <td>{dataInti.junior_hs_name && dataInti.junior_hs_name}</td>
              </tr>

              <tr>
                <th>Jenis Kelamin</th>
                <td>
                  {dataInti.gender && dataInti.gender === "male"
                    ? "Laki-laki"
                    : dataInti.gender === "female"
                    ? "Perempuan"
                    : ""}
                </td>
              </tr>

              <tr>
                <th>Agama</th>
                <td>{dataInti.religion && dataInti.religion}</td>
              </tr>

              <tr>
                <th>No.Telp / Whatsapp</th>
                <td>
                  {dataInti.correspondence_phone &&
                    dataInti.correspondence_phone}
                </td>
              </tr>

              <tr>
                <th>Email</th>
                <td>
                  {dataInti.correspondence_email &&
                    dataInti.correspondence_email}
                </td>
              </tr>

              <tr>
                <th>Mengetahui SMA FG dari</th>
                <td>
                  {dataInti.info_source &&
                  dataInti.info_source === "acquaintance"
                    ? "Kenalan"
                    : dataInti.info_source === "social_media"
                    ? "Sosial Media"
                    : dataInti.info_source === "fg_website"
                    ? "Website SMA FG"
                    : dataInti.info_source === "expo"
                    ? "Expo"
                    : dataInti.info_source === "newspaper"
                    ? "Koran"
                    : dataInti.info_source === "radio"
                    ? "Radio"
                    : "Brosur"}
                </td>
              </tr>

              <tr>
                <th>Alasan Mendaftar di SMA FG</th>
                <td>{dataInti.interest_in_fg && dataInti.interest_in_fg}</td>
              </tr>

              <tr>
                <th>SPP Pilihan</th>
                <td>
                  {dataInti.spp_choice && dataInti.spp_choice === "1_1_juta"
                    ? "1.1 Juta"
                    : dataInti.spp_choice === "1_3_juta"
                    ? "1.3 Juta"
                    : dataInti.spp_choice === "1_5_juta"
                    ? "1.5 Juta"
                    : "2 Juta"}
                </td>
              </tr>

              <tr>
                <th>Program Pilihan</th>
                <td>{dataInti.program_choice && dataInti.program_choice}</td>
              </tr>

              <tr>
                <th>Jurusan Pilihan</th>
                <td>{dataInti.major_choice && dataInti.major_choice}</td>
              </tr>

              <tr>
                <th>Jadwal Test</th>
                <td>
                  {dataInti.entranceExamSchedule &&
                  moment(dataInti.entranceExamSchedule.time_start).format(
                    "DD-MMM-YYYY"
                  ) ===
                    moment(dataInti.entranceExamSchedule.time_end).format(
                      "DD-MMM-YYYY"
                    )
                    ? `${moment(
                        dataInti.entranceExamSchedule.time_start
                      ).format("DD-MMM-YYYY | HH:mm")} - ${moment(
                        dataInti.entranceExamSchedule.time_end
                      ).format(" HH:mm")}`
                    : `${moment(
                        dataInti.entranceExamSchedule.time_start
                      ).format("DD-MMM-YYYY | HH:mm")} - ${moment(
                        dataInti.entranceExamSchedule.time_end
                      ).format("DD-YYYY-MMM | HH:mm")}`}
                </td>
              </tr>

              <tr>
                <th>SPP Final</th>
                <td>
                  {dataInti.spp_final && dataInti.spp_final === "1_1_juta"
                    ? "1.1 Juta"
                    : dataInti.spp_final === "1_3_juta"
                    ? "1.3 Juta"
                    : dataInti.spp_final === "1_5_juta"
                    ? "1.5 Juta"
                    : "2 Juta"}
                </td>
              </tr>

              <tr>
                <th>Program Final</th>
                <td>{dataInti.program_final && dataInti.program_final}</td>
              </tr>

              <tr>
                <th>Jurusan Final</th>
                <td>{dataInti.major_final && dataInti.major_final}</td>
              </tr>

              <tr>
                <th>Foto</th>
                <td>
                  {dataInti.photo && (
                    <img
                      style={{ width: "150px" }}
                      src={dataInti.photo && dataInti.photo}
                      alt="student"
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default PendaftaranAwalTab;
