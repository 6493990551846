import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useKotaList = (kodeProv) => {
  return useQuery(
    ["get-kota-list", kodeProv],
    () => GetList(`/wilayah?t=kot&p=${kodeProv}&k=`),
    { enabled: false }
  );
};
