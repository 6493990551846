import { GetList } from 'api/GetList';
import { useQuery } from 'react-query';

export const usePelajaranFilter = (classId, TeacherId) => {
  return useQuery(
    ['get-pelajaran-filter', classId, TeacherId],
    () =>
      GetList(
        `/academics/subjects?page=&limit=&keyword=&mode=list&classId=${classId}&teacherId=${TeacherId}`
      ),
    { enabled: false }
  );
};
