import { Button, message, Typography } from "antd";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import IntervalOtp from "./Component/IntervalOtp";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";

const { Link } = Typography;

export default function OtpVerification() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  const [showCountdown, setShowCountdown] = useState(true);

  const email = decryptCookies("emailForgotPassword");

  const handleResendOTP = async () => {
    setShowCountdown(true);
    try {
      await axios.post(REACT_APP_HOST_API + "/auth/send-otp-mail", {
        email,
      });
      message.success("OTP Telah Dikirim!");
    } catch (error) {
      console.log(error);
      alert("Gagal mengirim OTP!");
    }
  };

  const handleCountdownEnd = () => {
    setShowCountdown(false);
  };

  const onSubmit = async () => {
    try {
      setIsLoad(true);
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/auth/verify-otp",
        {
          email,
          otp,
        }
      );
      message.success(data?.message);
      navigate(`/create-password/${data?.data}`);
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.message);
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ marginTop: "80px", padding: "20px", maxWidth: "370px" }}>
        <div style={{ marginBottom: "50px" }}>
          <h1 style={{ fontWeight: "bold" }}>Verifikasi Kode OTP</h1>
          <p style={{ fontSize: "14px", lineHeight: "20px" }}>
            Masukkan kode verifikasi yang baru saja kami kirimkan ke alamat
            email Anda.
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <OtpInput
            inputStyle={{
              width: "52px",
              height: "50px",
              margin: "0 20px",
              border: "1px solid #acacac",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <div>
          {showCountdown ? (
            <IntervalOtp
              initialCount={60}
              onCountdownEnd={handleCountdownEnd}
            />
          ) : (
            <p
              style={{
                fontSize: "14px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              Saya tidak menerima kode.{" "}
              <Link onClick={handleResendOTP}>Kirim Ulang</Link>
            </p>
          )}
        </div>

        <Button
          onClick={onSubmit}
          type="primary"
          size="large"
          style={{ width: "100%", marginTop: "20px" }}
          loading={isLoad}
          disabled={otp.length < 4}
        >
          Verifikasi OTP
        </Button>
      </div>
    </div>
  );
}
