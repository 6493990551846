import { LockTwoTone } from "@ant-design/icons";
import { Form, Input, Modal, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useState } from "react";

const ResetPassword = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const { REACT_APP_HOST_API } = process.env;

  const handleResetPass = async () => {
    const val = await form.validateFields();

    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/ppdb/auth/change-password`,
        val,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success(data.message);
      form.resetFields();
      setOpen();
    } catch (error) {
      //   alert(error);
      message.error("password lama salah!");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      confirmLoading={isLoading}
      open={open}
      onCancel={setOpen}
      onOk={handleResetPass}
      closable={false}
      okText="Ubah Password"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Password Lama"
          name="current_password"
          rules={[
            { min: 6, message: "Enter min 6 character" },
            { required: true },
          ]}
        >
          <Input.Password prefix={<LockTwoTone />} />
        </Form.Item>
        <Form.Item
          label="Password Baru"
          name="new_password"
          hasFeedback
          rules={[
            { min: 6, message: "Enter min 6 character" },
            { required: true },
          ]}
        >
          <Input.Password prefix={<LockTwoTone />} />
        </Form.Item>
        <Form.Item
          label="Konfirmasi Password Baru"
          name="new_password_confirmation"
          hasFeedback
          rules={[
            { min: 6, message: "Enter min 6 character" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password must be the same"));
              },
            }),
            { required: true },
          ]}
        >
          <Input.Password prefix={<LockTwoTone />} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPassword;
