import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const usePresencePagination = (params) => {
  const { dataTable, date, keyword, idActivity } = params;

  return useQuery(
    [
      "get-presence-pagination",
      dataTable.current_page,
      dataTable.per_page,
      date.from,
      date.to,
      keyword,
      idActivity,
    ],
    () =>
      GetPagination(
        `/presence/?activityId=${idActivity}&page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&fromDate=${date.from}&toDate=${date.to}`
      ),
    { enabled: false }
  );
};
