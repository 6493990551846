import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

const DataIbuTab = () => {
  const { student_id } = useParams();
  const { data, isLoading, refetch } = useParentList(student_id, true);

  let dataIbu;

  const filteredData = data?.data?.studentParents?.filter(
    (obj) => obj?.parents?.relationship_w_student === "biological mother"
  );
  dataIbu = filteredData;

  useEffect(() => {
    refetch();
  }, [refetch]);

  const isDataIbuNotEmpty = dataIbu !== undefined && dataIbu.length > 0;

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data.data && (
        <Fragment key={data.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Nama Ibu</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.name}
                </td>
              </tr>
              <tr>
                <th>NIK</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.nik}{" "}
                </td>
              </tr>
              <tr>
                <th>Tahun Lahir</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.birth_date}
                </td>
              </tr>
              <tr>
                <th>Alamat</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.address}
                </td>
              </tr>
              <tr>
                <th>Jenjang Pendidikan</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.education}
                </td>
              </tr>
              <tr>
                <th>Pekerjaan</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.occupation}
                </td>
              </tr>
              <tr>
                <th>Penghasilan Minimum</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.min_salary}
                </td>
              </tr>
              <tr>
                <th>Penghasilan Maximum</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.max_salary}
                </td>
              </tr>

              <tr>
                <th>Nomor Telpon</th>
                <td>
                  {!isDataIbuNotEmpty
                    ? ""
                    : filteredData[0].parents.phone_number}
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {!isDataIbuNotEmpty ? "" : filteredData[0].parents.email}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DataIbuTab;
