import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useDailyAttandance = (
  dataTable,
  keyword,
  classId,
  dateIn,
  dateOut,
  foundationId,
  enabled
) => {
  return useQuery(
    [
      "get-daily-attandce",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      classId,
      dateIn,
      dateOut,
      foundationId,
    ],
    () =>
      GetDetail(
        `/academics/daily-attendances?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&mode=page&classId=${classId}&fromDate=${dateIn}&toDate=${dateOut}&sortingByAbsent=true&foundationId=${foundationId}`
      ),
    {
      enabled,
    }
  );
};
