import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Layout, Drawer, theme, Avatar } from "antd";
import { default as LOGO, default as LogoFG } from "assets/img/logoFG.png";
import BreadCrumb from "components/UI/BreadCrumb";
import React from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import "./LayoutFinance.css";
import LayoutFinanceLogic from "./LayoutFinanceLogic";
import NavMenus from "Layouts/NavMenus";
import { useNavigate } from "react-router-dom";
import DynamicTitle from "components/UI/DynamicTitle";
import NavUserProfile from "components/UI/NavUserProfile";

const { Sider, Content, Header } = Layout;
const { useToken } = theme;

const LayoutFinance = (props) => {
  const {
    email,
    collapsed,
    items,
    items2,
    itemsUser,
    itemsAccount,
    setCollapsed,
    handleClickItemUser,
    handleClickMenu,
    open,
    setOpen,
    isParent,
  } = LayoutFinanceLogic();

  const navigate = useNavigate();

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  return (
    <Layout>
      <DynamicTitle />
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={250}
      >
        <div className="mobile-menu-wrapper">
          <NavMenus
            items={items}
            theme="light"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultFinanceMenu"}
            defaultOpen={["mnuTransaksi"]}
          />
        </div>
      </Drawer>
      <div className="menu-mobile">
        <div onClick={() => navigate("/home")}>
          <img src={LogoFG} alt="logo fg" style={{ width: 22 }} />
          <h1 style={{ fontSize: "1.4em" }}>SMA FG</h1>
        </div>
        <div>
          {isParent ? (
            <div>
              <Dropdown
                menu={{
                  items: itemsAccount,
                }}
                dropdownRender={(menu) => (
                  <div style={contentStyle}>
                    {React.isValidElement(menu)
                      ? React.cloneElement(menu, {
                          style: menuStyle,
                        })
                      : menu}
                  </div>
                )}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      backgroundColor: "#f5f6f9",
                      borderRadius: "10px",
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#1677FF",
                        width: "25px",
                        height: "25px",
                        fontSize: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      icon={<UserOutlined />}
                    />
                    <h1
                      style={{
                        fontSize: "12px",
                        marginTop: "5px",
                        color: "#666",
                      }}
                    >
                      {email}
                    </h1>
                  </div>
                </div>
              </Dropdown>
            </div>
          ) : null}

          <NavUserProfile
            isOnlyIcon={true}
            itemsUser={itemsUser}
            handleClickItemUser={handleClickItemUser}
          />

          <MenuOutlined
            style={{ fontSize: "1.3em" }}
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      <Sider width={250} trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <img src={LOGO} alt="fg" />
          <span>SMA FG</span>
        </div>

        <div className="sider-menu-wrapper">
          <NavMenus
            items={items}
            theme="dark"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultFinanceMenu"}
            defaultOpen={["mnuTransaksi"]}
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header>
          {React.createElement(HiOutlineMenuAlt2, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          <div className="header-container">
            <NavUserProfile
              isOnlyIcon={false}
              itemsUser={itemsUser}
              handleClickItemUser={handleClickItemUser}
            />
          </div>
        </Header>

        <BreadCrumb />

        <Content className="site-layout-background">{props.content}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutFinance;
