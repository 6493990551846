import { createContext, useEffect, useState } from "react";

export const InventoryContext = createContext();

export const InventoryProvider = ({ children }) => {
  const [inventoryFetch, setInventoryFetch] = useState(false);

  useEffect(() => {
    return () => {
      setInventoryFetch(false);
    };
  }, [inventoryFetch]);

  return (
    <InventoryContext.Provider
      value={{
        inventoryFetch,
        setInventoryFetch,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
