import { Skeleton } from "antd";
import { useParentList } from "hooks/parents-hook/useParentList";
import { useStudentDetail } from "hooks/students-hook/useStudentDetail";
import React, { Fragment } from "react";

const IdentitasSiswaTab = ({ studentId }) => {
  const { data, isLoading } = useParentList(studentId, true);
  const { data: dataClass } = useStudentDetail(studentId, true);

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data.data && (
        <Fragment key={data.data.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>NIK</th>
                <td>{data.data.nik && data.data.nik}</td>
              </tr>
              <tr>
                <th>Nama</th>
                <td>{data.data.name && data.data.name}</td>
              </tr>
              <tr>
                <th>Kelas</th>
                <td>{dataClass?.data.class && dataClass.data.class.name}</td>
              </tr>
              <tr>
                <th>NIS</th>
                <td>{data.data.nis && data.data.nis}</td>
              </tr>
              <tr>
                <th>NISN</th>
                <td>{data.data.nisn && data.data.nisn}</td>
              </tr>
              <tr>
                <th>Jenis Kelamin</th>
                <td>
                  {data.data.gender && data.data.gender === "male"
                    ? "Laki-laki"
                    : data.data.gender === "female"
                      ? "Perempuan"
                      : ""}
                </td>
              </tr>
              <tr>
                <th>Kota Lahir</th>
                <td>{data.data.birth_city && data.data.birth_city}</td>
              </tr>
              <tr>
                <th>Tanggal Lahir</th>
                <td>{data.data.birth_day && data.data.birth_day}</td>
              </tr>
              <tr>
                <th>Agama</th>
                <td>{data.data.religion && data.data.religion}</td>
              </tr>
              <tr>
                <th>Universitas</th>
                <td>{data.data.institution && data.data.institution}</td>
              </tr>
              <tr>
                <th>Jurusan</th>
                <td>{data.data.department && data.data.department}</td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default IdentitasSiswaTab;
