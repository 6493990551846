import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useMemberActivity = (id, keyword) => {
  return useQuery(
    ["get-member-activity", id, keyword],
    () =>
      GetList(
        `/activity-members?activityId=${id}&keyword=${keyword ? keyword : ""}`
      ),
    { enabled: false }
  );
};
