import { Result } from "antd";
import React from "react";

export default function LinkGroup() {
  return (
    <div>
      <Result
        status="500"
        title="Sorry, This page is under construction."
        subTitle="We will add this page soon."
      />
    </div>
  );
}
