// /academics/daily-attendances?page=1&limit=10&mode=page&fromDate=2023-06-05&toDate=2023-06-12&recap=true
import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useTeachersPerSubjectFoundation = (
  dataTable,
  dateIn,
  dateOut,
  keyword,
  foundationId,
  enabled
) => {
  return useQuery(
    [
      "get-teachers-per-subject-foundation",
      dataTable.current_page,
      dataTable.per_page,
      dateIn,
      dateOut,
      keyword,
      foundationId,
    ],
    () =>
      GetDetail(
        `/academics/teacher-attendances?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&fromDate=${dateIn}&toDate=${dateOut}&keyword=${keyword}&recap=true&foundationId=${foundationId}`
      ),
    {
      enabled,
    }
  );
};
