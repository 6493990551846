import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useTeachersList = (foundationId = "") => {
  return useQuery(
    ["get-teachers-list", foundationId || ""],
    () => GetList(`/academics/teachers?mode=list&foundationId=${foundationId}`),
    {
      enabled: false,
    }
  );
};
