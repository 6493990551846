import { Divider, Skeleton, Table } from "antd";
import { Fragment } from "react";
import DetailBillingsLogic from "./DetailBillingsLogic";
import "./DetailBillings.css";
import {
  renderAmount,
  statusBillingsRender,
} from "pages/si-finance/utils/utlisFunction";
import EditTransactionAmount from "./editTransaction/EditTransactionAmount";

const DetailBillings = () => {
  const {
    data,
    skeleton,
    columns,
    dataSource,
    dataId,
    dataTransaction,
    showEditBillings,
    onUpdate,
    onCancel,
  } = DetailBillingsLogic();

  return (
    <>
      {skeleton && <Skeleton active />}
      {!skeleton && data && (
        <Fragment key={data.id}>
          <Divider orientation="left">Data billings</Divider>
          <table className="detail-billings__table">
            <tbody>
              <tr>
                <th>Nama billings</th>
                <td>: {data[0]?.name}</td>
              </tr>
              <tr>
                <th>Jumlah</th>
                <td>: {renderAmount(data[0]?.amount)}</td>
              </tr>
              <tr>
                <th>Sisah Bayar</th>
                <td>: {renderAmount(data[0]?.remaining_amount)}</td>
              </tr>
              <tr>
                <th>Tipe</th>
                <td>: {data[0]?.type}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>: {statusBillingsRender(data[0]?.status)}</td>
              </tr>
              <tr>
                <th>Deskripsi</th>
                <td>: {data[0]?.description}</td>
              </tr>
            </tbody>
          </table>
          <Divider orientation="left">Data Transaksi</Divider>
          <Table
            size="small"
            tableLayout="auto"
            columns={columns}
            loading={skeleton}
            dataSource={dataSource}
            pagination={true}
            scroll={{
              y: "50vh",
              x: 800,
            }}
          />
          <EditTransactionAmount
            id={dataId}
            show={showEditBillings}
            onUpdate={onUpdate}
            onCancel={onCancel}
            dataTransaction={dataTransaction}
          />
        </Fragment>
      )}
    </>
  );
};

export default DetailBillings;
