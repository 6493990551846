import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

// *untuk teacher journal add,create buku nilai
export const useStudentSubjectList = ({
  keyword,
  classId,
  subjectId,
  enabled,
}) => {
  // console.log({ keyword, classId, subjectId, enabled });
  return useQuery(
    ["get-student-pagination", keyword, classId, subjectId],
    () =>
      GetPagination(
        // `/academics/students?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&mode=page&classId=${classId}&isGraduated=false&notInSubject=${subjectId}`

        // `/academics/students?keyword=${keyword}&mode=list&classId=${classId}&isGraduated=false&subjectMember=${subjectId}`

        `/academics/students?page=1&limit=${
          classId || subjectId ? "10000" : "0"
        }&keyword=${keyword}&mode=page&classId=${classId}&isGraduated=false&subjectMember=${subjectId}`
      ),
    { enabled }
  );
};
