import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import { DeleteApi } from "api/DeleteApi";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useBatchPagination } from "hooks/ppdb-hook/useBatchPagination";
import { usePermission } from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import AddGelombang from "../create/AddGelombang";
import EditGelombang from "../edit/EditGelombang";

const GelombangSetting = () => {
  const { REACT_APP_HOST_API } = process.env;
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  // fetch Batch
  const {
    data,
    isLoading: isLoadings,
    isFetching,
    refetch,
  } = useBatchPagination(dataTable, keyword);

  // fetch Batch permission
  const { data: dataPermission } = usePermission("mdlPpdb", "mnuSetting");

  // Buttons Permission
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditBatch");
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddBatch");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteBatch");

  const handleSetOpenAdd = () => {
    setOpenAdd(!openAdd);
    refetch();
  };

  const [idBatch, setIdBatch] = useState();

  const handleSetOpenEdit = (id) => {
    setIdBatch(id);
    setOpenEdit(!openEdit);
    refetch();
  };

  const [refreshTable, setRefreshTable] = useState(false);
  const onChange = async (id, checked) => {
    const datas = {
      active: checked,
    };
    try {
      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/ppdb/settings/batches/${id}`,
        datas,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
    } catch (error) {
      alert("Hanya boleh memiliki satu gelombang aktif");
      setRefreshTable(!refreshTable);
      window.location.reload();
      // refetch();
    }
  };

  useEffect(() => {}, [refreshTable]);

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Nama", dataIndex: "name", align: "left" },
    { title: "Tahun Ajar", dataIndex: "academic_year", align: "left" },
    { title: "Deskripsi", dataIndex: "description", align: "left" },
    {
      title: "Aktif",
      dataIndex: "id",
      align: "center",

      render: (id, x) => {
        return (
          <Space>
            <Switch
              disabled={btnEdit && btnEdit.type === "disabled"}
              defaultChecked={x.active}
              onChange={(checked) => {
                onChange(id, checked);
              }}
            />
          </Space>
        );
      },
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({
                  url: `/ppdb/settings/batches/`,
                  dataId: id,
                  refetch,
                })
              }
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag className="btn" color="error">
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              onClick={
                btnEdit && btnEdit.type !== "disabled"
                  ? () => handleSetOpenEdit(id)
                  : null
              }
              className="btn"
              color="warning"
              hidden={!btnEdit}
            >
              Ubah
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <Divider />
      <div className="table-header">
        <h1>gelombang</h1>
        <Button
          type="primary"
          onClick={() => setOpenAdd(true)}
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
        >
          Tambah Gelombang
        </Button>
      </div>
      <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Cari Nama Gelombang"
        />
      </div>
      <Table
        key={refreshTable}
        size={window.innerWidth > 1600 ? "middle" : "small"}
        tableLayout="auto"
        columns={columns}
        loading={isLoadings || isFetching}
        dataSource={dataKey}
        pagination={pagination}
        scroll={{
          y: "50vh",
          x: 800,
        }}
      />
      <AddGelombang open={openAdd} setOpen={handleSetOpenAdd} />
      <EditGelombang open={openEdit} setOpen={handleSetOpenEdit} id={idBatch} />
    </div>
  );
};

export default GelombangSetting;
