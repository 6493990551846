import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const IdentitasSiswaTab = () => {
  const { candidate_id } = useParams();
  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>NIK</th>
                <td>{dataInti.nik && dataInti.nik}</td>
              </tr>
              <tr>
                <th>Nama</th>
                <td>{dataInti.full_name && dataInti.full_name}</td>
              </tr>
              <tr>
                <th>NIPD</th>
                <td>{dataInti.nis && dataInti.nis}</td>
              </tr>
              <tr>
                <th>NISN</th>
                <td>{dataInti.nisn && dataInti.nisn}</td>
              </tr>
              <tr>
                <th>Jenis Kelamin</th>
                <td>
                  {dataInti.gender && dataInti.gender === "male"
                    ? "Laki-laki"
                    : dataInti.gender === "female"
                    ? "Perempuan"
                    : ""}
                </td>
              </tr>
              <tr>
                <th>Kota Lahir</th>
                <td>{dataInti.birth_city && dataInti.birth_city}</td>
              </tr>
              <tr>
                <th>Tanggal Lahir</th>
                <td>{dataInti.birth_day && dataInti.birth_day}</td>
              </tr>
              <tr>
                <th>Agama</th>
                <td>{dataInti.religion && dataInti.religion}</td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default IdentitasSiswaTab;
