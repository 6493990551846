import React from "react";
import { Button, Form, Popconfirm, Space, Tabs } from "antd";
import "./AddStudent.css";
import AddStudentLogic from "./AddStudentLogic";
import { SaveFilled } from "@ant-design/icons";

// DONE: Create Student
// TODO: Validasi, experience

const AddStudent = () => {
  const {
    form,
    selectTap,
    items,
    activeKey,
    handleNext,
    handleSubmit,
    loading,
  } = AddStudentLogic();

  return (
    <div>
      <div className="table-header">
        <h1>Tambah Siswa</h1>
        {/* navigasi */}
        <Space>
          <Button
            disabled={activeKey === "1"}
            onClick={() => selectTap(parseInt(activeKey) - 1)}
          >
            Sebelumnya
          </Button>
          <Button
            type="primary"
            onClick={handleNext}
            disabled={activeKey === "9"}
          >
            Selanjutnya
          </Button>
          <Popconfirm
            key="3"
            okText="Simpan"
            cancelText="Batal"
            title="Anda yakin ingin menyimpannya ?"
            onConfirm={handleSubmit}
          >
            <Button icon={<SaveFilled />} loading={loading} type="primary">
              Simpan
            </Button>
          </Popconfirm>
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <Tabs
          onTabClick={(a) => selectTap(a)}
          defaultActiveKey="1"
          items={items}
          activeKey={activeKey}
        />
      </Form>
    </div>
  );
};

export default AddStudent;
