import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useStudentList } from "hooks/students-hook/useStudentList";
import { useEffect, useState } from "react";

const AddStudentToClassLogic = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [dataStudents, setDataStudents] = useState()

  const { REACT_APP_HOST_API } = process.env;
  const { show, onCreate, onCancel, classId } = props;

  // fetch data students
  const { data, isLoading, refetch } = useStudentList();

  const dataStudents = data?.data.data.filter(
    (x) => x.class === null || x.class.id !== classId
  );

  useEffect(() => {
    if (show) refetch();
  }, [show, refetch]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + `/academics/students/${values.studentId}`,
            { class_id: classId },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          message.success(data.message);
          form.resetFields();
          onCreate();
          refetch();
        } catch (error) {
          alert(error.message);
        }
        setLoading(false);
      })
      .catch(() => alert("Input Field Error"));
  };

  const onCancelModal = () => {
    onCancel();
    form.resetFields();
  };

  return {
    form,
    loading,
    isLoading,
    dataStudents,
    handleSubmit,
    onCancelModal,
  };
};

export default AddStudentToClassLogic;
