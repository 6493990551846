import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useShowdailyDetail = (id, enabled) => {
  return useQuery(
    ["get-show-daily-detail", id],
    () => GetDetail(`/academics/daily-attendances/${id}`),
    {
      enabled,
    }
  );
};
