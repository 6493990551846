import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const RequireMenuHrd = ({ menu, children }) => {
  const [menus, setMenus] = useState([]);
  const [employeeFunction, setEmployeeFunction] = useState([]);

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const { data } = await axios.get(REACT_APP_HOST_API, {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        });

        if (
          data.data[0].role.permissions.modules.find((x) => x.id === "mdlHRD")
        ) {
          encryptCookies("perHrd", "true");

          setMenus(
            data.data[0].role.permissions.modules.find((x) => x.id === "mdlHRD")
              .menus
          );
          if (
            data.data[0].role.permissions.modules
              .find((x) => x.id === "mdlHRD")
              .menus.find((x) => x.id === "mnuEmployee")
          )
            setEmployeeFunction(
              data.data[0].role.permissions.modules
                .find((x) => x.id === "mdlHRD")
                .menus.find((x) => x.id === "mnuEmployee").functions
            );
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            if (
              error.response.statusText === "TOKEN" ||
              error.response.statusText === "UNVERIFIED"
            ) {
              alert("hello");
              return <Navigate to="/" />;
            } else {
              alert("hello");
              return <Navigate to="/permission-error" />;
            }
          }
        }

        alert(`Gagal mengambil data menus
${error.message}`);
        return;
      }
    };

    fetchDashboard();
  }, [REACT_APP_HOST_API]);

  if (!Cookies.get("perHrd")) {
    return <Navigate to="/home" />;
  }

  if (menu) {
    if (menu === "btnDetailEmployee") {
      const btnDetailEmployee = employeeFunction.find((x) => x.id === menu);

      if (employeeFunction.length > 0) {
        if (!btnDetailEmployee || btnDetailEmployee.type === "disabled") {
          return <Navigate to="/permission-error" />;
        }
      }
    } else {
      const targettedMenu = menus.find((x) => x.id === menu);

      if (menus.length > 0) {
        if (!targettedMenu || targettedMenu.type === "disabled") {
          return <Navigate to="/permission-error" />;
        }
      }
    }
  }

  return children;
};

export default RequireMenuHrd;
