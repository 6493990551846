import { Button } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Fragment, useCallback, useEffect, useState } from "react";

const MasterProfileLogic = () => {
  const [dataId, setDataId] = useState("");
  const [dataEmployee, setDataEmployee] = useState([]);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const { REACT_APP_HOST_API: api } = process.env;

  const handleClickPasswordButton = (id) => {
    setDataId(id);
    setShowResetPasswordModal(true);
  };

  const onReset = () => {
    setShowResetPasswordModal(false);
  };

  const onCancel = () => {
    setShowResetPasswordModal(false);
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(api, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      setDataEmployee(data.data);
    } catch (error) {
      alert(error.message);
    }
  }, [api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const dataTable = dataEmployee?.map((user) => {
    return {
      ...user,
      key: user.id,
    };
  });

  const columns = [
    { title: "Nama", dataIndex: "name", align: "left" },
    // { title: "Role", dataIndex: "role_name", align: "left" },
    { title: "Email", dataIndex: "email", align: "left" },
    {
      dataIndex: "id",
      align: "left",
      render: (id) => (
        <Fragment>
          <Button
            size="small"
            shape="round"
            className="button-green"
            onClick={() => handleClickPasswordButton(id)}
          >
            Ubah password
          </Button>
        </Fragment>
      ),
    },
  ];

  return {
    dataId,
    columns,
    dataTable,
    showResetPasswordModal,
    onReset,
    onCancel,
    setShowResetPasswordModal,
  };
};

export default MasterProfileLogic;
