import {
  DatePicker,
  Form,
  Input,
  // InputNumber,
  Modal,
  Skeleton,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { MonthlyReportContext } from "context/MonthlyReportContext";
import dayjs from "dayjs";
import { useMonthlyReportDetail } from "hooks/monthly-report/apiCalls";
import moment from "moment";
import { useEffect } from "react";
import { useContext, useState } from "react";

const format = "YYYY-MM-DD";

const EditMReport = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const { showEdit, setShowEdit, setFetchReport, reportId } =
    useContext(MonthlyReportContext);

  const { REACT_APP_HOST_API } = process.env;
  const { data, isFetching, refetch } = useMonthlyReportDetail({
    id: reportId,
    keyword: "",
    employeeId: "",
    dataTable: {
      page: 1,
      limit: 1,
    },
  });

  useEffect(() => {
    let isMounted = true;
    let prevData = data; // Simpan nilai data sebelum refetch
    if (showEdit) refetch();

    if (prevData) {
      form.setFieldsValue({
        name: prevData?.monthlyReport?.name,
        redDates: prevData?.monthlyReport?.red_dates,
        fromDate: dayjs(
          moment(prevData?.monthlyReport?.from_date).format(format)
        ),
        toDate: dayjs(moment(prevData?.monthlyReport?.to_date).format(format)),
        workingDays: prevData?.monthlyReport?.working_dates?.map((date) =>
          dayjs(moment(date).format(format))
        ),
      });
    }

    return () => {
      isMounted && setNewData({}); // Hanya set newData jika komponen masih dipasang
      isMounted = false; // Set isMounted ke false saat komponen dilepas
    };
    // eslint-disable-next-line
  }, [showEdit]);

  useEffect(() => {
    // Atur ulang form ketika data berubah
    if (data) {
      form.setFieldsValue({
        name: data?.monthlyReport?.name,
        redDates: data?.monthlyReport?.red_dates,
        fromDate: dayjs(moment(data?.monthlyReport?.from_date).format(format)),
        toDate: dayjs(moment(data?.monthlyReport?.to_date).format(format)),
        workingDays: data?.monthlyReport?.working_dates?.map((date) =>
          dayjs(moment(date).format(format))
        ),
      });
    }
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang berubah");
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/monthly-reports/${reportId}`,

        newData.fromDate || newData.toDate
          ? {
            name: values.name,
            fromDate: values.fromDate.format(format),
            toDate: values.toDate.format(format),
            workingDays: values.workingDays.length,
            workingDates: values.workingDays.map((date) => dayjs(date).format(format)),
          }
          : {
            name: newData.name,
            workingDays: values.workingDays.length,
            workingDates: values.workingDays.map((date) => dayjs(date).format(format)
            ),
          },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      setFetchReport(true);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    setShowEdit(false);
    form.resetFields();
  };

  const onChangeDates = (_, dateString) => {
    setNewData({
      ...newData,
      workingDays: dateString,
    });
  };

  return (
    <Modal
      open={showEdit}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      centered
    >
      {isFetching ? (
        new Array(2).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Periode"
            tooltip="Contoh: juni 2023"
            rules={[{ min: 3 }]}
          >
            <Input
              onChange={({ target: { value } }) =>
                setNewData({
                  ...newData,
                  name: value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="fromDate"
            label="Tanggal Awal"
            rules={[{ required: newData.toDate }]}
          >
            <DatePicker
              format={format}
              onChange={(e) => {
                if (e !== null) {
                  setNewData({
                    ...newData,
                    fromDate: moment(e).format(format),
                  });
                } else return;
              }}
            />
          </Form.Item>
          <Form.Item
            name="toDate"
            label="Tanggal Akhir"
            rules={[{ required: newData.fromDate }]}
          >
            <DatePicker
              format={format}
              onChange={(e) => {
                if (e !== null)
                  setNewData({
                    ...newData,
                    toDate: moment(e).format(format),
                  });
                else return;
              }}
            />
          </Form.Item>
          <Form.Item
            name="workingDays"
            label="Jumlah hari kerja Dalam satu periode"
          >
            <DatePicker
              multiple
              maxTagCount="responsive"
              onChange={onChangeDates}
            />
          </Form.Item>
          {/* <Form.Item name="redDates" label="Tanggal merah dalam satu periode">
            <InputNumber
              style={{ width: "100%" }}
              onChange={(e) => (newData.redDates = e)}
            />
          </Form.Item> */}
        </Form>
      )}
    </Modal>
  );
};

export default EditMReport;
