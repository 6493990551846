import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import dayjs from "dayjs";

// Create styles
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    backgroundColor: "#f0f0f0",
    padding: 5,
    textAlign: "center",
  },
  tableCol: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    padding: 5,
    textAlign: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

// Create Document Component
const PdfBillings = ({ data }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.body}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Nama akun tertagih</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Rekening</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Status Siswa</Text>
          </View>
          {/* <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Tipe</Text>
          </View> */}
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Jumlah</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Sisa Bayar</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Jatuh Tempo</Text>
          </View>
        </View>
        {data?.map((row, i) => (
          <View style={styles.tableRow} key={i}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {row?.account?.student?.name}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row?.account?.number}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {row?.account?.student?.is_graduated ? "lulus" : "belum lulus"}
              </Text>
            </View>
            {/* <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.type}</Text>
            </View> */}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{renderAmount(row?.amount)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {renderAmount(row?.remaining_amount)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {dayjs(row?.due_date).format("DD MMM YYYY")}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default PdfBillings;
