import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useClassPagination = (dataTable, keyword, foundationId = "") => {
  return useQuery(
    [
      "get-class-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      foundationId || "",
    ],
    () =>
      GetPagination(
        `/academics/classes?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&foundationId=${foundationId}&mode=page`
      )
  );
};
