import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Table, Tooltip } from "antd";
// import AddClass from "../create/AddClass";
import EditClass from "../edit/EditClass";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useClassAlumniPagination } from "hooks/classes-hook/useClassAlumniPagination";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Classes = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  // const [showAddClass, setShowAddClass] = useState(false);
  const [showEditClass, setShowEditClass] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const navigate = useNavigate();

  // fetch classes
  const { data, isLoading, isFetching, refetch } = useClassAlumniPagination(
    dataTable,
    keyword,
    foundationId
  );

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetch classes permission
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuClasses");

  // const onCreate = useCallback(() => {
  //   setShowAddClass(false);
  //   refetch();
  // }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowEditClass(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    // setShowAddClass(false);
    setShowEditClass(false);
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  // Buttons Permission start
  // const btnAdd = dataPermission?.find((x) => x.id === "btnAddClass");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditClass");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailClass");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteClass");

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: 50,
      fixed: "left",
    },
    {
      title: "Kelas",
      dataIndex: "name",
      align: "left",
      fixed: "left",
      width: 150,
    },
    {
      title: "Yayasan",
      dataIndex: "foundationName",
      hidden: !isSuperAdmin,
      width: 100,
    },
    {
      title: "Jurusan",
      dataIndex: "kelas_jurusan",
      align: "left",
      width: 100,
      render: (val, item) => {
        return <>{val && <Tag color="green">{item?.jurusan?.nama}</Tag>}</>;
      },
    },

    { title: "Wali Kelas", dataIndex: "homeroomTeacher", align: "left" },
    { title: "Deskripsi", dataIndex: "description", align: "left", width: 120 },
    {
      title: "Jumlah Siswa",
      dataIndex: "students_count",
      align: "center",
      width: 120,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: 250,
      render: (id) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/academics/classes/", dataId, refetch });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditClass(true);
                }
              }}
              disabled={btnEdit?.type === "disabled"}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                const dataId = id;
                navigate(`/academic/classes/${dataId}`);
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        key: x.id,
        index: i + 1,
        foundationName: x.foundation?.name,
        homeroomTeacher: x.homeroomTeacher?.name,
        students_count: (
          <Tag color="blue" className="tag-round">
            {x.students_count}
          </Tag>
        ),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <>
      <div className="table-header">
        <h1>Daftar Kelas Alumni</h1>
        <Space>
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="cari kelas"
          />
          {roles.some((x) => x === "super_admin") && (
            <Tooltip Tooltip title="Yayasan">
              <Select
                placeholder="Pilih Yayasan"
                style={{ minWidth: 150, maxWidth: 150 }}
                onChange={(x) => setFoundationId(x)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          )}
          {/* <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => setShowAddClass(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button> */}
        </Space>
      </div>
      {/* <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={handleChange}
          placeholder=" cari nama kelas"
        />
      </div> */}
      <Table
        size="small"
        tableLayout="auto"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
      />
      {/* <AddClass show={showAddClass} onCreate={onCreate} onCancel={onCancel} /> */}
      <EditClass
        id={dataId}
        show={showEditClass}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
    </>
  );
};

export default Classes;
