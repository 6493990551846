import { Popconfirm, Space, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { encryptCookies } from "components/Helper/CookiesHelper";
import { useActivityList } from "hooks/activity-hook/useActivityList";
import { usePresencePagination } from "hooks/presence-hook/usePresencePagination";
import { usePermission } from "hooks/usePermissions";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

const disabled = { cursor: "not-allowed", color: "#ccc" };

const PresenceTableLogic = () => {
  // custom date variables
  const newDate = new Date();
  const firstDate = newDate;
  const lastDate = newDate;
  const { id } = useParams();

  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [idActivity, setIdActivity] = useState(id ? id : "");
  const [timeOutDisabled, setTimeOutDisabled] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [showCreate, setShowCreate] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showTimeoutDefault, setShowTimeoutDefault] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });

  const navigate = useNavigate();

  const params = { dataTable, date, idActivity, keyword };

  // fetch presence pagination
  const {
    data,
    isLoading,
    isFetching,
    refetch: fetchData,
  } = usePresencePagination(params);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuPresence");

  // fetch activityList
  const {
    data: dataActivity,
    isLoading: skeleton,
    refetch: fetchActivity,
  } = useActivityList("fixed_time");

  useEffect(() => {
    if (idActivity !== ":id") {
      fetchData();
      if (data?.data.presence.data.find((x) => x.time_out === null)) {
        setTimeOutDisabled(false);
      }
    }
    if (idActivity === ":id") fetchActivity();

    return () => setTimeOutDisabled(true);
  }, [
    data,
    idActivity,
    fetchData,
    fetchActivity,
    date.from,
    date.to,
    dataTable.current_page,
    dataTable.per_page,
    keyword,
  ]);

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddPresence");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditPresence");
  const btnRecap = dataPermission?.find((x) => x.id === "btnRecapPresence");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeletePresence");
  const btnTimeout = dataPermission?.find((x) => x.id === "btnTimeout");
  const btnExport = dataPermission?.find(
    (x) => x.id === "btnExportXLSXPresence"
  );

  const onCreate = () => {
    setShowCreate(false);
    fetchData();
  };

  const onSubmitTimeOut = () => {
    fetchData();
  };

  const onUpdate = () => {
    setShowEdit(false);
    fetchData();
  };

  const onCancel = () => {
    setShowEdit(false);
    setShowTimeoutDefault(false);
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const handleDateFromChange = (param) => {
    setDate((current) => {
      return {
        ...current,
        from:
          param !== null
            ? param.format("YYYY-MM-DD")
            : moment(firstDate).format("YYYY-MM-DD"),
      };
    });
  };

  const handleDateToChange = (param) => {
    setDate((current) => {
      return {
        ...current,
        to:
          param !== null
            ? param.format("YYYY-MM-DD")
            : moment(lastDate).format("YYYY-MM-DD"),
      };
    });
  };

  const handleActivityChange = (val) => {
    setIdActivity(val);
    setShowModal(false);
    navigate(`/hrd/activity/presence-${val}`);
  };

  const handleCancelModal = () => {
    encryptCookies("defaultHrdMenu", "mnuActivity");
    setShowModal(false);
    navigate("/hrd/activity");
  };

  const dataToExport = data?.data.presence.data.map((presence) => {
    return {
      Tanggal: moment(presence.time_in).format("YYYY-MM-DD"),
      Presensi: data?.data.activity.name,
      NIP: presence.employee.nip,
      Nama: presence.employee.name,
      "Waktu Masuk": moment(presence.time_in).format("HH:mm:ss"),
      "Waktu Pulang": presence.time_out
        ? moment(presence.time_out).format("HH:mm:ss")
        : null,
    };
  });

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `Data Presensi.xlsx`);
  };

  const dataSource = data?.data.presence.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        name: data.employee.name,
        time_in: data.time_in && moment(data.time_in).format("HH:mm:ss"),
        time_out: data.time_out && moment(data.time_out).format("HH:mm:ss"),
        date: data.time_in && moment(data.time_in).format("YYYY-MM-DD"),
        workingTimeDiff: data.workingTimeDiff ? data.workingTimeDiff : "0",
      };
    });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      align: "left",
      fixed: "left",
      width: window.innerWidth > 800 ? "" : 150,
    },
    { title: "Tanggal", dataIndex: "date", align: "left", width: 120 },
    { title: "Waktu Masuk", dataIndex: "time_in", align: "left", width: 120 },
    { title: "Waktu Pulang", dataIndex: "time_out", align: "left", width: 120 },
    {
      title: "Selisih Jam",
      dataIndex: "workingTimeDiff",
      align: "left",
      width: 120,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space size="small">
            <Popconfirm
              disabled={btnDelete && btnDelete.type === "disabled"}
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({
                  url: `/presence/`,
                  dataId: id,
                  refetch: fetchData,
                })
              }
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="blue"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") return;
                else {
                  setDataId(id);
                  setShowEdit(true);
                }
              }}
            >
              Ubah
            </Tag>
          </Space>
        );
      },
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: data?.data.presence.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return {
    id,
    date,
    data,
    dataId,
    btnAdd,
    dataSource,
    keyword,
    columns,
    btnRecap,
    showEdit,
    showModal,
    btnExport,
    skeleton,
    isLoading,
    isFetching,
    showCreate,
    pagination,
    showDetail,
    idActivity,
    btnTimeout,
    dataActivity,
    timeOutDisabled,
    showTimeoutDefault,
    onCreate,
    onUpdate,
    onCancel,
    handleExport,
    handleChange,
    setShowCreate,
    setShowDetail,
    onSubmitTimeOut,
    handleCancelModal,
    handleDateToChange,
    handleDateFromChange,
    handleActivityChange,
    setShowTimeoutDefault,
  };
};

export default PresenceTableLogic;
