import { Form, Modal, Select, message } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import axios from "axios";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const CreateProsem = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [teacherId, setTeacherId] = useState("");

  const roles = decryptCookies("role").split(",");
  const { REACT_APP_HOST_API: api } = process.env;

  // get teacher teaching
  const { data: dataTeaching, refetch: fetchTeaching } = useTeachingList(
    Cookies.get("teacher_id") ? decryptCookies("teacher_id") : teacherId
  );

  // get teacher list
  const { data: dataTeachers, refetch: fetchTeachers } = useTeachersList();

  useEffect(() => {
    if (
      roles.find(
        (x) =>
          x === "super_admin" ||
          x === "admin" ||
          x === "curriculum" ||
          x === "admin_academic"
      )
    ) {
      fetchTeachers();
      if (teacherId) {
        fetchTeaching();
      }
    } else if (roles.find((x) => x === "teacher")) fetchTeaching();
    // eslint-disable-next-line
  }, [fetchTeachers, fetchTeaching, teacherId]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    const datas = dataTeaching?.data?.filter((x) => x.id === values.subjectId);

    setLoading(true);
    try {
      const { data } = await axios.post(
        api + `/academics/program-semester`,
        {
          // totalPertemuan: values.totalPertemuan,
          teacherId: values.teacherId
            ? values.teacherId
            : decryptCookies("teacher_id"),
          subjectId: datas[0].subject_id,
          classId: datas[0].class_id,
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      props.onCreate();
      form.resetFields();
      message.success(data.message);
    } catch (error) {
      if (
        error?.response?.data?.data[0]?.message === "required validation failed"
      ) {
        alert("Belum dapat menambahkan prosem matapel ekskul");
      } else {
        alert("Prosem dengan mata pelajaran & kelas tersebut sudah ada!");
      }
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    setTeacherId("");
    props.onCancel();
  };


  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      closable={false}
      open={props.open}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        {roles.find(
          (x) =>
            x === "super_admin" ||
            x === "admin" ||
            x === "curriculum" ||
            x === "admin_academic" ||
            x === "admin_foundation"
        ) && (
            <Form.Item label="Guru" name="teacherId" rules={[{ required: true }]}>
              <Select
                placeholder="Pilih guru"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setTeacherId(e);
                  form.resetFields(["subjectId"]);
                }}
              >
                {dataTeachers?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.employee?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

        <Form.Item
          label="Pelajaran"
          name="subjectId"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option.children
                ? option.children.toString()
                : "";
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
            placeholder="Pilih Pelajaran"
          >
            {dataTeaching?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.class?.name} - {x.subject?.name}
              </Select.Option>
            ))}
          </Select>
          {/* )} */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProsem;
