import React, { useEffect } from "react";
import { useDeskripsiRaportDetail } from "hooks/student-raport-hook/useDeskripsiRaportDetail";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

export default function Deskripsi2() {
  const { raport_id } = useParams();

  const { data, refetch } = useDeskripsiRaportDetail(raport_id, false);

  useEffect(() => {
    if (raport_id) {
      refetch();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <main>
      <div className="container-raport-siswa papper-raport">
        <div style={{ marginTop: "20px" }}>
          <table border={1}>
            <thead>
              <tr>
                <th>NO.</th>
                <th>MATA PELAJARAN</th>
                <th>KOMPETENSI</th>
                <th>CATATAN</th>
              </tr>
            </thead>
            <tbody>
              {data?.deskripsi?.data.map((item, index) => {
                const res = index > 6 && index <= 14 && (
                  <Fragment key={index}>
                    <tr>
                      <td rowSpan={3}>{index + 1}</td>
                      <td rowSpan={3} style={{ width: "200px" }}>
                        {item?.subject_name}
                      </td>

                      <td>{item?.komepetensi[0]?.name}</td>
                      <td style={{ width: "350px" }}>
                        {item?.komepetensi[0]?.catatan}
                      </td>
                    </tr>
                    <tr>
                      <td>{item?.komepetensi[1]?.name}</td>
                      <td style={{ width: "350px" }}>
                        {item?.komepetensi[1]?.catatan}
                      </td>
                    </tr>
                    <tr>
                      <td>{item?.komepetensi[2]?.name}</td>
                      <td style={{ width: "350px" }}>
                        {item?.komepetensi[2]?.catatan}
                      </td>
                    </tr>
                  </Fragment>
                );

                return res;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
