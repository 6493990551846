import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useStudentsTemplate = () => {
  return useQuery(
    ["get-students-template-import"],
    () => GetPagination(`/template-excels?page=1&limit=10&keyword=`),
    { enabled: false }
  );
};
