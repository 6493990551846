import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Skeleton,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useLeaveDailyDetail } from "hooks/leave-hook/apiCalls";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import moment from "moment";
import { useEffect, useState } from "react";
import "./leaveDailyStyles.css";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useUnitEmployeeList } from "hooks/unit-hook/useUnitEmployeeList";

const format = "YYYY-MM-DD";

const EditLeave = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const isUserHrd = roles.some((x) => x === "user_hrd");
  const isAdminHrd = roles.some((x) => x === "admin_hrd");
  const [fileList, setFileList] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [deleteImages, setDeleteImages] = useState([]);
  const navigate = useNavigate();

  const { leave_id } = useParams();

  const { REACT_APP_HOST_API } = process.env;

  const { data, isFetching, refetch } = useLeaveDailyDetail(leave_id);

  // get unit
  const { data: dataUnits, isLoading: loadingUnits } = useUnitList();

  // get employee
  const { data: dataUnitEmployee, refetch: fecthUnitEmployee } =
    useUnitEmployeeList(unitId);

  useEffect(() => {
    if (data) {
      console.log(moment(data?.data?.leave_dates[0]));
      setUnitId(data?.data?.unit_id);
      form.setFieldsValue({
        leaveStatus: data?.data?.leave_status,
        employeeId: data?.data?.employee_id,
        // fromDate: dayjs(moment(data?.data?.from_date).format(format)),
        // toDate: dayjs(moment(data?.data?.to_date).format(format)),
        leaveDates: data?.data?.leave_dates.map((x) =>
          dayjs(moment(x))
        ),
        reason: data?.data?.reason,
        unitId: data?.data?.unit_id,
        status: data?.data?.status,
      });
      if (data?.data?.image) {
        setFileList([
          {
            uid: "-1",
            name: data?.data?.file_image,
            status: "done",
            url: data.data.image,
          },
        ]);
      }
    }
  }, [data, form]);

  useEffect(() => {
    if (!isSuperAdmin) {
      setUnitId(dataUnits?.data?.[0]?.id);
    }
  }, [isSuperAdmin, dataUnits]);

  useEffect(() => {
    if (unitId && unitId !== "") {
      fecthUnitEmployee();
    }
  }, [fecthUnitEmployee, unitId]);

  useEffect(() => {
    if (leave_id) {
      refetch();
    }

    return () => setNewData({});
  }, [leave_id, refetch]);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang berubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }
    if (deleteImages.length > 0 && !newData.image) {
      newData.deleteImage = data?.data?.file_image;
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/leaves/${leave_id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      form.resetFields();
      setFileList([]);
      navigate("/hrd/leavedaily");
      message.success(data.message);
    } catch (error) {
      alert(error?.response?.data?.message || "input tidak valid");
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, image: newFileList[0]?.originFileObj });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error(
        "hanya bisa upload file gambar (.jpeg, .jpg, .png) atau pdf"
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, image: null });
    setDeleteImages([...deleteImages, file?.name]);
    form.setFieldValue("file", null);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  return (
    <>
      <div className="table-header">
        <h1>Form Edit leave daily</h1>
      </div>
      {isFetching ? (
        new Array(3).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <div className="leave-form">
              <Form.Item name="leaveStatus" label="Status">
                <Radio.Group
                  buttonStyle="solid"
                  onChange={({ target: { value } }) =>
                    (newData.leaveStatus = value)
                  }
                >
                  <Radio.Button value="cuti">Cuti</Radio.Button>
                  <Radio.Button value="sakit">Sakit</Radio.Button>
                  <Radio.Button value="izin">Izin</Radio.Button>
                </Radio.Group>
              </Form.Item>
              {(!isUserHrd || isAdminHrd || isSuperAdmin) && (
                <Form.Item name="status" label="Status">
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={({ target: { value } }) =>
                      (newData.status = value)
                    }
                  >
                    <Radio.Button value="waiting">Menunggu</Radio.Button>
                    <Radio.Button value="aprove">Diterima</Radio.Button>
                    <Radio.Button value="rejected">Ditolak</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              )}
              <Form.Item name="unitId" label="Unit">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={loadingUnits}
                  onChange={(e) => (newData.unitId = e)}
                >
                  {dataUnits?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="employeeId" label="Nama">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => (newData.employeeId = e)}
                >
                  {dataUnitEmployee?.data?.map((x) => (
                    <Select.Option key={x.employee.id} value={x.employee.id}>
                      {x.employee.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <Form.Item name="type" label="Tipe">
              <Select
                options={[
                  { label: "Tidak Dipotong Gaji", value: "paid" },
                  { label: "Dipotong Gaji", value: "unpaid" },
                ]}
                onChange={(e) => (newData.type = e)}
              />
            </Form.Item> */}
              {/* <Form.Item name="fromDate" label="Tanggal Awal">
                <DatePicker
                  format={format}
                  onChange={(e) => {
                    newData.fromDate = dayjs(e).format(format);
                  }}
                />
              </Form.Item>
              <Form.Item name="toDate" label="Tanggal Akhir">
                <DatePicker
                  format={format}
                  onChange={(e) => {
                    newData.toDate = dayjs(e).format(format);
                  }}
                />
              </Form.Item> */}
              <Form.Item
                name="leaveDates"
                label="Tanggal Izin"
                rules={[{ required: true }]}
              >
                <DatePicker
                  multiple
                  maxTagCount="responsive"
                  onChange={(e) => {
                    newData.leaveDates = e.map((x) => dayjs(x).format(format));
                  }}
                />
              </Form.Item>
              <Form.Item name="reason" label="Alasan" rules={[{ min: 3 }]}>
                <Input
                  onChange={({ target: { value } }) => (newData.reason = value)}
                />
              </Form.Item>
            </div>
            {/* <Form.Item name="note" label="Catatan" rules={[{ min: 3 }]}>
            <TextArea
              rows={3}
              onChange={({ target: { value } }) => (newData.note = value)}
            />
          </Form.Item> */}
            <Form.Item
              name="file"
              label="Gambar(optional)"
              rules={[
                {
                  validator: (_, value) => {
                    const file = value?.fileList[0];
                    if (!file) {
                      return Promise.resolve();
                    }
                    if (!isImage(file)) {
                      return Promise.reject(
                        new Error("Please upload an image file")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Upload
                name="file"
                accept=".jpg,.jpeg,.png"
                listType="picture-card"
                showUploadList={true}
                onPreview={handlePreview}
                {...propsUpload}
                disabled={loading}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    {fileList.length === 0 ? (
                      <span style={{ fontSize: "12px" }}>
                        Upload <br />
                        (max 2 mb)
                      </span>
                    ) : (
                      "Ganti"
                    )}
                  </div>
                </div>
              </Upload>
            </Form.Item>
            <div className="button-container">
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default EditLeave;
