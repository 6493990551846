import { DatePicker, Form, Input, Modal, Skeleton } from "antd";
import EditPresenceLogic from "./EditPresenceLogic";

const EditPresence = (props) => {
  const { form, format, newData, loading, skeleton, handleSubmit } =
    EditPresenceLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={props.onCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item name="description" label="Deskripsi">
            <Input
              allowClear
              onChange={({ target: { value } }) =>
                (newData["description"] = value)
              }
            />
          </Form.Item>
          <Form.Item name="timeIn" label="Waktu Masuk">
            <DatePicker
              showTime
              format={format}
              onChange={(e) => {
                if (e !== null) newData["timeIn"] = e.format(format);
                else newData["timeIn"] = null;
              }}
            />
          </Form.Item>
          <Form.Item name="timeOut" label="Waktu Pulang">
            <DatePicker
              showTime
              format={format}
              onChange={(e) => {
                if (e !== null) newData["timeOut"] = e.format(format);
                else newData["timeOut"] = null;
              }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditPresence;
