import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table, Space } from "antd";
import EditEmployeFromDivision from "./EditEmployeeFromDivision";
import { Popconfirm, Tag, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useDivisionDetail } from "hooks/divison-hook/useDivisionDetail";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const DetailDivision = () => {
  const [dataId, setDataId] = useState("");
  const [dataEmployee, setDataEmployee] = useState({});
  const [keyword, setKeyword] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { division_id } = useParams();
  const { REACT_APP_HOST_API: api } = process.env;

  const navigate = useNavigate();

  const { data, isLoading, isFetching, refetch } = useDivisionDetail(
    division_id,
    true,
    keyword
  );

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuDivision");

  const onCreate = useCallback(() => {
    setShowEdit(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowEdit(false);
  };

  const handleChange = async (param) => {
    setKeyword(param.target.value);
  };

  const onDelete = async (item) => {
    if (rowSelected.length === 0 && !item) {
      message.error("Tidak ada yang di hapus");
      return;
    }

    const postData = [
      {
        employeeId: item?.employee_id,
        divisionId: item?.division_id,
      },
    ];

    const postDataSelected = rowSelected.map((x) => {
      return {
        employeeId: x.employee_id,
        divisionId: x.division_id,
      };
    });

    const isSelectedData = rowSelected.length > 0 && !item;

    setIsLoad(true);

    try {
      await axios.delete(api + `/employee-divisions/batch`, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
        data: {
          employeeDivisions: isSelectedData ? postDataSelected : postData,
        },
      });
      setRowSelected([]);
      message.success("Data berhasil dihapus");
      refetch();
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  };

  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddEmployeeToDivision"
  );
  const btnEdit = dataPermission?.find(
    (x) => x.id === "btnEditEmployeeFromDivision"
  );
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteEmployeeFromDivision"
  );

  const dataSource = data?.data?.employees.map((data, index) => {
    return {
      ...data,
      key: data.employee?.id,
      index: index + 1,
      name: data.employee?.name,
      id: data.employee?.id,
      title:
        data.title === "lead"
          ? "Ketua"
          : data.title === "vice"
          ? "Wakil"
          : "Anggota",
    };
  });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Nama", dataIndex: "name" },
    { title: "Jabatan", dataIndex: "title" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, data) => {
        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => onDelete(data)}
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete || rowSelected.length > 0}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="blue"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowEdit(true);
                  setDataId(id);
                  setDataEmployee(data);
                }
              }}
            >
              Ubah Jabatan
            </Tag>
          </>
        );
      },
    },
  ];

  const [rowSelected, setRowSelected] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      <div className="table-header">
        <h1>
          Daftar Anggota Divisi <mark>{data?.data?.name}</mark>
        </h1>
        <Space>
          <Popconfirm
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() => onDelete()}
            title="Yakin ingin menghapus ?"
            disabled={btnDelete && btnDelete.type === "disabled"}
          >
            <Button
              loading={isLoad}
              type="primary"
              danger
              hidden={rowSelected.length === 0 || !btnDelete}
            >
              Hapus
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => navigate(`add-employee-divisi-batch`)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah Anggota
          </Button>
        </Space>
      </div>
      <div className="search-wrapper">
        <Input
          value={keyword}
          onChange={handleChange}
          prefix={<SearchOutlined />}
          placeholder="cari nama anggota"
        />
      </div>
      <Table
        rowSelection={rowSelection}
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        // pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? {
                y: "50vh",
                x: 800,
              }
            : {
                y: "55vh",
                x: 800,
              }
        }
      />

      <EditEmployeFromDivision
        id={dataId}
        show={showEdit}
        onUpdate={onCreate}
        onCancel={onCancel}
        data={dataEmployee}
      />
    </>
  );
};

export default DetailDivision;
