import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useKompetensiIntiList } from "hooks/prosem-detail-hook/useKompetensiIntiList";
import { useMetodePenilaianList } from "hooks/prosem-detail-hook/useMetodePenilaianList";
import { useProsemDetailDetail } from "hooks/prosem-detail-hook/useProsemDetailDetail";
import { useEffect, useState } from "react";

const EditProsemDetailLogic = ({ id, open, onUpdate, onCancel }) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API: api } = process.env;

  // get KI list
  const { data: dataKI, refetch: fetchKI } = useKompetensiIntiList();

  // get metode penilaian list
  const { data: dataMetode, refetch: fetchMetode } = useMetodePenilaianList();

  //   get prosem detail
  const { data, isLoading, refetch } = useProsemDetailDetail(id, false);

  useEffect(() => {
    if (open && id) {
      refetch();
      fetchKI();
      fetchMetode();
    }

    if (data)
      form.setFieldsValue({
        kompetensiIntiId: data?.data?.kompetensi_inti_id,
        kompetensiDasar: data.data.kompetensi_dasar,
        kompetensiDasarIndex: data.data.kompetensi_dasar_index,
        pertemuan: data.data.pertemuan,
        materi: data.data.materi,
        metode: data.data.metode,
        kategori1: data.data.kategori1,
        kategori2: data.data.kategori2,
        kategori3: data.data.kategori3,
      });

    return () => setNewData({});
  }, [id, open, data, form, refetch, fetchKI, fetchMetode]);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang dirubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        api + `/academics/program-semester-detail/${id}`,
        newData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      onUpdate();
      form.resetFields();
      message.success(data.message);
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return {
    form,
    dataKI,
    newData,
    loading,
    isLoading,
    dataMetode,
    onSubmit,
    onCancelModal,
  };
};

export default EditProsemDetailLogic;
