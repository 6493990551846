import { Form, Input } from "antd";
import React from "react";

const TabKontakSiswa = ({ newData }) => {
  return (
    <div className="tab-wrapper">
      <Form.Item
        label="No Telp."
        name="phone"
        rules={[
          {
            pattern: new RegExp("^([0-9]*)$"),
            message: "input hanya berupa angka",
          },
        ]}
      >
        <Input onChange={({ target: { value } }) => (newData.phone = value)} />
      </Form.Item>

      <Form.Item
        label="No Hp"
        name="mobile_phone"
        rules={[
          {
            pattern: new RegExp("^([0-9]*)$"),
            message: "input hanya berupa angka",
          },
        ]}
      >
        <Input
          onChange={({ target: { value } }) => (newData.mobile_phone = value)}
        />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "Input harus berupa alamat email yang valid",
          },
        ]}
      >
        <Input onChange={({ target: { value } }) => (newData.email = value)} />
      </Form.Item>
    </div>
  );
};

export default TabKontakSiswa;
