import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  List,
  Modal,
  Space,
  Table,
  Popconfirm,
  Tag,
  Select,
  Tooltip,
} from "antd";
import AddTeachers from "../create/AddTeachers";
import { DeleteApi } from "api/DeleteApi";
import { useTeachersPagination } from "hooks/teachers-hook/useTeachersPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Teachers = () => {
  const navigate = useNavigate();
  const [showAddTeachers, setShowAddTeachers] = useState(false);
  // eslint-disable-next-line
  const [teaching] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [foundationId, setFoundationId] = useState("");

  const { data, isLoading, isFetching, refetch } = useTeachersPagination(
    dataTable,
    keyword,
    foundationId
  );
  const roles = decryptCookies("role").split(",");

  // fetch classes permission
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuTeachers");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const onCreate = useCallback(() => {
    setShowAddTeachers(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowAddTeachers(false);
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddTeachers");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteTeachers");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailTeachers");

  const columns = [
    {
      title: "No",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Nama",
      dataIndex: "name",
      align: "left",
      width: 150,
      fixed: "left",
    },
    { title: "NIP", dataIndex: "nip", align: "left" },
    {
      title: "Mengajar",
      dataIndex: "teaching",
      align: "left",
      width: 200,
      render: (teaching) => {
        const groupedData = {};

        // Filter data yang belum lulus
        const filteredData = teaching.filter(
          (item) =>
            item?.class?.is_graduated === false ||
            item?.subject?.is_extracurricular
        );

        // Melakukan pengelompokkan berdasarkan subjectData
        filteredData.forEach((data) => {
          const classData = data?.class?.name;
          const subjectData = data?.subject?.name;
          const isExtracurr = data?.subject?.is_extracurricular;
          if (!groupedData[subjectData]) {
            groupedData[subjectData] = [{ classData, isExtracurr }];
          } else if (
            !groupedData[subjectData].some(
              (item) => item.classData === classData
            )
          ) {
            groupedData[subjectData].push({ classData, isExtracurr });
          }
        });

        // Membuat array yang berisi hasil pengelompokkan
        const dataTeach = Object.keys(groupedData).map((subjectData, id) => {
          const classDataArray = groupedData[subjectData];
          const classData = classDataArray
            .map((item) => item.classData)
            .join(", ");
          const isExtracurr = classDataArray.some((item) => item.isExtracurr);

          const tagColor = isExtracurr ? "blue" : "green";

          return (
            <Tag key={id} color={tagColor}>
              {`( ${subjectData} ) - ${classData}`}
            </Tag>
          );
        });

        return (
          <Space>
            <div style={{ display: "flex", flexWrap: "wrap" }}>{dataTeach}</div>
          </Space>
        );
      },
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: 200,
      render: (id, record) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/academics/teachers/", dataId, refetch });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color={btnDelete?.type === "disabled" ? "" : "magenta"}
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color={btnDetail?.type === "disabled" ? "" : "blue"}
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                navigate(`/academic/teachers/${id}`);
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        name: x.employee.name,
        nip: x.employee.nip,
        key: x.id,
        index: i + 1,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Guru</h1>
        <Button
          type="primary"
          hidden={!btnAdd}
          onClick={() => setShowAddTeachers(true)}
          disabled={btnAdd && btnAdd.type === "disabled"}
        >
          Tambah Guru
        </Button>
      </div>
      <div
        style={{
          maxWidth: 700,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: 10,
        }}
      >
        <Input
          value={keyword}
          onChange={handleChange}
          prefix={<SearchOutlined />}
          placeholder="cari guru berdasarkan nama,kelas, atau pelajaran"
        />
        {roles.some((x) => x === "super_admin") && (
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => setFoundationId(x)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">
                ALL
              </Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        )}
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 1000 }
            : { y: "55vh", x: 1000 }
        }
      />
      <AddTeachers
        onCreate={onCreate}
        onCancel={onCancel}
        show={showAddTeachers}
      />
      <Modal
        title="Data Mengajar"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <List
          size="small"
          bordered
          dataSource={teaching}
          renderItem={(item) => (
            <List.Item>
              <Space>
                <p>{item.class.name}</p>
                <p>-</p>
                <p>{item.subject.name}</p>
              </Space>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default Teachers;
