import { Button, DatePicker, Form, Input, Select, Table } from "antd";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import moment from "moment";
import StudentsPerSubjectLogic from "./StudentsPerSubjectLogic";
import EditStudentsPerSubject from "../edit-student/EditStudentsPerSubject";
import { usePresenceContext } from "context/academic-context/PresencePerSubjectContext";

const StudentsPerSubject = () => {
  const {
    dataSession,
    dataPelajaran,
    setSession,
    setSubject,
    setClassName,
    dataClass,
    setKeyword,
    filteredColumn,
    isRecap,
    setIsRecap,
    form,
    dataSources,
    isFetching,
    isLoading,
    setDateIn,
    setDateOut,
    pagination,
    handleExport,
    open,
    handleEdit,
    dataId,
  } = StudentsPerSubjectLogic();

  // * Get presencePerSubject Context ---
  const {
    dateInFilter,
    dateOutFilter,
    isFetchFilter,
    setIsFetchFilter,
    className,
    subject,
    session,
  } = usePresenceContext();

  useEffect(() => {
    if (isFetchFilter) {
      form.setFieldsValue({
        date_in: dateInFilter,
        date_out: dateOutFilter,
        className: className,
        subject: subject,
        session: session,
      });
    }

    return setIsFetchFilter(false);
    // eslint-disable-next-line
  }, [isFetchFilter]);

  return (
    <div>
      <div className="table-header">
        <h1>{isRecap ? "Rekap" : "Data"} Absensi Students Per Subject</h1>

        <Button type="primary" onClick={handleExport}>
          Export to Excel
        </Button>
      </div>
      <Form form={form} layout="vertical">
        <div className="wrapper-input">
          <Form.Item label="Mode" className="item-input-wrapper">
            <Select
              defaultValue={isRecap}
              onChange={(value) => {
                setIsRecap(value);
              }}
            >
              <Select.Option value={true}>
                Rekap Absensi Per Pelajaran
              </Select.Option>
              <Select.Option value={false}>
                Data Absensi Per Pelajaran
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="date_in"
            label="Tanggal mulai"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            className="item-input-wrapper"
          >
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) => setDateIn(dayjs(value).format("YYYY-MM-DD"))}
            />
          </Form.Item>

          <Form.Item
            name="date_out"
            label="Tanggal akhir"
            className="item-input-wrapper"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
          >
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) =>
                setDateOut(dayjs(value).format("YYYY-MM-DD"))
              }
            />
          </Form.Item>

          <Form.Item
            label="Kelas"
            name="className"
            className="item-input-wrapper"
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => setClassName(value)}
              defaultValue="Semua kelas"
            >
              <Select.Option value={""}>Semua kelas</Select.Option>
              {dataClass?.data?.map((kelas) => (
                <Select.Option key={kelas.id} value={kelas.name}>
                  {kelas.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Pelajaran"
            name="subject"
            className="item-input-wrapper"
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              defaultValue="Semua pelajaran"
              onChange={(value) => setSubject(value)}
            >
              <Select.Option value={""}>Semua pelajaran</Select.Option>
              {dataPelajaran?.data?.map((pelajaran) => (
                <Select.Option key={pelajaran.id} value={pelajaran.name}>
                  {pelajaran.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Sesi" name="session" className="item-input-wrapper">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              defaultValue="Semua sesi"
              onChange={(value) => setSession(value)}
            >
              <Select.Option value={""}>Semua sesi</Select.Option>
              {dataSession?.data?.data?.map((session) => (
                <Select.Option key={session.id} value={session.session}>
                  {session.session}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="search-wrapper filter-column">
          <Form.Item label="Cari Nama" name="name" style={{ width: "100%" }}>
            <Input
              placeholder="cari berdasarkan nama ..."
              onChange={(e) => setKeyword(e.target.value)}
            />
          </Form.Item>
        </div>
      </Form>
      <Table
        size={window.innerWidth > 1600 ? "middle" : "small"}
        tableLayout="auto"
        dataSource={dataSources}
        columns={filteredColumn}
        loading={isLoading || isFetching}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
        pagination={pagination}
      />

      {/* edit --- */}
      <EditStudentsPerSubject
        open={open}
        setOpen={handleEdit}
        dataId={dataId}
      />
    </div>
  );
};

export default StudentsPerSubject;
