import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useSessionList = () => {
  return useQuery(
    "get-session-list",
    () => GetList("/academics/sessions?page=1&limit=100&keyword=&mode=page"),
    { enabled: false }
  );
};
