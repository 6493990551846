import { Form, Input, Modal, Skeleton, Upload } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useFoundationDetail } from "hooks/foundation-hook/useFoundationDetail";
import { PlusOutlined } from "@ant-design/icons";

const EditFoundation = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [fileList, setFileList] = useState([]);

  const { REACT_APP_HOST_API } = process.env;

  // fetch detail foundation
  const {
    isLoading: skeleton,
    refetch: fetchData,
  } = useFoundationDetail(props.id, false);

  const setData = async () => {
    const { data: successData } = await fetchData();

    if (successData) {
      form.setFieldsValue({
        name: successData.data?.name,
        description: successData.data?.description,
        website: successData.data?.website,
        email: successData.data?.email,
        phone: successData.data?.phone,
        address: successData.data?.address,
      });
      if (successData?.data?.logo) {
        setFileList([
          {
            uid: "-1",
            name: successData?.data?.file_image,
            status: "done",
            url: successData.data.logo,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (props.show) {
      setData();
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [form, props.show]);


  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (Object.keys(newData).length === 0) {
        message.error("Tidak ada perubahan data");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }

      setLoading(true);

      const { data } = await axios.put(
        REACT_APP_HOST_API + `/foundation/foundations/${props.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFileList([]);
      message.success(data.message);
      form.resetFields();
      props.onUpdate();
    } catch (error) {
      let msg = error?.response?.data?.message || "Input Field Error";
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, logo: newFileList[0]?.originFileObj });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error(
        "hanya bisa upload file gambar (.jpeg, .jpg, .png) atau pdf"
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, image: null });
    form.setFieldValue("file", null);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
      title="Edit Foundation"
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          <Form.Item
            label="Nama Foundation"
            name="name"
            rules={[
              {
                required: true,
                message: "Nama foundaiton harus diisi!",
              },
            ]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["name"] = value)}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'masukan email yang valid!',
              },
              {
                required: true,
                message: 'email harus diisi!',
              },
            ]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["email"] = value)}
            />
          </Form.Item>
          <Form.Item label="Alamat" name="address"
            rules={[
              {
                required: true,
                message: 'masukan alamat!',
              },
            ]}
          >
            <Input.TextArea
              onChange={({ target: { value } }) => (newData.address = value)}
            />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: 'masukan nomor telepon!',
              },
            ]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["phone"] = value)}
            />
          </Form.Item>
          <Form.Item
            label="Deskripsi"
            name="description"
          >
            <Input
              onChange={({ target: { value } }) => (newData["description"] = value)}
            />
          </Form.Item>
          <Form.Item
            label="Link Website"
            name="website"
          >
            <Input
              onChange={({ target: { value } }) => (newData["website"] = value)}
            />
          </Form.Item>
          <Form.Item
            name="file"
            label="Gambar"
            rules={[
              {
                validator: (_, value) => {
                  const file = value?.fileList[0];
                  if (!file) {
                    return Promise.resolve();
                  }
                  if (!isImage(file)) {
                    return Promise.reject(
                      new Error("Please upload an image file")
                    );
                  }
                  return Promise.resolve();
                },
              }
            ]}
          >
            <Upload
              name="file"
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              showUploadList={true}
              onPreview={handlePreview}
              {...propsUpload}
              disabled={loading}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  {fileList.length === 0 ? (
                    <span style={{ fontSize: "12px" }}>
                      Upload <br />
                      (max 2 mb)
                    </span>
                  ) : (
                    "Ganti"
                  )}
                  {fileList.length === 0 ? "" : ""}
                </div>
              </div>
            </Upload>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default React.memo(EditFoundation);
