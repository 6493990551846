import React from "react";

export default function IdentitasRaport({ data }) {
  return (
    <div className="identitas-raport-container">
      {/* kolom 1 */}
      <div className="col-idn ">
        <div className="title">
          <p>Nama Sekolah</p>
          <p>Alamat</p>
          <p>Nama Peserta Didik</p>
          <p>NIS</p>
          <p>NISN</p>
        </div>
        <div className="batas">
          <p>:</p>
          <p>:</p>
          <p>:</p>
          <p>:</p>
          <p>:</p>
        </div>
        <div className="field">
          <p>{data?.school_name}</p>
          <p>{data?.address}</p>
          <p>{data?.student_name}</p>
          <p>{data?.nis}</p>
          <p>{data?.nisn}</p>
        </div>
      </div>

      {/* kolom 2 */}
      <div className="col-idn ">
        <div className="title">
          <p>Kelas</p>
          <p>Semester</p>
          <p>Tahun</p>
        </div>
        <div className="batas">
          <p>:</p>
          <p>:</p>
          <p>:</p>
        </div>
        <div className="field">
          <p>{data?.kelas}</p>
          <p>{data?.semester}</p>
          <p>{data?.tahun}</p>
        </div>
      </div>
    </div>
  );
}
