import { MenuOutlined } from "@ant-design/icons";
import NavMenus from "Layouts/NavMenus";
import { Drawer, Layout } from "antd";
import LogoFG from "assets/img/logoFG.png";
import BreadCrumb from "components/UI/BreadCrumb";
import React, { useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import LayoutProfileLogic from "./LayoutProfileLogic";
import DynamicTitle from "components/UI/DynamicTitle";

const { Sider, Content, Header } = Layout;

const LayoutProfile = (props) => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );

  const { items, items2, open, setOpen, handleClickMenu, navigate } =
    LayoutProfileLogic();

  return (
    <Layout>
      <DynamicTitle />
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={250}
      >
        <div className="mobile-menu-wrapper">
          <NavMenus
            items={items}
            theme="light"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultProfileMenu"}
            defaultOpen={""}
          />
        </div>
      </Drawer>
      <div className="menu-mobile">
        <div onClick={() => navigate("/home")}>
          <img src={LogoFG} alt="logo fg" style={{ width: 22 }} />
          <h1 style={{ fontSize: "1.4em" }}>SMA FG</h1>
        </div>
        <MenuOutlined
          style={{ fontSize: "1.3em" }}
          onClick={() => setOpen(true)}
        />
      </div>

      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={window.innerWidth > 1600 ? 250 : 210}
      >
        <div className="logo">
          <img src={LogoFG} alt="fg" />
          <span>SMA FG</span>
        </div>
        <div className="sider-menu-wrapper">
          <NavMenus
            theme="dark"
            items={items}
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultProfileMenu"}
            defaultOpen={""}
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header>
          {React.createElement(HiOutlineMenuAlt2, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
        </Header>
        <BreadCrumb />

        <Content className="site-layout-background">{props.content}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutProfile;
