import MemberActivity from "./member/MemberActivity";
import SubActivity from "./sub-activity/SubActivity";

const DetailActivity = () => {
  return (
    <>
      <SubActivity />
      <MemberActivity />
    </>
  );
};

export default DetailActivity;
