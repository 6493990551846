import { DatePicker, Form, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import styles from "./MasterHrd.module.css";
import { Column } from '@ant-design/plots';
import { usePresenceEmployee } from "hooks/dashboard-hrd/usePresenceEmployee";
import dayjs from "dayjs";
import moment from "moment";
import { useActivityListDashboard } from "hooks/activity-hook/useActivityListDashboard";

const DashboardPresensiKaryawan = ({ dataUnit, isLoadingUnit }) => {
   const [form] = Form.useForm();
   const [startDateKepegawaian, setStartDateKepegawaian] = useState(
      dayjs().subtract(7, "day").format("YYYY-MM-DD")
   );
   const [endDateKepegawaian, setEndDateKepegawaian] = useState(
      dayjs().format("YYYY-MM-DD")
   );
   const [unitId, setUnitId] = useState("");
   const [activityId, setActivityId] = useState("");

   const { data: presenceEmployee } = usePresenceEmployee(
      startDateKepegawaian,
      endDateKepegawaian,
      unitId,
      activityId
   );
   // fetch acvitiy list
   const { data: activityList, refetch: fetchActivityList, isFetching: isFetchingActivity } = useActivityListDashboard(unitId);
   // column chart
   const dataChart = presenceEmployee?.data || [];

   useEffect(() => {
      if (unitId !== "") fetchActivityList();
   }
      , [unitId, fetchActivityList]);


   const configChart = {
      data: dataChart,
      isGroup: true,
      xField: 'date',
      yField: 'value',
      seriesField: 'name',
      isPercent: true,
      label: {
         position: 'middle',
         layout: [
            {
               type: 'interval-adjust-position',
            },
            {
               type: 'interval-hide-overlap',
            },
            {
               type: 'adjust-color',
            },
         ],
         content: (data) => {
            return `${(data.value * 100).toFixed(2)}%`;
         },
      },
      yAxis: {
         label: {
            formatter: (v) => `${v * 100}%`,
         },
      },
   };
   return (
      <div className={styles.card}>
         <h2 className={styles.headerSecondary}>Presensi Karyawan</h2>
         <div>
            <Form className={styles.filterKepegawaian} form={form}>
               <Tooltip Tooltip title="Unit">
                  <Form.Item
                     name="unit"
                  >
                     <Select
                        placeholder="Pilih Unit"
                        disabled={isLoadingUnit}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                           option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                           setUnitId(value)
                           setActivityId("")
                           form.setFieldsValue({ activity: "" })
                        }}
                     >
                        <Select.Option
                           className="select-option-foundation"
                           value=""
                        >
                           ALL
                        </Select.Option>
                        {dataUnit?.data?.map((x) => (
                           <Select.Option
                              key={x.id}
                              value={x.id}
                              className="select-option-foundation"
                           >
                              {x.name}
                           </Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               </Tooltip>
               <Tooltip Tooltip title="Activity List">
                  <Form.Item
                     name="activity"
                  >
                     <Select
                        placeholder="Pilih Activity"
                        disabled={isFetchingActivity || unitId === ""}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                           option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                        }
                        onChange={(value) => setActivityId(value)}
                     >
                        <Select.Option
                           className="select-option-foundation"
                           value=""
                        >
                           ALL
                        </Select.Option>
                        {activityList?.data?.map((x) => (
                           <Select.Option
                              key={x.id}
                              value={x.id}
                              className="select-option-foundation"
                           >
                              {x.name}
                           </Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               </Tooltip>
               <Tooltip title="Start Date">
                  <Form.Item
                     name="startDateTeacher"
                     initialValue={dayjs(startDateKepegawaian)}
                  >
                     <DatePicker
                        inputReadOnly
                        format="YYYY-MM-DD"
                        placeholder="from date"
                        onChange={(value) =>
                           setStartDateKepegawaian(dayjs(value).format("YYYY-MM-DD"))
                        }
                     />
                  </Form.Item>
               </Tooltip>
               <Tooltip title="End Date">
                  <Form.Item
                     name="endDateTeacher"
                     initialValue={dayjs(
                        moment(new Date()).format("YYYY-MM-DD")
                     )}
                  >
                     <DatePicker
                        inputReadOnly
                        onChange={(value) =>
                           setEndDateKepegawaian(dayjs(value).format("YYYY-MM-DD"))
                        }
                        format="YYYY-MM-DD"
                        placeholder="to date"
                     />
                  </Form.Item>
               </Tooltip>
            </Form>
         </div>
         <Column {...configChart} />
      </div>
   )
}

export default DashboardPresensiKaryawan