import React from "react";
import { Column, Line } from "@ant-design/plots";
import { Button, Form, Radio, Select, Skeleton } from "antd";
import moment from "moment";
import { useState } from "react";
import { useRecapsDailyList } from "hooks/attandance-hook/useRecapsDailyList";
import { useEffect } from "react";

import "./GrafikRecapsDaily.css";

import html2canvas from "html2canvas";
import download from "downloadjs";
import { DownloadOutlined } from "@ant-design/icons";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

const GrafikRecapsDaily = () => {
  const [lastMonths, setLastMonths] = useState(1);
  const [lastDays, setLastDays] = useState(7);

  const [mode, setMode] = useState("perClass");
  const [akumulasiBulanan, setAkumulasiBulanan] = useState();
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // * Get data recaps daily ---
  const { data: dataRecaps, isLoading } = useRecapsDailyList(
    lastMonths,
    lastDays,
    foundationId,
    true
  );


  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const [lineCharts, setLineCharts] = useState([]);

  // screenshoot

  //TODO: fungsi untuk grafik harian ---
  const grafikHarian = () => {
    const groupedData = {};

    // Group data berdasarkan class_name
    dataRecaps?.data?.dataHarian?.forEach((item) => {
      const className = item.class_name;

      if (!groupedData[className]) {
        groupedData[className] = [];
      }

      groupedData[className].push(item);
    });

    // Buat komponen Line dan tambahkan ke array lineCharts
    const lineChartsArray = [];
    for (let key in groupedData) {
      const res = groupedData[key];
      const data = res.map((x) => ({
        ...x,
        tanggal: moment(x.tanggal).format("YYYY-MM-D"),
        present_accumulation: x.present_accumulation
          ? parseInt(x.present_accumulation)
          : 0,
      }));

      const configLine = {
        data,
        xField: "tanggal",
        yField: "present_accumulation",
        label: { formatter: (x) => `${x.present_accumulation}%` },
        point: {
          size: 3,
          shape: "circle",
        },
        tooltip: {
          showMarkers: false,
          formatter: (datum) => {
            return {
              name: "Kehadiran",
              value: `${datum.present_accumulation}%`,
            };
          },
        },
        state: {
          active: {
            style: {
              shadowBlur: 4,
              stroke: "#000",
              fill: "red",
            },
          },
        },
        interactions: [{ type: "marker-active" }],
        yAxis: {
          grid: { line: { style: { stroke: "#ddd" } } },
          tickCount: 10,
          min: 0,
          max: 100,
          label: { style: {}, formatter: (x) => `${x}%` },
          nice: true,
          minInterval: 10,
        },
        xAxis: {
          grid: { line: { style: { stroke: "#ddd" } } },
          label: { style: {}, rotate: 5.7, offsetY: 25, offsetX: -50 },
        },
        smooth: true,
      };

      const lineChart = (
        <div
          key={key}
          className="chart-item"
          style={{
            margin: "0px 0 10px 0",
            position: "relative",
            width: "100%",
          }}
        >
          <Button
            style={{ position: "absolute", right: "10px", marginTop: "-30px" }}
            onClick={() => handleDownloadHarian(key)}
            type="primary"
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
          <br />
          <div id={`elementToCapture_${key}`}>
            <h1
              style={{
                marginTop: "-30px",
                color: "#4169E1",
                fontWeight: "bold",
              }}
            >
              Grafik Kehadiran Kelas - {key}
            </h1>

            <Line
              {...configLine}
              height={window.innerHeight > 800 ? 400 : 230}
              loading={isLoading}
            />
          </div>
        </div>
      );

      lineChartsArray.push(lineChart);
    }

    setLineCharts(lineChartsArray);
  };

  //TODO: fungsi untuk grafik bulanan ---
  const grafikBulanan = () => {
    if (dataRecaps) {
      const newAkumulasiBulanan = dataRecaps?.data?.akumulasiBulanan?.map(
        (x) => {
          return {
            ...x,
            bulan: moment(x.bulan).format("MMM-YYYY"),
            present_accumulation: parseInt(x.present_accumulation),
            total_student: x.total_student,
          };
        }
      );

      const configColumn = {
        data: newAkumulasiBulanan,
        xField: "class_name",
        yField: "present_accumulation",

        label: {
          position: "middle",
          style: { fill: "#FFFFFF" },
          formatter: (x) => `${x.present_accumulation}%`,
        },
        tooltip: {
          showMarkers: false,
          formatter: (datas) => {
            return {
              name: "Akumulasi kehadiran",
              value: datas.present_accumulation + "%",
              // value: moment(datum.bulan).format("MMM-YYYY"),
            };
          },
        },
        yAxis: {
          grid: { line: { style: { stroke: "#ddd" } } },
          tickCount: 9,
          min: 0,
          max: 100,
          label: { formatter: (x) => `${x}%` },
        },
        xAxis: {
          label: {
            // autoHide: true,
            // autoRotate: false,
            style: {},
            rotate: 5.7,
            offsetY: 15,
          },
        },
        maxColumnWidth: 100,
      };

      setAkumulasiBulanan(configColumn);
    }
  };

  useEffect(() => {
    grafikHarian();
    grafikBulanan();
    // eslint-disable-next-line
  }, [dataRecaps]);

  // ---
  const handleDownloadBulanan = () => {
    const elemenToImage = document.getElementById("elemenToImage");

    if (elemenToImage) {
      html2canvas(elemenToImage).then((canvas) => {
        // Mengonversi canvas menjadi data URL
        const imgData = canvas.toDataURL("image/png");

        // Mengunduh gambar dengan downloadjs
        download(imgData, "chart-bulanan.png", "image/png");
      });
    }
  };

  const handleDownloadHarian = (key) => {
    const elemenToImage = document.getElementById(`elementToCapture_${key}`);

    if (elemenToImage) {
      html2canvas(elemenToImage).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        download(imgData, `chart-harian-${key}.png`, "image/png");
      });
    }
  };

  return (
    <div>
      <div className="table-header">
        <h1>Grafik Kehadiran</h1>
      </div>
      <Form layout="vertical">
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <Form.Item label="MODE">
            <Radio.Group defaultValue="perClass" buttonStyle="solid">
              <Radio.Button
                value="perClass"
                onChange={(e) => setMode(e.target.value)}
              >
                PER KELAS ( HARIAN )
              </Radio.Button>
              <Radio.Button
                value="allClass"
                onChange={(e) => setMode(e.target.value)}
              >
                SEMUA KELAS ( BULANAN )
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <div style={{ width: "200px" }}>
            <Form.Item label="HARI" name="days" hidden={mode !== "perClass"}>
              <Select defaultValue={lastDays} onChange={(e) => setLastDays(e)}>
                <Select.Option value={7}>7 Hari Terakhir</Select.Option>
                <Select.Option value={15}>15 Hari Terakhir</Select.Option>
                <Select.Option value={20}>20 Hari Terakhir</Select.Option>
                <Select.Option value={30}>30 Hari Terakhir</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="BULAN" name="months" hidden={mode === "perClass"}>
              <Select
                defaultValue={lastMonths}
                onChange={(e) => setLastMonths(e)}
              >
                <Select.Option value={1}>1 Bulan Terakhir</Select.Option>
                <Select.Option value={2}>2 Bulan Terakhir</Select.Option>
                <Select.Option value={3}>3 Bulan Terakhir</Select.Option>
                <Select.Option value={4}>4 Bulan Terakhir</Select.Option>
                <Select.Option value={5}>5 Bulan Terakhir</Select.Option>
                <Select.Option value={6}>6 Bulan Terakhir</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div style={{ width: "300px" }}>
            {isSuperAdmin &&
              <Form.Item
                name="foundationId"
                label="Yayasan"
                className="form-item-kehadiran"
              >
                <Select
                  style={{ minWidth: 150 }}
                  onChange={(x) => setFoundationId(x)}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option className="select-option-foundation" value="">ALL</Select.Option>
                  {dataFoundation?.data?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}
                      className="select-option-foundation"
                    >
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
          </div>
        </div>
      </Form>
      <br />

      {mode === "perClass" ? (
        <>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <div className="chart-container-wrap">{lineCharts}</div>
          )}
        </>
      ) : (
        <div className="chart-item chart-container-wrap1">
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={handleDownloadBulanan}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
          <div id="elemenToImage">
            <div className="table-header">
              <h1>SEMUA KELAS</h1>
            </div>
            <br />
            <Column
              {...akumulasiBulanan}
              height={window.innerHeight > 800 ? 300 : 230}
              loading={isLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GrafikRecapsDaily;
