import { Button, Form, Input } from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";
import React, { useState } from "react";

export default function CreateGroup() {
  //eslint-disable-next-line
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");

  return (
    <div>
      <Form layout="vertical">
        <Form.Item label="Foundation">
          {roles.some((x) => x === "super_admin") && (
            <FilterFoundation
              setFoundationId={setFoundationId}
              isNotShowAll={true}
              isHiddenTooltip={true}
            />
          )}
        </Form.Item>

        <Form.Item label="Nama">
          <Input />
        </Form.Item>

        <Form.Item label="Deskripsi">
          <Input />
        </Form.Item>

        <Form.Item label="Unit">
          <Input />
        </Form.Item>

        <Button type="primary">Simpan</Button>
      </Form>
    </div>
  );
}
