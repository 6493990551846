import { Divider, Form, Radio, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import React, { useEffect, useState } from "react";

const PendaftaranSetting = () => {
  const { REACT_APP_HOST_API } = process.env;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/settings/is-active`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      form.setFieldsValue({
        active: data.data[0].active,
      });
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleEdit = async (val) => {
    const datas = {
      active: val,
    };
    try {
      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/ppdb/settings/is-active`,
        datas,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
    } catch (error) {
      alert(error);
    }
  };

  // fetch Batch permission
  const { data: dataPermission } = usePermission("mdlPpdb", "mnuSetting");

  // Buttons Permission
  const btnEdit = dataPermission?.find(
    (x) => x.id === "btnEditPendaftaranAktif"
  );

  return (
    <div>
      <Divider />
      <div className="table-header">
        <h1>PENDAFTARAN</h1>
      </div>

      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form}>
          <Form.Item name="active" hidden={!btnEdit}>
            <Radio.Group
              disabled={btnEdit && btnEdit.type === "disabled"}
              buttonStyle="solid"
              onChange={(e) => handleEdit(e.target.value)}
            >
              <Radio.Button value={true}>BUKA PENDAFTARAN</Radio.Button>
              <Radio.Button value={false}>TUTUP PENDAFTARAN</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default PendaftaranSetting;
