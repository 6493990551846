import { Tag } from "antd";
import { useBillingsDetail } from "hooks/finance-hook/useBIllingsDetail";
// import { useTransactionBillings } from "hooks/finance-hook/useTransactionBillings";
import { usePermission } from "hooks/usePermissions";
import {
  renderAmount,
  renderDateTime,
} from "pages/si-finance/utils/utlisFunction";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const DetailBillingsLogic = () => {
  const { billings_id: id } = useParams();
  const [dataId, setDataId] = useState("");
  const [dataTransaction, setDataTransaction] = useState({}); //
  const [showEditBillings, setShowEditBillings] = useState(false);

  // get transaction detail
  const {
    data: dataDetail,
    isLoading: skeleton,
    refetch,
  } = useBillingsDetail(id, true);

  const data = dataDetail?.data;
  const { data: dataPermission } = usePermission("mdlFinance", "mnuBillings");

  const onUpdate = useCallback(() => {
    console.log("update");
    setShowEditBillings(false);
    setDataTransaction({});
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowEditBillings(false);
    setDataTransaction({});
  };

  const btnEdit = dataPermission?.find((x) => x.id === "btnEditBillings");
  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    {
      title: "Tanggal transaksi",
      dataIndex: "created_at",
      align: "left",
      render: (created_at) => {
        return renderDateTime(created_at);
      },
    },
    {
      title: "Nominal",
      dataIndex: "pivot_amount",
      align: "left",
      render: (pivot_amount) => {
        return renderAmount(pivot_amount);
      },
    },
    { title: "Rekening sumber", dataIndex: "rekeningSumber", align: "left" },
    { title: "Pemilik Akun", dataIndex: "accountOwner", align: "left" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setDataTransaction(
                    data?.related_transaction?.find((x) => x.id === id)
                  );
                  setShowEditBillings(true);
                }
              }}
              disabled={btnEdit?.type === "disabled"}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = data?.related_transaction?.map((x, i) => {
    return {
      ...x,
      key: x.id,
      index: i + 1,
      rekeningSumber: x.revenue?.account?.number,
      accountOwner: x.revenue?.account?.account_name,
    };
  });

  return {
    data,
    skeleton,
    columns,
    dataSource,
    dataId,
    showEditBillings,
    dataTransaction,
    onUpdate,
    onCancel,
  };
};

export default DetailBillingsLogic;
