import { DatePicker, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { TriwulanReportContext } from "context/TriwulanReportContext";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import { useContext, useState } from "react";

const format = "YYYY-MM-DD";

const CreateTReport = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { showCreate, setShowCreate, setFetchReport } = useContext(
    TriwulanReportContext
  );
  const isSuperAdmin = decryptCookies("role").split(",").some((x) => x === "super_admin" || x === "admin_foundation");

  const { REACT_APP_HOST_API } = process.env;

  const { data: dataUnits, isLoading: loadingUnits } =
    useUnitList();

  const onSubmit = async () => {
    const values = await form.validateFields();
    if (!isSuperAdmin) {
      const unitIdFromArray = dataUnits?.data?.find((x) => x.employeeUnits.length > 0);
      values.unitId = unitIdFromArray?.id;
    }
    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/triwulans",
        {
          name: values.name,
          fromDate: values.fromDate.format(format),
          toDate: values.toDate.format(format),
          description: values.description,
          unitId: values.unitId,
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      setFetchReport(true);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    setShowCreate(false);
    form.resetFields();
  };

  return (
    <Modal
      open={showCreate}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      centered
    >
      <Form form={form} layout="vertical">
        {
          isSuperAdmin &&
          <Form.Item
            name="unitId"
            label="Unit"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={loadingUnits}
            >
              {dataUnits?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          name="name"
          label="Periode"
          tooltip="Contoh: Triwulan 1 2023"
          rules={[{ required: true, min: 3 }]}
        >
          <Input placeholder="Contoh: Triwulan 1 2023" />
        </Form.Item>
        <Form.Item
          name="fromDate"
          label="Tanggal Awal"
          rules={[{ required: true }]}
        >
          <DatePicker format={format} />
        </Form.Item>
        <Form.Item
          name="toDate"
          label="Tanggal Akhir"
          rules={[{ required: true }]}
        >
          <DatePicker format={format} />
        </Form.Item>
        <Form.Item name="description" label="Deskripsi" requiredMark="optional">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTReport;
