import { Form, Input, Modal, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

const AddInterview = ({ open, setOpen, candidateName, candidateId }) => {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    await form.setFieldsValue({
      full_name: candidateName,
    });
  };

  const [idBatch, setIdBatch] = useState("");

  //fetch batch ---
  const getBatch = async () => {
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/ppdb/settings/batches?page=1&limit=10&keyword=&is_active=true`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      setIdBatch(data.data.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getBatch();
    // eslint-disable-next-line
  }, [candidateName]);

  const handleCancel = () => {
    setOpen();
    form.resetFields();
  };

  const handleSubmit = async () => {
    const val = await form.validateFields();
    setIsLoading(true);

    try {
      const newData = {
        ...val,
        batch_id: idBatch,
      };
      await axios.post(
        `${REACT_APP_HOST_API}/ppdb/student-candidates/${candidateId}/interviews`,
        newData,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success("Berhasil menambahkan interview");
      form.resetFields();
      setOpen();
    } catch (error) {
      if (error.response.status === 400) {
        alert("Tidak dapat menyimpan data dengan topik yang sama!");
      } else {
        alert(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      style={{ marginTop: "-80px" }}
      open={open}
      onCancel={handleCancel}
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Nama Kandidat" name="full_name">
          <Input disabled={true} />
        </Form.Item>

        <Form.Item label="Topik" name="topic" rules={[{ required: true }]}>
          <Select
            options={[
              {
                value: "umum",
                label: "Umum",
              },
              {
                value: "tahfidz",
                label: "Tahfidz",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Nama Interviewer"
          name="interviewer_name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Program"
          name="program_result"
          rules={[{ required: true }]}
        >
          <Select
            options={[
              {
                value: "mahad",
                label: "Mahad",
              },
              {
                value: "boarding",
                label: "Boarding",
              },
              {
                value: "fullday",
                label: "Fullday",
              },
              {
                value: "wisma",
                label: "Wisma",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Jurusan"
          name="major_result"
          rules={[{ required: true }]}
        >
          <Select
            options={[
              {
                value: "mipa",
                label: "Mipa",
              },
              {
                value: "ips",
                label: "Ips",
              },
              {
                value: "bahasa",
                label: "Bahasa",
              },
            ]}
          />
        </Form.Item>

        <Form.Item label="SPP" name="spp_result" rules={[{ required: true }]}>
          <Select
            options={[
              {
                value: "2_juta",
                label: "2 Juta",
              },
              {
                value: "1_5_juta",
                label: "1.5 Juta",
              },
              {
                value: "1_3_juta",
                label: "1.3 Juta",
              },
              {
                value: "1_1_juta",
                label: "1.1 Juta",
              },
            ]}
          />
        </Form.Item>

        <Form.Item label="Catatan" name="note">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddInterview;
