import { Button, Input, Popconfirm, Space, Table, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useSubActivityPresence } from "hooks/activity-hook/useSubActivityPresence";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AddPresence from "./AddPresence";
import { usePermission } from "hooks/usePermissions";

const Presence = () => {
  const [keyword, setKeyword] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const { REACT_APP_HOST_API } = process.env;
  const { session_id } = useParams();

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuActivity");

  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddPresenceSubActivity"
  );
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeletePresenceSubActivity"
  );

  const { data, isFetching, refetch } = useSubActivityPresence({
    id: session_id,
    dataTable: { page: 1, limit: 100 },
    keyword,
  });

  const onDelete = async () => {
    try {
      await axios.delete(REACT_APP_HOST_API + "/multi-delete-presences", {
        data: { presences: selectedRow },
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      message.success("Berhasil menghapus data");
      // fetchDetail();
      refetch();
      setSelectedRow([]);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    index: i + 1,
    key: x.id,
    name: x.employee?.name,
  }));

  const columns = [
    { title: "No", dataIndex: "index", align: "center", width: 50 },
    { title: "Nama", dataIndex: "name" },
  ];

  const rowSelection = {
    onChange: setSelectedRow,
    preserveSelectedRowKeys: true,
  };

  const onAdd = () => {
    refetch();
    setShowAdd(false);
  };

  const onCancel = () => {
    setShowAdd(false);
  };

  return (
    <>
      <div className="table-header" style={{ marginTop: 30 }}>
        <h1>Presensi</h1>
        <Space>
          <Input
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
            placeholder="cari nama..."
          />
          <Popconfirm
            disabled={
              selectedRow.length === 0 || btnDelete?.type === "disabled"
            }
            title="Yakin ingin menghapus data?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={onDelete}
          >
            <Button
              danger
              type="primary"
              hidden={!btnDelete}
              disabled={
                selectedRow.length === 0 || btnDelete?.type === "disabled"
              }
            >
              Hapus
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            onClick={() => {
              if (btnAdd?.type === "disabled") return;
              else setShowAdd(true);
            }}
            disabled={btnAdd?.type === "disabled"}
            hidden={!btnDelete}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <Table
        tableLayout="auto"
        size="small"
        columns={columns}
        pagination={false}
        loading={isFetching}
        scroll={{ y: "30vh" }}
        dataSource={dataSource}
        rowSelection={{ ...rowSelection }}
      />
      <AddPresence show={showAdd} onCreate={onAdd} onCancel={onCancel} />
    </>
  );
};

export default Presence;
