import { AppstoreFilled, LogoutOutlined } from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import { encryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { RiUserFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const LayoutProfileLogic = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleSelectMenu = (param) => {
    encryptCookies("defaultProfileMenu", param);
  };

  const handleClickMenu = (param) => {
    if (param.key === "") return;
    else {
      handleSelectMenu(param.key);

      if (param.key === "logout") return;
      else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuProfile") navigate("/profile");
      else navigate("/profile/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    { key: "mnuProfile", icon: <RiUserFill />, label: "Profile" },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return { items, items2, open, setOpen, handleClickMenu, navigate };
};

export default LayoutProfileLogic;
