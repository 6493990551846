import { Skeleton } from "antd";
import { useStudentCandidate } from "hooks/ppdb-hook/useStudentCandidate";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

const AlamatSiswaTab = () => {
  const { candidate_id } = useParams();
  const { data, isLoading } = useStudentCandidate(candidate_id, true);

  let dataInti = data?.data[0];

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && dataInti && (
        <Fragment key={dataInti.id}>
          <table className="detail-student__table">
            <tbody>
              <tr>
                <th>Alamat</th>
                <td>{dataInti.address && dataInti.address}</td>
              </tr>
              <tr>
                <th>RT</th>
                <td>{dataInti.rt && dataInti.rt}</td>
              </tr>
              <tr>
                <th>RW</th>
                <td>{dataInti.rw && dataInti.rw}</td>
              </tr>
              <tr>
                <th>Provinsi</th>
                <td>{dataInti.provinsi && dataInti.provinsi.nama}</td>
              </tr>
              <tr>
                <th>Kota</th>
                <td>{dataInti.kota && dataInti.kota.nama}</td>
              </tr>
              <tr>
                <th>Kecamatan</th>
                <td>{dataInti.kecamatan && dataInti.kecamatan.nama}</td>
              </tr>
              <tr>
                <th>Kelurahan</th>
                <td>{dataInti.kelurahan && dataInti.kelurahan.nama}</td>
              </tr>
              <tr>
                <th>Kode Pos</th>
                <td>{dataInti.zip && dataInti.zip}</td>
              </tr>
              <tr>
                <th>Jenis Tinggal</th>
                <td>
                  {dataInti.residence &&
                    (() => {
                      if (dataInti.residence === "others") {
                        return "lainnya";
                      } else if (dataInti.residence === "with parent") {
                        return "Dengan orang tua";
                      } else if (dataInti.residence === "boarding school") {
                        return "Pondok";
                      } else if (dataInti.residence === "dormitory") {
                        return "Asrama";
                      }
                    })()}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default React.memo(AlamatSiswaTab);
