import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useRoleList } from "hooks/role-hook/useRoleList";
import { useEffect, useState } from "react";

const AddUserLogic = ({ show, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState(true);

  const { REACT_APP_HOST_API } = process.env;

  const { data: dataRole, refetch: fetchRoles } = useRoleList();

  const {
    data: dataEmployee,
    isLoading,
    refetch: fetchEmployee,
  } = useEmployeeList();

  useEffect(() => {
    if (show) {
      fetchRoles();
      fetchEmployee();
    }
  }, [show, fetchRoles, fetchEmployee]);

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const onFinish = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/system/users`,
        { ...values, verified: radioValue },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      onCreate();
      form.resetFields();
      message.success(data.message);
    } catch (error) {
      if (error.response.data.message) alert(error?.response?.data?.message);
      else alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return {
    form,
    loading,
    dataRole,
    isLoading,
    radioValue,
    dataEmployee,
    onFinish,
    onCancelModal,
    handleRadioChange,
  };
};

export default AddUserLogic;
