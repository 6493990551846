import { SearchOutlined } from "@ant-design/icons";
import { Badge, Input, Popconfirm, Space, Table, Tag } from "antd";
import { useStudentCandidatesPagination } from "hooks/ppdb-hook/useStudentCandidatesPagination";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddInterview from "../bread-interview/creat/AddInterview";
import { DeleteApi } from "api/DeleteApi";
import EditInterview from "../bread-interview/edit/EditInterview";
import EditStatus from "../bread-status/EditStatus";
import { usePermission } from "hooks/usePermissions";

const ForAdmin = () => {
  const navigate = useNavigate();
  const [openInterview, setOpenInterview] = useState(false);
  const [openEditInterview, setOpenEditInterview] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  // fetch Batch permission
  const { data: dataPermission } = usePermission("mdlPpdb", "mnuPendaftaran");

  // Buttons Permission
  const btnAddInterview = dataPermission?.find(
    (x) => x.id === "btnAddInterviewAdmin"
  );
  const btnStatusKeputusan = dataPermission?.find(
    (x) => x.id === "btnStatusKeputusanAdmin"
  );
  const btnDeleteInterview = dataPermission?.find(
    (x) => x.id === "btnDeleteInterviewAdmin"
  );
  const btnEditInterview = dataPermission?.find(
    (x) => x.id === "btnEditInterviewAdmin"
  );

  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  // fetch candidate list
  const { data, isLoading, isFetching, refetch } =
    useStudentCandidatesPagination(dataTable, keyword);

  const [candidateName, setCandidateName] = useState("");
  const [candidateId, setCandidateId] = useState("");

  const handleSetOpenInterview = (name, id) => {
    setCandidateId(id);
    setCandidateName(name);
    setOpenInterview(!openInterview);
    refetch();
  };

  const [interviewId, setInterviewId] = useState("");

  const handleSetOpenEditInterview = (id) => {
    setInterviewId(id);
    setOpenEditInterview(!openEditInterview);
    refetch();
  };

  const handleSetOpenStatus = (id) => {
    setCandidateId(id);
    setOpenStatus(!openStatus);
    refetch();
  };

  const columnsInterview = [
    {
      title: "Topik",
      dataIndex: "topic",
      align: "left",
    },
    {
      title: "Interviewer",
      dataIndex: "interviewer_name",
      align: "left",
    },
    {
      title: "Program",
      dataIndex: "program_result",
      align: "left",
    },
    {
      title: "Jurusan",
      dataIndex: "major_result",
      align: "left",
    },
    {
      title: "SPP",
      dataIndex: "spp_result",
      align: "left",
      render: (spp_result) => {
        return spp_result === "2_juta"
          ? "2 Juta"
          : spp_result === "1_5_juta"
          ? "1.5 Juta"
          : spp_result === "1_3_juta"
          ? "1.3 Juta"
          : spp_result === "1_1_juta"
          ? "1.1 Juta"
          : "";
      },
    },
    {
      title: "Catatan",
      dataIndex: "note",
      align: "left",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, x) => {
        return (
          <Space>
            <Tag
              hidden={!btnEditInterview}
              className="btn"
              color="green"
              onClick={() =>
                btnEditInterview && btnStatusKeputusan.type !== "disabled"
                  ? handleSetOpenEditInterview(id)
                  : null
              }
            >
              Edit
            </Tag>
            <Popconfirm
              hidden={!btnDeleteInterview}
              disabled={
                btnDeleteInterview && btnDeleteInterview.type === "disabled"
              }
              title="Yakin ingin menghapus data?"
              okText="Iya"
              cancelText="Tidak"
              onConfirm={() =>
                DeleteApi({
                  url: "/ppdb/interviews/",
                  dataId: id,
                  refetch,
                })
              }
            >
              <Tag className="btn" color="error">
                Hapus
              </Tag>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    {
      title: "Status",
      dataIndex: "id",
      render: (id, x) => {
        return (
          <Badge
            status={
              x.status === "new"
                ? "default"
                : x.status === "done_primary_data"
                ? "warning"
                : x.status === "failed_exam"
                ? "error"
                : x.status === "failed_re_register"
                ? "error"
                : x.status === "pass_exam"
                ? "processing"
                : x.status === "pass_re_register"
                ? "success"
                : "default"
            }
            text={
              x.status === "new"
                ? "Baru"
                : x.status === "done_primary_data"
                ? "Daftar Awal"
                : x.status === "failed_exam"
                ? "Gagal Ujian"
                : x.status === "failed_re_register"
                ? "Gagal Verifikasi"
                : x.status === "pass_exam"
                ? "Lulus Ujian"
                : x.status === "pass_re_register"
                ? "Diverifikasi"
                : x.status === "waiting_reregister_verification"
                ? "Menunggu Verifikasi"
                : null
            }
          />
        );
      },
    },
    {
      title: "Nama",
      dataIndex: "full_name",
      align: "left",
    },
    { title: "Nisn", dataIndex: "nisn", align: "left" },
    { title: "Program Pilihan", dataIndex: "program_choice", align: "left" },
    { title: "Jurusan Pilihan", dataIndex: "major_choice", align: "left" },
    {
      title: "Telp / Whatsapp",
      dataIndex: "correspondence_phone",
      align: "left",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: 300,
      render: (id, x) => {
        return (
          <Space>
            <Tag
              className="btn"
              color="default"
              hidden={x.interviews.length === 2 || !btnAddInterview}
              onClick={() =>
                btnAddInterview && btnAddInterview.type !== "disabled"
                  ? handleSetOpenInterview(x.full_name, id)
                  : null
              }
            >
              Interview
            </Tag>

            <Tag
              className="btn"
              color="purple"
              hidden={!btnStatusKeputusan}
              onClick={() =>
                btnStatusKeputusan && btnStatusKeputusan.type !== "disabled"
                  ? handleSetOpenStatus(id)
                  : null
              }
            >
              Status | Keputusan
            </Tag>

            <Tag
              className="btn"
              color="processing"
              onClick={() => navigate(`${id}`)}
            >
              Detail
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        // nisn: data.userStudentCandidate.nisn,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>data pendaftar</h1>
      </div>
      <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Cari Nama Pendaftar"
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>

      <Table
        size={window.innerWidth > 1600 ? "middle" : "small"}
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataKey}
        pagination={pagination}
        scroll={{
          // y: "100vh",
          x: 1200,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={columnsInterview}
              dataSource={record.interviews.map((data) => {
                return {
                  ...data,
                  key: data.id,
                };
              })}
              pagination={{
                position: ["none"],
              }}
              scroll={
                {
                  // y: "100vh",
                  // x: 1200,
                }
              }
            />
          ),
          expandRowByClick: true,
          rowExpandable: (record) => record.interviews.length !== 0,
        }}
      />

      {/* Modal Interview */}
      <AddInterview
        open={openInterview}
        setOpen={handleSetOpenInterview}
        candidateName={candidateName}
        candidateId={candidateId}
      />

      <EditInterview
        open={openEditInterview}
        setOpen={handleSetOpenEditInterview}
        idInterview={interviewId}
      />

      {/* Modal Status */}
      <EditStatus
        open={openStatus}
        setOpen={handleSetOpenStatus}
        candidateId={candidateId}
      />
    </div>
  );
};

export default ForAdmin;
