import { Form, Modal, Select, Skeleton } from "antd";
import AddStudentToClassLogic from "./AddStudentToClassLogic";

const AddStudentToClass = (props) => {
  const {
    form,
    loading,
    isLoading,
    dataStudents,
    handleSubmit,
    onCancelModal,
  } = AddStudentToClassLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      onOk={handleSubmit}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            name="studentId"
            label="Nama Siswa"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataStudents?.map((data) => (
                <Select.Option key={data.id} value={data.id}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddStudentToClass;
