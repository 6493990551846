import { Button, Form, Input, message, Result } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function NewPassword() {
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  const { token } = useParams();

  const onSubmit = async () => {
    try {
      const { password, password_confirmation } = await form.validateFields();
      setIsLoad(true);
      const { data } = await axios.post(
        REACT_APP_HOST_API + "/auth/reset-password-forgot",
        {
          token,
          password,
          password_confirmation,
        }
      );

      message.success(data?.message);
      setIsSuccess(true);
      Cookies.remove("emailForgotPassword");
    } catch (error) {
      console.log(error);
      alert("Gagal, reset password!");
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ marginTop: "80px", padding: "5px", maxWidth: "350px" }}>
        {!isSuccess ? (
          <div>
            <div style={{ marginBottom: "50px" }}>
              <h1 style={{ fontWeight: "bold" }}>Buat password baru</h1>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Masukan password baru anda.
              </p>
            </div>

            <Form form={form} layout="vertical">
              <Form.Item
                label="Password baru"
                name="password"
                rules={[{ required: true, min: 6 }]}
              >
                <Input.Password
                  size="large"
                  placeholder="Minimal 6 Karakter ..."
                />
              </Form.Item>

              <Form.Item
                label="Konfirmasi password"
                name="password_confirmation"
                rules={[
                  { required: true },
                  { min: 6 },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error("Password harus sama"));
                    },
                  }),
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Ulangi password ..."
                />
              </Form.Item>
            </Form>

            <Button
              onClick={onSubmit}
              type="primary"
              size="large"
              style={{ width: "100%" }}
              loading={isLoad}
            >
              Reset Password
            </Button>
          </div>
        ) : (
          <>
            <Result
              status="success"
              title="Password Diubah!"
              subTitle="kata sandi Anda telah berhasil diubah."
              extra={[
                <Button
                  onClick={() => navigate("/")}
                  type="primary"
                  key="console"
                  size="large"
                >
                  Back to login
                </Button>,
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
}
