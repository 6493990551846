import React, { useCallback, useState } from 'react'
import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Skeleton, Space, Table } from "antd";
import BayarAlokasi from '../bayar/BayarAlokasi';
import './AlokasiPembayaran.css'
import { renderAmount } from 'pages/si-finance/utils/utlisFunction';
import { useAcademicYears } from "hooks/finance-hook/useAcademicYears";
import { useBillingsPagination } from "hooks/finance-hook/useBillingsPagination";
import { useRevenuesDetail } from "hooks/finance-hook/useRevenuesDetail";
import { usePermission } from "hooks/usePermissions";
import {
   renderDateAndMonth,
   statusBillingsRender,
} from "pages/si-finance/utils/utlisFunction";
import { useParams } from "react-router-dom";

// const disabled = { color: "#ccc", cursor: "not-allowed" };

const AlokasiPembayaran = () => {
   const [selectedIds, setSelectedIds] = useState([]);
   const [selectedData, setSelectedData] = useState([]);
   const [keyword, setKeyword] = useState("");
   const [isBayar, setIsBayar] = useState(false);
   const [dataTable, setDataTable] = useState({
      current_page: 1,
      per_page: 15,
      total: 0,
   });
   const [filterObject, setFilterObject] = useState({
      year: "",
   });
   const [form] = Form.useForm();

   const { revenues_id: id } = useParams();
   // const navigate = useNavigate();

   const {
      data: dataDetail,
      isLoading: skeleton,
      refetch: fetchRevenueDetail,
   } = useRevenuesDetail(id, true);

   const { data, isLoading, isFetching, refetch } = useBillingsPagination(
      dataTable,
      keyword,
      filterObject
   );

   // fetch classes permission
   const { data: dataPermission } = usePermission("mdlFinance", "mnuBillings");
   const { data: dataAcademicYears, isLoading: loadingAcademicYears } =
      useAcademicYears();

   const handleChange = (param) => {
      setKeyword(param.target.value);
   };

   const onSelectChange = (key, data) => {
      setSelectedIds(key);
      setSelectedData(data);
   };

   const onCreate = useCallback(() => {
      setIsBayar(false);
      setSelectedIds([]);
      setSelectedData([]);
      refetch();
      fetchRevenueDetail();
   }, [refetch, fetchRevenueDetail]);

   const onCancel = () => {
      setSelectedIds([]);
      setSelectedData([]);
      setIsBayar(false);
   };

   const rowSelection = {
      selectedRowKeys: selectedIds,
      onChange: onSelectChange,
   };

   // Buttons Permission start
   const btnBayar = dataPermission?.find((x) => x.id === "btnBayarBillings");
   // const btnDetail = dataPermission?.find((x) => x.id === "btnDetailBillings");

   const columns = [
      {
         title: "No",
         dataIndex: "index",
         align: "left",
         width: window.innerWidth > 800 ? 70 : 50,
         fixed: "left",
      },
      {
         title: "Nama", dataIndex: "name", align: "left",
         fixed: "left",
         width: window.innerWidth > 800 ? 150 : 100,
      },
      {
         title: "Nama akun tertagih", dataIndex: "account_name", align: "left",
         width: 170
      },
      {
         title: "Rekening",
         dataIndex: "account_number",
         align: "left",
         width: window.innerWidth > 800 ? 100 : 80,
      },
      { title: "Jumlah", dataIndex: "jumlah", align: "left" },
      { title: "Sisa Bayar", dataIndex: "sisaBayar", align: "left" },
      {
         title: "Jatuh Tempo",
         dataIndex: "due_date",
         align: "left",
         width: window.innerWidth > 800 ? 150 : 100,
         render: (dueDate) => {
            return dueDate ? renderDateAndMonth(dueDate) : "-";
         },
      },
      {
         title: "Tipe",
         dataIndex: "type",
         align: "left",
         width: window.innerWidth > 800 ? 70 : 60,
      },
      {
         title: "Status",
         dataIndex: "status",
         align: "left",
         render: (status) => {
            return statusBillingsRender(status);
         },
      },
      // { title: "Deskripsi", dataIndex: "description", align: "left" },
      // {
      //    title: "Action",
      //    dataIndex: "id",
      //    align: "center",
      //    render: (id) => {
      //       return (
      //          <>
      //             <Tag
      //                color="blue"
      //                hidden={!btnDetail}
      //                style={
      //                   btnDetail?.type === "disabled"
      //                      ? disabled
      //                      : { cursor: "pointer" }
      //                }
      //                onClick={() => {
      //                   navigate(`/finance/billings/${id}`);
      //                }}
      //             >
      //                Detail
      //             </Tag>
      //          </>
      //       );
      //    },
      // },
   ];

   const dataSource = data?.data.data
      .slice(0, dataTable.per_page)
      .map((x, i) => {
         return {
            ...x,
            key: x.id,
            index: i + 1,
            account_number: x.account?.number || "-",
            account_name: x.account?.account_name || "-",
            jumlah:
               new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
               }).format(x.amount) || "-",
            sisaBayar: new Intl.NumberFormat("id-ID", {
               style: "currency",
               currency: "IDR",
            }).format(x.remaining_amount),
         };
      });

   const pagination = {
      current: dataTable.current_page,
      pageSize: dataTable.per_page,
      total: data?.data.meta.total,
      showSizeChanger: true,
      pageSizeOptions: [15, 20, 50, 100],
      onChange: (curr, size) => {
         setDataTable((prev) => {
            return {
               ...prev,
               current_page: curr,
               per_page: size,
            };
         });
      },
   };

   return (
      <div>
         <div className="table-header">
            <h1>Alokasi Pembayaran</h1>
            <Space>
               <Button
                  hidden={!btnBayar}
                  type='primary'
                  onClick={() => { setIsBayar(true) }}
                  disabled={rowSelection.selectedRowKeys.length === 0 || (btnBayar && btnBayar.type === "disabled")}
               >
                  Bayar
               </Button>
            </Space>
         </div>
         {skeleton && <Skeleton active />}
         {!skeleton &&
            <div className='alokasi-detail'>
               <p>Owner</p>
               <p>: {dataDetail?.data?.account.account_name}</p>
               <p>No Rekening</p>
               <p>: {dataDetail?.data?.account.number}</p>
               <p>sisa saldo</p>
               <p>: {renderAmount(dataDetail?.data?.current_balance)}</p>
            </div>}
         <Form form={form} layout="vertical">
            <div className="filter-search">
               <Form.Item
                  name="keyword"
                  label="Cari Billings"
               >
                  <Input
                     prefix={<SearchOutlined />}
                     value={keyword}
                     onChange={handleChange}
                     placeholder="Cari Billings berdasarkan nama dan no rekening"
                     className="search-input-billings"
                  />
               </Form.Item>
               <Form.Item
                  name="year"
                  label="Filter Tahun Akademik"
                  defaultValue={filterObject.year}
               >
                  <Select
                     showSearch
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                     }
                     style={{ width: "100%" }}
                     placeholder="Tahun akademik"
                     onChange={(value) => setFilterObject(prev => ({ ...prev, year: value }))}
                     disabled={loadingAcademicYears}
                  >
                     <Select.Option value="">Semua</Select.Option>
                     {dataAcademicYears?.data?.data.map((x) => (
                        <Select.Option key={x.id} value={x.id}>
                           {x.year}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            </div>
         </Form>
         <Table
            size="small"
            tableLayout="auto"
            columns={columns}
            loading={isLoading || isFetching}
            dataSource={dataSource}
            pagination={pagination}
            rowSelection={rowSelection}
            scroll={{
               y: "50vh",
               x: 1200,
            }}
         />
         <BayarAlokasi
            show={isBayar}
            rowSelection={selectedData}
            onCancel={onCancel}
            onCreate={onCreate}
         />
      </div>
   )
}

export default AlokasiPembayaran