import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Space,
  Table,
  Tooltip,
  message,
  DatePicker,
} from "antd";
import ImportRevenues from "../import/ImportRevenues";
import EditRevenues from "../edit/EditRevenues";
import { Popconfirm, Tag } from "antd";
import { useRevenuesPaginition } from "hooks/finance-hook/useRevenuesPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import { DeleteApi } from "api/DeleteApi";
// const ExcelJS = require("exceljs");
import ExcelJS from "exceljs";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import PdfExportRevenue from "../export-pdf/PdfExportRevenue";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import "./Revenues.css";
import dayjs from "dayjs";
import moment from "moment";
import FilterFoundation from "components/UI/FilterFoundation";

const disabled = { color: "#ccc", cursor: "not-allowed" };
const fromDate = dayjs().startOf("month").toDate();
const toDate = dayjs().toDate();

const Revenues = () => {
  const [showImportRevenues, setShowImportRevenues] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isEditRevenues, setIsEditRevenues] = useState(false);
  const [filterObject, setFilterObject] = useState({
    fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
    toDate: dayjs(toDate).format("YYYY-MM-DD"),
  });
  const [foundationId, setFoundationId] = useState("");

  const roles = decryptCookies("role").split(",");

  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;
  const [form] = Form.useForm();

  const { data, isLoading, isFetching, refetch } = useRevenuesPaginition(
    dataTable,
    keyword,
    filterObject,
    foundationId
  );

  // fetch maste billings permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuRevenues");

  const onCancel = () => {
    setShowImportRevenues(false);
    setIsEditRevenues(false);
  };
  const onUpdate = () => {
    setIsEditRevenues(false);
    setSelectedIds([]);
    refetch();
  };

  const onImport = useCallback(() => {
    setShowImportRevenues(false);
    refetch();
  }, [refetch]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const btnImport = dataPermission?.find((x) => x.id === "btnImportRevenues");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditRevenues");
  const btnBayar = dataPermission?.find((x) => x.id === "btnBayarRevenues");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteRevenues");

  const onSelectChange = (key, data) => {
    setSelectedIds(key);
    setSelectedData(data);
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 1200 ? 70 : 50,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "namaPengirim",
      align: "left",
      width: window.innerWidth > 1200 ? 150 : 130,
      fixed: "left",
    },
    {
      title: "Rekening",
      dataIndex: "rekeningSumber",
      align: "left",
      width: window.innerWidth > 1200 ? 100 : 80,
    },
    {
      title: "Mutasi",
      dataIndex: "mutasi",
      align: "left",
      width: window.innerWidth > 1200 ? 100 : 80,
    },

    // {
    //    title: "Atas nama",
    //    dataIndex: "atasNama",
    //    align: "left",
    //    width: window.innerWidth > 1200 ? 300 : 280,
    // },
    // { title: "Jumlah", dataIndex: "jumlah", align: "left" },
    // { title: "Sisa Saldo", dataIndex: "sisaSaldo", align: "left" },
    // {
    //   title: "No Invoice",
    //   dataIndex: "invoice_number",
    //   align: "left",
    //   width: 120,
    // },
    // {
    //   title: "Nominal Invoice",
    //   dataIndex: "invoice_amount",
    //   align: "left",
    //   width: 120,
    // },

    {
      title: "Tanggal",
      dataIndex: "tanggal",
      align: "left",
      width: window.innerWidth > 1200 ? 200 : 180,
    },
    // {
    //   title: "Status",
    //   dataIndex: "statusRender",
    //   align: "left",
    //   width: window.innerWidth > 1200 ? 100 : 80,
    // },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, data) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({
                  url: "/finance/revenues/",
                  dataId,
                  refetch,
                });
              }}
              disabled={
                (btnDelete && btnDelete.type === "disabled") ||
                data.amount !== data.current_balance
              }
            >
              <Tag
                color={
                  btnDelete?.type === "disabled" ||
                  data.amount !== data.current_balance
                    ? "default"
                    : "magenta"
                }
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled" ||
                  data.amount !== data.current_balance
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
              <Tag
                color="blue"
                hidden={!btnBayar}
                style={
                  btnBayar?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnBayar?.type === "disabled") {
                    return;
                  } else {
                    navigate(`/finance/alokasipembayaranrevenues/${id}`);
                  }
                }}
                disabled={btnBayar?.type === "disabled"}
              >
                Bayar
              </Tag>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const statusRender = {
    new: "Baru",
    exported: "Sudah diexport",
  };

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        key: x.id,
        index: i + 1,
        statusRender: statusRender[x.status],
        rekeningSumber: x.account.number,
        namaPengirim: x?.account?.student?.name,
        atasNama: x.account.owner,
        tanggal: DateTime.fromISO(x.time_received, {
          zone: "utc",
        }).toFormat("dd MMMM yyyy hh:mm a"),
        mutasi: renderAmount(x.amount),
        sisaSaldo: renderAmount(x.current_balance),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, 200],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const convertDataFromApi = (inputData) => {
    const outputData = [];

    for (const [month, monthData] of Object.entries(inputData)) {
      if (month === "grand_total") continue;
      const transactions = monthData?.items?.map((item) => ({
        tanggal: item.created_at.split("T")[0],
        nomorRekening: item.account.number,
        namaRekening: item.account.account_name,
        jumlah: item.amount,
      }));
      const subtotal = monthData.sub_total;
      outputData.push({
        month,
        transactions,
        subtotal,
      });
    }

    return outputData;
  };

  const getRevenuesRecap = async () => {
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/finance/revenues/report?academic_year_id=`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      const convertData = convertDataFromApi(data.data);
      return convertData;
    } catch (error) {
      message.error(error.message);
    }
  };

  const getLaporanPemasukanTitle = () => {
    return "Laporan Pemasukan";
  };

  const exportExcelFile = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("My Sheet");
      const data = await getRevenuesRecap();

      // Set the title
      const titleCell = sheet.getCell("A2");
      sheet.mergeCells("A2:D2");
      titleCell.value = getLaporanPemasukanTitle();
      titleCell.alignment = { vertical: "middle", horizontal: "center" };

      let currentRow = 4; // Start from row 6 for the data

      // style
      const fullBorder = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      data.forEach((monthData) => {
        // Set the month header

        sheet.mergeCells(`A${currentRow}:D${currentRow}`);
        sheet.getCell(`A${currentRow}`).value = monthData.month;
        sheet.getCell(`A${currentRow}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        sheet.getCell(`A${currentRow}`).border = fullBorder;
        currentRow++;

        // set table header
        sheet.getCell(`A${currentRow}`).value = "Tanggal";
        sheet.getCell(`B${currentRow}`).value = "Nomor Rekening";
        sheet.getCell(`C${currentRow}`).value = "Nama Rekening";
        sheet.getCell(`D${currentRow}`).value = "Jumlah";
        sheet.getCell(`A${currentRow}`).border = fullBorder;
        sheet.getCell(`B${currentRow}`).border = fullBorder;
        sheet.getCell(`C${currentRow}`).border = fullBorder;
        sheet.getCell(`D${currentRow}`).border = fullBorder;
        // style the column add b

        currentRow++;

        // Set the transaction data
        monthData.transactions.forEach((transaction) => {
          sheet.getCell(`A${currentRow}`).value = transaction.tanggal;
          sheet.getCell(`B${currentRow}`).value = transaction.nomorRekening;
          sheet.getCell(`C${currentRow}`).value = transaction.namaRekening;
          sheet.getCell(`D${currentRow}`).value = transaction.jumlah;
          sheet.getCell(`A${currentRow}`).border = fullBorder;
          sheet.getCell(`B${currentRow}`).border = fullBorder;
          sheet.getCell(`C${currentRow}`).border = fullBorder;
          sheet.getCell(`D${currentRow}`).border = fullBorder;
          currentRow++;
        });

        // Set the subtotal
        // merge cell
        sheet.mergeCells(`A${currentRow}:C${currentRow}`);
        sheet.getCell(`A${currentRow}`).value = "Subtotal";
        sheet.getCell(`A${currentRow}`).alignment = {
          vertical: "middle",
          horizontal: "right",
        };
        // style border
        sheet.getCell(`A${currentRow}`).border = fullBorder;
        sheet.getCell(`D${currentRow}`).value = monthData.subtotal;
        sheet.getCell(`D${currentRow}`).border = fullBorder;
        currentRow += 1; // Leave an empty row after each month's data
      });

      // // Set the grand total
      sheet.getCell(`A${currentRow}`).value = "Grand Total";
      sheet.getCell(`A${currentRow}`).alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      // merge cell
      sheet.mergeCells(`A${currentRow}:C${currentRow}`);
      // style border
      sheet.getCell(`A${currentRow}`).border = fullBorder;
      sheet.getCell(`D${currentRow}`).value = data.reduce(
        (total, monthData) => total + monthData.subtotal,
        0
      );
      sheet.getCell(`D${currentRow}`).border = fullBorder;

      // Set the column width
      sheet.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = getLaporanPemasukanTitle() + ".xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleGeneratePdf = async () => {
    try {
      // fetch billings data
      const data = await getRevenuesRecap();
      const fileName = getLaporanPemasukanTitle();
      const doc = <PdfExportRevenue data={data} title={fileName} />;
      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, fileName);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar penerimaan</h1>
        <Space size={[8, 16]} wrap className="order-1">
          <Button onClick={handleGeneratePdf}>Export PDF</Button>
          <Button onClick={exportExcelFile}>Export Excel</Button>
        </Space>
        <Space size={[8, 16]} wrap className="order-2">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#FA8C16",
              },
            }}
          >
            <Button
              hidden={!btnEdit}
              onClick={() => setIsEditRevenues(true)}
              disabled={
                rowSelection.selectedRowKeys.length === 0 ||
                (btnEdit && btnEdit.type === "disabled")
              }
              type="primary"
            >
              Edit penerimaan
            </Button>
          </ConfigProvider>
          <Button
            hidden={!btnImport}
            type="primary"
            disabled={btnImport?.type === "disabled"}
            onClick={() => setShowImportRevenues(true)}
          >
            Import
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <div className="filter-search">
          {roles.some((x) => x === "super_admin") && (
            <Form.Item>
              <FilterFoundation setFoundationId={setFoundationId} />
            </Form.Item>
          )}

          <Form.Item name="keyword">
            <Tooltip title="Cari nama berdasarkan nama">
              <Input
                prefix={<SearchOutlined />}
                value={keyword}
                onChange={handleChange}
                placeholder="Cari berdasarkan nama/rekening"
                className="search-input-billings"
                style={{
                  border: "1px solid #d9d9d9",
                }}
              />
            </Tooltip>
          </Form.Item>

          <Tooltip title="Dari tanggal">
            <Form.Item name="from_date" initialValue={dayjs().startOf("month")}>
              <DatePicker
                inputReadOnly
                placeholder="Dari tanggal"
                onChange={(e) =>
                  setFilterObject((prev) => ({
                    ...prev,
                    fromDate: e !== null ? dayjs(e).format("YYYY-MM-DD") : "",
                  }))
                }
              />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Sampai tanggal">
            <Form.Item
              name="to_date"
              initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            >
              <DatePicker
                inputReadOnly
                placeholder="Sampai tanggal"
                onChange={(e) =>
                  setFilterObject((prev) => ({
                    ...prev,
                    toDate: e !== null ? dayjs(e).format("YYYY-MM-DD") : "",
                  }))
                }
              />
            </Form.Item>
          </Tooltip>
        </div>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        rowSelection={btnEdit ? rowSelection : null}
        scroll={{
          y: "50vh",
          x: 1200,
        }}
      />
      <EditRevenues
        onUpdate={onUpdate}
        onCancel={onCancel}
        show={isEditRevenues}
        selectedData={selectedData}
      />
      <ImportRevenues
        show={showImportRevenues}
        onCancel={onCancel}
        onImport={onImport}
        url="/finance/revenues/import"
      />
    </div>
  );
};

export default Revenues;
