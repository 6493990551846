import moment from "moment";
import { createContext, useState } from "react";

const newDate = new Date();
const firstDate = new Date(newDate.getFullYear(), 0, 1);
const lastDate = new Date(newDate.getFullYear(), 11, 31);

export const TriwulanReportContext = createContext();

export const TriwulanReportProvider = ({ children }) => {
  const [keyword, setKeyword] = useState("");
  const [reportId, setReportId] = useState("");
  const [reportEmployeeId, setReportEmployeeId] = useState("");
  const [fetchReport, setFetchReport] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });

  return (
    <TriwulanReportContext.Provider
      value={{
        keyword,
        reportId,
        fetchReport,
        showCreate,
        showEdit,
        date,
        reportEmployeeId,
        setKeyword,
        setReportEmployeeId,
        setReportId,
        setFetchReport,
        setShowCreate,
        setShowEdit,
        setDate,
      }}
    >
      {children}
    </TriwulanReportContext.Provider>
  );
};
