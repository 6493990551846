import { Checkbox, Form, Input, InputNumber, Modal, Select } from "antd";
import CreateProsemDetailLogic from "./CreateProsemDetailLogic";

const CreateProsemDetail = (props) => {
  const { form, loading, dataKI, dataMetode, onSubmit, onCancelModal } =
    CreateProsemDetailLogic(props);

  return (
    <Modal
      style={{ top: 20 }}
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      closable={false}
      open={props.open}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Kompetensi Inti"
          name="kompetensiIntiId"
          rules={[{ required: true }]}
        >
          <Select>
            {dataKI?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Kompetensi Dasar"
          name="kompetensiDasar"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bab"
          name="kompetensiDasarIndex"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label="Pertemuan ke"
          name="pertemuan"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label="Nama Materi"
          name="materi"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Metode Pengambilan Nilai"
          name="metode"
          rules={[{ required: true }]}
        >
          <Select>
            {dataMetode?.data?.data?.map((x) => (
              <Select.Option key={x.id} value={x.nama}>
                {x.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="kategori1" valuePropName="checked">
          <Checkbox>Penting untuk keselamatan dunia dan akhirat</Checkbox>
        </Form.Item>

        <Form.Item name="kategori2" valuePropName="checked">
          <Checkbox>Penting sebagai bekal masa depan</Checkbox>
        </Form.Item>

        <Form.Item name="kategori3" valuePropName="checked">
          <Checkbox>Penting untuk melanjutkan jenjang berikutnya</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProsemDetail;
