import React from "react";

const TReportPenilai = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>PENILAI</th>
        </tr>
        <tr>
          <th>Atasan langsung</th>
          <th>Atasan tidak langsung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {data?.penilai?.direct_supervisor?.map((x) => x?.name).join("-")}
          </td>
          <td>{data?.penilai?.indirect_supervisor?.name}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TReportPenilai;
