import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useBukuNilaiDetail = (id, enabled) => {
  return useQuery(
    ["get-rpn-detail", id],
    () => GetDetail(`/academics/buku-nilai/${id}`),
    { enabled }
  );
};
