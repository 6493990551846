import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useStudentNotInClass = ({ dataTable, keyword, classId }) => {
  return useQuery(
    [
      "get-student-pagination-not-in-class",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      classId,
    ],
    () =>
      GetPagination(
        `/academics/student-not-in-class?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&mode=page&classId=${classId}&isGraduated=false`
      )
  );
};
