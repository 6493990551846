import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import CreateActivity from "pages/si-hrd/activity/create/CreateActivity";
import EditActivity from "../edit/EditActivity";
import { Popconfirm, Tag } from "antd";
import Column from "antd/lib/table/Column";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { DeleteApi } from "api/DeleteApi";
import { encryptCookies } from "components/Helper/CookiesHelper";
import { useActivityPagination } from "hooks/activity-hook/useActivityPagination";
import { usePermission } from "hooks/usePermissions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const ActivityTable = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 20,
    total: 0,
  });

  const [showCreate, setShowCreate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const navigate = useNavigate();

  // fetch activity pagination
  const {
    data: dataActivity,
    isLoading,
    isFetching,
    refetch,
  } = useActivityPagination(dataTable, keyword);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuActivity");

  const onCreate = () => {
    setShowCreate(false);
    refetch();
  };

  const onUpdate = () => {
    setShowUpdate(false);
    refetch();
  };

  const onCancel = () => {
    setShowUpdate(false);
  };

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddActivity");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditActivity");
  const btnPresence = dataPermission?.find((x) => x.id === "btnPresence");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailActivity");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteActivity");

  const dataSource = dataActivity?.data?.data
    ?.sort((a, b) => a.activity_type.localeCompare(b.activity_type))
    .map((data, index) => {
      return {
        ...data,
        division: data.division && data.division.name,
        key: data.id,
        index: index + 1,
        time_in_start: data.time_in_start ? data.time_in_start : "",
        time_late_start: data.time_late_start ? data.time_late_start : "",
        unitName: data.unit?.name,
      };
    });

  const columns = (
    <>
      <Column
        title="No"
        dataIndex="index"
        width={50}
        fixed="left"
        responsive={["md"]}
      />
      <Column title="Nama" dataIndex="name" width={120} fixed="left" />
      <Column title="Unit" dataIndex="unitName" width={120} />
      <Column
        title="Jam Kerja"
        dataIndex="max_working_duration"
        align="center"
        width={100}
      />
      <ColumnGroup title="Presensi Masuk">
        <Column
          title="Awal"
          dataIndex="time_in_start"
          align="center"
          width={120}
        />
        <Column
          title="Terlambat"
          dataIndex="time_late_start"
          align="center"
          width={100}
        />
        <Column
          title="Akhir"
          dataIndex="time_in_end"
          align="center"
          width={120}
        />
      </ColumnGroup>
      <ColumnGroup title="Presensi Pulang">
        <Column
          title="Awal"
          dataIndex="time_out_start"
          align="center"
          width={120}
        />
        <Column
          title="Akhir"
          dataIndex="time_out_end"
          align="center"
          width={120}
        />
        <Column
          title="Default"
          dataIndex="time_out_default"
          align="center"
          width={100}
        />
      </ColumnGroup>
      <Column
        title="Aksi"
        dataIndex="id"
        align="center"
        width={200}
        render={(_, record) => (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/activity/", dataId, refetch })
              }
              disabled={btnDelete && btnDelete.type === "disabled"}
              style={{ cursor: "pointer" }}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDelete?.type === "disabled") {
                    return;
                  } else {
                    setDataId(record.id);
                  }
                }}
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(record.id);
                  setShowUpdate(true);
                }
              }}
            >
              Ubah
            </Tag>
            {record.activity_type === "fixed_time" ? (
              <Tag
                color="blue"
                hidden={!btnPresence}
                style={
                  btnPresence?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnPresence?.type === "disabled") {
                    return;
                  } else {
                    navigate("/hrd/activity/presence-" + record.id);
                    encryptCookies("defaultHrdMenu", "mnuPresence");
                  }
                }}
              >
                Presensi
              </Tag>
            ) : (
              <Tag
                color="blue"
                hidden={!btnDetail}
                style={
                  btnDetail?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDetail?.type === "disabled") return;
                  else navigate(`/hrd/activity/${record.id}`);
                }}
              >
                Detail
              </Tag>
            )}
          </Space>
        )}
      />
    </>
  );

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataActivity?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <>
      <div className="table-header">
        <h1>Daftar Aktivitas</h1>
        <Space>
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="cari nama aktivitas"
          />
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <Table
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 800 }
            : { y: "55vh", x: 800 }
        }
      >
        {columns}
      </Table>
      <CreateActivity
        onCancel={() => setShowCreate(false)}
        onCreate={onCreate}
        show={showCreate}
      />
      <EditActivity
        dataId={dataId}
        onUpdate={onUpdate}
        onCancel={onCancel}
        show={showUpdate}
      />
    </>
  );
};

export default ActivityTable;
