import { Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import "./DetailJurnalMengajar.css";
import dayjs from "dayjs";

export default function DetailJurnalMengajar({
  open,
  setOpen,
  jurnalData: data,
}) {
  const [dataStatus, setDataStatus] = React.useState("");
  const [resDuration, setResDuration] = React.useState("");
  const status = () => {
    if (data?.status === "teach") {
      return setDataStatus("Mengajar");
    } else if (data?.status === "not_teach") {
      return setDataStatus("Tidak Mengajar");
    } else if (data?.status === "exam") {
      return setDataStatus("Ujian");
    } else if (data?.status === "homework") {
      return setDataStatus("Tugas");
    } else {
      return setDataStatus(data?.status);
    }
  };

  useEffect(() => {
    if (open) {
      status();

      const waktuAwal = dayjs(data.date_in);
      const waktuAkhir = dayjs(data.date_out);

      const selisihMenit = waktuAkhir.diff(waktuAwal, "minute");

      const jam = Math.floor(selisihMenit / 60);
      const menit = selisihMenit % 60;

      setResDuration(`${jam} jam ${menit} menit`);
    }
    //eslint-disable-next-line
  }, [open]);

  return (
    <Modal footer={false} open={open} onCancel={() => setOpen(false)}>
      {/* <table className="detail-student__table"> */}
      <table className="table-detail-jurnal">
        <tbody>
          <tr>
            <th>Guru</th>
            <td>{data?.teacherName}</td>
          </tr>
          <tr>
            <th>Pelajaran</th>
            <td>{data?.subject}</td>
          </tr>
          <tr>
            <th>Kelas</th>
            <td>{data?.className}</td>
          </tr>
          <tr>
            <th>KD</th>
            <td>{data?.kompetensiDasar}</td>
          </tr>
          <tr>
            <th>Materi Prosem</th>
            <td>{data?.materiProsem}</td>
          </tr>
          <tr>
            <th>Materi</th>
            <td>{data?.material}</td>
          </tr>
          <tr>
            <th>Sesi </th>
            <td>{data?.sessionName}</td>
          </tr>
          <tr>
            <th>Waktu</th>
            <td>
              {dayjs(data?.date_in).format("DD MMMM YYYY | HH:mm")} -{" "}
              {dayjs(data?.date_out).format("HH:mm")}
            </td>
          </tr>
          <tr>
            <th>Durasi Mengajar</th>
            <td>{resDuration}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{dataStatus}</td>
          </tr>
          <tr>
            <th>Alasan Tidak Mengajar </th>
            <td>{data?.reason_not_teach}</td>
          </tr>
          <tr>
            <th>Catatan Kelas</th>
            <td> {data?.catatan_kelas}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}
