import { GetDetail } from "api/GetDetail";
import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useMonthlyReportPagination = ({ dataTable, keyword, date }) => {
  return useQuery(
    [
      "get-monthlyreport-pagination",
      dataTable.page,
      dataTable.limit,
      keyword,
      date.from,
      date.to,
    ],
    () =>
      GetPagination(
        `/monthly-reports?page=${dataTable.page}&limit=${
          dataTable.limit
        }&keyword=${keyword ? keyword : ""}&fromDate=${date.from}&toDate=${
          date.to
        }`
      )
  );
};

export const useMonthlyReportDetail = ({
  id,
  keyword,
  employeeId,
  dataTable,
}) => {
  return useQuery(
    [
      "get-monthlyreport-detail",
      id,
      keyword,
      employeeId,
      dataTable?.page,
      dataTable?.limit,
    ],
    () =>
      GetDetail(
        `/monthly-reports/${id}?keyword=${keyword}&employeeId=${employeeId}&page=${dataTable?.page}&limit=${dataTable?.limit}`
      ),
    { enabled: false }
  );
};

export const useMonthlyReportEmployee = (id) => {
  return useQuery(
    ["get-monthlyreportemployee", id],
    () => GetDetail(`/monthly-report-employees/${id}`),
    { enabled: false }
  );
};
