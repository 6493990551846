import { Form, InputNumber, Modal, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useTeachersDetail } from "hooks/teachers-hook/useTeachersDetail";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export default function EditTeacher({ id, show, onCancel, onUpdate }) {
  const [form] = Form.useForm();
  const { data, isLoading } = useTeachersDetail(id, show);
  const [isLoad, setIsLoad] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        totalMengajar: data?.data?.total_mengajar,
      });
    }
  });

  const handleSubmit = async () => {
    const value = await form.validateFields();
    try {
      setIsLoad(true);
      await axios.put(REACT_APP_HOST_API + `/academics/teachers/${id}`, value, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      message.success("Data Berhasil Diubah");
      onUpdate();
      onCancel();
      form.resetFields();
    } catch (error) {
      console.log(error);
      alert("Gagal mengubah data!");
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <Modal
      confirmLoading={isLoad}
      closable={false}
      okText="Simpan"
      onOk={handleSubmit}
      cancelText="Batal"
      open={show}
      onCancel={onCancel}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item label="Total Mengajar" name="totalMengajar">
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
