import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useRevenuesDetail } from "hooks/finance-hook/useRevenuesDetail";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import { useState } from "react";
import { useParams } from "react-router-dom";

const BayarAlokasiLogic = ({ onCreate, onCancel, show, rowSelection }) => {
  const [posting, setPosting] = useState(false);
  const { revenues_id: id } = useParams();

  const { REACT_APP_HOST_API } = process.env;
  const [form] = Form.useForm();

  const { data: dataDetail, isLoading: skeleton } = useRevenuesDetail(id, show);

  const getTotalYangDibayar = () => {
    const totalTagihan = rowSelection?.reduce(
      (total, item) => total + item.amount,
      0
    );
    if (totalTagihan > dataDetail?.data?.current_balance) {
      return dataDetail?.data?.current_balance;
    } else if (totalTagihan < dataDetail?.data?.current_balance) {
      return totalTagihan;
    } else {
      return dataDetail?.data?.current_balance;
    }
  };

  const RowSelection = () => {
    const dataDetailAmount = dataDetail?.data?.current_balance;
    if (rowSelection) {
      let remainingAmount = dataDetailAmount;
      let renderAmountItem = 0;
      return rowSelection.map((item) => {
        remainingAmount -= item.amount;
        if (remainingAmount > 0 || remainingAmount === 0) {
          renderAmountItem = item.amount;
        } else if (remainingAmount < 0) {
          renderAmountItem = item.amount + remainingAmount;
        } else {
          renderAmountItem = 0;
        }
        item.amountPay = renderAmountItem < 0 ? 0 : renderAmountItem;
        return (
          <div key={item.id} className="modal-amount-container">
            <p className="modal-secondary-heading">{item.name}</p>
            <div className="modal-amount-box">
              <p>{renderAmount(item.amount)}</p>
              <p>{renderAmount(renderAmountItem < 0 ? 0 : renderAmountItem)}</p>
            </div>
          </div>
        );
      });
    }
    return null;
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const items = rowSelection?.map((item) => {
        return {
          billing_id: item.id,
          amount: item.amountPay,
        };
      });
      const postData = {
        teller_id: decryptCookies("employeeId"),
        revenue_id: id,
        method: values.method,
        type: values.type,
        account_id: dataDetail?.data?.account.id,
        items: items,
      };

      setPosting(true);
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/finance/transactions`,
        postData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      form.resetFields();
      onCreate();
    } catch (error) {
      message.error(error.message || "input field error");
    } finally {
      setPosting(false);
    }
  };

  const onCancelModal = () => {
    onCancel();
  };

  return {
    handleSubmit,
    onCancelModal,
    posting,
    skeleton,
    dataDetail,
    RowSelection,
    getTotalYangDibayar,
    form,
  };
};

export default BayarAlokasiLogic;
