import { DatePicker, Form, Input, Select } from "antd";
import React from "react";

const TabDataIbu = ({ newDataParent }) => {
  return (
    <div className="double-form">
      <div>
        <Form.Item
          label="Nama Ibu"
          name="mother0_0name"
          rules={[
            {
              pattern: new RegExp("^[A-Za-z ]+$"),
              message: "Nama hanya dapat berupa huruf",
            },
            { min: 5, message: "Nama minimal 5 karakter" },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0name = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="NIK "
          name="mother0_0nik"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 16, message: "Maksimal 16 karakter" },
            { min: 16, message: "Minimal 16 Karakter" },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0nik = value)
            }
          />
        </Form.Item>

        <Form.Item label="Tahun Lahir" name="mother0_0birth_date">
          <DatePicker
            onChange={(e) => (newDataParent.mother0_0birth_date = e)}
          />
        </Form.Item>

        <Form.Item label="Jenjang Pendidikan" name="mother0_0education">
          <Select
            onChange={(a) => {
              newDataParent.mother0_0education = a;
            }}
            options={[
              { value: "SD / Sederajat", label: "SD/Sederajat" },
              { value: "SMP / Sederajat", label: "SMP/Sederajat" },
              { value: "SMA / Sederajat", label: "SMA/Sederajat" },
              { value: "D1", label: "D1" },
              { value: "D2", label: "D2" },
              { value: "D3", label: "D3" },
              { value: "D4", label: "D4" },
              { value: "S1", label: "S1" },
              { value: "S2", label: "S2" },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Pekerjaan"
          name="mother0_0occupation"
          rules={[
            {
              pattern: new RegExp("^[A-Za-z ,]+$"),
              message: "Nama hanya dapat berupa huruf spasi (,)",
            },
            { max: 40 },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0occupation = value)
            }
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Penghasilan Minimun"
          name="mother0_0min_salary"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 10 },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0min_salary = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Penghasilan Maximum"
          name="mother0_0max_salary"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 10 },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0max_salary = value)
            }
          />
        </Form.Item>

        <Form.Item label="Alamat" name="mother0_0address">
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0address = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="No. Telp"
          name="mother0_0phone_number"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "input hanya berupa angka",
            },
            { max: 16 },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0phone_number = value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="mother0_0email"
          rules={[
            {
              type: "email",
              message: "Email tidak valid",
            },
            { max: 50 },
          ]}
        >
          <Input
            onChange={({ target: { value } }) =>
              (newDataParent.mother0_0email = value)
            }
          />
        </Form.Item>

        {/* <Form.Item label="Hubungan Dengan Siswa" name="relationship_w_student">
        <Select
        disabled={true}
        options={[
            {
              value: "biological mother",
              label: "Ibu Kandung",
            },
          ]}
        />
      </Form.Item> */}
      </div>
    </div>
  );
};

export default TabDataIbu;
