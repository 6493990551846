import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useSubActivityPresence = ({ id, dataTable, keyword }) => {
  return useQuery(
    [
      "get-subActivity-presence",
      id,
      dataTable.current_page,
      dataTable.per_page,
      keyword,
    ],
    () =>
      GetPagination(
        `/presences?subActivityId=${id}&page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      )
  );
};
