import { Form, Modal, Select, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useDivisionList } from "hooks/divison-hook/useDivisionList";
import React, { useEffect } from "react";

const titleOptions = [
  { label: "Ketua", value: "lead" },
  { label: "Wakil", value: "vice" },
  { label: "Anggota", value: "member" },
];

const AddDivision = ({ id, open, onCreateDivision, onCancelDivision }) => {
  const [form] = Form.useForm();

  const { REACT_APP_HOST_API: api } = process.env;

  // get division list
  const {
    data: dataDivision,
    isLoading,
    isFetching,
    refetch,
  } = useDivisionList();

  useEffect(() => {
    if (open) refetch();
  }, [open, refetch]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    try {
      const { data } = await axios.post(
        api + `/employee/${id}/divisions`,
        values,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      onCancelDivision();
      onCreateDivision();
      form.resetFields();
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Modal
      open={open}
      okText="Submit"
      closable={false}
      onOk={onSubmit}
      onCancel={onCancelDivision}
    >
      {isLoading || isFetching ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            name="divisionId"
            label="Divisi"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataDivision?.data?.map((division) => (
                <Select.Option key={division.id} value={division.id}>
                  {division.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="title" label="Jabatan" rules={[{ required: true }]}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label?.toLowerCase().includes(input.toLowerCase())
              }
              options={titleOptions}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddDivision;
