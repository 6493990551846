import { Form, Select, message } from "antd";
import Modal from "antd/es/modal/Modal";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { ActivityContext } from "context/ActivityContext";
import { useContext, useState } from "react";

const EditMemberActivity = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { memberId, showEditMember, setShowEditMember, setFetchActivity } =
    useContext(ActivityContext);

  const { REACT_APP_HOST_API: api } = process.env;

  const onCancelModal = () => {
    setShowEditMember(false);
  };

  const onSubmit = async () => {
    const value = await form.validateFields();

    setLoading(true);

    try {
      const { data } = await axios.put(
        api + `/activity-members/${memberId}`,
        { role: value.role },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      setFetchActivity(true);
      onCancelModal();
      form.resetFields();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={onSubmit}
      closable={false}
      open={showEditMember}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="role" label="Role">
          <Select
            options={[
              { label: "Manager", value: "manager" },
              { label: "Member", value: "member" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditMemberActivity;
