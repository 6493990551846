import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Skeleton,
  Tag,
} from "antd";
import AddDivision from "./AddDivision";
import EditDivision from "./EditDivision";
import EditEmployeeLogic from "./EditEmployeeLogic";
import { decryptCookies } from "components/Helper/CookiesHelper";

const items = [
  { key: "edit", label: "Edit" },
  { key: "delete", label: "Delete" },
];

const EditEmployee = (props) => {
  const { show } = props;
  const {
    data,
    form,
    newData,
    dataKec,
    dataKel,
    dataKota,
    kodeProv,
    idEmployee,
    idDivision,
    isFetching,
    kecDisabled,
    kelDisabled,
    modalFooter,
    dataProvinsi,
    kotaDisabled,
    showAddDivision,
    showEditDivision,
    dataEmployeeType,
    dataFoundation,
    status,
    setStatus,
    onClickTag,
    onCancelModal,
    setIdEmployee,
    handleKotaChange,
    onCreateDivision,
    onCancelDivision,
    setShowAddDivision,
    handleProvinsiChange,
    handleKecamatanChange,
    handleTitleDivision,
  } = EditEmployeeLogic(props);
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  return (
    <>
      <Modal
        open={show}
        onCancel={onCancelModal}
        footer={modalFooter}
        width={window.innerWidth > 1300 ? "70%" : "90%"}
        style={{ top: 20 }}
      >
        {isFetching ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <Form form={form} layout="vertical" className="full-form">
            <div className="first-form">
              <Form.Item
                name="name"
                label="Nama"
                rules={[{ min: 5, message: "Masukkan minimal 5 huruf" }]}
              >
                <Input
                  onChange={({ target: { value } }) =>
                    (newData["name"] = value)
                  }
                />
              </Form.Item>
              <Form.Item name="nik" label="NIK">
                <Input
                  onChange={({ target: { value } }) => (newData["nik"] = value)}
                />
              </Form.Item>
              <Form.Item name="nip" label="NIP">
                <Input
                  onChange={({ target: { value } }) => (newData["nip"] = value)}
                />
              </Form.Item>
              <Form.Item name="birthCity" label="Tempat Lahir">
                <Input
                  onChange={({ target: { value } }) =>
                    (newData["birthCity"] = value)
                  }
                />
              </Form.Item>
              <Form.Item name="birthDay" label="Tanggal Lahir">
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="Pilih Tanggal"
                  onChange={(val) =>
                    (newData["birthDay"] = val.format("YYYY-MM-DD"))
                  }
                />
              </Form.Item>
              <Form.Item name="gender" label="Gender">
                <Select onChange={(val) => (newData["gender"] = val)}>
                  <Select.Option value="L">Laki-laki</Select.Option>
                  <Select.Option value="P">Perempuan</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="nuptk" label="NUPTK">
                <Input
                  onChange={({ target: { value } }) =>
                    (newData["NUPTK"] = value)
                  }
                />
              </Form.Item>
              <Form.Item name="employeeTypeId" label="Tipe Pegawai">
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Tipe Pegawai"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(val) => (newData["employeeTypeId"] = val)}
                >
                  {dataEmployeeType?.data?.data?.map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="second-form">
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { label: "FULLTIME", value: "FULL_TIME" },
                    { label: "PARTTIME", value: "PART_TIME" },
                  ]}
                  onChange={(val) => {
                    newData.status = val;
                    setStatus(val);
                  }}
                />
              </Form.Item>
              {status === "PART_TIME" && (
                <Form.Item
                  name="defaultPresence"
                  label="Default kehadiran dalam 1 Bulan"
                >
                  <InputNumber
                    onChange={(val) => (newData.defaultPresence = val)}
                  />
                </Form.Item>
              )}
              <Form.Item name="dateIn" label="Tanggal Masuk">
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="Pilih Tanggal"
                  onChange={(val) =>
                    (newData["dateIn"] = val.format("YYYY-MM-DD"))
                  }
                />
              </Form.Item>
              <Form.Item name="dateOut" label="Tanggal Keluar">
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="Pilih Tanggal"
                  onChange={(val) =>
                    (newData["dateOut"] = val.format("YYYY-MM-DD"))
                  }
                />
              </Form.Item>
              <Form.Item
                name="address"
                label="Alamat"
                rules={[{ min: 20, message: "Masukkan minimal 20 huruf" }]}
              >
                <Input
                  onChange={({ target: { value } }) =>
                    (newData["address"] = value)
                  }
                />
              </Form.Item>
              <Form.Item name="kodeProvinsi" label="Provinsi">
                <Select
                  showSearch
                  placeholder="Pilih Provinsi"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={handleProvinsiChange}
                >
                  <Select.Option value="---">---</Select.Option>
                  {dataProvinsi?.data?.map((item) => (
                    <Select.Option key={item.kode} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="kodeKota"
                label="Kota"
                rules={[{ required: kodeProv }]}
              >
                <Select
                  disabled={kotaDisabled}
                  showSearch
                  placeholder="Pilih Kota"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={handleKotaChange}
                >
                  {dataKota?.data?.map((item) => (
                    <Select.Option key={item.kode} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="kodeKecamatan"
                label="Kecamatan"
                rules={[{ required: kodeProv }]}
              >
                <Select
                  disabled={kecDisabled}
                  showSearch
                  placeholder="Pilih Kecamatan"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={handleKecamatanChange}
                >
                  {dataKec?.data?.map((item) => (
                    <Select.Option key={item.kode} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="third-form">
              <Form.Item
                name="kodeKelurahan"
                label="Kelurahan"
                rules={[{ required: kodeProv }]}
              >
                <Select
                  disabled={kelDisabled}
                  showSearch
                  placeholder="Pilih Kelurahan"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => (newData.kodeKelurahan = e)}
                >
                  {dataKel?.data?.map((item) => (
                    <Select.Option key={item.kode} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="lastEducationName" label="Universitas">
                <Input
                  onChange={({ target: { value } }) =>
                    (newData["lastEducationName"] = value)
                  }
                />
              </Form.Item>
              <Form.Item name="lastEducationMajor" label="Jurusan">
                <Input
                  onChange={({ target: { value } }) =>
                    (newData["lastEducationMajor"] = value)
                  }
                />
              </Form.Item>
              <Form.Item name="lastEducationGraduate" label="Tanggal Lulus">
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={(val) =>
                    (newData["lastEducationGraduate"] =
                      val.format("YYYY-MM-DD"))
                  }
                />
              </Form.Item>

              <Form.Item label="Divisi">
                <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                  <Tag
                    style={{ borderStyle: "dashed", cursor: "pointer" }}
                    onClick={() => {
                      setIdEmployee(data?.data[0].id);
                      setShowAddDivision(true);
                    }}
                  >
                    <PlusOutlined /> Tambah Divisi
                  </Tag>
                  {data?.data[0].divisions?.map((item, i) => (
                    <Dropdown
                      key={i}
                      menu={{ items, onClick: (e) => onClickTag(e, item) }}
                      trigger={["click"]}
                      placement="topLeft"
                      arrow
                    >
                      <Tag color="blue" style={{ cursor: "pointer" }}>
                        {handleTitleDivision(item.title)} | {item.division.name}
                      </Tag>
                    </Dropdown>
                  ))}
                </div>
              </Form.Item>
              {isSuperAdmin && (
                <Form.Item
                  name="foundationId"
                  label="foundation"
                  rules={[{ required: kodeProv }]}
                >
                  <Select
                    showSearch
                    placeholder="Pilih foundation"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e) => (newData.foundationId = e)}
                  >
                    {dataFoundation?.data?.data?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                name={"hasBpjsHealth"}
                label="Sudah Menerima BPJS Kesehatan"
              >
                <Radio.Group
                  onChange={(e) => (newData.hasBpjsHealth = e.target.value)}
                >
                  <Radio value={true}>Sudah</Radio>
                  <Radio value={false}>Belum</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={"hasBpjsEmployment"}
                label="Sudah Menerima BPJS Ketenagakerjaan"
              >
                <Radio.Group
                  onChange={(e) => (newData.hasBpjsEmployment = e.target.value)}
                >
                  <Radio value={true}>Sudah</Radio>
                  <Radio value={false}>Belum</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Form>
        )}
      </Modal>
      <AddDivision
        id={idEmployee}
        open={showAddDivision}
        onCreateDivision={onCreateDivision}
        onCancelDivision={onCancelDivision}
      />
      <EditDivision
        open={showEditDivision}
        employeeId={idEmployee}
        divisionId={idDivision}
        onUpdate={onCreateDivision}
        onCancel={onCancelDivision}
      />
    </>
  );
};

export default EditEmployee;
