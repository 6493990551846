import { Form, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useMetodePenilaianList } from "hooks/prosem-detail-hook/useMetodePenilaianList";
import { useProsemDetailList } from "hooks/prosem-detail-hook/useProsemDetailList";
import { useProsemList } from "hooks/prosem-hook/useProsemList";
import { useEffect, useState } from "react";

const CreateRpnLogic = ({ open, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [idProsem, setIdProsem] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { REACT_APP_HOST_API: api } = process.env;

  // get metode pengambilan nilai
  const { data: dataMetode, refetch: fetchMetode } = useMetodePenilaianList();

  //   get prosem list
  const { data: dataProsem, refetch: fetchProsem } = useProsemList();

  //   get prosem detail list
  const { data: dataProsemDetail, refetch: fetchProsemDetail } =
    useProsemDetailList(idProsem, false);

  useEffect(() => {
    if (open) {
      fetchProsem();
      fetchMetode();
      if (idProsem) fetchProsemDetail();
    }
  }, [open, idProsem, fetchProsem, fetchMetode, fetchProsemDetail]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.post(
        api + `/academics/rencana-pengambilan-nilai`,
        {
          programSemesterDetailId: values.programSemesterDetailId,
          metodePengambilanNilaiId: values.metodePengambilanNilaiId,
          topik: values.topik,
          presentase: values.presentase,
          subjectId: dataProsemDetail?.data?.kelas?.subject_id,
          teacherId: dataProsemDetail?.data?.kelas?.teacher_id,
          pertemuan: values.pertemuan,
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      form.resetFields();
      onCreate();
      onCancelModal();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    setIdProsem("");
    setDisabled(true);
    onCancel();
  };

  const onSelectProsem = (value) => {
    setIdProsem(value);
    setDisabled(false);
  };

  return {
    form,
    loading,
    disabled,
    dataMetode,
    dataProsem,
    dataProsemDetail,
    onSubmit,
    onCancelModal,
    onSelectProsem,
  };
};

export default CreateRpnLogic;
