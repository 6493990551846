const TReportHeader = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={4}>RAPOR KINERJA TRIWULAN {data?.dataUnit?.name}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Nama</td>
          <td>: {data?.dataEmployee?.name}</td>
          <td>Masa Kerja</td>
          <td>: {data?.dataEmployee?.period_of_work}</td>
        </tr>
        <tr>
          <td>NIK</td>
          <td>: {data?.dataEmployee?.nik}</td>
          <td>Periode</td>
          <td>: {data?.dataEmployee?.period_of_assessment}</td>
        </tr>
        <tr>
          <td>Jabatan</td>
          <td>
            :{" "}
            {data?.dataEmployee?.divisions
              ?.map(
                (x) =>
                  `${x?.title?.charAt(0).toUpperCase()}${x?.title.slice(1)} - ${x?.division?.name
                  }`
              )
              .join(" & ")}
          </td>
          <td>Triwulan</td>
          <td>: {data?.dataEmployee?.triwulan}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TReportHeader;
